import { memo } from "react";
import { Navigate, useParams } from "react-router-dom";

// -----------------------------------------------------------------------------

export const DcvRedirectWrapperPage = memo(() => {
  const { code } = useParams();

  return <Navigate to={`/settings?code=${code ?? ""}`} replace />;
});
