import { type ApiMeGetResponse } from "data/api";
import { UserPermissionLevel, type UserRecord } from "data/user";

// -----------------------------------------------------------------------------

export function userHasPermission(
  user: UserRecord | ApiMeGetResponse,
  permission: UserPermissionLevel
): boolean {
  return (
    ((user as UserRecord)?.permissionLevel ??
      (user as ApiMeGetResponse)?.permission_level) === permission
  );
}

export function userIsSuperAdmin(user: UserRecord | ApiMeGetResponse): boolean {
  return userHasPermission(user, UserPermissionLevel.SuperAdmin);
}

export function userIsAdmin(user: UserRecord | ApiMeGetResponse): boolean {
  return (
    userHasPermission(user, UserPermissionLevel.SuperAdmin) ||
    userHasPermission(user, UserPermissionLevel.Admin)
  );
}

export function userIsAdminOnly(user: UserRecord | ApiMeGetResponse): boolean {
  return userHasPermission(user, UserPermissionLevel.Admin);
}

export function userCanInviteMembers(
  user: UserRecord | ApiMeGetResponse
): boolean {
  return userIsAdmin(user);
}

export function userCanAccessBilling(
  user: UserRecord | ApiMeGetResponse
): boolean {
  return userIsSuperAdmin(user);
}
