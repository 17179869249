import { memo } from "react";
import { useNavigate } from "react-router-dom";

import Stack from "@mui/material/Stack";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AppsIcon from "@mui/icons-material/Apps";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import CodeIcon from "@mui/icons-material/Code";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DiamondOutlinedIcon from "@mui/icons-material/DiamondOutlined";
import FeedbackOutlinedIcon from "@mui/icons-material/FeedbackOutlined";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import HomeIcon from "@mui/icons-material/Home";
import HubIcon from "@mui/icons-material/Hub";
import LanIcon from "@mui/icons-material/Lan";
import LogoutIcon from "@mui/icons-material/Logout";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import MemoryIcon from "@mui/icons-material/Memory";
import OutputIcon from "@mui/icons-material/Output";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PublicIcon from "@mui/icons-material/Public";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SafetyCheckIcon from "@mui/icons-material/SafetyCheck";
import SettingsIcon from "@mui/icons-material/Settings";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import TableChartIcon from "@mui/icons-material/TableChart";

import { useUserStore } from "stores/UserStore";

import { clearAccessTokens, getRefreshToken } from "utility/credential_storage";
import { authenticatedPostFetch } from "utility/fetch/authenticated";
import { openOrtto } from "utility/ortto";

import { SidebarButton } from "./Button";
import { SidebarCategory } from "./Category";

// -----------------------------------------------------------------------------

export const SidebarContent = memo(({ iconOnly, isMobile }: Props) => {
  const navigate = useNavigate();

  const [, userActions] = useUserStore();

  //
  return (
    <Stack direction="column">
      <SidebarCategory
        label="Reporting"
        to="/analytics"
        Icon={HomeIcon}
        iconOnly={iconOnly}
      >
        <SidebarButton
          label="Mission Control"
          to="/analytics"
          Icon={DashboardIcon}
        />
        <SidebarButton
          label="Security Health Score"
          to="/analytics/risk"
          Icon={ShowChartIcon}
        />
        <SidebarButton
          label="Identity Access Management"
          to="/analytics/iam"
          Icon={AssignmentIndIcon}
        />
        <SidebarButton
          label="Stale Findings"
          to="/analytics/stale"
          Icon={AvTimerIcon}
        />
        <SidebarButton
          label="Internet Exposed"
          to="/analytics/internet_exposed"
          Icon={PublicIcon}
        />
        <SidebarButton
          label="Third Party Apps"
          to="/analytics/third_party_apps"
          Icon={HubIcon}
        />
        <SidebarButton
          label="Network Security"
          to="/analytics/network_security"
          Icon={LanIcon}
        />
        <SidebarButton
          label="Software Supply Chain"
          to="/analytics/software_supply_chain"
          Icon={CodeIcon}
        />
        <SidebarButton
          label="Stale Accounts"
          to="/analytics/stale_accounts"
          Icon={SafetyCheckIcon}
        />
      </SidebarCategory>
      <SidebarCategory
        label="Findings"
        to="/findings"
        Icon={AssignmentIcon}
        iconOnly={iconOnly}
      >
        <SidebarButton label="Overview" to="/findings" Icon={TableChartIcon} />
        <SidebarButton
          label="Rule Management"
          to="/findings/rule-management"
          Icon={ManageSearchIcon}
        />
      </SidebarCategory>
      <SidebarCategory
        label="Environment"
        to="/environment/sources"
        Icon={MemoryIcon}
        iconOnly={iconOnly}
      >
        <SidebarButton
          label="Sources"
          to="/environment/sources"
          Icon={AppsIcon}
        />
        <SidebarButton
          label="Destinations"
          to="/environment/destinations"
          Icon={OutputIcon}
        />
      </SidebarCategory>
      <SidebarButton
        topLevel
        label="Asset Inventory"
        to="/asset/inventory"
        Icon={DiamondOutlinedIcon}
        iconOnly={iconOnly}
      />
      <SidebarCategory
        label="Organization"
        to="/settings"
        Icon={CorporateFareIcon}
        iconOnly={iconOnly}
      >
        <SidebarButton label="Settings" to="/settings" Icon={SettingsIcon} />
        <SidebarButton label="Members" to="/members" Icon={PeopleAltIcon} />
        <SidebarButton
          label="Trust Center"
          to="/settings/trust-center"
          Icon={AssuredWorkloadIcon}
        />
        <SidebarButton
          label="Billing"
          to="#"
          onClick={openOrtto}
          Icon={ReceiptIcon}
        />
      </SidebarCategory>
      <SidebarButton
        external
        topLevel
        label="Documentation"
        rel="noopener noreferrer"
        target="_blank"
        to="https://docs.threatkey.com"
        Icon={FolderOpenIcon}
        iconOnly={iconOnly}
      />
      <SidebarButton
        topLevel
        label="Give Feedback"
        to="#"
        Icon={FeedbackOutlinedIcon}
        iconOnly={iconOnly}
        onClick={openOrtto}
      />
      {isMobile && (
        <SidebarButton
          topLevel
          label="Account"
          to="/account"
          Icon={AccountCircleIcon}
          iconOnly={iconOnly}
        />
      )}
      {isMobile && (
        <SidebarButton
          topLevel
          label="Logout"
          to="/login"
          Icon={LogoutIcon}
          onClick={() => {
            authenticatedPostFetch("api/auth/logoutUser", {
              refresh_token: getRefreshToken(),
            });
            clearAccessTokens();
            userActions.clear();
            navigate("/login");
          }}
        />
      )}
    </Stack>
  );
});

// -----------------------------------------------------------------------------

interface Props {
  iconOnly: boolean;
  isMobile: boolean;
}
