import { memo } from "react";

import SvgIcon from "@mui/material/SvgIcon";

// -----------------------------------------------------------------------------

export const CloudflareSAMLSSOIcon = memo((props: object) => {
  return (
    <SvgIcon viewBox="0 40 395.68 114.22" {...props} overflow="visible">
      <path
        d="M623.48,421.76H534c-3,0-3.42-.53-3.7-3.57a41.49,41.49,0,0,1,1.35-15.37,41.31,41.31,0,0,1,36.24-29.88c2.23-.19,2.23-.22,1.92-2.41-1.48-10.37,1.44-19.27,9.17-26.42,7.2-6.66,15.79-8.69,25.34-7a28.48,28.48,0,0,1,10.17,4.12c1.44.88,1.61.84,2.21-.75a62.88,62.88,0,0,1,9-16.34c9.61-12.47,22-20.75,37.45-24.11,19.45-4.24,37.38-.4,53.34,11.5a61.9,61.9,0,0,1,22.75,32.56c.16.54.36,1.06.53,1.6a9.45,9.45,0,0,1,0,7.07c-2.36,5.44-4,11.21-6.81,16.4A33.7,33.7,0,0,1,708.45,387c-4.91.91-10.09.5-15.15.57-15.31.2-30.62.33-45.94.51q-14.91.17-29.81.36-5.25.07-10.49.23a5.69,5.69,0,0,0-1.65.26,2.06,2.06,0,0,0-1.48,2.48c.14,1.22,1,1.46,2,1.49,3.69.09,7.37.2,11.06.24,7.12.08,14.25.11,21.38.18q15.09.17,30.18.37,13.5.18,27,.4c3.19.05,6.38,0,9.55.25,9,.74,14.54,7.13,13.75,15.8a62.94,62.94,0,0,1-2,9.11c-.46,1.84-1.33,2.46-3.21,2.48-4.07,0-8.13,0-12.2,0h-78Z"
        fill="#f08021"
        transform="translate(-462.09 -298.32)"
      />
      <path
        d="M762,421.78H725.63c-2.52,0-2.75-.47-1.89-2.92a35.5,35.5,0,0,1,9.75-15.28c6.29-5.55,13.41-9.12,22.06-9.3,6.29-.14,12.57-.7,18.86-1.09a7.63,7.63,0,0,0,1.84-.32,2.21,2.21,0,0,0,1.8-2.47c-.11-1.36-1.12-1.55-2.13-1.62-2.68-.19-5.36-.28-8-.46-5-.34-10-.49-14.89-1.14a13.5,13.5,0,0,1-11.86-10,16.52,16.52,0,0,1,0-9.22c1.26-4.76,2.63-9.48,3.94-14.23a2.36,2.36,0,0,1,2.58-1.71,56.46,56.46,0,0,1,17,2.19,54.16,54.16,0,0,1,32.11,25.82,54.12,54.12,0,0,1,6.52,23.17,65.65,65.65,0,0,1-1.34,15.4c-.49,2.72-1.11,3.15-3.92,3.15Z"
        fill="#f9ad44"
        transform="translate(-462.09 -298.32)"
      />
      <path
        d="M790.61,455.79c0-4.74.06-9.49,0-14.23,0-1.53.46-1.86,1.9-1.83,4.69.09,9.37,0,14.06.07,3.7.1,7.17,1,9.79,3.89a10.38,10.38,0,0,1-2.57,16c-1.16.63-1.08,1-.45,1.88,2,2.85,4,5.74,5.93,8.63a10,10,0,0,1,.63,1.27c-.43,0-.85.13-1.28.13-2.43,0-4.87-.06-7.31,0a2,2,0,0,1-2-1.07c-1.65-2.56-3.37-5.08-5.08-7.62-.58-.86-3.65-1.53-4.4-.9-.26.22-.3.78-.31,1.18,0,2.37-.07,4.75,0,7.12,0,1-.26,1.32-1.26,1.28-2-.06-4-.09-6,0-1.28.07-1.73-.32-1.68-1.63.09-2.37,0-4.74,0-7.11Zm8.88-4.24a21.27,21.27,0,0,0,0,3,1.39,1.39,0,0,0,1,.93,17,17,0,0,0,7-.75,3.5,3.5,0,0,0,2.55-3.48,3.33,3.33,0,0,0-2.91-3.13,28.39,28.39,0,0,0-3.91-.22C799.51,447.85,799.51,447.87,799.49,451.55Z"
        fill={props.theme?.cloudFlareLogo.main}
        transform="translate(-462.09 -298.32)"
      />
      <path
        d="M555.06,472a15.47,15.47,0,0,1-13.63-9.24,15.66,15.66,0,0,1,1.71-17.24,16.69,16.69,0,0,1,15.7-6.54c6.83.73,12.09,4.18,14.37,11.09C577,461.36,568.93,472,555.06,472Zm2.86-8.31c4.1.1,7.17-3.18,7.32-7.82.15-4.85-3.28-9.05-7.47-9.14-5.08-.12-8.56,3.17-8.67,8.2C549,460.11,552.44,463.54,557.92,463.68Z"
        fill={props.theme?.cloudFlareLogo.main}
        transform="translate(-462.09 -298.32)"
      />
      <path
        d="M628.64,455.64c0-4.75,0-9.5,0-14.24,0-1.32.36-1.72,1.68-1.67,4.81.19,9.64,0,14.41.44,5.43.52,9.9,3.06,12.52,8.07,4,7.55,1.71,20.42-11.75,22.89-3.09.57-6.34.38-9.52.48-1.93.06-3.88-.08-5.81,0-1.31.09-1.54-.43-1.53-1.59,0-4.8,0-9.61,0-14.42Zm8.38-.15c0,2.31,0,4.63,0,6.94,0,.4.47,1.14.73,1.14,2.49,0,5,.22,7.38-.82,2.86-1.24,4.47-3.83,4.44-7.35a7.38,7.38,0,0,0-4.63-7.21c-2.37-1-4.81-.57-7.23-.59-.24,0-.68.74-.7,1.15C637,451,637,453.24,637,455.49Z"
        fill={props.theme?.cloudFlareLogo.main}
        transform="translate(-462.09 -298.32)"
      />
      <path
        d="M831.43,455.59c0-4.68.05-9.37,0-14,0-1.34.25-1.83,1.73-1.82,7.5.08,15,.07,22.5,0,1.32,0,1.77.34,1.67,1.66a28.33,28.33,0,0,0,0,4.12c.08,1.22-.31,1.62-1.57,1.6-4.5-.08-9,0-13.5,0-1.86,0-1.86,0-1.86,1.82,0,3,0,3,2.9,3,3.62,0,7.25.05,10.87,0,1.18,0,1.73.22,1.62,1.54a32,32,0,0,0,0,4.49c.06,1.1-.38,1.37-1.39,1.35-4.07,0-8.13,0-12.19-.06-1.35,0-1.88.26-1.84,1.73.09,3,0,3,3,3,4.25,0,8.5.05,12.75,0,1.33,0,1.75.39,1.67,1.7-.11,1.61-.07,3.24,0,4.87,0,.9-.28,1.17-1.19,1.17-7.93,0-15.87,0-23.81,0-1.17,0-1.32-.49-1.32-1.46,0-4.87,0-9.74,0-14.61Z"
        fill={props.theme?.cloudFlareLogo.main}
        transform="translate(-462.09 -298.32)"
      />
      <path
        d="M491.64,452.72a6.26,6.26,0,0,0-.75,0c-2.2.23-3.81-.16-5.27-2.32-2.21-3.3-7-3.81-10.4-1.69s-4.69,6.88-3.06,10.62a7.73,7.73,0,0,0,6.87,5,7.54,7.54,0,0,0,7.13-3.67,2.1,2.1,0,0,1,2.1-1.13c2.12.07,4.25.09,6.37,0,1.36-.06,1.49.47,1,1.52a18.12,18.12,0,0,1-12.76,11c-7.21,1.92-16.91-2.43-19.84-10.53-3.71-10.3,3.69-20.53,13.34-21.87,6.88-1,12.79.75,17.1,6.44a29.3,29.3,0,0,1,2.69,5.3c.43.93.06,1.36-1,1.31S492.83,452.72,491.64,452.72Z"
        fill={props.theme?.cloudFlareLogo.main}
        transform="translate(-462.09 -298.32)"
      />
      <path
        d="M614.78,450.67c0,3,.11,6,0,9-.28,6.12-3.82,10.9-10.24,12.39a19.21,19.21,0,0,1-12.4-1.33c-4.47-2-6.64-5.81-6.93-10.54s-.2-9.6-.23-14.41c0-1.92.11-3.84.17-5.76l5.73-.24c.37,0,.76,0,1.12,0,1.44-.22,1.93.26,1.9,1.8-.1,5.05-.07,10.11,0,15.17a13.79,13.79,0,0,0,.47,3.5,5.55,5.55,0,0,0,6.08,4.17c3.17-.26,5-1.88,5.43-4.92a17.67,17.67,0,0,0,.13-2.42c0-5.25,0-10.49,0-15.73,0-1.2.28-1.67,1.57-1.59,2,.11,4,.08,6,0,1.08,0,1.39.34,1.37,1.38-.06,3.19,0,6.37,0,9.56Z"
        fill={props.theme?.cloudFlareLogo.main}
        transform="translate(-462.09 -298.32)"
      />
      <path
        d="M774.29,471.63a15.38,15.38,0,0,0-2.8,0c-1.6.31-2.2-.57-2.69-1.83s-.81-2.94-1.78-3.57-2.65-.24-4-.25c-2.31,0-4.62.05-6.93,0a1.84,1.84,0,0,0-2,1.28c-.64,1.46-1,3.41-2.15,4.17s-3,.19-4.58.2h-2.63c-1.75,0-1.75,0-1.1-1.53q4.23-10,8.48-19.94c1.34-3.15,2.75-6.27,4-9.44a1.73,1.73,0,0,1,1.9-1.35c2.12.08,4.25.09,6.37,0,1,0,1.36.36,1.73,1.24q6.29,14.89,12.69,29.72c.53,1.24.1,1.36-1,1.3S775.48,471.63,774.29,471.63ZM761,459.3v-.1c.88,0,1.75,0,2.63,0s1.06-.38.73-1.16c-.93-2.25-1.83-4.51-2.76-6.75a3.68,3.68,0,0,0-.39-.58,2.8,2.8,0,0,0-.55.65q-.95,2.24-1.83,4.5c-.4,1-.75,2.08-1.13,3.12Z"
        fill={props.theme?.cloudFlareLogo.main}
        transform="translate(-462.09 -298.32)"
      />
      <path
        d="M671.46,455.79c0-4.81,0-9.61,0-14.41,0-1.36.42-1.66,1.7-1.65q11.14.09,22.3,0c1.18,0,1.66.24,1.57,1.52a44.78,44.78,0,0,0,0,5.05c0,1-.36,1.25-1.3,1.24-4.49,0-9,0-13.49,0-1.88,0-1.88,0-1.88,1.78,0,3.35,0,3.35,3.34,3.35s6.75.06,10.12,0c1.25,0,1.66.36,1.55,1.61a22.57,22.57,0,0,0,0,4.11c.16,1.45-.5,1.63-1.7,1.61-3.68-.05-7.37,0-11,0-2.47,0-2.24-.41-2.25,2.33s-.05,5.37,0,8.05c0,1-.27,1.33-1.26,1.3-2.06-.06-4.13-.07-6.18,0-1.15,0-1.5-.29-1.49-1.46C671.5,465.4,671.46,460.59,671.46,455.79Z"
        fill={props.theme?.cloudFlareLogo.main}
        transform="translate(-462.09 -298.32)"
      />
      <path
        d="M506.13,455.67c0-4.75,0-9.5,0-14.24,0-1.29.32-1.77,1.66-1.7a49,49,0,0,0,5.81,0c1.34-.08,1.52.48,1.51,1.62,0,6.93,0,13.86,0,20.79,0,2.19,0,1.85,2,1.83,4,0,8,0,12-.05,1.19,0,1.5.38,1.44,1.5-.09,1.69-.07,3.38,0,5.06,0,.91-.28,1.18-1.17,1.17-7.25,0-14.5,0-21.75,0-1.27,0-1.41-.53-1.4-1.57C506.15,465.28,506.13,460.47,506.13,455.67Z"
        fill={props.theme?.cloudFlareLogo.main}
        transform="translate(-462.09 -298.32)"
      />
      <path
        d="M709.41,455.68v-14c0-1.87,0-1.88,1.8-1.88s3.75.07,5.62,0c1.27-.07,1.56.39,1.55,1.6,0,6.92,0,13.85,0,20.78,0,1.94-.35,1.88,1.92,1.86,3.94,0,7.88,0,11.81,0,1.33,0,1.75.39,1.66,1.69a33.64,33.64,0,0,0,0,4.49c.06,1.1-.19,1.54-1.42,1.53q-10.77-.07-21.55,0c-1,0-1.38-.29-1.37-1.36.05-4.87,0-9.74,0-14.61Z"
        fill={props.theme?.cloudFlareLogo.main}
        transform="translate(-462.09 -298.32)"
      />
    </SvgIcon>
  );
});
