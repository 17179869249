import { ReturnIf } from "babel-plugin-transform-functional-return";

import { type OrgStoreState } from "stores/OrganizationStore";
import { type UserStoreState } from "stores/UserStore";

import { type Service, type ServicesContainer } from "utility/connections";

import { EKSKubernetesAppConfig } from "external/source/EKSKubernetes";
import { GKEKubernetesAppConfig } from "external/source/GKEKubernetes";

import { AmazonWebServicesAppConfig } from "./source/AmazonWebServices";
import { AzureAppConfig } from "./source/Azure";
import { BoxAppConfig } from "./source/Box";
import { GithubAppConfig } from "./source/Github";
import { GoogleCloudPlatformAppConfig } from "./source/GoogleCloudPlatform";
import { GoogleWorkspaceAppConfig } from "./source/GoogleWorkspace";
import { LookerAppConfig } from "./source/Looker";
import { Microsoft365AppConfig } from "./source/Microsoft365";
import { OktaAppConfig } from "./source/Okta";
import { SalesforceAppConfig } from "./source/Salesforce";
import { SnowflakeAppConfig } from "./source/Snowflake";

// -----------------------------------------------------------------------------

export const ExternalSources: ServicesContainer = {
  services: [
    // Keep in order of importance
    AmazonWebServicesAppConfig,
    GoogleCloudPlatformAppConfig,
    GoogleWorkspaceAppConfig,
    GithubAppConfig,
    EKSKubernetesAppConfig,
    GKEKubernetesAppConfig,
    SalesforceAppConfig,
    OktaAppConfig,
    Microsoft365AppConfig,
    // Cutoff for "available sources" preview
    // Anything below this line will appear in "all available"
    AzureAppConfig,
    BoxAppConfig,
    LookerAppConfig,
    SnowflakeAppConfig,
  ],
};

//  -----------------------------------------------------------------------------

export function getAvailableSources(
  sources: Service[],
  org: OrgStoreState,
  user: UserStoreState
): Service[] {
  return sources.filter((s) => {
    ReturnIf(s.featureFlag, user.user.features & (s.featureFlag as number));
    ReturnIf(!s.internalOnly, true);

    //
    return org.organization.org_isinternal;
  });
}
