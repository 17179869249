import { memo } from "react";
import { ObjectInspector, chromeDark, chromeLight } from "react-inspector";

import styled from "styled-components";

import { type ServerFindingWithType } from "data/finding";
import { type FOTS } from "data/typescript";

import { useThemeModeIsLight } from "theme/index";

// -----------------------------------------------------------------------------

export const RawDetails = memo(({ finding }: Props) => {
  const themeModeIsLight = useThemeModeIsLight();

  //
  return (
    <>
      {Object.keys(finding.details).length > 0 ? (
        <Container
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <ObjectInspector
            theme={getTheme(themeModeIsLight)}
            data={finding.details}
            expandLevel={99}
          />
        </Container>
      ) : (
        "No details."
      )}
    </>
  );
});

// -----------------------------------------------------------------------------

function getTheme(themeModeIsLight: boolean): FOTS {
  return {
    ...(themeModeIsLight ? chromeLight : chromeDark),
    ...{ BASE_BACKGROUND_COLOR: "none" },
  };
}

// -----------------------------------------------------------------------------

const Container = styled.div`
  * {
    font-size: 1rem;
    line-height: 1.5;
  }
`;

// -----------------------------------------------------------------------------

interface Props {
  finding: ServerFindingWithType;
  depth?: number;
}
