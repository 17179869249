import { memo, useState } from "react";

import { ThrowIf } from "babel-plugin-transform-functional-return";
import { useTheme } from "styled-components";

import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { type FOTS } from "data/typescript";

import { useOrganizationStore } from "stores/OrganizationStore";

import { asyncOrSwim } from "utility/async_or_swim";
import { authenticatedGetFetch } from "utility/fetch/authenticated";
import { notifyError } from "utility/notify";

import { ADFSSAMLSSOIcon } from "Component/Icon/SSO/ADFSSAML";
import { ADPOIDCSSOIcon } from "Component/Icon/SSO/ADPOIDC";
import { Auth0SAMLSSOIcon } from "Component/Icon/SSO/Auth0SAML";
import { AzureSAMLSSOIcon } from "Component/Icon/SSO/AzureSAML";
import { CasSAMLSSOIcon } from "Component/Icon/SSO/CasSAML";
import { ClassLinkSAMLSSOIcon } from "Component/Icon/SSO/ClassLinkSAML";
import { CloudflareSAMLSSOIcon } from "Component/Icon/SSO/CloudflareSAML";
import { CyberArkSAMLSSOIcon } from "Component/Icon/SSO/CyberArkSAML";
import { DuoSAMLSSOICon } from "Component/Icon/SSO/DuoSAML";
import { GenericOIDCSSOIcon } from "Component/Icon/SSO/GenericOIDC";
import { GenericSAMLSSOIcon } from "Component/Icon/SSO/GenericSAML";
import { GoogleSAMLSSOIcon } from "Component/Icon/SSO/GoogleSAML";
import { JumpCloudSAMLSSOIcon } from "Component/Icon/SSO/JumpCloudSAML";
import { KeycloakSAMLSSOIcon } from "Component/Icon/SSO/KeycloakSAML";
import { MiniOrangeSAMLSSOIcon } from "Component/Icon/SSO/MiniOrangeSAML";
import { NetIQSAMLSSOIcon } from "Component/Icon/SSO/NetIQSAML";
import { OktaSAMLSSOIcon } from "Component/Icon/SSO/OktaSAML";
import { OneLoginSAMLSSOIcon } from "Component/Icon/SSO/OneLoginSAML";
import { OracleSAMLSSOIcon } from "Component/Icon/SSO/OracleSAML";
import { PingFederateSAMLSSOIcon } from "Component/Icon/SSO/PingFederateSAML";
import { PingOneSAMLSSOIcon } from "Component/Icon/SSO/PingOneSAML";
import { SalesforceSAMLSSOIcon } from "Component/Icon/SSO/SalesforceSAML";
import { SimpleSamlPhpSAMLSSOIcon } from "Component/Icon/SSO/SimpleSamlPhpSAML";
import { VMwareSAMLSSOIcon } from "Component/Icon/SSO/VMwareSAML";

import { SettingsGeneralSSOUpdateLoginMethodModal as UpdateLoginMethodModal } from "./UpdateLoginMethodModal";

// -----------------------------------------------------------------------------

const ConnectionIcon: Record<string, Function> = {
  // GoogleOAuth: no longer an option
  // LastPassSAML: no longer an option
  // MagicLink: no icon
  // MicrosoftOAuth: no longer an option
  // ShibbolethSAML: no longer an option

  ADFSSAML: ADFSSAMLSSOIcon,
  ADPOIDC: ADPOIDCSSOIcon,
  Auth0SAML: Auth0SAMLSSOIcon,
  AzureSAML: AzureSAMLSSOIcon,
  CASSAML: CasSAMLSSOIcon,
  ClassLinkSAML: ClassLinkSAMLSSOIcon,
  CloudflareSAML: CloudflareSAMLSSOIcon,
  CyberArkSAML: CyberArkSAMLSSOIcon,
  DuoSAML: DuoSAMLSSOICon,
  GenericOIDC: GenericOIDCSSOIcon,
  GenericSAML: GenericSAMLSSOIcon,
  GoogleSAML: GoogleSAMLSSOIcon,
  JumpCloudSAML: JumpCloudSAMLSSOIcon,
  KeycloakSAML: KeycloakSAMLSSOIcon,
  MiniOrangeSAML: MiniOrangeSAMLSSOIcon,
  NetIqSAML: NetIQSAMLSSOIcon,
  OktaSAML: OktaSAMLSSOIcon,
  OneLoginSAML: OneLoginSAMLSSOIcon,
  OracleSAML: OracleSAMLSSOIcon,
  PingFederateSAML: PingFederateSAMLSSOIcon,
  PingOneSAML: PingOneSAMLSSOIcon,
  SalesforceSAML: SalesforceSAMLSSOIcon,
  SimpleSamlPhpSAML: SimpleSamlPhpSAMLSSOIcon,
  VMwareSAML: VMwareSAMLSSOIcon,
};

// -----------------------------------------------------------------------------

export const SettingsGeneralSSO = memo(({ disabled }: Props) => {
  const [organizationStore] = useOrganizationStore();
  const theme = useTheme();

  //
  const [isLoading, setIsLoading] = useState(false);
  const [showUpdateLoginMethodModal, setShowUpdateLoginMethodModal] =
    useState(false);

  //
  function redirectToWorkOSUrl() {
    asyncOrSwim(
      async () => {
        setIsLoading(true);
        const requestPayload = await authenticatedGetFetch(
          `api/organization/sso_admin`
        );
        ThrowIf(
          requestPayload.error,
          new Error(requestPayload.message ?? "There was an error")
        );
        setIsLoading(false);

        //
        // nosemgrep: javascript.browser.security.open-redirect.js-open-redirect
        window.location.href = requestPayload.admin_portal_url;
      },
      (error) => {
        console.error(error);
        setIsLoading(false);
        notifyError("There was an error configuring SSO");
      }
    );
  }

  function openUpdateLoginMethodModal() {
    setShowUpdateLoginMethodModal(true);
  }

  function closeUpdateLoginMethodModal() {
    setShowUpdateLoginMethodModal(false);
  }

  //
  const isConfirmed = !!organizationStore.organization.dcv_isvalid;
  const isSSOEnabled = true;
  const connections = Object.keys(
    (organizationStore.organization.workos_conn_id_dict ??
      []) as unknown as Record<string, string>
  );
  //
  return (
    <>
      <UpdateLoginMethodModal
        open={showUpdateLoginMethodModal}
        onClose={closeUpdateLoginMethodModal}
      />

      <Paper
        elevation={1}
        sx={
          isConfirmed && !disabled
            ? SSOSectionAvailableSX
            : SSOSectionUnavailableSX
        }
      >
        <Typography variant="h6" sx={TextSX} fontWeight="bold">
          Single Sign On (SSO)
        </Typography>

        <Typography variant="body1" sx={TextSX}>
          Our SSO solution is easy to set up and use, and it&apos;s compatible
          with a wide range of software applications. Configure your
          company&apos;s specific SSO provider below. Domain Control Validation
          (DCV) must be enabled first.
        </Typography>

        {isSSOEnabled ? (
          <>
            <Typography variant="body1" fontWeight="bold" sx={ConnectedTextSX}>
              Configured SSO Providers:
            </Typography>
            <Box sx={ConnectedServicesSX}>
              {connections.length > 0 ? (
                connections.map((connection) => {
                  const Icon: FOTS = ConnectionIcon[connection];

                  //
                  return (
                    <Box sx={ConnectedServiceSX} key={connection}>
                      {Icon ? (
                        <Icon sx={ConnectedServiceIconSX} theme={theme} />
                      ) : (
                        <Box sx={ConnectedServiceIconSX}>{connection}</Box>
                      )}
                    </Box>
                  );
                })
              ) : (
                <Typography
                  sx={{ marginLeft: "1vw", marginTop: "2vh" }}
                  variant="body1"
                >
                  No services configured.
                </Typography>
              )}
            </Box>
          </>
        ) : null}

        <Stack direction="row" spacing={2}>
          <LoadingButton
            variant={isSSOEnabled ? "outlined" : "contained"}
            loading={isLoading}
            onClick={redirectToWorkOSUrl}
            disabled={!isConfirmed || disabled}
          >
            {isSSOEnabled ? "Edit SSO Configuration" : "Enable SSO"}
          </LoadingButton>
          <Button
            variant="outlined"
            onClick={openUpdateLoginMethodModal}
            disabled={!isConfirmed || disabled}
          >
            Update organization login method
          </Button>
        </Stack>
      </Paper>
    </>
  );
});

// -----------------------------------------------------------------------------

const SSOSectionAvailableSX = {
  mt: 2,
  p: 2,
};

const SSOSectionUnavailableSX = {
  ...SSOSectionAvailableSX,
  opacity: 0.5,
  pointerEvents: "none",
};

const TextSX = { mb: 1 };

const ConnectedTextSX = { mt: 1, mb: 1 };

const ConnectedServicesSX = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  mb: 2,
  ml: -2,
  mt: -2,
};

const ConnectedServiceSX = {
  borderColor: "primary.border",
  alignItems: "center",
  border: "1px solid",
  borderRadius: 1,
  display: "flex",
  flex: "0 0",
  height: "60px",
  ml: 2,
  mt: 2,
  padding: 1,
  width: "120px",
};

const ConnectedServiceIconSX = {
  height: "20px",
  textAlign: "center",
  textOverflow: "ellipsis",
  width: "100px",
};

// -----------------------------------------------------------------------------

interface Props {
  disabled: boolean;
}
