import { redirectToGithubOauthUrl } from "./connect_handler";
import { GithubCoverImage } from "./CoverImage";

// -----------------------------------------------------------------------------

export const GithubAppConfig = {
  name: "github",
  label: "GitHub",
  coverImage: GithubCoverImage,
  onConnect: redirectToGithubOauthUrl,
  searchTerms: [],
};
