import { memo } from "react";

import SvgIcon from "@mui/material/SvgIcon";

// -----------------------------------------------------------------------------

export const WebhookCoverImage = memo((props: object) => {
  return (
    <SvgIcon viewBox="0 0 431.45 120.114" {...props}>
      <g>
        <g id="OvHZFw.tif">
          <g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#C73A63"
              d="M60.467,50.345c-5.367,9.022-10.509,17.759-15.758,26.43
				c-1.348,2.226-2.015,4.039-0.938,6.869c2.973,7.817-1.221,15.424-9.104,17.489c-7.434,1.948-14.677-2.938-16.152-10.897
				c-1.307-7.045,4.16-13.951,11.927-15.052c0.651-0.093,1.315-0.104,2.409-0.186c3.806-6.382,7.705-12.919,11.815-19.812
				c-7.431-7.389-11.854-16.027-10.875-26.73c0.692-7.566,3.667-14.104,9.105-19.463C53.311-1.269,69.2-2.931,81.463,4.946
				c11.778,7.566,17.172,22.304,12.574,34.918c-3.467-0.94-6.958-1.888-10.796-2.929c1.444-7.013,0.376-13.311-4.354-18.706
				c-3.125-3.562-7.135-5.429-11.695-6.117c-9.141-1.381-18.116,4.492-20.779,13.464C43.39,35.759,47.965,44.077,60.467,50.345z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill={props.theme?.webhookLogo.main}
              d="M75.794,39.676c3.781,6.67,7.62,13.441,11.425,20.15
				c19.232-5.95,33.732,4.696,38.934,16.094c6.283,13.768,1.988,30.075-10.352,38.569c-12.666,8.72-28.684,7.23-39.906-3.971
				c2.86-2.394,5.734-4.799,8.805-7.368c11.084,7.179,20.778,6.841,27.975-1.66c6.137-7.252,6.004-18.065-0.311-25.165
				c-7.288-8.193-17.05-8.443-28.85-0.578c-4.895-8.684-9.875-17.299-14.615-26.046c-1.598-2.948-3.363-4.658-6.965-5.282
				c-6.016-1.043-9.9-6.209-10.133-11.997c-0.229-5.724,3.143-10.898,8.414-12.914c5.221-1.997,11.348-0.385,14.86,4.054
				c2.87,3.627,3.782,7.709,2.272,12.182C76.927,36.991,76.383,38.198,75.794,39.676z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#4A4A4A"
              d="M84.831,94.204c-7.605,0-15.238,0-23.152,0
				c-2.219,9.127-7.012,16.496-15.271,21.182c-6.42,3.642-13.34,4.877-20.705,3.688c-13.56-2.187-24.648-14.394-25.624-28.14
				c-1.105-15.571,9.598-29.412,23.864-32.521c0.985,3.577,1.98,7.188,2.965,10.756C13.819,75.847,9.289,84.261,12.952,94.782
				c3.225,9.259,12.385,14.334,22.331,12.371c10.157-2.004,15.278-10.445,14.653-23.992c9.629,0,19.266-0.1,28.896,0.049
				c3.76,0.059,6.663-0.331,9.496-3.646c4.664-5.455,13.248-4.963,18.271,0.189c5.133,5.265,4.887,13.737-0.545,18.78
				c-5.241,4.866-13.521,4.606-18.424-0.637C86.622,96.815,85.828,95.532,84.831,94.204z"
            />
          </g>
        </g>
        <g>
          <path
            fill={props.theme?.webhookLogo.main}
            d="M158.292,47.382h8.894l5.109,24.158l5.231-24.158h9.141l-9.725,33.545h-9.017l-5.264-24.436l-5.324,24.436
			h-9.109l-9.417-33.545h9.417l5.232,24.066L158.292,47.382z"
          />
          <path
            fill={props.theme?.webhookLogo.main}
            d="M211.077,48.062c2.34,1.048,4.272,2.702,5.797,4.962c1.374,1.993,2.265,4.305,2.673,6.936
			c0.235,1.541,0.332,3.76,0.288,6.657h-24.432c0.137,3.365,1.306,5.725,3.509,7.078c1.339,0.842,2.951,1.262,4.836,1.262
			c1.998,0,3.62-0.513,4.87-1.538c0.682-0.555,1.283-1.323,1.805-2.309h8.955c-0.236,1.99-1.32,4.012-3.252,6.062
			c-3.006,3.263-7.211,4.894-12.62,4.894c-4.464,0-8.403-1.376-11.815-4.127c-3.413-2.752-5.119-7.229-5.119-13.431
			c0-5.811,1.54-10.267,4.621-13.367c3.08-3.101,7.078-4.651,11.996-4.651C206.107,46.489,208.737,47.014,211.077,48.062z
			 M197.956,55.639c-1.24,1.277-2.02,3.008-2.338,5.192h15.111c-0.16-2.329-0.938-4.096-2.336-5.301
			c-1.398-1.206-3.133-1.809-5.202-1.809C200.94,53.722,199.195,54.36,197.956,55.639z"
          />
          <path
            fill={props.theme?.webhookLogo.main}
            d="M252.194,51.413c2.51,3.181,3.764,7.284,3.764,12.311c0,5.211-1.237,9.53-3.713,12.956
			c-2.475,3.427-5.928,5.14-10.361,5.14c-2.785,0-5.021-0.554-6.713-1.662c-1.011-0.656-2.104-1.805-3.278-3.446v4.216h-8.648
			V35.626h8.771v16.126c1.115-1.559,2.344-2.749,3.686-3.57c1.591-1.025,3.614-1.538,6.07-1.538
			C246.209,46.644,249.685,48.233,252.194,51.413z M244.863,71.848c1.262-1.825,1.894-4.226,1.894-7.201
			c0-2.38-0.311-4.35-0.932-5.908c-1.179-2.955-3.351-4.432-6.516-4.432c-3.207,0-5.41,1.446-6.609,4.339
			c-0.621,1.539-0.932,3.529-0.932,5.971c0,2.872,0.642,5.252,1.925,7.14c1.282,1.888,3.236,2.831,5.864,2.831
			C241.833,74.587,243.601,73.675,244.863,71.848z"
          />
          <path
            fill={props.theme?.webhookLogo.main}
            d="M284.465,47.567c1.684,0.719,3.068,1.817,4.15,3.296c0.92,1.253,1.481,2.542,1.686,3.866
			s0.307,3.485,0.307,6.483v19.714h-8.955V60.5c0-1.808-0.307-3.267-0.918-4.375c-0.793-1.561-2.301-2.342-4.52-2.342
			c-2.303,0-4.048,0.775-5.24,2.326c-1.191,1.551-1.787,3.764-1.787,6.64v18.178h-8.771V35.718h8.771v16.003
			c1.268-1.948,2.733-3.308,4.396-4.077c1.666-0.77,3.418-1.154,5.258-1.154C280.905,46.489,282.779,46.849,284.465,47.567z"
          />
          <path
            fill={props.theme?.webhookLogo.main}
            d="M324.845,76.823c-2.831,3.495-7.13,5.242-12.895,5.242c-5.766,0-10.063-1.747-12.896-5.242
			c-2.83-3.495-4.246-7.702-4.246-12.623c0-4.837,1.416-9.029,4.246-12.576c2.832-3.546,7.13-5.319,12.896-5.319
			c5.765,0,10.063,1.773,12.895,5.319c2.832,3.547,4.247,7.739,4.247,12.576C329.092,69.121,327.677,73.328,324.845,76.823z
			 M317.829,71.976c1.374-1.823,2.062-4.415,2.062-7.775c0-3.359-0.688-5.946-2.062-7.759c-1.375-1.812-3.344-2.72-5.908-2.72
			c-2.565,0-4.54,0.907-5.925,2.72s-2.077,4.399-2.077,7.759c0,3.36,0.692,5.952,2.077,7.775s3.359,2.734,5.925,2.734
			C314.485,74.71,316.454,73.799,317.829,71.976z"
          />
          <path
            fill={props.theme?.webhookLogo.main}
            d="M361.629,76.823c-2.83,3.495-7.129,5.242-12.895,5.242s-10.062-1.747-12.893-5.242
			c-2.832-3.495-4.248-7.702-4.248-12.623c0-4.837,1.416-9.029,4.248-12.576c2.83-3.546,7.127-5.319,12.893-5.319
			s10.064,1.773,12.895,5.319c2.832,3.547,4.248,7.739,4.248,12.576C365.877,69.121,364.461,73.328,361.629,76.823z M354.614,71.976
			c1.373-1.823,2.062-4.415,2.062-7.775c0-3.359-0.689-5.946-2.062-7.759c-1.375-1.812-3.344-2.72-5.908-2.72
			c-2.565,0-4.541,0.907-5.926,2.72s-2.076,4.399-2.076,7.759c0,3.36,0.691,5.952,2.076,7.775s3.36,2.734,5.926,2.734
			C351.27,74.71,353.239,73.799,354.614,71.976z"
          />
          <path
            fill={props.theme?.webhookLogo.main}
            d="M370.223,35.718h8.617v24.472l11.062-12.653h10.907l-12.024,12.496l12.489,20.895H390.6l-8.127-14.332
			l-3.633,3.776v10.556h-8.617V35.718z"
          />
          <path
            fill={props.theme?.webhookLogo.main}
            d="M409.661,70.217c0.186,1.56,0.588,2.668,1.206,3.324c1.093,1.169,3.112,1.754,6.06,1.754
			c1.732,0,3.108-0.256,4.129-0.77c1.021-0.513,1.531-1.282,1.531-2.308c0-0.985-0.41-1.733-1.233-2.247
			c-0.822-0.513-3.881-1.395-9.176-2.646c-3.812-0.943-6.497-2.124-8.058-3.539c-1.558-1.396-2.338-3.405-2.338-6.032
			c0-3.098,1.219-5.76,3.655-7.986c2.437-2.226,5.864-3.339,10.285-3.339c4.194,0,7.612,0.836,10.255,2.508
			c2.642,1.673,4.158,4.561,4.549,8.664h-8.771c-0.124-1.129-0.442-2.021-0.956-2.678c-0.968-1.189-2.614-1.785-4.939-1.785
			c-1.912,0-3.275,0.298-4.088,0.893c-0.812,0.596-1.219,1.293-1.219,2.093c0,1.006,0.432,1.734,1.296,2.185
			c0.864,0.473,3.916,1.283,9.159,2.432c3.492,0.821,6.111,2.062,7.855,3.724c1.724,1.683,2.586,3.785,2.586,6.309
			c0,3.324-1.24,6.038-3.717,8.141c-2.478,2.103-6.307,3.154-11.486,3.154c-5.283,0-9.185-1.113-11.702-3.339
			c-2.519-2.227-3.778-5.062-3.778-8.51H409.661z"
          />
        </g>
      </g>
    </SvgIcon>
  );
});
