import { memo } from "react";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

// -----------------------------------------------------------------------------

export const AssetInventoryFilterBar = memo(({ filter, onChange }: Props) => {
  return (
    <FormControl variant="outlined" size="small">
      <InputLabel id="filter-select-label">Filter By Type</InputLabel>
      <Select
        labelId="filter-select-label"
        id="filter-select"
        value={filter}
        onChange={(event: { target: { value: any } }) =>
          onChange(event.target.value)
        }
        label="Filter By Type"
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value="AWSAccount">AWS Account</MenuItem>
        <MenuItem value="AWSEC2Instance">AWS EC2 Instance</MenuItem>
        <MenuItem value="AWSIAMAccessKey">AWS IAM Access Key</MenuItem>
        <MenuItem value="AWSIAMRole">AWS IAM Role</MenuItem>
        <MenuItem value="AWSIAMUser">AWS IAM User</MenuItem>
        <MenuItem value="GitHubOrganization">GitHub Organization</MenuItem>
        <MenuItem value="GitHubRepository">GitHub Repository</MenuItem>
        <MenuItem value="GoogleCustomer">Google Customer</MenuItem>
        <MenuItem value="GoogleGroup">Google Group</MenuItem>
        <MenuItem value="GoogleUser">Google User</MenuItem>
        <MenuItem value="OktaApplication">Okta Application</MenuItem>
        <MenuItem value="OktaOrganization">Okta Organization</MenuItem>
        <MenuItem value="OktaUser">Okta User</MenuItem>
        <MenuItem value="OktaUserFactor">Okta User Factor</MenuItem>
      </Select>
    </FormControl>
  );
});

// -----------------------------------------------------------------------------

interface Props {
  filter: string;
  onChange: Function;
}
