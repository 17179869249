// ref: http://emailregex.com/
// https://www.ietf.org/rfc/rfc5322.txt
const ValidEmailRegex: RegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// -----------------------------------------------------------------------------

export function isValidEmail(emailAddress: string): boolean {
  return ValidEmailRegex.test(emailAddress);
}
