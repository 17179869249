import { createContext } from "react";

import { type ServerFindingWithType } from "data/finding";
import { ViewMode } from "data/view_mode";

import { type FilterOption, type FindingEntry } from "./data";

// -----------------------------------------------------------------------------

export const FindingsContext = createContext<IFindingContext>({
  loading: false,
  loadingError: false,

  mode: "normal",

  action: false,

  findings: [],
  findingsTotal: 0,

  filteredFindings: [],
  filteredFindingsTotal: 0,

  updateFindings: () => {},

  selectedFindings: [],
  setSelectedFindings: () => {},

  filters: [],
  setFilters: () => {},

  tempFilters: [],
  setTempFilters: () => {},

  sort: ["severity", false],
  setSort: () => {},

  viewMode: ViewMode.Desktop,
});

// -----------------------------------------------------------------------------

export interface IFindingContext {
  loading: boolean;
  loadingError: boolean;

  mode: "normal" | "deeplink";

  action:
    | "acknowledging"
    | "ignoring"
    | "unignoring"
    | "editing-severity"
    | false;

  findings: ServerFindingWithType[];
  findingsTotal: number;

  filteredFindings: FindingEntry[];
  filteredFindingsTotal: number;

  updateFindings: (updatedFindings: ServerFindingWithType[]) => void;

  selectedFindings: ServerFindingWithType[];
  setSelectedFindings: (newSelectedFindings: ServerFindingWithType[]) => void;

  filters: FilterOption[];
  setFilters: (newFilters: FilterOption[]) => void;

  tempFilters: FilterOption[];
  setTempFilters: (newTempFilters: FilterOption[]) => void;

  sort: FindingsSortBy;
  setSort: (newSortBy: FindingsSortByColumn, sortAsc: boolean) => void;

  viewMode: ViewMode;
}

export type FindingsSortBy = [FindingsSortByColumn, boolean];

export type FindingsSortByColumn =
  | "asset"
  | "event_name"
  | "name"
  | "severity"
  | "source"
  | "state";
