import { memo, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { type State } from "simple-markdown";

import Grid from "@mui/material/Grid";

import { useFindingStore } from "stores/FindingStore";
import { SubmitState } from "stores/lib";

import { usePageTitle } from "effect/use_page_title";

import { Page } from "Component/Page";
import { PageContent } from "Component/PageContent";
import { PageHeader } from "Component/PageHeader";
import { PageSubTitle } from "Component/PageSubTitle";
import { PageTitle } from "Component/PageTitle";

import { Charts } from "./Charts";
import { ThirdPartyAppFindingsDataTable as Table } from "./Table";

// -----------------------------------------------------------------------------

export const ThirdPartyAppFindingsPage = memo(() => {
  usePageTitle("Reporting → Third-Party App Findings");

  const [page, setPage] = useState(1);
  const [findingsStore, findingsActions] = useFindingStore();

  const isLoading =
    findingsStore.loadState === SubmitState.STARTED ||
    findingsStore.loadState === SubmitState.STARTED_AGAIN;

  useEffectOfLoadingData(
    page,
    setPage,
    findingsStore,
    findingsActions,
    useMemoOfURLSearchParams()
  );

  //
  return (
    <Page>
      <PageHeader>
        <PageTitle>Third-Party Application Findings</PageTitle>
        <PageSubTitle>
          This dashboard shows all findings that are related to third-party
          applications. Resolution of these findings may be the responsibility
          of the third-party application vendor.
        </PageSubTitle>
      </PageHeader>

      <PageContent>
        <Grid container sx={{ display: "flex", flex: "1", gap: "24px" }}>
          <Grid
            item
            sx={{ display: "flex", flex: "1", flexDirection: "column" }}
          >
            <Table data={findingsStore.findings} isLoading={isLoading} />
          </Grid>
          <Charts
            findings={findingsStore.findings}
            loadingState={findingsStore.loadState}
          />
        </Grid>
      </PageContent>
    </Page>
  );
});

// -----------------------------------------------------------------------------

function useMemoOfURLSearchParams() {
  const [searchParams] = useSearchParams();

  //
  return useMemo(() => searchParams, [searchParams]);
}

function useEffectOfLoadingData(
  page: number,
  setPage: Function,
  findingStore: State,
  findingsActions: any,
  params: URLSearchParams
) {
  const trigger = useMemo(() => {
    const triggerableParams = params;
    if (triggerableParams.get("page") === "0") {
      setPage(1);
    }

    triggerableParams.delete("finding");
    triggerableParams.delete("page");
    triggerableParams.set("tag", "third-party-apps");
    triggerableParams.sort();

    return triggerableParams.toString();
  }, [params, setPage]);

  const effect = () => {
    const newPage = params.get("page") === "0" ? 1 : page;
    params.set("page", String(newPage));
    findingsActions.load(newPage, params);
  };

  //
  useEffect(effect, [page, trigger, findingStore.loadDeeplinkFindings]); // eslint-disable-line
}
