import DayJS, { extend } from "dayjs";
import duration from "dayjs/plugin/duration";
import isBetween from "dayjs/plugin/isBetween";
import relativeTime from "dayjs/plugin/relativeTime";

// -----------------------------------------------------------------------------

extend(relativeTime);
extend(duration);
extend(isBetween);

// -----------------------------------------------------------------------------

export const dayjs = DayJS;
