import { memo } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useOrganizationStore } from "stores/OrganizationStore";

import { usePageTitle } from "effect/use_page_title";

import { Page } from "Component/Page";
import { PageContent } from "Component/PageContent";
import { PageHeader } from "Component/PageHeader";
import { PageHeaderBackButton } from "Component/PageHeaderBackButton";
import { PageTitle } from "Component/PageTitle";
import { ServiceConnectionInstructionPhase } from "Component/ServiceConnectionInstructionPhase";
import { ServiceConnectionPhase } from "Component/ServiceConnectionPhase";
import { ServiceConnectionPhases } from "Component/ServiceConnectionPhases";

import { GithubConfig } from "./config";

// -----------------------------------------------------------------------------

export const GithubConnectPage = memo(() => {
  //
  usePageTitle(`Environment → Sources → Github → Connect`);

  //
  const [organizationStore] = useOrganizationStore();

  const redirectUrl = `${
    GithubConfig.publicLink ?? ""
  }/installations/new?${new URLSearchParams({
    redirect_uri: encodeURI(
      `${window.location.origin}/environment/sources/github/oauth2`
    ),
    state: organizationStore.uuid,
  }).toString()}`;

  //
  return (
    <Page>
      <PageHeader>
        <PageHeaderBackButton to="/environment/sources">
          Back to Sources
        </PageHeaderBackButton>
        <PageTitle>GitHub Connection</PageTitle>
      </PageHeader>

      <PageContent>
        <ServiceConnectionPhases>
          <ServiceConnectionInstructionPhase>
            <Typography variant="body1">
              Connect your ThreatKey account to Github for comprehensive
              security monitoring.
            </Typography>
          </ServiceConnectionInstructionPhase>

          <ServiceConnectionPhase disabled={false}>
            <Stack spacing={1}>
              <Typography variant="body1">
                To make a connection, you will need to install the ThreatKey app
                on your GitHub organization. You will be redirected to GitHub
                where you can confirm the installation and select which
                repositories the ThreatKey app can access. ThreatKey will only
                be able to access the repositories you select. ThreatKey will
                not be able to access any other repositories.
              </Typography>
              <Box>
                <Button
                  variant="contained"
                  LinkComponent="a"
                  href={redirectUrl}
                >
                  Connect to Github
                </Button>
              </Box>
            </Stack>
          </ServiceConnectionPhase>
        </ServiceConnectionPhases>
      </PageContent>
    </Page>
  );
});
