const CurrencyFormatter: Record<string, Intl.NumberFormat> = {};

// -----------------------------------------------------------------------------

export function toCurrency(amount: number, currency: string = "USD"): string {
  const currencyProcessed = String(currency || "USD").toUpperCase();
  const amountRaw = amount || 0;
  const amountProcessed = isFractionalCurrency(currencyProcessed)
    ? Math.max(amountRaw / 100, 0)
    : amountRaw;

  //
  return getCurrencyFormatter(currencyProcessed).format(amountProcessed);
}

function isFractionalCurrency(currency: string): boolean {
  switch (currency?.toLocaleUpperCase()) {
    case "USD": // only currency accepted
      return true;

    case "JPY":
      return false;

    default:
      throw new Error("Unsupported currency");
  }
}

function getCurrencyFormatter(currency: string): Intl.NumberFormat {
  CurrencyFormatter[currency] =
    CurrencyFormatter[currency] ||
    new Intl.NumberFormat(undefined, {
      style: "currency",
      currency,
      // not in the current typing
      trailingZeroDisplay: "stripIfInteger",
    } as any);

  //
  return CurrencyFormatter[currency];
}
