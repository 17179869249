import { memo } from "react";

import styled from "styled-components";

import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// -----------------------------------------------------------------------------

export const SettingsDetails = memo(
  ({ name, setName, slug, setSlug, disabled }: Props) => {
    return (
      <Container
        elevation={1}
        sx={{ p: 2 }}
        className={disabled ? "disabled" : undefined}
      >
        <Typography variant="h6" sx={{ mb: 1 }} fontWeight="bold">
          Details
        </Typography>

        <TextFieldStyled
          fullWidth
          label="Organization Name"
          variant="outlined"
          value={name}
          onChange={(event) => setName(event.target.value)}
          disabled={disabled}
        />

        <TextFieldStyled
          fullWidth
          label="Organization Slug"
          variant="outlined"
          value={slug}
          onChange={(event) => setSlug(event.target.value)}
          disabled={disabled}
        />
      </Container>
    );
  }
);

// -----------------------------------------------------------------------------

const Container = styled(Paper)`
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

const TextFieldStyled = styled(TextField)`
  // @REMOVE ME
  & .MuiOutlinedInput-notchedOutline {
    padding: 0 8 px !important;
    border-width: 1 px !important;
  }

  & + & {
    margin-top: 16px;
  }
`;

// -----------------------------------------------------------------------------

interface Props {
  slug: string;
  setSlug: Function;
  name: string;
  setName: Function;
  disabled: boolean;
}
