import { type CSSProperties } from "react";

import {
  type ReactElement,
  defaultRules,
  htmlTag,
  inlineRegex,
  outputFor,
  parserFor,
  reactElement,
  sanitizeUrl,
  unescapeUrl,
} from "simple-markdown";

import Link from "@mui/material/Link";

// This plugin overrides the default link parser and adds the ability to add the target attribute to
// anchor tags. Almost all of the code below is a copy-paste from the internals of simple markdown
// which the regex piece on line 22 being updated to include the target functionality.

// Usage: [text](url){target=_blank} or _self or any other acceptable target value
// All external link default to target _blank. Disable this manually by:
// [text](url){!target}
// -----------------------------------------------------------------------------

const ExternalLink: RegExp = /^https?:\/\//i;
const LinkInside = "(?:\\[[^\\]]*\\]|[^\\[\\]]|\\](?=[^\\[]*\\]))*";
const LinkHrefAndTitle =
  "\\s*<?((?:\\([^)]*\\)|[^\\s\\\\]|\\\\.)*?)>?(?:\\s+['\"]([\\s\\S]*?)['\"])?\\s*";

const TargetBlankLink = {
  order: defaultRules.em.order - 0.5,

  match: inlineRegex(
    new RegExp(
      `^\\[(${LinkInside})\\]\\(${LinkHrefAndTitle}\\)(\\{(!)?target(="?([^"}]+)"?)?\\})?`
    )
  ),

  parse: function (capture: any, parse: Function, state: any) {
    const target = unescapeUrl(capture[2]); // plugin-speak for URL
    const linkTargetIsDisabled = capture[5] !== undefined;
    const linkIsExternal = ExternalLink.test(target.trim());
    const linkTarget = linkTargetIsDisabled
      ? undefined
      : linkIsExternal
        ? "_blank"
        : capture[7];

    return {
      target,
      content: parse(capture[1], state),
      title: capture[3],
      link_target: linkTarget,
    };
  },

  react: function (node: any, output: Function, state: any) {
    return (
      <Link
        href={sanitizeUrl(node.target) ?? ""}
        title={node.title}
        target={node.link_target}
        rel="noopener noreferrer"
      >
        {output(node.content, state)}
      </Link>
    );
  },

  html: function (node: any, output: Function, state: any) {
    return htmlTag("a", output(node.content, state), {
      href: sanitizeUrl(node.target),
      title: node.title,
      target: node.link_target,
      rel: "noopener noreferrer",
    });
  },
};

const InlineCode = {
  order: defaultRules.text.order - 0.5,
  match: inlineRegex(/^(`)([^`]+?)\1/),
  parse: (capture: any) => ({
    content: capture[2],
  }),
  react: function (node: any, output: Function, state: any): ReactElement {
    const theme = state.theme;
    const style: CSSProperties = {
      ...theme.palette?.markdownInlineCode,
      backgroundColor: theme.palette?.markdownInlineCode.background,
      color: theme.palette?.markdownInlineCode.main,
      background: undefined,
      main: undefined,
    };

    return reactElement("code", state.key, {
      children: node.content,
      style,
    });
  },
};

const rules = {
  ...defaultRules,
  link: TargetBlankLink,
  inlineCode: InlineCode,
};

const markdownParser = parserFor(rules);

// -----------------------------------------------------------------------------

export const parseMarkdown = function (
  source: string,
  params: any = { inline: false }
) {
  return markdownParser(source, params);
};

export const reactOutput = outputFor(rules, "react");
