import {
  type MouseEventHandler,
  type RefObject,
  useRef,
  useState,
  memo,
} from "react";

import { ReturnIf } from "babel-plugin-transform-functional-return";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { copyElementContentsToClipboard } from "utility/copy_to_clipboard";

import { ServiceConnectionPhase } from "Component/ServiceConnectionPhase";

// -----------------------------------------------------------------------------

export const AzureConnectPageRunCommandPhase = memo(
  (props: DisabledProps | ActiveProps) => {
    const copyRef: RefObject<HTMLDivElement> = useRef() as any;
    const [userConfirmation, setUserConfirmation] = useState<boolean>(false);

    //
    ReturnIf(!props.shown, null);

    //
    const { command, loading, onNext } = props as ActiveProps;

    //
    function onContinue() {
      setUserConfirmation(false);
      onNext();
    }

    function toggleUserConfirmation() {
      setUserConfirmation((value) => !value);
    }

    //
    return (
      <ServiceConnectionPhase disabled={false}>
        <Box>
          <Typography variant="body1">
            Run the following command in an Azure Powershell terminal to enable
            ThreatKey access:
          </Typography>
          <Paper
            sx={{
              mt: 1,
              fontFamily: "monospace",
              backgroundColor: "primary.background",
              color: "primary.text",
              padding: "12px",
            }}
          >
            {loading ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CircularProgress
                  sx={{ color: "primary.text", mr: 1 }}
                  size="16px"
                />{" "}
                Loading command
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    userSelect: "all",
                    wordBreak: "break-all",
                  }}
                  ref={copyRef}
                >
                  {command}
                </Box>
                <Button
                  variant="contained"
                  sx={{ mt: 1 }}
                  onClick={() => {
                    (async () =>
                      copyRef.current !== null
                        ? await copyElementContentsToClipboard(copyRef.current)
                        : null)();
                  }}
                >
                  Copy to clipboard
                </Button>
              </>
            )}
          </Paper>
        </Box>
        <Box sx={{ mb: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={loading}
                checked={userConfirmation}
                onChange={toggleUserConfirmation}
              />
            }
            label="I have run the command above and have verified that it completed successfully."
          />
        </Box>
        <Button
          variant="contained"
          disabled={loading || !userConfirmation}
          onClick={onContinue as MouseEventHandler<HTMLButtonElement>}
        >
          Test Connection
        </Button>
      </ServiceConnectionPhase>
    );
  }
);

// -----------------------------------------------------------------------------

interface DisabledProps {
  shown: false;
}

interface ActiveProps {
  shown: true;
  loading: boolean;
  command: string;
  onNext: Function;
}
