import { SalesforceConnectPage } from "./Connect";
import { redirectToSalesforceConnectPage } from "./connect_handler";
import { SalesforceCoverImage } from "./CoverImage";

// -----------------------------------------------------------------------------

export { SalesforceConnectPage };

export const SalesforceAppConfig = {
  name: "salesforce",
  label: "Salesforce",
  coverImage: SalesforceCoverImage,
  onConnect: redirectToSalesforceConnectPage,
  searchTerms: ["salesforce", "sfdc", "lightning"],
};
