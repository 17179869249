import clamp from "lodash/clamp";

// -----------------------------------------------------------------------------

// Okay, but why the max param? It's a hard opt-in to raise it. Otherwise, a bug
// could make the user wait far too long. It's a dumb safety measure.

export async function wait(ms: number, max: number = 1000): Promise<void> {
  return await new Promise((resolve) => setTimeout(resolve, clamp(ms, 0, max)));
}
