import { memo } from "react";

import SvgIcon from "@mui/material/SvgIcon";

// -----------------------------------------------------------------------------

export const ADPOIDCSSOIcon = memo((props: object) => {
  return (
    <SvgIcon viewBox="0 0 1196.29 537.5" {...props}>
      <path
        d="M612.46,243.55h288l8.24.47c73.46,4.9,135.22,34.55,186.13,88.49v-89h190.53l7.14.48c106.52,5.06,194.65,93.16,199.89,199.81v21.42c-.37,4.63-.59,9.27-1.12,13.88C1479.38,583.31,1388,665.29,1283.56,665.7c-1.18,0-3.38,2.3-3.39,3.53-.24,37.08-.26,74.16-.32,111.24h-185V689.38c-1.4,1.57-2,2.33-2.73,3-53.12,55.62-118.24,84.77-195,86.14-59.11,1-118.25.25-177.38.14-.84,0-2.41-1.77-2.41-2.71-.12-36.49-.09-73-.22-109.47a3.65,3.65,0,0,0-2.52-2.53q-66-.19-131.94,0A4.87,4.87,0,0,0,579,665.9Q545.92,721,512.94,776.09a6.26,6.26,0,0,1-4.52,2.62q-105.93.21-211.87.14v-1.09a13,13,0,0,0,2.13-2.2q112.53-190.65,225-381.33Q568.1,318.91,612.46,243.55Zm-195.28,470c18.81,0,37,0,55.25-.09.83,0,1.9-1.23,2.44-2.13Q507.93,656,541,600.81a5.2,5.2,0,0,1,3.76-2.13q119.21-.15,238.43,0c1,0,2.72,1.9,2.72,2.92.14,36.3.09,72.6.23,108.9,0,1,1.81,2.85,2.78,2.85,34.22.15,68.45.84,102.65,0,82.64-2.06,144.11-39.19,183.14-112.55a5,5,0,0,1,3.68-2.14q40.24-.18,80.49,0a3.92,3.92,0,0,1,2.67,2.7c.14,36.3.09,72.61.25,108.92,0,1.06,2,3,3.14,3,14,.21,27.9.19,41.85,0a3.94,3.94,0,0,0,2.72-2.72c.14-35,.3-70.06,0-105.08-.06-6,1.37-7.16,7.24-7.19,27.89-.14,56.18,1.9,83.6-2,79.76-11.32,131.25-80.14,122.46-160.3-7.34-66.85-69.39-124-136.74-125.16-40.41-.7-80.84-.34-121.26-.29-1,0-3,1.93-3,3-.14,55.19-.11,110.38-.22,165.57,0,1-1.49,2.83-2.29,2.84q-32,.23-64.06,0a3.36,3.36,0,0,1-2.53-2c-15.49-96.92-99-168.73-198.11-169.37-79.48-.52-159-.14-238.44-.06a4.57,4.57,0,0,0-3.3,2Q562.37,466.29,472,620.11C453.86,651.05,435.68,682,417.18,713.51Z"
        fill="#f00028"
        transform="translate(-296.55 -243.55)"
      />
      <path
        d="M1094.81,781c0-.19,0-.38,0-.58h185V781Z"
        fill="#f00028"
        transform="translate(-296.55 -243.55)"
      />
      <path
        d="M1492.37,443.84a1.5,1.5,0,0,1,.47,0v21.34l-.24,0h-.23Z"
        fill="#f00028"
        transform="translate(-296.55 -243.55)"
      />
      <path
        d="M908.65,243.55a1.23,1.23,0,0,0,0,.47l-8.24-.47Z"
        fill="#f00028"
        transform="translate(-296.55 -243.55)"
      />
      <path
        d="M1292.45,243.55a1,1,0,0,0,0,.48l-7.14-.48Z"
        fill="#f00028"
        transform="translate(-296.55 -243.55)"
      />
      <path
        d="M1347.2,781l0-.59,6,0c0,.19,0,.37,0,.55Z"
        fill="#f00028"
        transform="translate(-296.55 -243.55)"
      />
      <path
        d="M1353.22,780.49l-6,0c-14-3.53-22.66-13.27-22.76-25.61-.1-11.35,8.65-22.44,19.87-25.17,13.68-3.33,28,5.68,31.22,19.68,3.09,13.38-6.44,27.82-20.22,30.64C1354.63,780.13,1353.93,780.32,1353.22,780.49Zm17.67-24.62c-.67-13-9.79-22.34-21.2-21.67s-20.41,10.32-19.91,21.3c.57,12.32,9.5,20.2,22.21,19.58C1362.52,774.56,1371.39,765.55,1370.89,755.87Z"
        fill="#f00028"
        transform="translate(-296.55 -243.55)"
      />
      <path
        d="M835.54,662.15c0-20.33,0-42,.12-63.72,0-.52,1.72-1.47,2.64-1.47,20.34-.17,40.7.18,61-.41,43.71-1.27,79.37-37,82-81.77,2.5-41.94-29.54-82.34-71.94-87.47-23.31-2.83-47.14-1.34-70.73-2-1.08,0-3-1.9-3-2.92-.18-20.43-.15-40.86-.06-61.29,0-.88,1-2.53,1.53-2.52,26.43.42,53-.79,79.24,1.86,65.47,6.6,123.75,66.12,130.81,131.47,9.11,84.29-45.57,157.36-129.66,169.61C890.86,665.43,863.15,662.15,835.54,662.15Z"
        fill="#f00028"
        transform="translate(-296.55 -243.55)"
      />
      <path
        d="M1249.58,550.65c-11.86,0-23.72.05-35.58-.12a3.65,3.65,0,0,1-2.55-2.5q-.21-30.78,0-61.57a3.86,3.86,0,0,1,2.67-2.67c21.34-.15,42.68-.11,64-.12,17.12,0,30.92-13.08,30.87-29.2s-13.83-29-30.79-29c-21.35,0-42.69,0-64-.12a3.9,3.9,0,0,1-2.73-2.62c-.16-20.52-.14-41.05,0-61.57,0-.89,1.12-2.55,1.72-2.55,25.26,0,50.59-1,75.77.56,42.32,2.54,78.52,36.23,85.61,77.74,7.54,44.16-14.08,87.46-54,104.44-12.65,5.38-27.08,7.12-40.9,9C1269.8,551.75,1259.61,550.65,1249.58,550.65Z"
        fill="#f00028"
        transform="translate(-296.55 -243.55)"
      />
      <path
        d="M569.61,550.65c13.16-22.29,26-44.16,39.06-65.95.44-.74,2.19-1,3.33-1,34-.06,68,0,102.07-.18,1,0,3-1.92,3-3,.16-39.77.12-79.54.25-119.3a3.9,3.9,0,0,1,2.7-2.68q31.47-.24,63,0c1,0,2.93,1.92,2.94,2.95q.21,93.14.06,186.28c0,.93-1.24,2.68-1.91,2.68C712.78,550.66,641.52,550.65,569.61,550.65Z"
        fill="#f00028"
        transform="translate(-296.55 -243.55)"
      />
      <path
        d="M1359.05,754.17c1,5.26,2,10.41,3,15.55l-2.1,1.54c-1.52-2.1-4.33-4.19-4.33-6.29,0-7.38-3.74-9.2-10.33-8.35v13.23l-2.24,1.24c-1-1.7-2.85-3.37-2.92-5.12-.31-8.09-.16-16.2-.09-24.31,0-.8.64-2.31.94-2.29,5.51.2,11.16,0,16.47,1.22,2,.48,4.45,4.23,4.62,6.64S1359.91,752.38,1359.05,754.17Zm-10.84-1,7.16-5.31c-2.44-1.13-4.81-2.56-7.38-3.17-.6-.14-2.37,2.41-2.65,3.9C1345.16,749.55,1346.69,750.85,1348.21,753.15Z"
        fill="#f00028"
        transform="translate(-296.55 -243.55)"
      />
      <path
        d="M1348.21,753.15c-1.52-2.3-3-3.6-2.87-4.58.28-1.49,2.05-4,2.65-3.9,2.57.61,4.94,2,7.38,3.17Z"
        fill="#f00028"
        transform="translate(-296.55 -243.55)"
      />
    </SvgIcon>
  );
});
