import { type NavigateFunction } from "react-router-dom";

import { type OrgStoreState } from "stores/OrganizationStore";

// -----------------------------------------------------------------------------

export function redirectToPagerDutyOauthUrl(
  _orgStore: OrgStoreState,
  navigate: NavigateFunction
): void {
  navigate("/environment/destinations/pagerduty/connect");
}
