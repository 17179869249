import { memo, useEffect, useReducer } from "react";
import { Link } from "react-router-dom";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import {
  type Finding,
  type FindingStoreState,
  type Metrics,
} from "data/finding";
import { type FOTS } from "data/typescript";

import { type FindingStoreActions, useFindingStore } from "stores/FindingStore";

import { accountHasConnections } from "model/connection";
import { loadDashboardMetrics } from "model/dashboard";
import { loadAllFindings } from "model/finding";
import { loadFindingTypes } from "model/finding_types";

import { asyncOrSwimWithSentry } from "utility/async_or_swim_sentry";
import { notifyError } from "utility/notify";
import { toObject } from "utility/object";

import { usePageTitle } from "effect/use_page_title";

import { Page } from "Component/Page";
import { PageContent } from "Component/PageContent";
import { PageHeader } from "Component/PageHeader";
import { PageSubTitle } from "Component/PageSubTitle";
import { PageTitle } from "Component/PageTitle";

import { ChartDisplay } from "./ChartDisplay";
import { DataDisplay } from "./DataDisplay";
import { getChartData } from "./lib/get_chart_data";
import { getPieData } from "./lib/get_pie_data";
import { getWidgetData } from "./lib/get_widget_data";
import { PieDisplay } from "./PieDisplay";
import { FindingsDataTable as Table } from "./Table";

// -----------------------------------------------------------------------------

export const DashboardPage = memo(() => {
  usePageTitle("Reporting → Mission Control");

  //
  const {
    error,
    loading,
    metricsData,
    chartData,
    pieData,
    widgetData,
    hasConnections,
  } = useLoadData();

  //
  return (
    <Page>
      <PageHeader>
        {!hasConnections && !loading ? (
          <Alert severity="warning" sx={{ mb: 1 }}>
            <Link to="/environment/sources" color="trialBarBase.linkColor">
              Connect a source
            </Link>{" "}
            to unlock security posture insights within minutes!
          </Alert>
        ) : null}

        <PageTitle>Mission Control</PageTitle>
        <PageSubTitle>
          This dashboard can help you stay on top of mission-critical metrics,
          track progress towards key milestones, and quickly identify and
          address any issues that may arise.
        </PageSubTitle>
      </PageHeader>
      <PageContent>
        {error ? (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        ) : null}

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            mb: -2,
            mr: -2,
          }}
        >
          <DataDisplay
            title="Findings (All time)"
            loading={loading}
            {...widgetData.findings}
          />
          <DataDisplay
            title="Findings Resolved (All time)"
            loading={loading}
            {...widgetData.findings_resolved}
          />
          <DataDisplay
            title="Findings Created (Trailing 30 Days)"
            loading={loading}
            {...widgetData.findings_created_last_30_days}
          />
        </Box>

        <Grid
          container
          spacing={2}
          sx={{
            mt: 0.25,
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={9}
            lg={9}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Typography variant="h6" fontWeight="bold" gutterBottom={true}>
              New Critical &amp; High Findings
            </Typography>

            <Table loading={loading} metrics={metricsData} />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Typography gutterBottom={true} variant="h6" fontWeight="bold">
              Findings Breakdown
            </Typography>
            <Stack spacing={2}>
              <ChartDisplay
                title="Critical (All time)"
                data={chartData?.critical}
                color="red"
                loading={loading}
              />
              <ChartDisplay
                title="High (All time)"
                data={chartData?.high}
                color="orange"
                loading={loading}
              />
              <PieDisplay
                title="Unresolved by source"
                data={pieData ?? []}
                loading={loading}
              />
            </Stack>
          </Grid>
        </Grid>
      </PageContent>
    </Page>
  );
});

// -----------------------------------------------------------------------------

function useLoadData() {
  const [findingStore, findingActions] = useFindingStore();

  const [data, setData] = useReducer(
    (state: LoadDataState, action: Partial<LoadDataState>): LoadDataState => ({
      ...state,
      ...action,
    }),
    {
      loading: true,
      chartData: {},
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      metricsData: {} as Metrics,
      pieData: [],
      widgetData: {},
      hasConnections: false,
    }
  );

  //
  useEffect(() => {
    asyncOrSwimWithSentry(
      async () => {
        const [allFindings, metricsData, hasConnections] = await Promise.all([
          loadChartData(findingStore, findingActions),
          loadDashboardMetrics(),
          accountHasConnections(),
        ]);

        //
        setData({
          loading: false,
          error: undefined,
          chartData: getChartData(sortChartData(allFindings)),
          metricsData,
          pieData: getPieData(metricsData?.unresolved_by_source),
          widgetData: getWidgetData(metricsData),
          hasConnections,
        });
      },
      "Failed to load dashboard data",
      () => {
        setData({
          loading: false,
          error: "Error loading dashboard data",
          chartData: {},
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          metricsData: {} as Metrics,
          pieData: [],
          widgetData: {},
          hasConnections: false,
        });
        notifyError("There was an error loading dashboard data");
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //
  return data;
}

async function loadChartData(
  findingStore: FindingStoreState,
  findingActions: FindingStoreActions
): Promise<Finding[]> {
  const allFindings: Finding[] = await loadAllFindings();

  //
  const findingTypesToLoad = allFindings
    .reduce((arr: string[], finding: Finding): string[] => {
      return arr.includes(finding.name) ? arr : [...arr, finding.name];
    }, [])
    .filter((findingType) => !findingStore.findingTypes[findingType]);
  const findingTypes = await loadFindingTypes(findingTypesToLoad);

  //
  findingActions.addFindingTypes(toObject(findingTypes, "name"));
  findingActions.setAll(allFindings);

  //
  return allFindings;
}

// -----------------------------------------------------------------------------

function sortChartData(findings: Finding[]) {
  return findings.sort(byCreateTime);
}

function byCreateTime(a: Finding, b: Finding) {
  return a.create_time > b.create_time
    ? 1
    : b.create_time > a.create_time
      ? -1
      : 0;
}

// -----------------------------------------------------------------------------

interface LoadDataState {
  loading: boolean;
  error?: string;

  chartData: Record<
    string,
    Array<{
      x: Date;
      y: number;
    }>
  >;
  metricsData: Metrics;
  pieData: FOTS;
  widgetData: FOTS;

  hasConnections: boolean;
}
