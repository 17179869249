import "react-toastify/dist/ReactToastify.css";

import "./lib/authorization";
import "./lib/bootstrap";
import "./lib/muix";
import "./lib/ortto";
import "./lib/sentry";

// -----------------------------------------------------------------------------

import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { createRoot } from "react-dom/client";

import { ThemeProvider } from "theme/provider";

import { store } from "stores/main";

import { GlobalErrorMessage } from "./GlobalErrorMessage";
import { HandleCrossTabMessages } from "./HandleCrossTabMessages";
import { LoadRequiredData } from "./LoadRequiredData";
import { Router } from "./Router";

// -----------------------------------------------------------------------------

export function renderApp() {
  const rootElement = document.createElement("div");
  rootElement.id = "root";

  document.body.appendChild(rootElement);

  //
  createRoot(rootElement).render(<ThreatKeyApp />);
}

// -----------------------------------------------------------------------------

function ThreatKeyApp() {
  return (
    <SentryErrorBoundary showDialog fallback={<GlobalErrorMessage />}>
      <ThemeProvider>
        <ReduxProvider store={store}>
          <BrowserRouter>
            <HandleCrossTabMessages />
            <LoadRequiredData>
              <Router />
            </LoadRequiredData>
          </BrowserRouter>
        </ReduxProvider>
      </ThemeProvider>
    </SentryErrorBoundary>
  );
}
