import { type Action, createHook, createStore } from "react-sweet-state";

import { ReturnIf, ThrowIf } from "babel-plugin-transform-functional-return";

import { type ResponseError } from "data/response_error";
import { type UserPermissionLevel } from "data/user";

import { captureSentryException } from "utility/capture_sentry_exception";
import {
  authenticatedDeleteFetch,
  authenticatedGetFetch,
  authenticatedPatchFetch,
  authenticatedPostFetch,
} from "utility/fetch/authenticated";

import { SubmitState } from "./lib";

// -----------------------------------------------------------------------------

const initialState: OrgStoreState = {
  uuid: "",
  organization: {
    name: "",
    slug: "",
    domain: "",
    dcv_isvalid: false,
    workos_conn_id_dict: null,
    supportAccess: null,
    org_isinternal: false,
    preferences: {
      resourceMadePublic: "",
      insecureResourceDetected: "",
      newResourceDetected: "",
      insecureResourceReintroduced: "",
    },
    isActive: false,
    isTrial: false,
  },
  flags: {},
  loadAttempted: false,
  loading: false,
  stripePortalOpening: false,
  members: [],
  membersLoadAttempted: false,
  membersLoading: false,
  membersLoaded: false,
  memberAdded: {
    email: "",
    name: "",
    role: "",
    success: null,
    errorMsg: "",
  },
  memberDeleted: {
    member: null,
    success: null,
    errorMsg: "",
  },
};

const INCOMPLETE_CHECKOUT = `${1 << 63}`;

// -----------------------------------------------------------------------------

export const useOrganizationStore = createHook(
  createStore({
    initialState,
    actions: {
      set,
      clear,

      patch,
      clearPatchErrors,
      clearPatchState,
      openCustomerPortal,
      loadMembers,
      addMember,
      deleteMember,
      resetMemberAdded,
      resetMembersLoaded,
      resetMembersLoadAttempted,
      resetMemberDeleted,
      editMember,
      createAndSendDCV,
      validateDCVCode,
      resetDCV,
    },
  })
);

// -----------------------------------------------------------------------------

function set(
  uuid: string,
  organization: ServerOrganization
): Action<OrgStoreState> {
  return ({ setState }) =>
    setState({ uuid, ...buildOrganizationData(organization) });
}

function clear(): Action<OrgStoreState> {
  return ({ setState }) => setState(initialState);
}

function patch(data: {}): Action<OrgStoreState> {
  return async ({ setState, getState }) => {
    ReturnIf(getState().patchState === SubmitState.STARTED);

    try {
      setState({
        patchState: SubmitState.STARTED,
      });
      await authenticatedPatchFetch(
        `api/organization/${getState().uuid}`,
        data
      );
      setState({ patchState: SubmitState.SUCCESS, patchErrors: {} });
    } catch (error) {
      captureSentryException(error, "Failed to update organization");
      setState({
        patchState: SubmitState.ERROR,
        patchErrors: (error as ResponseError)?.payload ?? {},
      });
    }
  };
}

function clearPatchErrors(): Action<OrgStoreState> {
  return ({ setState }) => setState({ patchErrors: initialState.patchErrors });
}

function clearPatchState(): Action<OrgStoreState> {
  return ({ setState }) => setState({ patchState: initialState.patchState });
}

function openCustomerPortal(): Action<OrgStoreState> {
  return async ({ setState, getState }) => {
    ReturnIf(getState().stripePortalOpening);

    try {
      setState({ stripePortalOpening: true });

      const responsePayload = await authenticatedPostFetch(
        "api/organization/stripe_portal"
      );

      window.location.href = responsePayload.url;
    } catch (error) {
      captureSentryException(error, "Failed to open stripe portal");
    }

    setState({ stripePortalOpening: false });
  };
}

function loadMembers(): Action<OrgStoreState> {
  return async ({ getState, setState }) => {
    ReturnIf(getState().membersLoading);

    try {
      setState({ membersLoading: true });

      const responsePayload = await authenticatedGetFetch(
        "api/organization/list_users"
      );

      setState({
        members: responsePayload,
        membersLoaded: true,
      });
    } catch (error) {
      captureSentryException(error, "Failed to load organization members");
    }

    setState({
      membersLoadAttempted: true,
      membersLoading: false,
    });
  };
}

function addMember(data: {
  email: string;
  name: string;
  role: string;
}): Action<OrgStoreState> {
  return async ({ setState }): Promise<any> => {
    try {
      const responsePayload = await authenticatedPostFetch(
        "api/account/create",
        {
          email: data.email,
          full_name: data.name,
          role: data.role,
        }
      );

      //
      const returnValue = {
        memberAdded: {
          email: data.email,
          name: data.name,
          role: data.role,
          ...(responsePayload?.error === false
            ? { success: true, errorMsg: "" }
            : {
                success: false,
                errorMsg: responsePayload?.message ?? "There was an error",
              }),
        },
      };
      setState(returnValue);

      //
      return returnValue;
    } catch (error) {
      captureSentryException(error, "Failed to create organization member");

      //
      const returnValue = {
        memberAdded: {
          email: data.email,
          name: data.name,
          role: data.role,
          success: false,
          errorMsg: (error as Error)?.message ?? "There was an error",
        },
      };
      setState(returnValue);

      //
      return returnValue;
    }
  };
}

function deleteMember(
  member: OrganizationMember,
  callback: Function
): Action<OrgStoreState> {
  return async ({ setState, getState }) => {
    try {
      await authenticatedDeleteFetch(`api/account/${member.uuid}`, member, {
        csrf: true,
      });
      callback(member);

      const newMembers = getState().members.filter(
        (value) => value.uuid !== member.uuid
      );
      setState({
        memberDeleted: {
          member,
          success: true,
          errorMsg: "",
        },
        members: newMembers,
      });
    } catch (error) {
      captureSentryException(error, "Failed to delete organization member");
      setState({
        memberDeleted: {
          member,
          success: false,
          errorMsg: (error as Error)?.message ?? "There was an error",
        },
      });
    }
  };
}

function resetMemberAdded(): Action<OrgStoreState> {
  return async ({ setState }) =>
    setState({ memberAdded: initialState.memberAdded });
}

function resetMembersLoaded(): Action<OrgStoreState> {
  return async ({ setState }) =>
    setState({ membersLoaded: initialState.membersLoaded });
}

function resetMembersLoadAttempted(): Action<OrgStoreState> {
  return async ({ setState }) =>
    setState({
      membersLoadAttempted: initialState.membersLoadAttempted,
      members: initialState.members,
    });
}

function resetMemberDeleted(): Action<OrgStoreState> {
  return async ({ setState }) =>
    setState({ memberDeleted: initialState.memberDeleted });
}

function editMember(
  member: string,
  data: any,
  onSuccess: Function,
  onError: Function
): Action<OrgStoreState> {
  return async (): Promise<any> => {
    try {
      await authenticatedPatchFetch(`api/account/${member}`, data, {
        csrf: true,
      });

      onSuccess(member);
    } catch (error) {
      captureSentryException(error, "Failed to edit organization member");
      onError();
    }
  };
}

function createAndSendDCV(
  emailAddress: string
): Action<OrgStoreState, void, Promise<{ success: boolean; message: string }>> {
  return async ({ setState, getState }) => {
    try {
      const { organization } = getState();
      setState({
        organization: {
          ...organization,
          dcv_isvalid: false,
        },
      });

      //
      const request = await authenticatedPostFetch<
        { error: boolean; message: string },
        { address: string }
      >("api/organization/dcv_generate", { address: emailAddress });
      ThrowIf(request.error, new Error(request.message));

      //
      return { success: true, message: request.message };
    } catch (error) {
      captureSentryException(error, "Failed to create and send DCV");

      //
      return {
        success: false,
        message: (error as Error)?.message ?? "There was an error",
      };
    }
  };
}

function validateDCVCode(
  proof: string
): Action<OrgStoreState, void, Promise<{ success: boolean; message: string }>> {
  return async ({ setState, getState }) => {
    try {
      const responsePayload = await authenticatedPostFetch(
        `api/organization/dcv_validate`,
        { proof }
      );
      ThrowIf(
        responsePayload?.error,
        new Error(responsePayload?.message ?? "There was an error")
      );

      //
      const { organization } = getState();
      setState({
        organization: {
          ...organization,
          dcv_isvalid: true,
        },
      });

      //
      return { success: true, ...responsePayload };
    } catch (error) {
      captureSentryException(error, "Failed to validate DCV");
    }
  };
}

function resetDCV(): Action<OrgStoreState> {
  return async ({ setState, getState }) => {
    const { organization } = getState();
    setState({
      organization: {
        ...organization,
        dcv_isvalid: false,
      },
    });
  };
}

// -----------------------------------------------------------------------------

function buildOrganizationData(
  data: ServerOrganization
): Pick<OrgStoreState, "organization" | "flags"> {
  const flagInt = BigInt(data.flags || INCOMPLETE_CHECKOUT);
  const flags: StateFlags = {
    cloudCheckup: hasFeatureFlag(flagInt, 0),
    incompleteCheckout: hasFeatureFlag(flagInt, 63),
    earlyAccess: !!data.early_access,
    requestedDeletion: !!data.requested_deletion,
  };

  //
  return {
    organization: {
      name: data.name,
      slug: data.slug,
      domain: data.domain,
      dcv_isvalid: data.dcv_isvalid,
      workos_conn_id_dict: data.workos_conn_id_dict,
      supportAccess: data.support_access,
      org_isinternal: data.org_isinternal,
      isActive: data.is_active,
      isTrial: data.is_trial,
      preferences: {
        resourceMadePublic: data.resource_made_public,
        insecureResourceDetected: data.insecure_resource_detected,
        newResourceDetected: data.new_resource_detected,
        insecureResourceReintroduced: data.insecure_resource_reintroduced,
      },
    },
    flags,
  };
}

function hasFeatureFlag(flags: bigint, offset: number): boolean {
  return !!(flags & (BigInt(1) << BigInt(offset)));
}

// -----------------------------------------------------------------------------

export interface OrgStoreState {
  uuid: string;
  organization: {
    name: string;
    slug: string;
    supportAccess: Date | null;
    preferences: {
      resourceMadePublic: null | string;
      insecureResourceDetected: null | string;
      newResourceDetected: null | string;
      insecureResourceReintroduced: null | string;
    };
    dcv_isvalid: boolean | null;
    domain: string | null;
    workos_conn_id_dict: Record<string, string> | null;
    org_isinternal: boolean | null;
    isTrial: boolean;
    isActive: boolean;
  };
  flags: StateFlags;
  loadAttempted: boolean;
  loading: boolean;
  patchState?: SubmitState;
  patchErrors?: { [key: string]: string };
  stripePortalOpening: boolean;
  members: OrganizationMember[];
  membersLoadAttempted: boolean;
  membersLoading: boolean;
  membersLoaded: boolean;
  memberAdded: {
    email: string;
    name: string;
    role: string;
    success: boolean | null;
    errorMsg: string;
  };
  memberDeleted: {
    member: OrganizationMember | null;
    success: boolean | null;
    errorMsg: string;
  };
}

interface StateFlags {
  cloudCheckup?: boolean;
  incompleteCheckout?: boolean;
  earlyAccess?: boolean;
  requestedDeletion?: boolean;
}

interface ServerOrganization {
  name: string;
  slug: string;
  early_access: null | string;
  requested_deletion: null | string;
  resource_made_public: null | string;
  insecure_resource_detected: null | string;
  new_resource_detected: null | string;
  insecure_resource_reintroduced: null | string;
  flags: string;
  domain: null | string;
  dcv_isvalid: null | boolean;
  workos_conn_id_dict: null | Record<string, string>;
  support_access: Date | null;
  org_isinternal: null | boolean;
  is_active: boolean;
  is_trial: boolean;
}

export interface OrganizationMember {
  uuid: string;
  full_name: string;
  preferredName: string;
  email: string;
  gravatarEmail: string;
  organization: string;
  permission_level: UserPermissionLevel;
  login_method: string;
  preferences: {
    productAndFeatures: null | Date;
    companyAnnouncements: null | Date;
    educationAndTraining: null | Date;
    marketingInformation: null | Date;
    monthlyNewsletter: null | Date;
  };
}
