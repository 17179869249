import { memo } from "react";

import SvgIcon from "@mui/material/SvgIcon";

// -----------------------------------------------------------------------------

export const Microsoft365CoverImage = memo((props: object) => {
  return (
    <SvgIcon viewBox="0 0 1319.928 213.51855" {...props}>
      <rect
        x="0"
        y="0.0019531"
        fill="#f25022"
        width="101.4585"
        height="101.45801"
      />
      <rect
        y="0"
        x="112.02197"
        fill="#7fba00"
        width="101.45703"
        height="101.45703"
      />
      <rect
        x="0"
        y="112.06201"
        fill="#00a4ef"
        width="101.45703"
        height="101.45654"
      />
      <rect
        x="112.02197"
        y="112.06201"
        fill="#ffb900"
        width="101.45703"
        height="101.45654"
      />
      <path
        fill={props.theme?.microsoft365Logo.main}
        d="m 352.96924,123.02734 -5.99512,16.77491 h -0.34082 c -1.0708,-3.93067 -2.86523,-9.50635 -5.67529,-16.59229 L 308.87842,42.737793 H 277.51416 V 170.68018 h 20.68555 V 92.029785 c 0,-4.854492 -0.1045,-10.722168 -0.3086,-17.435058 -0.10595,-3.39795 -0.49267,-6.119141 -0.58642,-8.193848 h 0.45459 c 1.05127,4.822266 2.14404,8.493164 2.93261,10.950195 l 38.46729,93.329106 h 14.46973 l 38.18066,-94.171391 c 0.86719,-2.147461 1.78418,-6.337402 2.62891,-10.10791 h 0.4541 c -0.5,9.320312 -0.92383,17.838379 -0.98047,22.978027 v 81.301274 h 22.06641 V 42.737793 h -30.11915 z"
      />
      <rect
        x="436.75391"
        y="78.992676"
        fill={props.theme?.microsoft365Logo.main}
        width="21.568359"
        height="91.686523"
      />
      <path
        fill={props.theme?.microsoft365Logo.main}
        d="m 447.75977,40.027832 c -3.55372,0 -6.64356,1.214355 -9.18848,3.602539 -2.55371,2.399902 -3.84863,5.428223 -3.84863,8.996582 0,3.509766 1.28027,6.476563 3.80566,8.816895 2.51465,2.331543 5.62207,3.511718 9.23145,3.511718 3.61035,0 6.73046,-1.180175 9.27343,-3.5083 2.5586,-2.34375 3.85547,-5.310547 3.85547,-8.820313 0,-3.445801 -1.26465,-6.437012 -3.75293,-8.896973 -2.4873,-2.457031 -5.6416,-3.702148 -9.37597,-3.702148"
      />
      <path
        fill={props.theme?.microsoft365Logo.main}
        d="m 534.67676,78.075195 c -4.14844,-0.85791 -8.2002,-1.29248 -12.05274,-1.29248 -9.89843,0 -18.72168,2.119629 -26.22851,6.306152 -7.52246,4.189941 -13.34375,10.174316 -17.29492,17.786623 -3.94629,7.59375 -5.94434,16.45703 -5.94434,26.3457 0,8.66504 1.94141,16.61035 5.77051,23.61963 3.83887,7.02344 9.2666,12.51172 16.13965,16.3208 6.86816,3.79981 14.7998,5.73096 23.58007,5.73096 10.24903,0 19.00293,-2.04932 26.01563,-6.0918 l 0.28516,-0.16553 v -19.75537 l -0.90723,0.65967 c -3.18067,2.31592 -6.73242,4.16553 -10.55078,5.49316 -3.81446,1.33057 -7.29004,2.00586 -10.33399,2.00586 -8.46289,0 -15.2539,-2.64892 -20.18847,-7.87158 -4.94141,-5.22754 -7.44727,-12.55908 -7.44727,-21.79834 0,-9.29785 2.6084,-16.82861 7.76074,-22.38135 5.13672,-5.540523 11.94336,-8.350581 20.22852,-8.350581 7.09375,0 13.99805,2.401855 20.53125,7.142581 l 0.90723,0.65674 V 81.617187 l -0.29004,-0.165527 c -2.45996,-1.375488 -5.81641,-2.51123 -9.98047,-3.376465"
      />
      <path
        fill={props.theme?.microsoft365Logo.main}
        d="m 605.74023,77.400879 c -5.41699,0 -10.26757,1.741699 -14.42382,5.171875 -3.64649,3.01123 -6.2793,7.141601 -8.29688,12.296875 h -0.22851 V 78.992187 h -21.56641 v 91.686523 h 21.56641 v -46.90234 c 0,-7.97608 1.80957,-14.52783 5.37402,-19.47266 3.52441,-4.890136 8.21582,-7.368651 13.95019,-7.368651 1.94434,0 4.125,0.3208 6.48536,0.95166 2.33593,0.630859 4.03027,1.315429 5.02832,2.035156 l 0.9082,0.653805 V 78.83252 l -0.34961,-0.146973 c -2.00586,-0.853516 -4.84961,-1.284668 -8.44727,-1.284668"
      />
      <path
        fill={props.theme?.microsoft365Logo.main}
        d="m 664.31934,76.782715 c -15.12207,0 -27.12891,4.429687 -35.68946,13.169922 -8.55957,8.73291 -12.89648,20.828123 -12.89648,35.945803 0,14.35449 4.23633,25.89892 12.58789,34.31396 8.35351,8.41065 19.72363,12.67871 33.78613,12.67871 14.65235,0 26.42578,-4.49072 34.98535,-13.34961 8.55274,-8.85058 12.89161,-20.82324 12.89161,-35.59082 0,-14.58642 -4.07032,-26.216793 -12.09571,-34.570309 -8.03125,-8.359863 -19.32812,-12.597656 -33.56933,-12.597656 m 17.14746,70.597655 c -4.05176,5.08301 -10.14746,7.65674 -18.12207,7.65674 -7.92286,0 -14.17383,-2.62451 -18.57325,-7.80127 -4.42871,-5.20264 -6.6748,-12.61865 -6.6748,-22.04688 0,-9.71777 2.24609,-17.33007 6.6748,-22.6206 4.40625,-5.264161 10.59571,-7.930176 18.39844,-7.930176 7.56738,0 13.59473,2.547851 17.91309,7.581546 4.33886,5.05566 6.53808,12.60693 6.53808,22.43994 0,9.96045 -2.07129,17.60205 -6.15429,22.7207"
      />
      <path
        fill={props.theme?.microsoft365Logo.main}
        d="m 757.81641,116.87793 c -6.79981,-2.73145 -11.15625,-4.99707 -12.94629,-6.72949 -1.73145,-1.68213 -2.61328,-4.05518 -2.61328,-7.06446 0,-2.66601 1.08789,-4.804195 3.31933,-6.532222 2.24121,-1.73877 5.36719,-2.623047 9.28711,-2.623047 3.64063,0 7.36328,0.574219 11.0625,1.700684 3.69434,1.125 6.94629,2.633789 9.65918,4.478515 l 0.89551,0.60791 V 80.777832 l -0.34863,-0.147461 c -2.5,-1.071289 -5.79688,-1.990723 -9.80274,-2.734863 -3.99609,-0.738281 -7.61328,-1.112793 -10.75976,-1.112793 -10.28614,0 -18.792,2.630371 -25.29297,7.817871 -6.53516,5.218262 -9.85157,12.060059 -9.85157,20.341794 0,4.30323 0.7168,8.12793 2.125,11.36475 1.41407,3.25683 3.61036,6.12695 6.52735,8.52881 2.88476,2.38037 7.35351,4.8789 13.26855,7.42383 4.96973,2.04248 8.67871,3.77539 11.03809,5.14111 2.30176,1.33545 3.93652,2.68115 4.85547,3.98877 0.89941,1.28174 1.35742,3.03125 1.35742,5.19678 0,6.16503 -4.61426,9.1582 -14.10449,9.1582 -3.52442,0 -7.53906,-0.73389 -11.9375,-2.18408 -4.40332,-1.44727 -8.5166,-3.53174 -12.22266,-6.19727 l -0.90723,-0.65234 v 21.02295 l 0.33008,0.15381 c 3.0918,1.42382 6.98438,2.6289 11.57813,3.57568 4.58984,0.94873 8.74902,1.42871 12.35937,1.42871 11.16504,0 20.14844,-2.64551 26.70606,-7.86084 6.59668,-5.25049 9.94433,-12.25488 9.94433,-20.82813 0,-6.18066 -1.79882,-11.48144 -5.35058,-15.75292 -3.52344,-4.23877 -9.63965,-8.13428 -18.17578,-11.57227"
      />
      <path
        fill={props.theme?.microsoft365Logo.main}
        d="m 839.33398,76.782715 c -15.12109,0 -27.125,4.429687 -35.68847,13.169922 -8.55664,8.73291 -12.89551,20.828123 -12.89551,35.945803 0,14.35449 4.23437,25.89892 12.58984,34.31396 8.35352,8.41065 19.71973,12.67871 33.78614,12.67871 14.65234,0 26.42187,-4.49072 34.98144,-13.34961 8.55664,-8.85058 12.89551,-20.82324 12.89551,-35.59082 0,-14.58642 -4.07031,-26.216793 -12.09863,-34.570309 -8.03028,-8.359863 -19.3291,-12.597656 -33.57032,-12.597656 m 17.15137,70.597655 c -4.05176,5.08301 -10.15137,7.65674 -18.12207,7.65674 -7.92285,0 -14.17383,-2.62451 -18.57519,-7.80127 -4.42774,-5.20264 -6.67383,-12.61865 -6.67383,-22.04688 0,-9.71777 2.24707,-17.33007 6.67676,-22.6206 4.40136,-5.264161 10.59082,-7.930176 18.39648,-7.930176 7.56543,0 13.5957,2.547851 17.91406,7.581546 4.33887,5.05566 6.53809,12.60693 6.53809,22.43994 0,9.96045 -2.0752,17.60205 -6.1543,22.7207"
      />
      <path
        fill={props.theme?.microsoft365Logo.main}
        d="M 1000,96.581543 V 78.991699 H 978.15723 V 51.645508 l -0.73633,0.225097 -20.51367,6.278809 -0.4043,0.124512 V 78.991699 H 924.1416 V 67.450195 c 0,-5.373047 1.20313,-9.486328 3.57422,-12.226074 2.35449,-2.716308 5.71582,-4.093262 10.00098,-4.093262 3.08496,0 6.28027,0.729493 9.49511,2.164063 l 0.80274,0.35791 V 35.126465 l -0.37598,-0.134766 c -2.99609,-1.079101 -7.07519,-1.625488 -12.13086,-1.625488 -6.37207,0 -12.16113,1.39209 -17.20605,4.134765 -5.05274,2.753419 -9.02637,6.682129 -11.8086,11.67627 -2.77734,4.986328 -4.18359,10.746582 -4.18359,17.12207 v 12.692383 h -15.20996 v 17.589844 h 15.20996 V 170.68115 H 924.1416 V 96.581543 h 32.36133 V 143.6709 c 0,19.39014 9.14746,29.22021 27.1875,29.22021 2.96484,0 6.08789,-0.34668 9.27148,-1.02978 3.24414,-0.69824 5.45118,-1.39649 6.75098,-2.14112 L 1000,169.55615 V 151.7959 l -0.88867,0.59082 c -1.17969,0.79346 -2.65821,1.43799 -4.38965,1.91699 -1.74023,0.48828 -3.18945,0.73193 -4.31055,0.73193 -4.22851,0 -7.35644,-1.1372 -9.2959,-3.38378 -1.96191,-2.26856 -2.958,-6.23145 -2.958,-11.78418 V 96.581543 Z"
      />
      <path d="m 1052.0125,165.94342 v -19.39218 q 12.7597,9.874 28.8006,9.874 13.0332,0 20.7803,-5.78207 7.8381,-5.78207 7.8381,-15.65606 0,-21.70501 -33.9957,-21.70501 h -10.1167 V 96.736483 h 9.661 q 30.1678,0 30.1678,-20.459633 0,-18.858444 -23.5145,-18.858444 -13.1244,0 -24.6994,9.162357 V 48.256048 q 13.3067,-7.294304 31.4439,-7.294304 17.408,0 27.8893,8.539673 10.4811,8.539673 10.4811,21.882912 0,25.174244 -26.2486,32.379591 v 0.35583 q 14.1269,1.33432 22.3295,9.69608 8.2028,8.27281 8.2028,20.7265 0,17.1683 -13.2155,27.75394 -13.2155,10.49668 -34.816,10.49668 -19.4131,0 -30.988,-6.84953 z" />
      <path d="m 1231.4041,129.64981 q 0,9.60713 -3.2811,17.52411 -3.1898,7.917 -8.9317,13.69906 -5.7419,5.69312 -13.6713,8.89549 -7.8381,3.11343 -17.0434,3.11343 -9.8433,0 -18.1372,-3.73611 -8.2026,-3.7361 -14.2179,-11.11936 -5.9242,-7.38326 -9.2053,-18.32471 -3.2811,-11.03042 -3.2811,-25.53008 0,-17.702021 4.2836,-31.40108 4.3748,-13.699059 11.9396,-22.950372 7.5646,-9.251312 17.7724,-14.054878 10.2079,-4.803566 22.0563,-4.803566 7.3825,0 13.3978,0.978504 6.1065,0.978505 9.7521,2.668648 v 18.591579 q -4.6482,-2.312828 -9.9344,-3.914016 -5.2862,-1.601189 -12.1218,-1.601189 -7.6559,0 -14.1269,3.024467 -6.4711,3.024469 -11.2104,8.895494 -4.6483,5.782069 -7.3825,14.410698 -2.643,8.539673 -2.7342,19.747991 h 0.5469 q 4.1924,-6.315797 11.3014,-9.962949 7.2002,-3.647152 16.8612,-3.647152 8.5673,0 15.4029,2.757603 6.9268,2.668648 11.7573,7.828038 4.9215,5.07042 7.5646,12.45369 2.6431,7.2943 2.6431,16.45666 z m -21.327,1.69015 q 0,-5.51522 -1.3671,-10.05191 -1.276,-4.53671 -4.0102,-7.73909 -2.6431,-3.29133 -6.7445,-5.07042 -4.0102,-1.7791 -9.4787,-1.7791 -5.4685,0 -9.661,1.957 -4.1014,1.86805 -6.9268,4.98148 -2.8254,3.11342 -4.2836,7.1164 -1.367,4.00296 -1.367,8.18384 0,4.53671 1.2758,9.34027 1.3672,4.71461 4.1014,8.62863 2.7343,3.91402 6.9268,6.49371 4.2836,2.49074 10.1167,2.49074 5.195,0 9.2053,-1.86805 4.0102,-1.86805 6.6533,-5.15939 2.7342,-3.29133 4.1014,-7.73908 1.4582,-4.5367 1.4582,-9.78503 z" />
      <path d="m 1242.7312,167.72252 v -19.83696 q 12.851,8.45073 26.0665,8.45073 13.3066,0 21.4182,-6.58267 8.1116,-6.67162 8.1116,-17.70203 0,-10.85251 -8.2939,-16.90144 -8.2028,-6.04893 -23.6056,-6.04893 -5.2862,0 -21.4182,1.06746 l 4.6482,-67.072018 h 64.3458 v 17.613075 h -46.7555 l -2.1874,31.93482 q 6.2888,-0.35582 11.3926,-0.35582 20.7803,0 32.0818,10.407723 11.3927,10.40773 11.3927,28.46558 0,18.9474 -13.0332,30.42259 -12.9421,11.38623 -35.6363,11.38623 -19.1397,0 -28.5273,-5.24834 z" />
    </SvgIcon>
  );
});
