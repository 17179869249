import { memo } from "react";

import { type ComputedDatum } from "@nivo/bar";
import { type Serie } from "@nivo/line";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import CloseFullscreenRounded from "@mui/icons-material/CloseFullscreenRounded";
import FileDownloadRounded from "@mui/icons-material/FileDownloadRounded";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

import { FindingSeverityLabel, FindingSeverity } from "data/finding";

import { generateTicks } from "utility/charting_utils";
import { exportChartAsImage } from "utility/save_chart_as_image";

import { ResponseBar } from "Component/ResponseBar";

// -----------------------------------------------------------------------------

export const RESOLVED_BAR_CHART_ID = "risk-barchart-resolved-findings-expanded";

const IconSX = { fontSize: 14, color: "icons.main" };
const IconLargeSX = { fontSize: 18, color: "primary.main" };

// -----------------------------------------------------------------------------

export const RateOfFindings = memo(({ closeModal, data, open }: Props) => {
  //
  return (
    <Modal
      open={open}
      onClose={() => closeModal()}
      sx={{ display: "flex", overflowY: "auto", padding: 16 }}
    >
      <Paper
        sx={{
          padding: "16px",
          maxWidth: "90vw",
          height: "475px",
          width: "100%",
          "@media screen and (max-width:640px)": {
            borderRadius: 0,
            height: "100%",
            overflowY: "auto",
            width: "100%",

            "& .MuiBox-root": {
              flexDirection: "column",
            },
          },
        }}
      >
        <Typography variant="overline">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "12px",
              }}
            >
              <span>Rate of findings - expanded view</span>
              <Tooltip title="Hover over individual bars to view severity levels for each day">
                <InfoOutlined sx={IconSX} />
              </Tooltip>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "12px",
              }}
            >
              <Button
                onClick={() => exportChartAsImage(RESOLVED_BAR_CHART_ID)}
                startIcon={<FileDownloadRounded sx={IconLargeSX} />}
              >
                Download
              </Button>
              <IconButton onClick={() => closeModal()}>
                <CloseFullscreenRounded sx={IconSX} />
              </IconButton>
            </Box>
          </Box>
        </Typography>
        <Box sx={{ height: 400 }} id={RESOLVED_BAR_CHART_ID}>
          <ResponseBar
            data={data}
            keys={[
              FindingSeverityLabel[FindingSeverity.Critical],
              FindingSeverityLabel[FindingSeverity.High],
              FindingSeverityLabel[FindingSeverity.Medium],
            ]}
            indexBy="create_time"
            colors={["#DE0000", "#FF7700", "#EBB000"]}
            margin={{ top: 50, right: 40, bottom: 30, left: 40 }}
            padding={0.5}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            borderColor={{
              from: "color",
              modifiers: [["darker", 1.6]],
            }}
            enableLabel={false}
            axisBottom={{
              tickValues: generateTicks(data),
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
            }}
            // gridYValues should match axisLeft.tickValues
            gridYValues={3}
            axisLeft={{
              tickValues: 3,
            }}
            legends={[
              {
                dataFrom: "keys",
                anchor: "top-right",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: -25,
                itemsSpacing: 8,
                itemWidth: 65,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 10,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            role="application"
            ariaLabel="Rate of findings bar chart"
            barAriaLabel={function (data: ComputedDatum<Serie>) {
              return `${data.id}: ${data.formattedValue} for date: ${data.indexValue}`;
            }}
          />
        </Box>
      </Paper>
    </Modal>
  );
});

// -----------------------------------------------------------------------------

interface Props {
  data: Serie[];
  closeModal: Function;
  open: boolean;
}
