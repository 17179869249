import { memo } from "react";

import { GhostEnvironmentPossibleSource } from "./PossibleSource";

// -----------------------------------------------------------------------------

export const GhostEnvironmentPossibleSources = memo(() => {
  return (
    <>
      <GhostEnvironmentPossibleSource />
      <GhostEnvironmentPossibleSource />
      <GhostEnvironmentPossibleSource />
      <GhostEnvironmentPossibleSource />
    </>
  );
});
