import { memo } from "react";

import {
  ResponsiveScatterPlot,
  type ScatterPlotDatum,
  type ScatterPlotNodeData,
  type ScatterPlotRawSerie,
  type ScatterPlotSvgProps,
  type ScatterPlotValue,
} from "@nivo/scatterplot";
import styled, { useTheme } from "styled-components";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import { FindingSeverityLabelToIntMap, SeverityToColor } from "data/finding";

import { type IAMFindingDatum } from "../lib/get_scatter_data";

// -----------------------------------------------------------------------------

const SeverityLabels = ["EA", "Info", "Low", "Med", "High", "Cri"];

// -----------------------------------------------------------------------------

const ScatterPlotConfig: Omit<
  ScatterPlotSvgProps<IAMFindingDatum>,
  "data" | "width" | "height"
> = {
  colors: getNodeColor,
  nodeSize: getNodeSize,
  margin: { top: 16, right: 0, bottom: 20, left: 46 },
  layers: ["grid", "axes", "nodes"],
  isInteractive: false,
  xScale: {
    type: "time",
    format: "%Y-%m-%d",
    precision: "day",
  },
  xFormat: "time:%Y-%m-%d",
  yScale: { type: "linear", min: 0, max: 5 },
  axisTop: null,
  axisRight: null,
  axisBottom: {
    tickValues: 14,
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    format: "%m/%d",
  },
  gridYValues: [0, 1, 2, 3, 4, 5],
  enableGridX: false,
  axisLeft: {
    format: (y: ScatterPlotValue) => SeverityLabels[y as number],
    tickValues: [0, 1, 2, 3, 4, 5],
    tickSize: 1,
    tickPadding: 5,
    tickRotation: 0,
    legend: "Severity",
    legendPosition: "middle",
    legendOffset: -40,
  },
};

// -----------------------------------------------------------------------------

export const ScatterPlotDisplay = memo(
  ({ title, data = [], loading = false }: Props) => {
    const theme = useTheme();

    const scatterplotThemeing = {
      axis: {
        legend: {
          text: {
            fill: theme.scatterPlot.text,
            fontSize: 13,
          },
        },
        ticks: {
          line: {
            stroke: theme.scatterPlot.text,
          },
          text: {
            fontSize: 8,
            fill: theme.scatterPlot.text,
          },
        },
      },
      grid: {
        line: {
          stroke: "#AAAAAA",
          strokeWidth: 0.5,
        },
      },
    };
    return (
      <Card>
        <CardContent>
          <Typography variant="overline">{title}</Typography>
          {loading ? (
            <CircularProgress size={20} />
          ) : hasAtLeastOneDatapoint(data) ? (
            <ChartBox>
              <ResponsiveScatterPlot
                theme={scatterplotThemeing}
                blendMode={theme.scatterPlot.blendMode}
                data={data}
                {...ScatterPlotConfig}
              />
            </ChartBox>
          ) : (
            <Typography variant="h5" fontWeight="bold">
              None
            </Typography>
          )}
        </CardContent>
      </Card>
    );
  }
);

// -----------------------------------------------------------------------------

function getNodeColor(node: { serieId: string | number }) {
  return serieIdToSeverityColor(node.serieId as string);
}

function serieIdToSeverityColor(id: string): string {
  return SeverityToColor[FindingSeverityLabelToIntMap[id]];
}

// Scale up small counts so they are visible on the chart
function getNodeSize(
  node: Omit<ScatterPlotNodeData<IAMFindingDatum>, "size" | "color">
) {
  return node.data.count * 2 + 10;
}

function hasAtLeastOneDatapoint(
  data: Array<ScatterPlotRawSerie<ScatterPlotDatum>>
) {
  return data.some(categoriesHaveData);
}

function categoriesHaveData(category: ScatterPlotRawSerie<ScatterPlotDatum>) {
  return category.data.length > 0;
}

// -----------------------------------------------------------------------------

const ChartBox = styled(Box)`
  border-radius: 4px;
  height: 256px;
`;

// -----------------------------------------------------------------------------

interface Props {
  title;
  data: Array<ScatterPlotRawSerie<IAMFindingDatum>>;
  loading?: boolean;
}
