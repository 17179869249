import { ResponseError } from "data/response_error";

import { getApiError } from "utility/apiErrors";

import {
  API_URL,
  DefaultAllowedStatuses,
  getRequestBodyString,
  getResponseJson,
  hasAllowedStatus,
  type Method,
} from "./lib";

// -----------------------------------------------------------------------------

const FetchHeaders = {
  "Content-Type": "application/json",
};

// -----------------------------------------------------------------------------

export async function unauthenticatedFetch<T = any>(
  method: Method,
  path: string,
  payload?: any,
  allowedStatuses?: number[],
  abort?: AbortController
): Promise<T> {
  const serverResponse = await fetch(`${API_URL}/${path}`, {
    method,
    headers: FetchHeaders,
    ...getRequestBodyString(method, payload),
    signal: abort?.signal,
  });

  //
  if (
    !hasAllowedStatus(
      allowedStatuses ?? DefaultAllowedStatuses,
      serverResponse.status
    )
  ) {
    const responsePayload = await getResponseJson<T>(serverResponse, true);
    throw new ResponseError(
      getApiError(responsePayload).message,
      serverResponse.status,
      responsePayload
    );
  }

  //
  return await getResponseJson<T>(serverResponse);
}

export async function unauthenticatedDeleteFetch<T = any>(
  path: string,
  payload?: any,
  allowedStatuses?: number[],
  abort?: AbortController
): Promise<T> {
  return await unauthenticatedFetch<T>(
    "DELETE",
    path,
    payload,
    allowedStatuses,
    abort
  );
}

export async function unauthenticatedGetFetch<T = any>(
  path: string,
  allowedStatuses?: number[],
  abort?: AbortController
): Promise<T> {
  return await unauthenticatedFetch<T>(
    "GET",
    path,
    undefined,
    allowedStatuses,
    abort
  );
}

export async function unauthenticatedPatchFetch<T = any>(
  path: string,
  payload?: any,
  allowedStatuses?: number[],
  abort?: AbortController
): Promise<T> {
  return await unauthenticatedFetch<T>(
    "PATCH",
    path,
    payload,
    allowedStatuses,
    abort
  );
}

export async function unauthenticatedPostFetch<T = any>(
  path: string,
  payload?: any,
  allowedStatuses?: number[],
  abort?: AbortController
): Promise<T> {
  return await unauthenticatedFetch<T>(
    "POST",
    path,
    payload,
    allowedStatuses,
    abort
  );
}

export async function unauthenticatedPutFetch<T = any>(
  path: string,
  payload?: any,
  allowedStatuses?: number[],
  abort?: AbortController
): Promise<T> {
  return await unauthenticatedFetch<T>(
    "PUT",
    path,
    payload,
    allowedStatuses,
    abort
  );
}
