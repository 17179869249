import { memo, useState, type ReactNode } from "react";

import { ReturnIf } from "babel-plugin-transform-functional-return";
import useSWR from "swr";

import Typography from "@mui/material/Typography";

import { useConnectionStore } from "stores/ConnectionStore";
import { useEncryptionStore } from "stores/EncryptionStore";
import { useFlairStore } from "stores/FlairStore";
import { swrFetcher, SecretState } from "stores/lib";

import { asyncOrSwimWithSentry } from "utility/async_or_swim_sentry";
import { captureSentryException } from "utility/capture_sentry_exception";
import { notifyError, notifySuccess } from "utility/notify";

import { usePageTitle } from "effect/use_page_title";

import { Page } from "Component/Page";
import { PageContent } from "Component/PageContent";
import { PageHeader } from "Component/PageHeader";
import { PageHeaderBackButton } from "Component/PageHeaderBackButton";
import { PageTitle } from "Component/PageTitle";
import { ProvisioningErrors } from "Component/ProvisioningErrors";
import { ServiceConnectionInstructionPhase } from "Component/ServiceConnectionInstructionPhase";
import { ServiceConnectionPhases } from "Component/ServiceConnectionPhases";

import { SnowflakeConnectPageError as Error } from "./Error";
import { SnowflakeConnectPageCompletedPhase as CompletedPhase } from "./Phase/Completed";
import { SnowflakeConnectPageTypePhase as ConnectionTypePhase } from "./Phase/ConnectionType";
import { SnowflakeConfigurePageGetOAuthUrlPhase as GetOAuthUrlPhase } from "./Phase/GetOAuthUrl";
import { SnowflakeConfigurePageNavigateOAuthPhase as NavigateOAuthPhase } from "./Phase/NavigateOAuth";
import { SnowflakeConnectPageOAuthPhase as OAuthPhase } from "./Phase/OAuthConfiguration";
import { SnowflakeConfigurePagePendingPhase as PendingPhase } from "./Phase/Pending";
import { SnowflakeConfigurePageSubmittingPhase as SubmittingPhase } from "./Phase/Submitting";
import { SnowflakeConnectPageUserPassPhase as UserPassPhase } from "./Phase/UserPasswordConfiguration";

// -----------------------------------------------------------------------------

enum Phase {
  ConnectionType,
  EnterDetails,
  Provisioning,
  Done,
  Error,
}

enum ProvisionUserPassPhase {
  Submitting,
  Pending,
  Done, // might not need this one
}

enum ProvisionOauthPhase {
  GetOauthUrl,
  NavigateOAuth,
  Done, // might not need this one
}

const ProvisionURL = "api/organization/connections/snowflake/provision/check";

// -----------------------------------------------------------------------------

export const SnowflakeConnectPage = memo(() => {
  usePageTitle("Environment → Sources → Snowflake → Connect");

  //
  const [connectionStore, connectionActions] = useConnectionStore();
  const [, encryptionActions] = useEncryptionStore();
  const [, flairActions] = useFlairStore();

  const [phase, setPhase] = useState(Phase.EnterDetails);
  const [provisionUserPassPhase, setProvisionUserPassPhase] = useState(
    ProvisionUserPassPhase.Submitting
  );
  const [oauthPhase, setOauthPhase] = useState(ProvisionOauthPhase.GetOauthUrl);
  const [refreshInterval, setRefreshInterval] = useState(0);
  const [errorMessage, setErrorMessage] = useState<ReactNode | undefined>(
    undefined
  );

  const [connectionType, setConnectionType] = useState("oauth");
  const [baseUrl, setBaseUrl] = useState("");
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [connectionID, setConnectionID] = useState("");
  const [oauthUrl, setOAuthUrl] = useState<string>("");

  //
  useSWR<ProvisionStateResponse>(
    ProvisionURL,
    phase === Phase.Provisioning &&
      oauthPhase < ProvisionOauthPhase.NavigateOAuth
      ? async (url) =>
          await swrFetcher(url, {
            method: "POST",
            body: JSON.stringify({
              connection_uuid: connectionID,
            }),
          })
      : null,
    {
      refreshInterval,
      onError: (error) => {
        captureSentryException(
          error,
          "Failed to validate Snowflake provisioning"
        );
        setRefreshInterval(0);
        setPhase(Phase.Error);
        setErrorMessage(<ProvisioningErrors data={error} />);
        notifyError("There was an error connecting to Snowflake");
      },

      onSuccess: (data) => {
        switch (data.state) {
          case SecretState.RECEIVED:
            if (connectionType === "oauth") {
              setRefreshInterval(0);
              setOauthPhase(ProvisionOauthPhase.GetOauthUrl);
              getOAuthUrl(data);
              break;
            }
            setProvisionUserPassPhase(ProvisionUserPassPhase.Submitting);
            break;

          // PENDING_INTERNAL happens when we're creating the connection in the backend,
          // verifying secrets like user/pass,
          // or generating the OAuth URL
          case SecretState.PENDING_INTERNAL:
            if (connectionType === "oauth") {
              break;
            }
            setProvisionUserPassPhase(ProvisionUserPassPhase.Pending);
            break;

          // PENDING_EXTERNAL happens when we've made the OAuth URL
          // but we're waiting for the user to click through the OAuth prompts
          // It shouldn't happen if they're doing user/pass auth
          case SecretState.PENDING_EXTERNAL:
            if (connectionType === "oauth") {
              getOAuthUrl(data);
              setOauthPhase(ProvisionOauthPhase.NavigateOAuth);
              break;
            }
            setProvisionUserPassPhase(ProvisionUserPassPhase.Pending);
            break;

          case SecretState.CORRECT:
            setRefreshInterval(0);
            setProvisionUserPassPhase(ProvisionUserPassPhase.Done);
            setOauthPhase(ProvisionOauthPhase.Done);
            setPhase(Phase.Done);
            notifySuccess("Successfully connected to Snowflake");

            // celebration on first connection
            if (connectionStore.connections.size < 1) {
              flairActions.startCelebration();
            }
            break;

          case SecretState.INCORRECT:
          case SecretState.ERROR:
            setRefreshInterval(0);
            setPhase(Phase.Error);
            setErrorMessage(<ProvisioningErrors data={data} />);
            notifyError("There was an error connecting to Snowflake");
            break;

          default:
            setRefreshInterval(0);
            setPhase(Phase.Error);
            setErrorMessage(
              "There was an error configuring your Snowflake connection"
            );
            notifyError("There was an error connecting to Snowflake");
            break;
        }
      },
    }
  );

  //
  function connectToSnowflake() {
    asyncOrSwimWithSentry(
      async () => {
        setProvisionUserPassPhase(ProvisionUserPassPhase.Submitting);

        const connectionInfo =
          connectionType === "oauth"
            ? {
                snowflake_client_id: clientId,
                snowflake_client_secret: clientSecret,
              }
            : {
                snowflake_username: username,
                snowflake_password: password,
              };

        const trimmedBaseUrl =
          baseUrl.split("snowflakecomputing.com")[0] + "snowflakecomputing.com";

        await connectionActions.createSnowflakeConnection({
          ...(await encryptionActions.getPublicKey()),
          data: {
            snowflake_base_url: trimmedBaseUrl,
            ...connectionInfo,
          },
          onSuccess: (data: any) => {
            setConnectionID(data.connection_uuid);
            setRefreshInterval(2000);
            setPhase(Phase.Provisioning);
          },
        });
      },
      "Failed to create Snowflake connection",
      (error) => {
        setRefreshInterval(0);
        setErrorMessage(error?.message ?? "There was an error");
        setPhase(Phase.Error);
        notifyError("There was an error connecting to Snowflake");
      }
    );
  }

  function retryConnection() {
    setOAuthUrl("");
    setConnectionID("");
    setErrorMessage(undefined);
    setProvisionUserPassPhase(ProvisionUserPassPhase.Submitting);
    setPhase(Phase.EnterDetails);
  }

  function getOAuthUrl(data: ProvisionStateResponse) {
    try {
      const details = JSON.parse(data.secret_details ?? "");

      const redirectUrl: string = `${
        details.auth_grant_url
      }?${new URLSearchParams({
        client_id: clientId,
        redirect_uri: encodeURI(
          `${window.location.origin}/environment/sources/snowflake/oauth2`
        ),
        state: connectionID,
        response_type: "code",
      } as any).toString()}`;

      setOauthPhase(ProvisionOauthPhase.NavigateOAuth);
      setOAuthUrl(redirectUrl);
    } catch (error: any) {
      setErrorMessage(error?.message ?? "There was an error");
      setPhase(Phase.Error);
      notifyError("There was an error connecting to Snowflake");
    }
  }

  //
  return (
    <Page>
      <PageHeader>
        <PageHeaderBackButton to="/environment/sources">
          Back to Sources
        </PageHeaderBackButton>
        <PageTitle>Snowflake Connection</PageTitle>
      </PageHeader>

      <PageContent>
        <ServiceConnectionPhases>
          <ServiceConnectionInstructionPhase>
            <Typography variant="body1">
              Connect your ThreatKey account with Snowflake in a just a few easy
              steps. Once connected, you can see all of your results on the
              Findings page.
            </Typography>
          </ServiceConnectionInstructionPhase>

          <ConnectionTypePhase
            disabled={phase !== Phase.EnterDetails}
            connectionType={connectionType}
            onConnectionTypeChange={(newType: string) => {
              setConnectionType(newType);
              setClientId("");
              setClientSecret("");
              setUsername("");
              setPassword("");
            }}
          />

          {connectionType === "oauth" && (
            <OAuthPhase
              disabled={phase !== Phase.EnterDetails}
              baseUrl={baseUrl}
              clientId={clientId}
              clientSecret={clientSecret}
              onChangeBaseUrl={setBaseUrl}
              onChangeClientId={setClientId}
              onChangeClientSecret={setClientSecret}
              onNext={() => {
                ReturnIf(!clientId || !clientSecret || !baseUrl);
                connectToSnowflake();
              }}
            />
          )}

          {connectionType === "userpass" && (
            <UserPassPhase
              disabled={phase !== Phase.EnterDetails}
              baseUrl={baseUrl}
              username={username}
              password={password}
              onChangeBaseUrl={setBaseUrl}
              onChangeUsername={setUsername}
              onChangePassword={setPassword}
              onNext={() => {
                ReturnIf(!username || !password || !baseUrl);
                connectToSnowflake();
              }}
            />
          )}

          {phase >= Phase.Provisioning ? (
            <>
              {connectionType === "oauth" && (
                <>
                  <GetOAuthUrlPhase
                    disabled={oauthPhase !== ProvisionOauthPhase.GetOauthUrl}
                    progressIcon={
                      oauthPhase === ProvisionOauthPhase.GetOauthUrl &&
                      phase !== Phase.Error
                    }
                    successIcon={oauthPhase > ProvisionOauthPhase.GetOauthUrl}
                    errorIcon={
                      oauthPhase === ProvisionOauthPhase.GetOauthUrl &&
                      phase === Phase.Error
                    }
                  />

                  <Error
                    show={
                      oauthPhase === ProvisionOauthPhase.GetOauthUrl &&
                      phase === Phase.Error
                    }
                    onTryAgain={retryConnection}
                  >
                    {errorMessage}
                  </Error>

                  <NavigateOAuthPhase
                    disabled={oauthPhase !== ProvisionOauthPhase.NavigateOAuth}
                    progressIcon={
                      oauthPhase === ProvisionOauthPhase.NavigateOAuth &&
                      phase !== Phase.Error
                    }
                    successIcon={oauthPhase > ProvisionOauthPhase.NavigateOAuth}
                    errorIcon={
                      oauthPhase === ProvisionOauthPhase.NavigateOAuth &&
                      phase === Phase.Error
                    }
                    oauthUrl={oauthUrl}
                  />
                </>
              )}

              {connectionType === "userpass" && (
                <>
                  <SubmittingPhase
                    disabled={
                      provisionUserPassPhase !==
                      ProvisionUserPassPhase.Submitting
                    }
                    progressIcon={
                      provisionUserPassPhase ===
                        ProvisionUserPassPhase.Submitting &&
                      phase !== Phase.Error
                    }
                    successIcon={
                      provisionUserPassPhase > ProvisionUserPassPhase.Submitting
                    }
                    errorIcon={
                      provisionUserPassPhase ===
                        ProvisionUserPassPhase.Submitting &&
                      phase === Phase.Error
                    }
                  />

                  <Error
                    show={
                      provisionUserPassPhase ===
                        ProvisionUserPassPhase.Submitting &&
                      phase === Phase.Error
                    }
                    onTryAgain={retryConnection}
                  >
                    {errorMessage}
                  </Error>

                  <PendingPhase
                    disabled={
                      provisionUserPassPhase !== ProvisionUserPassPhase.Pending
                    }
                    progressIcon={
                      provisionUserPassPhase ===
                        ProvisionUserPassPhase.Pending && phase !== Phase.Error
                    }
                    successIcon={
                      provisionUserPassPhase > ProvisionUserPassPhase.Pending
                    }
                    errorIcon={
                      provisionUserPassPhase ===
                        ProvisionUserPassPhase.Pending && phase === Phase.Error
                    }
                  />

                  <Error
                    show={
                      provisionUserPassPhase ===
                        ProvisionUserPassPhase.Pending && phase === Phase.Error
                    }
                    onTryAgain={retryConnection}
                  >
                    {errorMessage}
                  </Error>
                </>
              )}
              <CompletedPhase
                disabled={phase !== Phase.Done}
                successIcon={phase === Phase.Done}
                onClickFn={() => {
                  connectionActions.loadConnections({ reload: true });
                }}
              />
            </>
          ) : null}
        </ServiceConnectionPhases>
      </PageContent>
    </Page>
  );
});

// -----------------------------------------------------------------------------

interface ProvisionStateResponse {
  state: SecretState;
  secret_state?: SecretState;
  secret_details?: string;
  details: any;
}
