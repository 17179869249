export class ResponseError extends Error {
  status: number = -1;
  payload: any = undefined;

  constructor(message: string, status: number, payload: any = undefined) {
    super(message);

    this.status = status;
    this.payload = payload;
  }
}
