import React, { memo } from "react";

import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";

// -----------------------------------------------------------------------------

export const NotificationConfigItem = memo(
  ({ enabled, label, description, setEnabled, children }: Props) => {
    return (
      <Paper elevation={1}>
        <Box
          sx={{
            p: 1.5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              flexWrap: "wrap",
              mb: 2,
            }}
          >
            <Typography variant="h6">{label}</Typography>
            <span style={{ flexGrow: 1 }} />
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={enabled}
                    onChange={() => setEnabled?.(!enabled)}
                  />
                }
                label={enabled ? "Enabled" : "Disabled"}
              />
            </FormGroup>
          </Box>
          <Stack spacing={2}>
            {description}
            {children}
          </Stack>
        </Box>
      </Paper>
    );
  }
);

// -----------------------------------------------------------------------------

interface Props {
  children?: React.ReactNode;
  label: string;
  description: React.ReactNode;
  enabled: boolean;
  setEnabled: Function;
}
