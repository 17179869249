import { memo, useEffect, useState } from "react";

import styled from "styled-components";

import LoadingButton from "@mui/lab/LoadingButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";

import { SubmitState } from "stores/lib";
import { useOrganizationStore } from "stores/OrganizationStore";
import { useUserStore } from "stores/UserStore";

import { notifyError, notifySuccess } from "utility/notify";

import { usePageTitle } from "effect/use_page_title";

// -----------------------------------------------------------------------------

export const SettingsSupportAccess = memo(() => {
  usePageTitle("Settings → Support Access");

  //
  const [userStore] = useUserStore();
  const [organizationStore, organizationActions] = useOrganizationStore();

  const [supportAccess, setSupportAccess] = useState(
    !(organizationStore.organization.supportAccess == null)
  );
  const [isLoading, setIsLoading] = useState(false);

  //
  useEffect(() => {
    setSupportAccess(!(organizationStore.organization.supportAccess == null));
  }, [organizationStore]);

  // patch errors no longer appears to propagate so I conformed with toasts per similar pages
  useEffect(() => {
    switch (organizationStore.patchState) {
      case SubmitState.SUCCESS:
        notifySuccess("Settings saved");
        setIsLoading(false);
        organizationActions.clearPatchState();
        organizationActions.clearPatchErrors();
        break;

      case SubmitState.ERROR:
        notifyError(`Failed to save settings`);
        setIsLoading(false);
        organizationActions.clearPatchState();
        organizationActions.clearPatchErrors();
        break;
    }
  }, [organizationStore.patchState, organizationActions]);

  //
  function submitForm() {
    setIsLoading(true);
    organizationActions.patch({
      support_access: supportAccess ? new Date() : null,
    });
  }

  //
  const isNotSuperAdmin = userStore?.user?.permissionLevel !== "SuperAdmin";

  //
  return (
    <FormGroup>
      <Container
        elevation={1}
        sx={{ p: 2 }}
        className={isNotSuperAdmin ? "disabled" : undefined}
      >
        <Typography variant="h6" sx={{ mb: 1 }} fontWeight="bold">
          ThreatKey Support Access
        </Typography>
        <Typography variant="body1" sx={{ mb: 1 }}>
          If you&apos;re having trouble with your workspace, you can turn on
          access for the ThreatKey Customer Support team. They&apos;ll be able
          to help debug any issues you&apos;re having.
        </Typography>
        <Typography variant="body1" sx={{ mb: 1 }}>
          Note: ThreatKey will be given administration privileges to your
          organization during this time. You may revoke these privileges at any
          time.
        </Typography>
        <FormControlLabel
          control={
            <Switch
              name="support_access"
              disabled={isLoading || isNotSuperAdmin}
              checked={supportAccess}
              onChange={(event) => setSupportAccess(event.target.checked)}
            />
          }
          label={
            <Typography variant="body1">
              Yes! I want to allow ThreatKey support to access my organization.
            </Typography>
          }
        />
      </Container>

      <Box sx={{ mt: 3 }}>
        <LoadingButton
          variant="contained"
          color="primary"
          loading={isLoading}
          disabled={isLoading || isNotSuperAdmin}
          onClick={submitForm}
        >
          Save Changes
        </LoadingButton>
      </Box>
    </FormGroup>
  );
});

// -----------------------------------------------------------------------------

const Container = styled(Paper)`
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;
