import { setAccessTokens } from "utility/credential_storage";

// For non-production clients, authorization tokens are passed through the URL.
// We put the tokens into cookies to as closely mimic production as possible.
// -----------------------------------------------------------------------------

if (window.location.host !== "app.threatkey.com") {
  const params = new URLSearchParams(document.location.search);
  const accessToken = params.get("JWT_AccessToken");
  const refreshToken = params.get("JWT_RefreshToken");

  if (accessToken && refreshToken) {
    setAccessTokens(accessToken, refreshToken);

    window.history.pushState(null, "", window.location.pathname);
  }
}
