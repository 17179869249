import { memo } from "react";

import { ReturnIf } from "babel-plugin-transform-functional-return";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";

// -----------------------------------------------------------------------------

export const AmazonWebServicesConnectPageError = memo(
  ({ show, onTryAgain, children }: Props) => {
    ReturnIf(!show, null);

    //
    return (
      <Alert severity="error">
        {children ?? "There was an error"}
        <Button
          variant="contained"
          color="error"
          sx={{ mt: 2 }}
          onClick={onTryAgain as React.MouseEventHandler<HTMLButtonElement>}
        >
          Try Again
        </Button>
      </Alert>
    );
  }
);

// -----------------------------------------------------------------------------

interface Props {
  show: boolean;
  onTryAgain: Function;
  children: React.ReactNode;
}
