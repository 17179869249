import { memo } from "react";

import { usePageTitle } from "effect/use_page_title";

import { Page } from "Component/Page";
import { PageContent } from "Component/PageContent";
import { PageHeader } from "Component/PageHeader";
import { PageTitle } from "Component/PageTitle";

import { BillingPageDashboard as Dashboard } from "./Dashboard";

// -----------------------------------------------------------------------------

export const BillingPage = memo(() => {
  usePageTitle("Billing");

  //
  return (
    <Page>
      <PageHeader>
        <PageTitle>Billing</PageTitle>
      </PageHeader>

      <PageContent>
        <Dashboard />
      </PageContent>
    </Page>
  );
});
