import { memo, useEffect, useState } from "react";

import { BreakIf, ThrowIf } from "babel-plugin-transform-functional-return";

import Typography from "@mui/material/Typography";

import {
  loadAllConnections,
  useConnectionStore,
  type Connection,
} from "stores/ConnectionStore";
import { SecretState, swrFetcher } from "stores/lib";
import { useOrganizationStore } from "stores/OrganizationStore";

import { asyncOrSwim } from "utility/async_or_swim";
import { captureSentryException } from "utility/capture_sentry_exception";

import { usePageTitle } from "effect/use_page_title";

import { Page } from "Component/Page";
import { PageContent } from "Component/PageContent";
import { PageHeader } from "Component/PageHeader";
import { PageHeaderBackButton } from "Component/PageHeaderBackButton";
import { PageTitle } from "Component/PageTitle";
import { ServiceConnectionInstructionPhase } from "Component/ServiceConnectionInstructionPhase";
import { ServiceConnectionPhases } from "Component/ServiceConnectionPhases";

import { AzureConnectPageErrorPhase as ErrorPhase } from "./Phase/ErrorPhase";
import { AzureConnectPageRunCommandPhase as RunCommandPhase } from "./Phase/RunCommandPhase";
import { AzureConnectPageSuccessPhase as SuccessPhase } from "./Phase/SuccessPhase";
import { AzureConnectPageTestingPhase as TestingPhase } from "./Phase/TestingPhase";

// -----------------------------------------------------------------------------

enum Phase {
  DeleteAllBadAzureConnections = -1,
  LoadingNonce = 1,
  RunPowerShellCommand = 2,
  TestingConnection = 3,
  DisplayingResults = 4,
}

const ProvisionURL = "api/organization/connections/azure/provision";

// -----------------------------------------------------------------------------

export const AzureConnectPage = memo(() => {
  usePageTitle("Environment → Sources → Azure → Connect");

  //
  const [orgStore] = useOrganizationStore();
  const [, connectionActions] = useConnectionStore();

  const [phase, setPhase] = useState<Phase>(Phase.LoadingNonce);
  const [contactingServer, setContactingServer] = useState<boolean>(false);
  const [nonce, setNonce] = useState<string | undefined>(undefined);
  const [connectionUuid, setConnectionUuid] = useState<string | undefined>(
    undefined
  );
  const [results, setResults] = useState<{
    success: boolean;
    message?: string;
  }>({ success: false, message: "" });

  //
  useEffect(() => {
    switch (phase) {
      case Phase.DeleteAllBadAzureConnections:
        return !contactingServer
          ? asyncOrSwim(
              async () => {
                setContactingServer(true);

                // delete bad azure connections
                if (phase === Phase.DeleteAllBadAzureConnections) {
                  const badAzureConnections = await loadBadAzureConnections();
                  for (const connection of badAzureConnections) {
                    await new Promise((resolve, reject) => {
                      (async () => {
                        connectionActions.deleteConnection({
                          uuid: connection.uuid,
                          onSuccess: resolve,
                          onError: reject,
                        });
                      })();
                    });
                  }
                }

                //
                setContactingServer(false);
                setPhase(Phase.LoadingNonce);
              },
              (error) => {
                captureSentryException(
                  error,
                  "Failed to delete bad Azure connections"
                );
                setResults({
                  success: false,
                  message:
                    "There was an error contacting ThreatKey. Please try again.",
                });
                setPhase(Phase.DisplayingResults);
                setNonce(undefined);
                setConnectionUuid(undefined);
                setContactingServer(false);
              }
            )
          : undefined;
      case Phase.LoadingNonce:
        return !nonce && !contactingServer
          ? asyncOrSwim(
              async () => {
                setContactingServer(true);
                const response = await swrFetcher(ProvisionURL, {
                  method: "POST",
                });
                setPhase(Phase.RunPowerShellCommand);
                setNonce(response.nonce);
                setConnectionUuid(response.connection_uuid);
                setContactingServer(false);
              },
              (error) => {
                captureSentryException(error, "Failed to load Azure nonce");
                setResults({
                  success: false,
                  message:
                    "There was an error contacting ThreatKey. Please try again.",
                });
                setPhase(Phase.DisplayingResults);
                setNonce(undefined);
                setConnectionUuid(undefined);
                setContactingServer(false);
              }
            )
          : undefined;
    }
  }, [phase, nonce, contactingServer, connectionActions]);

  //
  function testConnection() {
    setPhase(Phase.TestingConnection);
    asyncOrSwim(
      async () => {
        setContactingServer(true);
        // if a user is too quick to press the button, the connection's secret might not be ready yet. Retry a few times to make sure.
        let success = false;
        for (let i = 0; i < 2; i++) {
          const connections = await loadAllConnections();
          const connection = connections.get(connectionUuid as string);
          if (!connection) {
            await new Promise((resolve) => setTimeout(resolve, 1500));
            continue;
          }

          success = connection.clientSecret?.state === SecretState.CORRECT;
          BreakIf(success);
        }
        ThrowIf(
          !success,
          new Error("Couldn't find Microsoft Azure connection")
        );

        setContactingServer(false);
        setResults({
          success,
          message: success
            ? undefined
            : "Could not find a properly connected Microsoft Azure connection. Please try again.",
        });
        setPhase(Phase.DisplayingResults);
      },
      (error) => {
        captureSentryException(error, "Failed validating Azure connection");
        setContactingServer(false);
        setResults({
          success: false,
          message:
            "There was an error validating the connection. Please try again.",
        });
        setPhase(Phase.DisplayingResults);
      }
    );
  }

  function deleteAllBadConnectionsAndRestartConfiguration() {
    setNonce(undefined);
    setConnectionUuid(undefined);
    setPhase(Phase.DeleteAllBadAzureConnections);
  }

  //
  const powerShellCommand = nonce
    ? `& ([scriptblock]::Create((irm
    https://s3.amazonaws.com/threatkey-assets/azure/Provision-ThreatKeyAzure.ps1)))
    -ThreatKeyOrganizationId "${orgStore.uuid}" -ThreatKeyNonce "
    ${nonce}" -ServicePrincipalPermissionSet ReadOnly`
    : "";

  //
  return (
    <Page>
      <PageHeader>
        <PageHeaderBackButton to="/environment/sources">
          Back to Sources
        </PageHeaderBackButton>
        <PageTitle>Microsoft Azure Connection</PageTitle>
      </PageHeader>

      <PageContent>
        <ServiceConnectionPhases>
          <ServiceConnectionInstructionPhase>
            <Typography variant="body1">
              Connect your ThreatKey account with Microsoft Azure in a just a
              few easy steps. Once connected, you can see all of your results on
              the Findings page.
            </Typography>
          </ServiceConnectionInstructionPhase>
          <RunCommandPhase
            shown={phase <= Phase.RunPowerShellCommand}
            loading={contactingServer}
            command={powerShellCommand}
            onNext={testConnection}
          />
          <TestingPhase shown={phase === Phase.TestingConnection} />
          <ErrorPhase
            shown={phase === Phase.DisplayingResults && !results.success}
            onNext={deleteAllBadConnectionsAndRestartConfiguration}
            message={results?.message}
          />
          <SuccessPhase
            shown={phase === Phase.DisplayingResults && results.success}
          />
        </ServiceConnectionPhases>
      </PageContent>
    </Page>
  );
});

// -----------------------------------------------------------------------------

async function loadBadAzureConnections() {
  return Object.values(await loadAllConnections()).filter(
    outGoodAzureConnections
  );
}

function outGoodAzureConnections(connection: Connection) {
  return (
    connection.kind === "azure_sp" &&
    connection.clientSecret?.state !== SecretState.CORRECT
  );
}
