import { type FOTS } from "data/typescript";

import { authenticatedGetFetch } from "utility/fetch/authenticated";

// -----------------------------------------------------------------------------

export async function accountHasConnections(): Promise<boolean> {
  const responsePayload = await authenticatedGetFetch<{
    results: FOTS[];
  }>(`api/organization/connections?page_size=1`);

  //
  return responsePayload?.results?.length > 0;
}
