import styled from "styled-components";

import Link from "@mui/material/Link";
import Skeleton from "@mui/material/Skeleton";

import DownloadIcon from "@mui/icons-material/Download";

import { toCurrency } from "utility/currency";
import { dayjs } from "utility/dayjs";

// -----------------------------------------------------------------------------

export const Columns = [
  {
    field: "payment_date",
    headerName: "Payment Date",
    flex: 1,
    sortable: false,
    renderCell: (params: any) => (
      <>{dayjs(params.row.created * 1000).format("MM/DD/YYYY")}</>
    ),
  },
  {
    field: "plan",
    headerName: "Plan",
    flex: 1,
    sortable: false,
    renderCell: (params: any) => (
      <>{params.row.subscription_label || "Unknown"}</>
    ),
  },
  {
    field: "amount",
    headerName: "Amount",
    flex: 1,
    sortable: false,
    renderCell: (params: any) => (
      <>{toCurrency(params.row.amount, params.row.currency)}</>
    ),
  },
  {
    field: "download_invoice",
    headerName: "Download Invoice",
    flex: 1,
    sortable: false,
    renderCell: (params: any) =>
      params.row.invoice_url ? (
        <Link
          href={params.row.invoice_url}
          style={{
            color: "icons.main",
            display: "flex",
            alignItems: "center",
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <DownloadIcon />
        </Link>
      ) : null,
  },
];

export const LoadingColumns = [
  {
    field: "payment_date",
    headerName: "Payment Date",
    flex: 1,
    renderCell: RenderSkeleton,
  },
  {
    field: "plan",
    headerName: "Plan",
    flex: 1,
    renderCell: RenderSkeleton,
  },
  {
    field: "amount",
    headerName: "Amount",
    flex: 1,
    renderCell: RenderSkeleton,
  },
  {
    field: "download_invoice",
    headerName: "Download Invoice",
    flex: 1,
    renderCell: RenderSkeleton,
  },
];

function RenderSkeleton() {
  return <SkeletonStyled />;
}

export const LoadingRows: any = [
  { id: 1 },
  { id: 2 },
  { id: 3 },
  { id: 4 },
  { id: 5 },
  { id: 6 },
  { id: 7 },
  { id: 8 },
  { id: 9 },
  { id: 10 },
];

// -----------------------------------------------------------------------------

export const PaperStyle = {
  p: 0,
  width: "100%",
};

export const DataGridStyle = {
  border: 0,
  minHeight: 348,

  "& .MuiTablePagination-displayedRows": {
    lineHeight: "1 !important",
    margin: 0,
  },

  "& .MuiDataGrid-cell": {
    border: 0,
    wordBreak: "break-all",
    p: 3,

    "&:focus": {
      outline: "none",
    },
  },

  "& .MuiDataGrid-row": {
    maxHeight: "none !important",
  },

  "& .MuiDataGrid-columnHeaders": {
    border: 0,
    maxHeight: "none !important",
  },

  "& .MuiDataGrid-virtualScroller": {
    marginTop: "48px !important",
  },

  "& .MuiDataGrid-columnHeaderTitleContainer": {
    display: "flex",
    alignItem: "center",
    height: 48,
  },

  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: "bold",
    p: 2,
    pt: 1,
    pb: 0,
  },

  "& .MuiDataGrid-columnSeparator": {
    display: "none !important",
  },

  "& .arrow-cell": {
    minWidth: "32px !important",
    justifyContent: "center",
    width: "32px !important",
  },

  "& .MuiDataGrid-footerContainer": {
    border: 0,
  },
};

// -----------------------------------------------------------------------------

const SkeletonStyled = styled(Skeleton)`
  width: 100%;
`;
