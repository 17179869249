import { memo } from "react";
import { Link, type LinkProps } from "react-router-dom";

import omit from "lodash/omit";
import styled, { css } from "styled-components";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { classIf } from "utility/style";

// -----------------------------------------------------------------------------

export const SidebarButton = memo((props: Props) => {
  const { label, Icon, topLevel, external, iconOnly } = props;
  const LinkComponent: any = external
    ? ListItemButtonAnchor
    : ListItemButtonLink;
  const linkProps = external
    ? {
        ...omit(
          props,
          "to",
          "label",
          "Icon",
          "topLevel",
          "external",
          "iconOnly"
        ),
        href: props.to,
      }
    : omit(props, "label", "Icon", "topLevel", "external", "iconOnly");

  //
  return (
    <LinkComponent {...linkProps} className={classIf(topLevel, "topLevel")}>
      <ListItemButton>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        {!iconOnly && (
          <ListItemText sx={{ color: "primary.text" }} primary={label} />
        )}
      </ListItemButton>
    </LinkComponent>
  );
});

// -----------------------------------------------------------------------------

function LinkStyle() {
  return css`
    align-items: center;
    color: ${(props) => props.theme.primary.text};
    display: block;
    min-height: 0vw;
    padding-left: clamp(4px, calc(0.25rem + ((1vw - 8px) * 3)), 16px);
    text-decoration: none;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .MuiListItemIcon-root {
      min-width: 32px;
      width: 32px;
    }

    .MuiListItemButton-root {
      height: 48px;

      svg {
        fill: ${(props) => props.theme.appHeader.main};
      }
    }

    &.topLevel {
      padding-left: 0;

      .MuiListItemButton-root svg {
        opacity: 1;
      }
    }
  `;
}

const ListItemButtonLink = styled(Link)`
  ${LinkStyle}
`;

const ListItemButtonAnchor = styled.a`
  ${LinkStyle}
`;

// -----------------------------------------------------------------------------

interface Props extends LinkProps {
  label: string;
  Icon: React.ElementType;
  topLevel?: boolean;
  external?: boolean;
  iconOnly?: boolean;
}
