import { GoogleSheetConnectPage } from "./Connect";
import { redirectToGoogleSheetPage } from "./connect_handler";
import { GoogleSheetCoverImage } from "./CoverImage";
import { GoogleSheetConnectedExtraConnectionDetails } from "./ExtraDetails";

// -----------------------------------------------------------------------------

export { GoogleSheetConnectPage };

export const GoogleSheetAppConfig = {
  name: "google_sheet",
  label: "Google Sheet Sync",
  coverImage: GoogleSheetCoverImage,
  onConnect: redirectToGoogleSheetPage,
  searchTerms: ["google", "sheet", "sheets"],
  url: "google-sheet",
  onExtraConnectionDetails: GoogleSheetConnectedExtraConnectionDetails,
};
