import { memo } from "react";

import { ReturnIf } from "babel-plugin-transform-functional-return";

import Box from "@mui/material/Box";

import { type ServerFinding as ServerFindingWithType } from "data/finding";
import { type FOTS } from "data/typescript";

import { hasNo } from "utility/has";

import { FindingsPageListFinding as ListFinding } from "Component/Page/Findings/ListPane/ListFinding";

// -----------------------------------------------------------------------------

export const SelectedFindingsList = memo(({ findings }: Props) => {
  ReturnIf(hasNo(findings), null);

  //
  return (
    <Box
      sx={{
        maxHeight: "100%",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {findings.map((finding, i) => {
        return (
          <ListFinding
            limitedView
            key={finding.uuid}
            finding={finding as FOTS}
            startExpanded={i === 0}
          />
        );
      })}
    </Box>
  );
});

// -----------------------------------------------------------------------------

interface Props {
  findings: ServerFindingWithType[];
}
