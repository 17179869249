import { captureSentryException } from "utility/capture_sentry_exception";

// -----------------------------------------------------------------------------

/**
 * A variant of `asyncOrSwim` that can be awaited. Captured errors emit Sentry
 * logs and are thrown when no errorHandler is provided.
 *
 * @see `utility/async_or_swim`
 *
 * @param action - The asynchronous function being wrapped
 * @param sentryError - Typical naming scheme:
 * `functionCallingTrying error` (e.g., `tryingToLogin error`)
 * @param errorHandler - An optional errorHandler; useful when trying is being
 * used more like `asyncOrSwim`
 * @returns
 */
export async function trying<T = void>(
  action: () => Promise<T>,
  sentryError: string,
  errorHandler?: (error: Error) => Promise<T> | T
): Promise<T> {
  try {
    return await action();
  } catch (error) {
    captureSentryException(error, sentryError);

    //
    if (!errorHandler) {
      throw error;
    }

    //
    return await errorHandler(error as Error);
  }
}
