import { TinesWebhookCoverImage } from "./CoverImage";

// ----------------------------------------------------------------------------- ∏

export const TinesWebhookAppConfig = {
  name: "tines_webhook",
  label: "Tines Webhooks",
  singularLabel: "Tines Webhook",
  coverImage: TinesWebhookCoverImage,
  searchTerms: [],
  url: "tines_webhook",
  connectInfo: {
    instructions: {
      example: "Tines instance",
    },
    placeholder:
      "https://<tenant-domain>.tines.com/webhook/<action-identifier>/<secret-token>",
    // Disable this check so we match the backend regex exactly.
    // eslint-disable-next-line no-useless-escape
    regex: /^https:\/\/[a-zA-Z0-9-.]+\/webhook\/[a-zA-Z0-9-]+\/[a-zA-Z0-9-]+$/,
    path: "tines/webhook",
  },
  notifications: true,
  notificationKey: "tines_webhook",
};
