import { memo, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";

import { useOrganizationStore } from "stores/OrganizationStore";

import { getGoogleOAuthUrl } from "utility/google";

import { usePageTitle } from "effect/use_page_title";

import { Page } from "Component/Page";
import { PageContent } from "Component/PageContent";
import { PageHeader } from "Component/PageHeader";
import { PageHeaderBackButton } from "Component/PageHeaderBackButton";
import { PageTitle } from "Component/PageTitle";
import { ServiceConnectionInstructionPhase } from "Component/ServiceConnectionInstructionPhase";
import { ServiceConnectionPhase } from "Component/ServiceConnectionPhase";
import { ServiceConnectionPhases } from "Component/ServiceConnectionPhases";

// -----------------------------------------------------------------------------

export const GoogleWorkspaceConnectPage = memo(() => {
  usePageTitle("Environment → Sources → Google Workspace → Connect");

  //
  const [organizationStore] = useOrganizationStore();
  const [readOnly, setReadOnly] = useState(false);

  //
  const oauthURL = getGoogleOAuthUrl(organizationStore.uuid, readOnly);

  //
  return (
    <Page>
      <PageHeader>
        <PageHeaderBackButton to="/environment/sources">
          Back to Sources
        </PageHeaderBackButton>
        <PageTitle>Google Workspace Connection</PageTitle>
      </PageHeader>

      <PageContent>
        <ServiceConnectionPhases>
          <ServiceConnectionInstructionPhase>
            <Typography variant="body1">
              Connect your ThreatKey account with Google Workspace in a just a
              few easy steps. Once connected, you can see all of your results on
              the Findings page.
            </Typography>
          </ServiceConnectionInstructionPhase>
          <ServiceConnectionPhase disabled={false}>
            <FormControl>
              <FormLabel>Permissions</FormLabel>
              <RadioGroup
                value={readOnly}
                onChange={(e: any) => setReadOnly(e.target.value === "true")}
              >
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Read-write permissions"
                />
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Read-only permissions (disables fixes)"
                />
              </RadioGroup>
            </FormControl>

            <Box sx={{ mt: 2 }}>
              <Button
                variant="contained"
                component="a"
                href={oauthURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Connect
              </Button>
            </Box>
          </ServiceConnectionPhase>
        </ServiceConnectionPhases>
      </PageContent>
    </Page>
  );
});
