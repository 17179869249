import { memo, useState } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { AvailableEmailInboxes } from "./lib";

// -----------------------------------------------------------------------------

export const SettingsGeneralDCVSectionChooseEmail = memo(
  ({ isLoading, disabled, onSubmit }: Props) => {
    const [inbox, setInbox] = useState("");
    const [domain, setDomain] = useState("");
    //
    return (
      <Stack direction="row" spacing={2} alignItems="center">
        <FormControl fullWidth>
          <InputLabel id="validation_email">Email</InputLabel>
          <Select
            labelId="validation_email"
            label="Email"
            disabled={isLoading || disabled}
            value={inbox}
            displayEmpty
            onChange={(event) => setInbox(event.target.value)}
          >
            {AvailableEmailInboxes.map((inbox) => {
              return (
                <MenuItem value={inbox} key={inbox}>
                  {inbox}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Typography>@</Typography>
        <TextField
          label="Domain"
          fullWidth
          disabled={isLoading || disabled}
          placeholder="your-domain.com"
          value={domain}
          onChange={(event) => setDomain(event.target.value)}
          data-private="lipsum"
        />
        <LoadingButton
          variant="contained"
          size="small"
          loading={isLoading}
          onClick={() => onSubmit(`${inbox}@${domain}`)}
          disabled={disabled}
        >
          Enable DCV
        </LoadingButton>
      </Stack>
    );
  }
);

// -----------------------------------------------------------------------------

interface Props {
  isLoading: boolean;
  onSubmit: (arg0: string) => void;
  disabled: boolean;
}
