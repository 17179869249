import { memo, useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { captureMessage } from "@sentry/react";
import { ReturnIf } from "babel-plugin-transform-functional-return";

import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

import { type FOTS } from "data/typescript";

import { SubmitState } from "stores/lib";
import {
  type NotificationConfig,
  useNotificationStore,
} from "stores/NotificationStore";
import { SlackProgress, useSlackStore } from "stores/SlackStore";

import { notifyError, notifySuccess } from "utility/notify";

import { usePageTitle } from "effect/use_page_title";

import { NotificationConfigItem } from "Component/NotificationConfig";
import { Page } from "Component/Page";
import { PageContent } from "Component/PageContent";
import { PageHeader } from "Component/PageHeader";
import { PageHeaderBackButton } from "Component/PageHeaderBackButton";
import { PageSubTitle } from "Component/PageSubTitle";
import { PageTitle } from "Component/PageTitle";

// -----------------------------------------------------------------------------

export const SlackConfigurePage = memo(() => {
  usePageTitle("Environment → Destinations → Slack → Configure");

  const [notificationStore, notificationActions] = useNotificationStore();
  const [slackStore, slackActions] = useSlackStore();
  const { uuid } = useParams();

  const [basicChannels, setBasicChannels] = useState<string[]>([]);
  const [basicEnable, setBasicEnable] = useState(false);
  const [basicError, setBasicError] = useState(false);

  const [findingChannels, setFindingChannels] = useState<string[]>([]);
  const [findingEnable, setFindingEnable] = useState(false);
  const [findingError, setFindingError] = useState(false);

  const [findingSummaryChannels, setFindingSummaryChannels] = useState<
    string[]
  >([]);
  const [findingSummaryEnable, setFindingSummaryEnable] = useState(false);
  const [findingSummaryError, setFindingSummaryError] = useState(false);

  const [slackChannels, setSlackChannels] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  useEffect(() => {
    const config = structuredClone(notificationStore.config);
    if (!config || !uuid) {
      return;
    }

    const initialBasicChannels: string[] = [];
    const initialFindingChannels: string[] = [];
    const initialFindingSummaryChannels: string[] = [];

    switch (notificationStore.fetchingState) {
      case SubmitState.STARTED:
      case SubmitState.STARTED_AGAIN:
      case SubmitState.SUCCESS:
        config.basic_notification.outputs.slack.destinations.forEach(
          (destination) => {
            if (
              destination.connection === uuid &&
              destination.specific_location
            ) {
              initialBasicChannels.push(
                destination.specific_location.replace("#", "")
              );
            }
          }
        );
        config.finding_notification.outputs.slack.destinations.forEach(
          (destination) => {
            if (
              destination.connection === uuid &&
              destination.specific_location
            ) {
              initialFindingChannels.push(
                destination.specific_location.replace("#", "")
              );
            }
          }
        );
        config.finding_summary_notification.outputs.slack.destinations.forEach(
          (destination) => {
            if (
              destination.connection === uuid &&
              destination.specific_location
            ) {
              initialFindingSummaryChannels.push(
                destination.specific_location.replace("#", "")
              );
            }
          }
        );
        setBasicChannels(initialBasicChannels);
        setBasicEnable(initialBasicChannels.length > 0);
        setFindingChannels(initialFindingChannels);
        setFindingEnable(initialFindingChannels.length > 0);
        setFindingSummaryChannels(initialFindingSummaryChannels);
        setFindingSummaryEnable(initialFindingSummaryChannels.length > 0);
        break;

      case SubmitState.ERROR:
        captureMessage("Slack notification fetching error");
        break;
    }
  }, [notificationStore.fetchingState, notificationStore.config, uuid]);

  // Fetch a fresh config to ensure no stale data
  useEffect(() => {
    notificationActions.fetchConfig();
  }, [notificationActions]);

  const handleBasicChange = (event: any, newValue: string[] | null) => {
    setBasicError(false);
    setBasicChannels(newValue ?? []);
  };

  const handleFindingChange = (event: any, newValue: string[] | null) => {
    setFindingError(false);
    setFindingChannels(newValue ?? []);
  };

  const handleFindingSummaryChange = (
    event: any,
    newValue: string[] | null
  ) => {
    setFindingSummaryError(false);
    setFindingSummaryChannels(newValue ?? []);
  };

  const onSuccess = () => {
    setLoading(false);
    notifySuccess("Successfully saved the notification settings");
    notificationActions.fetchConfig();
  };

  const onError = () => {
    setLoading(false);
    notifyError("Something went wrong saving the notification settings");
  };

  const saveConfig = () => {
    setLoading(true);
    setBasicError(false);
    setFindingError(false);
    let error = false;

    ReturnIf(!uuid);

    const newConfig: Partial<NotificationConfig> = {};

    if (basicEnable) {
      if (basicChannels.length < 1) {
        setBasicError(true);
        error = true;
      } else {
        const destinations: FOTS = mapToDestinations(
          basicChannels,
          uuid as string
        );

        newConfig.basic_notification = {
          outputs: {
            slack: {
              enabled: true,
              destinations,
            },
          },
        };
      }
    }

    if (findingEnable) {
      if (findingChannels.length < 1) {
        setFindingError(true);
        error = true;
      } else {
        const destinations: FOTS = mapToDestinations(
          findingChannels,
          uuid as string
        );

        newConfig.finding_notification = {
          outputs: {
            slack: {
              enabled: true,
              destinations,
            },
          },
        };
      }
    }

    if (findingSummaryEnable) {
      if (findingSummaryChannels.length < 1) {
        setFindingSummaryError(true);
        error = true;
      } else {
        const destinations: FOTS = mapToDestinations(
          findingSummaryChannels,
          uuid as string
        );

        newConfig.finding_summary_notification = {
          outputs: {
            slack: {
              enabled: true,
              destinations,
            },
          },
        };
      }
    }

    ReturnIf(error, setLoading(false));

    const config =
      newConfig.basic_notification ??
      newConfig.finding_notification ??
      newConfig.finding_summary_notification
        ? newConfig
        : null;

    notificationActions.updateConfig(
      config,
      uuid as string,
      onSuccess,
      onError
    );
  };

  const getChannels = useCallback(() => {
    ReturnIf(!uuid || !slackStore.channels[uuid], setSlackChannels([]));
    setSlackChannels(
      (slackStore.channels[uuid as string] ?? [])?.map(
        ([channel, id]) => `${channel} (${id})`
      )
    );
  }, [slackStore.channels, uuid]);

  //
  useEffect(() => {
    switch (slackStore.listProgress) {
      case undefined:
      case SlackProgress.Unknown:
        slackActions.listChannels();
        break;
      case SlackProgress.Submitting:
        break;
      case SlackProgress.Success:
        getChannels();
        break;
      case SlackProgress.Error:
        captureMessage("Slack notification listing channel error");
    }
  }, [slackStore.listProgress, getChannels, slackActions]);

  //
  return (
    <Page>
      <PageHeader>
        <PageHeaderBackButton to="/environment/destinations">
          Back to Destinations
        </PageHeaderBackButton>

        <PageTitle>Slack</PageTitle>
        <PageSubTitle>
          Looking to fine-tune your Slack notifications? You&apos;ve come to the
          right place! With our notification configuration page, you can tailor
          your Slack experience to better suit your needs. Once you&apos;re
          happy with your settings, simply hit the &quot;Save&quot; button and
          you&apos;re all set!
        </PageSubTitle>
      </PageHeader>

      <PageContent>
        <Stack spacing={2}>
          {slackChannels.length < 1 && (
            <Alert severity="warning">
              This Slack connection has no channels. Notifications cannot be
              configured to be sent to any channels.
            </Alert>
          )}

          <NotificationConfigItem
            enabled={findingSummaryEnable}
            label={"Finding Summary Notifications"}
            description={
              <>
                <Typography variant="body1">
                  Summary Notifications for security findings can be configured
                  in a variety of ways, including the ability to receive a
                  real-time summarized notification whenever new security
                  finding(s) are generated. This way, you will be alerted as
                  soon as possible if there are any potential threats to your
                  company that ThreatKey has detected.
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  We highly recommend you only configure your notifications to
                  include summaries of security findings.
                </Typography>
              </>
            }
            setEnabled={setFindingSummaryEnable}
          >
            {findingSummaryEnable ? (
              <>
                <Typography>
                  Choose the <strong>Channel(s)</strong> you would like to send
                  finding summary notifications to.
                </Typography>

                <FormControl required fullWidth error={findingSummaryError}>
                  <Autocomplete
                    multiple
                    disableCloseOnSelect
                    id="finding-summary-channel"
                    options={slackChannels}
                    getOptionLabel={(option) => (option ? `#${option}` : "")}
                    value={findingSummaryChannels}
                    onChange={handleFindingSummaryChange}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        #{option}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        required
                        error={findingSummaryError}
                        {...params}
                        label="Channels"
                      />
                    )}
                  />
                  {findingSummaryError ? (
                    <FormHelperText>Required</FormHelperText>
                  ) : null}
                </FormControl>
                <Stack spacing={0}>
                  <Typography fontWeight="bold">
                    You must invite the ThreatKey bot to the selected channels
                    to receive notifications.
                  </Typography>
                  <Typography fontWeight="bold">
                    Complete this step by sending <code>@threatkey</code> in the
                    channels you wish to receive notifications in, and clicking
                    the &quot;Invite&quot; button.
                  </Typography>
                </Stack>
              </>
            ) : null}
          </NotificationConfigItem>

          <NotificationConfigItem
            enabled={basicEnable}
            label={"Basic Notifications"}
            description={
              "Basic Notifications include all things related to your account lifecycle (such as billing) and other common platform features"
            }
            setEnabled={setBasicEnable}
          >
            {basicEnable ? (
              <>
                <Typography>
                  Choose the <strong>Channel(s)</strong> you would like to send
                  basic notifications to.
                </Typography>

                <FormControl required fullWidth error={basicError}>
                  <Autocomplete
                    multiple
                    disableCloseOnSelect
                    id="basic-channel"
                    options={slackChannels}
                    getOptionLabel={(option) => (option ? `#${option}` : "")}
                    value={basicChannels}
                    onChange={handleBasicChange}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        #{option}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        required
                        error={basicError}
                        {...params}
                        label="Channels"
                      />
                    )}
                  />
                  {basicError ? (
                    <FormHelperText>Required</FormHelperText>
                  ) : null}
                </FormControl>
                <Stack spacing={0}>
                  <Typography fontWeight="bold">
                    You must invite the ThreatKey bot to the selected channels
                    to receive notifications.
                  </Typography>
                  <Typography fontWeight="bold">
                    Complete this step by sending <code>@threatkey</code> in the
                    channels you wish to receive notifications in, and clicking
                    the &quot;Invite&quot; button.
                  </Typography>
                </Stack>
              </>
            ) : null}
          </NotificationConfigItem>

          <NotificationConfigItem
            enabled={findingEnable}
            label={"Finding Notifications"}
            description={
              <>
                <Typography variant="body1">
                  Notifications for security findings can be configured in a
                  variety of ways, including the ability to receive a real-time
                  notification whenever a new security finding is generated.
                  This way, you will receive immediate alerts when ThreatKey
                  detects any potential threats to your company.
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  We recommend you only configure your notifications to include
                  security findings for small environments, or automations that
                  action through Slack messages. This setting may result in a
                  large volume of notifications.
                </Typography>
              </>
            }
            setEnabled={setFindingEnable}
          >
            {findingEnable ? (
              <>
                <Typography>
                  Choose the <strong>Channel(s)</strong> you would like to send
                  finding notifications to.
                </Typography>

                <FormControl required fullWidth error={findingError}>
                  <Autocomplete
                    multiple
                    disableCloseOnSelect
                    id="finding-channel"
                    options={slackChannels}
                    getOptionLabel={(option) => (option ? `#${option}` : "")}
                    value={findingChannels}
                    onChange={handleFindingChange}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        #{option}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        required
                        error={basicError}
                        {...params}
                        label="Channels"
                      />
                    )}
                  />
                  {findingError ? (
                    <FormHelperText>Required</FormHelperText>
                  ) : null}
                </FormControl>
                <Stack spacing={0}>
                  <Typography fontWeight="bold">
                    You must invite the ThreatKey bot to the selected channels
                    to receive notifications.
                  </Typography>
                  <Typography fontWeight="bold">
                    Complete this step by sending <code>@threatkey</code> in the
                    channels you wish to receive notifications in, and clicking
                    the &quot;Invite&quot; button.
                  </Typography>
                </Stack>
              </>
            ) : null}
          </NotificationConfigItem>

          <Stack sx={{ mt: 2 }} spacing={2} direction="row">
            <LoadingButton
              variant="contained"
              onClick={saveConfig}
              loading={loading}
            >
              Save
            </LoadingButton>
            <Link
              to="/environment/destinations"
              style={{ textDecoration: "none" }}
            >
              <Button variant="text">Cancel</Button>
            </Link>
          </Stack>
        </Stack>
      </PageContent>
    </Page>
  );
});

// -----------------------------------------------------------------------------

function mapToDestinations(
  channels: string[],
  uuid: string
): Array<{ filter: null; connection: string; specific_location: string }> {
  return channels.map((channel) => ({
    filter: null,
    connection: uuid,
    specific_location: `#${channel}`,
  }));
}
