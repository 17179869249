import { memo, type ReactNode } from "react";

import styled from "styled-components";

// -----------------------------------------------------------------------------

export const PageContent = memo(({ children }: Props) => {
  return (
    <Container>
      <ScrollContainer>{children}</ScrollContainer>
    </Container>
  );
});

// -----------------------------------------------------------------------------

const Container = styled.div`
  flex-grow: 1;
  position: relative;
`;

const ScrollContainer = styled.div`
  inset: 0;
  overflow: auto;
  padding: clamp(8px, 0.5rem + (1vw - 3.75px) * 1.9394, 24px)
    clamp(8px, 0.5rem + (1vw - 3.75px) * 1.9394, 24px);
  position: absolute;
`;

// -----------------------------------------------------------------------------

interface Props {
  children: ReactNode;
}
