import { memo, useEffect, useState } from "react";

import styled from "styled-components";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import DownloadIcon from "@mui/icons-material/Download";

import { type FOTS } from "data/typescript";

import { useBillingStore } from "stores/BillingStore";

import { openOrtto } from "utility/ortto";

import { usePageTitle } from "effect/use_page_title";

// -----------------------------------------------------------------------------

export const SettingsPageTrustCenter = memo(() => {
  usePageTitle("Settings → Trust Center");

  //
  const [billingStore] = useBillingStore();
  const [showSOC2, setShowSOC2] = useState(false);

  //
  useEffect(() => {
    setShowSOC2(!billingStore?.subscription?.is_trial);
  }, [billingStore?.subscription?.is_trial, setShowSOC2]);

  //
  function gotoBilling() {
    openOrtto();
  }

  //
  return (
    <Sections>
      <Section elevation={1}>
        <SectionHeader variant="h6">ThreatKey Trust Center</SectionHeader>
        <Typography variant="body1">
          ThreatKey Trust Center is a centralized location for all ThreatKey
          customers to learn about the latest product security updates, security
          features, and other information. The center also serves as a location
          for ThreatKey&apos;s various compliance documents and reports.
        </Typography>
        <Typography variant="body1">
          With the ThreatKey Trust Center, you can have peace of mind knowing
          that your data is in good hands. Thank you for choosing ThreatKey as
          your trusted partner for cybersecurity.
        </Typography>
      </Section>

      <Section elevation={1}>
        <SectionHeader variant="h6">SOC 2 Type 2 Report</SectionHeader>
        <Tags>
          <Chip label="Compliance" size="small" />
        </Tags>
        <Typography variant="body1">
          ThreatKey&apos;s SOC2 Type 2 Report is available for download below.
          Our SOC2 Type 2 report provides detailed information on our security
          controls and how they meet the requirements of the SOC2 standard. This
          report is updated annually and provides detailed information on our
          security controls and procedures.
        </Typography>
        <Actions>
          {showSOC2 ? (
            <DownloadButton
              label="Download SOC 2 Type 2 Report"
              href="https://threatkey-assets.s3.us-east-1.amazonaws.com/ThreatKey_SOC2.pdf"
            />
          ) : (
            <RedirectButton
              label="Upgrade Plan to Download SOC 2 Type 2 Report"
              onClick={gotoBilling}
            />
          )}
        </Actions>
      </Section>
    </Sections>
  );
});

// -----------------------------------------------------------------------------

function DownloadButton({ label, href }: { label: string; href: string }) {
  return (
    <Button
      variant="contained"
      startIcon={<DownloadIcon />}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      download={true}
    >
      {label}
    </Button>
  );
}

function RedirectButton({ label, onClick }: { label: string; onClick: FOTS }) {
  return (
    <Button variant="contained" onClick={onClick}>
      {label}
    </Button>
  );
}

// -----------------------------------------------------------------------------

const Sections = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Section = styled(Paper)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
`;

const SectionHeader = styled(Typography)`
  font-weight: bold;
`;

const Tags = styled(Box)``;

const Actions = styled(Box)``;
