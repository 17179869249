import { FindingState, type Metrics, type ServerFinding } from "data/finding";

import { authenticatedGetFetch } from "utility/fetch/authenticated";
import { toFinding } from "utility/finding";

// -----------------------------------------------------------------------------

export async function loadDashboardMetrics(): Promise<Metrics> {
  const metrics = await authenticatedGetFetch("api/dashboard");

  //
  metrics.findings = metrics.findings
    .map(toFinding)
    .filter((a: ServerFinding) => a.state !== FindingState.Source_Disconnected)
    .sort((a: ServerFinding, b: ServerFinding) => {
      switch (true) {
        case a.severity > b.severity:
          return -1;

        case a.severity < b.severity:
          return 1;

        case a.event_time >= b.event_time:
          return -1;

        default:
          return 1;
      }
    });

  //
  return metrics;
}
