import { type Service } from "utility/connections";

import { MatterMostWebhookAppConfig } from "external/destination/MatterMostWebhook";
import { MicrosoftTeamsWebhookAppConfig } from "external/destination/MicrosoftTeamsWebhook";
import { SplunkCloudWebhookAppConfig } from "external/destination/SplunkCloudWebhook";
import { SplunkWebhookAppConfig } from "external/destination/SplunkWebhook";
import { TinesWebhookAppConfig } from "external/destination/TinesWebhook";
import { WebexWebhookAppConfig } from "external/destination/WebexWebhook";
import { ZapierWebhookAppConfig } from "external/destination/ZapierWebhook";

// -----------------------------------------------------------------------------

export const AvailableWebhookAppConfigs: Service[] = [
  MatterMostWebhookAppConfig,
  MicrosoftTeamsWebhookAppConfig,
  SplunkCloudWebhookAppConfig,
  SplunkWebhookAppConfig,
  TinesWebhookAppConfig,
  WebexWebhookAppConfig,
  ZapierWebhookAppConfig,
];
