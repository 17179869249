import { type OrgStoreState } from "stores/OrganizationStore";

// -----------------------------------------------------------------------------

export function redirectToLookerConnectPage(
  _orgStore: OrgStoreState,
  navigate: Function
): void {
  navigate("/environment/sources/looker/connect");
}
