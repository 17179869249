import { memo } from "react";

import styled from "styled-components";

import Button from "@mui/material/Button";

import { openOrtto } from "utility/ortto";

// -----------------------------------------------------------------------------

export const MagicLinkErrorContactButton = memo(() => {
  return (
    <>
      Please{" "}
      <ErrorButton
        disableRipple
        disableFocusRipple
        color="primary"
        variant="text"
        onClick={launchHelp}
      >
        contact us
      </ErrorButton>{" "}
      at <a href="mailto:support@threatkey.com">support@threatkey.com</a> for
      assistance.
    </>
  );
});

// -----------------------------------------------------------------------------

function launchHelp(e: React.MouseEvent<HTMLButtonElement>) {
  e.preventDefault();
  openOrtto();
}

// -----------------------------------------------------------------------------

const ErrorButton = styled(Button)`
  display: inline;
  font-size: inherit;
  line-height: 1;
  padding: 0;
  text-transform: lowercase;
  vertical-align: inherit;

  &:hover {
    background: none;
  }
`;
