import { memo } from "react";

import SvgIcon from "@mui/material/SvgIcon";

// -----------------------------------------------------------------------------

export const OracleSAMLSSOIcon = memo((props: object) => {
  return (
    <SvgIcon viewBox="0 0 771.75 272.31" {...props}>
      <path
        d="M316.7,329.55c-10,0-20,0-30,0-26.68.06-50-22.88-49-51.57.87-25.68,22.6-47.55,48.93-47.57q29.49,0,59,0a49.57,49.57,0,0,1,.06,99.13Zm-.2-81.21c-9.83,0-19.66-.08-29.49,0-15.84.15-29.57,14.22-30.2,30.83-.6,15.75,12.71,31.77,28.11,32.26,20.61.65,41.28.74,61.89,0,17.59-.64,29.87-15.84,29-34.11-.72-15.77-14.94-28.94-31.34-29C335.17,248.31,325.84,248.34,316.5,248.34Z"
        fill="#f10607"
        transform="translate(-237.69 -229.45)"
      />
      <path
        d="M1007.61,230.91c-3.74,5.8-7,11.16-10.64,16.24-.69,1-2.94,1.13-4.47,1.14-18,.08-36,0-54,.08-11.5,0-23.48,8.53-27.52,19.25a25.36,25.36,0,0,0-.68,3.38h92.41c-4.06,6.29-7.33,11.76-11.09,16.88-.77,1.05-3.34,1.06-5.08,1.07q-35,.09-70,0h-6c1.45,11.23,13.94,22.18,26.42,22.5,21.81.55,43.63.43,65.45.59,1.95,0,3.91,0,7,0-3.86,6-7,11.23-10.52,16.2-.64.91-2.61,1.21-4,1.22-19.67.07-39.34.28-59,0C913,329.13,892,309,889.6,286.15c-3.58-33.7,24-57,50.93-55.9,21.64.85,43.33.2,65,.23A18.21,18.21,0,0,1,1007.61,230.91Z"
        fill="#f10506"
        transform="translate(-237.69 -229.45)"
      />
      <path
        d="M496.77,310.74l3.25,2.7.84.79q5.56,4.61,11.11,9.23l1,.78.17.17.73.71,2.9,2.41,1.07,1a3.62,3.62,0,0,1,0,.93l-.92.25c-7.46,0-14.93,0-22.39-.19a38.31,38.31,0,0,1-5.76-1l-3.83-3.15-11.2-9.86-.83-.78-6.07-5.24-.93-.82-8.26-7.11-2.69-2.23-4.22-3.82-.82-.73-7.66-6.45-3.33-2.93-2.14-1.9-.94-.8-1.17-1.19-.72-1.16a48.67,48.67,0,0,1,5.35-.61c15.49,0,31,.06,46.46-.1,2.73,0,5.44-1,8.16-1.56l1-.6,4.93-4.31.43-.17,0-.45.62-1.06c.65-2,1.31-4,2-6,0-1.33-.05-2.66-.07-4l-1.83-5.2c-.17-.28-.34-.56-.52-.84l-.61-.77-2-2.19-2.92-2.08,0,0c-2-.71-3.95-2-5.93-2q-30.67-.22-61.37,0c-.94,0-1.88,1.35-2.82,2.08-.1,14.63-.27,29.26-.28,43.89,0,11.3.15,22.61.24,33.91l-.66,1.22L405,329.41l0-98.85h0l88.92-.2,1.77.23,1.18.44,2.05.45,2,.76a.8.8,0,0,0,.91.28l1.05.6,1,.38,1,.62.95.4,1.05.67,3.26,2.24c.25.19.49.39.73.59,1.41,1.47,2.83,2.94,4.24,4.42l.82,1,.85,1.13,2,3.31a.43.43,0,0,0,.28.63l1.83,4.07.27.92c.22.68.43,1.37.65,2.05.18,1.31.35,2.62.53,3.94l-.06,9-.52,4c-.22.69-.45,1.37-.67,2.06-.38,1-.76,2-1.15,3l-.5.9-1.5,3.11-.59.91-1.42,2.06-.81,1-8.21,7.14-1.14.75a.79.79,0,0,0-.87.37l-1.1.64-.9.35c-.37.22-.75.43-1.13.64l-1.89.5-1.14.48-.44-.06-.37.27-3,.77-4.94.47h-7.62l-.27,1,3.81,2.78,2.2,1.84.83.71a.58.58,0,0,0,.68.48l2.32,1.92,2.5,2.13Z"
        fill="#f00708"
        transform="translate(-237.69 -229.45)"
      />
      <path
        d="M550.51,285.51l1.34-2.14.64-.86,1.33-2.25.52-.72.59-1,1.93-3.11v0l.71-1,2.36-3.85.8-1.15,3.16-4.95.73-1.05,1.23-2.06.63-.92,1.37-2.1c.21-.3.43-.6.64-.91l.44-.89,2-3.09v0l1.95-3.06h0c.65-1,1.31-2,2-3h0l.7-1c.42-.71.84-1.42,1.27-2.12l.64-.87.45-.86.75-1.15c.4-.66.8-1.31,1.21-2l.75-1,1.21-2.06.67-.93c.47-.63.94-1.26,1.4-1.9l1.17-1,3.77-2.26,2-.86,8,0,2.92,1.12,0,0,3,2.07,2.39,2.86.67,1,1.22,2c.25.36.5.73.76,1.09l1.22,1.91.75,1.15.44.86.66.87,1.29,2.13.67,1h0l2,3h0l1.95,3.13.51.89.6.81,1.3,2.18.67.93,1.31,2.07.68,1,1.24,2,.72,1.07,1.27,1.93c.24.36.48.73.73,1.09l.42.92.64.89,3.25,5.1c.24.34.47.68.71,1h0c.66,1,1.32,2,2,3.05v0l2,3.09.52.91.57.86,1.3,2.12.69.91,1.29,2.09c.23.34.45.68.67,1l1.21,1.91.79,1.09.45,1,.61.83,1.35,2.16.64.86,1.27,2.13.7,1,1.27,2,.71,1h0c.66,1,1.31,2.08,2,3.12l2.44,3.89c.21.31.43.61.65.91l1.35,2.09.63.95,1.26,2,.72,1,2.43,4,.63.81,2,3.14,1.92,3.07c-7.53.24-15.05.51-22.58.63-.57,0-1.15-1.12-1.73-1.72l-.64-1-2.32-4-.68-1h0L637,318.31c-.17-.29-.35-.58-.53-.87l-.57-.85-1.2-2.12-.74-1v0c-1.74-.52-3.47-1.49-5.21-1.5-15.62-.12-31.24-.08-46.85-.08l-1.26-1.41-.72-1-1.29-2-.7-1-.48-1-.58-.78-2.14-3.19-.76-1.12c-.14-.3-.27-.6-.41-.89l-.65-.93-1.87-3c4.23-.32,8.45-.86,12.67-.9,12.26-.12,24.53,0,37.9,0-9.08-14.63-17.6-28.37-26.73-43.07l-27.15,43.94-1.84,3-.8,1.11c-.4.64-.8,1.27-1.19,1.91l-.69,1-1.27,2.08-.6.9-.5.87c-.65,1.05-1.3,2.1-2,3.15h0l-2,3h0l-.66,1c-.44.7-.87,1.41-1.3,2.12l-.63.87-2.43,3.95-.69,1.06-1.27,2.06-.65.94-1.32,2.16-.59.82-.49,1-1,1.06L523,328.72l1.84-3.25,0,0,.71-.94,2.36-3.89.77-1.11a.4.4,0,0,0,.36-.56c.94-1.49,1.89-3,2.83-4.45h0l.7-1,1.27-2.09.6-.9,1.39-2.11.61-.89.47-.92c.67-1,1.34-2.05,2-3.07l0,0,.72-1.06,1.23-2,.69-1,1.3-2.07.68-.93,1.29-2.17.61-.82.48-.9.74-1.12,1.22-2,.74-1,1.23-2.09Z"
        fill="#f10607"
        transform="translate(-237.69 -229.45)"
      />
      <path
        d="M777.08,312.08c-3.79,5.92-6.88,11-10.28,15.87-.67.95-2.51,1.53-3.82,1.54-20,.09-40,.5-60-.06-25.27-.72-46.31-23.56-46.24-49.28.08-26.39,20.82-49,46.35-49.62,23.31-.52,46.65-.09,70-.07a17.45,17.45,0,0,1,2.22.47c-3.59,5.53-6.82,10.88-10.5,15.9-.81,1.1-3.2,1.44-4.86,1.45-17.33.1-34.66,0-52,.07a31.86,31.86,0,0,0-32,31.08c-.54,16.5,13,31.56,29.91,32.06,21.63.64,43.28.43,64.93.59Z"
        fill="#f00607"
        transform="translate(-237.69 -229.45)"
      />
      <path
        d="M1009,379.77v5.47H238.1l-.29-5.47Z"
        fill="#090909"
        transform="translate(-237.69 -229.45)"
      />
      <path
        d="M894.37,311.39c-4,6.24-7.13,11.35-10.58,16.22a5.74,5.74,0,0,1-4.13,1.87q-42.23.15-84.45,0c-6,0-9.59-3.74-9.93-10.2-.23-4.32,0-8.66,0-13V230.7h19.4v80.69Z"
        fill="#f00808"
        transform="translate(-237.69 -229.45)"
      />
      <path
        d="M727,500.25V434.87c11.4.63,22.72.39,33.74,2.1,11.74,1.82,18.44,10.14,20.38,21.7,2.1,12.58.93,24.53-8.85,34.2-5,5-11.35,7-18,7.28C745.28,500.57,736.33,500.25,727,500.25Zm15-53.89v43c8.43.08,17,1,21.59-7.53,5-9.3,5.6-19.33,0-28.56C758.65,445.16,750.1,446.17,742,446.36Z"
        fill="currentColor"
        transform="translate(-237.69 -229.45)"
      />
      <path
        d="M611.92,501.5c-20.82,0-32.14-12.72-31-35.59,1.46-28.38,21.85-35.78,42.47-30.38,11.5,3,17.35,11.76,19,22.69a59.87,59.87,0,0,1-.94,22C637.94,494.9,628,501.5,611.92,501.5Zm-16.55-33.56c.89,4.38,1.41,9.21,2.89,13.71,2.1,6.37,7.12,9.42,13.74,9.65,6.42.22,11.29-3,13.86-9.39a39.26,39.26,0,0,0,.8-26.82,14.85,14.85,0,0,0-14.55-10.82c-6.92.11-12.16,3.86-14.36,11C596.54,459.17,596.2,463.35,595.37,467.94Z"
        fill="currentColor"
        transform="translate(-237.69 -229.45)"
      />
      <path
        d="M696.58,435.54h13.65c0,15.32.48,30.44-.19,45.5-.39,8.88-6.11,14.93-14.44,18a37.1,37.1,0,0,1-28.21-.42c-8.56-3.6-13-10.43-13.19-19.45-.35-14.44-.09-28.9-.09-43.61h14.25c0,2,0,3.87,0,5.8.14,12.65-.11,25.31.57,37.93.45,8.53,5.32,11.68,14.93,11.21,7.81-.38,12.62-5.19,12.69-13.06.11-12.16,0-24.32,0-36.48Z"
        fill="currentColor"
        transform="translate(-237.69 -229.45)"
      />
      <path
        d="M506.31,479h14.08c2,7.86-3.22,17.33-12.38,20.53-16.34,5.72-36.82,1.24-40.58-21.65-2.19-13.31-.93-26,9.59-36,10.23-9.68,29.07-9.73,38,.28,3.21,3.6,4.72,8.72,7.51,14.13h-16c-.23-.73-.58-1.75-.87-2.79-1.14-4.18-3.51-7.22-8-7.92-4.7-.73-8.53,1.09-11.33,4.84-5.87,7.87-6.39,27-1.07,35.38,2.87,4.49,7.09,5.91,12.09,4.95,4.71-.91,7.47-4.05,8.53-8.69C506.08,481.1,506.15,480.13,506.31,479Z"
        fill="currentColor"
        transform="translate(-237.69 -229.45)"
      />
      <path
        d="M534,500.08v-64.5h14.6v53h27.33v11.46Z"
        fill="currentColor"
        transform="translate(-237.69 -229.45)"
      />
    </SvgIcon>
  );
});
