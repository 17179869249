import { memo, type PropsWithChildren } from "react";

import styled from "styled-components";

import Paper from "@mui/material/Paper";

import { classIf } from "utility/style";

import { LogoIcon } from "Component/Icon/Logo";

// -----------------------------------------------------------------------------

export const LoginContainer = memo(({ children, noMaxWidth }: Props) => {
  return (
    <Container elevation={1} className={classIf(!noMaxWidth, "maxWidth")}>
      <Logo />
      {children}
    </Container>
  );
});

// -----------------------------------------------------------------------------

const Container = styled(Paper)`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 16px;
  position: relative;

  &.maxWidth {
    max-width: 420px;
    width: 100%;
  }
`;

const Logo = styled(LogoIcon)`
  bottom: 100%;
  fill: ${(props) => props.theme.appLogo.main};
  height: 40px;
  left: 50%;
  margin-bottom: 16px;
  position: absolute;
  transform: translateX(-50%);
  width: 164.17px;
`;

// -----------------------------------------------------------------------------

interface Props extends PropsWithChildren {
  noMaxWidth?: boolean;
}
// -----------------------------------------------------------------------------

interface Props extends PropsWithChildren {
  noMaxWidth?: boolean;
}
