import React, { memo, useRef, useState, type MouseEvent } from "react";
import { ObjectInspector, chromeDark, chromeLight } from "react-inspector";

import { ReturnIf } from "babel-plugin-transform-functional-return";
import styled from "styled-components";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { type FOTS } from "data/typescript";

import { useThemeModeIsLight } from "theme/index";

import { type Connection } from "stores/ConnectionStore";

import { type Service } from "utility/connections";
import { classIf, classes } from "utility/style";

// -----------------------------------------------------------------------------

export const ConnectionExtraDetails = memo(({ connection, service }: Props) => {
  const themeModeIsLight = useThemeModeIsLight();

  const detailsRef = useRef<HTMLDivElement>(null);

  const [expanded, setExpanded] = useState(false);

  //
  ReturnIf(!connection?.clientSecret?.stateDetails, null);

  //
  function getDetails() {
    const CustomDetails = service?.customExtraDetails as React.ElementType<{
      connection: Connection;
    }>;
    ReturnIf(
      CustomDetails !== undefined,
      <CustomDetails connection={connection} />
    );

    //
    const details = processStateDetails(
      connection.clientSecret?.stateDetails ?? ""
    );

    //
    ReturnIf(typeof details === "string", <Typography>{details}</Typography>);

    //
    return (
      <ObjectInspector
        theme={getTheme(themeModeIsLight)}
        data={details}
        expandLevel={99}
      />
    );
  }

  const toggleDetails = (event: MouseEvent<HTMLDivElement>) => {
    ReturnIf((event.target as FOTS).tagName === "A");

    //
    const selection = window.getSelection();
    ReturnIf(!selection?.isCollapsed);

    //
    setExpanded((value) => !value);
  };

  //
  return (
    <Container
      className={classes(classIf(expanded, "expanded"))}
      onClick={toggleDetails}
      ref={detailsRef}
    >
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <Arrow />
        <Typography>More details</Typography>
      </Box>

      <Expanded>{expanded && getDetails()}</Expanded>
    </Container>
  );
});

// -----------------------------------------------------------------------------

function processStateDetails(stateDetails: string) {
  try {
    return JSON.parse(stateDetails);
  } catch (error: any) {
    return stateDetails;
  }
}

function getTheme(themeModeIsLight: boolean): FOTS {
  return {
    ...(themeModeIsLight ? chromeLight : chromeDark),
    ...{ BASE_BACKGROUND_COLOR: "none" },
  };
}

// -----------------------------------------------------------------------------

const Arrow = styled(KeyboardArrowRightIcon)`
  margin-top: 0px;
  transition: 500ms ease;
`;

const Expanded = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 0;
  margin-left: 16px;
  margin-top: 4px;
  overflow: hidden;
  transition: 500ms ease;
`;

const Container = styled(Box)`
  cursor: pointer;
  margin-top: 8px;
  position: relative;

  &.expanded {
    ${Arrow} {
      transform: rotateZ(90deg);
    }

    ${Expanded} {
      height: 100%;
    }
  }
`;

// -----------------------------------------------------------------------------

interface Props {
  connection: Connection;
  service: Service;
}
