import { memo } from "react";

import SvgIcon from "@mui/material/SvgIcon";

// -----------------------------------------------------------------------------

export const GKEKubernetesCoverImage = memo((props: object) => {
  return (
    <SvgIcon {...props} viewBox="0 0 32 32">
      <polygon
        fill="#4285F4"
        points="20.3,17.7 27.6,21.9 27.6,23.5 19.7,18.9"
      />
      <polygon fill="#669DF6" points="12.8,18.6 4.4,23.5 3.9,22.2 12.1,17.5" />
      <rect x="15.3" y="2.1" fill="#AECBFA" width="1.4" height="9.2" />
      <path
        fill="#AECBFA"
        d="M2,8v16l14,8l14-8V8L16,0L2,8z M27,22.2l-11,6.2L5,22.2V9.8
        l11-6.2l11,6.2L27,22.2L27,22.2z"
      />
      <polygon fill="#AECBFA" points="16,15.2 22.3,11.5 16,7.9 9.6,11.5" />
      <polygon fill="#669DF6" points="15.2,16.5 9.1,12.8 9.1,20.2 15.2,23.8" />
      <polygon fill="#4285F4" points="16.8,16.5 16.8,23.8 23,20.2 23,12.8" />
    </SvgIcon>
  );
});
