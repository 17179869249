import styled from "styled-components";

import Typography from "@mui/material/Typography";

// -----------------------------------------------------------------------------

export const PageTitle = styled(Typography).attrs(() => ({
  variant: "h4",
}))`
  font-weight: bold;
  font-size: clamp(24px, calc(1.5rem + ((1vw - 3.75px) * 1.2121)), 34px);
`;
