import { memo, useState } from "react";

import Typography from "@mui/material/Typography";

import { FindingState, type ServerFinding } from "data/finding";

import { unignoreFindings } from "model/finding";

import { asyncOrSwimWithSentry } from "utility/async_or_swim_sentry";
import { notifyError, notifySuccess } from "utility/notify";
import { pluralizeCustom } from "utility/pluralize";

import { ModalActions as Actions } from "Component/Modal/Component/Actions";
import { ModalCancelButton as CancelButton } from "Component/Modal/Component/CancelButton";
import { ModalFullHeightContent as FullHeightContent } from "Component/Modal/Component/FullHeightContent";
import { FullHeightModal } from "Component/Modal/Component/FullHeightModal";
import { ModalSubContent as SubContent } from "Component/Modal/Component/SubContent";
import { ModalSubmitButton as SubmitButton } from "Component/Modal/Component/SubmitButton";
import { ModalTitle as Title } from "Component/Modal/Component/Title";

import { SelectedFindingsList } from "Component/SelectedFindingsList";

// -----------------------------------------------------------------------------

export const UnignoreFindingsModal = memo(({ findings, onClose }: Props) => {
  const [loading, setLoading] = useState(false);

  //
  function onUnignore() {
    setLoading(true);
    asyncOrSwimWithSentry(
      async () => {
        await unignoreFindings(findings.map((finding) => finding.uuid));

        //
        findings.forEach((finding) => (finding.state = FindingState.New));

        //
        notifySuccess(
          pluralizeCustom(
            findings.length,
            `Unignored the finding`,
            `Unignored ${findings.length} findings`
          )
        );
        setLoading(false);
        onClose(true);
      },
      "Failed to unignore findings",
      (error) => {
        console.error(error);
        notifyError(
          pluralizeCustom(
            findings.length,
            "Something went wrong unignoring the finding",
            `Something went wrong unignoring ${findings.length} findings`
          )
        );
        setLoading(false);
      }
    );
  }

  function onDone() {
    onClose(false);
  }

  //
  return (
    <FullHeightModal onClose={loading ? undefined : onDone}>
      <Title>
        {pluralizeCustom(
          findings.length,
          "Unignore this finding?",
          `Unignore ${findings.length} findings?`
        )}
      </Title>

      <FullHeightContent>
        <SelectedFindingsList findings={findings} />
      </FullHeightContent>

      <SubContent>
        <Typography variant="body1" textAlign="center">
          Note: this action will only unignore{" "}
          {pluralizeCustom(
            findings.length,
            "this specific finding",
            "these specific findings"
          )}
          . It will not modify related ignore rules.
        </Typography>
      </SubContent>

      <Actions>
        <CancelButton disabled={loading} onClick={onDone} />
        <SubmitButton loading={loading} onClick={onUnignore}>
          Unignore
        </SubmitButton>
      </Actions>
    </FullHeightModal>
  );
});

// -----------------------------------------------------------------------------

interface Props {
  findings: ServerFinding[];
  onClose: (success?: boolean) => void;
}
