import { type NavigateFunction } from "react-router-dom";

import { type OrgStoreState } from "stores/OrganizationStore";

// -----------------------------------------------------------------------------

export function redirectToSlackOauthUrl(
  _orgStore: OrgStoreState,
  navigate: NavigateFunction
): void {
  navigate("/environment/destinations/slack/connect");
}
