import { type ScatterPlotRawSerie } from "@nivo/scatterplot";
import { ReturnIf } from "babel-plugin-transform-functional-return";

import { FindingSeverityLabel, SeverityToColor } from "data/finding";

import { type StaleFinding } from "stores/DashboardStore";

import { dayjs } from "utility/dayjs";

// -----------------------------------------------------------------------------

export interface StaleFindingDatum {
  x: number; // age in days
  y: number; // severity
  count: number;
  color: string;
  severity: string;
}

export const getDaysFromCreation = (createTime: Date) => {
  return Math.floor(dayjs.duration(dayjs().diff(dayjs(createTime))).asDays());
};

// -----------------------------------------------------------------------------

// This builds a timeline chart for the severity types from the first finding
// to the (user's) current date

export function getScatterData(
  findings: StaleFinding[] = []
): Array<ScatterPlotRawSerie<StaleFindingDatum>> {
  ReturnIf(findings.length < 1, []);

  // Initial data structure
  const chartData: Array<ScatterPlotRawSerie<StaleFindingDatum>> =
    Object.values(FindingSeverityLabel).map((k: string) => ({
      id: k,
      data: [],
    }));

  const scatterData = findings.reduce((acc, curr) => {
    const sevIndex = curr.severity;

    // If this age already exists in the array for this severity, increment the count. Otherwise, add a new object to the array
    const ageExistsForSeverity = acc[sevIndex].data.findIndex(
      (point) => point.x === getDaysFromCreation(curr.create_time)
    );

    if (ageExistsForSeverity !== -1) {
      acc[sevIndex].data[ageExistsForSeverity].count++;
    } else {
      acc[sevIndex].data.push({
        x: getDaysFromCreation(curr.create_time),
        severity: FindingSeverityLabel[curr.severity],
        y: curr.severity,
        count: 1,
        color: SeverityToColor[curr.severity],
      });
    }

    //
    return acc;
  }, chartData);

  //
  return scatterData;
}
