import { memo } from "react";

import { DataGridPremium } from "@mui/x-data-grid-premium";

import Paper from "@mui/material/Paper";

import {
  BillingState,
  type Invoice,
  useBillingStore,
} from "stores/BillingStore";

import {
  DataGridStyle,
  Columns,
  LoadingColumns,
  LoadingRows,
  PaperStyle,
} from "./data";

// -----------------------------------------------------------------------------

const PageSize = 20;
const PageSizeOptions = [PageSize];
const SortByPaymentDateDesc: any[] = [{ field: "payment_date", sort: "desc" }];
const DataComponents: Record<string, () => JSX.Element> = {
  LoadingOverlay: NullComponent,
};

// -----------------------------------------------------------------------------

export const BillingPagePastPaymentsTable = memo(() => {
  const [billingStore] = useBillingStore();

  //
  const isLoading = billingStore?.state === BillingState.Loading;
  const invoices: Invoice[] = billingStore?.invoices ?? [];

  //
  return (
    <Paper elevation={1} sx={PaperStyle}>
      <DataGridPremium<Invoice>
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
        density="compact"
        getRowId={getRowId}
        rows={isLoading ? LoadingRows : invoices}
        rowCount={invoices.length}
        columns={isLoading ? LoadingColumns : Columns}
        components={DataComponents}
        pageSize={PageSize}
        rowsPerPageOptions={PageSizeOptions}
        checkboxSelection={false}
        sx={DataGridStyle}
        sortModel={SortByPaymentDateDesc}
        loading={isLoading}
      />
    </Paper>
  );
});

// -----------------------------------------------------------------------------

function getRowId(row: Invoice): string {
  return row.id;
}

function NullComponent() {
  return <></>;
}
