import { memo, useEffect, useState } from "react";

import { DataGridPremium } from "@mui/x-data-grid-premium";
import styled from "styled-components";

import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select, { type SelectChangeEvent } from "@mui/material/Select";
import Typography from "@mui/material/Typography";

import { type Finding } from "data/finding";

import { dayjs } from "utility/dayjs";

import {
  DataGridStyle,
  FindingsColumns,
  FindingsColumnsLoading,
  LoadingRows,
  PaperStyle,
} from "./data";

// -----------------------------------------------------------------------------

enum SORT_BY {
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
}

// -----------------------------------------------------------------------------

const PageSize = 20;
const PageSizeOptions = [PageSize];
const DataComponents: Record<string, () => JSX.Element> = {
  LoadingOverlay: NullComponent,
};

// -----------------------------------------------------------------------------

export const IAMFindingsDataTable = memo(({ data, isLoading }: Props) => {
  const [displayFindings, setDisplayFindings] = useState(data);
  const [sortBy, setSortBy] = useState<SORT_BY>(SORT_BY.ASCENDING);

  useEffect(() => {
    const sortedData = [...data].sort((a: Finding, b: Finding) => {
      return (
        (dayjs(b.event_time).valueOf() - dayjs(a.event_time).valueOf()) *
        (sortBy === SORT_BY.ASCENDING ? -1 : 1)
      );
    });
    setDisplayFindings(sortedData);
  }, [data, sortBy]);

  const handleChange = (event: SelectChangeEvent) => {
    setSortBy(event.target.value as SORT_BY);
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        marginBottom="16px"
      >
        <Grid item paddingTop={0}>
          <Select
            id="sort-by-select"
            value={sortBy}
            inputProps={{ "aria-label": "Sort by order" }}
            onChange={handleChange}
          >
            <MenuItem value={SORT_BY.ASCENDING}>Sort By: Ascending</MenuItem>
            <MenuItem value={SORT_BY.DESCENDING}>Sort By: Descending</MenuItem>
          </Select>
        </Grid>

        <Grid item>
          <Typography gutterBottom variant="caption" fontWeight="light">
            Showing data as of {dayjs().format("MM/DD/YYYY")}
          </Typography>
        </Grid>
      </Grid>
      <Paper elevation={1} sx={PaperStyle}>
        <CustomGrid
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          localeText={{
            noRowsLabel: "No IAM Findings Found",
          }}
          disableSelectionOnClick
          density="compact"
          getRowId={getRowId}
          rows={isLoading ? LoadingRows : displayFindings}
          rowCount={displayFindings.length}
          columns={isLoading ? FindingsColumnsLoading : FindingsColumns}
          components={DataComponents}
          pageSize={PageSize}
          rowsPerPageOptions={PageSizeOptions}
          checkboxSelection={false}
          sx={DataGridStyle}
          loading={isLoading}
        />
      </Paper>
    </>
  );
});

// -----------------------------------------------------------------------------

function getRowId(row: Finding): string {
  return row.uuid;
}

function NullComponent() {
  return <></>;
}

// -----------------------------------------------------------------------------

const CustomGrid = styled(DataGridPremium<Finding>)`
  .MuiDataGrid-overlay {
    font-size: 2rem;
  }
`;

// -----------------------------------------------------------------------------

interface Props {
  data: Finding[];
  isLoading: boolean;
}
