import { type PropsWithChildren, memo, useMemo, useState } from "react";
import { ToastContainer } from "react-toastify";

import { ReturnIf } from "babel-plugin-transform-functional-return";
import { ThemeProvider as StyledThemeProvider } from "styled-components";

import MuiCssBaseline from "@mui/material/CssBaseline";
import {
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
  type PaletteOptions,
} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { ThemeContext, ThemeMode } from "./context";
import darkJson from "./data/dark.json";
import lightJson from "./data/light.json";
import { getMuiTheme, getStyledTheme, getThemeModeName } from "./index";

// -----------------------------------------------------------------------------

const BrowserThemeMode = loadFromLocalStorage("themeMode");

// -----------------------------------------------------------------------------

export const ThemeProvider = memo(({ children }: PropsWithChildren) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [themeMode, setThemeMode] = useState(BrowserThemeMode);

  //
  function updateThemeMode(themeMode: ThemeMode) {
    localStorage?.setItem("themeMode", JSON.stringify(themeMode));
    setThemeMode(themeMode);
  }

  //
  const finalThemeMode = useMemo(() => {
    ReturnIf(themeMode !== ThemeMode.Automatic, themeMode);

    //
    return prefersDarkMode ? ThemeMode.Dark : ThemeMode.Light;
  }, [themeMode, prefersDarkMode]);
  const palette: PaletteOptions = useMemo(
    () => (finalThemeMode === ThemeMode.Light ? lightJson : darkJson),
    [finalThemeMode]
  );

  //
  return (
    <StyledEngineProvider injectFirst>
      <StyledThemeProvider theme={getStyledTheme(finalThemeMode)}>
        <MuiThemeProvider theme={getMuiTheme(finalThemeMode)}>
          <MuiCssBaseline />
          <ThemeContext.Provider
            value={{
              themeMode,
              finalThemeMode,
              setThemeMode: updateThemeMode,
              palette,
            }}
          >
            {children}
            <ToastContainer theme={getThemeModeName(finalThemeMode)} />
          </ThemeContext.Provider>
        </MuiThemeProvider>
      </StyledThemeProvider>
    </StyledEngineProvider>
  );
});

// -----------------------------------------------------------------------------

function loadFromLocalStorage(key: string) {
  try {
    return (
      JSON.parse(localStorage?.getItem(key) ?? "undefined") ??
      ThemeMode.Automatic
    );
  } catch (error) {
    return ThemeMode.Automatic;
  }
}
