export interface UserRecord {
  uuid: string;
  fullName: string;
  preferredName: string;
  email: string;
  features: number;
  gravatarEmail: string;
  organization: string;
  permissionLevel: UserPermissionLevel;
  isNew?: boolean;
  preferences: UserPreferences;
}

export interface UserPreferences {
  sourceConnected?: Date;
  sourceDisconnected?: Date;
  scanFailure?: Date;
  destinationConnected?: Date;
  destinationDisconnected?: Date;
  productAndFeatures?: Date;
  companyAnnouncements?: Date;
  educationAndTraining?: Date;
  marketingInformation?: Date;
  monthlyNewsletter?: Date;
}

export enum UserPermissionLevel {
  SuperAdmin = "SuperAdmin",
  Auditor = "Auditor",
  Admin = "Admin",
  UnprivilegedUser = "UnprivilegedUser",
}
