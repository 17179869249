import { type OrgStoreState } from "stores/OrganizationStore";

// -----------------------------------------------------------------------------

export function redirectToGKEKubernetesConnectPage(
  orgStore: OrgStoreState,
  navigate: Function
): void {
  navigate("/environment/sources/gke-kubernetes/connect");
}
