import { memo, type ReactFragment, useState } from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

// -----------------------------------------------------------------------------

export const SidebarCategory = memo(
  ({ label, Icon, iconOnly, children, to }: Props) => {
    const navigate = useNavigate();

    const [open, toggleOpen] = useToggleValue(false);

    //
    return (
      <>
        <Button onClick={iconOnly ? () => navigate(to) : toggleOpen}>
          <IconContainer>
            <Icon />
          </IconContainer>
          {!iconOnly && (
            <>
              <Label primary={label} />
              {open ? <ExpandLess /> : <ExpandMore />}
            </>
          )}
        </Button>
        {!iconOnly && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children}
            </List>
          </Collapse>
        )}
      </>
    );
  }
);

// -----------------------------------------------------------------------------

function useToggleValue(defaultValue: boolean): [boolean, () => void] {
  const [open, setOpen] = useState(defaultValue);

  //
  function toggleOpen() {
    setOpen(toOppositeValue);
  }

  //
  return [open, toggleOpen];
}

function toOppositeValue(value: boolean): boolean {
  return !value;
}

// -----------------------------------------------------------------------------

const Button = styled(ListItemButton)`
  display: grid;
  grid-template-columns: auto 1fr auto;

  .MuiListItemIcon-root {
    min-width: 32px;
    width: 32px;
  }
`;

const IconContainer = styled(ListItemIcon)`
  svg {
    fill: ${(props) => props.theme.appHeader.main};
  }
`;

const Label = styled(ListItemText)`
  display: flex;

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

// -----------------------------------------------------------------------------

interface Props {
  label: string;
  Icon: React.FunctionComponent;
  iconOnly: boolean;
  to: string;
  children: ReactFragment;
}
