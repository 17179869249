import { memo, useState } from "react";

import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";

import { usePageTitle } from "effect/use_page_title";

import { Page } from "Component/Page";
import { PageContent } from "Component/PageContent";
import { PageHeader } from "Component/PageHeader";
import { PageTitle } from "Component/PageTitle";

import { RuleTable } from "./Table";

// -----------------------------------------------------------------------------

export const FindingsRuleManagementPage = memo(() => {
  usePageTitle("Findings → Rule Management");

  //
  const [searchTerm, setSearchTerm] = useState("");

  //
  return (
    <Page>
      <PageHeader>
        <PageTitle>Findings Rule Management</PageTitle>

        <TextField
          variant="outlined"
          size="small"
          type="text"
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
          sx={{ mt: 2, alignSelf: "flex-start" }}
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: searchTerm ? (
              <InputAdornment position="end">
                <CancelIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setSearchTerm("")}
                />
              </InputAdornment>
            ) : null,
          }}
        />
      </PageHeader>

      <PageContent>
        <RuleTable searchTerm={searchTerm} />
      </PageContent>
    </Page>
  );
});
