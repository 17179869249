import { memo, useState } from "react";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";

import { type FOTS, type SUTS } from "data/typescript";

import { useOrganizationStore } from "stores/OrganizationStore";
import { useUserStore } from "stores/UserStore";

import { requestSalesforceQuoteSynchronous } from "model/salesforce";

import { notifyError, notifySuccess } from "utility/notify";

// -----------------------------------------------------------------------------

export const BillingModal = memo(({ showModal, setShowModal }: Props) => {
  const [userStore] = useUserStore();
  const [organizationStore] = useOrganizationStore();
  const [prefillForm, setPrefillForm] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [website, setWebsite] = useState("");
  function togglePrefillForm(newPrefillForm: boolean) {
    setPrefillForm(newPrefillForm);

    if (newPrefillForm) {
      setName(userStore.user?.fullName);
      setEmail(userStore.user?.email);
      setCompanyName(organizationStore.organization.name);

      if (organizationStore.organization.domain) {
        // pull domain from user's email address
        setWebsite(
          userStore.user?.email
            .substring(
              userStore.user?.email.indexOf("@") + 1,
              userStore.user?.email.length
            )
            .trim()
        );
      }
    } else {
      setName("");
      setEmail("");
      setCompanyName("");
      setWebsite("");
    }
  }

  function requestQuote() {
    setShowModal(false);
    requestSalesforceQuoteSynchronous(
      {
        request_full_name: name,
        request_email: email,
        request_company_name: companyName,
        request_domain: website,
      },
      () => notifySuccess("Your request has been submitted!"),
      () => notifyError("There was an error submitting your request")
    );
  }

  function closeModal() {
    setShowModal(false);
    setPrefillForm(false);
  }
  function isDisabled() {
    return name === "" || email === "" || companyName === "" || website === "";
  }
  return (
    <Dialog
      sx={{
        backdropFilter: "blur(4px)",
      }}
      open={showModal}
      onClose={closeModal}
    >
      <DialogTitle>Upgrade Your ThreatKey Plan</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To upgrade your ThreatKey plan, fill out the form below and one of our
          representatives will be in touch with you shortly. If you have any
          questions, please contact us via the chat icon in the bottom right
          corner.
        </DialogContentText>
        <FormGroup>
          <FormControlLabel
            label="Use my information to pre-fill the form"
            control={
              <Checkbox
                checked={prefillForm}
                onChange={(event: FOTS) =>
                  togglePrefillForm(event?.target?.checked)
                }
              />
            }
          />
        </FormGroup>
        <TextField
          autoFocus
          margin="dense"
          id="email"
          label="Contact Email Address (required)"
          type="email"
          fullWidth
          variant="standard"
          value={email}
          onChange={
            ((e: SUTS<{ target: { value: string } }>) =>
              setEmail(e?.target?.value)) as FOTS
          }
        />
        <TextField
          margin="dense"
          id="name"
          label="Contact Name (required)"
          type="text"
          fullWidth
          variant="standard"
          value={name}
          onChange={
            ((e: SUTS<{ target: { value: string } }>) =>
              setName(e?.target?.value)) as FOTS
          }
        />
        <TextField
          margin="dense"
          id="company_name"
          label="Company Name (required)"
          type="text"
          fullWidth
          variant="standard"
          value={companyName}
          onChange={
            ((e: SUTS<{ target: { value: string } }>) =>
              setCompanyName(e?.target?.value)) as FOTS
          }
        />
        <TextField
          margin="dense"
          id="company_website"
          label="Company Website (required)"
          type="text"
          fullWidth
          variant="standard"
          value={website}
          onChange={
            ((e: SUTS<{ target: { value: string } }>) =>
              setWebsite(e?.target?.value)) as FOTS
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="error" variant="contained">
          Cancel
        </Button>
        <Button
          disabled={isDisabled()}
          onClick={requestQuote}
          variant="contained"
        >
          Request Quote
        </Button>
      </DialogActions>
    </Dialog>
  );
});

// -----------------------------------------------------------------------------
interface Props {
  showModal: boolean;
  setShowModal: Function;
}
