import { memo, useState } from "react";

import useSWR from "swr";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid2 from "@mui/material/Unstable_Grid2";

import { type FOTS, type SUTS } from "data/typescript";

import { type Connection, useConnectionStore } from "stores/ConnectionStore";
import {
  JiraCreateIssueMetaContainer,
  useJiraCIM,
} from "stores/JiraCreateIssueMetaStore";
import { SubmitState, swrFetcher } from "stores/lib";
import { useNotificationStore } from "stores/NotificationStore";

import { notifySuccess } from "utility/notify";

import { JiraIssueCreateMeta } from "./IssueCreateMeta";

// -----------------------------------------------------------------------------

export const JiraFindingMappings = memo((props: Props) => {
  return (
    <JiraCreateIssueMetaContainer
      scope={props.connection.uuid}
      initialData={props.connection.config?.issueCreateFields}
    >
      <JiraFindingMappingsInner {...props} />
    </JiraCreateIssueMetaContainer>
  );
});

export const JiraFindingMappingsInner = memo(({ connection }: Props) => {
  const apiURL = `api/organization/connections/${connection.uuid}/jira/projects`;

  // state
  const initialProjectID: string = connection?.config.project?.id;
  const initialIssueTypeID: string = connection?.config.issueType?.id;
  const [projectOptions, setProjectOptions] = useState<MappingProjectOption[]>(
    []
  );
  const [selectedProject, setSelectedProject] = useState(
    initialProjectID || ""
  );
  const [selectedIssueType, setSelectedIssueType] = useState(
    initialIssueTypeID || ""
  );
  // sweet-state
  const [, connectionActions] = useConnectionStore();
  const [cimStore, cimActions] = useJiraCIM();
  const [notificationStore, notificationActions] = useNotificationStore();
  let selectedProjectData: MappingProjectOption | undefined;
  if (!!selectedProject && projectOptions.length > 0) {
    selectedProjectData = projectOptions.find(
      (e: SUTS<{ id: string }>) => e.id === selectedProject
    );
  }
  if (
    notificationStore.fetchingState === undefined ||
    notificationStore.fetchingState < SubmitState.SUCCESS
  ) {
    notificationActions.fetchConfig();
  }
  useSWR<MappingsConfigResponse>(apiURL, swrFetcher, {
    onSuccess(data) {
      setProjectOptions(data.projects);
    },
  });
  const onSelectProject = (e: SUTS<{ target: { value: string } }>) =>
    setSelectedProject(e?.target?.value);
  const onSelectIssue = (e: SUTS<{ target: { value: string } }>) => {
    setSelectedIssueType(() => {
      cimActions.reset();

      //
      return e?.target?.value;
    });
  };
  const onSave = () => {
    // save Jira config and notification config in a chain
    const project: { [x: string]: any } = { ...selectedProjectData };
    delete project.issue_types;
    connectionActions.editConnection({
      uuid: connection.uuid,
      data: {
        config: {
          ...connection.config,
          project,
          issueType: selectedProjectData?.issue_types.find(
            (it) => it.id === selectedIssueType
          ),
          issueCreateFields: cimStore.data,
        },
      },
      onSuccess: () => {
        cimActions.onSave();
        notificationActions.updateConfig(
          {
            finding_notification: {
              outputs: {
                jira_sync: {
                  enabled: true,
                  destinations: [
                    {
                      // 1. There are no finding filters scoped for the Jira
                      //    launch
                      // 2. We don't use this part of the data structure for
                      //    any Jira integration and we have it filled in to
                      //    play nice with the rest of the codebase. This
                      //    object just needs to not be false-y.
                      filter: null,
                      connection: connection.uuid,
                      specific_location: null,
                    },
                  ],
                },
              },
            },
          },
          connection.uuid,
          () => notifySuccess("Issue mappings and field settings saved."),
          () => {}
        );
      },
    });
  };
  const onReset = () => {
    setSelectedProject(initialProjectID);
    setSelectedIssueType(initialIssueTypeID);
    cimActions.reset();
  };
  const resetDisabled =
    (!selectedProject || selectedProject === initialProjectID) &&
    selectedIssueType === initialIssueTypeID &&
    !cimStore.changed;
  const saveDisabled =
    !(selectedProject && selectedIssueType) ||
    (selectedProject === initialProjectID &&
      selectedIssueType === initialIssueTypeID &&
      !cimStore.changed);
  const mustSelectProject = !selectedProject;
  return (
    <Paper elevation={1} sx={{ p: 1.5 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          flexWrap: "wrap",
          mb: 2,
        }}
      >
        <Typography variant="h6">Finding mappings</Typography>
      </Box>
      <Stack spacing={2}>
        <Typography variant="body1">
          These settings specify mappings between ThreatKey findings and Jira
          issues.
        </Typography>
        <Grid2 container spacing={2}>
          <Grid2 xs={6}>
            <FormControl size="small" fullWidth required>
              <InputLabel id="jira-project-label">Project</InputLabel>
              <Select
                labelId="jira-project-label"
                value={selectedProject}
                onChange={onSelectProject as FOTS}
                label="Project"
              >
                {projectOptions.map((p) => (
                  <MenuItem key={p.id} value={p.id}>
                    <code>{p.key}</code>: {p.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid2>
          <Grid2 xs={6}>
            <FormControl
              size="small"
              error={mustSelectProject}
              fullWidth
              required
            >
              <InputLabel id="jira-issue-label">Issue type</InputLabel>
              <Select
                labelId="jira-issue-label"
                value={selectedIssueType}
                onChange={onSelectIssue as FOTS}
                disabled={mustSelectProject}
                label="Issue type"
              >
                {selectedProjectData == null
                  ? null
                  : selectedProjectData.issue_types.map((it) => (
                      <MenuItem key={it.id} value={it.id}>
                        {it.name}
                      </MenuItem>
                    ))}
              </Select>
              {mustSelectProject ? (
                <FormHelperText>
                  Select a project to show eligible issue types.
                </FormHelperText>
              ) : null}
            </FormControl>
          </Grid2>
          {selectedProjectData?.projectTypeKey && (
            <JiraIssueCreateMeta
              connection={connection}
              projectID={selectedProject}
              issueTypeID={selectedIssueType}
              projectTypeKey={selectedProjectData?.projectTypeKey}
            />
          )}
        </Grid2>
        <Stack spacing={1} direction="row" justifyContent="flex-end">
          <Button variant="outlined" onClick={onReset} disabled={resetDisabled}>
            Reset
          </Button>
          <Button variant="contained" onClick={onSave} disabled={saveDisabled}>
            Save
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
});

// -----------------------------------------------------------------------------

interface Props {
  connection: Connection;
}

interface MappingsConfigResponse {
  projects: MappingProjectOption[];
}

interface MappingProjectOption {
  id: string;
  key: string;
  name: string;
  projectTypeKey: "software" | "service_desk" | "business";
  issue_types: MappingIssueType[];
}

interface MappingIssueType {
  id: string;
  name: string;
}
