import { memo } from "react";

import { GhostEnvironmentConnectedDestination } from "./ConnectedDestination";

// -----------------------------------------------------------------------------

export const GhostEnvironmentConnectedSources = memo(() => {
  return (
    <>
      <GhostEnvironmentConnectedDestination />
      <GhostEnvironmentConnectedDestination />
      <GhostEnvironmentConnectedDestination />
      <GhostEnvironmentConnectedDestination />
    </>
  );
});
