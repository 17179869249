import { type MouseEventHandler, memo } from "react";

import styled from "styled-components";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

// -----------------------------------------------------------------------------

export const FindingsPageListPaneNoFindings = memo(
  ({ resetSearch, retrySearch }: Props) => {
    return (
      <Container>
        <Content>
          <Header>There are no findings</Header>
          <Buttons>
            {resetSearch ? (
              <Button
                onClick={resetSearch as MouseEventHandler<HTMLButtonElement>}
              >
                Reset search
              </Button>
            ) : null}
            {retrySearch ? (
              <Button onClick={() => retrySearch(true)} color="success">
                Retry search
              </Button>
            ) : null}
          </Buttons>
        </Content>
      </Container>
    );
  }
);

// -----------------------------------------------------------------------------

const Container = styled(Box)`
  height: 100%;
  width: 100%;
`;

const Content = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  max-width: 1142px;
  min-height: 0vw;
  padding: 10vh clamp(8px, calc(0.5rem + ((1vw - 3.75px) * 1.9394)), 24px);
`;

const Header = styled(Box)`
  font-size: 1.5rem;
  font-weight: bold;
`;

const Buttons = styled(Box)`
  display: flex;
  gap: 8px;
`;

// -----------------------------------------------------------------------------

interface Props {
  resetSearch: Function | undefined;
  retrySearch: Function | undefined;
}
