import React, { type MouseEventHandler, memo, useState } from "react";

import styled from "styled-components";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

import { classIf } from "utility/style";

import { SidebarContent } from "./Content";

// -----------------------------------------------------------------------------

export const Sidebar = memo(({ mode = "full", onClose }: Props) => {
  const [collapsed, setCollapsed] = useState(false);

  const Icon = collapsed
    ? KeyboardDoubleArrowRightIcon
    : KeyboardDoubleArrowLeftIcon;

  //
  function toggleCollapsed() {
    setCollapsed(toggleBoolean);
  }

  function closeMobileMenu(e: React.MouseEvent) {
    if ((e.target as HTMLDivElement).closest("a") !== null) {
      onClose?.();
    } else {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  //
  return mode === "full" ? (
    <ScrollContainer className={classIf(collapsed, "iconOnly")}>
      <Container>
        <SidebarContentContainer>
          <SidebarContent iconOnly={collapsed} isMobile={false} />
        </SidebarContentContainer>
      </Container>
      <CollapseButton onClick={toggleCollapsed}>
        <Icon />
        {collapsed ? null : <Box component="span">Collapse</Box>}
      </CollapseButton>
    </ScrollContainer>
  ) : (
    <MobileContainer onClick={onClose as MouseEventHandler<HTMLDivElement>}>
      <MobileCloseButton onClick={onClose as MouseEventHandler<HTMLDivElement>}>
        Close
      </MobileCloseButton>
      <MobileContent
        onClick={closeMobileMenu as React.MouseEventHandler<HTMLDivElement>}
      >
        <SidebarContent iconOnly={false} isMobile={true} />
      </MobileContent>
    </MobileContainer>
  );
});

// -----------------------------------------------------------------------------

function toggleBoolean(value: boolean): boolean {
  return !value;
}

// -----------------------------------------------------------------------------

const CollapseButton = styled(Button)`
  background: ${(props) => props.theme.primary.background};
  border-radius: 0;
  bottom: 0;
  color: ${(props) => props.theme.primary.text};
  height: 48px;
  left: 0;
  min-width: auto;
  padding: 0;
  width: 100%;
  z-index: 1000;

  span {
    font-size: 16px;
    margin-left: 8px;
    text-transform: capitalize;
  }

  svg {
    color: ${(props) => props.theme.icons.main};
  }

  &:hover {
    background-color: ${(props) => props.theme.primary.border};
  }
`;

const ScrollContainer = styled.div`
  border-right: 1px ${(props) => props.theme.primary.border} solid;
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: 0;
  min-width: 192px;
  position: relative;
  width: clamp(192px, calc(12rem + ((1vw - 8px) * 27)), 300px);

  &.iconOnly {
    min-width: 58px;
    width: 58px;
  }

  @media (max-width: 800px) {
    display: none;

    ${CollapseButton} {
      display: none;
    }
  }
`;

const Container = styled.div`
  background: ${(props) => {
    return props.theme.primary.background;
  }};
  position: relative;
`;

const SidebarContentContainer = styled.div`
  inset: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
`;

const MobileCloseButton = styled.div`
  background: ${(props) => props.theme.mobileNavigationClose.main};
  color: ${(props) => props.theme.primary.contrastText};
  left: 0;
  padding: 8px;
  position: sticky;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 1000;
`;

const MobileContainer = styled.div`
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2147483646;
`;

const MobileContent = styled.div`
  background: ${(props) => {
    return props.theme.appBackground.main;
  }};
  flex-grow: 1;
  width: 100%;
`;

// -----------------------------------------------------------------------------

interface Props {
  mode?: "full" | "mobile";
  onClose?: Function;
  collapsed?: boolean;
}
