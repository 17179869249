import { ReturnIf } from "babel-plugin-transform-functional-return";

import { type FindingType } from "data/finding";

import { type DRFPageResponse } from "stores/lib";

import { authenticatedGetFetch } from "utility/fetch/authenticated";
import { hasNo } from "utility/has";
import { trying } from "utility/trying";

// -----------------------------------------------------------------------------

export async function loadFindingTypes(
  types: string[]
): Promise<FindingType[]> {
  ReturnIf(hasNo(types), []);

  //
  const [pages] = types.reduce(
    (state: [URLSearchParams[], number], type) => {
      if (state[1] >= 25) {
        state[0].push(new URLSearchParams());
        state[1] = 0;
      }

      //
      state[0][state[0].length - 1].append("name", type);
      state[1] += 1;

      //
      return state;
    },
    [[new URLSearchParams()], 0]
  );

  //
  return await trying<FindingType[]>(async () => {
    const results: FindingType[] = [];
    for (const page of pages) {
      const responsePayload = await authenticatedGetFetch<
        DRFPageResponse<FindingType>
      >(`api/findings/types?${page.toString()}`);
      results.push(...responsePayload.results);
    }

    //
    return results;
  }, "Failed to load finding types");
}

export async function loadFindingTypesAsObject(
  types: string[]
): Promise<Record<string, FindingType>> {
  return (await loadFindingTypes(types)).reduce(
    (obj: Record<string, FindingType>, type) => {
      obj[type.name] = type;

      return obj;
    },
    {}
  );
}
