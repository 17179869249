import { type Action, createHook, createStore } from "react-sweet-state";

import { ReturnIf } from "babel-plugin-transform-functional-return";

import { getServerPublicKey } from "model/encryption";

import { generateBrowserPublicKey } from "utility/encryption";

// -----------------------------------------------------------------------------

const initialState: EncryptionStoreState = {
  publicKey: undefined,
  prefix: undefined,
};

// -----------------------------------------------------------------------------

const store = createStore({
  initialState,
  actions: {
    getPublicKey,
  },
});

export const useEncryptionStore = createHook(store);

// -----------------------------------------------------------------------------

function getPublicKey(
  force?: boolean
): Action<
  EncryptionStoreState,
  void,
  Promise<{ publicKey: CryptoKey; prefix: string }>
> {
  return async ({ setState, getState }) => {
    const { publicKey, prefix } = getState();
    ReturnIf(publicKey && !force, { publicKey, prefix });

    //
    const responsePayload = await getServerPublicKey();
    const browserPublicKey = await generateBrowserPublicKey(responsePayload);

    //
    setState({
      publicKey: browserPublicKey,
      prefix: responsePayload.prefix,
    });

    //
    return {
      publicKey: browserPublicKey,
      prefix: responsePayload.prefix,
    };
  };
}

// -----------------------------------------------------------------------------

interface EncryptionStoreState {
  publicKey?: CryptoKey;
  prefix?: string;
}
