import { memo, isValidElement } from "react";

import {
  type GridRenderCellParams,
  useGridSelector,
  useGridApiContext,
  gridDetailPanelExpandedRowsContentCacheSelector,
} from "@mui/x-data-grid-premium";

import IconButton from "@mui/material/IconButton";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// -----------------------------------------------------------------------------

export const RuleTableIgnoreReason = memo(
  (props: Pick<GridRenderCellParams, "id" | "value">) => {
    const { id, value: isExpanded } = props;
    const apiRef = useGridApiContext();

    // To avoid calling ´getDetailPanelContent` all the time, the following
    // selector gives an object with the detail panel content for each row id.
    const contentCache = useGridSelector(
      apiRef,
      gridDetailPanelExpandedRowsContentCacheSelector
    );

    // If the value is not a valid React element, it means that the row has no
    // detail panel.

    return isValidElement(contentCache[id]) ? (
      <IconButton
        size="small"
        tabIndex={-1}
        aria-label={isExpanded ? "Close" : "Open"}
      >
        <ExpandMoreIcon
          sx={{
            transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
            transition: (theme) =>
              theme.transitions.create("transform", {
                duration: theme.transitions.duration.shortest,
              }),
          }}
          fontSize="inherit"
        />
      </IconButton>
    ) : null;
  }
);
