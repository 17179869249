import { memo } from "react";

import { ResponsiveBar } from "@nivo/bar";
import { useTheme } from "styled-components";

import { type FOTS } from "data/typescript";

// -----------------------------------------------------------------------------

export const ResponseBar = memo(
  ({
    data,
    isInteractive,
    keys,
    indexBy,
    colors,
    margin,
    padding,
    valueScale,
    indexScale,
    borderColor,
    enableLabel,
    axisLeft,
    axisBottom,
    legends,
    role,
    ariaLabel,
    barAriaLabel,
    enableGridY,
  }: Props) => {
    const theme = useTheme();
    const myThemes = {
      axis: {
        ticks: {
          text: {
            fill: theme.primary.text,
          },
        },
      },
      legends: {
        text: {
          fill: theme.primary.text,
        },
      },
      grid: {
        line: {
          stroke: "#AAAAAA",
          strokeWidth: 0.5,
        },
      },
    };
    return (
      <ResponsiveBar
        theme={myThemes}
        data={data}
        isInteractive={isInteractive}
        keys={keys}
        indexBy={indexBy}
        colors={colors}
        margin={margin}
        padding={padding}
        valueScale={valueScale}
        indexScale={indexScale}
        borderColor={borderColor}
        enableLabel={enableLabel}
        axisLeft={axisLeft}
        axisBottom={axisBottom}
        legends={legends}
        role={role}
        ariaLabel={ariaLabel}
        barAriaLabel={barAriaLabel}
        enableGridY={enableGridY}
      />
    );
  }
);

// -----------------------------------------------------------------------------

interface Props {
  theme?: FOTS;
  data?: FOTS;
  isInteractive?: FOTS;
  keys?: FOTS;
  indexBy?: FOTS;
  colors?: FOTS;
  margin?: FOTS;
  innerRadius?: FOTS;
  padding?: FOTS;
  valueScale?: FOTS;
  indexScale?: FOTS;
  borderColor?: FOTS;
  enableLabel?: FOTS;
  axisLeft?: FOTS;
  axisBottom?: FOTS;
  layers?: FOTS;
  legends?: FOTS;
  role?: FOTS;
  ariaLabel?: string;
  barAriaLabel?: FOTS;
  enableGridY?: boolean;
}
