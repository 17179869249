import { memo, type RefObject, useEffect, useRef, useState } from "react";
import Confetti from "react-confetti";

import Box from "@mui/material/Box";

import { position } from "utility/style";

// -----------------------------------------------------------------------------

export const Celebration = memo(() => {
  const containerRef: RefObject<HTMLDivElement> = useRef(null);
  const [{ width, height }, setSize] = useState({ width: 0, height: 0 });

  //
  useEffect(() => {
    function onResize() {
      const bounds = containerRef.current?.getBoundingClientRect();
      setSize(
        bounds != null
          ? { width: bounds.width, height: bounds.height }
          : { width: 0, height: 0 }
      );
    }

    //
    window.addEventListener("resize", onResize);
    onResize();

    //
    return () => window.removeEventListener("resize", onResize);
  }, []);

  //
  return (
    <Box ref={containerRef} sx={position("absolute", 0, 0, 0, 0)}>
      <Confetti
        width={width}
        height={height}
        confettiSource={{ x: 0, y: -256, w: width, h: 256 }}
      />
    </Box>
  );
});
