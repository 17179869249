import { ReturnIf, ThrowIf } from "babel-plugin-transform-functional-return";

// this should always be an envar
export const API_URL =
  process.env.NODE_ENV === "production"
    ? "https://api.threatkey.com"
    : process.env.REACT_APP_API_SITE_URL ?? "http://localhost:8000";

// -----------------------------------------------------------------------------

export const DefaultAllowedStatuses = [200, 201, 204];

const EmptyObject = {};
const Empty = "";
const EmptyJson = "{}";
const ContentType = "Content-Type";
const ApplicationJson = "application/json";
const Get = "GET";

// -----------------------------------------------------------------------------

export function hasAllowedStatus(
  allowedStatuses: number[],
  status: number
): boolean {
  return allowedStatuses.includes(status);
}

export async function getResponseJson<T = any>(
  response: Response,
  swallowError: boolean = false
): Promise<T> {
  try {
    ReturnIf(responseShouldHaveJson(response), await response.json());
  } catch (error) {
    ThrowIf(!swallowError, error as Error);
  }

  //
  return undefined as any;
}

export function getRequestBodyString(
  method: string,
  payload: any
): { body: string } | {} {
  return method === Get
    ? EmptyObject
    : {
        body:
          payload !== undefined && payload !== null
            ? JSON.stringify(payload)
            : EmptyJson,
      };
}

// -----------------------------------------------------------------------------

function responseShouldHaveJson(response: Response): boolean {
  return (
    response.status !== 204 &&
    (response.headers.get(ContentType) ?? Empty).includes(ApplicationJson)
  );
}

// -----------------------------------------------------------------------------

export type Method = "DELETE" | "GET" | "PATCH" | "POST" | "PUT";
