import { memo } from "react";
import { useParams } from "react-router-dom";

import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

import { useConnectionByID } from "stores/ConnectionStore";

import { usePageTitle } from "effect/use_page_title";

import { Page } from "Component/Page";
import { PageContent } from "Component/PageContent";
import { PageHeader } from "Component/PageHeader";
import { PageHeaderBackButton } from "Component/PageHeaderBackButton";
import { PageSubTitle } from "Component/PageSubTitle";
import { PageTitle } from "Component/PageTitle";

import { JiraConfigInfo } from "./ConfigInfo";
import { JiraFindingMappings } from "./FindingMappings";

// -----------------------------------------------------------------------------

export const JiraCloudConfigurePage = memo(() => {
  usePageTitle("Environment → Destinations → Jira Cloud → Configure");

  //
  const { uuid } = useParams();
  const connection = useConnectionByID(uuid ?? "");

  //
  return (
    <Page>
      <PageHeader>
        <PageHeaderBackButton to="/environment/destinations">
          Back to Destinations
        </PageHeaderBackButton>

        <PageTitle>Jira Cloud Issue Sync</PageTitle>
        <PageSubTitle>
          Jira Cloud Issue Sync can be configured to create and maintain
          associations with Jira issues in a particular project.
        </PageSubTitle>
      </PageHeader>

      <PageContent>
        {connection === undefined ? (
          <Skeleton height={400} />
        ) : (
          <Stack spacing={2}>
            <JiraConfigInfo connection={connection} />
            <JiraFindingMappings connection={connection} />
          </Stack>
        )}
      </PageContent>
    </Page>
  );
});
