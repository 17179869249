import { memo } from "react";

import { usePageTitle } from "effect/use_page_title";

import { ErrorPage } from "./Error";

// -----------------------------------------------------------------------------

export const ComingSoonPage = memo(() => {
  usePageTitle("Coming Soon");

  //
  return <ErrorPage override="coming-soon" />;
});
