import styled, { keyframes } from "styled-components";

// -----------------------------------------------------------------------------

const Animation = keyframes`
from {
		left: -28px;
}

to {
		left: -5px;
}
`;

// -----------------------------------------------------------------------------

export const ProgressBar = styled.progress`
  --fg: rgb(21, 74, 131);

  appearance: none;
  background: #fff;
  border-radius: 99px;
  height: 50%;
  overflow: hidden;
  position: relative;
  width: 100%;

  &:before {
    animation: ${Animation} 0.5s infinite linear forwards;
    background: repeating-linear-gradient(
      135deg,
      #ccc 0,
      #ccc 8px,
      #fff 8px,
      #fff 16px
    );
    content: "";
    inset: 0 -32px;
    opacity: 0.5;
    position: absolute;
    z-index: 1;
  }

  &:after {
    background: linear-gradient(180deg, #00000000, #0000007f);
    border-radius: 99px;
    box-shadow: inset 0 2px 2px #0000007f;
    content: "";
    inset: 0;
    position: absolute;
    z-index: 2;
  }

  &::-webkit-progress-bar {
    background: transparent;
  }

  &::-webkit-progress-value {
    background: var(--fg);
    border-radius: 99px;
    box-shadow: inset 0 -2px 2px #0000007f;
    opacity: 0.75;
    position: relative;
    z-index: 3;
  }

  &::-moz-progress-bar {
    background: var(--fg);
    border-radius: 99px;
    box-shadow: inset 0 -2px 2px #0000007f;
    opacity: 0.75;
    position: relative;
    z-index: 3;
  }
`;
