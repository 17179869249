import { memo, useState } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { useConnectionStore } from "stores/ConnectionStore";
import { useEncryptionStore } from "stores/EncryptionStore";

import { ServiceConnectionPhase } from "Component/ServiceConnectionPhase";

// -----------------------------------------------------------------------------

const PROJECT_ID_REGEX: RegExp = /^[a-z][-a-z0-9]{4,28}[a-z0-9]{1}$/;

// -----------------------------------------------------------------------------

export const GoogleCloudPlatformConnectPageEnterDetailsPhase = memo(
  ({ disabled, onSuccess, onError }: Props) => {
    const [, connectionActions] = useConnectionStore();
    const [, encryptionActions] = useEncryptionStore();

    const [projectId, setProjectId] = useState("");
    const [confirmSa, setConfirmSa] = useState(false);
    const [loading, setLoading] = useState(false);

    const validProjectId = projectId ? PROJECT_ID_REGEX.test(projectId) : true;

    //
    const connect = () => {
      setLoading(true);

      (async () => {
        connectionActions.createGCPConnection({
          ...(await encryptionActions.getPublicKey()),
          data: { gcp_resource_id: projectId, is_org_level: false },
          onSuccess: (data: any) => {
            setLoading(false);
            onSuccess(data);
          },
          onError: (error: any) => {
            setLoading(false);
            onError(error);
          },
        });
      })();
    };

    const reset = () => {
      setProjectId("");
      setConfirmSa(false);
    };

    //
    return (
      <ServiceConnectionPhase disabled={loading || disabled}>
        <Stack spacing={1.5} direction="column">
          <Typography variant="body1">
            Please provide your <strong>Google Cloud Project ID</strong>.
          </Typography>
          <TextField
            fullWidth
            label="Google Cloud Project ID"
            placeholder="your-gcp-project-id"
            value={projectId}
            error={!validProjectId}
            helperText={!validProjectId ? "Project ID is invalid" : undefined}
            onChange={(event) => {
              setProjectId(event.target.value);
            }}
            data-private="lipsum"
          />

          <FormGroup>
            <Typography>
              Please ensure that you have added the{" "}
              <strong>ThreatKey Service Account</strong> to your project in
              Google Cloud. To connect to ThreatKey, please note that you must
              use a GCP project ID instead of a GCP project number. This ensures
              proper authentication and access to the necessary resources.
              Kindly double-check your project details before proceeding with
              the connection.
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={confirmSa}
                  onChange={() => setConfirmSa(!confirmSa)}
                />
              }
              label={
                <Typography>
                  I have added the <strong>ThreatKey Service Account</strong> to
                  my project in Google Cloud Platform.
                </Typography>
              }
            />
          </FormGroup>
          <Stack sx={{ mt: 2 }} spacing={2} direction="row">
            <Tooltip
              arrow
              title={!projectId || !confirmSa ? "Please fill out the form" : ""}
            >
              <span>
                <LoadingButton
                  variant="contained"
                  onClick={connect}
                  loading={loading}
                  disabled={!projectId || !confirmSa || !validProjectId}
                >
                  Connect to Google Cloud Platform
                </LoadingButton>
              </span>
            </Tooltip>
            <Button variant="text" onClick={reset}>
              Clear
            </Button>
          </Stack>
        </Stack>
      </ServiceConnectionPhase>
    );
  }
);

// -----------------------------------------------------------------------------

interface Props {
  disabled: boolean;
  onSuccess: Function;
  onError: Function;
}
