import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material";
import Fuse from "fuse.js";
import styled from "styled-components";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AppsIcon from "@mui/icons-material/Apps";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import AvTimer from "@mui/icons-material/AvTimer";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import Dashboard from "@mui/icons-material/Dashboard";
import FeedbackOutlined from "@mui/icons-material/FeedbackOutlined";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import HomeIcon from "@mui/icons-material/Home";
import HubIcon from "@mui/icons-material/Hub";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import MemoryIcon from "@mui/icons-material/Memory";
import OutputIcon from "@mui/icons-material/Output";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PublicIcon from "@mui/icons-material/Public";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import ShowChart from "@mui/icons-material/ShowChart";
import TableChartIcon from "@mui/icons-material/TableChart";

import { type FOTS } from "data/typescript";

import { openOrtto } from "utility/ortto";

// -----------------------------------------------------------------------------

export const useActions = () => {
  const navigate = useNavigate();

  //
  return [
    {
      doesPerform: false,
      id: "Reporting",
      name: "Reporting",
      icon: <HomeIcon />,
      keywords: "Reporting",
    },
    {
      doesPerform: true,
      parent: "Reporting",
      id: "Mission Control",
      name: "Mission Control",
      icon: <Dashboard />,
      keywords: [
        "Mission Control",
        "New Critical & High Findings",
        "New Critical and High Findings",
        "Findings Breakdown",
        "FINDINGS (ALL TIME)",
        "FINDINGS RESOLVED (ALL TIME)",
        "FINDINGS CREATED (TRAILING 30 DAYS)",
      ],
      perform: () => navigate("/analytics"),
    },
    {
      doesPerform: true,
      parent: "Reporting",
      id: "Security Health Score",
      name: "Security Health Score",
      icon: <ShowChart />,
      keywords: [
        "Security Health Score",
        "Risk per source",
        "time to respond",
        "FINDINGS OVER TIME",
        "RESOLVED FINDINGS OVER TIME",
        "TIME TO COMPLETE",
        "rate of fidnings",
        "MED. TIME TO COMPLETE (DAYS)",
        "MED. TIME TO RESPOND (DAYS)",
      ],
      perform: () => navigate("/analytics/risk"),
    },
    {
      doesPerform: true,
      parent: "Reporting",
      id: "IAM",
      name: "Identity Access Management",
      icon: <AssignmentIndIcon />,
      keywords: ["IAM", "FINDINGS BY SOURCE", "IAM FINDINGS SCATTERPLOT"],
      perform: () => navigate("/analytics/iam"),
    },
    {
      doesPerform: true,
      parent: "Reporting",
      id: "Stale Findings",
      name: "Stale Findings",
      icon: <AvTimer />,
      keywords: ["Stale Findings", "STALE FINDINGS SCATTERPLOT"],
      perform: () => navigate("/analytics/stale"),
    },
    {
      doesPerform: true,
      parent: "Reporting",
      id: "Internet Exposed",
      name: "Internet Exposed",
      icon: <PublicIcon />,
      keywords: "Internet Exposed",
      perform: () => navigate("/analytics/internet_exposed"),
    },
    {
      doesPerform: true,
      parent: "Reporting",
      id: "Third Party Apps",
      name: "Third Party Apps",
      icon: <HubIcon />,
      keywords: "Third Party Beta Apps",
      perform: () => navigate("/analytics/third_party_apps"),
    },
    {
      doesPerform: false,
      id: "Findings",
      name: "Findings",
      icon: <AssignmentIcon />,
      keywords: "Findings",
    },
    {
      doesPerform: true,
      parent: "Findings",
      id: "Overview",
      name: "Overview",
      icon: <TableChartIcon />,
      keywords: ["Search", "Filters", "filters"],
      perform: () => navigate("/findings"),
    },
    {
      doesPerform: true,
      parent: "Findings",
      id: "Rule Management",
      name: "Rule Management",
      icon: <ManageSearchIcon />,
      keywords: "Rule Management",
      perform: () => navigate("/findings/rule-management"),
    },
    {
      doesPerform: false,
      id: "Environment",
      name: "Environment",
      icon: <MemoryIcon />,
      keywords: "Environment",
    },
    {
      doesPerform: true,
      id: "Sources",
      parent: "Environment",
      name: "Sources",
      icon: <AppsIcon />,
      keywords: "Sources Connected Sources Available",
      perform: () => navigate("/environment/sources"),
    },
    {
      doesPerform: true,
      id: "Destinations",
      parent: "Environment",
      icon: <OutputIcon />,
      name: "Destinations",
      keywords: "Destinations Connected Sources Destinations",
      perform: () => navigate("/environment/destinations"),
    },
    {
      doesPerform: false,
      id: "Organization",
      icon: <CorporateFareIcon />,
      name: "Organization",
      keywords: "Organization",
    },
    {
      doesPerform: true,
      id: "Settings",
      name: "Settings",
      parent: "Organization",
      icon: <SettingsIcon />,
      keywords:
        "Settings Preferences Details Early Access Program Domain Control Validation DCV ThreatKey Support Access Trust Compliance",
      perform: () => navigate("/settings"),
    },
    {
      doesPerform: true,
      id: "Members",
      parent: "Organization",
      icon: <PeopleAltIcon />,
      name: "Members",
      keywords: "Members Login method",
      perform: () => navigate("/members"),
    },
    {
      doesPerform: true,
      id: "Members",
      parent: "Organization",
      icon: <GroupAddIcon />,
      name: "Invite",
      keywords: "invite roles auditor admin Super Member",
      perform: () => navigate("/members/invite"),
    },
    {
      doesPerform: true,
      id: "Billing",
      parent: "Organization",
      icon: <ReceiptIcon />,
      name: "Billing",
      keywords: "Billing",
      perform: openOrtto,
    },
    {
      doesPerform: true,
      id: "Give Feedback",
      icon: <FeedbackOutlined />,
      name: "Give Feedback",
      keywords: "Help Support Feedback",
      perform: openOrtto,
    },
    {
      doesPerform: true,
      id: "Support Access",
      icon: <LocalHospitalIcon />,
      name: "Support Access",
      keywords: "Support Access debug",
      perform: () => navigate("organization/settings/support-access"),
    },
    {
      doesPerform: true,
      id: "Account Settings",
      icon: <AccountCircleIcon />,
      name: "Account Settings",
      keywords: "Account Settings Activity Notifications Email Subscriptions",
      perform: () => navigate("/account"),
    },
    {
      doesPerform: true,
      id: "Trust",
      icon: <AssuredWorkloadIcon />,
      name: "Trust and Compliance",
      keywords: "Trust and Compliance SOC2 SOC 2 ",
      perform: () => navigate("/settings/trust-center"),
    },
  ];
};

const options = {
  // isCaseSensitive: false,
  // includeScore: false,
  // shouldSort: true,
  // includeMatches: false,
  // findAllMatches: false,
  // minMatchCharLength: 1,
  // location: 0,
  threshold: 0.4,
  // distance: 100,
  // useExtendedSearch: false,
  // ignoreLocation: false,
  // ignoreFieldNorm: false,
  // fieldNormWeight: 1,
  keys: ["name", "keywords"],
};

export const PaletteSearch = (props: FOTS) => {
  const [results, setResults] = useState<Directory[]>([]);
  const isEmpty = results.length === 0;
  const [query, setCurrentQuery] = useState<string>("");

  const noSearch = query === "" && isEmpty;
  const arr = useActions();
  const theme = useTheme();
  const searchStyle = {
    padding: "12px 16px",
    fontSize: "16px",
    width: "100%",
    boxSizing: "border-box" as React.CSSProperties["boxSizing"],
    outline: "none",
    borderTopRightRadius: "20px",
    borderBottomRightRadius: noSearch ? "20px" : "0px",
    border: "none",
    color: theme.palette.primary.text,
    marginLeft: "-10px",
    backgroundColor: theme.palette.paletteSearch.background,
  };
  const handleInput = (value: string) => {
    const searcher = new Fuse(arr, options);
    const results = searcher.search(value).reduce((getResults, currItem) => {
      const item = getResults.concat(currItem.item);
      return item;
    }, []);
    setResults(results);
  };
  const handleParentInput = (value: string) => {
    const filteredOptions = arr.filter((item) => {
      return item.parent === value;
    });
    setCurrentQuery("");
    setResults(filteredOptions);
  };
  return (
    <>
      <Grid
        container
        sx={{
          position: "absolute",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            backgroundColor: theme.palette.paletteSearch.background,
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            borderBottomRightRadius: "20px",

            borderBottomLeftRadius: noSearch ? "20px" : "0px",
            alignItems: "center",
          }}
        >
          <SearchIcon
            sx={{
              marginLeft: "10px",
              zIndex: 10,
            }}
          />
          <input
            id="searchPalette"
            autoFocus
            style={searchStyle}
            value={query}
            placeholder="ThreatKey Search"
            onChange={(event) => {
              handleInput(event.target.value);
              setCurrentQuery(event.target.value);
            }}
          />
        </div>
        <ScrollContainer>
          <ScrollContent>
            {results.map((item, index) => (
              <ItemComponent
                onClose={props.onClose}
                key={index}
                theme={theme}
                item={item}
                parentSearch={handleParentInput}
              />
            ))}
            {isEmpty && query !== "" && (
              <Grid
                item
                sx={{
                  minHeight: "7vh",
                  height: "100%",
                  display: "flex",
                  background: theme.palette.paletteSearch.background,
                  alignItems: "center",
                }}
              >
                <Typography style={{ marginLeft: "1vw" }}>
                  No Results Found
                </Typography>
              </Grid>
            )}
          </ScrollContent>
          <ScrollbarContainer>
            <ScrollbarContent />
          </ScrollbarContainer>
        </ScrollContainer>
      </Grid>
    </>
  );
};
const ScrollContainer = styled.div`
  /* Container styles */
  background-color: ${(props) => props.theme.paletteSearch.background};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  max-height: 50vh;
  overflow: hidden;
  position: relative;
`;

const ScrollContent = styled.div`
  /* Customize the scrollbar */
  border-bottom-left-radius: 20px;
  max-height: 50vh;
  &::-webkit-scrollbar {
    background-color: ${(props) =>
      props.theme.paletteSearch.background}; /* width of the scrollbar */
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.paletteSearch.scrollbar};
    border-radius: 5px;
    z-index: 4000;
  }

  scrollbar-color: ${(props) => props.theme.paletteSearch.scrollbar}
    ${(props) => props.theme.paletteSearch.background}; //firefox supported property since webkit does not work on firefox
  scrollbar-width: auto; //firefox supported property since webkit does not work on firefox
  overflow: auto;
`;

const ScrollbarContainer = styled.div`
  /* Scrollbar container styles */
  bottom: 0;
  overflow: auto;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;

  width: 20px;
`;

const ScrollbarContent = styled.div`
  /* Scrollbar content styles */
  height: 100%;
`;
interface Directory {
  doesPerform: Boolean;
  name: string;
  icon: React.ReactNode;
  keywords: string;
  parent?: string;
  perform?: () => void;
}

const ItemComponent = ({
  onClose,
  theme,
  item,
  parentSearch,
}: {
  onClose: () => void;
  theme?: FOTS;
  item: Directory;
  parentSearch: (value: string) => void;
}) => {
  const { name, icon, perform, doesPerform } = item;
  const [active, setActive] = useState(false);

  const handleMouseEnter = () => {
    setActive(true);
  };

  const handleMouseLeave = () => {
    setActive(false);
  };

  const handleClick = () => {
    if (doesPerform) {
      onClose();
      if (perform) {
        perform();
      }
    } else {
      parentSearch(name);
    }
  };

  return (
    <Grid
      key={`${name}`}
      item
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      sx={{
        minHeight: "7vh",
        height: "100%",
        display: "flex",
        background: active
          ? theme.palette.paletteSearch.selected
          : theme.palette.paletteSearch.background,
        alignItems: "center",
      }}
    >
      <Typography style={{ marginLeft: "1vw" }}>{icon}</Typography>
      <span style={{ marginLeft: "1vh", marginBottom: "0.4vh" }}>{name}</span>
      {!doesPerform && <ArrowRightIcon />}
    </Grid>
  );
};
