import { memo } from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { useConnectionStore } from "stores/ConnectionStore";
import { ActionState } from "stores/lib";
import { useOrganizationStore } from "stores/OrganizationStore";
import { useUserStore } from "stores/UserStore";

import { ExternalSources, getAvailableSources } from "external/sources";

import { AvailableConnection } from "Component/AvailableConnection";

import { GhostEnvironmentPossibleSources as Loading } from "./Loading";

// -----------------------------------------------------------------------------

export const EnvironmentPossibleSources = memo(() => {
  const [store] = useConnectionStore();
  const [userStore] = useUserStore();
  const [orgStore] = useOrganizationStore();

  const possibleSources = getAvailableSources(
    ExternalSources.services,
    orgStore,
    userStore
  ).slice(0, 8);

  //
  switch (true) {
    case store.loadConnections.state === ActionState.IN_PROGRESS:
      return (
        <Grid container spacing={1}>
          <Loading />
        </Grid>
      );

    case possibleSources.length > 0:
      return (
        <Grid container spacing={1}>
          {possibleSources.map((source) => {
            return (
              <AvailableConnection
                connection={source as any}
                connectionType="source"
                key={source.name}
              />
            );
          })}
        </Grid>
      );

    default:
      return (
        <Typography variant="body1">
          You&apos;ve connected to all available sources.
        </Typography>
      );
  }
});
