import { memo, useEffect, useState } from "react";

import styled from "styled-components";

import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import Box from "@mui/system/Box";

import { SubmitState } from "stores/lib";
import { useOrganizationStore } from "stores/OrganizationStore";
import { useUserStore } from "stores/UserStore";

import { notifyError, notifySuccess } from "utility/notify";

import { usePageTitle } from "effect/use_page_title";

import { SettingsDomainControlValidation as DomainControlValidationSection } from "./DCV";
import { SettingsDetails as DetailsSection } from "./Details";
import { SettingsEarlyAccess as EarlyAccessSection } from "./EarlyAccess";
import { SettingsRequestDeletion as RequestDeletionSection } from "./RequestDeletion";
import { SettingsGeneralSSO as SSOSection } from "./SSO";

// -----------------------------------------------------------------------------

export const SettingsGeneral = memo(() => {
  usePageTitle("Settings → General");

  //
  const [userStore] = useUserStore();
  const [{ patchState, organization, flags }, organizationActions] =
    useOrganizationStore();

  //
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [earlyAccess, setEarlyAccess] = useState(false);
  const [requestedDeletion, setRequestedDeletion] = useState(false);

  //
  // patch errors no longer appears to propagate so I conformed with toasts per similar pages
  useEffect(() => {
    switch (patchState) {
      case SubmitState.SUCCESS:
        notifySuccess("Settings saved");
        break;

      case SubmitState.ERROR:
        notifyError(`Failed to save settings`);
        break;
    }

    organizationActions.clearPatchErrors();
  }, [patchState, organizationActions]);

  // seems to be the only way to populate the form on pageload
  useEffect(() => {
    setName(organization.name);
    setSlug(organization.slug);
    setEarlyAccess(!!flags.earlyAccess);
    setRequestedDeletion(!!flags.requestedDeletion);
  }, [organization, flags]);

  //
  function resetForm() {
    setName(organization.name);
    setSlug(organization.slug);
  }

  function submitForm() {
    organizationActions.patch({
      name,
      slug,
      early_access: earlyAccess ? new Date() : null,
    });
  }

  //
  const isNotSuperAdmin = userStore?.user?.permissionLevel !== "SuperAdmin";

  //
  return (
    <FormGroup>
      <DetailsSection
        name={name}
        setName={setName}
        slug={slug}
        setSlug={setSlug}
        disabled={isNotSuperAdmin}
      />

      <EarlyAccessSection
        earlyAccess={earlyAccess}
        setEarlyAccess={setEarlyAccess}
        disabled={isNotSuperAdmin}
      />

      <DomainControlValidationSection disabled={isNotSuperAdmin} />

      <SSOSection disabled={isNotSuperAdmin} />

      <RequestDeletionSection
        requestedDeletion={requestedDeletion}
        setRequestedDeletion={setRequestedDeletion}
        actions={organizationActions}
        disabled={isNotSuperAdmin}
      />

      <Box sx={{ mt: 3 }}>
        <ButtonStyled
          variant="contained"
          color="primary"
          onClick={submitForm}
          disabled={isNotSuperAdmin}
        >
          Save Changes
        </ButtonStyled>
        <ButtonStyled
          variant="outlined"
          onClick={resetForm}
          disabled={isNotSuperAdmin}
        >
          Reset
        </ButtonStyled>
      </Box>
    </FormGroup>
  );
});

// -----------------------------------------------------------------------------

const ButtonStyled = styled(Button)`
  & + & {
    margin-left: 16px;
  }
`;
