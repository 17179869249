import { JiraCloudConfigurePage } from "./Configure";
import { JiraCloudConnectPage } from "./Connect";
import { redirectToJiraCloudConnectPage } from "./connect_handler";
import { JiraCloudCoverImage } from "./CoverImage";

// -----------------------------------------------------------------------------

export { JiraCloudConfigurePage, JiraCloudConnectPage };

export const JiraCloudAppConfig = {
  name: "jira_sync",
  label: "Jira Cloud Issue Sync",
  coverImage: JiraCloudCoverImage,
  onConnect: redirectToJiraCloudConnectPage,
  searchTerms: ["atlassian", "jira", "issue"],
  url: "jira",
  notifications: true,
  internalOnly: true,
  featureFlag: 2, // Old Jira feature flag
};
