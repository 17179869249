import { PagerDutyConfigurePage } from "./Configure";
import { PagerDutyConnectPage } from "./Connect";
import { redirectToPagerDutyOauthUrl } from "./connect_handler";
import { PagerDutyConnectConfirmPage } from "./ConnectConfirm";
import { PagerDutyCoverImage } from "./CoverImage";

// -----------------------------------------------------------------------------

export {
  PagerDutyConfigurePage,
  PagerDutyConnectPage,
  PagerDutyConnectConfirmPage,
};

export const PagerDutyAppConfig = {
  name: "pagerduty",
  label: "PagerDuty",
  coverImage: PagerDutyCoverImage,
  onConnect: redirectToPagerDutyOauthUrl,
  searchTerms: [],
  url: "pagerduty",
  notifications: true,
};
