import React, { useEffect, useState } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { ReturnIf } from "babel-plugin-transform-functional-return";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import type { SlideDirection } from "@mui/x-date-pickers/CalendarPicker/PickersSlideTransition";
import { DayPicker } from "@mui/x-date-pickers/internals";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";

import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ChevronLeftRounded from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRounded from "@mui/icons-material/ChevronRightRounded";

import { type FOTS } from "data/typescript";

import { dayjs } from "utility/dayjs";

import { AdjustedText } from "Component/AdjustedText";

// -----------------------------------------------------------------------------

const CalendarHeaderStyle = { fontWeight: 500, fontSize: 14 };

const defaultIntervals = {
  today: {
    title: "Today",
    startDate: new Date(),
    endDate: new Date(),
  },
  yesterday: {
    title: "Yesterday",
    startDate: dayjs().subtract(1, "day").toDate(),
    endDate: dayjs().subtract(1, "day").toDate(),
  },
  last7: {
    title: "Last 7 days",
    startDate: dayjs().subtract(7, "days").toDate(),
    endDate: new Date(),
  },
  last30: {
    title: "Last 30 days",
    startDate: dayjs().subtract(30, "days").toDate(),
    endDate: new Date(),
  },
  thisMonth: {
    title: "This month",
    startDate: dayjs().startOf("month").toDate(),
    endDate: dayjs().endOf("month").toDate(),
  },
  prevMonth: {
    title: "Previous month",
    startDate: dayjs().subtract(1, "month").startOf("month").toDate(),
    endDate: dayjs().subtract(1, "month").endOf("month").toDate(),
  },
};

// -----------------------------------------------------------------------------

export const RangePicker = ({
  sDate = null,
  eDate = null,
  label = "",
  id = "range-picker",
  onChange,
  intervals = defaultIntervals,
}: Props) => {
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(
    sDate
  );
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(eDate);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectedDays, setSelectedDays] = useState<Date[]>([]);
  const [month, setMonth] = useState<Date>(new Date());
  const [direction, setDirection] = useState<SlideDirection>("left");
  const [anchorEl, setAnchorEl] = useState<
    HTMLButtonElement | HTMLInputElement | HTMLTextAreaElement | null
  >(null);
  const [open, setOpen] = useState(false);
  const [selectedIntervalKey, setSelectedIntervalKey] =
    useState<string>("last30");

  const minDate = dayjs("1 January 2020").toDate();
  const maxDate = dayjs("1 January 2050").toDate();

  useEffect(() => {
    selectInterval("last30");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectDate = (d: Date | null) => {
    ReturnIf(startDate == null && endDate == null, setStartDate(d));

    if (endDate == null) {
      if (dayjs(d).isBefore(startDate)) {
        setStartDate(d);
        setEndDate(startDate);
      } else {
        setEndDate(d);
      }
      return;
    }

    setStartDate(d);
    setEndDate(null);
  };

  useEffect(() => {
    if (startDate != null && endDate == null) {
      setSelectedDays([startDate]);
    }
    if (startDate != null && endDate != null) {
      const sDays: Date[] = [];
      const diff = dayjs(endDate).diff(startDate, "days");
      for (let i = 0; i <= diff; i++) {
        sDays.push(dayjs(startDate).add(i, "days").toDate());
      }

      setSelectedDays(sDays);
    }
  }, [startDate, endDate]);

  const selectInterval = (key: string) => {
    setStartDate(intervals[key].startDate);
    setEndDate(intervals[key].endDate);
    setSelectedIntervalKey(key);
  };

  const v =
    selectedStartDate != null && selectedEndDate != null
      ? `${dayjs(selectedStartDate).format("MM/DD/YYYY")} - ${dayjs(
          selectedEndDate
        ).format("MM/DD/YYYY")}`
      : "Select date";

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <AdjustedText
        label={label}
        id={id}
        onFocus={(event: FOTS) => {
          setOpen(true);
          setAnchorEl(event.currentTarget);
        }}
        value={v}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{ mr: 2 }}>
              <IconButton
                aria-label="open range picker"
                onClick={(event) => {
                  setOpen(true);
                  setAnchorEl(event.currentTarget);
                }}
                edge="end"
              >
                <ArrowDropDown />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Popper
        open={open}
        anchorEl={anchorEl}
        transition
        sx={{ width: "840px", zIndex: 9 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              elevation={12}
              sx={{
                padding: "20px",
                boxShadow: "0px 4px 10px primary.border",
              }}
            >
              <Box sx={{ display: "flex", width: "1000px" }}>
                <Box
                  sx={{
                    minWidth: "200px",
                    mr: 1,
                    pr: 1,
                    pt: 2,
                  }}
                >
                  <List sx={{ p: 0 }}>
                    {Object.keys(intervals).map((key: string) => {
                      const isItemSelected = key === selectedIntervalKey;
                      return (
                        <ListItem
                          key={key}
                          disablePadding
                          onClick={() => selectInterval(key)}
                          sx={{
                            backgroundColor: isItemSelected
                              ? "datePicker.background"
                              : "transparent",
                            color: isItemSelected
                              ? "primary.main"
                              : "info.main",
                            borderRight: isItemSelected
                              ? "3px solid primary.main"
                              : "none",
                          }}
                        >
                          <ListItemButton>
                            <ListItemText
                              primary={intervals[key].title}
                              sx={{
                                fontWeight: isItemSelected ? 500 : "inherit",
                              }}
                            />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                </Box>
                <Box sx={{ display: "flex", pt: 2 }}>
                  <Box sx={{ mr: 3 }}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item sx={{ width: "40px" }}>
                        <IconButton
                          onClick={() => {
                            setDirection("right");
                            setMonth(
                              dayjs(month).subtract(1, "month").toDate()
                            );
                          }}
                        >
                          <ChevronLeftRounded />
                        </IconButton>
                      </Grid>
                      <Grid item sx={{ textAlign: "center" }}>
                        <Typography sx={CalendarHeaderStyle}>
                          {dayjs(month).format("MMMM YYYY")}
                        </Typography>
                      </Grid>
                      <Grid item sx={{ width: "40px" }} />
                    </Grid>
                    <DayPicker
                      currentMonth={month}
                      selectedDays={selectedDays}
                      onSelectedDaysChange={selectDate}
                      focusedDay={new Date()}
                      isMonthSwitchingAnimating
                      onFocusedDayChange={() => null}
                      onMonthSwitchingAnimationEnd={() => null}
                      reduceAnimations={false}
                      slideDirection={direction}
                      loading={false}
                      minDate={minDate}
                      maxDate={maxDate}
                      disablePast={false}
                      disableFuture={false}
                    />
                  </Box>
                  <Box>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item sx={{ width: "40px" }} />
                      <Grid item sx={{ textAlign: "center" }}>
                        <Typography variant="body2" sx={CalendarHeaderStyle}>
                          {dayjs(month).add(1, "month").format("MMMM YYYY")}
                        </Typography>
                      </Grid>
                      <Grid item sx={{ width: "40px" }}>
                        <IconButton
                          onClick={() => {
                            setDirection("left");
                            setMonth(dayjs(month).add(1, "month").toDate());
                          }}
                        >
                          <ChevronRightRounded />
                        </IconButton>
                      </Grid>
                    </Grid>
                    <DayPicker
                      currentMonth={dayjs(month).add(1, "month").toDate()}
                      selectedDays={selectedDays}
                      onSelectedDaysChange={selectDate}
                      focusedDay={new Date()}
                      isMonthSwitchingAnimating
                      onFocusedDayChange={() => null}
                      onMonthSwitchingAnimationEnd={() => null}
                      reduceAnimations={false}
                      slideDirection={direction}
                      loading={false}
                      minDate={minDate}
                      maxDate={maxDate}
                      disablePast={false}
                      disableFuture={false}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ color: "info.main" }}>
                  {dayjs(selectedStartDate).format("MMMM DD, YYYY")} -{" "}
                  {dayjs(selectedEndDate).format("MMMM DD, YYYY")}
                </Typography>
                <Box
                  sx={{
                    textAlign: "right",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      mr: 1,
                    }}
                    color="error"
                    onClick={() => {
                      setOpen(false);
                      setStartDate(selectedStartDate);
                      setEndDate(selectedEndDate);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setOpen(false);
                      setSelectedStartDate(startDate);
                      setSelectedEndDate(endDate);
                      onChange(startDate, endDate);
                    }}
                  >
                    Apply
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Fade>
        )}
      </Popper>
    </LocalizationProvider>
  );
};

// -----------------------------------------------------------------------------

interface Props {
  sDate?: Date | null;
  eDate?: Date | null;
  label: string;
  id?: string;
  onChange: (sDate: Date | null, eDate: Date | null) => any;
  intervals?: any;
}
