import { memo } from "react";

import SvgIcon from "@mui/material/SvgIcon";

// -----------------------------------------------------------------------------

export const MiniOrangeSAMLSSOIcon = memo((props: object) => {
  return (
    <SvgIcon viewBox="0 0 926.92 165.87" {...props}>
      <path
        d="M396.12,301.35c1,2.54,2.79,5,2.87,7.62.43,12.92.27,12.92-12.46,12.67-10.09-.2-10.23-.2-10-10.28.08-3.35,1.05-6.67,1.62-10Z"
        fill="#626365"
        transform="translate(-220.31 -301.35)"
      />
      <path
        d="M526,301.35c3,2.82,4.52,17.93,1.67,18.44a59,59,0,0,1-20.41,0c-2.79-.49-1.16-15.74,1.76-18.44Z"
        fill="#626365"
        transform="translate(-220.31 -301.35)"
      />
      <path
        d="M220.31,373.92a1.22,1.22,0,0,1,.82-.07q.17,25.83.32,51.65l-1.14.11Z"
        fill="#cacbcb"
        transform="translate(-220.31 -301.35)"
      />
      <path
        d="M221.45,425.5q-.16-25.83-.32-51.65c.91-13.27,4.31-25.56,15-34.51,15.36-12.85,38.33-12.44,53.64.76,1.85,1.59,3,3.1,5.57.57,18.85-18.5,60.13-14.46,67.73,22.57A110.15,110.15,0,0,1,364.8,383c.25,12.91,0,25.83.13,38.74,0,3-.83,4.08-3.93,3.94-5-.24-10-.25-15,0-3,.15-4-.75-3.92-3.85.16-14.24.14-28.48,0-42.72a58.83,58.83,0,0,0-1-11.33c-1.69-8.19-7.87-12.59-17.18-12.7-10.24-.13-16.6,3.91-18.5,12.27a53.59,53.59,0,0,0-1.1,11.32c-.12,13.91-.28,27.82.08,41.72.12,4.63-1.39,5.63-5.6,5.3a100.76,100.76,0,0,0-13.46,0c-3.17.17-3.9-1-3.88-4,.11-14.57,0-29.14,0-43.71,0-2.15-.07-4.31-.25-6.45-.91-10.5-7.06-16.25-17.51-16.45-12-.23-18.86,4.74-19.4,15.78-.81,16.52-.75,33.09-.71,49.63,0,4.07-.9,5.52-5.16,5.21C232.78,425.27,227.1,425.53,221.45,425.5Z"
        fill="#616263"
        transform="translate(-220.31 -301.35)"
      />
      <path
        d="M1025,416c-20.43,21.62-55.11,10.1-62.12-17.21-3.49-13.59-3.77-27.19.58-40.62a40,40,0,0,1,35.87-27.86,71.48,71.48,0,0,1,15.88.61c16.46,2.62,27.15,12.53,29.86,28.55,4.34,25.56,3.7,51.44.52,77.12-1.66,13.34-10.35,22.12-22.79,26.49a80,80,0,0,1-20.4,3.85c-8.61.64-17.3.05-25.94.2-3,.06-4.18-.78-4-3.91a113.3,113.3,0,0,0,0-12.9c-.18-3.18,1.12-3.87,4.05-3.87,8.47,0,17,.27,25.41-.67C1021.22,443.61,1026.82,436.22,1025,416Zm.61-37.42c-.33-3.15-.56-6.28-1-9.39-1.54-11-7.29-16.59-17.72-17.36-11.64-.86-19.86,4-22,14.8a66.47,66.47,0,0,0-.65,22.11c1.71,12,9.7,18.12,20.88,17.7,10.61-.4,17.73-7.31,19.48-19C1025,384.52,1025.22,381.55,1025.56,378.57Z"
        fill="#616264"
        transform="translate(-220.31 -301.35)"
      />
      <path
        d="M544.66,377.37a73.16,73.16,0,0,1,72.69-72.68,83.47,83.47,0,0,1,14.83,1.6c5.3.93,8.32,5.33,7.41,10-1,5-5.25,7.86-10.94,6.84a55.59,55.59,0,0,0-27.12,1.37c-27,8.6-41.25,33.24-39.23,56.91,2.38,28,22.63,48.39,49.64,51.37a55.47,55.47,0,0,0,61.15-63.11c-.87-6.32,2-10.92,7.22-11.57,5.38-.67,9.37,2.87,10.26,9.09,5.63,39.28-22.38,76.64-60.76,82.47-39.49,6-78.56-20.24-84.53-62.36C544.82,384,544.85,380.68,544.66,377.37Z"
        fill="#fe8e3c"
        transform="translate(-220.31 -301.35)"
      />
      <path
        d="M1144.86,394.9c-4.42,21.47-24.54,35.18-46.53,32.34-22.54-2.9-35-14.79-38.39-37.17a68.26,68.26,0,0,1,1.68-30c5.77-19.46,22.42-30.87,43.52-29.94,20.11.88,36,14,40,33.33.91,4.36,1.19,8.85,1.9,13.26.67,4.16-.51,5.72-5.19,5.65-18.3-.3-36.62,0-54.92-.22-4.26-.05-5.41,1.22-4.14,5.1,1,3.13,1.51,6.51,3,9.41,6.87,13.46,24.86,14.57,33.09,1.93,2-3,4-3.81,7.22-3.71C1132.24,395,1138.39,394.9,1144.86,394.9Zm-23-28.16c-2.31-10.66-9.42-16.28-19.83-15.93-9.51.32-16.67,6.68-17.92,15.93Z"
        fill="#616264"
        transform="translate(-220.31 -301.35)"
      />
      <path
        d="M832.72,417.53c-6.13,3-11.94,7.19-18.41,8.8-16.77,4.18-34.64-5-41.41-20.88-7.18-16.86-7.42-34.19-.46-51.19,6.13-15,18.51-22.22,34.06-23.84a58.33,58.33,0,0,1,19.75,1.34c17,4.19,25.53,16.43,27.25,32.9,1.4,13.45,1,27.09,1.27,40.65.12,5.46-.13,10.93.08,16.38.11,2.95-.63,4.16-3.84,4a112.11,112.11,0,0,0-14,0c-3.56.23-4.84-1-4.36-4.43A21.86,21.86,0,0,0,832.72,417.53Zm.35-38.14c-.47-4.54-.62-7.86-1.19-11.1-1.94-10.88-7.4-15.82-18-16.5-11.17-.72-18.67,4-21.63,14.23a45.83,45.83,0,0,0,0,26c2.75,9.39,9.63,14.38,19.13,14.42s16.5-4.72,19.33-14.1C832.13,387.81,832.46,383,833.07,379.39Z"
        fill="#606163"
        transform="translate(-220.31 -301.35)"
      />
      <path
        d="M410.93,395.47c.68-10.56.4-21.28,2.24-31.63,5.18-29.07,35.57-42.76,61.33-28.07,12.12,6.91,18.27,18.28,19.23,31.4,1.36,18.46.9,37.05,1,55.58,0,.94-1.76,2.66-2.76,2.7-5.64.27-11.31,0-17,.18-3.16.1-2.93-1.8-2.92-3.86,0-13.42.06-26.83,0-40.24a61.49,61.49,0,0,0-.81-10.86c-2.07-10.85-8.31-15.84-19-15.62-9.63.21-16.45,5.87-17.19,16.43-1,14.34-.77,28.77-.93,43.15-.12,10.95,0,10.95-11.16,10.95-11.46,0-11.46,0-11.46-11.2V395.5Z"
        fill="#606163"
        transform="translate(-220.31 -301.35)"
      />
      <path
        d="M864.82,396c.59-10.55.21-21.27,1.95-31.63,4.89-29.08,35.27-43.22,61-28.89a36.64,36.64,0,0,1,19.33,29.73c1.59,19.24,1.12,38.66,1.32,58,0,.79-2,2.24-3.14,2.3-5.15.25-10.32-.11-15.46.19-3.52.21-4.09-1.25-4.06-4.3q.21-19.86,0-39.73a60.32,60.32,0,0,0-1.11-12.31c-2.2-10.11-8.79-14.73-19.56-14.25-8.63.38-15.36,6.41-16.33,15.78-.87,8.36-.83,16.82-1,25.25-.15,8.6-.12,17.21,0,25.82,0,2.7-.61,3.85-3.57,3.72-5-.22-10-.23-15,0-3,.14-4.06-.71-4-3.84.2-8.6.07-17.21.07-25.82Z"
        fill="#606163"
        transform="translate(-220.31 -301.35)"
      />
      <path
        d="M704.92,389.33c0-9.93-.1-19.86,0-29.78.19-15.17,11.27-26.61,26.55-27.26,7.3-.31,14.63,0,21.94-.11,2.6-.05,3.53.77,3.45,3.39-.15,5.12-.18,10.26,0,15.38.1,2.78-.78,3.85-3.65,3.74-4.64-.17-9.31-.17-14,0-7.84.3-11.44,4.1-11.45,11.85,0,18.2-.08,36.4.07,54.6,0,3.34-.71,4.75-4.36,4.51a104.74,104.74,0,0,0-13.95,0c-3.64.24-4.89-.72-4.79-4.58C705.1,410.51,704.91,399.91,704.92,389.33Z"
        fill="#616264"
        transform="translate(-220.31 -301.35)"
      />
      <path
        d="M399.14,379.39c0,13.74-.19,27.48.11,41.21.09,4.28-1.25,5.38-5.3,5.07a132.65,132.65,0,0,0-14,0c-2.43.07-3.6-.48-3.59-3.24q.12-43.45,0-86.9c0-2.69,1-3.38,3.54-3.31,5.15.14,10.32.24,15.46,0,3.29-.18,3.82,1.18,3.79,4C399.07,350.58,399.14,365,399.14,379.39Z"
        fill="#626365"
        transform="translate(-220.31 -301.35)"
      />
      <path
        d="M506.11,378.91c0-13.9.11-27.81-.08-41.71,0-3.69.62-5.44,4.86-5.07a126.8,126.8,0,0,0,14.46.05c2.46-.07,3.55.54,3.54,3.28q-.12,43.46,0,86.91c0,2.76-1.1,3.32-3.54,3.26-5.16-.13-10.33-.23-15.47,0-3.25.16-3.85-1.15-3.83-4C506.18,407.39,506.11,393.15,506.11,378.91Z"
        fill="#636365"
        transform="translate(-220.31 -301.35)"
      />
      <path
        d="M619.07,401.85h-1c-8.75,0-9.11-.45-7.62-9,.37-2.11.41-4.3.92-6.37,1-3.93.54-6.86-2.82-10-5.61-5.27-4.75-14.24,1-19.51a14.14,14.14,0,0,1,19.43.19c5.54,5.36,6,14.3.46,19.58-2.76,2.61-3.79,4.87-2.8,8.53.85,3.16.89,6.53,1.4,9.8,1,6.23.51,6.77-6,6.8Z"
        fill="#fe8f3e"
        transform="translate(-220.31 -301.35)"
      />
      <path
        d="M670.07,321.14l19,1.15a30.54,30.54,0,0,0-3.85,2.91c-3.94,4.35-7.89,8.7-11.64,13.2-2.52,3-4.27,6.81-7.15,9.39-6.6,5.91-16.94,3.91-21.36-3.68.71-1,1.46-2,2.14-3,2.62-3.93,4.52-8.69,8-11.61C659.52,325.92,665.07,323.86,670.07,321.14Z"
        fill="#8dd072"
        transform="translate(-220.31 -301.35)"
      />
      <path
        d="M670.07,321.14c-5,2.72-10.55,4.78-14.82,8.37-3.47,2.92-5.37,7.68-8,11.61-.68,1-1.43,2-2.14,3-5-8.84-3-16.81,6.6-22.71,7-4.28,12.06-9.69,14.69-17.44l1.52,0Q669,312.55,670.07,321.14Z"
        fill="#319f65"
        transform="translate(-220.31 -301.35)"
      />
    </SvgIcon>
  );
});
