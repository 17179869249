import {
  memo,
  useEffect,
  useRef,
  useState,
  type MutableRefObject,
  type ReactElement,
} from "react";
import { useNavigate } from "react-router-dom";

import { ReturnIf } from "babel-plugin-transform-functional-return";
import styled from "styled-components";

import NativeSelect from "@mui/material/NativeSelect";
import Tabs from "@mui/material/Tabs";

import { type FOTS } from "data/typescript";

import { classIf } from "utility/style";

import { type LinkTab } from "Component/LinkTab";

// -----------------------------------------------------------------------------

export const PageTabs = memo(({ children, value, ...props }: Props) => {
  const navigate = useNavigate();

  //
  const tabsRef = useRef<HTMLDivElement>(null);
  const maxRightRef: MutableRefObject<number> = useRef<number>(
    Number.MAX_SAFE_INTEGER
  );

  //
  const [useDropdown, setUseDropdown] = useState(false);

  //
  useEffect(() => {
    function onResize() {
      const parent = tabsRef.current as HTMLDivElement;
      const parentBounds = parent.getBoundingClientRect();

      const tabs = Array.from(parent.querySelectorAll("a"));
      const lastTabBounds = tabs?.pop()?.getBoundingClientRect() ?? {
        right: Number.MAX_SAFE_INTEGER,
      };
      maxRightRef.current = lastTabBounds.right;

      //
      const maxRight = maxRightRef?.current ?? Number.MAX_SAFE_INTEGER;

      if (parentBounds.right >= maxRight) {
        maxRightRef.current = Number.MAX_SAFE_INTEGER;
        setUseDropdown(false);
        return;
      }

      //
      setUseDropdown(lastTabBounds.right >= parentBounds.width);
    }

    //
    window.addEventListener("resize", onResize);
    onResize();

    //
    return () => window.removeEventListener("resize", onResize);
  }, []);

  //
  const realChildren = Array.isArray(children) ? children : [children];

  //
  return (
    <Container ref={tabsRef} className={classIf(useDropdown, "dropdown")}>
      {useDropdown && (
        <TabsSelect
          fullWidth
          value={value}
          onChange={(e: FOTS) => {
            const child: FOTS = realChildren[e.target.value];
            ReturnIf(!child);

            //
            navigate(child.props.to);
          }}
        >
          {realChildren.map((child: FOTS, index: number) => {
            ReturnIf(!child, null);

            //
            return (
              <option value={index} key={child.props.to}>
                {child.props.label}
              </option>
            );
          })}
        </TabsSelect>
      )}
      <Tabs value={value} {...props}>
        {children}
      </Tabs>
    </Container>
  );
});

// -----------------------------------------------------------------------------

const Container = styled.div`
  display: flex;
  position: relative;

  & .MuiTabs-root {
    overflow: visible;
  }

  & .MuiTabs-scroller {
    overflow: visible !important;
  }

  &.dropdown {
    & .MuiTabs-root {
      inset: 0;
      opacity: 0;
      pointer-events: none;
      position: absolute;
    }
  }
`;

const TabsSelect = styled(NativeSelect)`
  margin-bottom: clamp(8px, 0.5rem + (1vw - 3.75px) * 1.9394, 24px);
`;

// -----------------------------------------------------------------------------

interface Props {
  children:
    | ReactElement<typeof LinkTab>
    | Array<ReactElement<typeof LinkTab> | null>;
  value: number;
}
