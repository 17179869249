import { WebhookConfigurePage } from "./Configure";
import { WebhookConnectPage } from "./Connect";
import { WebhookCoverImage } from "./CoverImage";

// -----------------------------------------------------------------------------

export { WebhookConfigurePage, WebhookConnectPage };

export const WebhookAppConfig = {
  name: "webhook_url",
  label: "Webhooks",
  singularLabel: "Webhook",
  coverImage: WebhookCoverImage,
  searchTerms: [],
  url: "webhook",
  connectInfo: {
    instructions: {
      example: "third-party application",
      extraExample: "common security applications, such as Splunk",
      extras: [
        "ThreatKey Webhooks can also be used with automation platforms like Zapier and Tray. Automation platforms can send security alerts to a variety of different services, such as Jira, PagerDuty, or email.",
      ],
    },
    placeholder: "https://your-webhook-url-here",
    // Disable this check so we match the backend regex exactly.
    // eslint-disable-next-line no-useless-escape
    regex: /^(?:https:\/\/)(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/,
    path: "webhook",
  },
  notifications: true,
  notificationKey: "webhook",
};
