import { memo, useState } from "react";
import { Navigate } from "react-router-dom";

import { ReturnIf } from "babel-plugin-transform-functional-return";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SportsMartialArtsIcon from "@mui/icons-material/SportsMartialArts";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

import { useOrganizationStore } from "stores/OrganizationStore";
import { useUserStore } from "stores/UserStore";

import { isValidEmail } from "utility/is_valid_email";
import { notifyError, notifySuccess, notifyWarn } from "utility/notify";
import { userCanInviteMembers, userIsSuperAdmin } from "utility/user";

import { usePageTitle } from "effect/use_page_title";

import { MembersPageInviteForm as InviteForm } from "./Form";

// -----------------------------------------------------------------------------

const RolesBase: { [prop: string]: RoleDefinition } = {
  UnprivilegedUser: {
    label: "Member",
    role: "UnprivilegedUser",
    icon: <VerifiedUserIcon />,
    definition:
      "Can view and edit all data in the organization. Cannot invite other members. Cannot change organization settings. Cannot delete the organization. Cannot change the organization's subscription. Cannot change the organization's billing information. Cannot change the organization's payment method.",
  },
  Auditor: {
    label: "Auditor",
    role: "Auditor",
    icon: <AddModeratorIcon />,
    definition:
      "Can view all data in the organization. Cannot edit data. Cannot invite new members. Cannot edit member roles. Cannot edit organization settings. Cannot edit organization billing. Cannot edit organization members.",
  },
  Admin: {
    label: "Admin",
    role: "Admin",
    icon: <AdminPanelSettingsIcon />,
    definition:
      "Can view and edit all data in the organization. Can invite new members. Can edit member roles. Can edit organization settings. Can edit organization billing. Can edit organization members.",
  },
  // Billing: {
  //   label: "Billing",
  //   role: "Billing",
  //   icon: <AttachMoneyIcon />,
  //   definition: "foo bar baz",
  // },
};

const SuperAdminRoles: { [prop: string]: RoleDefinition } = {
  ...RolesBase,
  SuperAdmin: {
    label: "Super Admin",
    role: "SuperAdmin",
    icon: <SportsMartialArtsIcon />,
    definition:
      "Can do anything. Can invite new members. Can change the organization name. Can change the organization plan. Can change the organization billing information. Can change the organization members. Can change the organization settings. Can change the organization integrations. Can change the organization API keys.",
  },
};

const Fields: Array<"email" | "name" | "role"> = ["email", "name", "role"];

const FieldValidator: { [prop: string]: Function } = {
  email: isValidEmail,
  name: isNotEmpty,
  role: isValidRole,
};

const FieldErrorMessage: { [prop: string]: string } = {
  email: "Email is invalid",
  name: "Name is missing",
  role: "Role is invalid",
};

const emptyInvite: Invite = { email: "", name: "", role: "" };

// -----------------------------------------------------------------------------

export const MembersInvite = memo(() => {
  usePageTitle("Members → Invite");

  //
  const [userStore] = useUserStore();
  const [, organizationActions] = useOrganizationStore();

  const [invites, setInvites] = useState<Invite[]>([emptyInvite]);
  const [communicatingWithServer, setCommunicatingWithServer] = useState(false);

  //
  const modifyInvite = (index: number) => {
    return (newInvite: Invite) => {
      const newInvites = [...invites];
      newInvites.splice(index, 1, newInvite);

      setInvites(newInvites);
    };
  };

  const deleteInvite = (index: number) => () => {
    const newInvites = [...invites];
    newInvites.splice(index, 1);
    newInvites.length < 1 && newInvites.push(emptyInvite);

    setInvites(newInvites);
  };

  const addNewInvite = () =>
    isLastInviteComplete(invites) &&
    setInvites((invites: Invite[]) => [...invites, emptyInvite]);

  const sendInvites = () => {
    (async () => {
      setCommunicatingWithServer(true);
      invitesResponse(
        await sendingInvites(invites, organizationActions),
        setInvites
      );
      setCommunicatingWithServer(false);
      organizationActions.resetMemberAdded();
      organizationActions.resetMembersLoaded();
      organizationActions.resetMembersLoadAttempted();
    })();
  };

  //
  const Roles: any = userIsSuperAdmin(userStore.user)
    ? SuperAdminRoles
    : RolesBase;

  //
  return userCanInviteMembers(userStore.user) ? (
    <>
      <Box sx={{ mb: 2 }}>
        <Button
          variant="contained"
          onClick={sendInvites}
          disabled={!isLastInviteComplete(invites) || communicatingWithServer}
        >
          {invites.length > 1
            ? `Send Invites (${invites.length})`
            : "Send Invite"}
        </Button>
      </Box>
      {invites.map((invite: Invite, key: number) => {
        return (
          <InviteForm
            Roles={Roles}
            index={key + 1}
            invite={invite}
            errors={getInviteErrors(invite)}
            onChange={modifyInvite(key)}
            onDelete={deleteInvite(key)}
            disabled={communicatingWithServer}
            key={key}
            hideNumberLabel={invites.length < 2}
          />
        );
      })}
      <Box sx={{ mt: 2 }}>
        {!isLastInviteComplete(invites) || communicatingWithServer ? null : (
          <Button
            variant="text"
            startIcon={<AddCircleOutlineIcon />}
            disabled={!isLastInviteComplete(invites) || communicatingWithServer}
            onClick={addNewInvite}
          >
            ADD ANOTHER
          </Button>
        )}
      </Box>
    </>
  ) : (
    <Navigate to="/members" />
  );
});

// -----------------------------------------------------------------------------

async function sendingInvites(
  invites: Invite[] = [],
  actions: any
): Promise<InviteResponse[]> {
  const results: InviteResponse[] = [];

  //
  for (const invite of invites) {
    const inviteResult = await actions.addMember(invite);

    //
    results.push(
      inviteResult?.memberAdded ?? {
        ...invite,
        success: false,
        errorMsg: "There was an error",
      }
    );
  }

  //
  return results;
}

function invitesResponse(
  results: InviteResponse[],
  setInvites: Function
): void {
  return results.length === 1
    ? singleInviteResponse(results[0], setInvites)
    : multipleInvitesResponse(results, setInvites);
}

function singleInviteResponse(
  result: InviteResponse,
  setInvites: Function
): void {
  const { email, role, success, errorMsg } = result;
  const roleLabel = SuperAdminRoles?.[role]?.label || role;

  //
  return success
    ? successToastAndClearInvites(`Invited ${email} (${roleLabel})`, setInvites)
    : errorToast(
        `Failed to invite ${email} (${roleLabel}). ${
          errorMsg ?? "There was an error"
        }`
      );
}

function multipleInvitesResponse(
  results: InviteResponse[] = [],
  setInvites: Function
): void {
  const inviteStatus = results.reduce(
    (count: number, result: InviteResponse) =>
      result.success ? count + 1 : count,
    0
  );

  switch (true) {
    case inviteStatus === 0:
      return errorToast("Failed to send any invites");

    case inviteStatus === results.length:
      return successToastAndClearInvites("All invites were sent", setInvites);

    default: {
      const failedInvites = results.reduce(
        (obj: { [email: string]: boolean }, result: InviteResponse) => {
          obj[result.email] = !result.success;

          //
          return obj;
        },
        {}
      );

      //
      warnToast("Some invites were not sent");
      setInvites((invites: Invite[]) =>
        invites.filter((invite) => failedInvites[invite.email])
      );
    }
  }
}

function successToastAndClearInvites(
  message: string,
  setInvites: Function
): void {
  setInvites([emptyInvite]);
  notifySuccess(message);
}

function errorToast(message: string) {
  notifyError(message);
}

function warnToast(message: string) {
  notifyWarn(message);
}

// -----------------------------------------------------------------------------

function getInviteErrors(invite: Invite = emptyInvite): {
  [field: string]: string | undefined;
} {
  return Fields.reduce(
    (errors: { [field: string]: string | undefined }, field) => {
      const value = invite[field] ?? "";
      errors[field] = isNotEmpty(value)
        ? validateField(field, value)
        : undefined;

      //
      return errors;
    },
    {}
  );
}

function validateField(field: string, value: string): string {
  const validator = FieldValidator[field] || isAlwaysFalse;

  //
  return validator(value)
    ? (undefined as any)
    : FieldErrorMessage[field] || "Field not valid";
}

function isLastInviteComplete(invites: Invite[] = []): boolean {
  return invites.length > 0
    ? isInviteComplete(invites[invites.length - 1])
    : false;
}

function isInviteComplete(invite: Invite): boolean {
  ReturnIf(invite === emptyInvite, false);

  const values = Object.values(invite);
  ReturnIf(values.length !== Fields.length, false);

  //
  return Fields.every((field): boolean => {
    const validator = FieldValidator[field] || isAlwaysFalse;

    //
    return validator(invite[field]);
  });
}

function isNotEmpty(value: string): boolean {
  return value.length > 0;
}

function isValidRole(value: string): boolean {
  return (RolesBase[value] || SuperAdminRoles[value]) !== undefined;
}

function isAlwaysFalse(value: any): boolean {
  return false;
}

// -----------------------------------------------------------------------------

interface RoleDefinition {
  label: string;
  role: string;
  icon: JSX.Element;
  definition: string;
}

interface Invite {
  email: string;
  name: string;
  role: string;
}

interface InviteResponse {
  email: string;
  name: string;
  role: string;
  success: boolean;
  errorMsg: string;
}
