import { memo } from "react";

import SvgIcon from "@mui/material/SvgIcon";

// -----------------------------------------------------------------------------

export const GenericOIDCSSOIcon = memo((props: object) => {
  return (
    <SvgIcon viewBox="0 0 757.78 169.74" {...props}>
      <path
        d="M692.75,464l-4.87-.89-10.36,22.18c-3.93,8.4-7.73,16.86-11.84,25.17-2.4,4.84-5.95,7.9-12.07,8-6.77.1-10.56-3.33-13.16-8.73-4.18-8.66-8.15-17.41-12.2-26.12q-4.8-10.37-9.57-20.75a38.73,38.73,0,0,1-1.87-4.59c-1.73-5.52.48-10.7,5.2-12.4,5.7-2.06,10.89-.08,13.27,5.39,5.1,11.69,10,23.45,15,35.18.77,1.78,1.64,3.52,2.93,6.3,1.15-2.29,1.91-3.65,2.52-5.07,3.8-8.82,8-17.5,11.26-26.52,6.72-18.74,28.91-20.23,40.3-8.69a12.83,12.83,0,0,0,1.52,1.17c14.17-11.34,30.78-11.45,42.79.06,6.89-6.41,15.12-8.93,24.59-8.36,12,.72,21.95,10.51,22.08,22.64.14,13.09.08,26.18,0,39.26,0,6.94-3.45,10.85-9.38,11.07-7.28.28-11.08-3-11.4-10.59-.3-7.28-.08-14.57-.08-21.86,0-4.31.11-8.62-.06-12.92-.25-6.32-4.28-9.93-10.69-9.85s-10.48,3.86-10.56,10.06c-.16,10.76,0,21.53-.1,32.3a22.58,22.58,0,0,1-.77,6.36c-1.51,4.95-4.8,6.79-10.89,6.51-4.64-.21-7.72-2.65-8.67-7.38a39.72,39.72,0,0,1-.42-7.41c0-9.77.08-19.55,0-29.32-.1-8-5.17-12.17-13.07-11-5.44.84-8.67,4.95-8.73,11.53-.09,10.93,0,21.87-.05,32.8-.07,7.65-4,11.18-11.59,10.74-4.56-.26-8-3-8.41-7.59-.55-5.58-.56-11.22-.61-16.84C692.69,484.13,692.75,474.38,692.75,464Z"
        fill="#737375"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M831.08,501.05c3-8.48,5.59-15.91,8.17-23.35,2.94-8.45,5.82-16.91,8.8-25.34,1.06-3,2.19-6.06,6.32-5.91,4,.15,5.15,3,6.21,6.11q7.17,20.9,14.44,41.78c.64,1.85,1.37,3.68,2.36,6.31.81-1.75,1.4-2.78,1.79-3.89q8-22.71,15.89-45.46a11.75,11.75,0,0,1,.73-1.84c1.39-2.48,3.53-3.67,6.25-2.68s4.22,3.24,3.14,6.32c-2.88,8.28-5.83,16.55-8.79,24.81q-5.86,16.36-11.8,32.7c-.45,1.25-.68,2.82-1.57,3.6-1.74,1.52-3.93,3.64-5.83,3.54s-4.52-2.29-5.24-4.17c-4.43-11.6-8.42-23.37-12.55-35.08a80.55,80.55,0,0,0-5.6-13.2c-.64,1.56-1.34,3.09-1.9,4.68q-7.53,21.3-15,42.63c-1,3-2.49,5.5-6.2,5.44s-5.27-2.49-6.33-5.43Q814,483.9,803.72,455.13c-.44-1.24-1.34-2.92-.88-3.74,1-1.84,2.56-4,4.38-4.67,2.6-1,4.86.65,5.88,3.27,1.33,3.39,2.48,6.85,3.67,10.29l12.8,37.1C829.87,498.25,830.26,499.08,831.08,501.05Z"
        fill="#757577"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M1030.13,486.29c-.68,11.82,8.94,22.15,21.1,23.15a27.61,27.61,0,0,0,19.58-5.89c1-.8,2-2.23,3.14-2.32,1.91-.15,4.42-.06,5.65,1.06.89.82.77,3.81,0,5.17a16.28,16.28,0,0,1-5,4.7c-11.77,7.92-24.46,8.7-37,2.72s-18-17.05-18.68-30.64c-.44-9.07,1.68-17.51,7.15-24.8,8.13-10.83,19.21-15.15,32.46-12.54s20.75,11.74,24.15,24.38a36.28,36.28,0,0,1,1.06,7.85c.24,5.79-1,7.12-6.79,7.14-11.65.05-23.29,0-34.94,0Zm-.16-8.06h42.88c.81-10.91-8.64-23.41-20.51-23.05C1039.71,455.55,1029.84,465.14,1030,478.23Z"
        fill="#757577"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M956.67,476.62c1.48-10.06-3.66-17.92-13.07-20.15a36,36,0,0,0-20.11,1.1c-1.88.63-3.75,1.27-6.55,2.23-1.15-1.76-3.21-3.53-3.17-5.26,0-1.55,2.08-3.77,3.76-4.46,11.31-4.68,23-5.34,34.51-1.12,10.19,3.75,14.71,12.18,15,22.55.38,13.56.15,27.13.09,40.7,0,4-1.8,5.95-5.11,5.89-3.67-.06-4.79-2.35-4.78-5.6,0-1.29-.11-2.59-.2-4.45a22.8,22.8,0,0,0-2.6,1.87c-9.17,8.92-20.31,10.11-32,7.32-10.71-2.56-17.07-10.87-17-20.61.12-10.29,7-18.39,17.85-21.06,10.06-2.48,20.08-1.56,30.07.44Zm.18,8.16a63.61,63.61,0,0,0-25.26-2.42,27.78,27.78,0,0,0-7.59,2.12c-5.14,2.14-7.64,6.41-7.69,11.72-.07,5.58,2.78,9.83,8,12.1,8.78,3.87,17.26,2.58,25.07-2.54C956.76,500.9,958.29,493.57,956.85,484.78Z"
        fill="#757577"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M549.94,432.07c21.85,5.13,37.44,17.32,44.55,39.09l0,.33-16-.18h-13.8l-14.18,0a1.36,1.36,0,0,0-.58-.56Z"
        fill="#b7d003"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M991.05,460.71c2.19-2.39,4.24-4.89,6.55-7.1a25.09,25.09,0,0,1,15.54-7.07c3.64-.31,6.36,1.64,6.53,4.59.18,3.28-1.57,5.58-4.75,6-17.07,2.19-24.48,15.48-24.79,32.55-.13,7.27,0,14.55,0,21.83,0,4.24-1.89,6.55-5.07,6.51s-5.07-2.26-5.08-6.56q-.06-29.26,0-58.54c0-4.45,1.71-6.45,5.1-6.45s5,1.87,5.07,6.38c0,2.31.07,4.63.15,6.94C990.27,460,990.64,460.24,991.05,460.71Z"
        fill="#757577"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M367.71,430.55l4.08-6.8,36-.06h0l-.34,46Q387.61,450.15,367.71,430.55Z"
        fill="#8ad6f2"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M496.64,383.18l6,2.83.12,37.73-.3.09-46.57-.16a2.77,2.77,0,0,1-.05-.86q15.22-14.62,30.45-29.24C489.78,390.17,493.18,386.65,496.64,383.18Z"
        fill="#005352"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M549.94,432.07l-.06,38.65-45.27.13-1-.35c1.07-1.24,2.08-2.56,3.23-3.72,10.2-10.19,20.47-20.31,30.59-30.57,1.39-1.42,2.11-3.5,3.14-5.27l2,.1,1.68.24Z"
        fill="#003823"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M540.59,430.94c-1,1.77-1.75,3.85-3.14,5.27-10.12,10.26-20.39,20.38-30.59,30.57-1.15,1.16-2.16,2.48-3.23,3.72l-.83,0c-.06-1.82-.14-3.64-.15-5.46q-.12-20.62-.22-41.25l.3-.09,29,0c.65,2.19,1.3,4.37,2,6.64Z"
        fill="#01472f"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M367.71,430.55q19.91,19.59,39.82,39.17l.07-.11,1,1.11q0,.36.12.72a1.94,1.94,0,0,1-.72,0l-47-.1L361,471l-.08-7.44,4-1.3C362.46,451.41,361.85,440.66,367.71,430.55Z"
        fill="#05547e"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M594.49,471.16C600,488,597,503.43,586.91,517.75l-36.24-.39v-1l2-1.93,41-40.7.81-2.24Z"
        fill="#80c314"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M496.64,383.18c-3.46,3.47-6.86,7-10.38,10.39q-15.19,14.65-30.45,29.24,0-18.78-.08-37.56C469.06,378.53,482.69,377.7,496.64,383.18Z"
        fill="#017469"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M328.89,486.39a30.73,30.73,0,0,1,14.12-15L361,471l.09.34c-.06,1.81-.17,3.62-.18,5.43q-.12,19.75-.2,39.51a39.48,39.48,0,0,1-4.65-3.38C346.93,504.09,337.92,495.23,328.89,486.39Z"
        fill="#8ad6f2"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M428.63,545.93l-20.74-9.06-.18-19.35.43-1.32c1.7.67,3.39,1.88,5.09,1.9,12.28.16,24.56.12,36.83,0a23.73,23.73,0,0,0,4.64-.94l1.1,0,0,1-.23,1.1Z"
        fill="#034a57"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M502.53,533.2a28.91,28.91,0,0,0-4.86,1.4c-5.7,2.78-11.32,5.73-17,8.61-1.3-1.48-2.52-3-3.9-4.44q-10-10.31-20.13-20.54l45.72,0,.26-.05h.26Z"
        fill="#009e9b"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M502.53,533.2l.39-15c7.58,0,15.16.07,22.74.06s15.3-.08,22.94-.13l0,.09c-1.18,1.36-2.29,2.78-3.55,4.06q-9.67,9.87-19.42,19.68A50.11,50.11,0,0,1,502.53,533.2Z"
        fill="#097d32"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M531.71,423.71l-29,0L502.61,386C518.18,394.09,527.76,406.75,531.71,423.71Z"
        fill="#003823"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M407.71,517.52l.18,19.35-9.2-5.86-20.82,4L361,519a6.85,6.85,0,0,0-.16-.76,6.32,6.32,0,0,1,.24-.85,2.42,2.42,0,0,0,.76-.12Z"
        fill="#0476b4"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M361.08,517.42a6.32,6.32,0,0,0-.24.85,4.32,4.32,0,0,0-.88,0c-7.22,0-14.44-.12-21.66,0a13.45,13.45,0,0,0-3.57,1.06,28.24,28.24,0,0,1-5.84-32.92c9,8.84,18,17.7,27.13,26.49a39.48,39.48,0,0,0,4.65,3.38Z"
        fill="#abdff9"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M407.81,411.56l18.73,5.49c2.48-4.07,5.28-8.69,8.08-13.3q10,10,20,20.06l-46.78-.12h0Z"
        fill="#01bef0"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M428.63,545.93l26.91-26.67L455.6,549A62.85,62.85,0,0,1,428.63,545.93Z"
        fill="#24245c"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M454.65,423.81q-10-10-20-20.06c5.12-8.34,12.36-14.28,21.11-18.5q0,18.78.08,37.56a2.77,2.77,0,0,0,.05.86l-.34.15a3.12,3.12,0,0,0-.86-.06Z"
        fill="#009e9b"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M455.6,549l-.06-29.74.23-1.1.91.07q10.06,10.26,20.13,20.54c1.38,1.41,2.6,3,3.9,4.44A57.62,57.62,0,0,1,455.6,549Z"
        fill="#008988"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M550.67,517.36l36.24.39a50,50,0,0,1-20,17.55L550.57,519a2.31,2.31,0,0,0-.18-.62Z"
        fill="#54c898"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M407.81,411.56l0,12.13-36,.06C381.49,412.94,393.76,409.64,407.81,411.56Z"
        fill="#68cac5"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M550.39,518.38a2.31,2.31,0,0,1,.18.62l-.68,19.1v1l-.09,2.39a48.66,48.66,0,0,1-24.18.45q9.72-9.84,19.42-19.68c1.26-1.28,2.37-2.7,3.55-4.06Z"
        fill="#01472f"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M549.89,538.1l.68-19.1,16.32,16.3-.16.15-1.09.65-15.85,5.38.09-2.39c.1-.1.28-.22.26-.3A3.57,3.57,0,0,0,549.89,538.1Z"
        fill="#00ae37"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M334.73,519.31a13.45,13.45,0,0,1,3.57-1.06c7.22-.07,14.44,0,21.66,0l-11.17,10.86C342.77,527.77,338.61,523.74,334.73,519.31Z"
        fill="#8ad6f2"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M361,519l16.87,16L360.81,534Z"
        fill="#00a7e1"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M361,519l-.19,15-12-4.83L360,518.3a4.32,4.32,0,0,1,.88,0A6.85,6.85,0,0,1,361,519Z"
        fill="#01bef0"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M361,471l-17.95.45c5-4.85,11.29-6.67,17.87-7.89Z"
        fill="#0476b4"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M544.31,431.28l-1.68-.24Z"
        fill="#01472f"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M565.64,536.1l1.09-.65Z"
        fill="#54c898"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M550.39,518.38l-1.81-.19,0-.09a35.42,35.42,0,0,0-3.4-4.68c-12.76-12.81-25.61-25.52-38.35-38.34-1.08-1.08-1.51-2.8-2.24-4.23l45.27-.13a1.36,1.36,0,0,1,.58.56l.09,1.19-.94,35.79v5l1.07,3.14v1Z"
        fill="#00ae37"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M549.61,513.22v-5l.94-35.79,14.09-1.16h13.8c3.12.39,6.24.71,9.35,1.18,2,.3,3.91.82,5.87,1.24l-41,40.7Z"
        fill="#138735"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M593.66,473.73c-2-.42-3.9-.94-5.87-1.24-3.11-.47-6.23-.79-9.35-1.18l16,.18Z"
        fill="#097d32"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M564.64,471.31l-14.09,1.16-.09-1.19Z"
        fill="#097d32"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M575.62,471.31h0Z"
        fill="#097d32"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M407.87,423.69l46.78.12v-.05l-.08,1.92c-5.2,4.78-10.5,9.45-15.58,14.35-10.11,9.75-20.09,19.63-30.12,29.46l-1.28.12-.07.11Z"
        fill="#5ecbf4"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M502.43,423.83q.1,20.63.22,41.25c0,1.82.09,3.64.15,5.46l-.23,1c-.29,0-.59,0-.88-.05a7.46,7.46,0,0,1-.06-.95,5.14,5.14,0,0,0-.72-1.77C495,463,489,457.27,483.08,451.49q-13.71-13.35-27.34-26.77a9.08,9.08,0,0,0-.22-.9l.34-.15Z"
        fill="#008988"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M502.57,471.55l.23-1,.83,0,1,.35c.73,1.43,1.16,3.15,2.24,4.23,12.74,12.82,25.59,25.53,38.35,38.34a35.42,35.42,0,0,1,3.4,4.68c-7.64,0-15.29.12-22.94.13s-15.16,0-22.74-.06h-.26l-.26.05Q502.49,494.89,502.57,471.55Z"
        fill="#009338"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M455.8,517.16l-1.1,0-44.95-44.73-1-1q-.08-.36-.12-.72l.13-.29,46.88.06c0,13.64,0,27.29.07,40.94a28,28,0,0,0,1,5.12Z"
        fill="#00a7e1"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M361.08,517.42l-.41-1.16q.09-19.75.2-39.51c0-1.81.12-3.62.18-5.43l47,.1a2.34,2.34,0,0,0-.35.92,40.49,40.49,0,0,0-4.68,3.47q-17.46,17.25-34.79,34.61c-2.2,2.21-4.23,4.58-6.35,6.88A2.42,2.42,0,0,1,361.08,517.42Z"
        fill="#6c8e9c"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M407.66,472.34a2.34,2.34,0,0,1,.35-.92,1.94,1.94,0,0,0,.72,0l1,1-1.25,1Z"
        fill="#034a57"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M407.6,469.61l1.28-.12a5.92,5.92,0,0,0-.14.94l-.13.29Z"
        fill="#8ad6f2"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M549.61,513.22l3.07,1.21-2,1.93Z"
        fill="#009338"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M408.5,473.51l1.25-1L454.7,517.2a23.73,23.73,0,0,1-4.64.94c-12.27.08-24.55.12-36.83,0-1.7,0-3.39-1.23-5.09-1.9Z"
        fill="#003823"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M455.8,517.16l.87-.61c4.62-4.54,9.26-9,13.83-13.63q15.63-15.68,31.19-31.42c.29,0,.59,0,.88.05q-.07,23.32-.17,46.66l-45.72,0-.91-.07Z"
        fill="#009edc"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M408.5,473.51l-.36,42.69-.43,1.32-45.87-.22c2.12-2.3,4.15-4.67,6.35-6.88q17.35-17.35,34.79-34.61a40.49,40.49,0,0,1,4.68-3.47Z"
        fill="#5b6c75"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M455.52,423.82a9.08,9.08,0,0,1,.22.9l-.18,1c-.32,0-.65,0-1-.07l.08-1.92A3.12,3.12,0,0,1,455.52,423.82Z"
        fill="#00a7e1"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M549.89,538.1a3.57,3.57,0,0,1,.25.69c0,.08-.16.2-.26.3Z"
        fill="#009338"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M549.61,508.26v0Z"
        fill="#009338"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M454.58,425.68c.33,0,.66.06,1,.07l.19,44.79-.13-.05-46.88-.06a5.92,5.92,0,0,1,.14-.94c10-9.83,20-19.71,30.12-29.46C444.08,435.13,449.38,430.46,454.58,425.68Z"
        fill="#01bef0"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M501.69,471.5q-15.6,15.72-31.19,31.42c-4.57,4.59-9.21,9.09-13.83,13.63a28,28,0,0,1-1-5.12c-.09-13.65-.06-27.3-.07-40.94l.13.05h45.88A7.46,7.46,0,0,0,501.69,471.5Z"
        fill="#0057a2"
        transform="translate(-325.96 -379.58)"
      />
      <path
        d="M501.63,470.55H455.75l-.19-44.79.18-1q13.67,13.39,27.34,26.77C489,457.27,495,463,500.91,468.78A5.14,5.14,0,0,1,501.63,470.55Z"
        fill="#009e9b"
        transform="translate(-325.96 -379.58)"
      />
    </SvgIcon>
  );
});
