import { toast, type ToastPosition } from "react-toastify";

// -----------------------------------------------------------------------------

const settings = {
  position: "bottom-center" as ToastPosition,
};

// -----------------------------------------------------------------------------

export function notify(
  methodName: "error" | "success" | "warn" | "info",
  message: string
) {
  toast.dismiss();
  toast[methodName](message, settings);
}

export function notifyError(message: string): void {
  notify("error", message);
}

export function notifySuccess(message: string): void {
  notify("success", message);
}

export function notifyWarn(message: string): void {
  notify("warn", message);
}

export function notifyInfo(message: string): void {
  notify("info", message);
}
