import { memo, useEffect, useState } from "react";

import { DataGridPremium } from "@mui/x-data-grid-premium";
import { ReturnIf } from "babel-plugin-transform-functional-return";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import CancelIcon from "@mui/icons-material/Cancel";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";

import { type FOTS } from "data/typescript";

import {
  type OrganizationMember,
  useOrganizationStore,
} from "stores/OrganizationStore";
import { useUserStore } from "stores/UserStore";

import { notifyError, notifySuccess } from "utility/notify";
import { userIsAdmin, userIsAdminOnly, userIsSuperAdmin } from "utility/user";

import { usePageTitle } from "effect/use_page_title";

import { LoginMethod, MembersColumns } from "./data";
import { MembersDeleteConfirmationModal } from "./DeleteConfirmationModal";

// -----------------------------------------------------------------------------

const GreedySpace: RegExp = /\s+/g;
const SearchableKeys: Array<
  keyof Pick<OrganizationMember, "email" | "permission_level" | "full_name">
> = ["email", "permission_level", "full_name"];

// -----------------------------------------------------------------------------

const DataGridSX = {
  border: 0,

  "& .MuiDataGrid-cell": {
    wordBreak: "break-all",

    "&:focus": {
      outline: "none",
    },
  },

  "& .MuiDataGrid-columnHeader": {
    "&:focus": {
      outline: "none",
    },
  },

  "& .MuiDataGrid-columnHeaderTitleContainer": {
    padding: 0,
  },

  "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator": {
    display: "none",
  },
};

// -----------------------------------------------------------------------------

export const MembersPageList = memo(() => {
  usePageTitle("Organization → Members → List");

  //
  const [userStore] = useUserStore();
  const [organizationStore, organizationActions] = useOrganizationStore();

  //
  const [memberToDelete, setMemberToDelete] = useState(null as any);
  const [filterText, setFilterText] = useState("");
  const [openEdit, setOpenEdit] = useState(false);
  const [editMemberUuid, setEditMemberUuid] = useState("");
  const [editMemberName, setEditMemberName] = useState("");
  const [editMemberRole, setEditMemberRole] = useState("");
  const [editMemberLoginMethod, setEditMemberLoginMethod] = useState("");
  const [, setEditLoading] = useState(false);

  //
  useEffect(() => {
    ReturnIf(organizationStore?.memberDeleted?.success === null);

    //
    organizationStore?.memberDeleted?.success
      ? notifySuccess(
          `Deleted ${
            organizationStore?.memberDeleted?.member?.full_name ?? "member"
          }`
        )
      : notifyError(
          `Failed to delete ${
            organizationStore?.memberDeleted?.member?.full_name ?? "member"
          }`
        );
    organizationActions.resetMemberDeleted();
  }, [organizationStore.memberDeleted, organizationActions]);

  useEffect(() => {
    ReturnIf(
      organizationStore.membersLoadAttempted || organizationStore.membersLoading
    );

    //
    organizationActions.loadMembers();
  }, [organizationStore, organizationActions]);

  //
  function getLoginMethodOptions() {
    const loginMethods = Object.keys(
      organizationStore.organization.workos_conn_id_dict ?? {}
    );

    //
    return loginMethods.length > 0 ? (
      loginMethods.map((method) => (
        <MenuItem value={method} key={method}>
          {LoginMethod[method]}
        </MenuItem>
      ))
    ) : (
      <MenuItem value={editMemberLoginMethod} key={editMemberLoginMethod}>
        {LoginMethod[editMemberLoginMethod]}
      </MenuItem>
    );
  }

  function openEditDialog(member: OrganizationMember) {
    setOpenEdit(true);
    setEditMemberUuid(member.uuid);
    setEditMemberName(member.full_name);
    setEditMemberRole(member.permission_level);
    setEditMemberLoginMethod(member.login_method);
  }

  function resetEdit() {
    setEditLoading(false);
    setEditMemberUuid("");
    setEditMemberName("");
    setEditMemberRole("");
    setEditMemberLoginMethod("");
    setOpenEdit(false);
  }

  function closeEditDialog() {
    resetEdit();
  }

  function saveEdit() {
    setEditLoading(true);
    organizationActions.editMember(
      editMemberUuid,
      {
        permission_level: editMemberRole,
        login_method: editMemberLoginMethod,
      },
      onEditSuccess,
      onEditError
    );
    closeEditDialog();
  }

  function onEditSuccess() {
    resetEdit();
    organizationActions.loadMembers();
    notifySuccess(`Successfully updated the member`);
  }

  function onEditError() {
    resetEdit();
    notifyError(`Something went wrong updating the member`);
  }

  function deleteMemberIfRequested(index: number) {
    setMemberToDelete(null);
    ReturnIf(index < 1);

    //
    organizationActions.deleteMember(memberToDelete, () => {});
  }

  //
  const isAdmin = userIsAdmin(userStore.user);
  const isAdminOnly = userIsAdminOnly(userStore.user);
  const isSuperAdmin = userIsSuperAdmin(userStore.user);
  const treatedFilterText = filterText.trim();
  const filterTerms = treatedFilterText
    ? treatedFilterText.split(GreedySpace)
    : [];
  const superAdminUuids = organizationStore.members.reduce(
    (arr: string[], member: OrganizationMember) => {
      if (member.permission_level === "SuperAdmin") {
        arr.push(member.uuid);
      }

      //
      return arr;
    },
    []
  );
  const filteredMembers =
    filterTerms.length > 0
      ? (organizationStore.members ?? []).filter((member: OrganizationMember) =>
          SearchableKeys.some((key) =>
            filterTerms.some((term) => member[key].includes(term))
          )
        )
      : organizationStore.members ?? [];
  const members: FOTS[] = filteredMembers.map((member: OrganizationMember) => {
    const memberIsSuperAdmin = member.permission_level === "SuperAdmin";

    //
    return {
      ...member,
      actions: (
        <>
          <IconButton
            onClick={() => openEditDialog(member)}
            disabled={!isAdmin}
          >
            <EditIcon style={{ cursor: "pointer" }} />
          </IconButton>

          <IconButton
            onClick={() => setMemberToDelete(member)}
            disabled={
              (isAdminOnly && memberIsSuperAdmin) ||
              (superAdminUuids.length < 2 && memberIsSuperAdmin)
            }
          >
            <DeleteOutlineIcon />
          </IconButton>
        </>
      ),
    };
  });

  //
  return (
    <>
      <Paper
        elevation={1}
        sx={{
          p: 0,
          width: "100%",
          height: "100%",
          minHeight: 512,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {memberToDelete ? (
          <MembersDeleteConfirmationModal
            name={memberToDelete.full_name}
            onClose={deleteMemberIfRequested}
          />
        ) : null}
        <Box
          mx={{ display: "flex", justifyContent: "flex-end", margin: "8px" }}
        >
          <TextField
            variant="outlined"
            size="small"
            placeholder="Filter users"
            InputProps={{
              endAdornment: filterText ? (
                <InputAdornment position="end">
                  <CancelIcon
                    onClick={() => setFilterText("")}
                    style={{ cursor: "pointer" }}
                  />
                </InputAdornment>
              ) : null,
            }}
            value={filterText}
            onChange={(event) => setFilterText(event.target.value)}
          />
        </Box>
        <DataGridPremium
          disableSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          density="compact"
          getRowId={(row) => row.uuid}
          rows={members}
          rowCount={members.length}
          columns={MembersColumns}
          page={0}
          pageSize={20}
          rowsPerPageOptions={[20]}
          checkboxSelection={false}
          sx={DataGridSX}
          paginationMode="server"
          loading={organizationStore.membersLoading}
        />
      </Paper>

      <Dialog open={openEdit} onClose={closeEditDialog}>
        <DialogTitle>Edit Member</DialogTitle>

        <DialogContent sx={{ maxWidth: "400px" }}>
          <Stack spacing={2}>
            <DialogContentText>
              Edit {editMemberName}&apos;s permission level and login method.
            </DialogContentText>

            {editMemberUuid === userStore.user.uuid &&
              userIsSuperAdmin(userStore.user) &&
              superAdminUuids.length < 2 && (
                <Alert severity="warning">
                  You can&apos;t edit your role because you are the only Super
                  Admin.
                </Alert>
              )}
            <FormControl fullWidth>
              <InputLabel id="select-role-label">Role</InputLabel>
              <Select
                labelId="select-role-label"
                id="select-role"
                value={editMemberRole}
                label="Role"
                onChange={(event) => setEditMemberRole(event.target.value)}
                disabled={
                  (userIsAdminOnly(userStore.user) &&
                    superAdminUuids.includes(editMemberUuid)) ||
                  (editMemberUuid === userStore.user.uuid &&
                    userIsSuperAdmin(userStore.user) &&
                    superAdminUuids.length < 2)
                }
              >
                <MenuItem value={"SuperAdmin"} disabled={!isSuperAdmin}>
                  Super Admin
                </MenuItem>
                <MenuItem value={"Admin"}>Admin</MenuItem>
                <MenuItem value={"Auditor"}>Auditor</MenuItem>
                <MenuItem value={"UnprivilegedUser"}>Member</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="select-login-label">Login Method</InputLabel>
              <Select
                labelId="select-login-label"
                id="select-login"
                value={editMemberLoginMethod}
                label="Login Method"
                onChange={(event) =>
                  setEditMemberLoginMethod(event.target.value)
                }
              >
                {getLoginMethodOptions()}
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button onClick={closeEditDialog}>Cancel</Button>
          <Button onClick={saveEdit}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
});
