import { memo } from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";

import { ServiceConnectionPhase } from "Component/ServiceConnectionPhase";

// -----------------------------------------------------------------------------

export const AmazonWebServicesConnectPageCreatingPhase = memo(
  ({ disabled, progressIcon, successIcon, errorIcon }: Props) => {
    return (
      <ServiceConnectionPhase disabled={disabled}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {progressIcon ? (
            <CircularProgress size="24px" sx={{ mr: 1 }} />
          ) : null}
          {successIcon ? (
            <CheckCircleOutlineRoundedIcon color="success" sx={{ mr: 1 }} />
          ) : null}
          {errorIcon ? (
            <ErrorOutlineRoundedIcon color="error" sx={{ mr: 1 }} />
          ) : null}
          <Typography variant="body1">
            Submitting your connection for provisioning
          </Typography>
        </Box>
        <Stack sx={{ mt: 2 }} spacing={1} direction="column">
          <Typography variant="body1">
            We&apos;re getting everything ready to provision your AWS
            connection. Once everything looks good, we&apos;ll add your
            connection to our queue for provisioning.
          </Typography>
        </Stack>
      </ServiceConnectionPhase>
    );
  }
);

// -----------------------------------------------------------------------------

interface Props {
  disabled: boolean;
  progressIcon: boolean;
  successIcon: boolean;
  errorIcon: boolean;
}
