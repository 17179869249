import { memo } from "react";

import styled from "styled-components";

import Box from "@mui/material/Box";

import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

// -----------------------------------------------------------------------------

export const FindingsPageListFilteredEntry = memo(({ count }: Props) => {
  //
  return (
    <Container>
      <Content>
        <VisibilityOffIcon /> {count} findings have been hidden
      </Content>
    </Container>
  );
});

// -----------------------------------------------------------------------------

const Container = styled(Box)`
  cursor: default;
  position: relative;

  &.selected {
    &:after {
      bottom: 0;
      background-color: ${(props) => props.theme.primary.mainFaded};
      content: "";
      pointer-events: 0;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      z-index: 1;
    }
  }

  &:nth-child(even) {
    background: ${(props) => props.theme.primary.mainFaded};
  }

  & + & {
    border-top: thin ${(props) => props.theme.primary.border} solid;
  }
`;

const Content = styled(Box)`
  align-items: center;
  display: flex;
  justify-content: center;
  max-width: 1142px;
  min-height: 0vw;
  padding: clamp(8px, calc(0.5rem + ((1vw - 3.75px) * 0.9697)), 16px)
    clamp(8px, calc(0.5rem + ((1vw - 3.75px) * 1.9394)), 24px);
  text-align: center;

  svg {
    font-size: inherit;
    margin-right: 8px;
    margin-top: -2px;
  }
`;

// -----------------------------------------------------------------------------

interface Props {
  count: number;
}
