import styled from "styled-components";

// -----------------------------------------------------------------------------

export const Page = styled.div`
  display: grid;
  flex-grow: 1;
  grid-template-rows: auto 1fr;
  position: relative;
  width: 100%;
`;
