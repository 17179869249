import sortBy from "lodash/sortBy";

import { FindingSeverity, FindingSeverityLabel } from "data/finding";

import { type RateOfFinding } from "stores/DashboardStore";

import { dayjs } from "utility/dayjs";

// -----------------------------------------------------------------------------

export function getBarData(
  data: RateOfFinding[] = [],
  startDate: Date,
  endDate: Date
) {
  const dates: any = data.reduce((obj: any, stat: RateOfFinding) => {
    const key = dayjs(stat.create_time).format("YYYY-MM-DD");
    const item = obj[key] || {
      key,
      timestamp: stat.create_time,
      create_time: dayjs(stat.create_time).format("MM/DD"),
      [FindingSeverityLabel[FindingSeverity.Critical]]: 0,
      [`${FindingSeverityLabel[FindingSeverity.Critical]}Color`]: "#FF6257",
      [FindingSeverityLabel[FindingSeverity.High]]: 0,
      [`${FindingSeverityLabel[FindingSeverity.High]}Color`]: "#FFB458",
      [FindingSeverityLabel[FindingSeverity.Medium]]: 0,
    };

    obj[key] = item;

    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    item[FindingSeverityLabel[stat.severity]] += stat.count || 0;

    //
    return obj;
  }, {});

  // fill empty dates
  let now = dayjs(startDate);
  while (now.isBefore(endDate, "date")) {
    now = now.add(1, "day");
    const key = now.format("YYYY-MM-DD");

    if (dates[key] === undefined) {
      dates[key] = {
        key,
        timestamp: now.toISOString(),
        create_time: now.format("MM/DD"),
        [FindingSeverityLabel[FindingSeverity.Critical]]: 0,
        [`${FindingSeverityLabel[FindingSeverity.Critical]}Color`]: "#FF6257",
        [FindingSeverityLabel[FindingSeverity.High]]: 0,
        [`${FindingSeverityLabel[FindingSeverity.High]}Color`]: "#FFB458",
        [FindingSeverityLabel[FindingSeverity.Medium]]: 0,
      };
    }
  }

  //
  return sortBy(Object.values(dates), "key") as any;
}
