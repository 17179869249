import { SnowflakeConnectPage } from "./Connect";
import { redirectToSnowflakeConnectPage } from "./connect_handler";
import { SnowflakeCoverImage } from "./CoverImage";

// -----------------------------------------------------------------------------

export { SnowflakeConnectPage };

export const SnowflakeAppConfig = {
  name: "snowflake",
  label: "Snowflake",
  coverImage: SnowflakeCoverImage,
  onConnect: redirectToSnowflakeConnectPage,
  searchTerms: ["snowflake", "snow", "database"],
  notifications: false,
};
