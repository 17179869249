import { type MutableRefObject, memo } from "react";

import styled from "styled-components";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

// -----------------------------------------------------------------------------

export enum LoadingMoreState {
  Ready,
  Loading,
  AllLoaded,
  Error,
}

// -----------------------------------------------------------------------------

export const AssetInventoryLoadMore = memo(
  ({ containerRef, triggerLoad, state }: Props) => {
    return (
      <Container ref={containerRef}>
        <Content>{renderMessage(state, triggerLoad)}</Content>
      </Container>
    );
  }
);

// -----------------------------------------------------------------------------

function renderMessage(state: LoadingMoreState, triggerLoad: Function) {
  switch (state) {
    case LoadingMoreState.Loading:
      return <CircularProgress size={48} />;

    case LoadingMoreState.AllLoaded:
      return <>All assets loaded!</>;

    case LoadingMoreState.Error:
      return (
        <>
          <Box>There was an error loading assets...</Box>
          <Button onClick={() => triggerLoad(true)}>Try again</Button>
        </>
      );

    default:
      return (
        <Button onClick={() => triggerLoad(false)}>Load more assets</Button>
      );
  }
}

// -----------------------------------------------------------------------------

const Container = styled(Box)`
  align-items: center;
  background: ${(props) => props.theme.overviewListItem.border};
  border-top: thin ${(props) => props.theme.overviewListItem.border} solid;
  display: flex;
  height: 128px;
`;

const Content = styled(Box)`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  width: 100%;
`;

// -----------------------------------------------------------------------------

interface Props {
  containerRef: MutableRefObject<HTMLDivElement | undefined>;
  state: LoadingMoreState;
  triggerLoad: Function;
}
