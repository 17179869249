import { Fragment, memo, useState } from "react";

import { ReturnIf } from "babel-plugin-transform-functional-return";
import styled from "styled-components";

import LoadingButton from "@mui/lab/LoadingButton";
import Collapse from "@mui/material/Collapse";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

import { type Finding, type ServerFindingWithType } from "data/finding";
import { type ResponseError } from "data/response_error";
import { type FOTS } from "data/typescript";

import { previewFindingIgnoreRule } from "model/finding";

import { asyncOrSwimWithSentry } from "utility/async_or_swim_sentry";
import { hasMoreThan, hasSome } from "utility/has";
import { pluralizeCustom } from "utility/pluralize";

import { FakeModalDropdown } from "./FakeModalDropdown";

// -----------------------------------------------------------------------------

export const IgnoreRegexInput = memo(({ value, onChange, findings }: Props) => {
  const [localValue, setLocalValue] = useState(value);
  const [previewFindings, setPreviewFindings] = useState<Finding[]>([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [canPreview, setCanPreview] = useState(false);

  //
  ReturnIf(
    hasMoreThan(1, findings),
    <>You can only use regular expression with a single finding.</>
  );

  //
  function onPreview() {
    setLoading(true);
    asyncOrSwimWithSentry(
      async () => {
        const responsePayload = await previewFindingIgnoreRule<{
          results: Finding[];
        }>(findings[0].uuid, {
          use_asset_re: true,
          asset_re: localValue,
        });

        //
        const newPreviewFindings = responsePayload?.results ?? [];
        setPreviewFindings(newPreviewFindings);
        setErrorMessage("");
        setLoading(false);

        if (hasSome(newPreviewFindings)) {
          setErrorMessage("");
          onChange(localValue);
        } else {
          setErrorMessage("The regular expression doesn't match any findings");
          onChange("");
        }
      },
      "Failed to preview ignore rule",
      (error) => {
        console.error(error);
        setLoading(false);
        setPreviewFindings([]);

        switch ((error as ResponseError)?.status) {
          case 409:
            setErrorMessage(
              "The regular expression doesn't match the finding asset name"
            );
            break;

          default:
            setErrorMessage("There was an error generating the preview");
            break;
        }
      }
    );
  }

  function updateRegex(regexString: string) {
    setLocalValue(regexString.trimStart());
    setPreviewFindings([]);
    setErrorMessage("");
    setCanPreview(false);
    onChange("");

    //
    switch (true) {
      case regexString.trim() === "":
        break;

      case regexString[0] === "/" ||
        regexString[regexString.length - 1] === "/":
        setErrorMessage("The surrounding slashes are not required");
        return;

      case !getRegexTester(regexString).test(findings[0].asset_name):
        setErrorMessage("The regular expression doesn't match the asset name");
        return;

      default:
        setCanPreview(true);
        break;
    }
  }

  //
  return (
    <>
      <TextField
        fullWidth
        required
        label="Asset Name (Regular Expression)"
        variant="outlined"
        error={errorMessage !== ""}
        value={localValue}
        onChange={(e: FOTS) => updateRegex(e?.target?.value)}
        helperText={errorMessage}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <LoadingButton
                size="small"
                variant="text"
                loading={loading}
                disabled={loading || !canPreview}
                onClick={onPreview}
              >
                Preview
              </LoadingButton>
            </InputAdornment>
          ),
        }}
      />
      <Collapse in={previewFindings.length > 0} mountOnEnter unmountOnExit>
        <FakeModalDropdown
          label={pluralizeCustom(
            previewFindings.length,
            "1 finding found",
            `${previewFindings.length} findings found`
          )}
        >
          {previewFindings.map((finding, i) => (
            <Fragment key={i}>
              <SelectableStrong>{finding.asset}</SelectableStrong> &mdash;{" "}
              <Selectable>{finding.name}</Selectable>
            </Fragment>
          ))}
        </FakeModalDropdown>
      </Collapse>
    </>
  );
});

// -----------------------------------------------------------------------------

function getRegexTester(regexString: string): RegExp {
  try {
    return new RegExp(regexString, "i");
  } catch (error) {
    const FakeRegExp: FOTS = {
      test: (_str: string) => false,
    };

    //
    return FakeRegExp;
  }
}

// -----------------------------------------------------------------------------

const Selectable = styled.span`
  user-select: all;
`;

const SelectableStrong = styled(Selectable)`
  font-weight: bold;
`;

// -----------------------------------------------------------------------------

interface Props {
  value: string;
  onChange: (regex: string) => void;
  findings: ServerFindingWithType[];
}
