import { memo } from "react";

import styled from "styled-components";

import Box from "@mui/material/Box";

import { formatNumberWithSeparator } from "utility/number";

// -----------------------------------------------------------------------------

export const AssetInventoryStatusBar = memo(
  ({ total = 0, selected = 0 }: Props) => {
    return (
      <Container>
        <Information>
          Showing {formatNumberWithSeparator(selected)} of{" "}
          {formatNumberWithSeparator(total)} Assets
        </Information>
      </Container>
    );
  }
);

// -----------------------------------------------------------------------------

const gutter = "8px clamp(8px, calc(0.5rem + ((1vw - 3.75px) * 1.9394)), 24px)";

const Container = styled(Box)`
  display: grid;
  font-size: 0.9rem;
  gap: ${gutter};
  grid-template-columns: 1fr auto auto;
  max-width: 1142px;
`;

const Information = styled(Box)`
  align-items: center;
  display: flex;
  overflow: hidden;
  padding: ${gutter};
  padding-right: 0;
  white-space: nowrap;
`;

// -----------------------------------------------------------------------------

interface Props {
  total: number;
  selected: number;
}
