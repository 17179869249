import { memo, useEffect, useState } from "react";

import { Box } from "@mui/system";

import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";

import InfoOutlined from "@mui/icons-material/InfoOutlined";

import { type Finding, FindingSeverity } from "data/finding";

import { SubmitState } from "stores/lib";

import { PieDisplay } from "Component/Page/Analytics/Other/PieDisplay";

import { getPieData } from "../lib/get_pie_data";

// -----------------------------------------------------------------------------

const IconSX = { fontSize: 14, color: "icons.main" };

// -----------------------------------------------------------------------------

export const Charts = memo(({ findings, loadingState }: Props) => {
  const [pieDataCritical, setPieDataCritical] = useState(getPieData([]));
  const [pieDataHigh, setPieDataHigh] = useState(getPieData([]));

  const isLoading =
    loadingState === SubmitState.STARTED ||
    loadingState === SubmitState.STARTED_AGAIN;

  //
  useEffect(() => {
    switch (loadingState) {
      case SubmitState.SUCCESS: {
        const criticalMetrics = findings.filter(
          (finding: Finding) => finding.severity === FindingSeverity.Critical
        );
        const highMetrics = findings.filter(
          (finding: Finding) => finding.severity === FindingSeverity.High
        );

        setPieDataCritical(getPieData(criticalMetrics));
        setPieDataHigh(getPieData(highMetrics));
        break;
      }

      case SubmitState.ERROR: {
        setPieDataCritical([]);
        setPieDataHigh([]);
        break;
      }

      default:
        break;
    }
  }, [loadingState, findings]);

  //
  return (
    <>
      <Grid sx={{ display: "grid", gap: "12px", minWidth: "500px" }}>
        <PieDisplay
          title={
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <Chip color="error" label="Critical" size="small" />
              <span>findings by source</span>
              <Tooltip title="Hover over each segment to view data">
                <InfoOutlined sx={IconSX} />
              </Tooltip>
            </Box>
          }
          data={pieDataCritical ?? []}
          loading={isLoading}
        />
        <PieDisplay
          title={
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <Chip color="warning" label="High" size="small" />
              <span>findings by source</span>
              <Tooltip title="Hover over each segment to view data">
                <InfoOutlined sx={IconSX} />
              </Tooltip>
            </Box>
          }
          data={pieDataHigh ?? []}
          loading={isLoading}
        />
      </Grid>
    </>
  );
});

// -----------------------------------------------------------------------------

interface Props {
  findings: Finding[];
  loadingState: SubmitState | undefined;
}
