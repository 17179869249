import { memo, useState, useEffect, useCallback } from "react";

import {
  DataGridPremium,
  type GridColumns,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
} from "@mui/x-data-grid-premium";
import { ReturnIf } from "babel-plugin-transform-functional-return";
import styled from "styled-components";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { type FOTS } from "data/typescript";

import { useIgnoreStore } from "stores/IgnoreStore";
import { ActionState } from "stores/lib";

import {
  generateLoadingColumns,
  generateLoadingRows,
} from "Component/Table/Loading";

import {
  RuleManagementTableColumns,
  type TableRule,
  getTableData,
} from "./data";

// -----------------------------------------------------------------------------

export const RuleTable = memo(({ searchTerm }: Props) => {
  const [ignoreStore, ignoreActions] = useIgnoreStore();

  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<TableRule[]>([]);

  //
  useEffect(() => {
    ignoreActions.listIgnoreRules();
  }, [ignoreActions]);

  useEffect(() => {
    ReturnIf(ignoreStore.listRulesState !== ActionState.COMPLETED);
    setLoading(false);

    const rules = ignoreStore.ignoreRules.map((rule) => getTableData(rule));
    const treatedFilterText = searchTerm.trim();
    const filteredRules = treatedFilterText
      ? rules.filter((rule) =>
          Object.entries(rule).some(([k, v]) =>
            k === "created_at"
              ? false
              : v.toLowerCase().includes(treatedFilterText.toLowerCase())
          )
        )
      : rules;

    setRows(filteredRules);
  }, [ignoreStore, ignoreActions, searchTerm]);

  //
  const getDetailPanelContent = useCallback(
    ({ row }: { row: TableRule }) =>
      row.ignore_reason ? (
        <Box sx={{ ml: 7.5, backgroundColor: "primary.background" }}>
          <Typography variant="body1" sx={{ fontSize: "14px", mt: 2 }}>
            Ignore reason:
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px", mb: 2 }}>
            {row.ignore_reason}
          </Typography>
        </Box>
      ) : null,
    []
  );

  const displayColumns: GridColumns = loading
    ? generateLoadingColumns(RuleManagementTableColumns)
    : RuleManagementTableColumns;
  const displayRows: TableRule[] = loading
    ? (generateLoadingRows(10, "uuid") as FOTS)
    : rows;

  //
  return (
    <Paper
      elevation={1}
      sx={{
        p: 0,
        width: "100%",
        height: "100%",
        minHeight: 512,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CustomGrid
        localeText={{
          noRowsLabel: "No Rules Configured",
        }}
        getRowId={(row: FOTS) => row.uuid}
        columns={displayColumns}
        rows={displayRows}
        loading={loading}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        pagination
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={() => "auto"}
        disableSelectionOnClick
        // Workaround for console warning
        columnTypes={{
          [GRID_DETAIL_PANEL_TOGGLE_COL_DEF.type as string]:
            GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        }}
        sx={{
          border: 0,
          "& .MuiDataGrid-cell": {
            wordBreak: "break-all",

            "&:focus": {
              outline: "none",
            },
          },

          "& .MuiDataGrid-columnHeader": {
            "&:focus": {
              outline: "none",
            },
          },

          "& .MuiDataGrid-columnHeaderTitleContainer": {
            padding: 0,
          },

          "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator":
            {
              display: "none",
            },
        }}
      />
    </Paper>
  );
});

// -----------------------------------------------------------------------------

const CustomGrid = styled(DataGridPremium<TableRule>)`
  .MuiDataGrid-overlay {
    font-size: 2rem;
  }
`;

// -----------------------------------------------------------------------------

interface Props {
  searchTerm: string;
}
