import { memo, useState, type ReactNode } from "react";

import { ReturnIf } from "babel-plugin-transform-functional-return";
import useSWR from "swr";

import Typography from "@mui/material/Typography";

import { useConnectionStore } from "stores/ConnectionStore";
import { useEncryptionStore } from "stores/EncryptionStore";
import { useFlairStore } from "stores/FlairStore";
import { ProvisionState, type SecretState, swrFetcher } from "stores/lib";

import { asyncOrSwimWithSentry } from "utility/async_or_swim_sentry";
import { captureSentryException } from "utility/capture_sentry_exception";
import { notifyError, notifySuccess } from "utility/notify";

import { usePageTitle } from "effect/use_page_title";

import { Page } from "Component/Page";
import { PageContent } from "Component/PageContent";
import { PageHeader } from "Component/PageHeader";
import { PageHeaderBackButton } from "Component/PageHeaderBackButton";
import { PageTitle } from "Component/PageTitle";
import { ProvisioningErrors } from "Component/ProvisioningErrors";
import { ServiceConnectionInstructionPhase } from "Component/ServiceConnectionInstructionPhase";
import { ServiceConnectionPhases } from "Component/ServiceConnectionPhases";

import { OktaConnectPageError as Error } from "./Error";
import { OktaConnectPageCompletedPhase as CompletedPhase } from "./Phase/Completed";
import { OktaConnectPageConfiguringPhase as ConfiguringPhase } from "./Phase/Configuring";
import { OktaConfigurePageRecreatingApplicationPhase as RecreatingApplicationPhase } from "./Phase/RecreatingApplication";
import { OktaConfigurePageVerifyingAPITokenPhase as VerifyingAPITokenPhase } from "./Phase/VerifyingAPIToken";
import { OktaConfigurePageVerifyingIntegrationPhase as VerifyingIntegrationPhase } from "./Phase/VerifyingIntegration";

// -----------------------------------------------------------------------------

enum Phase {
  EnterDetails,
  Provisioning,
  Done,
  Error,
}

enum ProvisionPhase {
  VerifyingAPIToken,
  RecreatingApplication,
  VerifyingIntegration,
  Done,
}

const ProvisionURL = "api/organization/connections/okta/provision/check";

// -----------------------------------------------------------------------------

export const OktaConnectPage = memo(() => {
  usePageTitle("Environment → Sources → Okta → Connect");

  //
  const [connectionStore, connectionActions] = useConnectionStore();
  const [, encryptionActions] = useEncryptionStore();
  const [, flairActions] = useFlairStore();

  const [phase, setPhase] = useState(Phase.EnterDetails);
  const [provisionPhase, setProvisionPhase] = useState(
    ProvisionPhase.VerifyingAPIToken
  );
  const [refreshInterval, setRefreshInterval] = useState(0);
  const [errorMessage, setErrorMessage] = useState<ReactNode | undefined>(
    undefined
  );

  const [organization, setOrganization] = useState("");
  const [apiToken, setAPIToken] = useState("");
  const [mode, setMode] = useState<"readwrite" | "readonly">("readwrite");

  const [oktaConnectionID, setConnectionID] = useState("");

  //
  useSWR<ProvisionStateResponse>(
    ProvisionURL,
    phase === Phase.Provisioning && provisionPhase !== ProvisionPhase.Done
      ? async (url) =>
          await swrFetcher(url, {
            method: "POST",
            body: JSON.stringify({
              org_url: organization,
              connection_uuid: oktaConnectionID,
              ro: mode === "readonly",
            }),
          })
      : null,
    {
      refreshInterval,
      onError: (error) => {
        captureSentryException(error, "Failed to validate Okta provisioning");
        setRefreshInterval(0);
        setPhase(Phase.Error);
        setErrorMessage(<ProvisioningErrors data={error} />);
        notifyError("There was an error connecting to Okta");
      },

      onSuccess: (data) => {
        switch (data.state) {
          case ProvisionState.QUEUED:
            setProvisionPhase(ProvisionPhase.RecreatingApplication);
            break;

          case ProvisionState.RUNNING:
            setProvisionPhase(ProvisionPhase.VerifyingIntegration);
            break;

          case ProvisionState.COMPLETED:
            setRefreshInterval(0);
            setProvisionPhase(ProvisionPhase.Done);
            notifySuccess("Successfully connected to Okta");

            // celebration on first connection
            if (connectionStore.connections.size < 1) {
              flairActions.startCelebration();
            }
            break;

          case ProvisionState.ERROR:
            setRefreshInterval(0);
            setPhase(Phase.Error);
            setErrorMessage(<ProvisioningErrors data={data} />);
            notifyError("There was an error connecting to Okta");
            break;

          default:
            setRefreshInterval(0);
            setPhase(Phase.Error);
            setErrorMessage(
              "There was an error configuring your Okta connection"
            );
            notifyError("There was an error connecting to Okta");
            break;
        }
      },
    }
  );

  //
  function connectToOkta() {
    asyncOrSwimWithSentry(
      async () => {
        setProvisionPhase(ProvisionPhase.VerifyingAPIToken);
        await connectionActions.createOktaConnection({
          ...(await encryptionActions.getPublicKey()),
          data: {
            org_url: stripAdmin(organization),
            token: apiToken,
          },
          onSuccess: (data: any) => {
            setConnectionID(data.connection_uuid);
            setRefreshInterval(2000);
            setPhase(Phase.Provisioning);
          },
        });
      },
      "Failed to create Okta connection",
      (error) => {
        setRefreshInterval(0);
        setErrorMessage(error?.message ?? "There was an error");
        setPhase(Phase.Error);
        notifyError("There was an error connecting to Okta");
      }
    );
  }

  function retryConnection() {
    setErrorMessage(undefined);
    setProvisionPhase(ProvisionPhase.VerifyingAPIToken);
    setPhase(Phase.EnterDetails);
  }

  //
  return (
    <Page>
      <PageHeader>
        <PageHeaderBackButton to="/environment/sources">
          Back to Sources
        </PageHeaderBackButton>
        <PageTitle>Okta Connection</PageTitle>
      </PageHeader>

      <PageContent>
        <ServiceConnectionPhases>
          <ServiceConnectionInstructionPhase>
            <Typography variant="body1">
              Connect your ThreatKey account with Okta in a just a few easy
              steps. Once connected, you can see all of your results on the
              Findings page.
            </Typography>
            <Typography variant="body1">
              Once connected, ThreatKey analyzes your Okta instance for
              potential misconfigurations and insecure settings. ThreatKey will
              not make any changes to your Okta instance. ThreatKey will only be
              able to access the data it needs to monitor your Okta instance.
            </Typography>
          </ServiceConnectionInstructionPhase>
          <ConfiguringPhase
            disabled={phase !== Phase.EnterDetails}
            organization={organization}
            apiToken={apiToken}
            mode={mode}
            onChangeOrganization={setOrganization}
            onChangeApiToken={setAPIToken}
            onChangeMode={setMode}
            onNext={() => {
              ReturnIf(!organization || !apiToken);
              connectToOkta();
            }}
          />

          {phase >= Phase.Provisioning ? (
            <>
              <VerifyingAPITokenPhase
                disabled={provisionPhase !== ProvisionPhase.VerifyingAPIToken}
                organization={organization}
                progressIcon={
                  provisionPhase === ProvisionPhase.VerifyingAPIToken &&
                  phase !== Phase.Error
                }
                successIcon={provisionPhase > ProvisionPhase.VerifyingAPIToken}
                errorIcon={
                  provisionPhase === ProvisionPhase.VerifyingAPIToken &&
                  phase === Phase.Error
                }
              />

              <Error
                show={
                  provisionPhase === ProvisionPhase.VerifyingAPIToken &&
                  phase === Phase.Error
                }
                onTryAgain={retryConnection}
              >
                {errorMessage}
              </Error>

              <RecreatingApplicationPhase
                disabled={
                  provisionPhase !== ProvisionPhase.RecreatingApplication
                }
                progressIcon={
                  provisionPhase === ProvisionPhase.RecreatingApplication &&
                  phase !== Phase.Error
                }
                successIcon={
                  provisionPhase > ProvisionPhase.RecreatingApplication
                }
                errorIcon={
                  provisionPhase === ProvisionPhase.RecreatingApplication &&
                  phase === Phase.Error
                }
              />

              <Error
                show={
                  provisionPhase === ProvisionPhase.RecreatingApplication &&
                  phase === Phase.Error
                }
                onTryAgain={retryConnection}
              >
                {errorMessage}
              </Error>

              <VerifyingIntegrationPhase
                disabled={
                  provisionPhase !== ProvisionPhase.VerifyingIntegration
                }
                progressIcon={
                  provisionPhase === ProvisionPhase.VerifyingIntegration &&
                  phase !== Phase.Error
                }
                successIcon={
                  provisionPhase > ProvisionPhase.VerifyingIntegration
                }
                errorIcon={
                  provisionPhase === ProvisionPhase.VerifyingIntegration &&
                  phase === Phase.Error
                }
              />

              <Error
                show={
                  provisionPhase === ProvisionPhase.VerifyingIntegration &&
                  phase === Phase.Error
                }
                onTryAgain={retryConnection}
              >
                {errorMessage}
              </Error>

              <CompletedPhase
                disabled={provisionPhase !== ProvisionPhase.Done}
                successIcon={provisionPhase === ProvisionPhase.Done}
                onClickFn={() => {
                  connectionActions.loadConnections({ reload: true });
                }}
              />
            </>
          ) : null}
        </ServiceConnectionPhases>
      </PageContent>
    </Page>
  );
});

// -----------------------------------------------------------------------------

function stripAdmin(data: string): string {
  return data.replace("-admin.okta.com", ".okta.com").replace(/\/$/, "");
}

// -----------------------------------------------------------------------------

interface ProvisionStateResponse {
  state: ProvisionState;
  secret_state?: SecretState;
  secret_details?: string;
  details: any;
}
