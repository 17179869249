import { type Finding, SourceLabel, SourceToColor } from "data/finding";

// -----------------------------------------------------------------------------

export function getPieData(findings: Finding[] = []): PieDataEntry[] {
  let foundIdx: number;

  // Group all findings by source and keep track of count per source
  let pieData: PieDataEntry[] =
    findings?.reduce<PieDataEntry[]>((accu, curr) => {
      if (
        accu.some((value, resIdx) => {
          // present in output array?
          foundIdx = resIdx;
          return value.source === curr.source;
        })
      ) {
        // already present, increment value
        accu[foundIdx].value++;
      } else {
        // not yet present, push the element with a count of one
        accu.push({
          id: SourceLabel[curr.source],
          label: SourceLabel[curr.source],
          value: 1,
          source: curr.source,
        });
      }
      return accu;
    }, []) ?? [];

  if (pieData.length > 6) {
    const above = [];
    const below: PieDataEntry[] = [];

    for (const item of pieData) {
      if (item.value <= 10) {
        below.push(item);
        continue;
      }
      above.push(item);
    }

    if (below.length > 1) {
      let otherCount = 0;
      for (const v of below) {
        otherCount += v.value;
      }

      above.push({
        id: "Other",
        label: "Other",
        value: otherCount,
        source: -1,
      });

      pieData = above;
    }
  }

  //
  pieData.map(addColor);

  //
  return pieData;
}

// -----------------------------------------------------------------------------

function addColor(item: PieDataEntry): void {
  item.color = SourceToColor[item.source];
}

// -----------------------------------------------------------------------------

interface PieDataEntry {
  id: string;
  label: string;
  value: number;
  source: number;
  color?: string;
}
