import { memo } from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";

// -----------------------------------------------------------------------------

export const DataDisplay = memo(
  ({
    title,
    positiveChangeIsGood = false,
    count = 0,
    change = 0,
    changePercentage = 0,
    loading = false,
  }: Props) => {
    const ChangeIcon =
      change === 0
        ? HorizontalRuleIcon
        : change < 0
          ? ArrowDownwardIcon
          : ArrowUpwardIcon;
    const changeColor =
      change === 0
        ? "primary"
        : change > 0
          ? positiveChangeIsGood
            ? "success"
            : "error"
          : positiveChangeIsGood
            ? "error"
            : "success";

    //
    return (
      <Box
        sx={{
          flexBasis: "20%",
          flexGrow: 1,
          minWidth: 128,
          pr: 2,
          pb: 2,
        }}
      >
        <Card>
          <CardContent>
            <Box sx={{ position: "relative" }}>
              <Typography
                variant="overline"
                sx={{
                  whiteSpace: "pre",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  position: "absolute",
                  left: 0,
                  right: 0,
                }}
              >
                {title}
              </Typography>
              &nbsp;
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h5" fontWeight="bold">
                  {count || "0"}
                </Typography>
                {loading ? (
                  <>
                    &nbsp;
                    <CircularProgress size={20} />
                  </>
                ) : null}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ChangeIcon color={changeColor} sx={{ mr: 0.25 }} />
                <Typography variant="overline">
                  {(changePercentage || 0) * 100}% ({change})
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    );
  }
);

// -----------------------------------------------------------------------------

interface Props {
  title: string;
  positiveChangeIsGood?: boolean;
  count: number;
  change: number;
  changePercentage: number;
  loading: boolean;
}
