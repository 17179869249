import { type Key, memo, useEffect, useRef, useState } from "react";

import styled from "styled-components";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import { type FOTS } from "data/typescript";

import { asyncOrSwimWithSentry } from "utility/async_or_swim_sentry";
import { authenticatedGetFetch } from "utility/fetch/authenticated";

import { Render } from "Component/Render";
import { ScrollPane } from "Component/ScrollPane";

import { AssetInventoryAsset as Asset } from "./Asset";
import { AssetInventoryFilterBar as FilterBar } from "./FilterBar";
import {
  LoadingMoreState,
  AssetInventoryLoadMore as LoadMore,
} from "./LoadMore";
import { AssetInventoryStatusBar as StatusBar } from "./StatusBar";

// -----------------------------------------------------------------------------

export const AssetInventoryPage = memo(() => {
  const loadMoreRef = useRef<HTMLDivElement>();

  const { loading, error, data, loadMore, loadFilter } = useAssetData();

  const [selectedFilter, setSelectedFilter] = useState("");

  // Function to handle filter change from dropdown
  const handleFilterChange = (filterType: string) => {
    setSelectedFilter(filterType);
    loadFilter(filterType);
  };

  // Function to handle loading more results or trying the load again
  const handleLoadMore = (reset: boolean) => {
    if (reset) {
      setSelectedFilter("");
    }
    loadMore(reset);
  };

  //
  function loadMoreState() {
    switch (true) {
      case loading:
        return LoadingMoreState.Loading;

      case error !== undefined:
        return LoadingMoreState.Error;

      case data.assets?.length >= data.total:
        return LoadingMoreState.AllLoaded;

      default:
        return LoadingMoreState.Ready;
    }
  }

  //
  return (
    <FullSize>
      <HeaderContainer>
        <Header>
          <Title>Asset Inventory</Title>
        </Header>
      </HeaderContainer>
      <FilterBar filter={selectedFilter} onChange={handleFilterChange} />
      <ScrollPane onScroll={() => {}}>
        <Render if={loading}>
          <Loading>
            <CircularProgress />
          </Loading>
        </Render>

        <AssetContainer>
          {data.assets
            ? data.assets.map(
                (
                  item: {
                    fields: { created_at: string; updated_at: string };
                    id: string[];
                    type: string;
                  },
                  index: Key | null | undefined
                ) => {
                  return (
                    <Asset
                      key={index}
                      data={item}
                      triggerLoad={handleFilterChange}
                    ></Asset>
                  );
                }
              )
            : null}

          <LoadMore
            containerRef={loadMoreRef}
            triggerLoad={handleLoadMore}
            state={loadMoreState()}
          />
        </AssetContainer>
      </ScrollPane>
      <StatusBarContainer>
        <StatusBar
          total={data?.total ?? 0}
          selected={data?.assets?.length ?? 0}
        />
      </StatusBarContainer>
    </FullSize>
  );
});

// -----------------------------------------------------------------------------

function useAssetData() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<FOTS>({});
  const [initialTotal, setInitialTotal] = useState<number | null>(null);
  const [error, setError] = useState<Error>(undefined as FOTS);
  const [cursor, setCursor] = useState("");
  const [filter, setFilter] = useState<Filter>(undefined as FOTS);

  //
  useEffect(() => {
    setLoading(true);
    asyncOrSwimWithSentry(
      async () => {
        const responsePayload = await authenticatedGetFetch(
          `api/v1/assets?cursor=${cursor}&filter=${JSON.stringify(
            filter ?? {}
          )}`
        );

        setError(undefined as FOTS);

        // Update the total only if it hasn't been set initially
        if (initialTotal === null) {
          setInitialTotal(parseInt(responsePayload?.total ?? "0", 10));
        }

        setData({
          assets: [...(data?.assets ?? []), ...responsePayload.assets],
          total: initialTotal ?? parseInt(responsePayload?.total ?? "0", 10),
          nextCursor: responsePayload.cursor,
        });
        setLoading(false);
      },
      "Could not load asset data",
      (error) => {
        // Get the real error, right now it is passing "Service Unavailable"
        // instead of the real error:
        // { code: "unavailable"
        //   msg: "Asset Inventory temporarily unavailable" }
        setError(error);
        setLoading(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursor, filter]);

  //
  return {
    loading,
    error,
    data,
    filter,
    loadMore: (reset: boolean) => {
      if (reset) {
        setData({});
        setCursor("");
        setFilter(undefined as FOTS);
        return;
      }
      setCursor(data.nextCursor);
    },
    loadFilter: (type?: string) => {
      setData({});
      setCursor("");
      if (type) {
        setFilter({ type: { in: [type] } });
      } else {
        setFilter(undefined as FOTS); // Explicitly reset the filter state to undefined
      }
    },
  };
}

// -----------------------------------------------------------------------------

const FullSize = styled(Box)`
  display: grid;
  flex-grow: 1;
  grid-template-rows: min-content auto 1fr min-content;
`;

const HeaderContainer = styled(Box)`
  background: ${(props) => props.theme.primary.background};
  border-bottom: thin ${(props) => props.theme.primary.border} solid;
`;

const Header = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: clamp(8px, calc(0.5rem + ((1vw - 3.75px) * 0.9697)), 16px);
  grid-column: 1 / span 2;
  max-width: 1142px;
  min-height: 0vw;
  padding: clamp(8px, calc(0.5rem + ((1vw - 3.75px) * 1.9394)), 24px);
  padding-bottom: clamp(8px, 0.5rem + (1vw - 3.75px) * 0.9697, 16px);
`;

const Title = styled(Typography).attrs(() => ({ variant: "h5" }))`
  font-size: clamp(24px, calc(1.5rem + ((1vw - 3.75px) * 1.2121)), 34px);
  font-weight: bold;
  min-height: 0vw;

  span {
    display: inline-block;
  }
`;

const StatusBarContainer = styled(Box)`
  background: ${(props) => props.theme.primary.background};
  border-top: thin ${(props) => props.theme.primary.border} solid;
`;

const Loading = styled.div`
  display: flex;
  max-width: 1142px;
`;

const AssetContainer = styled.div`
  background: ${(props) => props.theme.primary.background};
`;

// -----------------------------------------------------------------------------

interface Filter {
  type: {
    in: string[];
  };
}
