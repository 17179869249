import { RemoveLoader } from "./RemoveLoader";

// -----------------------------------------------------------------------------

export function GlobalErrorMessage() {
  return (
    <>
      {/* God help us if the error came from <RemoveLoader /> */}
      <RemoveLoader />
      <div
        style={{
          alignItems: "center",
          background: "#643AD1",
          bottom: 0,
          color: "#fff",
          display: "flex",
          fontFamily: "Arial, Helvetica, sans-serif",
          left: 0,
          justifyContent: "center",
          position: "fixed",
          right: 0,
          top: 0,
        }}
      >
        <div
          style={{
            padding: "8p",
            fontSize: "1.25rem",
            textAlign: "center",
          }}
        >
          <h1
            style={{
              fontSize: "1.5rem",
            }}
          >
            An error occurred
          </h1>
          <p style={{ maxWidth: "300px", lineHeight: "1.5rem" }}>
            Please refresh your browser. We have been informed of this error,
            but if the issue persists, contact
            <br />
            <a href="mailto:support@threatkey.com" style={{ color: "#fff" }}>
              support@threatkey.com
            </a>
            .
          </p>
        </div>
      </div>
    </>
  );
}
