import { memo, useState } from "react";

import { Box } from "@mui/system";
import { type ScatterPlotRawSerie } from "@nivo/scatterplot";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import CloseFullscreenRounded from "@mui/icons-material/CloseFullscreenRounded";
import FileDownloadRounded from "@mui/icons-material/FileDownloadRounded";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import OpenInFullRounded from "@mui/icons-material/OpenInFullRounded";

import { exportChartAsImage } from "utility/save_chart_as_image";

import { type StaleFindingDatum } from "../lib/get_scatter_data";

import { StaleScatterPlotDisplay } from "./StaleScatterPlotDisplay";
import {
  StaleScatterPlotModal,
  SCATTER_PLOT_ID,
} from "./StaleScatterPlotModal";

// -----------------------------------------------------------------------------

const IconSX = { fontSize: 14, color: "icons.main" };
const IconLargeSX = { fontSize: 18, color: "primary.main" };

// -----------------------------------------------------------------------------

export const StaleFindingsCharts = memo(
  ({ scatterData, isLoading, median }: Props) => {
    const [showScatterModal, setShowScatterModal] = useState(false);

    //
    return (
      <>
        {showScatterModal ? (
          <StaleScatterPlotModal
            title={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  <span>Stale Findings Scatterplot - Expanded View</span>
                  <Tooltip title="Hover over individual dots to view finding details">
                    <InfoOutlined sx={IconSX} />
                  </Tooltip>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  <Button
                    onClick={() => exportChartAsImage(SCATTER_PLOT_ID)}
                    startIcon={<FileDownloadRounded sx={IconLargeSX} />}
                  >
                    Download
                  </Button>
                  <IconButton onClick={() => setShowScatterModal(false)}>
                    <CloseFullscreenRounded sx={IconSX} />
                  </IconButton>
                </Box>
              </Box>
            }
            scatterData={scatterData}
            onClose={() => setShowScatterModal(false)}
          />
        ) : null}
        <Grid sx={{ display: "grid", gap: "12px", width: "100%" }}>
          <StaleScatterPlotDisplay
            title={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  <span>Stale Findings Scatterplot</span>
                  <Tooltip title="Hover over individual dots to view finding details">
                    <InfoOutlined sx={IconSX} />
                  </Tooltip>
                </Box>
                <IconButton onClick={() => setShowScatterModal(true)}>
                  <OpenInFullRounded sx={IconSX} />
                </IconButton>
              </Box>
            }
            data={scatterData}
            loading={isLoading}
            median={median}
          />
        </Grid>
      </>
    );
  }
);

// -----------------------------------------------------------------------------

interface Props {
  scatterData: Array<ScatterPlotRawSerie<StaleFindingDatum>>;
  median: number | undefined;
  isLoading: boolean;
}
