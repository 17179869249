import {
  type Scope,
  captureException,
  type SeverityLevel,
} from "@sentry/react";
import { type CaptureContext } from "@sentry/types";
import { ReturnIf } from "babel-plugin-transform-functional-return";

import { type ResponseError } from "data/response_error";

// -----------------------------------------------------------------------------

export function captureSentryExceptionUnless(
  error: unknown,
  scope: string,
  acceptableStatusCodes: number[]
): void;

export function captureSentryExceptionUnless(
  error: unknown,
  scope: string,
  isRealError: (error: unknown) => boolean
): void;

export function captureSentryExceptionUnless(
  error: unknown,
  scope: string,
  isRealError: () => boolean
): void;

export function captureSentryExceptionUnless(
  error: unknown,
  scopeFunction: (scope: Scope) => Scope,
  acceptableStatusCodes: number[]
): void;

export function captureSentryExceptionUnless(
  error: unknown,
  scopeFunction: (scope: Scope) => Scope,
  isRealError: (error: unknown) => boolean
): void;

export function captureSentryExceptionUnless(
  error: unknown,
  scopeFunction: (scope: Scope) => Scope,
  isRealError: () => boolean
): void;

export function captureSentryExceptionUnless(
  error: unknown,
  scopeOrScopeFunction: string | ((scope: Scope) => Scope),
  isRealErrorOrAcceptableStatusCodes?: number[] | ((error: unknown) => boolean)
): void {
  // (1) error is ResponseError
  // (2) isRealError is an array of numbers
  const statusCode = (error as ResponseError)?.status;
  const acceptableStatusCodes = isRealErrorOrAcceptableStatusCodes as number[];
  ReturnIf(
    Array.isArray(acceptableStatusCodes) &&
      !acceptableStatusCodes.includes(statusCode)
  );

  // (1) isRealError is a function
  const isRealError = isRealErrorOrAcceptableStatusCodes as (
    error: unknown
  ) => boolean;
  ReturnIf(typeof isRealError === "function" && !isRealError(error));

  //
  const scopeFunction = (
    typeof scopeOrScopeFunction === "function"
      ? scopeOrScopeFunction
      : (scope: Scope, level: SeverityLevel) => {
          scope.setTransactionName(scopeOrScopeFunction);
          scope.setLevel(level);

          //
          return scope;
        }
  ) as CaptureContext;

  //
  captureException(error, scopeFunction);
}
