import { memo, type PropsWithChildren } from "react";

import styled from "styled-components";

import Box from "@mui/material/Box";

// -----------------------------------------------------------------------------

export const ModalSubContent = memo(
  ({ className, children }: PropsWithChildren & { className?: string }) => {
    return <Container className={className}>{children}</Container>;
  }
);

// -----------------------------------------------------------------------------

const Container = styled(Box)`
  padding: clamp(8px, calc(0.5rem + ((1vw - 3.75px) * 1.9394)), 24px);
  padding-bottom: 0;
`;
