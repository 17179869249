import { type Service } from "utility/connections";

import { LookerConnectPage } from "./Connect";
import { redirectToLookerConnectPage } from "./connect_handler";
import { LookerCoverImage } from "./CoverImage";

// -----------------------------------------------------------------------------

export { LookerConnectPage };

export const LookerAppConfig: Service = {
  name: "looker",
  label: "Looker",
  coverImage: LookerCoverImage,
  onConnect: redirectToLookerConnectPage,
  searchTerms: ["looker"],
};
