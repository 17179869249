import { memo } from "react";

import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { ServiceConnectionPhase } from "Component/ServiceConnectionPhase";

// -----------------------------------------------------------------------------

export const SetupUserWithPolicy = memo(({ disabled }: Props) => {
  //
  return (
    <ServiceConnectionPhase disabled={disabled}>
      <Stack direction="column" spacing={1.5}>
        <Typography variant="h5">Setup IAM User</Typography>
        <Typography variant="body1">
          You will need to create the IAM User with the Policy as shown in our{" "}
          <Link
            href="https://docs.threatkey.com/docs/sources/kubernetes/#prepare-aws-iam-and-connect-it-to-eks"
            target="_blank"
            rel="noopener noreferrer"
          >
            technical documentation
          </Link>
          .
        </Typography>
      </Stack>
    </ServiceConnectionPhase>
  );
});

// -----------------------------------------------------------------------------

interface Props {
  disabled: boolean;
}
