import { memo } from "react";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useOrganizationStore } from "stores/OrganizationStore";

import { ReactMarkdown } from "Component/ReactMarkdown";
import { ServiceConnectionPhase } from "Component/ServiceConnectionPhase";

import { AmazonWebServicesEksConfig as Config } from "../../config";

// -----------------------------------------------------------------------------

export const KubernetesInstallEksCloudFormationTemplatePhase = memo(
  ({ disabled }: Props) => {
    const [organizationStore] = useOrganizationStore();

    //
    return (
      <ServiceConnectionPhase disabled={disabled}>
        <Stack direction="column" spacing={1.5}>
          <Typography variant="h5">Install IAM Role</Typography>
          <Typography variant="body1">
            The CloudFormation template creates an IAM role with permissions to
            access EKS and observe security issues. For more details on what it
            does and how we use it, you can inspect the{" "}
            <Link href="https://threatkey-assets.s3.amazonaws.com/aws/eks/provision.yml">
              CloudFormation template file
            </Link>{" "}
            and read more in our{" "}
            <Link
              href="https://docs.threatkey.com/docs/sources/kubernetes/#prepare-aws-iam-and-connect-it-to-eks"
              target="_blank"
              rel="noopener noreferrer"
            >
              technical documentation
            </Link>
            .
          </Typography>
          <Alert severity="warning">
            If{" "}
            <Link href="https://docs.threatkey.com/docs/sources/kubernetes#installation-and-setup">
              manually creating the role
            </Link>
            , use <code style={{ fontWeight: "bold" }}>ExternalId</code>:{" "}
            <Typography
              sx={{ display: "inline", userSelect: "all" }}
              component="span"
            >
              <ReactMarkdown
                params={{ inline: true }}
              >{`\`${organizationStore.uuid}\``}</ReactMarkdown>
            </Typography>
          </Alert>
          <div>
            <Button
              variant="contained"
              component="a"
              href={`https://console.aws.amazon.com/cloudformation/home#/stacks/create/review?templateURL=${
                Config.cloudFormationTemplateURL ?? ""
              }&stackName=threatkey-eks-role-${
                organizationStore.uuid
              }&param_ThreatKeyOrgId=${organizationStore.uuid}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Install Template
            </Button>
          </div>
        </Stack>
      </ServiceConnectionPhase>
    );
  }
);

// -----------------------------------------------------------------------------

interface Props {
  disabled: boolean;
}
