import { memo } from "react";

import styled from "@emotion/styled";

import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import {
  FindingSeverity,
  FindingSeverityColor,
  FindingSeverityLabel,
} from "data/finding";
import { type FOTS } from "data/typescript";

// -----------------------------------------------------------------------------

const SeverityOptions = (Object.values(FindingSeverity) as FindingSeverity[])
  .filter((k) => k > FindingSeverity.EarlyAccessInfo)
  .sort((a, b) => b - a);

// -----------------------------------------------------------------------------

export const FindingSeveritySelector = memo(({ severity, onChange }: Props) => {
  return (
    <FormControl fullWidth>
      <InputLabel>New Severity</InputLabel>
      <SeveritySelector
        label="New Severity"
        onChange={(event) => onChange(event.target.value as FindingSeverity)}
        renderValue={renderSeverityChip}
        value={severity}
      >
        {SeverityOptions.map(renderSeverityChip)}
      </SeveritySelector>
    </FormControl>
  );
});

// -----------------------------------------------------------------------------

function renderSeverityChip(severity: FindingSeverity) {
  const label = FindingSeverityLabel[severity];
  const color = FindingSeverityColor[severity];

  //
  return (
    <MenuItem key={severity} value={severity}>
      <SeverityChip key={label} label={label} color={color as FOTS} />
    </MenuItem>
  );
}

// -----------------------------------------------------------------------------

const SeveritySelector = styled(Select<FindingSeverity>)`
  .MuiMenuItem-root {
    min-height: unset;
    padding: 0;
  }
`;

const SeverityChip = styled(Chip)`
  font-weight: bold;
`;

// -----------------------------------------------------------------------------

interface Props {
  severity: FindingSeverity;
  onChange: (severity: FindingSeverity) => void;
}
