import { BreakIf } from "babel-plugin-transform-functional-return";
import isEmpty from "lodash/isEmpty";

import {
  type Finding,
  type FindingSeverity,
  type ServerFinding,
  FindingState,
} from "data/finding";
import { type IgnorePreviewData } from "data/finding_ignore_rule";

import {
  authenticatedGetFetch,
  authenticatedPatchFetch,
  authenticatedPostFetch,
} from "utility/fetch/authenticated";
import { toFinding } from "utility/finding";

// -----------------------------------------------------------------------------

export async function loadAllServerFindings(): Promise<ServerFinding[]> {
  let page = 1;
  let attemptsLeft = 100;
  const findings: ServerFinding[] = [];

  do {
    const data = await authenticatedGetFetch<{
      results: ServerFinding[];
      next: string | null;
    }>(`api/findings?page=${page}`);

    //
    findings.push(...(data.results || []));

    //
    page += 1;
    attemptsLeft -= 1;

    //
    BreakIf(data.next === null);
  } while (attemptsLeft > 0);

  //
  return findings;
}

export async function loadAllFindings(): Promise<Finding[]> {
  return (await loadAllServerFindings()).map(toFinding);
}

// -----------------------------------------------------------------------------

export async function acknowledgeFindings<T>(
  findingUuids: string[]
): Promise<T> {
  return await authenticatedPatchFetch(`api/findings/update_state`, {
    finding_uuids: findingUuids,
    state: FindingState.Acknowledged,
  });
}

export async function alterFindingsSeverity(
  findingUuids: string[],
  severity: FindingSeverity
): Promise<void> {
  for (const findingUuid of findingUuids) {
    await authenticatedPatchFetch(`api/findings/${findingUuid}`, { severity });
  }
}

export async function ignoreFindings<T>(
  findingUuids: string[],
  ignoreReason?: string
): Promise<T> {
  return await authenticatedPostFetch<T>(`api/findings/bulk_ignore`, {
    finding_uuids: findingUuids,
    ignoreReason: isEmpty(ignoreReason) ? undefined : ignoreReason,
  });
}

export async function previewFindingIgnoreRule<T>(
  findingUuid: string,
  data: IgnorePreviewData
): Promise<T> {
  return await authenticatedPostFetch<T>("api/findings/ignore/create_preview", {
    finding_uuid: findingUuid,
    ignore_type: "rule",
    ignore_reason: "",
    ...data,
  });
}

export async function updateFindingsState(
  findingUuids: string[],
  newState: FindingState
): Promise<UpdateFindingStateResponse> {
  return await authenticatedPatchFetch<UpdateFindingStateResponse>(
    `api/findings/update_state`,
    {
      finding_uuids: findingUuids,
      state: newState,
    }
  );
}

export async function unignoreFindings<T>(findingUuids: string[]): Promise<T> {
  return await authenticatedPostFetch(`api/findings/bulk_unignore`, {
    finding_uuids: findingUuids,
  });
}

// -----------------------------------------------------------------------------

interface UpdateFindingStateResponse {
  error: boolean;
  count: number;
}
