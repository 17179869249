import { ThrowIf } from "babel-plugin-transform-functional-return";

// -----------------------------------------------------------------------------

const RequiredAlgorithm = "RSA-OAEP";

// -----------------------------------------------------------------------------

export async function generateBrowserPublicKey(serverPublicKey: {
  algo: string;
  pem: string;
  prefix: string;
}) {
  ThrowIf(
    serverPublicKey.algo !== RequiredAlgorithm,
    new Error(`Unsupported encryption scheme: ${serverPublicKey.algo}`)
  );

  //
  return await crypto.subtle.importKey(
    "spki",
    string2Buffer(
      window.atob(
        serverPublicKey.pem
          .replace("-----BEGIN PUBLIC KEY-----", "")
          .replace("-----END PUBLIC KEY-----", "")
          .trim()
      )
    ),
    {
      name: RequiredAlgorithm,
      hash: "SHA-256",
    },
    true,
    ["encrypt"]
  );
}

/**
 * Encrypts a JavaScript object for use with ThreatKey's end to end encryption scheme.
 *
 * Uses RSA-OAEP or RSA-OAEP & AES-GCM-256, depending on length.
 *
 * @param data Object that can be used with `JSON.stringify()`
 * @param publicKey An RSA-4096 public key
 * @returns Encoded ciphertext that starts with `v1:` or `t1:` and colon-delimited base64.
 */
export async function encryptData(
  data: any,
  publicKey: CryptoKey
): Promise<string> {
  const plaintext = string2Buffer(JSON.stringify(data));
  // plain RSA-OAEP if it fits
  if (plaintext.byteLength <= 446) {
    const ciphertext = arrayToB64(
      await crypto.subtle.encrypt(
        { name: RequiredAlgorithm },
        publicKey,
        plaintext
      )
    );
    return `v1:${ciphertext}`;
  }
  // otherwise, use RSA-OAEP + AES-256-GCM
  // generate key
  const key = await crypto.subtle.generateKey(
    { name: "AES-GCM", length: 256 },
    true,
    ["encrypt"]
  );
  // generate initialization vector
  const iv = new Uint8Array(12); // 96 bits
  crypto.getRandomValues(iv);
  const ciphertextRaw = await crypto.subtle.encrypt(
    { name: "AES-GCM", iv },
    key,
    plaintext
  );
  // encode
  const aesParamsPlaintext = JSON.stringify({
    key: arrayToB64(await crypto.subtle.exportKey("raw", key)),
    iv: arrayToB64(iv),
  });
  const aesParamsCiphertext = arrayToB64(
    await crypto.subtle.encrypt(
      { name: RequiredAlgorithm },
      publicKey,
      string2Buffer(aesParamsPlaintext)
    )
  );
  const ciphertext = arrayToB64(ciphertextRaw);
  return `t1:${aesParamsCiphertext}:${ciphertext}`;
}

// -----------------------------------------------------------------------------

function string2Buffer(input: string): ArrayBuffer {
  const buffer = new ArrayBuffer(input.length);
  const bufferView = new Uint8Array(buffer);

  for (let i = 0; i < input.length; i++) {
    bufferView[i] = input.charCodeAt(i);
  }

  //
  return buffer;
}

function arrayToB64(buf: ArrayBuffer): string {
  return window.btoa(String.fromCharCode(...new Uint8Array(buf)));
}
