import { memo } from "react";
import { useParams } from "react-router-dom";

import Box from "@mui/material/Box";

import { useUserStore } from "stores/UserStore";

import { userCanInviteMembers } from "utility/user";

import { LinkTab } from "Component/LinkTab";
import { Page } from "Component/Page";
import { PageContent } from "Component/PageContent";
import { PageHeader } from "Component/PageHeader";
import { PageTabs } from "Component/PageTabs";
import { PageTitle } from "Component/PageTitle";

import { MembersInvite as InviteTab } from "./Invite";
import { MembersPageList as ListTab } from "./List";

// -----------------------------------------------------------------------------

export const MembersPage = memo(() => {
  const [userStore] = useUserStore();

  const { module } = useParams() as any;
  const moduleId = getModuleId(module);

  //
  return (
    <Page>
      <PageHeader hasTabs>
        <PageTitle>Members</PageTitle>

        <PageTabs value={moduleId}>
          <LinkTab label="Members" to="/members" key={0} />
          {userCanInviteMembers(userStore.user) ? (
            <LinkTab label="Invite" to="/members/invite" key={1} />
          ) : null}
        </PageTabs>
      </PageHeader>

      <PageContent>
        <Box>{renderTab(moduleId)}</Box>
      </PageContent>
    </Page>
  );
});

// -----------------------------------------------------------------------------

function renderTab(value: number) {
  switch (value) {
    case 1:
      return <InviteTab />;

    default:
      return <ListTab />;
  }
}

function getModuleId(module: string): number {
  switch (module) {
    case "invite":
      return 1;

    default:
      return 0;
  }
}
