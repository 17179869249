import { redirectToMicrosoft365OauthUrl } from "./connect_handler";
import { Microsoft365CoverImage } from "./CoverImage";

// -----------------------------------------------------------------------------

export const Microsoft365AppConfig = {
  name: "m365",
  label: "Microsoft 365",
  coverImage: Microsoft365CoverImage,
  onConnect: redirectToMicrosoft365OauthUrl,
  searchTerms: ["m365", "microsoft 365", "office 365"],
};
