import { memo, useEffect, useState } from "react";

import { Box } from "@mui/system";

import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import CloseFullscreenRounded from "@mui/icons-material/CloseFullscreenRounded";
import FileDownloadRounded from "@mui/icons-material/FileDownloadRounded";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import OpenInFullRounded from "@mui/icons-material/OpenInFullRounded";

import { type Finding, FindingSeverity } from "data/finding";

import { SubmitState } from "stores/lib";

import { exportChartAsImage } from "utility/save_chart_as_image";

import { PieDisplay } from "../../Other/PieDisplay";

import { getPieData } from "../lib/get_pie_data";
import { getScatterData } from "../lib/get_scatter_data";
import {
  IAMScatterPlotModal,
  SCATTER_PLOT_ID,
} from "../Table/IAMScatterPlotModal";

import { ScatterPlotDisplay } from "./ScatterPlotDisplay";

// -----------------------------------------------------------------------------

const IconSX = { fontSize: 14, color: "icons.main" };
const IconLargeSX = { fontSize: 18, color: "primary.main" };

// -----------------------------------------------------------------------------

export const Charts = memo(({ findings, loadingState }: Props) => {
  const [pieDataCritical, setPieDataCritical] = useState(getPieData([]));
  const [pieDataHigh, setPieDataHigh] = useState(getPieData([]));
  const [scatterData, setScatterData] = useState(getScatterData([]));
  const [showScatterModal, setShowScatterModal] = useState(false);

  const isLoading =
    loadingState === SubmitState.STARTED ||
    loadingState === SubmitState.STARTED_AGAIN;

  //
  useEffect(() => {
    switch (loadingState) {
      case SubmitState.SUCCESS: {
        const criticalMetrics = findings.filter(
          (finding: Finding) => finding.severity === FindingSeverity.Critical
        );
        const highMetrics = findings.filter(
          (finding: Finding) => finding.severity === FindingSeverity.High
        );

        setPieDataCritical(getPieData(criticalMetrics));
        setPieDataHigh(getPieData(highMetrics));
        setScatterData(getScatterData(findings));
        break;
      }

      case SubmitState.ERROR: {
        setPieDataCritical([]);
        setPieDataHigh([]);
        setScatterData([]);
        break;
      }

      default:
        break;
    }
  }, [loadingState, findings]);

  //
  return (
    <>
      {showScatterModal ? (
        <IAMScatterPlotModal
          title={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                <span>IAM Findings Scatterplot - Expanded View</span>
                <Tooltip title="Hover over individual dots to view finding details">
                  <InfoOutlined sx={IconSX} />
                </Tooltip>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                <Button
                  onClick={() => exportChartAsImage(SCATTER_PLOT_ID)}
                  startIcon={<FileDownloadRounded sx={IconLargeSX} />}
                >
                  Download
                </Button>
                <IconButton onClick={() => setShowScatterModal(false)}>
                  <CloseFullscreenRounded sx={IconSX} />
                </IconButton>
              </Box>
            </Box>
          }
          scatterData={scatterData}
          onClose={() => setShowScatterModal(false)}
        />
      ) : null}
      <Grid sx={{ display: "grid", gap: "12px", minWidth: "500px" }}>
        <ScatterPlotDisplay
          title={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span>IAM Findings Scatterplot</span>
              <IconButton onClick={() => setShowScatterModal(true)}>
                <OpenInFullRounded sx={IconSX} />
              </IconButton>
            </Box>
          }
          data={scatterData}
          loading={isLoading}
        />
        <PieDisplay
          title={
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <Chip color="error" label="Critical" size="small" />
              <span>findings by source</span>
              <Tooltip title="Hover over each segment to view data">
                <InfoOutlined sx={IconSX} />
              </Tooltip>
            </Box>
          }
          data={pieDataCritical ?? []}
          loading={isLoading}
        />
        <PieDisplay
          title={
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <Chip color="warning" label="High" size="small" />
              <span>findings by source</span>
              <Tooltip title="Hover over each segment to view data">
                <InfoOutlined sx={IconSX} />
              </Tooltip>
            </Box>
          }
          data={pieDataHigh ?? []}
          loading={isLoading}
        />
      </Grid>
    </>
  );
});

// -----------------------------------------------------------------------------

interface Props {
  findings: Finding[];
  loadingState: SubmitState | undefined;
}
