import { MicrosoftTeamsWebhookCoverImage } from "./CoverImage";

// ----------------------------------------------------------------------------- ∏

export const MicrosoftTeamsWebhookAppConfig = {
  name: "teams_webhook",
  label: "Microsoft Teams Webhooks",
  singularLabel: "Microsoft Teams Webhook",
  coverImage: MicrosoftTeamsWebhookCoverImage,
  searchTerms: [],
  url: "teams_webhook",
  connectInfo: {
    instructions: {
      example: "Microsoft Teams instance",
    },
    placeholder:
      "https://teams.webhook.office.com/webhookb2/xxxxxxxx-xxxx-Mxxx-Nxxx-xxxxxxxxxxxx/IncomingWebhook/xxxxxxxx-xxxx-Mxxx-Nxxx-xxxxxxxxxxxx",
    // Disable this check so we match the backend regex exactly.
    // eslint-disable-next-line no-useless-escape
    regex:
      /(https:\/\/[a-zA-Z0-9-.]+\.webhook\.office\.com\/webhookb2\/[a-zA-Z0-9-.]+\/IncomingWebhook\/[a-zA-Z0-9-.]+\/[a-zA-Z0-9-.]+)/i,
    path: "teams/webhook", // doesn't exist on BE?
  },
  notifications: true,
  notificationKey: "teams_webhook",
};
