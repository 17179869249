import { memo } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { ServiceConnectionPhase } from "Component/ServiceConnectionPhase";

// -----------------------------------------------------------------------------

export const AzureConnectPageSuccessPhase = memo(({ shown }: Props) => {
  const navigate = useNavigate();

  //
  function backToSources() {
    navigate("/environment/sources");
  }

  //
  return shown ? (
    <ServiceConnectionPhase disabled={false} sx={{ background: "#CFC" }}>
      <Box sx={{ mb: 1 }}>
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          You successly connected to Microsoft Azure!
        </Typography>
      </Box>
      <Button variant="contained" onClick={backToSources}>
        Back to sources
      </Button>
    </ServiceConnectionPhase>
  ) : null;
});

// -----------------------------------------------------------------------------

interface Props {
  shown: boolean;
}
