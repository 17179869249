import {
  type Action,
  type BoundActions,
  createHook,
  createStore,
} from "react-sweet-state";

import { authenticatedGetFetch } from "utility/fetch/authenticated";
import { promiser } from "utility/promiser";
import { trying } from "utility/trying";

// -----------------------------------------------------------------------------

export enum BillingState {
  Unknown,
  Error,
  Loading,
  Success,
  Restricted,
}

// -----------------------------------------------------------------------------

const initialState: BillingStoreState = Object.freeze({
  state: BillingState.Unknown,
});

// -----------------------------------------------------------------------------

const BillingStore = createStore({
  initialState,
  actions: {
    load,
    loadRestricted,
  },
});

export const useBillingStore = createHook(BillingStore);

// -----------------------------------------------------------------------------

function load(): Action<BillingStoreState> {
  return async ({ setState }) =>
    await promiser(
      "BillingStore.load",
      async () =>
        await trying(
          async () => {
            setState({ state: BillingState.Loading });
            const responsePayload = await authenticatedGetFetch(
              "api/billing/info",
              {
                csrf: true,
              }
            );
            setState({
              state: BillingState.Success,
              ...responsePayload,
              error: undefined,
            });

            return responsePayload;
          },
          `BillingStore:load failed`,
          (error: Error) =>
            setState({
              state: BillingState.Error,
              subscription: undefined,
              invoices: undefined,
              error: error?.message ?? undefined,
            })
        )
    );
}

function loadRestricted(): Action<BillingStoreState> {
  return async ({ setState }) =>
    await Promise.resolve(
      setState({
        state: BillingState.Restricted,
        error: undefined,
        subscription: undefined,
        invoices: undefined,
      })
    );
}

// -----------------------------------------------------------------------------

export interface BillingStoreState {
  state: BillingState;
  error?: string;
  subscription?: Subscription;
  invoices?: Invoice[];
}

export type BillingStoreActions = BoundActions<
  BillingStoreState,
  typeof BillingStore.actions
>;

interface Subscription {
  label: string;
  is_trial?: boolean;
  days_left?: number;
  expires?: number;
  price: number;
  interval: string;
}

export interface Invoice extends Record<string, unknown> {
  id: string;
}
