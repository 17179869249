import { memo, type PropsWithChildren } from "react";

import styled from "styled-components";

import DialogActions from "@mui/material/DialogActions";

// -----------------------------------------------------------------------------

export const ModalActions = memo(({ children }: PropsWithChildren) => {
  return <Container disableSpacing>{children}</Container>;
});

// -----------------------------------------------------------------------------

const Container = styled(DialogActions)`
  gap: clamp(8px, calc(0.5rem + ((1vw - 3.75px) * 1.9394)), 24px);
  padding: clamp(8px, calc(0.5rem + ((1vw - 3.75px) * 1.9394)), 24px);
`;
