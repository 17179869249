import { WebexWebhookCoverImage } from "./CoverImage";

// ----------------------------------------------------------------------------- ∏

export const WebexWebhookAppConfig = {
  name: "webex_webhook",
  label: "Cisco Webex Teams Webhooks",
  singularLabel: "Cisco Webex Teams Webhook",
  coverImage: WebexWebhookCoverImage,
  searchTerms: [],
  url: "webex_webhook",
  connectInfo: {
    instructions: {
      example: "Cisco Webex Teams instance",
    },
    placeholder:
      "https://webexapis.com/v1/webhooks/incoming/<incoming_webhook_url>",
    // Disable this check so we match the backend regex exactly.
    // eslint-disable-next-line no-useless-escape
    regex: /^https:\/\/[a-zA-Z0-9-.]+\/v1\/webhooks\/incoming\/[a-zA-Z0-9-]+$/,
    path: "webex/webhook",
  },
  notifications: true,
  notificationKey: "webex_webhook",
};
