import { memo } from "react";

import SvgIcon from "@mui/material/SvgIcon";

// -----------------------------------------------------------------------------

export const PingOneSAMLSSOIcon = memo((props: object) => {
  return (
    <SvgIcon viewBox="50 150 800 180" {...props} overflow="visible">
      <g
        stroke="none"
        transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
      >
        <path d="M2247 3426 c-73 -27 -117 -86 -117 -156 0 -121 112 -199 225 -155 103 39 139 171 68 255 -47 56 -115 78 -176 56z" />
        <path d="M1100 2655 l0 -695 145 0 145 0 0 254 0 253 163 6 c187 6 235 17 326 78 117 78 181 207 182 365 0 45 -5 102 -11 125 -43 157 -181 273 -355 299 -38 5 -188 10 -332 10 l-263 0 0 -695z m578 437 c52 -37 75 -93 75 -183 0 -62 -5 -85 -24 -120 -42 -75 -62 -84 -210 -88 l-129 -3 0 212 0 212 128 -4 c114 -3 130 -5 160 -26z" />
        <path d="M5164 3301 c-173 -28 -317 -119 -403 -256 -88 -139 -115 -256 -109 -463 4 -123 10 -160 31 -227 69 -210 214 -349 417 -401 84 -22 243 -22 333 0 245 58 408 255 448 541 14 101 6 247 -20 346 -58 224 -215 391 -417 443 -75 19 -212 27 -280 17z m249 -187 c164 -62 264 -230 274 -459 13 -302 -120 -507 -354 -546 -221 -36 -405 93 -469 331 -27 100 -25 274 5 370 31 101 54 144 109 202 87 93 182 131 312 124 41 -3 97 -13 123 -22z" />
        <path d="M7874 3179 c-42 -12 -100 -77 -108 -122 -15 -79 36 -161 117 -187 119 -38 240 77 208 196 -18 68 -97 126 -166 123 -11 0 -34 -5 -51 -10z m124 -45 c72 -50 68 -180 -8 -219 -60 -31 -137 -10 -165 45 -8 16 -15 50 -15 76 0 39 5 51 34 80 29 29 41 34 83 34 28 0 58 -7 71 -16z" />
        <path d="M7870 3024 c0 -76 2 -85 18 -82 11 2 17 13 17 33 1 28 5 31 34 27 5 -1 14 -15 20 -32 6 -20 17 -30 30 -30 17 0 19 4 11 31 -5 17 -14 37 -20 44 -8 9 -6 14 5 19 22 8 19 53 -4 66 -11 5 -40 10 -65 10 l-46 0 0 -86z m85 46 c8 -13 -13 -40 -31 -40 -8 0 -14 10 -14 25 0 18 5 25 19 25 11 0 23 -5 26 -10z" />
        <path d="M4440 3026 c-18 -23 -90 -55 -144 -65 -38 -7 -72 -4 -165 16 -95 20 -136 24 -226 20 -164 -6 -263 -51 -342 -155 -41 -55 -56 -108 -56 -200 1 -123 63 -215 169 -254 19 -7 34 -15 34 -18 0 -3 -21 -12 -46 -19 -26 -8 -63 -30 -85 -51 -37 -35 -39 -40 -39 -97 0 -46 5 -68 23 -94 36 -54 97 -69 317 -78 211 -9 247 -19 275 -78 28 -59 2 -118 -68 -155 -58 -31 -222 -33 -280 -4 -49 24 -71 66 -64 123 l5 43 -124 0 c-147 0 -148 -1 -142 -116 3 -61 8 -79 33 -116 66 -93 163 -139 338 -159 168 -19 353 15 454 85 55 38 108 110 124 169 6 23 9 74 7 113 -8 123 -73 211 -191 256 -41 17 -85 22 -214 27 -177 7 -201 14 -167 52 34 37 85 56 169 61 106 7 181 37 249 99 107 97 123 217 48 362 -2 4 25 7 61 7 68 0 114 14 159 50 l27 21 -56 85 c-31 46 -59 84 -63 84 -4 0 -13 -7 -20 -14z m-406 -245 c60 -34 83 -127 50 -197 -41 -86 -210 -101 -278 -25 -27 30 -34 127 -12 169 39 75 156 101 240 53z" />
        <path d="M2283 2988 l-123 -20 0 -504 0 -504 135 0 135 0 0 525 c0 414 -3 525 -12 524 -7 -1 -68 -10 -135 -21z" />
        <path d="M2675 2979 c-55 -16 -105 -31 -112 -33 -8 -4 -7 -18 5 -53 13 -40 16 -117 20 -490 l3 -443 135 0 134 0 0 363 0 363 28 21 c99 73 202 87 235 30 9 -16 13 -121 15 -399 l3 -378 130 0 130 0 -3 438 -3 439 -30 49 c-24 38 -45 56 -95 82 -63 33 -68 34 -155 30 -98 -5 -150 -23 -230 -78 -26 -18 -47 -30 -49 -28 -1 1 -10 29 -20 61 -9 31 -22 57 -29 56 -7 0 -57 -14 -112 -30z" />
        <path d="M6365 2937 c-74 -25 -123 -56 -166 -106 l-44 -50 0 72 0 72 -82 3 -83 3 0 -486 0 -485 85 0 85 0 0 258 c0 337 20 432 105 515 52 51 109 70 189 65 72 -4 106 -22 143 -77 38 -56 45 -113 50 -443 l5 -318 84 0 84 0 0 323 c0 331 -6 403 -40 482 -27 64 -92 129 -153 155 -64 28 -202 37 -262 17z" />
        <path d="M7235 2936 c-203 -49 -328 -235 -327 -486 0 -167 42 -288 130 -382 123 -131 363 -171 585 -97 l67 23 0 89 c0 49 -3 87 -7 85 -133 -67 -223 -93 -304 -85 -147 13 -262 122 -284 270 l-7 47 336 0 336 0 0 65 c0 140 -47 289 -116 364 -92 103 -254 145 -409 107z m237 -171 c48 -34 93 -113 103 -182 l7 -43 -246 0 -245 0 6 33 c11 59 44 124 81 159 61 59 88 69 174 66 67 -3 82 -7 120 -33z" />
      </g>
    </SvgIcon>
  );
});
