import { type UIEventHandler, memo, type MutableRefObject } from "react";

import times from "lodash/times";
import { type ReactElement } from "simple-markdown";
import styled from "styled-components";

import Box from "@mui/material/Box";

import { type ServerFindingWithType } from "data/finding";

import { hasLessThan, hasNo } from "utility/has";
import { toObjectWithValue } from "utility/object";
import { classIf } from "utility/style";

import { type FindingEntry } from "Component/Page/Findings/lib/data";
import { FindingsPageListFilteredEntry as FilteredEntry } from "Component/Page/Findings/ListPane/FilteredEntry";

import { ScrollPane } from "Component/ScrollPane";

import { FindingsContext } from "../lib/context";

import { FindingsPageListFinding as ListFinding } from "./ListFinding";
import { FindingsListPaneSkeletonFinding as SkeletonFinding } from "./SkeletonFinding";

// -----------------------------------------------------------------------------

export const FindingsPageListPane = memo(
  ({ scrollRef, onScroll, children: [loadMore, noFindings] }: Props) => {
    return (
      <FindingsContext.Consumer>
        {({
          loading,
          findingsTotal,
          filteredFindings,
          selectedFindings,
          setSelectedFindings,
          mode,
        }) => {
          const selectedFindingsByUuid = toObjectWithValue(
            selectedFindings,
            "uuid",
            true
          );

          //
          function toggleSelected(finding: ServerFindingWithType) {
            if (selectedFindingsByUuid[finding.uuid]) {
              setSelectedFindings(
                selectedFindings.filter(
                  (selectedFinding) => selectedFinding !== finding
                )
              );
            } else {
              setSelectedFindings([...selectedFindings, finding]);
            }
          }

          //
          return (
            <ScrollPane
              onScroll={onScroll as UIEventHandler<HTMLDivElement>}
              scrollRef={scrollRef}
            >
              <Container
                className={classIf(hasNo(selectedFindings), "noFindings")}
              >
                {filteredFindings.map((findingEntry: FindingEntry) => {
                  switch (findingEntry.type) {
                    case "filtered":
                      return (
                        <FilteredEntry
                          count={findingEntry.count}
                          key={`${Date.now()}-${Math.random()}`}
                        />
                      );

                    default: {
                      const finding =
                        findingEntry.finding as ServerFindingWithType;

                      //
                      return (
                        <ListFinding
                          key={finding.uuid}
                          selected={selectedFindingsByUuid[finding.uuid]}
                          selectable={hasLessThan(100, selectedFindings)}
                          finding={finding}
                          onToggle={toggleSelected}
                          startExpanded={mode === "deeplink"}
                        />
                      );
                    }
                  }
                })}

                {loading ? (
                  <Skeletons>
                    {times(20, (i) => (
                      <SkeletonFinding key={i} />
                    ))}
                  </Skeletons>
                ) : findingsTotal < 1 ? (
                  noFindings
                ) : (
                  loadMore
                )}
              </Container>
            </ScrollPane>
          );
        }}
      </FindingsContext.Consumer>
    );
  }
);

// -----------------------------------------------------------------------------

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  padding-bottom: 128px;
`;

const Skeletons = styled(Box)`
  display: flex;
  flex-direction: column;
  grid-column: 1 / span 2;
  min-height: 0vw;
`;

// -----------------------------------------------------------------------------

interface Props {
  onScroll: Function;
  scrollRef?: MutableRefObject<HTMLDivElement | undefined>;
  children: [ReactElement | null, ReactElement];
}
