import { memo } from "react";

import SvgIcon from "@mui/material/SvgIcon";

// -----------------------------------------------------------------------------

export const GoogleSheetCoverImage = memo((props: object) => {
  return (
    <SvgIcon viewBox="0 0 64 88" {...props}>
      <path d="M 42,0 64,22 53,24 42,22 40,11 Z" fill="#188038" />
      <path
        d="M 42,22 V 0 H 6 C 2.685,0 0,2.685 0,6 v 76 c 0,3.315 2.685,6 6,6 h 52 c 3.315,0 6,-2.685 6,-6 V 22 Z"
        fill="#34a853"
      />
      <path
        d="M 12,34 V 63 H 52 V 34 Z M 29.5,58 H 17 v -7 h 12.5 z m 0,-12 H 17 V 39 H 29.5 Z M 47,58 H 34.5 V 51 H 47 Z M 47,46 H 34.5 V 39 H 47 Z"
        fill="#fff"
      />
    </SvgIcon>
  );
});
