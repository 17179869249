import { memo } from "react";

import SvgIcon from "@mui/material/SvgIcon";

// -----------------------------------------------------------------------------

export const Auth0SAMLSSOIcon = memo((props: object) => {
  return (
    <SvgIcon viewBox="0 0 414.000000 122.000000" {...props}>
      <g
        fill="#eb5424"
        stroke="none"
        transform="translate(0.000000,122.000000) scale(0.100000,-0.100000)"
      >
        <path d="M155 1210 c-18 -31 -138 -442 -145 -495 -19 -148 28 -297 129 -407 61 -67 406 -312 423 -302 68 40 353 253 390 291 126 130 175 305 133 478 -8 33 -41 143 -74 245 l-59 185 -196 3 c-108 1 -196 -1 -196 -4 0 -4 26 -87 57 -185 l57 -179 193 0 c106 0 193 -3 193 -7 0 -5 -70 -57 -154 -117 -85 -60 -152 -114 -150 -120 30 -89 113 -360 111 -363 -2 -2 -72 46 -156 107 -84 60 -155 110 -158 110 -3 0 -74 -49 -158 -110 -84 -60 -155 -110 -158 -110 -3 0 22 82 54 183 32 100 57 187 55 192 -2 6 -71 57 -154 115 -83 58 -151 108 -152 112 0 4 88 9 196 10 l195 3 45 135 c24 74 51 156 58 182 l15 47 -196 3 c-107 2 -196 1 -198 -2z" />
        <path d="M2900 640 l0 -410 69 0 70 0 3 211 3 211 37 34 c32 29 45 34 88 34 41 0 55 -5 82 -29 l33 -29 3 -216 3 -216 70 0 69 0 0 229 0 229 -28 53 c-36 68 -89 99 -174 99 -73 0 -114 -17 -155 -64 l-32 -36 -3 152 -3 153 -67 3 -68 3 0 -411z" />
        <path d="M3755 1041 c-55 -14 -113 -50 -149 -92 -73 -85 -107 -244 -87 -401 15 -114 42 -184 91 -241 58 -66 117 -91 210 -91 179 -1 285 112 309 329 25 222 -40 402 -170 468 -60 31 -149 43 -204 28z m145 -140 c24 -18 70 -86 70 -106 0 -10 -292 -225 -305 -225 -5 0 -5 44 -2 98 7 145 49 229 123 252 37 11 82 3 114 -19z m90 -273 c0 -128 -34 -228 -92 -271 -37 -28 -108 -28 -145 -1 -33 25 -74 92 -71 116 3 16 282 228 300 228 5 0 8 -33 8 -72z" />
        <path d="M2558 913 l-3 -78 -42 -3 -43 -3 0 -55 0 -54 45 0 45 0 0 -199 c0 -189 1 -200 23 -232 34 -51 79 -70 162 -67 l70 3 3 59 3 58 -36 -6 c-26 -5 -44 -3 -61 9 -24 15 -24 16 -24 195 l0 180 60 0 60 0 0 55 0 55 -60 0 -60 0 0 80 0 80 -70 0 -69 0 -3 -77z" />
        <path d="M1417 825 c-53 -20 -92 -50 -116 -89 -34 -54 -29 -62 38 -69 58 -6 61 -5 93 27 42 42 99 54 151 32 28 -11 38 -23 48 -56 18 -58 4 -68 -122 -85 -57 -8 -122 -22 -145 -30 -129 -49 -150 -216 -37 -302 31 -24 48 -29 113 -31 86 -4 141 15 179 64 l21 27 0 -42 0 -41 70 0 70 0 0 223 c-1 286 -10 314 -115 363 -40 19 -66 24 -130 23 -44 0 -97 -7 -118 -14z m223 -358 c0 -86 -61 -147 -148 -147 -35 0 -49 6 -73 29 -35 36 -37 61 -6 97 19 22 41 31 108 45 123 25 119 26 119 -24z" />
        <path d="M1860 616 c0 -240 7 -285 53 -334 82 -89 247 -80 312 16 l25 37 0 -53 0 -52 70 0 70 0 0 305 0 305 -70 0 -70 0 0 -192 c0 -217 -7 -244 -69 -285 -40 -27 -71 -29 -116 -7 -58 27 -65 56 -65 283 l0 201 -70 0 -70 0 0 -224z" />
      </g>
    </SvgIcon>
  );
});
