import { memo } from "react";

import { Pie } from "@nivo/pie";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import { SVGText } from "Component/SVGText";

// -----------------------------------------------------------------------------

export const SingleValuePie = memo(
  ({ title, data, loading = false }: Props) => {
    const CenteredMetric = ({ centerX, centerY }: any) => {
      return (
        <SVGText
          x={centerX}
          y={centerY}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: "50px",
            fontWeight: 800,
          }}
        >
          {getDaysLabel(data as number)}
        </SVGText>
      );
    };

    //
    return (
      <Card
        sx={{
          width: "100%",
          minWidth: "250px",
          height: "194px",
          overflow: "visible",
        }}
      >
        <CardContent sx={{ padding: "8px 16px" }}>
          <Typography variant="overline">{title}</Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5" fontWeight="bold">
              {loading ? (
                <>
                  &nbsp;
                  <CircularProgress size={20} />
                </>
              ) : !data ? (
                "None"
              ) : null}
            </Typography>
          </Box>
        </CardContent>
        {data ? (
          <Box
            sx={{
              width: "100%",
              height: 130,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Pie
              margin={{ top: 1, right: 1, bottom: 1, left: 1 }}
              colors={{ datum: "data.color" }}
              borderWidth={1}
              borderColor={{
                from: "color",
                modifiers: [["darker", 0.5]],
              }}
              isInteractive={false}
              activeOuterRadiusOffset={4}
              innerRadius={0.85}
              padAngle={1}
              cornerRadius={1}
              enableArcLabels={false}
              enableArcLinkLabels={false}
              data={
                [
                  {
                    id: "singleValue",
                    label: "",
                    value: data,
                    color: getColorFromData(data),
                  },
                ] as any
              }
              layers={["arcs", "arcLabels", "arcLinkLabels", CenteredMetric]}
              height={130}
              width={130}
            />
          </Box>
        ) : null}
      </Card>
    );
  }
);

// -----------------------------------------------------------------------------

const getColorFromData = (data: number) => {
  if (data <= 7) {
    return "#4CAF50";
  } else if (data <= 30) {
    return "#FFBC57";
  } else {
    return "#E31C0C";
  }
};

const OneDecimal = Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 1,
});

const getDaysLabel = (data: number) => {
  switch (true) {
    case !data:
      return "";
    case data < 1:
      return "< 1";
    case data > 30:
      return "> 30";
    default:
      return OneDecimal.format(data);
  }
};

// -----------------------------------------------------------------------------

interface Props {
  title: JSX.Element | string;
  data: number | undefined;
  loading?: boolean;
}
