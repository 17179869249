import { memo } from "react";

import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// -----------------------------------------------------------------------------

export const MembersDeleteConfirmationModal = memo(
  ({ name, onClose }: Props) => {
    //
    return (
      <Modal open onClose={() => onClose(0)} sx={{ display: "flex" }}>
        <Paper
          sx={{
            margin: "auto",
            p: 2,
          }}
        >
          <Typography variant="h6">Confirm Deletion</Typography>
          <Typography variant="body1">
            Are you sure you want to delete <strong>{name}</strong>?
          </Typography>

          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <Button onClick={() => onClose(1)} variant="contained">
              Delete Member
            </Button>
            <Button onClick={() => onClose(0)} variant="contained">
              Cancel
            </Button>
          </Stack>
        </Paper>
      </Modal>
    );
  }
);

// -----------------------------------------------------------------------------

interface Props {
  name: string;
  onClose: (index: number) => void;
}
