import { type SUTS } from "data/typescript";

import { captureSentryException } from "utility/capture_sentry_exception";

// -----------------------------------------------------------------------------

/**
 * An asyncOrSwim variant that contains a Sentry logger in the error handler.
 *
 *
 * @see asyncOrSwim
 *
 * @param action
 * @param sentryError
 * @param errorHandler
 */
export function asyncOrSwimWithSentry(
  action: () => Promise<any>,
  sentryError: string,
  errorHandler: (error: Error) => any,
  shouldThrow: (error: Error) => boolean = returnTrue
): void {
  (async () => {
    try {
      await action();
    } catch (error) {
      if (shouldThrow(error as SUTS<Error>)) {
        captureSentryException(
          error,
          sentryError ?? "An unknown error occurred"
        );
        errorHandler(error as SUTS<Error>);
      }
    }
  })();
}

// -----------------------------------------------------------------------------

function returnTrue() {
  return true;
}
