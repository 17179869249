import { memo } from "react";

import SvgIcon from "@mui/material/SvgIcon";

// -----------------------------------------------------------------------------

export const DuoSAMLSSOICon = memo((props: object) => {
  return (
    <SvgIcon viewBox="0 0 285 92.9" {...props}>
      <path
        d="M145,92h43.5V.5H145ZM0,92c48.32.48,90.52,2.66,92-43.5H0ZM193.5,48.5c-.24,57.15,91.55,57,91.5,0Z"
        fill="#82c96a"
        transform="translate(0 0.4)"
      />
      <path
        d="M0,0V44H92c-.17-11.36-4.56-18.92-9.5-25.5C66-3.49,38-.46,0,0ZM96.5,30c0,41.25,10.86,54.08,43.5,62.5q.25-46,.5-92L97,0Q96.75,15,96.5,30Zm126-27C206.05,9.37,195.38,23.41,193,44h92C285.09,14.45,253.79-9.12,222.5,3Z"
        fill="#6ebf51"
        transform="translate(0 0.4)"
      />
    </SvgIcon>
  );
});
