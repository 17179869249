import md5 from "md5";

import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";

// -----------------------------------------------------------------------------

const Role: { [prop: string]: string } = {
  SuperAdmin: "Super Admin",
  Auditor: "Auditor",
  Admin: "Admin",
  UnprivilegedUser: "Member",
  Unknown: "Unknown",
};

export const LoginMethod: { [prop: string]: string } = {
  ADFSSAML: "AD FS SAML",
  Auth0SAML: "Auth0 SAML",
  AzureSAML: "Azure AD SAML",
  CASSAML: "CAS SAML",
  CloudflareSAML: "Cloudflare SAML",
  CyberArkSAML: "CyberArk SAML",
  ClassLinkSAML: "ClassLink SAML",
  DuoSAML: "Duo SAML",
  GenericOIDC: "Generic OIDC",
  GenericSAML: "Generic SAML",
  GoogleOAuth: "Google OAuth",
  GoogleSAML: "Google SAML",
  JumpCloudSAML: "JumpCloud SAML",
  KeycloakSAML: "Keycloak SAML",
  LastPassSAML: "LastPass SAML",
  MicrosoftOAuth: "Microsoft OAuth",
  MiniOrangeSAML: "miniOrange SAML",
  NetIqSAML: "NetIQ SAML",
  OktaSAML: "Okta SAML",
  OneLoginSAML: "OneLogin SAML",
  OracleSAML: "Oracle SAML",
  PingFederateSAML: "PingFederate SAML",
  PingOneSAML: "PingOne SAML",
  SalesforceSAML: "Salesforce SAML",
  SimpleSamlPhpSAML: "SimpleSAMLPhp SAML",
  VMwareSAML: "VMware Workspace One SAML",
  MagicLink: "Magic Link",
  Unknown: "Unknown",
};

// -----------------------------------------------------------------------------

export const MembersColumns = [
  {
    field: "email",
    headerName: "Email Address",
    flex: 1,
    renderCell: (params: any) => {
      const hash = md5(params.row.email.trim().toLocaleLowerCase());

      //
      return (
        <>
          <Avatar
            src={`//www.gravatar.com/avatar/${hash}}?d=retro&r=g&s=30`}
            sx={{ height: "calc(100% - 8px)", width: "auto", mr: 1 }}
          />
          <Typography variant="inherit" noWrap>
            {params.row.email}
          </Typography>
        </>
      );
    },
  },
  {
    field: "full_name",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "role",
    headerName: "Role",
    width: 128,
    valueGetter: (params: any) =>
      Role[params.row.permission_level as string] || Role.Unknown,
  },
  {
    field: "status",
    width: 128,
    headerName: "Status",
    renderCell: (params: any) =>
      params.row.is_active ? (
        <Chip label="ACTIVE" color="success" size="small" />
      ) : (
        <Chip label="INACTIVE" color="warning" size="small" />
      ),
  },
  {
    field: "login_method",
    headerName: "Login Method",
    flex: 1,
    valueGetter: (params: any) => {
      return (
        LoginMethod[params.row.login_method as string] || LoginMethod.Unknown
      );
    },
  },
  {
    field: "actions",
    headerName: "Actions",
    flex: 1,
    renderCell: (params: any) => params.row.actions,
    disableReorder: true,
    hideSortIcons: true,
  },
];
