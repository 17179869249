import { memo, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { type Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import CheckIcon from "@mui/icons-material/Check";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { toCurrency } from "utility/currency";
import { pluralize } from "utility/pluralize";

import { BillingModal } from "Component/Modal/BillingModal";

// -----------------------------------------------------------------------------

export const BillingPageCurrentFeatures = memo(
  ({ label, interval, features, isTrial, price = 0, daysLeft = 0 }: Props) => {
    const [showModal, setShowModal] = useState(false);
    //
    const daysLeftSanitized: number | "no" = daysLeft > 0 ? daysLeft : "no";

    //
    function openModal() {
      setShowModal(true);
    }

    //
    return (
      <Paper sx={PaperSectionSX}>
        <Stack direction="row" sx={SectionHeaderSX}>
          <Typography variant="h6" fontWeight="bold" sx={SectionHeaderLabelSX}>
            {label}
          </Typography>
          <Typography variant="h6">
            <Box component="strong">{toCurrency(price)}</Box>
            <Box component="span" sx={PriceIntervalSX}>
              /{interval}
            </Box>
          </Typography>
        </Stack>
        <BillingModal showModal={showModal} setShowModal={setShowModal} />
        {isTrial ? (
          <Paper sx={TrialLabelContainerSX}>
            <Box
              sx={{
                display: "grid",
                grid: "auto / auto 1fr auto",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <InfoOutlinedIcon color="info" />
              <Typography
                sx={TrialLabelWarningSX}
                color="primary"
                alignItems="center"
              >
                You have{" "}
                <strong>
                  {daysLeftSanitized} {pluralize(daysLeft, "day")}
                </strong>{" "}
                left on your fully-featured free trial.
              </Typography>
              <Button variant="contained" color="primary" onClick={openModal}>
                Upgrade
              </Button>
            </Box>
          </Paper>
        ) : null}

        <Stack spacing={0.25} p={2}>
          {features?.map((feature: string) => (
            <Typography variant="h6" sx={FeatureLabelSX} key={feature}>
              <CheckIcon sx={FeatureIconSX} />
              {feature}
            </Typography>
          )) ?? null}
        </Stack>
      </Paper>
    );
  }
);

// -----------------------------------------------------------------------------

const PaperSectionSX = { p: 1.5 };

const SectionHeaderSX = { borderBottom: "1px primary.border solid" };

const SectionHeaderLabelSX = { flexGrow: 1 };

const PriceIntervalSX = { opacity: 0.6, ml: 0.5 };

const TrialLabelContainerSX = {
  m: 2,
  mb: 0,
  padding: "8px 16px",
  borderColor: (theme: Theme) => `${theme.palette.info.main} !important`,
};

const TrialLabelWarningSX = { flexGrow: 1 };

const FeatureLabelSX = {
  display: "flex",
  alignItems: "flex-start",
  svg: { marginTop: "4px" },
};

const FeatureIconSX = { mr: 2 };

// -----------------------------------------------------------------------------

interface Props {
  label: string;
  interval: string;
  features: string[];
  isTrial: boolean;
  price?: number;
  daysLeft?: number;
}
