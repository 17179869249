import { memo } from "react";

import SvgIcon from "@mui/material/SvgIcon";

// -----------------------------------------------------------------------------

export const PagerDutyCoverImage = memo((props: object) => {
  return (
    <SvgIcon viewBox="0 0 974.8 200" {...props}>
      <path
        d="M950.5,41.9l-22,62.4c-4,11.2-6,17.6-9.7,28.4h-0.4c-2.9-9.3-5.5-16.8-9.3-27.8l-21.4-63.1h-25.6l44.3,115.8c-1,2.5-2,5.1-3.1,7.8c-3.7,9-12.5,14.9-22.2,14.9l-11,0V200h11.3c18.3,0,34.8-11.1,41.6-28.2c14.1-35.3,38.1-95.4,51.8-129.9H950.5z"
        fill="#06AC38"
      />
      <path
        d="M336.2,135.4c0,41.9-19.2,64.6-58,64.6c-28.9,0-46.3-15.2-52.5-38.1h23.4c4,10.6,12.3,20.1,29.5,20.1c26.5,0,35.9-16.5,35.9-47.9c-0.2,0-0.2-0.2-0.4-0.2c-5.7,9.9-18.5,18.7-38.8,18.7c-32.6,0-53.4-23.6-53.4-56.7c0-34.4,22.5-57.6,53.6-57.6c20.1,0,32.2,8.6,38.8,18.1c-0.2-2.4-0.2-4.9-0.2-7.3v-7.3h22.1V135.4z M246.2,95.7c0,22.5,12.6,38.6,34.2,38.6c19.4,0,34.4-13.7,34.4-39.3c0-22.7-13.2-37.9-34.4-37.9S246.2,72.3,246.2,95.7z"
        fill="#06AC38"
      />
      <path
        d="M372.7,105.6c0.9,20.3,13.9,36.4,36.4,36.4c15.9,0,23.6-7.5,29.5-17.4h22.3c-6.6,20.9-24.9,35.9-52.7,35.9c-35.7,0-58-24-58-60.2c0-36.2,23.2-61.7,58-61.7c37.7,0,55.1,28.7,55.1,59.8v7.3H372.7z M440.1,88c-2-18.3-13.5-31.3-32.4-31.3c-18.1,0-32,11.9-34.2,31.3H440.1z"
        fill="#06AC38"
      />
      <path
        d="M499,41.9v14.8c3.7-10.8,15-17.6,28-17.6c2.9,0,4.2,0.2,5.7,0.4v20.3c-2-0.4-5.3-0.7-7.7-0.7c-20.9,0-24.9,15.4-24.9,38.6v60h-22.3v-95c0-7.3,0-13.7-0.2-20.7H499z"
        fill="#06AC38"
      />
      <path
        d="M761.7,141.1c-6,9.9-17.2,19.4-36.8,19.4c-26.9,0-40.1-16.5-40.1-46.5V41.9H707v63.7c0,22.7,7.5,36.4,25.6,36.4c23.8,0,28-21.6,28-47V41.9h22.3v98.3c0,6,0,11.7,0.2,17.4h-21.4V141.1z"
        fill="#06AC38"
      />
      <path
        d="M833,41.9h21.6v17.2H833v69.5c0,11,5.5,14.3,14.1,14.3c1.5,0,4.2-0.2,5.5-0.4V159c-3.3,0.4-5.7,0.9-8.6,0.9c-20.5,0-33.5-6.8-33.5-30V59.1h-17.4V41.9h17.4V10.6H833V41.9z"
        fill="#06AC38"
      />
      <rect y="115.6" width="23.1" height="42" fill="#06AC38" />
      <path
        d="M87,7.7C74.6,1.1,66,0,45.7,0H0v95.6h23.1h3h19.4c18.1,0,31.6-1.1,43.5-9c13-8.6,19.7-22.9,19.7-39.4C108.7,29.3,100.4,15,87,7.7z M50.8,75.6H23.1V20.5l26.1-0.2C73,20,84.9,28.4,84.9,47.4C84.9,67.8,70.2,75.6,50.8,75.6z"
        fill="#06AC38"
      />
      <path
        d="M546.3,0h38.6c57.8,0.4,85.6,28.6,85.8,79.2c0.2,43.1-22.1,77.9-82,78.5l-42.3,0V0z M569.7,138l13.5,0c41.9,0,63.8-16,63.8-58.9c-0.1-37.7-21.8-59.3-61.6-59.3c-7.7,0-15.7,0.2-15.7,0.2V138z"
        fill="#06AC38"
      />
      <path
        d="M189.7,157.7c-0.7-4.6-0.7-7.5-1.1-15.9c-8.8,12.6-20.5,18.1-39,18.1c-24.3,0-41.9-11.9-41.9-33.5c0-24.9,23.8-33.1,54.7-37.3c7.7-1.1,16.3-2,23.8-2.6c0-22.9-12.7-29.8-26-29.8s-23,9.3-23,21.5h-21.6c0-23.5,18.5-39.3,45.1-39.3s47.1,11,47.1,53.4v22.5c0,18.7,0.9,32.4,2.9,43H189.7z M131.7,126.1c0,10.8,8.8,17.6,22.9,17.6c20.7,0,31.8-12.6,31.8-35.1c0-2.9,0-4.9,0.2-6.2C148.9,106.3,131.7,110,131.7,126.1z"
        fill="#06AC38"
      />
    </SvgIcon>
  );
});
