import { memo, type ReactNode } from "react";

import { ResponsivePie } from "@nivo/pie";
import { useTheme } from "styled-components";

import { type FOTS } from "data/typescript";

// -----------------------------------------------------------------------------

export const PieInteraction = memo(
  ({
    margin,
    colors,
    borderWidth,
    borderColor,
    activeOuterRadiusOffset,
    innerRadius,
    padAngle,
    cornerRadius,
    enableArcLinkLabels,
    enableArcLabels,
    data,
    layers,
    legends,
    tooltip,
  }: Props) => {
    const theme = useTheme();
    return (
      <ResponsivePie
        theme={{
          tooltip: {
            container: {
              color: theme.pieChart.text,
            },
          },
        }}
        enableArcLabels={enableArcLabels}
        margin={margin}
        colors={colors}
        borderWidth={borderWidth}
        borderColor={borderColor}
        activeOuterRadiusOffset={activeOuterRadiusOffset}
        innerRadius={innerRadius}
        padAngle={padAngle}
        cornerRadius={cornerRadius}
        enableArcLinkLabels={enableArcLinkLabels}
        arcLabelsTextColor="white"
        data={data}
        layers={layers}
        legends={{
          ...legends,
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: theme.primary.text,
              },
            },
          ],
        }}
        tooltip={tooltip}
      />
    );
  }
);

// -----------------------------------------------------------------------------

interface Props extends ResponsivePie {
  margin: FOTS;
  colors: FOTS;
  borderWidth: FOTS;
  borderColor: FOTS;
  activeOuterRadiusOffset: FOTS;
  innerRadius: FOTS;
  padAngle: FOTS;
  cornerRadius: FOTS;
  enableArcLinkLabels: FOTS;
  data: FOTS;
  layers: FOTS;
  variant: FOTS;
  fontWeight?: string;
  children: ReactNode;
  isGutterBottom: boolean;
  legends?: FOTS;
  enableArcLabels?: FOTS;
  tooltip?: FOTS;
}
