import { memo } from "react";

import SvgIcon from "@mui/material/SvgIcon";

// -----------------------------------------------------------------------------

export const KeycloakSAMLSSOIcon = memo((props: object) => {
  return (
    <SvgIcon viewBox="0 0 123 26" {...props}>
      <path
        d="M7.22944 0.943712C10.8448 0.85862 14.5037 0.932803 18.1256 0.908802C19.2732 0.937166 20.4427 0.841165 21.5838 0.954621C22.9169 2.91828 23.938 5.13067 25.2602 7.1096C23.6434 7.17724 22.018 7.12051 20.3969 7.13142C19.9954 6.47687 19.6747 5.72413 19.1729 5.14376C18.3787 5.06303 17.5648 5.09576 16.7685 5.12194L16.7183 5.19612C16.3255 5.84631 15.9634 6.51396 15.5597 7.1576C14.783 7.14015 13.9975 7.11396 13.223 7.16851C12.8128 6.49214 12.42 5.80486 12.0251 5.11758C11.2157 5.12631 10.3451 5.01721 9.55092 5.18303C9.09492 5.78522 8.76982 6.49214 8.37927 7.13796C6.7538 7.16196 5.12614 7.09869 3.50285 7.17069C4.40177 5.8114 5.14796 4.34957 5.98797 2.95101C6.39816 2.29427 6.71453 1.52408 7.22944 0.943712Z"
        fill="#4D4D4D"
      />
      <path
        d="M12.0251 5.11758C11.6848 5.76776 11.3313 6.40923 10.9495 7.03541C9.69492 9.09944 8.54509 11.2267 7.2949 13.2929L7.16835 13.4893C6.72107 14.146 6.31743 14.8268 5.90943 15.5053C5.52542 14.8333 5.13705 14.1613 4.73778 13.498C4.85123 13.282 4.96469 13.066 5.07815 12.85C6.17343 10.9453 7.27744 9.04271 8.37927 7.13796C8.76982 6.49214 9.09492 5.78522 9.55092 5.18303C10.3451 5.01721 11.2157 5.1263 12.0251 5.11758Z"
        fill="#008AAA"
      />
      <path
        d="M16.7685 5.12194C17.5648 5.09575 18.3787 5.06303 19.1729 5.14375C19.6747 5.72412 19.9954 6.47686 20.3969 7.13141C21.2216 8.57361 22.0594 10.0071 22.8907 11.4449C22.4543 12.1802 22.0071 12.9024 21.5205 13.6049C20.735 13.522 19.9452 13.5155 19.1554 13.4828L19.103 13.4042C19.8863 13.4129 20.6696 13.4086 21.4507 13.3933C20.7023 11.9489 19.8274 10.5744 19.0376 9.1518C18.2565 7.8296 17.5823 6.46595 16.7183 5.19612L16.7685 5.12194Z"
        fill="#33C6E9"
      />
      <path
        d="M12.0251 5.11761C12.42 5.8049 12.8128 6.49218 13.223 7.16855C12.0753 9.27621 10.8447 11.3402 9.63819 13.4152C8.86145 13.3606 8.07381 13.3061 7.29489 13.293C8.54509 11.2268 9.69492 9.09948 10.9495 7.03545C11.3313 6.40927 11.6848 5.7678 12.0251 5.11761Z"
        fill="#00B8E3"
      />
      <path
        d="M16.7183 5.19614C17.5823 6.46597 18.2565 7.82962 19.0376 9.15182C19.8274 10.5744 20.7023 11.9489 21.4507 13.3933C20.6696 13.4086 19.8863 13.413 19.103 13.4042C18.7365 12.7628 18.3896 12.1082 18.0099 11.4711C17.1895 10.0376 16.3234 8.62381 15.5597 7.15761C15.9633 6.51397 16.3255 5.84633 16.7183 5.19614Z"
        fill="#00B8E3"
      />
      <path
        d="M72.3662 7.11616C73.6906 7.45871 74.764 8.24636 75.6869 9.23255C75.1088 9.95038 74.5546 10.6726 73.828 11.2529C73.2193 10.6529 72.628 10.1009 71.788 9.84565C70.0709 9.20855 67.8999 10.0791 67.1625 11.7722C66.7392 12.7497 66.8308 13.8515 67.049 14.8661C66.0977 15.1606 65.1836 15.525 64.2737 15.9264C63.7806 14.5213 63.7195 12.9766 64.0883 11.5366C64.505 10.0006 65.5785 8.65436 66.929 7.82962C68.5305 6.83252 70.5596 6.61216 72.3662 7.11616Z"
        fill="#A9A9A9"
      />
      <path
        d="M95.4741 8.05217C95.1555 8.96854 94.7715 9.83691 94.2697 10.6704C93.4078 9.77146 92.2689 9.23036 91.0056 9.45945C89.1773 9.778 87.9271 11.5388 87.9205 13.3431C87.8878 14.5955 88.4638 15.7126 89.3562 16.5614C88.466 16.9955 87.5474 17.3555 86.6878 17.8574C84.423 15.5206 84.3336 11.5017 86.5285 9.08636C88.6863 6.53142 92.7773 6.15178 95.4741 8.05217Z"
        fill="#919191"
      />
      <path
        d="M25.2602 7.10964C26.7373 6.946 28.1839 7.0311 29.6653 7.02019C29.8181 7.17292 29.9708 7.32564 30.1235 7.47837C30.1322 8.6173 30.141 9.75404 30.1104 10.893C29.6392 11.3817 29.1722 11.8661 28.7555 12.4028C28.2624 13.0203 27.8631 13.6595 27.1868 14.0981C26.3817 14.6174 25.5177 15.0428 24.7016 15.549C23.8158 16.0945 22.9322 16.6334 21.9765 17.0567C22.6311 15.8654 23.3904 14.7199 23.9969 13.5068C23.7634 12.7759 23.2485 12.1235 22.8907 11.445C22.0594 10.0071 21.2216 8.57366 20.3969 7.13146C22.018 7.12055 23.6434 7.17728 25.2602 7.10964Z"
        fill="#D4D4D4"
      />
      <path
        d="M3.50285 7.1707C5.12614 7.0987 6.7538 7.16197 8.37927 7.13797C7.27744 9.04272 6.17343 10.9453 5.07814 12.85C3.41776 12.9242 1.76828 13.5984 0.321723 14.3795C-0.0273717 13.7839 -0.143009 13.3169 0.232268 12.6908C1.31664 10.8536 2.3312 8.95108 3.50285 7.1707Z"
        fill="#E2E2E2"
      />
      <path
        d="M13.223 7.16852C13.9975 7.11397 14.783 7.14016 15.5597 7.15761C16.3234 8.62381 17.1896 10.0376 18.0099 11.4711C16.1859 11.9118 14.3554 12.3242 12.527 12.7431C12.1277 12.8697 11.5626 12.9373 11.3531 13.3431C11.0215 13.8493 10.7619 14.4101 10.4826 14.9468C10.1902 14.4406 9.90876 13.9322 9.63821 13.4151C10.8448 11.3402 12.0753 9.27618 13.223 7.16852Z"
        fill="#DEDEDE"
      />
      <path
        d="M31.0988 7.24703C32.0239 7.04412 33.0624 7.13794 34.0006 7.2034C34.0181 8.83323 33.9723 10.4587 34.0268 12.0864C35.5781 10.4565 37.0443 8.74596 38.6218 7.1423C39.7607 7.12049 40.8931 7.08558 42.0276 7.19467C41.8182 7.54158 41.6065 7.88631 41.3294 8.18523C40.2472 9.34815 39.1956 10.5394 38.1221 11.7089C37.389 11.6762 36.7301 11.8136 36.0407 12.0558C34.4741 12.5969 32.8639 12.9526 31.2755 13.4129C31.1882 15.5337 31.3213 17.6392 31.1206 19.7664C30.9221 18.2261 31.0879 16.6442 31.0399 15.0908C31.0813 12.4791 31.0071 9.85434 31.0988 7.24703Z"
        fill="#CCCCCD"
      />
      <path
        d="M43.2407 7.17287C44.8422 7.11614 46.4459 7.15105 48.0473 7.14232C47.4757 8.14379 47.3339 9.23907 46.2561 9.82817L46.0619 9.90453C46.0553 10.3714 46.0488 10.8405 46.0401 11.3096C45.1804 12.9155 44.2662 14.53 43.6291 16.2406C43.4917 16.2973 43.3564 16.3562 43.2211 16.4152C43.208 13.3344 43.2189 10.2536 43.2407 7.17287Z"
        fill="#C3C3C3"
      />
      <path
        d="M48.0474 7.14234C49.4743 7.14888 50.9121 7.0747 52.3281 7.20125C52.3281 7.99107 52.3958 8.80272 52.3041 9.58818C52.2038 9.74091 52.0816 9.81291 51.9354 9.79982C50.0459 9.84782 48.1455 9.74309 46.2561 9.82819C47.3339 9.23909 47.4757 8.1438 48.0474 7.14234Z"
        fill="#BDBDBD"
      />
      <path
        d="M53.1616 7.18813C54.1565 7.17285 55.2976 6.99612 56.2598 7.24049C57.198 8.65432 57.9878 10.1903 58.8606 11.6478C58.1406 12.2478 57.3616 12.778 56.6154 13.3475C55.4634 11.2987 54.2089 9.29142 53.1616 7.18813Z"
        fill="#BCBCBC"
      />
      <path
        d="M61.5617 7.18376C62.5937 7.09649 63.6235 7.12485 64.6555 7.20122C63.6672 9.17797 62.4628 11.0434 61.398 12.9787C61.0293 13.7009 60.3486 14.4711 60.4009 15.3046C60.3748 16.7839 60.4642 18.261 60.3704 19.7403C60.3202 19.1228 60.2679 18.5053 60.2111 17.8879C59.2271 18.3199 58.494 19.0137 57.7173 19.7315L57.4533 19.7184C57.4227 18.2479 57.4838 16.773 57.4555 15.3024C57.4773 14.6086 56.9384 13.9366 56.6154 13.3475C57.3616 12.778 58.1406 12.2478 58.8606 11.6478C59.8882 10.2514 60.6039 8.63032 61.5617 7.18376Z"
        fill="#AFAFAF"
      />
      <path
        d="M76.8848 7.21651C77.812 7.07906 78.783 7.1576 79.719 7.14888C79.7801 9.43108 79.7342 11.7177 79.7495 13.9999C79.7692 15.0297 79.6797 16.0923 79.8193 17.1134C79.8477 17.2377 79.8761 17.3599 79.9066 17.4843C79.2019 18.2435 78.4361 18.9286 77.8142 19.7643C77.4848 19.7556 77.1575 19.7381 76.8302 19.7228C76.8586 15.5599 76.7844 11.3751 76.8848 7.21651Z"
        fill="#9B9B9B"
      />
      <path
        d="M102.816 7.23181C103.418 7.08563 104.965 7.08126 105.567 7.22744C106.25 8.53873 106.756 9.95475 107.37 11.3009C106.527 11.7482 105.7 12.2151 104.887 12.7082C104.66 12.1868 104.439 11.6609 104.195 11.146C103.748 12.0842 103.368 13.0442 102.943 13.9933C101.487 14.4013 100.392 15.3614 98.9978 15.9461C100.052 13.4893 101.129 11.0435 102.175 8.58237C102.371 8.13073 102.552 7.64854 102.816 7.23181Z"
        fill="#7F7F7F"
      />
      <path
        d="M111.77 7.20122C112.715 7.10959 113.679 7.1445 114.628 7.17068C114.687 8.81797 114.622 10.4653 114.679 12.1126C116.287 10.5067 117.738 8.75033 119.343 7.14231C120.441 7.14668 121.538 7.12922 122.636 7.1685V7.45868C121.074 9.23688 119.459 10.9714 117.897 12.7497C118.327 13.3366 118.75 13.9257 119.167 14.5191C118.383 15.0777 117.629 15.6668 116.891 16.2821C116.553 15.8195 116.208 15.3591 115.857 14.9075C115.464 15.3373 114.975 15.7562 114.696 16.269C114.618 16.7882 114.655 17.3315 114.65 17.8552C113.895 18.1344 113.079 18.3941 112.388 18.8086C112.002 19.0072 111.949 19.3781 111.751 19.7381C111.753 15.5599 111.727 11.3795 111.77 7.20122Z"
        fill="#6D6D6D"
      />
      <path
        d="M95.4741 8.05215C96.1744 8.56925 96.7897 9.18889 97.2414 9.93727C98.845 12.5031 98.389 16.2079 96.0828 18.2108C93.5017 20.5934 89.0878 20.4297 86.6878 17.8574C87.5474 17.3555 88.466 16.9955 89.3562 16.5613C90.7482 17.6261 92.7359 17.6021 93.9991 16.3453C95.5439 14.8377 95.6028 12.3373 94.2697 10.6704C94.7715 9.8369 95.1555 8.96853 95.4741 8.05215Z"
        fill="#868686"
      />
      <path
        d="M30.1104 10.8929C30.0515 13.6028 30.1213 16.3148 30.0973 19.0247C30.117 19.4458 29.9228 19.8232 29.4668 19.8472C28.0682 19.8952 26.65 19.8778 25.2536 19.797C23.6391 19.7425 22.0223 19.7883 20.4099 19.7774C20.9336 18.8719 21.4463 17.9599 21.9765 17.0567C22.9322 16.6334 23.8158 16.0945 24.7016 15.549C25.5176 15.0428 26.3816 14.6174 27.1867 14.0981C27.8631 13.6595 28.2624 13.0202 28.7555 12.4028C29.1722 11.866 29.6391 11.3817 30.1104 10.8929Z"
        fill="#C6C6C6"
      />
      <path
        d="M46.0401 11.3097C46.051 11.6173 46.0662 11.9249 46.0837 12.2304C47.9579 12.2871 49.8321 12.2282 51.7041 12.2653C51.7216 13.0595 51.7216 13.8493 51.7041 14.6435C49.8299 14.6806 47.9557 14.6195 46.0815 14.6806C46.0662 15.1039 46.051 15.5272 46.0488 15.9504C45.2437 16.0639 44.432 16.1163 43.6291 16.2406C44.2662 14.5301 45.1804 12.9155 46.0401 11.3097Z"
        fill="#BDBDBD"
      />
      <path
        d="M107.369 11.3009C107.983 12.7366 108.615 14.1613 109.222 15.597C108.5 16.8232 108.277 18.3788 107.902 19.7447C107.524 18.8327 107.14 17.925 106.717 17.0348C105.041 17.061 103.357 16.9868 101.684 17.061C101.112 17.8072 100.97 19.0857 100.348 19.7207C99.3861 19.8232 98.3977 19.7468 97.4312 19.7272C97.8676 18.4334 98.4806 17.2137 98.9977 15.9461C100.392 15.3613 101.487 14.4013 102.943 13.9933L102.724 14.4842C103.7 14.4995 104.671 14.5039 105.646 14.4799C105.393 13.8886 105.14 13.2995 104.887 12.7082C105.7 12.2151 106.527 11.7482 107.369 11.3009Z"
        fill="#767676"
      />
      <path
        d="M18.0099 11.4711C18.3896 12.1082 18.7365 12.7628 19.103 13.4042L19.1554 13.4828C17.951 15.5708 16.7008 17.6414 15.5575 19.7643C14.7764 19.773 13.9953 19.7752 13.2142 19.7665C12.3655 18.1279 11.3859 16.5548 10.4826 14.9468C10.7619 14.41 11.0215 13.8493 11.3531 13.3431C11.5626 12.9373 12.1277 12.8697 12.527 12.7431C14.3553 12.3242 16.1859 11.9118 18.0099 11.4711Z"
        fill="#D1D1D1"
      />
      <path
        d="M22.8907 11.4449C23.2485 12.1235 23.7634 12.7758 23.9969 13.5068C23.3903 14.7199 22.6311 15.8653 21.9765 17.0566C21.4463 17.9599 20.9336 18.8719 20.4099 19.7774C20.0172 20.4581 19.6223 21.1388 19.2099 21.8087C18.3917 21.7999 17.5714 21.7999 16.7532 21.8021C18.3503 19.077 19.8448 16.2821 21.5205 13.6049C22.0071 12.9024 22.4543 12.1802 22.8907 11.4449Z"
        fill="#00B8E3"
      />
      <path
        d="M38.1222 11.7089C37.8211 12.0471 37.5221 12.3853 37.2232 12.7234C38.3425 14.4165 39.584 16.0246 40.7295 17.7024C39.8807 18.1191 39.0451 18.5359 38.2989 19.1271C37.2363 17.7308 36.237 16.2864 35.1483 14.9118C34.7752 15.3155 34.3279 15.7147 34.053 16.1969C33.9221 17.3511 34.0399 18.5424 34.0028 19.7053C33.0428 19.7948 32.085 19.7839 31.1206 19.7664C31.3214 17.6391 31.1883 15.5337 31.2755 13.4129C32.8639 12.9525 34.4741 12.5969 36.0407 12.0558C36.7301 11.8136 37.3891 11.6762 38.1222 11.7089Z"
        fill="#C6C6C6"
      />
      <path
        d="M5.07815 12.85C4.96469 13.066 4.85124 13.282 4.73778 13.498C5.13706 14.1613 5.52543 14.8333 5.90943 15.5053C6.72326 16.9301 7.55018 18.3482 8.37055 19.7708C6.75162 19.7643 5.13051 19.7926 3.51377 19.7512C2.38139 18.0057 1.41265 16.1533 0.321732 14.3795C1.76829 13.5984 3.41777 12.9242 5.07815 12.85Z"
        fill="#D8D8D8"
      />
      <path
        d="M7.2949 13.2929C8.07382 13.306 8.86146 13.3606 9.6382 13.4151C9.90874 13.9322 10.1902 14.4406 10.4826 14.9468C11.3859 16.5548 12.3655 18.1279 13.2142 19.7665C12.8084 20.4363 12.4179 21.1127 12.0317 21.7934C10.404 19.029 8.80255 16.2472 7.16835 13.4893L7.2949 13.2929Z"
        fill="#33C6E9"
      />
      <path
        d="M7.16835 13.4893C8.80255 16.2472 10.404 19.029 12.0317 21.7934C11.2157 21.7803 10.3473 21.8872 9.54656 21.7323C9.09055 21.1258 8.76327 20.4189 8.37054 19.7708C7.55017 18.3483 6.72325 16.9301 5.90942 15.5053C6.31743 14.8268 6.72107 14.1461 7.16835 13.4893Z"
        fill="#00B8E3"
      />
      <path
        d="M19.1554 13.4828C19.9452 13.5155 20.7351 13.5221 21.5205 13.605C19.8449 16.2821 18.3503 19.077 16.7532 21.8022C16.3648 21.1171 15.9699 20.4341 15.5575 19.7643C16.7008 17.6414 17.951 15.5708 19.1554 13.4828Z"
        fill="#008AAA"
      />
      <path
        d="M119.167 14.5191C120.168 15.9417 121.183 17.3555 122.195 18.7694C121.346 18.7694 120.417 18.6057 119.588 18.7934C119.455 19.0006 119.326 19.2079 119.202 19.4217C118.453 18.357 117.663 17.3272 116.891 16.2821C117.628 15.6668 118.383 15.0777 119.167 14.5191Z"
        fill="#646464"
      />
      <path
        d="M67.049 14.866C67.5552 15.9308 68.1509 16.533 69.2309 17.0173C68.5698 17.7242 67.9632 18.4704 67.385 19.2472C65.9734 18.525 64.8803 17.3991 64.2737 15.9264C65.1836 15.5249 66.0977 15.1606 67.049 14.866Z"
        fill="#A3A3A3"
      />
      <path
        d="M73.7538 15.5424C74.3822 16.1773 75.0193 16.8013 75.604 17.4755C74.6418 18.5425 73.4789 19.3716 72.0673 19.7163C70.516 20.0697 68.836 19.9127 67.385 19.2472C67.9632 18.4705 68.5698 17.7243 69.2309 17.0173C70.1712 17.2224 71.1225 17.2355 72.0236 16.8515C72.7305 16.557 73.2084 16.0573 73.7538 15.5424Z"
        fill="#9C9C9C"
      />
      <path
        d="M109.222 15.597C109.787 16.9781 110.492 18.3177 110.93 19.7447C109.92 19.7774 108.91 19.7709 107.902 19.7447C108.277 18.3788 108.5 16.8232 109.222 15.597Z"
        fill="#6D6D6D"
      />
      <path
        d="M46.0488 15.9504C46.051 16.3562 46.0662 16.7599 46.0793 17.1635C48.2328 17.2355 50.3972 17.0915 52.5463 17.2399C52.555 18.0624 52.5594 18.885 52.5572 19.7097C51.2568 19.8297 49.9739 19.7468 48.6714 19.7643C46.8604 19.7534 45.0451 19.7926 43.2342 19.7337C43.2298 18.6275 43.2364 17.5213 43.2211 16.4151C43.3564 16.3562 43.4917 16.2973 43.6291 16.2406C44.432 16.1162 45.2437 16.0639 46.0488 15.9504Z"
        fill="#B6B6B6"
      />
      <path
        d="M79.8194 17.1133C81.2943 17.1177 82.767 17.1133 84.2398 17.1046C84.5387 17.1439 84.842 17.0261 85.0187 17.3163C85.106 18.1083 85.0492 18.9177 85.0601 19.7141C82.6514 19.8363 80.2274 19.7359 77.8142 19.7643C78.4361 18.9286 79.2019 18.2435 79.9066 17.4843C79.8761 17.3599 79.8477 17.2377 79.8194 17.1133Z"
        fill="#919191"
      />
      <path
        d="M40.7294 17.7024C41.216 18.3482 41.6851 19.0006 42.0495 19.7272C40.9716 19.7817 39.896 19.7664 38.8203 19.7643C38.6458 19.5526 38.4712 19.341 38.2989 19.1272C39.0451 18.5359 39.8807 18.1192 40.7294 17.7024Z"
        fill="#B8B8B8"
      />
      <path
        d="M60.2111 17.8879C60.2679 18.5054 60.3202 19.1228 60.3704 19.7403C59.4868 19.7708 58.6031 19.7817 57.7173 19.7316C58.494 19.0137 59.2271 18.3199 60.2111 17.8879Z"
        fill="#A0A0A0"
      />
      <path
        d="M114.65 17.8552C114.657 18.4879 114.65 19.1185 114.639 19.749C113.675 19.7621 112.713 19.7752 111.751 19.7381C111.949 19.3781 112.002 19.0072 112.388 18.8086C113.079 18.3941 113.895 18.1344 114.65 17.8552Z"
        fill="#636363"
      />
      <path
        d="M122.195 18.7694C122.346 18.9788 122.492 19.1883 122.636 19.4021V19.749C121.623 19.7381 120.591 19.8276 119.586 19.7185L119.202 19.4218C119.326 19.2079 119.455 19.0007 119.588 18.7934C120.417 18.6057 121.346 18.7694 122.195 18.7694Z"
        fill="#585858"
      />
      <path
        d="M3.51378 19.7512C5.13052 19.7927 6.75163 19.7643 8.37056 19.7709C8.76329 20.4189 9.09057 21.1258 9.54657 21.7323C10.3473 21.8872 11.2157 21.7803 12.0317 21.7934C12.4179 21.1127 12.8084 20.4363 13.2143 19.7665C13.9954 19.7752 14.7765 19.773 15.5576 19.7643C15.9699 20.4341 16.3648 21.1171 16.7532 21.8022C17.5714 21.8 18.3918 21.8 19.21 21.8087C19.6223 21.1389 20.0172 20.4581 20.41 19.7774C22.0224 19.7883 23.6391 19.7425 25.2537 19.797C23.9555 21.8152 22.8776 23.9796 21.5838 26H7.19237C5.91817 23.9469 4.81198 21.7869 3.51378 19.7512Z"
        fill="#4D4D4D"
      />
    </SvgIcon>
  );
});
