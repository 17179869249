import { memo } from "react";

import {
  ResponsiveScatterPlot,
  type ScatterPlotDatum,
  type ScatterPlotNodeData,
  type ScatterPlotRawSerie,
  type ScatterPlotSvgProps,
  type ScatterPlotValue,
} from "@nivo/scatterplot";
import styled, { useTheme } from "styled-components";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import {
  FindingSeverityLabel,
  FindingSeverityNameToIntMap,
  SeverityToColor,
} from "data/finding";

import { type StaleFindingDatum } from "../lib/get_scatter_data";

// -----------------------------------------------------------------------------

const ScatterPlotConfig: Omit<
  ScatterPlotSvgProps<StaleFindingDatum>,
  "data" | "width" | "height"
> = {
  colors: getNodeColor,
  nodeSize: getNodeSize,
  margin: { top: 20, right: 40, bottom: 70, left: 110 },
  layers: ["grid", "axes", "nodes"],
  yScale: { type: "linear", min: 0, max: 5 },
  xScale: { type: "linear", min: 30 },
  axisTop: null,
  axisRight: null,
  axisBottom: {
    tickValues: 14,
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "Age (days)",
    legendPosition: "middle",
    legendOffset: 50,
  },
  enableGridX: false,
  // not sure why this works for the IAM scatterplot but not this one
  // gridYValues: [0, 1, 2, ,3, 4, 5],
  gridYValues: 6,
  axisLeft: {
    tickValues: [0, 1, 2, 3, 4, 5],
    format: (y: ScatterPlotValue) => FindingSeverityLabel[y as number],
    tickSize: 1,
    tickPadding: 5,
    tickRotation: 0,
    legend: "Severity",
    legendPosition: "middle",
    legendOffset: -80,
  },
};

export const StaleScatterPlotDisplay = memo(
  ({ title, data = [], loading = false, median = 0 }: Props) => {
    const theme = useTheme();

    const scatterplotThemeing = {
      axis: {
        legend: {
          text: {
            fill: theme.scatterPlot.text,
            fontSize: 20,
          },
        },
        ticks: {
          line: {
            stroke: theme.scatterPlot.text,
          },
          text: {
            fontSize: 14,
            fill: theme.scatterPlot.text,
          },
        },
      },
      grid: {
        line: {
          stroke: "#AAAAAA",
          strokeWidth: 0.5,
        },
      },
    };
    const toolTipColor = theme.scatterPlot.toolTipColor;
    const tooltipBackground = theme.scatterPlot.toolTipBackground;
    return (
      <Card>
        <CardContent>
          <Typography variant="overline">{title}</Typography>
          {loading ? (
            <CircularProgress size={20} />
          ) : hasAtLeastOneDatapoint(data) ? (
            <ChartGrid>
              <ChartBox>
                <ResponsiveScatterPlot
                  theme={scatterplotThemeing}
                  data={data}
                  {...ScatterPlotConfig}
                  blendMode={theme.scatterPlot.blendMode}
                  tooltip={({ node }) => (
                    <div
                      style={{
                        color: toolTipColor,
                        background: tooltipBackground,
                        padding: "12px 16px",
                      }}
                    >
                      {`Age (days): ${node.formattedX}`}
                      <br />
                      {`Severity: ${
                        FindingSeverityLabel[node.formattedY as number]
                      }`}
                      <br />
                      {`Count: ${node.data.count}`}
                    </div>
                  )}
                />
              </ChartBox>
              <TextBox>
                <Centered variant="body1">Median Stale Length:</Centered>
                <Typography variant="h6">{median} days</Typography>
              </TextBox>
            </ChartGrid>
          ) : (
            <Typography variant="h5" fontWeight="bold">
              None
            </Typography>
          )}
        </CardContent>
      </Card>
    );
  }
);

// -----------------------------------------------------------------------------

function getNodeColor(node: { serieId: string | number }) {
  return serieIdToSeverityColor(node.serieId as number);
}

function serieIdToSeverityColor(id: number): string {
  return SeverityToColor[FindingSeverityNameToIntMap[id]];
}

// Scale up small counts so they are visible on the chart
function getNodeSize(
  node: Omit<ScatterPlotNodeData<StaleFindingDatum>, "size" | "color">
) {
  return node.data.count * 2 + 10;
}

function hasAtLeastOneDatapoint(
  data: Array<ScatterPlotRawSerie<ScatterPlotDatum>>
) {
  return data.some(categoriesHaveData);
}

function categoriesHaveData(category: ScatterPlotRawSerie<ScatterPlotDatum>) {
  return category.data.length > 0;
}

// -----------------------------------------------------------------------------

const ChartGrid = styled(Grid)`
  display: flex;
  width: 100%;
`;

const ChartBox = styled(Box)`
  border-radius: 5px;
  height: 350px;
  width: 90%;
`;

const TextBox = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 16px;
`;

const Centered = styled(Typography)`
  text-align: center;
`;

// -----------------------------------------------------------------------------

interface Props {
  title;
  data: Array<ScatterPlotRawSerie<StaleFindingDatum>>;
  loading?: boolean;
  median: number | undefined;
}
