console.log(`'########:'##::::'##:'########::'########::::'###::::'########:'##:::'##:'########:'##:::'##:
... ##..:: ##:::: ##: ##.... ##: ##.....::::'## ##:::... ##..:: ##::'##:: ##.....::. ##:'##::
::: ##:::: ##:::: ##: ##:::: ##: ##::::::::'##:. ##::::: ##:::: ##:'##::: ##::::::::. ####:::
::: ##:::: #########: ########:: ######:::'##:::. ##:::: ##:::: #####:::: ######:::::. ##::::
::: ##:::: ##.... ##: ##.. ##::: ##...:::: #########:::: ##:::: ##. ##::: ##...::::::: ##::::
::: ##:::: ##:::: ##: ##::. ##:: ##::::::: ##.... ##:::: ##:::: ##:. ##:: ##:::::::::: ##::::
::: ##:::: ##:::: ##: ##:::. ##: ########: ##:::: ##:::: ##:::: ##::. ##: ########:::: ##::::
:::..:::::..:::::..::..:::::..::........::..:::::..:::::..:::::..::::..::........:::::..:::::
`);

// -----------------------------------------------------------------------------

export {};
