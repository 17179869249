import { memo } from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Skeleton from "@mui/material/Skeleton";

// -----------------------------------------------------------------------------

export const GhostEnvironmentConnectedSource = memo(() => {
  return (
    <Card
      sx={{
        padding: "12px",
        display: "flex",
      }}
    >
      <Skeleton
        variant="circular"
        sx={{
          width: 42,
          height: 42,
          marginRight: 1,
        }}
      />
      <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <Skeleton variant="text" sx={{ maxWidth: 256, width: "100%" }} />
        <Skeleton variant="text" sx={{ maxWidth: 200, width: "100%" }} />
      </Box>
    </Card>
  );
});
