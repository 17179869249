import { SplunkWebhookCoverImage } from "./CoverImage";

// ----------------------------------------------------------------------------- ∏

export const SplunkWebhookAppConfig = {
  name: "splunk_webhook",
  label: "Splunk Webhooks",
  singularLabel: "Splunk Webhook",
  coverImage: SplunkWebhookCoverImage,
  searchTerms: [],
  url: "splunk_webhook",
  connectInfo: {
    instructions: {
      example: "Splunk self-hosted instance",
    },
    placeholder:
      "https://subdomain.example.com:8080/12345678-abcd-efab-cdef-1234567890ab/services/collector/event",
    // Disable this check so we match the backend regex exactly.
    // eslint-disable-next-line no-useless-escape
    regex:
      /^https:\/\/[a-zA-Z0-9-.]+\.[a-zA-Z]{2,6}(:\d{1,5})?\/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}\/services\/collector\/event$/,
    path: "splunk/webhook",
  },
  notifications: true,
  notificationKey: "splunk_webhook",
};
