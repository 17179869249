import { type ReactNode } from "react";

// -----------------------------------------------------------------------------

export const Render = ({ if: renderIf, children }: Props) => {
  return renderIf ? <>{children}</> : null;
};

// -----------------------------------------------------------------------------

interface Props {
  if: boolean;
  children: ReactNode;
}
