import { memo, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { captureMessage } from "@sentry/react";
import { ReturnIf } from "babel-plugin-transform-functional-return";

import LoadingButton from "@mui/lab/LoadingButton";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { type FOTS } from "data/typescript";

import { useConnectionStore } from "stores/ConnectionStore";
import { SubmitState } from "stores/lib";
import {
  useNotificationStore,
  type NotificationConfig,
} from "stores/NotificationStore";

import { notifyError, notifySuccess } from "utility/notify";

import { usePageTitle } from "effect/use_page_title";

import { NotificationConfigItem } from "Component/NotificationConfig";
import { Page } from "Component/Page";
import { PageContent } from "Component/PageContent";
import { PageHeader } from "Component/PageHeader";
import { PageHeaderBackButton } from "Component/PageHeaderBackButton";
import { PageSubTitle } from "Component/PageSubTitle";
import { PageTitle } from "Component/PageTitle";

// -----------------------------------------------------------------------------

export const PagerDutyConfigurePage = memo(() => {
  usePageTitle("Environment → Destinations → PagerDuty → Configure");

  //
  const [notificationStore, notificationActions] = useNotificationStore();
  const [connectionStore, connectionActions] = useConnectionStore();
  const { uuid } = useParams();

  const [basicService, setBasicService] = useState<PagerDutyService>({
    name: "",
    serviceId: "",
  });
  const [basicEnable, setBasicEnable] = useState(false);
  const [basicError, setBasicError] = useState(false);

  const [findingService, setFindingService] = useState<PagerDutyService>({
    name: "",
    serviceId: "",
  });
  const [findingEnable, setFindingEnable] = useState(false);
  const [findingError, setFindingError] = useState(false);

  const [PagerDutyServices, setPagerDutyServices] = useState<
    PagerDutyService[]
  >([]);
  const [loading, setLoading] = useState(false);

  //
  useEffect(() => {
    const config = structuredClone(
      notificationStore.config
    ) as NotificationConfig;
    ReturnIf(!config || !uuid);

    let basicService: PagerDutyService = { name: "", serviceId: "" };
    let findingService: PagerDutyService = { name: "", serviceId: "" };

    switch (notificationStore.fetchingState) {
      case SubmitState.STARTED:
      case SubmitState.STARTED_AGAIN:
      case SubmitState.SUCCESS:
        config.basic_notification.outputs.pagerduty.destinations.forEach(
          (destination) => {
            if (
              destination.connection === uuid &&
              destination.specific_location
            ) {
              basicService = PagerDutyServices.find(
                (x) => x.serviceId === destination.specific_location
              ) ?? {
                name: "",
                serviceId: "",
              };
            }
          }
        );
        config.finding_notification.outputs.pagerduty.destinations.forEach(
          (destination) => {
            if (
              destination.connection === uuid &&
              destination.specific_location
            ) {
              findingService = PagerDutyServices.find(
                (x) => x.serviceId === destination.specific_location
              ) ?? {
                name: "",
                serviceId: "",
              };
            }
          }
        );

        setBasicService(basicService);
        setBasicEnable(basicService.serviceId.length > 0);
        setFindingService(findingService);
        setFindingEnable(findingService.serviceId.length > 0);
        break;

      case SubmitState.ERROR:
        captureMessage("Pagerduty notification fetching error");
    }
  }, [
    notificationStore.fetchingState,
    notificationStore.config,
    PagerDutyServices,
    uuid,
  ]);

  useEffect(() => {
    const currConnection = connectionStore.connections.get(uuid ?? "") as FOTS;
    ReturnIf(currConnection === undefined);

    const stateDetailsStr: string =
      currConnection.clientSecret?.stateDetails ?? "{}";
    const stateDetails = JSON.parse(stateDetailsStr);
    const services = stateDetails.services ?? {};
    const pdServices: PagerDutyService[] = [];
    for (const id in services) {
      pdServices.push({
        name: services[id],
        serviceId: id,
      });
    }
    setPagerDutyServices(pdServices);
  }, [connectionStore, setPagerDutyServices, uuid]);

  // Fetch a fresh config to ensure no stale data
  useEffect(() => {
    notificationActions.fetchConfig();
  }, [notificationActions]);

  useEffect(() => {
    connectionActions.loadConnections({ reload: true });
  }, [connectionActions]);

  const handleBasicChange = (event: any, value: any) => {
    setBasicError(false);
    setBasicService(value ?? "");
  };

  const handleFindingChange = (event: any, value: any) => {
    setFindingError(false);
    setFindingService(value ?? "");
  };

  const onSuccess = () => {
    setLoading(false);
    notifySuccess("Successfully saved the notification settings");
    notificationActions.fetchConfig();
  };

  const onError = () => {
    setLoading(false);
    notifyError("Something went wrong saving the notification settings");
  };

  const saveConfig = () => {
    setLoading(true);
    setBasicError(false);
    setFindingError(false);
    let error = false;

    ReturnIf(!uuid);

    const newConfig: Partial<NotificationConfig> = {};

    if (basicEnable) {
      if (!basicService) {
        setBasicError(true);
        error = true;
      } else {
        newConfig.basic_notification = {
          outputs: {
            pagerduty: {
              enabled: true,
              destinations: [
                {
                  filter: null,
                  connection: uuid as string,
                  specific_location: basicService.serviceId,
                },
              ],
            },
          },
        };
      }
    }

    if (findingEnable) {
      if (!findingService) {
        setFindingError(true);
        error = true;
      } else {
        newConfig.finding_notification = {
          outputs: {
            pagerduty: {
              enabled: true,
              destinations: [
                {
                  filter: null,
                  connection: uuid as string,
                  specific_location: findingService.serviceId,
                },
              ],
            },
          },
        };
      }
    }

    ReturnIf(error, setLoading(false));

    const config =
      newConfig.basic_notification ?? newConfig.finding_notification
        ? newConfig
        : null;

    notificationActions.updateConfig(
      config,
      uuid as string,
      onSuccess,
      onError
    );
  };

  return (
    <Page>
      <PageHeader>
        <PageHeaderBackButton to="/environment/destinations">
          Back to Destinations
        </PageHeaderBackButton>

        <PageTitle>PagerDuty</PageTitle>
        <PageSubTitle>
          Looking to fine-tune your PagerDuty notifications? You&apos;ve come to
          the right place! With our notification configuration page, you can
          tailor your PagerDuty experience to better suit your needs. Once
          you&apos;re happy with your settings, simply hit the &quot;Save&quot;
          button and you&apos;re all set!
        </PageSubTitle>
      </PageHeader>

      <PageContent>
        <Stack spacing={2}>
          <NotificationConfigItem
            enabled={basicEnable}
            label={"Basic Notifications"}
            description={
              <Typography variant="body1">
                Basic Notifications include all things related to your account
                lifecycle (such as billing) and other common platform features
              </Typography>
            }
            setEnabled={setBasicEnable}
          >
            {basicEnable ? (
              <>
                <Typography>
                  Input the <strong>Channel</strong> you would like to send
                  basic notifications to.
                </Typography>

                <FormControl required fullWidth error={basicError}>
                  <Autocomplete
                    id="basic-service"
                    options={PagerDutyServices}
                    getOptionLabel={(option: PagerDutyService) => option.name}
                    value={basicService}
                    defaultValue={PagerDutyServices[0]}
                    onChange={handleBasicChange}
                    renderInput={(params) => (
                      <TextField
                        required
                        error={basicError}
                        {...params}
                        label="PagerDuty Service"
                      />
                    )}
                  />

                  {basicError ? (
                    <FormHelperText>Required</FormHelperText>
                  ) : null}
                </FormControl>
              </>
            ) : null}
          </NotificationConfigItem>

          <NotificationConfigItem
            enabled={findingEnable}
            label={"Finding Notifications"}
            description={
              <Typography variant="body1">
                Notifications for security findings can be configured in a
                variety of ways, including the ability to receive a real-time
                notification whenever a new security finding is generated. We
                highly recommend that you configure your notifications to
                include security findings. This way, you will be alerted as soon
                as possible if there are any potential threats to your company
                that ThreatKey has detected.
              </Typography>
            }
            setEnabled={setFindingEnable}
          >
            {findingEnable ? (
              <>
                <Typography>
                  Input the <strong>Channel</strong> you would like to send
                  finding notifications to.
                </Typography>

                <FormControl required fullWidth error={findingError}>
                  <Autocomplete
                    disablePortal
                    id="finding-service"
                    options={PagerDutyServices}
                    getOptionLabel={(option: PagerDutyService) => option.name}
                    value={findingService}
                    defaultValue={PagerDutyServices[0]}
                    onChange={handleFindingChange}
                    renderInput={(params) => (
                      <TextField
                        required
                        error={findingError}
                        {...params}
                        label="PagerDuty Service"
                      />
                    )}
                  />

                  {findingError ? (
                    <FormHelperText>Required</FormHelperText>
                  ) : null}
                </FormControl>
              </>
            ) : null}
          </NotificationConfigItem>

          <Stack sx={{ mt: 2 }} spacing={2} direction="row">
            <LoadingButton
              variant="contained"
              onClick={saveConfig}
              loading={loading}
            >
              {"Save"}
            </LoadingButton>
            <Link
              to="/environment/destinations"
              style={{ textDecoration: "none" }}
            >
              <Button variant="text">{"Cancel"}</Button>
            </Link>
          </Stack>
        </Stack>
      </PageContent>
    </Page>
  );
});

// -----------------------------------------------------------------------------

interface PagerDutyService {
  name: string;
  serviceId: string;
}
