import { Link } from "react-router-dom";

import { type ExtendButtonBase } from "@mui/material";
import styled from "styled-components";

import Tab, { type TabTypeMap } from "@mui/material/Tab";

import { type FOTS } from "data/typescript";

// -----------------------------------------------------------------------------

export const LinkTab: ExtendButtonBase<TabTypeMap<{ to: string }, "div">> =
  styled(Tab).attrs(() => ({ component: Link }) as FOTS)`
    text-decoration: none;
  `;
