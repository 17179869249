import { memo } from "react";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { type Connection } from "stores/ConnectionStore";

import { DefinitionList } from "Component/DefinitionList";

// -----------------------------------------------------------------------------

export const JiraConfigInfo = memo(({ connection }: Props) => {
  const url = `https://${connection.config.tld ?? ""}`;
  return (
    <Paper elevation={1} sx={{ p: 1.5 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          flexWrap: "wrap",
          mb: 2,
        }}
      >
        <Typography variant="h6">Static information</Typography>
      </Box>
      <DefinitionList
        dtProps={{ xs: 12, md: 3, fontWeight: "bold" }}
        ddProps={{ xs: 12, md: 9 }}
        items={[
          {
            dt: "Client Key",
            dd: connection.config.clientKey,
          },
          {
            dt: "Jira Instance",
            dd: <Link href={url}>{url}</Link>,
          },
        ]}
      />
      <Typography variant="body1">
        To view any Jira issues associated with this instance, query for the
        issue property we store on associated Jira issues:{" "}
        <code>
          <Link
            href={`https://${
              connection.config.tld ?? ""
            }/issues/?jql=issue.property%5Bthreatkey%5D.finding%20IS%20NOT%20NULL%20order%20by%20updated%20DESC`}
            target="_blank"
            rel="noopener noreferrer"
          >
            issue.property[threatkey].finding IS NOT NULL order by updated DESC
          </Link>
        </code>
        .
      </Typography>
    </Paper>
  );
});

// -----------------------------------------------------------------------------

interface Props {
  connection: Connection;
}
