import { memo } from "react";

import { type ComputedDatum } from "@nivo/pie";

import Box from "@mui/material/Box";

import {
  FindingSeverityNameToIntMap,
  SeverityToColor,
  SourceLabel,
} from "data/finding";

import { type PieDataProps } from "../../lib/get_src_sev_pie_data";

// -----------------------------------------------------------------------------

export const PieChartTooltip = memo(({ datum }: Props) => {
  const severities = Object.entries(datum.data.severity);

  //
  return (
    <Box
      sx={{
        backgroundColor: "primary.background",
        padding: "9px 12px",
        border: "1px solid #ccc",
        borderRadius: "2px",
      }}
    >
      <div>
        {SourceLabel[datum.data.source]}: {datum.data.value}
      </div>
      {severities.map((singleSev) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
            key={`sev-${singleSev[0]}-${datum.data.source}`}
          >
            <div
              style={{
                height: 10,
                width: 10,
                backgroundColor:
                  SeverityToColor[FindingSeverityNameToIntMap[singleSev[0]]],
              }}
            ></div>
            {`${singleSev[1]} ${singleSev[0]}`}
          </div>
        );
      })}
    </Box>
  );
});

// -----------------------------------------------------------------------------

interface Props {
  datum: ComputedDatum<PieDataProps>;
}
