import { createContext } from "react";

import type { PaletteOptions } from "@mui/material";

import lightJson from "./data/light.json";

// -----------------------------------------------------------------------------

export enum ThemeMode {
  Automatic = 0,
  Dark = 1,
  Light = 2,
}

// -----------------------------------------------------------------------------

export const ThemeContext = createContext<Props>({
  themeMode: ThemeMode.Light,
  finalThemeMode: ThemeMode.Light,
  setThemeMode: () => {},
  palette: lightJson,
});

// -----------------------------------------------------------------------------

interface Props {
  themeMode: ThemeMode;
  finalThemeMode: ThemeMode;
  setThemeMode: (themeMode: ThemeMode) => void;
  palette: PaletteOptions;
}
