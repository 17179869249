import styled from "styled-components";

import { type FOTS } from "data/typescript";

// -----------------------------------------------------------------------------

export const PageHeader = styled.div.attrs(({ hasTabs }: FOTS) => ({
  hasTabs,
}))`
  background-color: ${({ theme }) => theme.primary.background};
  border-bottom: 1px rgba(0, 0, 0, 0.12) solid;
  padding: clamp(8px, 0.5rem + (1vw - 3.75px) * 1.9394, 24px)
    clamp(8px, 0.5rem + (1vw - 3.75px) * 1.9394, 24px);
  display: flex;
  gap: clamp(4px, calc(0.25rem + ((1vw - 3.75px) * 0.4848)), 8px);
  min-height: 0vw;
  flex-direction: column;

  ${({ hasTabs }) => (hasTabs ? { paddingBottom: "0" } : {})}
`;
