import { memo, type MouseEventHandler, useState } from "react";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { ServiceConnectionPhase } from "Component/ServiceConnectionPhase";

// -----------------------------------------------------------------------------

const OktaDomainRegex = /^(https:\/\/[\w-].+\.okta\.com)/;

// -----------------------------------------------------------------------------

export const OktaConnectPageConfiguringPhase = memo(
  ({
    disabled,
    organization,
    apiToken,
    mode,
    onChangeOrganization,
    onChangeApiToken,
    onChangeMode,
    onNext,
  }: Props) => {
    const [errorOrganization, setErrorOrganization] = useState("");
    const [errorApiToken, setErrorApiToken] = useState("");
    const [adminUrl, setAdminUrl] = useState(false);

    //
    return (
      <ServiceConnectionPhase disabled={disabled}>
        <Stack spacing={1.5} direction="column">
          <Typography variant="body1">
            Please provide the <strong>Okta organization URL</strong> and the{" "}
            <strong>API token</strong> associated with the account you&apos;d
            like to connect to ThreatKey.
          </Typography>
          <TextField
            fullWidth
            label="Okta Organization URL"
            placeholder="https://your-org-name.okta.com"
            value={organization}
            onChange={(event) => {
              let newOrganization = event.target.value.trim();
              if (newOrganization.indexOf("https://") !== 0) {
                newOrganization = "https://" + newOrganization;
              }
              const orgURLValid = !(
                newOrganization.match(OktaDomainRegex) == null
              );

              onChangeOrganization(event.target.value);
              setErrorOrganization(orgURLValid ? "" : "Invalid OKTA URL");

              setAdminUrl(newOrganization.includes("-admin.okta.com"));
            }}
            error={errorOrganization !== ""}
            helperText={errorOrganization}
            data-private="lipsum"
          />
          <TextField
            fullWidth
            label="API Token"
            placeholder="00q2vRRmTYDHid5..."
            value={apiToken}
            onChange={(event) => {
              onChangeApiToken(event.target.value);
              setErrorApiToken("");
            }}
            onBlur={() => {
              setErrorApiToken(
                apiToken.trim() === "" ? "Invalid API Token" : ""
              );
            }}
            error={errorApiToken !== ""}
            helperText={errorApiToken}
            data-private="lipsum"
          />
          <FormControl sx={{ pt: 1 }}>
            <FormLabel>App Permissions</FormLabel>
            <RadioGroup
              value={mode}
              onChange={(event) => onChangeMode(event.target.value)}
            >
              <FormControlLabel
                value="readwrite"
                control={<Radio />}
                label="Read-write permissions"
              />
              <FormControlLabel
                value="readonly"
                control={<Radio />}
                label="Read-only permissions (disables fixes)"
              />
            </RadioGroup>
          </FormControl>
          <Typography variant="body1">
            The connection process uses the API token of a super administrator
            to create an App with the appropriate permissions. This API token
            must have <strong>Super Administrator</strong> privileges. The API
            key can be deleted afterwards, and the App will continue to
            function. The App will be named{" "}
            <strong>ThreatKey Integration</strong>. The integration makes a
            number of checks, so please stay on the page as it performs these
            checks. This may take a few minutes.
          </Typography>

          {adminUrl ? (
            <Alert severity="warning">
              You are using the Okta admin URL, we will remove the
              &apos;-admin&apos; from your URL on connection. This is to ensure
              a consistent experience across all Okta tenants. No other changes
              will be made.
            </Alert>
          ) : null}
        </Stack>

        <Box sx={{ flexGrow: 0, mt: 2.5 }}>
          <Tooltip
            arrow
            title={
              !organization || !apiToken || !!errorOrganization
                ? "Please fill the form out correctly"
                : ""
            }
          >
            <span>
              <Button
                disabled={!organization || !apiToken || !!errorOrganization}
                variant="contained"
                onClick={onNext as MouseEventHandler<HTMLButtonElement>}
              >
                Connect to Okta
              </Button>
            </span>
          </Tooltip>
        </Box>
      </ServiceConnectionPhase>
    );
  }
);

// -----------------------------------------------------------------------------

interface Props {
  disabled: boolean;
  organization: string;
  apiToken: string;
  mode: "readwrite" | "readonly";
  onChangeOrganization: Function;
  onChangeApiToken: Function;
  onChangeMode: Function;
  onNext: Function;
}
