import { type GridColumns } from "@mui/x-data-grid-premium";
import styled from "styled-components";

import Skeleton from "@mui/material/Skeleton";

// -----------------------------------------------------------------------------

export function generateLoadingColumns(columns: GridColumns) {
  return columns.map((value) => ({ ...value, renderCell: Cell }));
}

export function generateLoadingRows(count: number, id: string) {
  const rows = [];
  for (let i = 1; i <= count; i++) {
    rows.push({ [id]: i });
  }

  //
  return rows;
}

// -----------------------------------------------------------------------------

function Cell() {
  return <SkeletonStyled />;
}

// -----------------------------------------------------------------------------

const SkeletonStyled = styled(Skeleton)`
  width: 100%;
`;
