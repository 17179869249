import { captureException, type SeverityLevel } from "@sentry/react";
// -----------------------------------------------------------------------------

export function captureSentryException(
  error: Error,
  transactionName: string,
  level: SeverityLevel = "error"
) {
  captureException(error, (scope) => {
    scope.setTransactionName(transactionName);
    scope.setLevel(level);

    //
    return scope;
  });
}
