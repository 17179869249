import { memo } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

// -----------------------------------------------------------------------------

export const MagicLinkRedirect = memo(({ redirectUrl }: Props) => {
  //
  return (
    <Box sx={{ textAlign: "center" }}>
      <Typography variant="h5" fontWeight="bold">
        Redirect
      </Typography>

      <Typography variant="body1">
        You&apos;ll be redirected to passwordless login after pressing continue.
      </Typography>

      <Box sx={{ mt: 2 }}>
        <Link href={redirectUrl}>
          <Button fullWidth variant="contained">
            Continue
          </Button>
        </Link>
      </Box>
    </Box>
  );
});

// -----------------------------------------------------------------------------

interface Props {
  redirectUrl: string;
}
