import { memo, useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { captureMessage } from "@sentry/react";
import { ReturnIf } from "babel-plugin-transform-functional-return";

import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { SubmitState } from "stores/lib";
import {
  useNotificationStore,
  type NotificationConfig,
} from "stores/NotificationStore";

import { type Service } from "utility/connections";
import { notifyError, notifySuccess } from "utility/notify";

import { usePageTitle } from "effect/use_page_title";

import { WebhookAppConfig } from "external/destination/Webhook";
import { AvailableWebhookAppConfigs } from "external/destination/Webhook/data";

import { NotificationConfigItem } from "Component/NotificationConfig";
import { Page } from "Component/Page";
import { PageContent } from "Component/PageContent";
import { PageHeader } from "Component/PageHeader";
import { PageHeaderBackButton } from "Component/PageHeaderBackButton";
import { PageSubTitle } from "Component/PageSubTitle";
import { PageTitle } from "Component/PageTitle";

// -----------------------------------------------------------------------------

export const WebhookConfigurePage = memo(() => {
  const { name = "Invalid", uuid } = useParams();

  const [notificationStore, notificationActions] = useNotificationStore();

  const [findingEnable, setFindingEnable] = useState(false);
  const [loading, setLoading] = useState(false);

  const webhook = useMemo(
    () =>
      name === "webhook"
        ? WebhookAppConfig
        : (AvailableWebhookAppConfigs.find(
            (service) => service.url === name
          ) as Service),
    [name]
  );

  //
  usePageTitle(
    `Environment → Destinations → ${webhook?.label ?? name} → Configure`
  );

  //
  useEffect(() => {
    const config = structuredClone(
      notificationStore.config
    ) as NotificationConfig;
    ReturnIf(!config || !uuid || !webhook);

    let enabled = false;

    switch (notificationStore.fetchingState) {
      case SubmitState.STARTED:
      case SubmitState.STARTED_AGAIN:
      case SubmitState.SUCCESS: {
        ReturnIf(!webhook.notificationKey);

        const notificationKey = webhook.notificationKey as string;
        config.finding_notification.outputs[
          notificationKey
        ].destinations.forEach((destination) => {
          if (destination.connection === uuid) {
            enabled = true;
          }
        });

        setFindingEnable(enabled);
        break;
      }

      case SubmitState.ERROR:
        captureMessage(`${webhook.label} notification fetching error`);
    }
  }, [
    notificationStore.fetchingState,
    notificationStore.config,
    webhook,
    uuid,
  ]);

  // Fetch a fresh config to ensure no stale data
  useEffect(() => {
    notificationActions.fetchConfig();
  }, [notificationActions]);

  //
  if (
    webhook === undefined ||
    webhook.connectInfo === undefined ||
    webhook.notificationKey === undefined
  ) {
    return (
      <Page>
        <PageHeader>
          <PageHeaderBackButton to="/environment/destinations">
            Back to Destinations
          </PageHeaderBackButton>

          <PageTitle>Invalid Destination</PageTitle>
        </PageHeader>

        <PageContent>
          Return to <Link to="/environment/destinations">Destinations</Link>
        </PageContent>
      </Page>
    );
  }

  //
  const saveConfig = () => {
    setLoading(true);
    const error = false;

    ReturnIf(!uuid || !webhook.notificationKey);

    const newConfig: Partial<NotificationConfig> = {};

    if (findingEnable) {
      const notificationKey = webhook.notificationKey as string;
      newConfig.finding_notification = {
        outputs: {
          [notificationKey]: {
            enabled: true,
            destinations: [
              {
                filter: null,
                connection: uuid as string,
                specific_location: null,
              },
            ],
          },
        },
      };
    }

    ReturnIf(error, setLoading(false));

    const config = newConfig.finding_notification ? newConfig : null;
    notificationActions.updateConfig(
      config,
      uuid as string,
      onSuccess,
      onError
    );
  };

  const onSuccess = () => {
    setLoading(false);
    notifySuccess("Successfully saved the notification settings");
    notificationActions.fetchConfig();
  };

  const onError = () => {
    setLoading(false);
    notifyError("Something went wrong saving the notification settings");
  };

  //
  return (
    <Page>
      <PageHeader>
        <PageHeaderBackButton to="/environment/destinations">
          Back to Destinations
        </PageHeaderBackButton>
        <PageTitle>
          {webhook.singularLabel ?? webhook.label} Notifications
        </PageTitle>
        <PageSubTitle>
          Configuring your {webhook.label} couldn&apos;t be easier. Simply enter
          the URL of your {webhook.singularLabel ?? webhook.label} endpoint and
          we&apos;ll take care of the rest. We&apos;ll send all of the data you
          need to keep your application in sync with our platform.
        </PageSubTitle>
      </PageHeader>

      <PageContent>
        <Stack spacing={2}>
          <NotificationConfigItem
            enabled={findingEnable}
            label={"Finding Notifications"}
            description={
              <Typography variant="body1">
                Notifications for security findings can be configured in a
                variety of ways, including the ability to receive a real-time
                notification whenever a new security finding is generated. We
                highly recommend that you configure your notifications to
                include security findings. This way, you will be alerted as soon
                as possible if there are any potential threats to your company
                that ThreatKey has detected.
              </Typography>
            }
            setEnabled={setFindingEnable}
          />

          <Stack sx={{ mt: 2 }} spacing={2} direction="row">
            <LoadingButton
              variant="contained"
              onClick={saveConfig}
              loading={loading}
            >
              Save
            </LoadingButton>
            <Link
              to="/environment/destinations"
              style={{ textDecoration: "none" }}
            >
              <Button variant="text">Cancel</Button>
            </Link>
          </Stack>
        </Stack>
      </PageContent>
    </Page>
  );
});
