import React, { memo, useReducer } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import {
  Kubernetes,
  type KubernetesSvcAcctSecret,
  type KubernetesProvider,
  type KubernetesSecretType,
} from "data/kubernetes";

import { ServiceConnectionPhase } from "Component/ServiceConnectionPhase";

// -----------------------------------------------------------------------------

const ClusterResourceIdRegex =
  /^projects\/([a-zA-Z0-9_-]+)\/locations\/([a-zA-Z0-9_-]+)\/clusters\/([a-zA-Z0-9_-]+)$/;

// -----------------------------------------------------------------------------

export const GKEKubernetesConnectPageConfiguringKubernetes = memo(
  ({ disabled, loading, onNext }: Props) => {
    const [data, setData] = useReducer(
      (state: State, changes: Partial<State>): State => ({
        ...state,
        ...changes,
      }),
      {
        provider: Kubernetes.Provider.Gcp as KubernetesProvider,
        secretType: Kubernetes.SecretType.GcpSvcAcct as KubernetesSecretType,
        clusterResourceId: "",
        gcpSvcAcctJson: null, // Update the initial value to null
        errorProvider: "",
        errorSecretType: "",
        errorClusterResourceId: "",
        errorGcpSvcAcctJson: "",
        fileContent: "",
      }
    );

    //
    function changeProvider(event: React.ChangeEvent<{ value: unknown }>) {
      setData({
        provider: event.target.value as KubernetesProvider,
        secretType: "" as KubernetesSecretType,
        clusterResourceId: "",
        gcpSvcAcctJson: null, // Reset uploaded JSON file on provider change
        errorProvider: "",
        errorSecretType: "",
        errorClusterResourceId: "",
        errorGcpSvcAcctJson: "",
        fileContent: "",
      });
    }

    function changeSecretType(event: React.ChangeEvent<{ value: unknown }>) {
      setData({
        secretType: event.target.value as KubernetesSecretType,
      });
    }

    function changeClusterResourceId(
      event: React.ChangeEvent<{ value: unknown }>
    ) {
      validateAndChangeKey(
        setData,
        "clusterResourceId",
        event.target.value as string,
        (value) => ClusterResourceIdRegex.test(value),
        "errorClusterResourceId",
        "Invalid Cluster Resource ID"
      );
    }

    function parseDictFromString(
      dataString: string
    ): KubernetesSvcAcctSecret | null {
      try {
        const parsedData = JSON.parse(dataString);
        return parsedData;
      } catch (error) {
        console.error("Error parsing JSON:", error);
        return null; // Return null in case of parsing error
      }
    }

    function handleFileUpload(event: React.ChangeEvent<HTMLInputElement>) {
      const file = event.target.files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          const fileContent = reader.result as string;
          const gcpSvcAcctJson = parseDictFromString(fileContent);
          setData({
            fileContent,
            gcpSvcAcctJson,
            errorGcpSvcAcctJson: "", // Clear any previous error
          });
        };
        reader.readAsText(file);
      }
    }

    function handleDrop(event: React.DragEvent<HTMLDivElement>) {
      event.preventDefault(); // Prevent default behavior
      const file = event.dataTransfer.files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          const fileContent = reader.result as string;
          const gcpSvcAcctJson = parseDictFromString(fileContent);
          setData({
            fileContent,
            gcpSvcAcctJson,
            errorGcpSvcAcctJson: "", // Clear any previous error
          });
        };
        reader.readAsText(file);
      }
    }

    function submitForm() {
      onNext(data);
    }

    //
    const isSubmitEnabled =
      ClusterResourceIdRegex.test(data.clusterResourceId) &&
      data.gcpSvcAcctJson !== null;

    //
    return (
      <>
        <ServiceConnectionPhase disabled={disabled}>
          <Stack spacing={1.5} direction="column">
            <Typography variant="h5">Provider</Typography>
            <TextField
              fullWidth
              select
              data-private="lipsum"
              label="Provider"
              placeholder="Provider"
              value={data.provider}
              onChange={changeProvider}
              error={data.errorProvider !== ""}
              helperText={data.errorProvider}
              disabled={true}
            >
              <MenuItem value={Kubernetes.Provider.Gcp}>GCP</MenuItem>
            </TextField>
            {data.provider === Kubernetes.Provider.Gcp && (
              <Stack spacing={1.5} direction="column">
                <TextField
                  fullWidth
                  select
                  data-private="lipsum"
                  label="Secret Type"
                  placeholder="Secret Type"
                  value={data.secretType}
                  onChange={changeSecretType}
                  error={data.errorSecretType !== ""}
                  helperText={data.errorSecretType}
                  disabled={true}
                >
                  <MenuItem value={Kubernetes.SecretType.GcpSvcAcct}>
                    Service Account JSON
                  </MenuItem>
                </TextField>
              </Stack>
            )}
          </Stack>
        </ServiceConnectionPhase>

        <>
          <ServiceConnectionPhase disabled={disabled}>
            <Stack spacing={1.5} direction="column">
              <Typography variant="h5">Values</Typography>
              <TextField
                fullWidth
                data-private="lipsum"
                label="Cluster Resource ID"
                placeholder="(e.g., projects/PROJECT_NAME/locations/CLUSTER_ZONE/clusters/CLUSTER_NAME)"
                value={data.clusterResourceId}
                onChange={changeClusterResourceId}
                error={data.errorClusterResourceId !== ""}
                helperText={data.errorClusterResourceId}
                disabled={disabled || loading}
              />
              <input
                type="file"
                accept=".json"
                onChange={handleFileUpload}
                style={{ display: "none" }}
                id="uploadJsonInput"
              />
              <label htmlFor="uploadJsonInput">
                <div
                  onDragEnter={(event) => {
                    event.currentTarget.style.backgroundColor = "#e0e0e08b"; // Change background color when something is dragged over
                  }}
                  onDragOver={(event) => {
                    event.preventDefault(); // Prevent default behavior
                  }}
                  onDragLeave={(event) => {
                    event.currentTarget.style.backgroundColor = ""; // Reset background color when the drag leaves the element
                  }}
                  onDrop={(event) => {
                    handleDrop(event);
                    event.currentTarget.style.backgroundColor = ""; // Reset background color after drop
                  }}
                  style={{
                    border: "2px dashed #aaa",
                    borderRadius: "5px",
                    padding: "20px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  Drag & Drop or Click to Upload GCP Service Account JSON File
                </div>
              </label>
              {data.errorGcpSvcAcctJson && (
                <Typography variant="body2" color="error">
                  {data.errorGcpSvcAcctJson}
                </Typography>
              )}
            </Stack>
          </ServiceConnectionPhase>

          {data.fileContent && (
            <ServiceConnectionPhase disabled={disabled}>
              <Stack spacing={1.5} direction="column">
                <Typography variant="h5">Preview</Typography>
                <pre style={{ overflowX: "auto" }}>{data.fileContent}</pre>
              </Stack>
            </ServiceConnectionPhase>
          )}
        </>

        <ServiceConnectionPhase disabled={disabled}>
          <Alert severity="info">
            The application automatically scans your GKE environment, where it
            continuously monitors various cluster resources and assets. It is
            also designed to self-update in response to any new features or
            asset types introduced in GKE updates.
          </Alert>
          <LoadingButton
            disabled={loading || disabled || !isSubmitEnabled}
            loading={loading}
            variant="contained"
            onClick={submitForm}
            sx={{ mt: 1.5 }}
          >
            Connect to GCP GKE
          </LoadingButton>
        </ServiceConnectionPhase>
      </>
    );
  }
);

// -----------------------------------------------------------------------------

function validateAndChangeKey(
  setData: (changes: Partial<State>) => void,
  key: keyof Pick<State, "clusterResourceId">,
  value: string,
  tester: (value: string) => boolean,
  errorKey: keyof Pick<State, "errorClusterResourceId">,
  error: string
) {
  setData({
    [key]: value,
    [errorKey]: tester(value) ? "" : error,
  });
}

// -----------------------------------------------------------------------------

interface Props {
  disabled: boolean;
  loading: boolean;
  onNext: Function;
}

interface State {
  provider: KubernetesProvider;
  secretType: KubernetesSecretType;
  clusterResourceId: string;
  gcpSvcAcctJson: null | KubernetesSvcAcctSecret; // Type for uploaded JSON file
  fileContent: string;

  errorProvider: string;
  errorSecretType: string;
  errorClusterResourceId: string;
  errorGcpSvcAcctJson: string;
}
