import { memo } from "react";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

// -----------------------------------------------------------------------------

export const OktaConnectPageError = memo(
  ({ show, onTryAgain, children }: Props) => {
    return show ? (
      <Alert severity="error">
        {children ?? "There was an error"}
        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="error"
            onClick={onTryAgain as React.MouseEventHandler<HTMLButtonElement>}
          >
            Reattempt Connection
          </Button>
        </Box>
      </Alert>
    ) : null;
  }
);

// -----------------------------------------------------------------------------

interface Props {
  show: boolean;
  onTryAgain: Function;
  children: React.ReactNode;
}
