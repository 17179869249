import { type Action, createHook, createStore } from "react-sweet-state";

// -----------------------------------------------------------------------------

const url = new URL(window.location.href);
const celebrate = url.searchParams.get("celebrate");
const forceCelebration = celebrate !== null && celebrate !== "";

if (forceCelebration) {
  // remove from url
  url.searchParams.delete("celebrate");
  window.history.replaceState(null, "", url.toString());
}

// -----------------------------------------------------------------------------

export const initialState: State = {
  celebration: forceCelebration,
};

// -----------------------------------------------------------------------------

export const useFlairStore = createHook(
  createStore({
    initialState,
    actions: {
      startCelebration,
      stopCelebration,
    },
  })
);

// -----------------------------------------------------------------------------

function startCelebration(): Action<State> {
  return ({ setState }) => {
    setState({ celebration: true });
  };
}

function stopCelebration(): Action<State> {
  return ({ setState }) => {
    setState({ celebration: false });
  };
}

// -----------------------------------------------------------------------------

interface State {
  celebration: boolean;
}
