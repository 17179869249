import { memo } from "react";

import styled from "styled-components";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { MagicLinkErrorMessage as Message } from "./Message";

// -----------------------------------------------------------------------------

export const MagicLinkError = memo(({ errorMessage }: Props) => {
  return (
    <Container>
      <ErrorOutlineIcon color="error" />
      <Typography variant="body1">
        <Message errorMessage={errorMessage} />
      </Typography>
    </Container>
  );
});

// -----------------------------------------------------------------------------

const Container = styled(Box)`
  align-items: center;
  display: grid;
  gap: 16px;
  grid-template-columns: auto 1fr;
`;

// -----------------------------------------------------------------------------

interface Props {
  errorMessage: string;
}
