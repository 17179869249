import { memo, type ReactNode } from "react";

import { type DialogProps } from "@mui/material";
import styled from "styled-components";

import Dialog from "@mui/material/Dialog";

// ----------------------------------------------------------------------------

export const FullHeightModal = memo(
  ({
    children,
    onClose,
  }: Pick<DialogProps, "onClose"> & { children: ReactNode }) => {
    return (
      <Container fullWidth open onClose={onClose}>
        {children}
      </Container>
    );
  }
);

// ----------------------------------------------------------------------------

const Container = styled(Dialog)`
  .MuiDialog-container {
    padding: clamp(8px, calc(0.5rem + ((1vw - 5px) * 3.4286)), 32px);

    @media screen and (max-width: 500px) {
      padding: 0;
    }
  }

  .MuiDialog-paper {
    margin: 0;
    max-height: 100%;
    max-width: 800px;
    width: 100%;

    @media screen and (max-width: 500px) {
      border-radius: 0;
      max-height: 100%;
    }
  }
`;
