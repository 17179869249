export function position(
  type: Position,
  top?: PositionValue,
  right?: PositionValue,
  bottom?: PositionValue,
  left?: PositionValue
): PositionObject {
  const retVal: PositionObject = { position: type };
  top !== undefined && (retVal.top = top);
  right !== undefined && (retVal.right = right);
  bottom !== undefined && (retVal.bottom = bottom);
  left !== undefined && (retVal.left = left);

  //
  return retVal;
}

type Position = "relative" | "absolute" | "fixed";
type PositionValueOut = number | string;
type PositionValue = PositionValueOut | undefined;
interface PositionObject {
  position: Position;
  top?: PositionValueOut;
  right?: PositionValueOut;
  bottom?: PositionValueOut;
  left?: PositionValueOut;
}

// -----------------------------------------------------------------------------

export function classes(...classes: string[]): string {
  return classes.join(" ");
}

export function classIf(
  truthy: any,
  className: string,
  otherClassName: string = ""
): string {
  return truthy ? className : otherClassName;
}
