import { memo } from "react";
import {
  Navigate,
  type Params,
  Route,
  Routes,
  useParams,
} from "react-router-dom";

import { withSentryReactRouterV6Routing } from "@sentry/react";

import { AtlassianConnectConnectPage } from "external/destination/AtlassianConnect";
import { AtlassianConnectConnectConfirmPage } from "external/destination/AtlassianConnect/ConnectConfirm";
import { GoogleSheetConnectPage } from "external/destination/GoogleSheet";
import {
  JiraCloudConfigurePage,
  JiraCloudConnectPage,
} from "external/destination/JiraCloud";
import {
  PagerDutyConfigurePage,
  PagerDutyConnectConfirmPage,
  PagerDutyConnectPage,
} from "external/destination/PagerDuty";
import { SlackConfigurePage } from "external/destination/Slack";
import { SlackConnectPage } from "external/destination/Slack/Connect";
import {
  WebhookConnectPage,
  WebhookConfigurePage,
} from "external/destination/Webhook";
import { AmazonWebServicesConnectPage } from "external/source/AmazonWebServices";
import { AzureConnectPage } from "external/source/Azure";
import { BoxConnectPage } from "external/source/Box/Connect";
import { EKSKubernetesConnectSetupPage } from "external/source/EKSKubernetes";
import { GithubConnectPage } from "external/source/Github/Connect";
import { GKEKubernetesConnectSetupPage } from "external/source/GKEKubernetes";
import { GoogleCloudPlatformConnectPage } from "external/source/GoogleCloudPlatform";
import { GoogleWorkspaceConnectPage } from "external/source/GoogleWorkspace";
import { LookerConnectPage } from "external/source/Looker";
import { Microsoft365ConnectPage } from "external/source/Microsoft365/Connect";
import { OktaConnectPage } from "external/source/Okta";
import { SalesforceConnectPage } from "external/source/Salesforce";
import { SnowflakeConnectPage } from "external/source/Snowflake";

import { AccountPage } from "Component/Page/Account";
import { DashboardPage } from "Component/Page/Analytics/Dashboard";
import { IAMFindingsPage } from "Component/Page/Analytics/IAMFindings";
import { InternetExposedFindingsPage } from "Component/Page/Analytics/InternetExposedFindings";
import { NetworkSecurityFindingsPage } from "Component/Page/Analytics/NetworkSecurityFindings";
import { SecurityHealthScorePage } from "Component/Page/Analytics/SecurityHealthScore";
import { SoftwareSupplyChainSecurityFindingsPage } from "Component/Page/Analytics/SSCSecurityFindings";
import { StaleAccountsFindingsPage } from "Component/Page/Analytics/StaleAccountsFindings";
import { StaleFindingsPage } from "Component/Page/Analytics/StaleFindings";
import { ThirdPartyAppFindingsPage } from "Component/Page/Analytics/ThirdPartyAppFindings";
import { AssetInventoryPage } from "Component/Page/Asset/Inventory";
import { BillingPage } from "Component/Page/Billing";
import { ComingSoonPage } from "Component/Page/ComingSoon";
import { EnvironmentPage } from "Component/Page/Environment";
import { EnvironmentDestinationsOAuthPage } from "Component/Page/Environment/Destinations/OAuth";
import { EnvironmentDestinationsSearchPage } from "Component/Page/Environment/DestinationsSearch";
import { EnvironmentSourcesOAuthPage } from "Component/Page/Environment/Sources/OAuth";
import { EnvironmentSourcesSearchPage } from "Component/Page/Environment/SourcesSearch";
import { ErrorPage } from "Component/Page/Error";
import { FindingsPage } from "Component/Page/Findings";
import { FindingsRuleManagementPage } from "Component/Page/FindingsRuleManagement";
import { LoginPage } from "Component/Page/Login";
import { MagicLinkPage } from "Component/Page/MagicLink";
import { MembersPage } from "Component/Page/Members";
import { RegisterPage } from "Component/Page/Register";
import { SettingsPage } from "Component/Page/Settings";
import { DcvRedirectWrapperPage } from "Component/Page/Settings/General/DCV/RedirectWrapper";

import { WithNavigationLayout } from "./Layout/WithNavigation";
import { WithoutNavigationLayout } from "./Layout/WithoutNavigation";

// -----------------------------------------------------------------------------

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

// -----------------------------------------------------------------------------

export const Router = memo(() => {
  return (
    <SentryRoutes>
      <Route element={<WithoutNavigationLayout requireNoAuth={true} />}>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/magic_link" element={<MagicLinkPage />} />
      </Route>

      <Route element={<WithoutNavigationLayout requireNoAuth={false} />}>
        <Route path="/misc/error/:code" element={<ErrorPage />} />
        <Route path="/misc/:code" element={<ErrorPage />} />
      </Route>

      <Route element={<WithNavigationLayout />}>
        <Route path="/account" element={<AccountPage />} />

        <Route path="/analytics" element={<DashboardPage />} />
        <Route path="/analytics/risk" element={<SecurityHealthScorePage />} />
        <Route path="/analytics/iam" element={<IAMFindingsPage />} />
        <Route
          path="/analytics/third_party_apps"
          element={<ThirdPartyAppFindingsPage />}
        />
        <Route
          path="/analytics/internet_exposed"
          element={<InternetExposedFindingsPage />}
        />
        <Route
          path="/analytics/network_security"
          element={<NetworkSecurityFindingsPage />}
        />
        <Route
          path="/analytics/software_supply_chain"
          element={<SoftwareSupplyChainSecurityFindingsPage />}
        />
        <Route
          path="/analytics/stale_accounts"
          element={<StaleAccountsFindingsPage />}
        />

        <Route path="/analytics/stale" element={<StaleFindingsPage />} />

        <Route path="/asset/inventory" element={<AssetInventoryPage />} />

        <Route path="/audit-log" element={<ComingSoonPage />} />

        <Route
          path="/billing/:module"
          element={<Navigate to="/billing" replace />}
        />
        <Route path="/billing" element={<BillingPage />} />

        <Route
          path="/organization/dcv/verify/:code"
          element={<DcvRedirectWrapperPage />}
        />

        <Route path="/findings" element={<FindingsPage />} />
        <Route
          path="/findings/rule-management"
          element={<FindingsRuleManagementPage />}
        />

        <Route path="/members/:module" element={<MembersPage />} />
        <Route path="/members" element={<MembersPage />} />

        <Route path="/settings/:module" element={<SettingsPage />} />
        <Route path="/settings" element={<SettingsPage />} />

        <Route
          path="/environment/destinations/all"
          element={<EnvironmentDestinationsSearchPage />}
        />
        <Route
          path="/environment/destinations/oauth"
          element={<EnvironmentDestinationsOAuthPage />}
        />
        <Route
          path="/environment/destinations/atlassian-connect/connect"
          element={<AtlassianConnectConnectPage />}
        />
        <Route
          path="/environment/destinations/atlassian-connect/connect/confirm"
          element={<AtlassianConnectConnectConfirmPage />}
        />
        <Route
          path="/environment/destinations/google-sheet/connect"
          element={<GoogleSheetConnectPage />}
        />
        <Route
          path="/environment/destinations/jira/connect"
          element={<JiraCloudConnectPage />}
        />
        <Route
          path="/environment/destinations/slack/connect"
          element={<SlackConnectPage />}
        />
        <Route
          path="/environment/destinations/jira/:uuid/notifications"
          element={<JiraCloudConfigurePage />}
        />
        <Route
          path="/environment/destinations/pagerduty/connect"
          element={<PagerDutyConnectPage />}
        />
        <Route
          path="/environment/destinations/pagerduty/connect/confirm"
          element={<PagerDutyConnectConfirmPage />}
        />
        <Route
          path="/environment/destinations/pagerduty/:uuid/notifications"
          element={<PagerDutyConfigurePage />}
        />
        <Route
          path="/environment/destinations/slack/:uuid/notifications"
          element={<SlackConfigurePage />}
        />
        <Route
          path="/environment/destinations/:name/connect"
          element={<WebhookConnectPage />}
        />
        <Route
          path="/environment/destinations/:name/:uuid/notifications"
          element={<WebhookConfigurePage />}
        />
        <Route
          path="/environment/destinations"
          element={<EnvironmentPage tab="destinations" />}
        />

        <Route
          path="/environment/sources/all"
          element={<EnvironmentSourcesSearchPage />}
        />
        <Route
          path="/environment/sources/oauth"
          element={<EnvironmentSourcesOAuthPage />}
        />
        <Route
          path="/environment/sources/aws/connect"
          element={<AmazonWebServicesConnectPage />}
        />
        <Route
          path="/environment/sources/azure/connect"
          element={<AzureConnectPage />}
        />
        <Route
          path="/environment/sources/eks-kubernetes/connect"
          element={<EKSKubernetesConnectSetupPage />}
        />
        <Route
          path="/environment/sources/gke-kubernetes/connect"
          element={<GKEKubernetesConnectSetupPage />}
        />
        <Route
          path="/environment/sources/box/connect"
          element={<BoxConnectPage />}
        />
        <Route
          path="/environment/sources/gcp/connect"
          element={<GoogleCloudPlatformConnectPage />}
        />
        <Route
          path="/environment/sources/github/connect"
          element={<GithubConnectPage />}
        />
        <Route
          path="/environment/sources/google/connect"
          element={<GoogleWorkspaceConnectPage />}
        />
        <Route
          path="/environment/sources/looker/connect"
          element={<LookerConnectPage />}
        />
        <Route
          path="/environment/sources/m365/connect"
          element={<Microsoft365ConnectPage />}
        />
        <Route
          path="/environment/sources/okta/connect"
          element={<OktaConnectPage />}
        />
        <Route
          path="/environment/sources/snowflake/connect"
          element={<SnowflakeConnectPage />}
        />
        <Route
          path="/environment/sources/salesforce/connect"
          element={<SalesforceConnectPage />}
        />
        <Route
          path="/environment/sources"
          element={<EnvironmentPage tab="sources" />}
        />
      </Route>

      {/* <redirects> */}
      <Route path="/account/*" element={<Navigate to="/account" replace />} />
      <Route
        path="/environment/atlassian-connect"
        element={
          <Navigate
            to="/environment/destination/atlassian-connect/connect"
            replace
          />
        }
      />
      <Route
        path="/environment/destinations/google_sheet/connect"
        element={
          <Navigate
            to="/environment/destinations/google-sheet/connect"
            replace
          />
        }
      />
      <Route
        path="/environment/destinations/google_sheet/:uuid/notifications"
        element={
          <NavigateSpecial
            to={({ uuid }) =>
              `/environment/destinations/google-sheet/${uuid}/notifications`
            }
          />
        }
      />
      <Route
        path="/environment/destinations/pagerduty/redirect"
        element={<Navigate to={toPagerDutyConnect()} replace />}
      />
      <Route
        path="/environment/destinations/slack/redirect"
        element={
          <Navigate to={toOauthConnect("destination", "slack")} replace />
        }
      />
      <Route
        path="/environment/sources/azure/oauth2"
        element={<Navigate to={toOauthConnect("source", "azure")} replace />}
      />
      <Route
        path="/environment/sources/box/oauth2"
        element={<Navigate to={toOauthConnect("source", "box")} replace />}
      />
      <Route
        path="/environment/sources/github/oauth2"
        element={<Navigate to={toOauthConnect("source", "github")} replace />}
      />
      <Route
        path="/environment/sources/google/oauth2"
        element={<Navigate to={toOauthConnect("source", "google")} replace />}
      />
      <Route
        path="/environment/sources/m365/oauth2"
        element={<Navigate to={toOauthConnect("source", "m365")} replace />}
      />
      <Route
        path="/environment/sources/salesforce/oauth2"
        element={
          <Navigate to={toOauthConnect("source", "salesforce")} replace />
        }
      />
      <Route
        path="/environment/sources/snowflake/oauth2"
        element={
          <Navigate to={toOauthConnect("source", "snowflake")} replace />
        }
      />
      <Route
        path="/findings/rule_management"
        element={<Navigate to="/findings/rule-management" replace />}
      />
      <Route
        path="/organization/audit-log"
        element={<Navigate to="/audit-log" replace />}
      />
      <Route
        path="/organization/dcv/verify/:code"
        element={<NavigateSpecial to={({ code }) => `dcv/verify/${code}`} />}
      />
      <Route
        path="/organization/findings"
        element={
          <Navigate
            to={`/findings?${window.location.search.substring(1)}`}
            replace
          />
        }
      />
      <Route
        path="/organization/members/:module"
        element={<NavigateSpecial to={({ module }) => `/members/${module}`} />}
      />
      <Route
        path="/organization/members"
        element={<Navigate to="/members" replace />}
      />
      <Route
        path="/organization/rule-management"
        element={<Navigate to="/findings/rule-management" replace />}
      />
      <Route
        path="/organization/rule_management"
        element={<Navigate to="/findings/rule-management" replace />}
      />
      <Route
        path="/organization/settings/billing/:module"
        element={<Navigate to="/billing" />}
      />
      <Route
        path="/organization/settings/:module"
        element={<NavigateSpecial to={({ module }) => `/settings/${module}`} />}
      />
      <Route
        path="/organization/settings"
        element={<Navigate to="/settings" replace />}
      />
      <Route
        path="/organization"
        element={<Navigate to="/settings" replace />}
      />
      <Route
        path="/settings/general"
        element={<Navigate to="/settings" replace />}
      />
      {/* </redirects> */}

      {/* catch all */}
      <Route element={<WithoutNavigationLayout requireNoAuth={false} />}>
        <Route path="*" element={<ErrorPage />} />
      </Route>
    </SentryRoutes>
  );
});

// -----------------------------------------------------------------------------

function toOauthConnect(type: string, service: string) {
  return `/environment/${type}s/oauth?service=${service}&${window.location.search.substring(
    1
  )}`;
}

function toPagerDutyConnect() {
  /* nosemgrep: typescript.react.security.audit.react-router-redirect.react-router-redirect */
  return `/environment/destinations/pagerduty/connect?${window.location.search.substring(
    1
  )}`;
}

function NavigateSpecial({ to }: { to: (params: Params<string>) => string }) {
  const params = useParams();

  //
  return <Navigate to={to(params)} replace />;
}
