export function hasOne(value: any[]): boolean {
  return Array.isArray(value) && value?.length === 1;
}

export function hasSome(value: any[]): boolean {
  return Array.isArray(value) && value?.length > 0;
}

export function hasAtLeast(len: number, value: any[]) {
  return Array.isArray(value) && value?.length >= len;
}

export function hasAtMost(len: number, value: any[]) {
  return Array.isArray(value) && value?.length <= len;
}

export function hasLessThan(len: number, value: any[]) {
  return Array.isArray(value) && value?.length < len;
}

export function hasMoreThan(len: number, value: any[]) {
  return Array.isArray(value) && value?.length > len;
}

export function hasNo(value: any[]) {
  return hasLessThan(1, value);
}

export function hasSame(value: any[], other: any[]) {
  return (
    Array.isArray(value) &&
    Array.isArray(other) &&
    value?.length === other?.length
  );
}
