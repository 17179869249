import { memo, useEffect } from "react";
import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { useConnectionStore } from "stores/ConnectionStore";

import { getConnectedServices } from "utility/connections";

import { usePageTitle } from "effect/use_page_title";

import { ExternalSources } from "external/sources";

import { EnvironmentConnectedSources } from "./ConnectedSources";
import { EnvironmentPossibleSources } from "./PossibleSources";

// -----------------------------------------------------------------------------

export const EnvironmentSources = memo(() => {
  usePageTitle("Environment → Sources");

  //
  const [connectionStore, connectionActions] = useConnectionStore();

  //
  useEffect(() => {
    connectionActions.loadConnections({ reload: true });
  }, [connectionActions]);

  const AttemptedConnectionsCount = getConnectedServices(
    connectionStore?.connections,
    ExternalSources.services
  ).length;

  //
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 1,
        }}
      >
        <Typography variant="h5" fontWeight="bold" sx={{ mr: 2 }}>
          Available Sources
        </Typography>
        <Link
          to="/environment/sources/all"
          style={{
            alignItems: "center",
            display: "flex",
            textDecoration: "none",
            textTransform: "uppercase",
          }}
        >
          <Typography variant="body1" color="primary">
            See All Available
            <ArrowForwardIcon sx={{ ml: "4px", width: 16, height: 16 }} />
          </Typography>
        </Link>
      </Box>

      <EnvironmentPossibleSources />

      {AttemptedConnectionsCount > 0 ? (
        <Box sx={{ mt: 4 }}>
          <EnvironmentConnectedSources />
        </Box>
      ) : null}
    </>
  );
});
