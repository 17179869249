import { type ScatterPlotRawSerie } from "@nivo/scatterplot";
import sortBy from "lodash/sortBy";

import { type Finding, SeverityToColor } from "data/finding";

import { dayjs } from "utility/dayjs";

// -----------------------------------------------------------------------------

const Categories = [
  "early_access",
  "info",
  "low",
  "medium",
  "high",
  "critical",
];

const SortColumns = ["x", "y"];
const DateFormat = "YYYY-MM-DD";
const Comma = ",";

// -----------------------------------------------------------------------------

export function getScatterData(
  findings: Finding[] = []
): Array<ScatterPlotRawSerie<IAMFindingDatum>> {
  return findings.length > 1 ? generateScatterData(findings) : [];
}

// -----------------------------------------------------------------------------

function generateScatterData(
  findings: Finding[]
): Array<ScatterPlotRawSerie<IAMFindingDatum>> {
  const scatterData = Categories.map(toEmptyScatterCategory);

  // 3
  sortBy(
    // 2
    Object.values(
      // 1
      findings.reduce(toScatterEntry, {})
    ),
    SortColumns
  )
    // 4
    .map((data: IAMFindingDatum) => scatterData[data.y].data.push(data));

  //
  return scatterData;
}

function toEmptyScatterCategory(
  category: string
): ScatterPlotRawSerie<IAMFindingDatum> {
  return { id: category, data: [] };
}

function toScatterEntry(
  obj: Record<string, IAMFindingDatum>,
  finding: Finding
): Record<string, IAMFindingDatum> {
  const severityIndex = finding.severity;
  const key = getKey(finding.create_time, severityIndex);
  const data = obj[key] || {
    x: finding.create_time,
    y: severityIndex,
    color: SeverityToColor[severityIndex],
    count: 0,
  };

  //
  data.count += 1;
  obj[key] = data;

  //
  return obj;
}

function getKey(createTime: Date, severityIndex: number): string {
  return [dayjs(createTime).format(DateFormat), severityIndex].join(Comma);
}

// -----------------------------------------------------------------------------

export interface IAMFindingDatum {
  x: Date;
  y: number;
  color: string;
  count: number;
}
