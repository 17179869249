import { memo, useState } from "react";

import styled from "styled-components";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

// -----------------------------------------------------------------------------

export const SettingsRequestDeletion = memo(
  ({ requestedDeletion, setRequestedDeletion, actions, disabled }: Props) => {
    const [open, setOpen] = useState(false);

    //
    function openModal() {
      setOpen(true);
    }

    function closeModal() {
      setOpen(false);
    }

    function submitDeletionRequest() {
      actions.patch({
        requested_deletion: new Date(),
      });

      setRequestedDeletion(true);
      closeModal();
    }

    //
    return (
      <>
        <Container
          elevation={1}
          sx={{ p: 2, mt: 2 }}
          className={disabled ? "disabled" : undefined}
        >
          <Typography variant="h6" sx={{ mb: 1 }} fontWeight="bold">
            Request Deletion
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            If you&apos;d like to request deletion of your organization, you may
            do so here. This will not delete your organization, but will instead
            notify our team that you have requested deletion. We will review
            your request and contact you if any further information is required.
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            There is up to a seven-day grace period starting at the time you
            make the request before the deletion begins.
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            During the grace period, you may contact ThreatKey Support to cancel
            the request.
          </Typography>
          <Button
            variant="contained"
            color="error"
            onClick={openModal}
            disabled={disabled || requestedDeletion}
          >
            {requestedDeletion
              ? "Submitted Deletion Request"
              : "Request Deletion"}
          </Button>
        </Container>
        <Dialog
          open={open}
          onClose={closeModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Request Deletion of Your Organization?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This action may be irreversible. Are you sure you wish to request
              deletion of your ThreatKey organization?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal} autoFocus>
              No
            </Button>
            <Button color="error" onClick={submitDeletionRequest}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
);

// -----------------------------------------------------------------------------

const Container = styled(Paper)`
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

// -----------------------------------------------------------------------------

interface Props {
  actions: { patch: Function };
  requestedDeletion: boolean;
  setRequestedDeletion: Function;
  disabled: boolean;
}
