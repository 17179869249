import { memo } from "react";
import { useSearchParams } from "react-router-dom";

import { Buffer } from "buffer";

import { usePageTitle } from "effect/use_page_title";

import { MagicLinkContainer } from "./Component/Container";
import { MagicLinkError } from "./Component/Error";
import { MagicLinkRedirect } from "./Component/Redirect";

// -----------------------------------------------------------------------------

const RedirectUrlRegex: RegExp =
  /^(https?:\/\/)(.*\.)?((localhost(:\d{4,5})?)|(threatkey\.com)|(threatkey\.developer))(\/.*)?$/;

// -----------------------------------------------------------------------------

export const MagicLinkPage = memo(() => {
  usePageTitle("Magic Link");

  const [searchParams] = useSearchParams();
  const code = searchParams.get("code") ?? "";

  const errorMessage = useErrorMessageFromURL();

  const redirectUrl = Buffer.from(code, "base64").toString().trim();

  const testedRedirectUrl =
    !!redirectUrl && redirectUrl.match(RedirectUrlRegex) ? redirectUrl : "";

  //
  switch (true) {
    case !!errorMessage:
      return (
        <MagicLinkContainer>
          <MagicLinkError errorMessage={errorMessage} />
        </MagicLinkContainer>
      );

    case !code:
      return (
        <MagicLinkContainer>
          <MagicLinkError errorMessage="missing_code" />
        </MagicLinkContainer>
      );

    case !redirectUrl:
      return (
        <MagicLinkContainer>
          <MagicLinkError errorMessage="redirect_url" />
        </MagicLinkContainer>
      );

    case !testedRedirectUrl:
      return (
        <MagicLinkContainer>
          <MagicLinkError errorMessage="redirect_url" />
        </MagicLinkContainer>
      );

    default:
      return (
        <MagicLinkContainer>
          <MagicLinkRedirect redirectUrl={testedRedirectUrl} />
        </MagicLinkContainer>
      );
  }
});

// -----------------------------------------------------------------------------

function useErrorMessageFromURL(): string {
  const [searchParams] = useSearchParams();

  //
  return new URLSearchParams(searchParams).get("error_msg") ?? "";
}
