import {
  type MutableRefObject,
  type PropsWithChildren,
  type UIEventHandler,
  memo,
} from "react";

import styled from "styled-components";

import Box from "@mui/material/Box";

// -----------------------------------------------------------------------------

export const ScrollPane = memo(
  ({ className = "", children, containerRef, scrollRef, onScroll }: Props) => {
    return (
      <ScrollContainer
        className={`scroll-container ${className}`}
        ref={containerRef}
      >
        <ScrollContent
          className="scroll-content"
          onScroll={onScroll}
          ref={scrollRef}
        >
          {children}
        </ScrollContent>
      </ScrollContainer>
    );
  }
);

// -----------------------------------------------------------------------------

const ScrollContainer = styled(Box)`
  position: relative;
`;

const ScrollContent = styled(Box)`
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  right: 0;
  /* Customize the scrollbar */
  &::-webkit-scrollbar {
    width: 8px; /* width of the scrollbar */
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.overviewScrollbar.main};
    border-radius: 5px;
  }

  top: 0;
`;
// -----------------------------------------------------------------------------

interface Props extends PropsWithChildren {
  containerRef?: MutableRefObject<HTMLDivElement | undefined>;
  scrollRef?: MutableRefObject<HTMLDivElement | undefined>;
  className?: string;
  onScroll: UIEventHandler<HTMLDivElement>;
}
