import { memo } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useOrganizationStore } from "stores/OrganizationStore";

import { usePageTitle } from "effect/use_page_title";

import { Page } from "Component/Page";
import { PageContent } from "Component/PageContent";
import { PageHeader } from "Component/PageHeader";
import { PageHeaderBackButton } from "Component/PageHeaderBackButton";
import { PageTitle } from "Component/PageTitle";
import { ServiceConnectionInstructionPhase } from "Component/ServiceConnectionInstructionPhase";
import { ServiceConnectionPhase } from "Component/ServiceConnectionPhase";
import { ServiceConnectionPhases } from "Component/ServiceConnectionPhases";

import { SlackConfig } from "./config";

// -----------------------------------------------------------------------------

// slack demands SSL.
// you'll need to manually rewrite to http:// when using localhost.
const redirectAddress = window.location.origin.replace(/^http:/i, "https:");

// -----------------------------------------------------------------------------

export const SlackConnectPage = memo(() => {
  usePageTitle(`Environment → Sources → Slack → Connect`);

  //
  const [organizationStore] = useOrganizationStore();

  //
  const redirectUrl: string = `https://slack.com/oauth/v2/authorize?${new URLSearchParams(
    {
      client_id: SlackConfig.clientId,
      redirect_uri: encodeURI(
        `${redirectAddress}/environment/destinations/slack/redirect`
      ),
      scope: [
        "im:read,im:write,channels:read,chat:write,groups:read,team:read,users:read.email,users:read",
      ],
      state: organizationStore.uuid,
      response_type: "code",
    } as any
  ).toString()}`;

  //
  return (
    <Page>
      <PageHeader>
        <PageHeaderBackButton to="/environment/destinations">
          Back to Destinations
        </PageHeaderBackButton>
        <PageTitle>Slack Connection</PageTitle>
      </PageHeader>

      <PageContent>
        <ServiceConnectionPhases>
          <ServiceConnectionInstructionPhase>
            <Typography variant="body1">
              Connect your ThreatKey account to Slack for real-time
              notifications.
            </Typography>
          </ServiceConnectionInstructionPhase>

          <ServiceConnectionPhase disabled={false}>
            <Stack spacing={1}>
              <Typography variant="body1">
                To make a connection, you will be redirected to Slack where you
                can securely approve our access using OAuth 2.0 authorization.
                Afterwards, you&apos;ll be redirected back here.
              </Typography>
              <Box>
                <Button
                  variant="contained"
                  LinkComponent="a"
                  href={redirectUrl}
                >
                  Connect to Slack
                </Button>
              </Box>
            </Stack>
          </ServiceConnectionPhase>
        </ServiceConnectionPhases>
      </PageContent>
    </Page>
  );
});
