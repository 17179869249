import { type MouseEvent, memo, useEffect, useRef, useState } from "react";

import styled from "styled-components";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";

import CheckIcon from "@mui/icons-material/Check";
import Close from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { type FOTS } from "data/typescript";

import { asyncOrSwim } from "utility/async_or_swim";

// -----------------------------------------------------------------------------

export const ShareModal = memo(({ open, onClose, value }: Props) => {
  const ref = useRef<HTMLDivElement>();

  const [canUseClipboard, setCanUseClipboard] = useState(true);
  const [copiedURL, setCopiedURL] = useState(false);

  //
  useEffect(() => {
    asyncOrSwim(
      async () => {
        const result: PermissionStatus = await navigator.permissions.query({
          // @ts-expect-error
          name: "clipboard-write",
        });

        //
        setCanUseClipboard(
          result.state === "granted" || result.state === "prompt"
        );
      },
      (error) => {
        console.error(error);
        setCanUseClipboard(false);
      }
    );
  }, []);

  //
  function copyUrlToClipboard() {
    asyncOrSwim(
      async () => {
        await navigator.clipboard.writeText(value);
        setCopiedURL(true);
        setTimeout(() => setCopiedURL(false), 1500);
      },
      (error) => console.log(error)
    );
  }

  function closeModal() {
    setCopiedURL(false);
    onClose();
  }

  function closeModalIfNecessary(e: MouseEvent<HTMLDivElement>) {
    const target = e.target;
    if (ref.current === target || ref.current?.contains(target as Node)) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }

    //
    closeModal();
  }

  //
  return open ? (
    <Container onClick={closeModalIfNecessary}>
      <Content>
        <CopyWindow ref={ref as FOTS}>
          <Copyable rows={10} defaultValue={value} />
          <Box>
            {canUseClipboard ? (
              <IconButton onClick={copyUrlToClipboard} disabled={copiedURL}>
                {copiedURL ? <CheckIcon /> : <ContentCopyIcon />}
              </IconButton>
            ) : null}
          </Box>
        </CopyWindow>
        <Buttons>
          <IconButton color="inherit" size="large" onClick={closeModal}>
            <Close />
          </IconButton>
        </Buttons>
      </Content>
    </Container>
  ) : null;
});

// -----------------------------------------------------------------------------
const Container = styled(Box)`
  background: ${(props) => props.theme.appHeader.main};
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  padding: 16px;
  padding-top: 33vh;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
`;

const Content = styled(Box)`
  color: ${(props) => props.theme.primary.contrastText};
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: calc(512px - 16px);
  width: 100%;
`;

const CopyWindow = styled(Paper)`
  align-items: center;
  border-radius: 4px;
  display: flex;
  gap: 8px;
  padding: 8px;
`;

const Copyable = styled.textarea`
  appearance: none;
  border: 0;
  outline: 0;
  resize: vertical;
  width: 100%;
  word-break: break-all;
  word-wrap: break-all;
`;

const Buttons = styled(Box)`
  display: flex;
  justify-content: center;
`;

// -----------------------------------------------------------------------------

interface Props {
  open: boolean;
  onClose: Function;
  value: string;
}
