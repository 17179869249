import { memo, useMemo, useState } from "react";
import { type NavigateFunction, useNavigate } from "react-router-dom";

import { DataGridPremium, type GridRowParams } from "@mui/x-data-grid-premium";
import styled from "styled-components";

import Paper from "@mui/material/Paper";

import { type Finding, FindingSeverity, type Metrics } from "data/finding";

import {
  DataGridStyle,
  FindingsColumns,
  FindingsColumnsLoading,
  LoadingRows,
  PaperStyle,
} from "./data";

// -----------------------------------------------------------------------------

const PageSize = 20;
const PageSizeOptions = [PageSize];
const NoSort: any[] = [];
const DataComponents: Record<string, () => JSX.Element> = {
  LoadingOverlay: NullComponent,
};

// -----------------------------------------------------------------------------

export const FindingsDataTable = memo(({ loading, metrics }: Props) => {
  const navigate = useNavigate();

  const [sort, setSort] = useState<Sort>(undefined as any);

  const displayFindings = useMemo(
    () => metrics?.findings?.filter(outUndesiredFindings) ?? [],
    [metrics]
  );

  //
  function handleSort([
    { field = "", sort: sortDirection = "" } = {},
  ]: any[] = []): void {
    setSort(
      field
        ? {
            field,
            sort: sortDirection,
          }
        : (undefined as any)
    );
  }

  const onRowClick = loading ? onRowClickNoop : onRowClickFactory(navigate);

  //
  return (
    <Paper elevation={1} sx={PaperStyle}>
      <CustomGrid
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
        density="compact"
        getRowId={getRowId}
        localeText={{
          noRowsLabel: "No Critical or High Findings found",
        }}
        rows={loading ? LoadingRows : displayFindings}
        rowCount={displayFindings.length}
        columns={loading ? FindingsColumnsLoading : FindingsColumns}
        components={DataComponents}
        pageSize={PageSize}
        rowsPerPageOptions={PageSizeOptions}
        checkboxSelection={false}
        sx={DataGridStyle}
        sortModel={sort ? [sort] : NoSort}
        onRowClick={onRowClick}
        onSortModelChange={handleSort}
        loading={loading}
      />
    </Paper>
  );
});

// -----------------------------------------------------------------------------

function outUndesiredFindings(finding: Finding): boolean {
  return (
    finding.severity === FindingSeverity.Critical ||
    finding.severity === FindingSeverity.High
  );
}

function getRowId(row: Finding): string {
  return row.uuid;
}

function NullComponent() {
  return <></>;
}

function onRowClickFactory(
  navigate: NavigateFunction
): (params: GridRowParams<{ id: string }>) => void {
  return (params) => navigate(`/findings/?f=${params.id}`);
}

function onRowClickNoop(params: GridRowParams<{ id: string }>): void {}

// -----------------------------------------------------------------------------

const CustomGrid = styled(DataGridPremium<Finding>)`
  .MuiDataGrid-overlay {
    font-size: 2rem;
  }
`;

// -----------------------------------------------------------------------------

interface Props {
  loading: boolean;
  metrics: Metrics;
}

interface Sort {
  field: string;
  sort: "asc" | "desc";
}
