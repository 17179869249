import { redirectToBoxOauthUrl } from "./connect_handler";
import { BoxCoverImage } from "./CoverImage";

// -----------------------------------------------------------------------------

export const BoxAppConfig = {
  name: "box",
  label: "Box",
  coverImage: BoxCoverImage,
  onConnect: redirectToBoxOauthUrl,
  searchTerms: ["box"],
};
