import { OktaConnectPage } from "./Connect";
import { redirectToOktaConnectPage } from "./connect_handler";
import { OktaCoverImage } from "./CoverImage";

// -----------------------------------------------------------------------------

export { OktaConnectPage };

export const OktaAppConfig = {
  name: "okta",
  alternativeNames: ["okta_app"],
  label: "Okta",
  coverImage: OktaCoverImage,
  onConnect: redirectToOktaConnectPage,
  searchTerms: [],
};
