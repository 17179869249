export const Kubernetes = {
  Provider: {
    Aws: "aws" as KubernetesProviderAws,
    Kubeconfig: "kubeconfig" as KubernetesProviderKubeconfig,
    Gcp: "gcp" as GKEKubernetesProvider,
  },
  SecretType: {
    AwsAkid: "aws:akid/aks" as KubernetesSecretTypeAwsAkid,
    AwsRole: "aws:role" as KubernetesSecretTypeAwsRole,
    Kubeconfig: "kubeconfig" as KubernetesSecretTypeKubeconfig,
    GcpSvcAcct: "gcp:service_acct_json" as KubernetesSecretTypeGcp,
  },
};

// -----------------------------------------------------------------------------

export type KubernetesProvider =
  | KubernetesProviderAws
  | GKEKubernetesProvider
  | KubernetesProviderKubeconfig;
export type KubernetesProviderAws = "aws";
export type KubernetesProviderKubeconfig = "kubeconfig";
export type GKEKubernetesProvider = "gcp";

export type KubernetesSecretType =
  | KubernetesSecretTypeAwsAkid
  | KubernetesSecretTypeAwsRole
  | KubernetesSecretTypeKubeconfig
  | KubernetesSecretTypeGcp;
export type KubernetesSecretTypeAwsAkid = "aws:akid/aks";
export type KubernetesSecretTypeAwsRole = "aws:role";
export type KubernetesSecretTypeKubeconfig = "kubeconfig";
export type KubernetesSecretTypeGcp = "gcp:service_acct_json";

export type KubernetesConnectionCreateState =
  | KubernetesConnectionCreateStateAkid
  | KubernetesConnectionCreateStateRole
  | KubernetesConnectionCreateStateKubeconfig
  | KubernetesConnectionCreateStateSvcAcctJson;

export interface KubernetesConnectionCreateStateBase {
  provider: KubernetesProvider;
  clusterResourceId: string;
  secretType: KubernetesSecretType;
}

export interface KubernetesConnectionCreateStateAkid
  extends KubernetesConnectionCreateStateBase {
  awsAccessKeyId: string;
  awsSecretKey: string;
}

export interface KubernetesConnectionCreateStateSvcAcctJson
  extends KubernetesConnectionCreateStateBase {
  gcpSvcAcctJson: any;
}

export interface KubernetesConnectionCreateStateRole
  extends KubernetesConnectionCreateStateBase {
  awsRoleArn: string;
  awsRoleExternalId: string;
}

export interface KubernetesConnectionCreateStateKubeconfig
  extends KubernetesConnectionCreateStateBase {
  kubeconfig: string;
}

export type KubernetesConnectionCreateApiPayload =
  | KubernetesConnectionCreateApiPayloadAwsAkid
  | KubernetesConnectionCreateApiPayloadAwsRole
  | KubernetesConnectionCreateApiPayloadKubeconfig
  | KubernetesConnectionCreateApiPayloadGcpSvAcctJson;

export interface KubernetesConnectionCreateApiPayloadBase {
  k8s_provider_type: KubernetesProvider;
  k8s_secret_type: KubernetesSecretType;
  k8s_cluster_resource_id: string;
}

export interface KubernetesConnectionCreateApiPayloadAwsAkid
  extends KubernetesConnectionCreateApiPayloadBase {
  k8s_provider_type: KubernetesProviderAws;
  k8s_secret_type: KubernetesSecretTypeAwsAkid;
  k8s_secret: {
    akid: string;
    aks: string;
  };
}

export interface KubernetesConnectionCreateApiPayloadAwsRole
  extends KubernetesConnectionCreateApiPayloadBase {
  k8s_provider_type: KubernetesProviderAws;
  k8s_secret_type: KubernetesSecretTypeAwsRole;
  k8s_secret: {
    role_arn: string;
    external_id: string;
  };
}

export interface KubernetesConnectionCreateApiPayloadKubeconfig
  extends KubernetesConnectionCreateApiPayloadBase {
  k8s_provider_type: KubernetesProviderKubeconfig;
  k8s_secret_type: KubernetesSecretTypeKubeconfig;
  k8s_secret: {
    kubeconfig: string;
  };
}

export interface KubernetesConnectionCreateApiPayloadGcpSvAcctJson
  extends KubernetesConnectionCreateApiPayloadBase {
  k8s_provider_type: GKEKubernetesProvider;
  k8s_secret_type: KubernetesSecretTypeGcp;
  k8s_secret: KubernetesSvcAcctSecret;
}

export interface KubernetesSvcAcctSecret {
  type: string;
  project_id: string;
  private_key_id: string;
  private_key: string;
  client_email: string;
  client_id: string;
  auth_uri: string;
  token_uri: string;
  auth_provider_x509_cert_url: string;
  client_x509_cert_url: string;
  universe_domain: string;
}
