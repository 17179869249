import { memo, useState, type ReactNode } from "react";

import useSWR from "swr";

import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { useConnectionStore } from "stores/ConnectionStore";
import { ProvisionState, swrFetcher } from "stores/lib";

import { captureSentryException } from "utility/capture_sentry_exception";
import { notifyError, notifySuccess } from "utility/notify";
import { openOrtto } from "utility/ortto";

import { usePageTitle } from "effect/use_page_title";

import { Page } from "Component/Page";
import { PageContent } from "Component/PageContent";
import { PageHeader } from "Component/PageHeader";
import { PageHeaderBackButton } from "Component/PageHeaderBackButton";
import { PageTitle } from "Component/PageTitle";
import { ProvisioningErrors } from "Component/ProvisioningErrors";
import { ServiceConnectionInstructionPhase } from "Component/ServiceConnectionInstructionPhase";
import { ServiceConnectionPhases } from "Component/ServiceConnectionPhases";

import { AmazonWebServicesConnectPageError as Error } from "./Error";
import { AmazonWebServicesCnonectPageCompletedPhase as CompletedPhase } from "./Phase/Completed";
import { AmazonWebServicesConnectPageCreatingPhase as CreatingPhase } from "./Phase/Creating";
import { AmazonWebServicesConnectPageDesignateAWSRolePhase as DesignateAWSRolePhase } from "./Phase/DesignateAWSRole";
import { AmazonWebServicesConnectPageInstallCloudFormationTemplatePhase as InstallCloudFormationTemplatePhase } from "./Phase/InstallCloudFormationTemplatePhase";
import { AmazonWebServicesConnectPageQueuedPhase as QueuedPhase } from "./Phase/Queued";
import { AmazonWebServicesConnectPageRunningPhase as RunningPhase } from "./Phase/Running";

// -----------------------------------------------------------------------------

enum Phase {
  InstallCloudFormationTemplate,
  DesignateAWSRole,
  ApplyNecessaryChanges,
  Completed,
  AnErrorOccurred,
}

enum ChangePhase {
  Creating,
  Queued,
  Running,
  Completed,
}

const ProvisionURL = "api/organization/connections/aws/provision";

// -----------------------------------------------------------------------------

export const AmazonWebServicesConnectPage = memo(() => {
  usePageTitle("Environment → Sources → AWS → Connect");

  //
  const [, connectionActions] = useConnectionStore();

  const [phase, setPhase] = useState(Phase.InstallCloudFormationTemplate);
  const [changePhase, setChangePhase] = useState(ChangePhase.Queued);
  const [refreshInterval, setRefreshInterval] = useState(2000);
  const [userId, setUserId] = useState<number>(undefined as any);
  const [errorMessage, setErrorMessage] = useState<ReactNode | undefined>(
    undefined
  );

  //
  useSWR<ProvisionStateResponse>(
    ProvisionURL,
    phase === Phase.ApplyNecessaryChanges
      ? async (url) =>
          await swrFetcher(url, {
            method: "POST",
            body: JSON.stringify({
              account_id: userId,
            }),
          })
      : null,
    {
      refreshInterval,
      onError: (error) => {
        captureSentryException(error, "Failed to provision AWS connection");
        setRefreshInterval(0);
        setPhase(Phase.AnErrorOccurred);

        //
        notifyError("There was an error connecting to Amazon Web Services");
        setErrorMessage(<ProvisioningErrors data={error} />);
      },

      onSuccess: (data) => {
        const provisionState = data?.state;
        if (provisionState !== undefined) {
          switch (provisionState) {
            case ProvisionState.CREATING:
              setChangePhase(ChangePhase.Creating);
              break;
            case ProvisionState.QUEUED:
              setChangePhase(ChangePhase.Queued);
              break;
            case ProvisionState.RUNNING:
              setRefreshInterval(1000);
              setChangePhase(ChangePhase.Running);
              break;

            case ProvisionState.COMPLETED:
              notifySuccess("Successfully connected to Amazon Web Services");
              connectionActions.loadConnections({ reload: true });

              setRefreshInterval(0);
              setPhase(Phase.Completed);
              setChangePhase(ChangePhase.Completed);
              break;

            case ProvisionState.ERROR:
              setRefreshInterval(0);
              setPhase(Phase.AnErrorOccurred);

              notifyError(
                "There was an error connecting to Amazon Web Services"
              );
              setErrorMessage(<ProvisioningErrors data={data} />);
              break;
          }
        }
      },
    }
  );

  //
  function retryConnection() {
    setErrorMessage(undefined);
    setUserId(undefined as any);
    setChangePhase(ChangePhase.Queued);
    setPhase(Phase.InstallCloudFormationTemplate);
    setRefreshInterval(2000);
  }

  //
  return (
    <Page>
      <PageHeader>
        <PageHeaderBackButton to="/environment/sources">
          Back to Sources
        </PageHeaderBackButton>
        <PageTitle>Amazon Web Services Connection</PageTitle>
      </PageHeader>

      <PageContent>
        <ServiceConnectionPhases>
          <ServiceConnectionInstructionPhase>
            <Typography variant="body1">
              Connect your ThreatKey account with Amazon Web Services in a just
              a few easy steps. By connecting Amazon Web Services to ThreatKey,
              you can get a comprehensive view of your AWS environment&apos;s
              security posture, identify and prioritize risks, and remediate
              them quickly and easily.
            </Typography>

            <Typography variant="body1">
              You can find more detailed instructions in{" "}
              <Link
                href="https://docs.threatkey.com/docs/sources/amazon-web-services"
                target="_blank"
                rel="noopener noreferrer"
              >
                our technical documentation
              </Link>
              .
            </Typography>

            <Typography variant="body1">
              If you&apos;re having trouble connecting your AWS environment,
              please{" "}
              <Link onClick={openOrtto} sx={{ cursor: "pointer" }}>
                reach out to us
              </Link>
              .
            </Typography>
          </ServiceConnectionInstructionPhase>

          <InstallCloudFormationTemplatePhase
            disabled={phase !== Phase.InstallCloudFormationTemplate}
            onNext={() => setPhase(Phase.DesignateAWSRole)}
          />

          <DesignateAWSRolePhase
            disabled={phase !== Phase.DesignateAWSRole}
            onNext={(newUserId: number) => {
              setUserId(newUserId);
              setPhase(Phase.ApplyNecessaryChanges);
            }}
            onBack={() => setPhase(Phase.InstallCloudFormationTemplate)}
          />

          {phase >= Phase.ApplyNecessaryChanges ? (
            <>
              <Error
                show={
                  changePhase === ChangePhase.Creating &&
                  phase === Phase.AnErrorOccurred
                }
                onTryAgain={retryConnection}
              >
                {errorMessage}
              </Error>

              <CreatingPhase
                disabled={changePhase !== ChangePhase.Creating}
                progressIcon={
                  changePhase === ChangePhase.Creating &&
                  phase !== Phase.AnErrorOccurred
                }
                successIcon={changePhase > ChangePhase.Creating}
                errorIcon={
                  changePhase === ChangePhase.Creating &&
                  phase === Phase.AnErrorOccurred
                }
              />

              <Error
                show={
                  changePhase === ChangePhase.Queued &&
                  phase === Phase.AnErrorOccurred
                }
                onTryAgain={retryConnection}
              >
                {errorMessage}
              </Error>

              <QueuedPhase
                disabled={changePhase !== ChangePhase.Queued}
                progressIcon={
                  changePhase === ChangePhase.Queued &&
                  phase !== Phase.AnErrorOccurred
                }
                successIcon={
                  changePhase > ChangePhase.Queued &&
                  phase !== Phase.AnErrorOccurred
                }
                errorIcon={
                  changePhase === ChangePhase.Queued &&
                  phase === Phase.AnErrorOccurred
                }
              />

              <Error
                show={
                  changePhase === ChangePhase.Running &&
                  phase === Phase.AnErrorOccurred
                }
                onTryAgain={retryConnection}
              >
                {errorMessage}
              </Error>

              <RunningPhase
                disabled={changePhase !== ChangePhase.Running}
                progressIcon={
                  changePhase === ChangePhase.Running &&
                  phase !== Phase.AnErrorOccurred
                }
                successIcon={phase === Phase.Completed}
                errorIcon={
                  changePhase === ChangePhase.Running &&
                  phase === Phase.AnErrorOccurred
                }
              />

              <CompletedPhase
                disabled={phase !== Phase.Completed}
                successIcon={phase === Phase.Completed}
                onClickFn={() => {
                  connectionActions.loadConnections({ reload: true });
                }}
              />
            </>
          ) : null}
        </ServiceConnectionPhases>
      </PageContent>
    </Page>
  );
});

// -----------------------------------------------------------------------------

interface ProvisionStateResponse {
  state: ProvisionState;
  secret_details?: string;
}
