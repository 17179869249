import { memo } from "react";

import SvgIcon from "@mui/material/SvgIcon";

// -----------------------------------------------------------------------------

export const OktaSAMLSSOIcon = memo((props: object) => {
  return (
    <SvgIcon viewBox="0 0 90 30" {...props}>
      <path
        d="M11.2097 7.52832C5.01428 7.52832 0 12.5395 0 18.731C0 24.9225 5.01428 29.9337
        11.2097 29.9337C17.4051 29.9337 22.4194 24.9225 22.4194 18.731C22.4194 12.5395
        17.4051 7.52832 11.2097 7.52832ZM11.2097 24.3435C8.112 24.3435 5.59371 21.8268
        5.59371 18.731C5.59371 15.6352 8.112 13.1185 11.2097 13.1185C14.3074 13.1185 16.8257
        15.6352 16.8257 18.731C16.8257 21.8268 14.3074 24.3435 11.2097 24.3435Z"
        fill="#007DC1"
      />
      <path
        d="M30.9103 22.4944C30.9103 21.6036 31.9801 21.1804 32.6041 21.804C35.4121 24.6548
        40.0475 29.5546 40.0698 29.5768C40.1366 29.6437 40.2035 29.755 40.4709 29.8441C40.5823
        29.8886 40.7606 29.8886 40.9612 29.8886H46.0201C46.9338 29.8886 47.2012 28.8419 46.7778
        28.3073L38.3983 19.7327L37.9526 19.2873C36.9943 18.1514 37.1058 17.706 38.1978 16.5479L44.8389
        9.1314C45.2623 8.59688 44.9949 7.57238 44.0589 7.57238H39.4681C39.2898 7.57238 39.1561 7.57238
        39.0223 7.61693C38.7549 7.70601 38.6435 7.7951 38.5766 7.88419C38.5543 7.90646 34.8772 11.8708
        32.6041 14.2984C31.9801 14.9666 30.8658 14.5212 30.8658 13.608V0.890868C30.8658 0.244989 30.3309
        0 29.9075 0H26.1635C25.5172 0 25.2052 0.423162 25.2052 0.801782V28.9978C25.2052 29.6436 25.7401
        29.8218 26.1858 29.8218H29.9298C30.5092 29.8218 30.8881 29.3987 30.8881 28.9755V28.686V22.4944H30.9103Z"
        fill="#007DC1"
      />
      <path
        d="M61.4863 28.8641L61.0852 25.1225C61.0406 24.6102 60.5503 24.2539 60.0377
        24.343C59.748 24.3875 59.4583 24.4098 59.1686 24.4098C56.1823 24.4098 53.7532
        22.0713 53.5749 19.1091C53.5749 19.02 53.5749 18.9087 53.5749 18.7973V14.2094C53.5749
        13.608 54.0206 13.118 54.6223 13.118H59.6366C59.9932 13.118 60.528 12.8062 60.528
        12.1604V8.61915C60.528 7.92873 60.0823 7.57238 59.6812 7.57238H54.6223C54.0429 7.57238
        53.5749 7.14922 53.5526 6.57016V0.890869C53.5526 0.534521 53.2852 0 52.5943 0H48.8726C48.4046
        0 47.9589 0.289532 47.9589 0.868597C47.9589 0.868597 47.9589 19.02 47.9589 19.1091C48.1149
        25.167 53.0846 30 59.1686 30C59.6812 30 60.1715 29.9555 60.6617 29.8886C61.1966 29.8218 61.5532 29.3764 61.4863 28.8641Z"
        fill="#007DC1"
      />
      <path
        d="M88.4964 24.1651C85.3318 24.1651 84.8415 23.0292 84.8415 18.7753C84.8415 18.7531 84.8415
        18.7531 84.8415 18.7308V8.50809C84.8415 8.15174 84.5741 7.5504 83.8609 7.5504H80.1169C79.6489
        7.5504 79.1364 7.92902 79.1364 8.50809V8.97579C77.5095 8.04038 75.6152 7.50586 73.6095
        7.50586C67.4141 7.50586 62.3998 12.517 62.3998 18.7085C62.3998 24.9001 67.4141 29.9112
        73.6095 29.9112C76.3952 29.9112 78.9358 28.8867 80.8969 27.2163C81.9444 28.8199 83.6381
        29.8889 86.2901 29.9112C86.7358 29.9112 89.1426 30.0003 89.1426 28.8644V24.8778C89.1426
        24.5437 88.8752 24.1651 88.4964 24.1651ZM73.6318 24.3433C70.5341 24.3433 68.0158 21.8266
        68.0158 18.7308C68.0158 15.635 70.5341 13.1183 73.6318 13.1183C76.7295 13.1183 79.2478 15.635
        79.2478 18.7308C79.2255 21.8266 76.7072 24.3433 73.6318 24.3433Z"
        fill="#007DC1"
      />
    </SvgIcon>
  );
});
