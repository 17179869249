import {
  type GridColumns,
  type GridRenderCellParams,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
} from "@mui/x-data-grid-premium";
import { ReturnIf } from "babel-plugin-transform-functional-return";

import { type ApiRule } from "stores/IgnoreStore";

import { dayjs } from "utility/dayjs";

import { GridCellExpand } from "Component/Table/Overflow";

import { RuleTableActions } from "./Actions";
import { RuleTableIgnoreReason } from "./IgnoreReason";

// -----------------------------------------------------------------------------

export const RuleManagementTableColumns: GridColumns = [
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    renderCell: (params) => (
      <RuleTableIgnoreReason id={params.id} value={params.value} />
    ),
  },
  {
    field: "rule_type",
    headerName: "Rule Type",
    flex: 1,
    renderCell: (params: GridRenderCellParams<string, TableRule>) => (
      <GridCellExpand
        value={params.row.rule_type}
        width={params.colDef.computedWidth}
      />
    ),
  },
  {
    field: "details",
    headerName: "Details",
    flex: 3,
    // TODO: Consider linking to finding
    renderCell: (params: GridRenderCellParams<string, TableRule>) => (
      <GridCellExpand
        value={params.row.details}
        width={params.colDef.computedWidth}
      />
    ),
  },
  {
    field: "affected_findings",
    headerName: "Affected findings (#)",
    flex: 1,
    renderCell: (params: GridRenderCellParams<string, TableRule>) => (
      <GridCellExpand
        value={params.row.affected_findings}
        width={params.colDef.computedWidth}
      />
    ),
  },
  {
    field: "created_at",
    headerName: "Date created",
    type: "date",
    flex: 1,
    renderCell: (params: GridRenderCellParams<string, TableRule>) => (
      <GridCellExpand
        value={params.row.formatted_created_at}
        width={params.colDef.computedWidth}
      />
    ),
  },
  {
    field: "created_by",
    headerName: "Created by",
    flex: 2,
    renderCell: (params: GridRenderCellParams<string, TableRule>) => (
      <GridCellExpand
        value={params.row.created_by}
        width={params.colDef.computedWidth}
      />
    ),
  },
  {
    field: "actions",
    headerName: "Actions",
    disableReorder: true,
    hideSortIcons: true,
    sortable: false,
    filterable: false,
    renderCell: ({ row }: { row: TableRule }) => <RuleTableActions row={row} />,
  },
];

// -----------------------------------------------------------------------------

export function getTableData(row: ApiRule): TableRule {
  return {
    uuid: row.uuid,
    rule_type: getRuleType(row),
    details: getDetails(row),
    affected_findings: `${row.affected_findings.length} ${
      row.affected_findings.length > 1 ? "findings" : "finding"
    }`,
    created_at: new Date(row.created_at),
    formatted_created_at: dayjs(row.created_at).format("MM/DD/YYYY"),
    created_by: row.created_by,
    ignore_reason: row.ignore_reason ?? "",
  };
}

function getRuleType(rule: ApiRule): RuleTypeLabel {
  ReturnIf(rule.single_finding_ignored, "Instance");
  ReturnIf(rule.finding_type_ignored, "Finding Type");
  ReturnIf(rule.actor_ignored, "Actor");
  ReturnIf(rule.asset_ignored, "Asset ID");
  ReturnIf(rule.use_asset_re, "Regex");

  //
  return "Instance";
}

function getDetails(rule: ApiRule) {
  ReturnIf(rule.finding_type_name, rule.finding_type_name);
  ReturnIf(rule.asset_name, rule.asset_name);
  ReturnIf(rule.actor_name, rule.actor_name);
  ReturnIf(rule.asset_re, rule.asset_re);

  //
  return rule.finding_uuid;
}

// -----------------------------------------------------------------------------

export interface TableRule {
  uuid: string;
  rule_type: RuleTypeLabel;
  details: string;
  affected_findings: string;
  created_at: Date;
  formatted_created_at: string;
  created_by: string;
  ignore_reason: string;
}

type RuleTypeLabel =
  | "Instance"
  | "Finding Type"
  | "Actor"
  | "Asset ID"
  | "Regex";
