import { SplunkCloudWebhookCoverImage } from "./CoverImage";

// ----------------------------------------------------------------------------- ∏

export const SplunkCloudWebhookAppConfig = {
  name: "splunk_cloud_webhook",
  label: "Splunk Cloud Webhooks",
  singularLabel: "Splunk Cloud Webhook",
  coverImage: SplunkCloudWebhookCoverImage,
  searchTerms: [],
  url: "splunk_cloud_webhook",
  connectInfo: {
    instructions: {
      example: "Splunk Cloud instance",
    },
    placeholder:
      "https://subdomain.splunkcloud.com:8080/12345678-abcd-efab-cdef-1234567890ab/services/collector/event",
    // Disable this check so we match the backend regex exactly.
    // eslint-disable-next-line no-useless-escape
    regex:
      /^https:\/\/[a-zA-Z0-9-.]+\.splunkcloud\.com(:\d{1,5})?\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\/services\/collector\/event$/,
    path: "splunk_cloud/webhook",
  },
  notifications: true,
  notificationKey: "splunk_cloud_webhook",
};
