import React, { memo, useReducer } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import {
  Kubernetes,
  type KubernetesProvider,
  type KubernetesSecretType,
} from "data/kubernetes";

import { useOrganizationStore } from "stores/OrganizationStore";

import { CopyToClipboard } from "Component/CopyToClipboard";
import { ServiceConnectionPhase } from "Component/ServiceConnectionPhase";

import { KubernetesInstallEksCloudFormationTemplatePhase as InstallEksCloudFormationTemplatePhase } from "./InstallEksCloudFormationTemplatePhase";
import { SetupUserWithPolicy } from "./SetupUserWithPolicy";
// -----------------------------------------------------------------------------

const AwsAccessKeyIdRegex = /^AKIA[A-Z0-9]{16}$/;
const AwsSecretKeyRegex = /^[a-z0-9/+=]{40}$/i;
const AwsRoleArnRegex = /^arn:aws:iam::([0-9]{12}):role\/([a-zA-Z0-9_-]+)$/;
const ClusterResourceIdRegex =
  /^arn:aws:eks:([a-zA-Z0-9_-]+):([0-9]{12}):cluster\/([a-zA-Z0-9_-]+)$/;

// -----------------------------------------------------------------------------

export const EKSKubernetesConnectPageConfiguringKubernetes = memo(
  ({ disabled, loading, onNext }: Props) => {
    const [organizationStore] = useOrganizationStore();

    const [data, setData] = useReducer(
      (state: State, changes: Partial<State>): State => ({
        ...state,
        ...changes,
      }),
      {
        provider: Kubernetes.Provider.Aws as KubernetesProvider,
        secretType: "" as KubernetesSecretType,
        clusterResourceId: "",

        awsAccessKeyId: "",
        awsSecretKey: "",

        awsRoleArn: "",
        awsRoleExternalId: organizationStore.uuid,

        kubeConfig: "",

        errorProvider: "",
        errorSecretType: "",
        errorAwsAccessKeyId: "",
        errorAwsSecretKey: "",
        errorAwsRoleArn: "",
        errorKubeconfig: "",
        errorClusterResourceId: "",
      }
    );

    //
    function changeProvider(event: React.ChangeEvent<HTMLInputElement>) {
      setData({
        provider: event.target.value as KubernetesProvider,
        secretType: "" as KubernetesSecretType,
        clusterResourceId: "",

        awsAccessKeyId: "",
        awsSecretKey: "",

        awsRoleArn: "",
        awsRoleExternalId: organizationStore.uuid,

        kubeConfig: "",

        errorProvider: "",
        errorSecretType: "",
        errorAwsAccessKeyId: "",
        errorAwsSecretKey: "",
        errorAwsRoleArn: "",
        errorKubeconfig: "",
        errorClusterResourceId: "",
      });
    }

    function changeSecretType(event: React.ChangeEvent<HTMLInputElement>) {
      setData({
        secretType: event.target.value as KubernetesSecretType,
      });
    }
    function changeClusterResourceId(
      event: React.ChangeEvent<HTMLInputElement>
    ) {
      validateAndChangeKey(
        setData,
        "clusterResourceId",
        event.target.value,
        (value) => ClusterResourceIdRegex.test(value),
        "errorClusterResourceId",
        "Invalid Cluster Resource ID"
      );
    }

    function changeAwsAccessKeyId(event: React.ChangeEvent<HTMLInputElement>) {
      validateAndChangeKey(
        setData,
        "awsAccessKeyId",
        event.target.value,
        (value) => AwsAccessKeyIdRegex.test(value),
        "errorAwsAccessKeyId",
        "Invalid AWS Access Key ID"
      );
    }

    function changeAwsSecretKey(event: React.ChangeEvent<HTMLInputElement>) {
      validateAndChangeKey(
        setData,
        "awsSecretKey",
        event.target.value,
        (value) => AwsSecretKeyRegex.test(value),
        "errorAwsSecretKey",
        "Invalid AWS Secret Key"
      );
    }

    function changeAwsRoleArn(event: React.ChangeEvent<HTMLInputElement>) {
      validateAndChangeKey(
        setData,
        "awsRoleArn",
        event.target.value,
        (value) => AwsRoleArnRegex.test(value),
        "errorAwsRoleArn",
        "Invalid IAM Role ARN"
      );
    }

    function changeKubeconfig(event: React.ChangeEvent<HTMLInputElement>) {
      validateAndChangeKey(
        setData,
        "kubeConfig",
        event.target.value,
        (value) => value !== "",
        "errorKubeconfig",
        "Kubeconfig is required"
      );
    }

    function submitForm() {
      onNext(data);
    }

    //
    const isAwsAkidComplete =
      data.provider === Kubernetes.Provider.Aws &&
      AwsAccessKeyIdRegex.test(data.awsAccessKeyId) &&
      AwsSecretKeyRegex.test(data.awsSecretKey);
    const isAwsRoleArnComplete =
      data.provider === Kubernetes.Provider.Aws &&
      AwsRoleArnRegex.test(data.awsRoleArn);
    const isKubeconfigComplete =
      data.provider === "kubeconfig" && data.kubeConfig !== "";
    const isSubmitEnabled =
      (isAwsAkidComplete || isAwsRoleArnComplete || isKubeconfigComplete) &&
      ClusterResourceIdRegex.test(data.clusterResourceId);

    const [, clusterRegion, clusterUserId, clusterName] =
      ClusterResourceIdRegex.exec(data.clusterResourceId) ?? [];

    //
    return (
      <>
        <ServiceConnectionPhase disabled={disabled}>
          <Stack spacing={1.5} direction="column">
            <Typography variant="h5">Provider</Typography>
            <TextField
              fullWidth
              select
              data-private="lipsum"
              label="Provider"
              placeholder="Provider"
              value={data.provider}
              onChange={changeProvider}
              error={data.errorProvider !== ""}
              helperText={data.errorProvider}
              disabled={true || disabled || loading}
            >
              <MenuItem value="aws">AWS</MenuItem>
              {/* <MenuItem value="kubeconfig">Kubeconfig</MenuItem> */}
            </TextField>
            {data.provider === Kubernetes.Provider.Aws && (
              <TextField
                fullWidth
                select
                data-private="lipsum"
                label="Secret Type"
                placeholder="Secret Type"
                value={data.secretType}
                onChange={changeSecretType}
                error={data.errorSecretType !== ""}
                helperText={data.errorSecretType}
                disabled={disabled || loading}
              >
                <MenuItem value={Kubernetes.SecretType.AwsRole}>Role</MenuItem>
                <MenuItem value={Kubernetes.SecretType.AwsAkid}>
                  Access Key ID (AKID)
                </MenuItem>
              </TextField>
            )}
          </Stack>
        </ServiceConnectionPhase>

        {data.provider === Kubernetes.Provider.Aws &&
          data.secretType === Kubernetes.SecretType.AwsAkid && (
            <>
              <SetupUserWithPolicy disabled={disabled} />
              <ServiceConnectionPhase disabled={disabled}>
                <Stack spacing={1.5} direction="column">
                  <Typography variant="h5">Values</Typography>
                  <TextField
                    fullWidth
                    data-private="lipsum"
                    label="Access Key ID (AKID)"
                    placeholder="(e.g., AKIAIOSFODNN7EXAMPLE)"
                    value={data.awsAccessKeyId}
                    onChange={changeAwsAccessKeyId}
                    error={data.errorAwsAccessKeyId !== ""}
                    helperText={data.errorAwsAccessKeyId}
                  />
                  <TextField
                    fullWidth
                    data-private="lipsum"
                    label="Secret Key"
                    placeholder="(e.g., wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY)"
                    value={data.awsSecretKey}
                    onChange={changeAwsSecretKey}
                    error={data.errorAwsSecretKey !== ""}
                    helperText={data.errorAwsSecretKey}
                    disabled={disabled || loading}
                  />
                  <TextField
                    fullWidth
                    data-private="lipsum"
                    label="Cluster Resource ID"
                    placeholder="(e.g., arn:aws:eks:us-east-1:012345678910:cluster/my-cluster)"
                    value={data.clusterResourceId}
                    onChange={changeClusterResourceId}
                    error={data.errorClusterResourceId !== ""}
                    helperText={data.errorClusterResourceId}
                    disabled={disabled || loading}
                  />
                </Stack>
              </ServiceConnectionPhase>
            </>
          )}

        {data.provider === Kubernetes.Provider.Aws &&
          data.secretType === Kubernetes.SecretType.AwsRole && (
            <>
              <InstallEksCloudFormationTemplatePhase disabled={disabled} />
              <ServiceConnectionPhase disabled={disabled}>
                <Stack spacing={1.5} direction="column">
                  <Typography variant="h5">Values</Typography>
                  <TextField
                    fullWidth
                    data-private="lipsum"
                    label="IAM Role ARN"
                    placeholder={`(e.g, arn:aws:iam::012345678910:role/ThreatKey-EKS-${organizationStore.uuid})`}
                    value={data.awsRoleArn}
                    onChange={changeAwsRoleArn}
                    error={data.errorAwsRoleArn !== ""}
                    helperText={data.errorAwsRoleArn}
                    disabled={disabled || loading}
                  />
                  <TextField
                    disabled
                    fullWidth
                    data-private="lipsum"
                    label="IAM Role External ID"
                    placeholder="12345"
                    value={data.awsRoleExternalId}
                  />
                  <TextField
                    fullWidth
                    data-private="lipsum"
                    label="Cluster Resource ID"
                    placeholder="(e.g., arn:aws:eks:us-east-1:012345678910:cluster/my-cluster)"
                    value={data.clusterResourceId}
                    onChange={changeClusterResourceId}
                    error={data.errorClusterResourceId !== ""}
                    helperText={data.errorClusterResourceId}
                    disabled={disabled || loading}
                  />
                </Stack>
              </ServiceConnectionPhase>
            </>
          )}

        {data.provider === Kubernetes.Provider.Kubeconfig && (
          <ServiceConnectionPhase disabled={disabled}>
            <Stack spacing={1.5} direction="column">
              <Typography variant="h5">Values</Typography>
              <TextField
                fullWidth
                multiline
                minRows={10}
                data-private="lipsum"
                label="Kubeconfig"
                placeholder="(YAML or JSON)"
                value={data.kubeConfig}
                onChange={changeKubeconfig}
                error={data.errorKubeconfig !== ""}
                helperText={data.errorKubeconfig}
                disabled={disabled || loading}
              />
              <TextField
                fullWidth
                data-private="lipsum"
                label="Cluster Resource ID"
                placeholder="(e.g., arn:aws:eks:us-east-1:012345678910:cluster/my-cluster)"
                value={data.clusterResourceId}
                onChange={changeClusterResourceId}
                error={data.errorClusterResourceId !== ""}
                helperText={data.errorClusterResourceId}
                disabled={disabled || loading}
              />
            </Stack>
          </ServiceConnectionPhase>
        )}

        {data.provider === Kubernetes.Provider.Aws && data.secretType && (
          <ServiceConnectionPhase disabled={disabled}>
            <Stack direction="column" spacing={1.5}>
              <Typography variant="h5">Terminal Commands</Typography>
              <Alert severity="warning">
                Run the following commands in an terminal configured for the AWS
                account you&apos;re connecting.
              </Alert>
              <CopyToClipboard>
                {`kubectl apply -f https://threatkey-assets.s3.amazonaws.com/k8s/provision.yml`}
              </CopyToClipboard>

              <CopyToClipboard>
                {`eksctl create iamidentitymapping --cluster ${
                  clusterName ?? "$CLUSTER"
                } --region=${clusterRegion ?? "$REGION"} --arn ${
                  data.secretType === Kubernetes.SecretType.AwsAkid
                    ? `arn arn:aws:iam::${clusterUserId}:user/$USER_NAME_YOU_CREATED`
                    : data.awsRoleArn
                } --username ThreatkeyAccessUser --no-duplicate-arns`}
              </CopyToClipboard>
            </Stack>
          </ServiceConnectionPhase>
        )}

        <ServiceConnectionPhase disabled={disabled}>
          <Alert severity="info">
            The application automatically scans your EKS environment, where it
            continuously monitors various cluster resources and assets. It is
            also designed to self-update in response to any new features or
            asset types introduced in Amazon EKS Kubernetes updates.
          </Alert>
          <LoadingButton
            disabled={loading || disabled || !isSubmitEnabled}
            loading={loading}
            variant="contained"
            onClick={submitForm}
            sx={{ mt: 1.5 }}
          >
            Connect to EKS
          </LoadingButton>
        </ServiceConnectionPhase>
      </>
    );
  }
);

// -----------------------------------------------------------------------------

function validateAndChangeKey(
  setData: (changes: Partial<State>) => void,
  key: keyof Pick<
    State,
    | "awsAccessKeyId"
    | "awsSecretKey"
    | "awsRoleArn"
    | "clusterResourceId"
    | "kubeConfig"
  >,
  value: string,
  tester: (value: string) => boolean,
  errorKey: keyof Pick<
    State,
    | "errorProvider"
    | "errorSecretType"
    | "errorClusterResourceId"
    | "errorAwsAccessKeyId"
    | "errorAwsSecretKey"
    | "errorAwsRoleArn"
    | "errorKubeconfig"
  >,
  error: string
) {
  setData({
    [key]: value,
    [errorKey]: tester(value) ? "" : error,
  });
}

// -----------------------------------------------------------------------------

interface Props {
  disabled: boolean;
  loading: boolean;
  onNext: Function;
}

interface State {
  provider: KubernetesProvider;
  secretType: KubernetesSecretType;
  clusterResourceId: string;

  awsAccessKeyId: string;
  awsSecretKey: string;

  awsRoleArn: string;
  awsRoleExternalId: string;

  kubeConfig: string;

  errorProvider: string;
  errorSecretType: string;
  errorClusterResourceId: string;
  errorAwsAccessKeyId: string;
  errorAwsSecretKey: string;
  errorAwsRoleArn: string;
  errorKubeconfig: string;
}
