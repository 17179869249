import { memo, useState, useMemo } from "react";

import { ReturnIf } from "babel-plugin-transform-functional-return";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";

import { type FOTS } from "data/typescript";

import { LoginMethod } from "stores/lib";
import { useOrganizationStore } from "stores/OrganizationStore";

import { asyncOrSwimWithSentry } from "utility/async_or_swim_sentry";
import { authenticatedPatchFetch } from "utility/fetch/authenticated";
import { notifyError, notifySuccess } from "utility/notify";

// -----------------------------------------------------------------------------

export const SettingsGeneralSSOUpdateLoginMethodModal = memo(
  ({ open, onClose }: Props) => {
    const [organizationStore] = useOrganizationStore();

    const [contactingServer, setContactingServer] = useState(false);
    const [loginMethod, setLoginMethod] = useState("");
    const [defaultOnly, setDefaultOnly] = useState(false);

    //
    function updateLoginMethod() {
      ReturnIf(!loginMethod);

      //
      asyncOrSwimWithSentry(
        async () => {
          setContactingServer(true);

          //
          await (defaultOnly
            ? authenticatedPatchFetch(
                `api/organization/${organizationStore.uuid}`,
                { default_workos_auth_method: loginMethod },
                { csrf: true }
              )
            : authenticatedPatchFetch(
                "api/organization/update_users_login_method",
                { login_method: loginMethod },
                { csrf: true }
              ));

          //
          notifySuccess(
            defaultOnly
              ? "Login method successfully updated for new members"
              : "Login method successfully updated for all members"
          );
          setContactingServer(false);
        },
        "Failed to update login method",
        () => {
          notifyError("There was an error updating the login method");
          setContactingServer(false);
        }
      );
    }

    //
    return (
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogTitle>Update Login Method</DialogTitle>

        <DialogContent>
          <Stack spacing={2}>
            <DialogContentText>
              Update login method for{" "}
              {defaultOnly ? <strong>only new</strong> : <strong>all</strong>}{" "}
              members in the organization.
            </DialogContentText>

            <FormControl required fullWidth>
              <InputLabel id="select-login-label">Login Method</InputLabel>
              <Select
                labelId="select-login-label"
                id="select-login"
                value={loginMethod}
                label="Login Method"
                onChange={(event) => setLoginMethod(event.target.value)}
              >
                {useMemo(
                  () =>
                    Object.keys(
                      organizationStore.organization.workos_conn_id_dict ?? {}
                    ).map((method) => (
                      <MenuItem value={method} key={method}>
                        {LoginMethod[method]}
                      </MenuItem>
                    )),
                  [organizationStore.organization.workos_conn_id_dict]
                )}
              </Select>
            </FormControl>

            <Box>
              <FormControl component="fieldset">
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={defaultOnly}
                      onChange={(e) => setDefaultOnly(e.target.checked)}
                    />
                  }
                  label="Apply to new members only"
                  labelPlacement="end"
                />
              </FormControl>
            </Box>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="contained"
            onClick={updateLoginMethod}
            disabled={contactingServer}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

// -----------------------------------------------------------------------------

interface Props {
  open: boolean;
  onClose: FOTS;
}
