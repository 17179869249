import { memo } from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import { type FOTS } from "data/typescript";

import { PieInteraction } from "Component/PieLabel";
import { SVGText } from "Component/SVGText";

// -----------------------------------------------------------------------------

export const PieDisplay = memo(
  ({ title, data = [], loading = false }: Props) => {
    const hasAtLeastOneDatapoint = data.some(
      (entry: { value: number }) => entry.value > 0
    );

    const CenteredMetric = ({ dataWithArc, centerX, centerY }: any) => {
      let total = 0;
      dataWithArc.forEach((datum: { value: number }) => {
        total += datum.value;
      });
      return (
        <SVGText
          x={centerX}
          y={centerY}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: "18px",
            fontWeight: 600,
          }}
        >
          {total}
        </SVGText>
      );
    };

    //
    return (
      <Card sx={{ overflow: "visible" }}>
        <CardContent>
          <Typography variant="overline">{title}</Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5" fontWeight="bold">
              {loading ? (
                <>
                  &nbsp;
                  <CircularProgress size={20} />
                </>
              ) : !hasAtLeastOneDatapoint ? (
                "None"
              ) : null}
            </Typography>
          </Box>
        </CardContent>
        {hasAtLeastOneDatapoint ? (
          <Box sx={{ height: 256 }}>
            <PieInteraction
              margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
              colors={{ datum: "data.color" }}
              borderWidth={1}
              borderColor={{
                from: "color",
                modifiers: [["darker", 0.5]],
              }}
              activeOuterRadiusOffset={4}
              innerRadius={0.3}
              padAngle={1}
              cornerRadius={1}
              enableArcLinkLabels={false}
              data={data}
              layers={[
                "arcs",
                "arcLabels",
                "arcLinkLabels",
                "legends",
                CenteredMetric,
              ]}
            />
          </Box>
        ) : null}
      </Card>
    );
  }
);

// -----------------------------------------------------------------------------

interface Props {
  title: string;
  data: FOTS;
  loading?: boolean;
}
