import { type SubmitState } from "stores/lib";

// -----------------------------------------------------------------------------

export enum FindingSeverity {
  EarlyAccessInfo = 0,
  Info = 1,
  Low = 2,
  Medium = 3,
  High = 4,
  Critical = 5,
}

export enum FindingState {
  New = 1,
  Notified = 2,
  Resolved = 3,
  Suppressed = 4,
  Resolving = 5,
  Acknowledged = 6,
  Source_Disconnected = 7,
}

export enum Source {
  AWSScan = 1,
  AWSCloudTrail = 2,
  Box = 3,
  GithubScan = 4,
  GoogleScan = 5,
  Okta = 6,
  M365 = 7,
  GoogleEvent = 8,
  GCPScan = 9,
  Zoom = 10,
  ZoomScan = 11,
  AzureScan = 12,
  SalesforceScan = 13,
  LookerScan = 14,
  SnowflakeScan = 15,
  EKS_KubernetesScan = 16,
  GKE_KubernetesScan = 17,
}

// -----------------------------------------------------------------------------

export const FindingSeverityLabel: { [prop: number]: string } = {
  [FindingSeverity.EarlyAccessInfo]: "Early Access",
  [FindingSeverity.Info]: "Info",
  [FindingSeverity.Low]: "Low",
  [FindingSeverity.Medium]: "Medium",
  [FindingSeverity.High]: "High",
  [FindingSeverity.Critical]: "Critical",
};

export const FindingSeverityLabelToIntMap: { [prop: string]: FindingSeverity } =
  {
    early_access: FindingSeverity.EarlyAccessInfo,
    info: FindingSeverity.Info,
    low: FindingSeverity.Low,
    medium: FindingSeverity.Medium,
    high: FindingSeverity.High,
    critical: FindingSeverity.Critical,
  };

export const FindingSeverityNameToIntMap: { [prop: string]: FindingSeverity } =
  {
    "Early Access": FindingSeverity.EarlyAccessInfo,
    EarlyAccessInfo: FindingSeverity.EarlyAccessInfo,
    Info: FindingSeverity.Info,
    Low: FindingSeverity.Low,
    Medium: FindingSeverity.Medium,
    High: FindingSeverity.High,
    Critical: FindingSeverity.Critical,
  };

export const FindingStateLabel: { [prop: number]: string } = {
  [FindingState.New]: "New",
  [FindingState.Notified]: "Notified",
  [FindingState.Resolved]: "Resolved",
  [FindingState.Suppressed]: "Ignored",
  [FindingState.Resolving]: "Resolving",
  [FindingState.Acknowledged]: "Acknowledged",
  [FindingState.Source_Disconnected]: "Source Disconnected",
};

export const FindingSeverityColor = {
  [FindingSeverity.Critical]: "critical",
  [FindingSeverity.High]: "high",
  [FindingSeverity.Medium]: "medium",
  [FindingSeverity.Low]: "low",
  [FindingSeverity.Info]: "info",
  [FindingSeverity.EarlyAccessInfo]: "early_access",
};

export const SourceLabel: { [prop: number]: string } = {
  [Source.AWSScan]: "Amazon Web Services",
  [Source.AWSCloudTrail]: "Amazon Web Services (CloudTrail)",
  [Source.Box]: "Box",
  [Source.GCPScan]: "Google Cloud Platform",
  [Source.GithubScan]: "GitHub",
  [Source.GoogleScan]: "Google Workspace",
  [Source.GoogleEvent]: "Google Workspace",
  [Source.Okta]: "Okta",
  [Source.M365]: "Microsoft 365",
  [Source.Zoom]: "Zoom",
  [Source.ZoomScan]: "Zoom",
  [Source.AzureScan]: "Azure",
  [Source.SalesforceScan]: "Salesforce",
  [Source.LookerScan]: "Looker",
  [Source.EKS_KubernetesScan]: "EKS Kubernetes",
  [Source.GKE_KubernetesScan]: "GKE Kubernetes",
  [Source.SnowflakeScan]: "Snowflake",
};
export const SourceToColor: { [prop: number]: string } = {
  [Source.AWSScan]: "#FF9900",
  [Source.AWSCloudTrail]: "#FF9900",
  [Source.Box]: "#0061D5",
  [Source.GCPScan]: "#1A73E8",
  [Source.GithubScan]: "#181717",
  [Source.GoogleScan]: "#4285F4",
  [Source.GoogleEvent]: "#4285F4",
  [Source.Okta]: "#007DC1",
  [Source.M365]: "#00A4EF",
  [Source.Zoom]: "#2D8CFF",
  [Source.ZoomScan]: "#2D8CFF",
  [Source.AzureScan]: "#0078D4",
  [Source.SalesforceScan]: "#00A1E0",
  [Source.LookerScan]: "#4285F4",
  [Source.EKS_KubernetesScan]: "#326CE5",
  [Source.GKE_KubernetesScan]: "#123CD5",
  [Source.SnowflakeScan]: "#29B5E8",
};

export const SeverityToColor: { [prop: number]: string } = {
  [FindingSeverity.EarlyAccessInfo]: "#50C6CC",
  [FindingSeverity.Info]: "#bdbdbd",
  [FindingSeverity.Low]: "#0072FE",
  [FindingSeverity.Medium]: "#EBB000",
  [FindingSeverity.High]: "#FF7700",
  [FindingSeverity.Critical]: "#DE0000",
};

export const OpenStates = [
  FindingState.New,
  FindingState.Notified,
  FindingState.Acknowledged,
  FindingState.Source_Disconnected,
];

export const IgnorableStates = [
  FindingState.Source_Disconnected,
  FindingState.Suppressed,
  FindingState.New,
  FindingState.Notified,
];

export const UnignorableStates = [FindingState.Suppressed];

export const AcknowledgableStates = [FindingState.New];

// -----------------------------------------------------------------------------

export interface ServerFindingWithType extends ServerFinding {
  type: FindingType | undefined;
}

export interface ServerFinding extends FindingBase {
  create_time: string;
  event_time: string;
  resolved_time?: string;
}

export interface FindingFix {
  name: string;
  description: string;
  undo: string | null;
}

export interface FindingType {
  name: string;
  friendly_name: string;
  description: string;
  fix_description: string;
  default_severity: FindingSeverity;
  source: number;
  resource_type: string;
  require_enduser_confirmation: boolean;
  tags: string[];
}

export interface FindingDetails {
  actor?: MysteryDetail;
  asset?: MysteryDetail;
}

export interface FindingBase {
  uuid: string;
  source_id: string;
  source: Source;
  name: string;
  severity: FindingSeverity;
  customer_overridden_severity: FindingSeverity;
  state: FindingState;
  actor: string;
  asset: string;
  asset_name: string;
  details: FindingDetails;
  fix?: FindingFix;
  fix_undo: boolean;
  ignore_reason?: string;
}

export interface Finding extends FindingBase {
  create_time: Date;
  event_time: Date;
  resolved_time?: Date;
}

export interface MysteryDetail {
  name: string;
  [x: string]: any;
}

export interface Metrics {
  total: number;
  findings_created_last_30_days: number;
  resolved: number;
  findings: Finding[];
  by_severity_open: SeverityCount[];
  unresolved_by_source: UnresolvedBySource[];
}

export interface SeverityCount {
  count: number;
  severity: FindingSeverity;
}

export interface UnresolvedBySource {
  source: Source;
  count: number;
}

export interface FindingStoreState {
  loadState?: SubmitState;
  findings: Finding[];
  findingTypes: { [name: string]: FindingType | undefined };
  findingQuery: URLSearchParams;
  findingsError?: string;
  findingsPage: number;
  findingsCount?: number;
  findingsPageCount?: number;
  updateIgnoreInProgress?: string;
  updateAckInProgress?: string;

  updateSeverityInProgress: string;
  setOrgSeverityOverrideInProgress: string;

  loadAllState?: SubmitState;
  findingsAll: Finding[];
  findingsAllError?: string;
  findingsAllCount?: number;

  loadMetricsState?: SubmitState;
  metrics?: Metrics;
  metricsError?: string;

  bulkActionState?: SubmitState;
}
