export enum GoogleScope {
  AdminDirectoryCustomerReadonly = "https://www.googleapis.com/auth/admin.directory.customer.readonly",
  AdminDirectoryGroup = "https://www.googleapis.com/auth/admin.directory.group",
  AdminDirectoryGroupReadOnly = "https://www.googleapis.com/auth/admin.directory.group.readonly",
  AdminDirectoryUser = "https://www.googleapis.com/auth/admin.directory.user",
  AdminDirectoryUserReadOnly = "https://www.googleapis.com/auth/admin.directory.user.readonly",
  AdminReportsUsageReadOnly = "https://www.googleapis.com/auth/admin.reports.usage.readonly",
}

export enum GoogleScopesEvaluationSummary {
  Insufficient,
  ReadOnly,
  ReadOnlyPartial,
  ReadWrite,
  ReadWritePartial,
}

// Extracted and sometimes modified from
// https://developers.google.com/identity/protocols/oauth2/scopes
// Modifications are:
// * remove "the provisioning of" from non-readonly scopes
export const GoogleScopeDescriptions: { [s in GoogleScope]: string } = {
  [GoogleScope.AdminDirectoryCustomerReadonly]:
    "View Workspace customer related information",
  [GoogleScope.AdminDirectoryGroup]: "View and manage groups on your domain",
  [GoogleScope.AdminDirectoryGroupReadOnly]: "View groups on your domain",
  [GoogleScope.AdminDirectoryUser]: "View and manage users on your domain",
  [GoogleScope.AdminDirectoryUserReadOnly]: "View users on your domain",
  [GoogleScope.AdminReportsUsageReadOnly]: "View usage reports for your domain",
};

// try to:
// * keep this in alphabetical order
export const GoogleReadWriteScopes = [
  GoogleScope.AdminDirectoryCustomerReadonly,
  GoogleScope.AdminDirectoryGroup,
  GoogleScope.AdminDirectoryUser,
  GoogleScope.AdminReportsUsageReadOnly,
];

export const GoogleReadOnlyScopes = [
  GoogleScope.AdminDirectoryCustomerReadonly,
  GoogleScope.AdminDirectoryGroupReadOnly,
  GoogleScope.AdminDirectoryUserReadOnly,
  GoogleScope.AdminReportsUsageReadOnly,
];

export interface GoogleScopesEvaluation {
  summary: GoogleScopesEvaluationSummary;
  breakdown: { [scope: string]: boolean };
}
