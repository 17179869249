import { memo } from "react";

import { ReturnIf } from "babel-plugin-transform-functional-return";

import Alert from "@mui/material/Alert";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import LockIcon from "@mui/icons-material/Lock";

import { BillingState, useBillingStore } from "stores/BillingStore";

import { LoaderModal } from "Component/Modal/LoaderModal";

import { BillingPageCancelPlan as CancelPlan } from "./CancelPlan";
import { BillingPageCurrentFeatures as CurrentFeatures } from "./CurrentFeatures";
import { BillingPageNextTierFeatures as TierFeatures } from "./NextTierFeatures";
import { BillingPagePastPayments as PastPayments } from "./PastPayments";

// -----------------------------------------------------------------------------

const Trial = {
  label: "Trial",
  next_tier: "Launch",
  is_downgrade: false,
  features: [
    "Fully featured 30-day free trial",
    "Unlimited supported sources and destinations",
    "Single Sign-On (SSO) via Google or MagicLink",
  ],
};

const Launch = {
  label: "Launch Plan",
  next_tier: "Scale Plan",
  is_downgrade: false,
  features: [
    ...Trial.features,
    "SAML 2.0 SSO",
    "Roles-based Access Control (RBAC)",
  ],
};

const Scale = {
  label: "Scale Plan",
  next_tier: "Launch",
  is_downgrade: true,
  features: [
    ...Launch.features,
    "Automated Fixes",
    "Dedicated Account Manager",
    "Out of the box Compliance Framework Support (SOC2, CIS, etc.)",
  ],
};

const Tier: Record<
  string,
  {
    label: string;
    next_tier: string;
    is_downgrade: boolean;
    features: string[];
  }
> = {
  Trial,
  Launch,
  Scale,
};

// -----------------------------------------------------------------------------

const GridLeftColumnSX = { display: "flex", flexDirection: "column" };

// -----------------------------------------------------------------------------

export const BillingPageDashboard = memo(() => {
  const [billingStore] = useBillingStore();

  //
  ReturnIf(billingStore.state === BillingState.Loading, <LoaderModal />);
  ReturnIf(
    billingStore.state === BillingState.Restricted,
    <CardContent
      sx={{
        marginTop: "20vh",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <LockIcon sx={{ transform: "scale(2.8)", marginBottom: "2vh" }} />
      <Typography
        variant="h4"
        sx={{ color: "error.main", marginBottom: "10vh" }}
      >
        Access Denied
      </Typography>
      <Typography variant="h5" textAlign="center">
        Sorry you don&apos;t have permission to the billing page. Please contact
        your administrator.
      </Typography>
    </CardContent>
  );
  ReturnIf(
    billingStore.state === BillingState.Error,
    <Alert color="error">
      There was an error loading your subscription data
    </Alert>
  );

  //
  const {
    subscription: {
      label: planName = "Unknown",
      is_trial: isTrial = true,
      price = 0,
      days_left: daysLeft = 0,
      interval = "month",
    } = {},
  } = billingStore;
  const tier = Tier[planName] ?? Tier.Trial;
  const {
    next_tier: nextTierLabel,
    is_downgrade: isDowngrade,
    features: tierFeatures = [],
  } = tier;

  const nextTier = Tier[nextTierLabel];
  const nextTierDirection = isDowngrade ? "Downgrade" : "Upgrade";
  const nextTierFeatures = isDowngrade
    ? (nextTier?.features ?? []).filter((feature: string) =>
        tierFeatures.includes(feature)
      )
    : (nextTier?.features ?? []).filter(
        (feature: string) => !tierFeatures.includes(feature)
      );

  //
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={7} lg={7} sx={GridLeftColumnSX}>
        <Typography gutterBottom variant="h6" fontWeight="bold">
          My Subscription
        </Typography>
        <CurrentFeatures
          label={planName}
          features={tierFeatures}
          daysLeft={daysLeft}
          price={price}
          interval={interval}
          isTrial={isTrial}
        />
        <PastPayments />
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={5}>
        <Typography gutterBottom variant="h6" fontWeight="bold">
          Manage Subscription
        </Typography>
        <Stack spacing={2}>
          {nextTier ? (
            <TierFeatures
              label={planName}
              nextLabel={nextTier.label}
              direction={nextTierDirection}
              isDowngrade={isDowngrade}
              features={nextTierFeatures}
            />
          ) : (
            (null as any)
          )}
          <CancelPlan />
        </Stack>
      </Grid>
    </Grid>
  );
});
