import { type PropsWithChildren, memo, type RefObject, useRef } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";

import { type FOTS } from "data/typescript";

import { copyElementContentsToClipboard } from "utility/copy_to_clipboard";

// -----------------------------------------------------------------------------

export const CopyToClipboard = memo(({ children }: PropsWithChildren) => {
  const copyRef: RefObject<HTMLDivElement> = useRef(undefined as FOTS);

  //
  return (
    <Paper
      sx={{
        mt: 1,
        fontFamily: "monospace",
        backgroundColor: "primary.background",
        color: "primary.text",
        padding: "12px",
      }}
    >
      <Box
        sx={{
          userSelect: "all",
          wordBreak: "break-all",
        }}
        ref={copyRef}
      >
        {children}
      </Box>
      <Button
        variant="contained"
        sx={{ mt: 1 }}
        onClick={() => {
          (async () =>
            copyRef.current !== null
              ? await copyElementContentsToClipboard(copyRef.current)
              : null)();
        }}
      >
        Copy to clipboard
      </Button>
    </Paper>
  );
});
