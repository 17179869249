import {
  type ChangeEvent,
  type ChangeEventHandler,
  memo,
  useState,
} from "react";

import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import styled from "styled-components";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { type ResponseError } from "data/response_error";

import { useUserStore } from "stores/UserStore";

import { editAccount } from "model/account";

import { asyncOrSwim } from "utility/async_or_swim";
import { captureSentryExceptionUnless } from "utility/capture_sentry_exception_unless";
import { notifyError, notifySuccess } from "utility/notify";

import { usePageTitle } from "effect/use_page_title";

import { Page } from "Component/Page";
import { PageContent } from "Component/PageContent";
import { PageHeader } from "Component/PageHeader";
import { PageTitle } from "Component/PageTitle";

// -----------------------------------------------------------------------------

export const AccountPage = memo(() => {
  usePageTitle("Account");

  //
  const [userStore, userActions] = useUserStore();
  const user = userStore.user;

  //
  const [preferredName, setPreferredName] = useState(user.preferredName);
  const [fullName, setFullName] = useState(user.fullName);
  const [gravatarEmail, setGravatarEmail] = useState(user.gravatarEmail);

  const [productAndFeatures, setProductAndFeatures] = useState(
    isSet(user.preferences.productAndFeatures)
  );
  const [companyAnnouncements, setCompanyAnnouncements] = useState(
    isSet(user.preferences.companyAnnouncements)
  );
  const [educationAndTraining, setEducationAndTraining] = useState(
    isSet(user.preferences.educationAndTraining)
  );
  const [monthlyNewsletter, setMonthlyNewsletter] = useState(
    isSet(user.preferences.monthlyNewsletter)
  );
  const [sourceConnected, setSourceConnected] = useState(
    isSet(user.preferences.sourceConnected)
  );
  const [sourceDisconnected, setSourceDisconnected] = useState(
    isSet(user.preferences.sourceDisconnected)
  );
  const [scanFailure, setScanFailure] = useState(
    isSet(user.preferences.scanFailure)
  );
  const [destinationConnected, setDestinationConnected] = useState(
    isSet(user.preferences.destinationConnected)
  );
  const [destinationDisconnected, setDestinationDisconnected] = useState(
    isSet(user.preferences.destinationDisconnected)
  );

  const [expanded, setExpanded] = useState(
    monthlyNewsletter ||
      productAndFeatures ||
      educationAndTraining ||
      companyAnnouncements
  );
  const [expandedActivity, setExpandedActivity] = useState(
    sourceConnected ||
      sourceDisconnected ||
      scanFailure ||
      destinationConnected ||
      destinationDisconnected
  );

  //
  function toggleExpanded() {
    setExpanded((expanded) => !expanded);
  }

  function toggleExpandedActivity() {
    setExpandedActivity((expandedActivity) => !expandedActivity);
  }

  function setField(set: Function): ChangeEventHandler<HTMLInputElement> {
    return (e: ChangeEvent<HTMLInputElement>) => set(e.target.value);
  }

  function toggleField(set: Function): ChangeEventHandler<HTMLInputElement> {
    return (e: ChangeEvent<HTMLInputElement>) => set(e.target.checked);
  }

  function resetForm() {
    setPreferredName(user.preferredName);
    setFullName(user.fullName);
    setGravatarEmail(user.gravatarEmail);
    setProductAndFeatures(isSet(user.preferences.productAndFeatures));
    setCompanyAnnouncements(isSet(user.preferences.companyAnnouncements));
    setEducationAndTraining(isSet(user.preferences.educationAndTraining));
    setMonthlyNewsletter(isSet(user.preferences.monthlyNewsletter));
    setSourceConnected(isSet(user.preferences.sourceConnected));
    setSourceDisconnected(isSet(user.preferences.sourceDisconnected));
    setScanFailure(isSet(user.preferences.scanFailure));
    setDestinationConnected(isSet(user.preferences.destinationConnected));
    setDestinationDisconnected(isSet(user.preferences.destinationDisconnected));
  }

  function submitForm() {
    asyncOrSwim(
      async () => {
        await userActions.set(
          await editAccount(user.uuid, {
            preferred_name: preferredName,
            full_name: fullName,
            gravatar_specific_email: gravatarEmail,
            product_and_features: dateOrNull(productAndFeatures),
            company_announcements: dateOrNull(companyAnnouncements),
            education_and_training: dateOrNull(educationAndTraining),
            monthly_newsletter: dateOrNull(monthlyNewsletter),
            source_connected: dateOrNull(sourceConnected),
            source_disconnected: dateOrNull(sourceDisconnected),
            scan_failure: dateOrNull(scanFailure),
            destination_connected: dateOrNull(destinationConnected),
            destination_disconnected: dateOrNull(destinationDisconnected),
          })
        );
        notifySuccess("Updated your account settings");
      },
      (error) => {
        const message: string = (error as ResponseError).payload?.message;
        notifyError(
          `Failed to update account settings: ${
            message ?? "An unknown error occurred."
          }`
        );
        captureSentryExceptionUnless(
          error,
          "Failed to edit account settings",
          () => !isEmpty(message)
        );
      }
    );
  }

  //
  return (
    <Page>
      <PageHeader>
        <PageTitle>Account Settings</PageTitle>
      </PageHeader>

      <PageContent>
        <Paper elevation={1} sx={{ p: 2 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            General Settings
          </Typography>

          <TextFieldStyled
            fullWidth
            label="Email Address"
            variant="outlined"
            disabled
            defaultValue={user.email}
          />

          <TextFieldStyled
            fullWidth
            label="Preferred Name"
            variant="outlined"
            value={preferredName}
            onChange={setField(setPreferredName)}
          />

          <TextFieldStyled
            fullWidth
            label="Full Name"
            variant="outlined"
            value={fullName}
            onChange={setField(setFullName)}
          />

          <TextFieldStyled
            fullWidth
            label="Gravatar Email (Private)"
            variant="outlined"
            value={gravatarEmail}
            onChange={setField(setGravatarEmail)}
          />
        </Paper>

        <Paper elevation={1} sx={{ p: 2, mt: 3 }}>
          <Box
            sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={toggleExpandedActivity}
          >
            <ArrowForwardIosIcon
              sx={{
                fontSize: "1.25rem",
                mr: 1,
                transform: expandedActivity ? "rotate(90deg)" : undefined,
                transition: "all 250ms",
              }}
            />
            <Typography variant="h6">Activity Notifications</Typography>
          </Box>

          <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
            Get alerted when events happen within the ThreatKey Platform.
            Receive emails for activity across your ThreatKey workspace.
          </Typography>

          <Collapse in={expandedActivity} timeout="auto" unmountOnExit>
            <FormGroup>
              <FormControlLabel
                label="Source Connected"
                control={
                  <Switch
                    checked={sourceConnected}
                    onChange={toggleField(setSourceConnected)}
                  />
                }
              />

              <FormControlLabel
                label="Source Disconnected"
                control={
                  <Switch
                    checked={sourceDisconnected}
                    onChange={toggleField(setSourceDisconnected)}
                  />
                }
              />

              <FormControlLabel
                label="Source is Unhealthy"
                control={
                  <Switch
                    checked={scanFailure}
                    onChange={toggleField(setScanFailure)}
                  />
                }
              />

              <FormControlLabel
                label="Destination Connected"
                control={
                  <Switch
                    name="destination_connected"
                    checked={destinationConnected}
                    onChange={toggleField(setDestinationConnected)}
                  />
                }
              />

              <FormControlLabel
                label="Destination Disconnected"
                control={
                  <Switch
                    name="destination_disconnected"
                    checked={destinationDisconnected}
                    onChange={toggleField(setDestinationDisconnected)}
                  />
                }
              />
            </FormGroup>
          </Collapse>
        </Paper>

        <Paper elevation={1} sx={{ p: 2, mt: 3 }}>
          <Box
            sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={toggleExpanded}
          >
            <ArrowForwardIosIcon
              sx={{
                fontSize: "1.25rem",
                mr: 1,
                transform: expanded ? "rotate(90deg)" : undefined,
                transition: "all 250ms",
              }}
            />
            <Typography variant="h6">Email Subscriptions</Typography>
          </Box>

          <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
            Enable your email subscriptions to get the latest news, new
            features, and product updates from ThreatKey. You can unsubscribe at
            any time.
          </Typography>

          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <FormGroup>
              <FormControlLabel
                label="Product and Feature Updates"
                control={
                  <Switch
                    checked={productAndFeatures}
                    onChange={toggleField(setProductAndFeatures)}
                  />
                }
              />

              <FormControlLabel
                label="Company Announcements"
                control={
                  <Switch
                    checked={companyAnnouncements}
                    onChange={toggleField(setCompanyAnnouncements)}
                  />
                }
              />

              <FormControlLabel
                label="Education and Training"
                control={
                  <Switch
                    checked={educationAndTraining}
                    onChange={toggleField(setEducationAndTraining)}
                  />
                }
              />

              <FormControlLabel
                label="Monthly Newsletter"
                control={
                  <Switch
                    name="monthly_newsletter"
                    checked={monthlyNewsletter}
                    onChange={toggleField(setMonthlyNewsletter)}
                  />
                }
              />
            </FormGroup>
          </Collapse>
        </Paper>

        <Box sx={{ mt: 3 }}>
          <ButtonStyled variant="contained" onClick={submitForm}>
            Save Changes
          </ButtonStyled>
          <ButtonStyled variant="outlined" onClick={resetForm}>
            Reset
          </ButtonStyled>
        </Box>
      </PageContent>
    </Page>
  );
});

// -----------------------------------------------------------------------------

function dateOrNull(value: boolean): Date | null {
  return value ? new Date() : null;
}

function isSet(value: unknown): boolean {
  return !isNil(value);
}

// -----------------------------------------------------------------------------

const TextFieldStyled = styled(TextField)`
  & + & {
    margin-top: 16px;
  }
`;

const ButtonStyled = styled(Button)`
  & + & {
    margin-left: 16px;
  }
`;
