/**
 * A wrapper for asynchronous actions in a synchronous, generally functional context. A fancy
 * IIFE wrapper.
 *
 * @param action - The asynchronous function being called
 * @param errorHandler - Called if an error is caught
 */
export function asyncOrSwim(
  action: () => Promise<void>,
  errorHandler: (error: Error) => any
): void {
  (async () => {
    try {
      await action();
    } catch (error) {
      errorHandler(error as Error);
    }
  })();
}
