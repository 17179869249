import { Fragment, memo } from "react";

import Grid2, { type Grid2Props } from "@mui/material/Unstable_Grid2";

// -----------------------------------------------------------------------------

export const DefinitionList = memo(({ dtProps, ddProps, items }: Props) => {
  const nodes = items.map((item) => (
    <Fragment key={item.key ?? item.dt?.toString()}>
      <Grid2 {...dtProps}>{item.dt}</Grid2>
      <Grid2 {...ddProps}>{item.dd}</Grid2>
    </Fragment>
  ));
  return (
    <Grid2 container component="dl">
      {nodes}
    </Grid2>
  );
});

// -----------------------------------------------------------------------------

interface Props {
  dtProps?: Grid2Props;
  ddProps?: Grid2Props;
  items: Array<{ key?: string; dt: React.ReactNode; dd: React.ReactNode }>;
}
