import { useContext } from "react";

import { ThemeContext } from "theme/context";

import { parseMarkdown, reactOutput } from "utility/markdown";

// -----------------------------------------------------------------------------

export function ReactMarkdown({
  children,
  params,
}: {
  children: string;
  params?: any;
}) {
  const theme = useContext(ThemeContext);
  const content = reactOutput(parseMarkdown(children || "", params), { theme });

  //
  return <>{content}</>;
}
