import { useEffect, useState } from "react";

import { ViewMode } from "data/view_mode";

import { getViewMode } from "utility/get_view_mode";

// -----------------------------------------------------------------------------

export function useViewMode(onChange: (newViewMode: ViewMode) => void) {
  useEffect(() => {
    function updateViewMode() {
      onChange(getViewMode());
    }

    //
    window.addEventListener("resize", updateViewMode);

    //
    return () => window.removeEventListener("resize", updateViewMode);
  }, [onChange]);
}

// -----------------------------------------------------------------------------

export function useViewModeValue(): ViewMode {
  const [viewMode, setViewMode] = useState(ViewMode.Desktop);

  //
  useEffect(() => {
    function updateViewMode() {
      setViewMode(getViewMode());
    }

    //
    window.addEventListener("resize", updateViewMode);

    //
    return () => window.removeEventListener("resize", updateViewMode);
  }, []);

  //
  return viewMode;
}
