import styled from "styled-components";

import Typography from "@mui/material/Typography";

// -----------------------------------------------------------------------------

export const PageSubTitle = styled(Typography).attrs(() => ({
  variant: "body1",
}))`
  max-width: 1142px;

  @media screen and (max-width: 500px) {
    max-height: 64px;
    overflow-x: hidden;
  }
`;
