import { memo } from "react";

import { type Serie } from "@nivo/line";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import CloseFullscreenRounded from "@mui/icons-material/CloseFullscreenRounded";
import FileDownloadRounded from "@mui/icons-material/FileDownloadRounded";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

import { exportChartAsImage } from "utility/save_chart_as_image";

import { LineChartModal, OPEN_LINE_CHART_ID } from "./LineChartModal";

// -----------------------------------------------------------------------------

const IconSX = { fontSize: 14, color: "icons.main" };
const IconLargeSX = { fontSize: 18, color: "primary.main" };

// -----------------------------------------------------------------------------

export const ResolvedFindings = memo(({ closeModal, data, open }: Props) => {
  return (
    <LineChartModal
      title={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <span>Findings over time - resolved - expanded view</span>
            <Tooltip title="Hover over individual dots to view findings for each day">
              <InfoOutlined sx={IconSX} />
            </Tooltip>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <Button
              onClick={() => exportChartAsImage(OPEN_LINE_CHART_ID)}
              startIcon={<FileDownloadRounded sx={IconLargeSX} />}
            >
              Download
            </Button>
            <IconButton onClick={() => closeModal()}>
              <CloseFullscreenRounded sx={IconSX} />
            </IconButton>
          </Box>
        </Box>
      }
      data={data}
      onClose={() => closeModal()}
      isOpen={open}
      isResolved={true}
    />
  );
});

// -----------------------------------------------------------------------------

interface Props {
  data: Serie[];
  closeModal: Function;
  open: boolean;
}
