import { memo, useState } from "react";
import { Link } from "react-router-dom";

import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { useConnectionStore } from "stores/ConnectionStore";
import { useEncryptionStore } from "stores/EncryptionStore";

import { asyncOrSwim } from "utility/async_or_swim";
import { notifyError, notifySuccess } from "utility/notify";

import { usePageTitle } from "effect/use_page_title";

import { Page } from "Component/Page";
import { PageContent } from "Component/PageContent";
import { PageHeader } from "Component/PageHeader";
import { PageHeaderBackButton } from "Component/PageHeaderBackButton";
import { PageTitle } from "Component/PageTitle";
import { ServiceConnectionInstructionPhase } from "Component/ServiceConnectionInstructionPhase";
import { ServiceConnectionPhases } from "Component/ServiceConnectionPhases";

// -----------------------------------------------------------------------------

export const LookerConnectPage = memo(() => {
  usePageTitle("Environment → Sources → Looker → Connect");

  //

  const [, encryptionActions] = useEncryptionStore();
  const [, connectionActions] = useConnectionStore();

  const [baseUrl, setbaseUrl] = useState("");
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false); //

  //

  function createConnection() {
    async function onSuccess() {
      connectionActions.loadConnections({ reload: true });
      notifySuccess("Looker connection created successfully.");
      setSuccess(true);
    }

    function onError(error: Error) {
      notifyError(error.message);
      setLoading(false);
    }

    //
    asyncOrSwim(async () => {
      setLoading(true);
      connectionActions.createLookerConnection({
        ...(await encryptionActions.getPublicKey()),
        data: {
          looker_client_id: clientId,
          looker_client_secret: clientSecret,
          looker_base_url: baseUrl,
        },
        onSuccess,
        onError,
      });
    }, onError);
  }

  return (
    <Page>
      <PageHeader>
        <PageHeaderBackButton to="/environment/sources">
          Back to Sources
        </PageHeaderBackButton>
        <PageTitle>Looker Connection</PageTitle>
      </PageHeader>

      <PageContent>
        {success ? (
          <Paper sx={{ p: 2, mb: 2 }}>
            <ServiceConnectionPhases>
              <Box sx={{ p: 2, textAlign: "center" }}>
                <Typography variant="body1">
                  A Looker connection has been created successfully. You can now
                  view your results on the Findings page. Looker scans may take
                  a few minutes to appear.
                </Typography>
                <Typography variant="body1">
                  To view your results, <Link to="/findings">click here</Link>
                </Typography>
                <Typography variant="body1">
                  To return to all sources,{" "}
                  <Link to="/environment/sources">click here</Link>{" "}
                </Typography>
              </Box>
            </ServiceConnectionPhases>
          </Paper>
        ) : (
          // Render connection form
          <ServiceConnectionPhases>
            <ServiceConnectionInstructionPhase>
              <Typography variant="body1">
                Connect your ThreatKey account with Looker in just a few easy
                steps. Once connected, you can see all of your results on the
                Findings page.
              </Typography>
            </ServiceConnectionInstructionPhase>
            <Paper sx={{ p: 2, mb: 2 }}>
              <FormControl fullWidth>
                <FormLabel>Looker Domain</FormLabel>
                <TextField
                  fullWidth
                  data-private="lipsum"
                  placeholder="https://company.cloud.looker.com"
                  value={baseUrl}
                  onChange={(event) => setbaseUrl(event.target.value)}
                  disabled={loading}
                />

                <FormLabel>Client ID</FormLabel>
                <TextField
                  fullWidth
                  data-private="lipsum"
                  placeholder="Enter your Client ID"
                  value={clientId}
                  onChange={(event) => setClientId(event.target.value)}
                  disabled={loading}
                />

                <FormLabel>Client Secret</FormLabel>
                <TextField
                  fullWidth
                  data-private="lipsum"
                  placeholder="Enter your Client Secret"
                  type="password"
                  value={clientSecret}
                  onChange={(event) => setClientSecret(event.target.value)}
                  disabled={loading}
                />
              </FormControl>

              <Box sx={{ mt: 2 }}>
                <LoadingButton
                  loading={loading}
                  disabled={loading}
                  variant="contained"
                  onClick={createConnection}
                >
                  Connect
                </LoadingButton>
              </Box>
            </Paper>
          </ServiceConnectionPhases>
        )}
      </PageContent>
    </Page>
  );
});
