import { memo } from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";

import { ServiceConnectionPhase } from "Component/ServiceConnectionPhase";

// -----------------------------------------------------------------------------

export const AmazonWebServicesConnectPageQueuedPhase = memo(
  ({ disabled, progressIcon, successIcon, errorIcon }: Props) => {
    return (
      <ServiceConnectionPhase disabled={disabled}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {progressIcon ? (
            <CircularProgress size="24px" sx={{ mr: 1 }} />
          ) : null}
          {successIcon ? (
            <CheckCircleOutlineRoundedIcon color="success" sx={{ mr: 1 }} />
          ) : null}
          {errorIcon ? (
            <ErrorOutlineRoundedIcon color="error" sx={{ mr: 1 }} />
          ) : null}
          <Typography variant="body1">
            Looking for an available worker
          </Typography>
        </Box>
        <Stack sx={{ mt: 2 }} spacing={1} direction="column">
          <Typography variant="body1">
            Once a worker selects your connection from the queue, we&apos;ll
            create a read-only IAM role called <strong>ThreateKeyAudit</strong>{" "}
            to scan your environment. We&apos;ll also configure AWS CloudTrail
            to provide us with information we need to monitor your AWS
            environment.
          </Typography>
        </Stack>
      </ServiceConnectionPhase>
    );
  }
);

// -----------------------------------------------------------------------------

interface Props {
  disabled: boolean;
  progressIcon: boolean;
  successIcon: boolean;
  errorIcon: boolean;
}
