import { memo } from "react";

import { LoginErrorContactButton as Button } from "./ContactButton";

// -----------------------------------------------------------------------------

export const LoginErrorMessage = memo(({ errorMessage }: Props) => {
  switch (errorMessage) {
    case "expired_or_already_used":
      return <>This login link expired or was already used.</>;

    case "organization_already_exists":
      return (
        <>
          Organization already exists, please contact your ThreatKey
          administrator for an invite.
        </>
      );

    case "new_user_error":
      return (
        <>
          An error occurred creating your account, <Button />
        </>
      );

    case "new_organization_error":
      return (
        <>
          An error occurred creating your organization, <Button />
        </>
      );

    case "incorrect_login_type":
      return (
        <>
          You are not configured to use this login method. Please contact your
          ThreatKey administrator, <Button />
        </>
      );

    case "signin_error":
    default:
      return (
        <>
          If your email is valid, a one-time login link has been sent to you
          expiring in 15 minutes. You can close this window.
        </>
      );
  }
});

// -----------------------------------------------------------------------------

interface Props {
  errorMessage: string;
}
