import { memo } from "react";

import SvgIcon from "@mui/material/SvgIcon";

// -----------------------------------------------------------------------------

export const CyberArkSAMLSSOIcon = memo((props: object) => {
  return (
    <SvgIcon viewBox="0 0 363.62 74.83" {...props}>
      <path
        d="M441.55,359.41c.05-.27,0-.69.17-.78,2.26-1.3,4.57-2.51,6.82-3.84s4.45-2.5,6.59-3.92c1.1-.74,2.38-1.2,3.53-1.87,2.56-1.49,5-3.13,7.64-4.52,2.43-1.3,4.74-2.78,7.14-4.13.16-.09.42,0,.64,0-.36.5.76.86.14,1.46a2.21,2.21,0,0,0,0,1c.47.38-.35.94.23,1.31.11.07-.42,1-.29,1.14.49.41-.21.87.2,1.3.18.2-.09.78-.07,1.19a6.33,6.33,0,0,1,0,1.23c-.06.39.29,1,.12,1.18-.43.41.41,1-.29,1.27.81.2-.33,1,.36,1.22-.67.3.25.85-.22,1.26-.18.16.29.7-.09,1,.59.27-.41,1,.36,1.23-.75.22-.12.82-.28,1.25a2.47,2.47,0,0,0,.9,2.39c1.12.61,2.28,1.15,3.38,1.78,1.39.82,2.85,1.53,4.2,2.4,2.28,1.45,4.67,2.71,7,4.09v18.2a3.58,3.58,0,0,1-.94-.31c-2.12-1.44-4.42-2.56-6.54-4-.82-.55-1.86-.6-2.52-1.21-1.42-1.33-3.32-1.83-4.82-3-.19-.15-.52-.11-.79-.16V359.4c-5.19,3.34-10.66,5.93-15.61,9.25v17.19c-.85-.15-.41-.81-.42-1.2,0-5.47,0-10.93,0-16.42a5.08,5.08,0,0,1-.78-.28c-1.62-1-3.22-2-4.85-2.89-3-1.7-6-3.23-8.9-5.15A2.37,2.37,0,0,0,441.55,359.41Z"
        fill="#5d8cba"
        transform="translate(-441.55 -339.98)"
      />
      <path
        d="M768.91,393.17c-3.7.07-7.07,0-10.59,0-2.94-3.35-5.14-7.39-8-10.77h-2.94v10.69H738c-.3-1.7-.41-27.21-.12-32.86h17.5a12.39,12.39,0,0,1,10.2,6.21,13.78,13.78,0,0,1,1.17,3.62,4.73,4.73,0,0,1-.34,3.92c.17,1.81-1,3.1-2,4.36a10.09,10.09,0,0,1-3.61,2.69,9.77,9.77,0,0,0-.88.53Zm-13.24-17.73a4.41,4.41,0,0,0,1.89-2.65,4.08,4.08,0,0,0-3.55-5.16c-2.21.05-4.42,0-6.59,0v7.8Z"
        fill="#216eae"
        transform="translate(-441.55 -339.98)"
      />
      <path
        d="M790.61,376.27c2.5,2.86,4.84,5.68,7.35,8.34s4.93,5.37,7.21,8.24c-.27.2-.38.35-.49.35H793.61l-9.36-10.81v10.81c-3,0-5.75,0-8.55,0a1,1,0,0,1-1.05-1.05c0-.28,0-.57,0-.86v-31h9.53v10.73l8.5-10.73h11.14a4.06,4.06,0,0,1-.47,1.36c-1.23,1.46-2.58,2.82-3.8,4.29-1.79,2.17-3.69,4.25-5.46,6.42C793,373.71,791.72,374.82,790.61,376.27Z"
        fill="#206dae"
        transform="translate(-441.55 -339.98)"
      />
      <path
        d="M708.82,360.3h9.37a18.61,18.61,0,0,1,1.7,3.17c.72,2.24,1.87,4.28,2.73,6.44.75,1.86,2,3.55,2.51,5.45s2.1,3.47,2.29,5.55c1.26,1.76,1.77,3.88,2.73,5.79,1,2.07,1.93,4.21,3,6.51-3.27,0-6.35,0-9.43,0a1,1,0,0,1-1.08-.83,45.74,45.74,0,0,0-1.89-4.81H706.36c-.83,1.88-1.32,3.93-2.48,5.57h-9.35c-.41-.91.26-1.48.56-2.12,1.65-3.5,3.19-7,4.83-10.56,1.07-2.28,2.06-4.62,3.08-6.94,1.39-3.17,2.84-6.33,4.24-9.5A22.23,22.23,0,0,0,708.82,360.3Zm4.91,9.45c-.3.34-.58.52-.66.76-.88,2.73-2.24,5.28-3.06,8-.17.56-.81,1-.27,1.6h8.15Z"
        fill="#206dae"
        transform="translate(-441.55 -339.98)"
      />
      <path
        d="M489.69,386.27a4.31,4.31,0,0,0,1-.31c2.46-1.53,5-2.92,7.53-4.35,2.33-1.32,4.58-2.82,7-4,.43-.21.83-.48,1.3-.76-.07,0,0,0,0,0a.27.27,0,0,1,.11.17v19.11c-1.05.61-2.1,1.24-3.16,1.83-2.58,1.44-5.21,2.83-7.7,4.39-3.79,2.37-7.74,4.43-11.52,6.79a56.91,56.91,0,0,1-7.58,4.17c-.75.33-1.13,1.33-2.14,1.12v-11a19.09,19.09,0,0,0,0-2.14c-.09-.74.38-1.59-.36-2.22.41-.6.71-1.2,0-1.77.71-.53.32-1.3.41-2,1.74-.91,3.35-2.07,5.07-3,3.23-1.67,6.22-3.75,9.49-5.33C489.35,387,489.49,386.55,489.69,386.27Z"
        fill="#206dae"
        transform="translate(-441.55 -339.98)"
      />
      <path
        d="M593.42,360.27c5.92,0,11.77-.14,17.6.08a8.37,8.37,0,0,1,5.94,2.41,9.19,9.19,0,0,1,2.6,3.9,14.72,14.72,0,0,1,.24,2.7c.36,3.18-1.42,5.17-3.84,6.76.2.21.37.54.61.6,2.56.68,3.17,3,4.1,5,1.16,2.51-.15,6.31-1.57,8.07a8.92,8.92,0,0,1-4.12,2.56,16,16,0,0,1-5.48.93c-5.34-.09-10.67,0-16.08,0Zm5.64,18.2V388c3.88,0,7.69.06,11.49,0a5.91,5.91,0,0,0,2.86-.87c2.17-1.36,3-3.88.89-6.15a7.79,7.79,0,0,0-5.47-2.42C605.61,378.33,602.37,378.47,599.06,378.47Zm-.07-4.83c.4,0,.8.06,1.21.07,3.1,0,6.21-.06,9.31,0a7.33,7.33,0,0,0,2.79-.6c2.45-.91,3-3.81,1.25-5.89a6.18,6.18,0,0,0-5.14-2.21c-2.6,0-5.2,0-7.79,0-.55,0-1.09.05-1.63.07Z"
        fill={props.theme?.cyberArkLogo.main}
        transform="translate(-441.55 -339.98)"
      />
      <path
        d="M681.4,381.61l10.29,11.5H685a76.51,76.51,0,0,1-5.75-6.32c-1.08-1.47-2.69-2.46-3.51-4.16-.19-.39-1-.63-1.61-.66-1.86-.08-3.73,0-5.67,0v11.18h-5.63V360.27h2.13c4.62,0,9.25,0,13.87,0a15.12,15.12,0,0,1,4.21.83c3.55,1,5.32,3.59,6.61,6.73-.24,1.08.47,2,.47,3.06a11.1,11.1,0,0,1-2.36,7,8.16,8.16,0,0,1-5.37,3.18A3.36,3.36,0,0,0,681.4,381.61Zm-13-4.87c3.72,0,7.25,0,10.78,0a4.43,4.43,0,0,0,2.38-.46,5.92,5.92,0,0,0,2.81-4.83c0-3.38-1.79-6-5.88-6.33-3.34-.29-6.72-.06-10.09-.06Z"
        fill={props.theme?.cyberArkLogo.main}
        transform="translate(-441.55 -339.98)"
      />
      <path
        d="M474.51,395.37c-.09.67.3,1.44-.41,2,.75.57.45,1.17,0,1.77.74.63.27,1.48.36,2.22a19.09,19.09,0,0,1,0,2.14v11l-.15.37a11,11,0,0,1-1.77-.82c-2.83-1.95-5.95-3.41-8.87-5.2-2.07-1.28-4.27-2.32-6.26-3.74-1.34-1-3-1.5-4.42-2.34-2.57-1.48-5.08-3.06-7.64-4.56-1.21-.71-2.47-1.32-3.85-2V384.54c.6,0,.43.46.43.77,0,2.52,0,5,0,7.55v3.27c.49-.55.67-.89,1-1.05,1.46-.8,3-1.52,4.43-2.31a35,35,0,0,0,4.15-2.44c1.86-1.38,4.11-2.08,5.87-3.6.21-.18.7,0,1.06,0,1.28.71,2.81,1,3.86,2.16a2,2,0,0,0,.68.42,25.14,25.14,0,0,1,4.91,2.74c.16.12.35.3.51.3,1-.06,1.6.8,2.27,1.28s1.83.33,2.29,1.31C473.3,395.58,474,395.35,474.51,395.37Z"
        fill="#5d8cba"
        transform="translate(-441.55 -339.98)"
      />
      <path
        d="M653.16,374.06v5.23H635.08V388H654v5c-1.68.32-18.36.42-24.57.14V360.38h24.16v5.07H635v8.61Z"
        fill={props.theme?.cyberArkLogo.main}
        transform="translate(-441.55 -339.98)"
      />
      <path
        d="M489.69,368.07c-2.31-1.38-4.7-2.64-7-4.09-1.35-.87-2.81-1.58-4.2-2.4-1.1-.63-2.26-1.17-3.38-1.78a2.47,2.47,0,0,1-.9-2.39c.16-.43-.47-1,.28-1.25-.77-.22.23-1-.36-1.23.38-.34-.09-.88.09-1,.47-.41-.45-1,.22-1.26-.69-.24.45-1-.36-1.22.7-.25-.14-.86.29-1.27.17-.16-.18-.79-.12-1.18a6.33,6.33,0,0,0,0-1.23c0-.41.25-1,.07-1.19-.41-.43.29-.89-.2-1.3-.13-.12.4-1.07.29-1.14-.58-.37.24-.93-.23-1.31a2.21,2.21,0,0,1,0-1c.62-.6-.5-1-.14-1.46h.43c1.18,0,1.75,1.3,2.9,1.37,1,1.23,2.85,1.33,3.92,2.53,3.57,1.57,6.75,3.84,10.18,5.69,3.07,1.65,6,3.51,9.1,5.16,1.89,1,3.69,2.2,5.64,3.37-.16.23-.3.6-.56.75-2.83,1.68-5.69,3.3-8.53,5-1.8,1.06-3.55,2.21-5.35,3.28A3.05,3.05,0,0,1,489.69,368.07Z"
        fill="#206dae"
        transform="translate(-441.55 -339.98)"
      />
      <path
        d="M458.47,386.71c-.36,0-.85-.16-1.06,0-1.76,1.52-4,2.22-5.87,3.6a35,35,0,0,1-4.15,2.44c-1.46.79-3,1.51-4.43,2.31-.3.16-.48.5-1,1.05v-3.27c0-2.52,0-5,0-7.55,0-.31.17-.76-.43-.77V359.41a2.37,2.37,0,0,1,1.95.49c2.85,1.92,5.92,3.45,8.9,5.15,1.63.93,3.23,1.93,4.85,2.89a5.08,5.08,0,0,0,.78.28c0,5.49,0,10.95,0,16.42,0,.39-.43,1.05.42,1.2v.87Z"
        fill="#1f6dad"
        transform="translate(-441.55 -339.98)"
      />
      <path
        d="M548.25,385.46l3.45,3.44c-.21.92-1.17,1.19-1.9,1.64a19.3,19.3,0,0,1-4.62,2.44,9.82,9.82,0,0,1-3.41.68,40.44,40.44,0,0,0-4.12,0,15,15,0,0,1-8.84-2.74,15.54,15.54,0,0,1-5.82-8,13,13,0,0,1-.78-3.8,4,4,0,0,0-.33-1.81,2.5,2.5,0,0,1,.21-2.43,1,1,0,0,0,.13-.38c.58-4,.75-4.59,3-8.05a14.94,14.94,0,0,1,9.33-6.25,24.08,24.08,0,0,1,7.84-.31,12.7,12.7,0,0,1,6.8,2.86l2.24,1.77-1.81,1.88c-.54.57-1.07,1.16-1.58,1.71-1.22-.75-2.26-1.52-3.41-2.09a7.59,7.59,0,0,0-4.13-1,12.69,12.69,0,0,1-1.95,0,11.21,11.21,0,0,0-7.07,2.25,10.07,10.07,0,0,0-3.22,4.7,11.66,11.66,0,0,0-.85,6.35c.43,2.7,1.21,5.24,3.36,7.28a11.21,11.21,0,0,0,8.85,3.18,11.68,11.68,0,0,0,4.57-.94C545.62,387.16,546.91,386.27,548.25,385.46Z"
        fill={props.theme?.cyberArkLogo.main}
        transform="translate(-441.55 -339.98)"
      />
      <path
        d="M573.77,393.21h-5.5c0-.54-.07-1.09-.07-1.64,0-3.61,0-7.21,0-10.82a3.12,3.12,0,0,0-.66-1.91c-1.93-2.78-3.76-5.64-5.54-8.52s-3.88-5.55-5.53-8.52c-.26-.46-1-.63-.82-1.44h6.5L571,374.49l9.08-14.15H587a10.15,10.15,0,0,1-1.15,2c-1.85,1.89-3,4.29-4.54,6.37-2.4,3.13-4.3,6.64-6.76,9.74a3.49,3.49,0,0,0-.72,2.31c0,3.67,0,7.35,0,11C573.83,392.26,573.79,392.74,573.77,393.21Z"
        fill={props.theme?.cyberArkLogo.main}
        transform="translate(-441.55 -339.98)"
      />
      <path
        d="M474.51,340.34h-.43l.29-.36Z"
        fill="#5d8cba"
        transform="translate(-441.55 -339.98)"
      />
      <path
        d="M474.08,377.61c-.21,0-.5-.09-.64,0a43.38,43.38,0,0,1-5.37,3.19c-2.2,1.29-4.36,2.65-6.57,3.93-1,.57-2,1-3,1.53V368.65c4.95-3.32,10.42-5.91,15.61-9.25Z"
        fill="#595655"
        transform="translate(-441.55 -339.98)"
      />
    </SvgIcon>
  );
});
