import { type MouseEventHandler, memo } from "react";

import styled from "styled-components";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

// -----------------------------------------------------------------------------

export const ActionButton = memo(
  ({ label, variant, color, onClick }: Props) => {
    return (
      <Container
        variant={variant}
        color={color}
        size="small"
        onClick={onClick as MouseEventHandler<HTMLButtonElement>}
      >
        <Label>{label}</Label>
      </Container>
    );
  }
);

// -----------------------------------------------------------------------------

const Container = styled(Button)`
  align-items: center;
  display: flex;
  flex-shrink: 1;
  gap: 8px;
`;

const Label = styled(Box)`
  min-width: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
`;

// -----------------------------------------------------------------------------

interface Props {
  label: string;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  variant?: "text" | "outlined" | "contained";
  onClick: Function;
}
