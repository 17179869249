import { memo } from "react";

import { type ComputedDatum } from "@nivo/pie";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import { PieInteraction } from "Component/PieLabel";
import { SVGText } from "Component/SVGText";

import { type PieDataProps } from "../../lib/get_src_sev_pie_data";

import { PieChartTooltip } from "./PieChartTooltip";

// -----------------------------------------------------------------------------

export const PieChart = memo(({ title, data = [], loading = false }: Props) => {
  const hasAtLeastOneDatapoint = data.length > 0;
  const CenteredMetric = ({
    dataWithArc,
    centerX,
    centerY,
  }: {
    dataWithArc: any;
    centerX: number;
    centerY: number;
  }) => {
    const totalFindings = dataWithArc.reduce((acc: number, d: any) => {
      const severities: number[] = Object.values(d.data.severity);
      acc += severities.reduce((sevAcc, sev) => (sevAcc += sev), 0);
      return acc;
    }, 0);

    return (
      <>
        <SVGText
          x={centerX}
          y={centerY - 12}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: "24px",
            fontWeight: 700,
          }}
        >
          {totalFindings}
        </SVGText>
        <SVGText
          x={centerX}
          y={centerY + 12}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: "12px",
            fontWeight: 400,
            textTransform: "uppercase",
          }}
        >
          Severe findings
        </SVGText>
      </>
    );
  };

  //
  return (
    <Card sx={{ width: "100%", maxWidth: 500, overflow: "visible" }}>
      <CardContent>
        <Typography variant="overline">{title}</Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5" fontWeight="bold">
            {loading ? (
              <>
                &nbsp;
                <CircularProgress size={20} />
              </>
            ) : !hasAtLeastOneDatapoint ? (
              "None"
            ) : null}
          </Typography>
        </Box>
      </CardContent>
      {hasAtLeastOneDatapoint ? (
        <Box sx={{ height: 200 }}>
          <PieInteraction
            margin={{ top: 1, right: 10, bottom: 10, left: -250 }}
            colors={{ datum: "data.color" }}
            borderWidth={1}
            borderColor={{
              from: "color",
              modifiers: [["darker", 0.5]],
            }}
            activeOuterRadiusOffset={4}
            innerRadius={0.75}
            padAngle={1}
            cornerRadius={1}
            enableArcLabels={false}
            enableArcLinkLabels={false}
            data={data as any}
            layers={[
              "arcs",
              "arcLabels",
              "arcLinkLabels",
              "legends",
              CenteredMetric,
            ]}
            legends={[
              {
                data: data.map((item: PieDataProps, index: number) => ({
                  color: item.color,
                  id: item.source,
                  label: `${item.label}: ${item.value}`,
                })),
                anchor: "right",
                direction: "column",
                justify: false,
                translateX: -150,
                translateY: 0,
                itemsSpacing: 12,
                itemWidth: 50,
                itemHeight: 18,
                itemTextColor: "#999",
                itemDirection: "left-to-right",
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: "square",
              },
            ]}
            tooltip={({ datum }: { datum: ComputedDatum<PieDataProps> }) => {
              return <PieChartTooltip datum={datum} />;
            }}
          />
        </Box>
      ) : null}
    </Card>
  );
});

// -----------------------------------------------------------------------------

interface Props {
  title: JSX.Element | string;
  data: PieDataProps[];
  loading?: boolean;
}
