import { type MutableRefObject, memo } from "react";

import styled from "styled-components";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

// -----------------------------------------------------------------------------

export enum LoadingMoreState {
  Ready,
  Loading,
  AllLoaded,
  Error,
}

// -----------------------------------------------------------------------------

export const FindingsPageListPaneLoadMore = memo(
  ({ containerRef, triggerLoad, state }: Props) => {
    return (
      <Container
        ref={containerRef}
        onClick={() => triggerLoad(state === LoadingMoreState.Error)}
      >
        <Content>{renderMessage(state)}</Content>
      </Container>
    );
  }
);

// -----------------------------------------------------------------------------

function renderMessage(state: LoadingMoreState) {
  switch (state) {
    case LoadingMoreState.Loading:
      return <CircularProgress size={48} />;

    case LoadingMoreState.AllLoaded:
      return <>All findings loaded!</>;

    case LoadingMoreState.Error:
      return (
        <>
          <Box>There was an error loading findings...</Box>
          <Box>
            <Button>Try again</Button>
          </Box>
        </>
      );

    default:
      return <Button>Load more findings</Button>;
  }
}

// -----------------------------------------------------------------------------

const Container = styled(Box)`
  align-items: center;
  background: ${(props) => props.theme.overviewListItem.border};
  border-top: thin ${(props) => props.theme.overviewListItem.border} solid;
  display: flex;
  height: 128px;
`;

const Content = styled(Box)`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  width: 100%;
`;

// -----------------------------------------------------------------------------

interface Props {
  containerRef: MutableRefObject<HTMLDivElement | undefined>;
  state: LoadingMoreState;
  triggerLoad: Function;
}
