import { GKEKubernetesConnectSetupPage } from "./Connect";
import { redirectToGKEKubernetesConnectPage } from "./connect_handler";
import { GKEKubernetesCoverImage } from "./CoverImage";

// -----------------------------------------------------------------------------

export { GKEKubernetesConnectSetupPage, redirectToGKEKubernetesConnectPage };

export const GKEKubernetesAppConfig = {
  name: "gke_kubernetes",
  label: "GKE Kubernetes",
  coverImage: GKEKubernetesCoverImage,
  onConnect: redirectToGKEKubernetesConnectPage,
  searchTerms: ["gke kubernetes", "k8s"],
};
