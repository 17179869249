import { type ChangeEvent, memo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import MailOutlineIcon from "@mui/icons-material/MailOutline";

import { type FOTS } from "data/typescript";

import { getRememberEmail, setRememberEmail } from "utility/credential_storage";
import { isValidEmail } from "utility/is_valid_email";

// -----------------------------------------------------------------------------

export const LoginForm = memo(({ login }: Props) => {
  const navigate = useNavigate();
  const location = useLocation() as { state: locationState };
  const justRegisteredEmail = location.state?.justRegisteredEmail;
  const emailCookie = getRememberEmail();

  //
  const [emailAddress, setEmailAddress] = useState(
    justRegisteredEmail ?? emailCookie ?? ""
  );
  const [emailCheckbox, setEmailCheckbox] = useState(false);

  //
  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    if (emailCheckbox) {
      setRememberEmail(emailAddress);
    }

    e.preventDefault();
    login(emailAddress);
  }

  //
  const canLogin = isValidEmail(emailAddress);

  //
  return (
    <Box sx={{ textAlign: "center" }}>
      <Typography variant="h5" fontWeight="bold">
        Sign In
      </Typography>

      <Typography variant="body1">
        You&apos;ll be redirected automatically if your ThreatKey organization
        enabled Single Sign-On (SSO).
      </Typography>

      <form onSubmit={onSubmit}>
        <Box sx={{ mt: 2.5 }}>
          <TextField
            fullWidth
            label="Email Address"
            variant="outlined"
            value={emailAddress}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setEmailAddress(e.target.value)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailOutlineIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box sx={{ mt: 2 }}>
          <FormGroup>
            <FormControlLabel
              label="Remember this email on this device"
              control={
                <Checkbox
                  checked={emailCheckbox}
                  onChange={(event: FOTS) =>
                    setEmailCheckbox(event?.target?.checked)
                  }
                />
              }
            />
          </FormGroup>

          <LoadingButton
            fullWidth
            variant="contained"
            type="submit"
            disabled={!canLogin}
          >
            Log in
          </LoadingButton>
        </Box>
      </form>

      <Box sx={{ mt: 3 }}>
        <Typography variant="body1">
          Don&apos;t have an account?{" "}
          <Button
            size="small"
            onClick={() => navigate("/register")}
            sx={{ fontWeight: "bold" }}
          >
            REGISTER
          </Button>
        </Typography>
        <Typography variant="body2">
          By logging in, you agree to our{" "}
          <Link
            color="primary.main"
            href="https://www.threatkey.com/policies/privacy-policy"
          >
            Privacy Policy
          </Link>
          .
        </Typography>
      </Box>
    </Box>
  );
});

// -----------------------------------------------------------------------------

interface Props {
  login: Function;
}

interface locationState {
  justRegisteredEmail?: string;
}
