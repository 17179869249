import { memo, useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";

import {
  DoIf,
  ReturnIf,
  ThrowIf,
} from "babel-plugin-transform-functional-return";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { loadAllConnections, useConnectionStore } from "stores/ConnectionStore";
import { useFlairStore } from "stores/FlairStore";
import { SecretState } from "stores/lib";
import {
  OAuth2Progress,
  OAuth2URLSuffix,
  useOAuth2Store,
} from "stores/OAuth2Store";
import { useOrganizationStore } from "stores/OrganizationStore";

import { asyncOrSwim } from "utility/async_or_swim";
import { captureSentryException } from "utility/capture_sentry_exception";
import { getServicesByName, type Service } from "utility/connections";
import { notifyError, notifySuccess } from "utility/notify";

import { usePageTitle } from "effect/use_page_title";

import { ExternalDestinations } from "external/destinations";

import { LoaderModal } from "Component/Modal/LoaderModal";

import { PageContent } from "Component/PageContent";

// -----------------------------------------------------------------------------

enum Phase {
  OAuthProgress,
  LoadSecrets,
  Error,
  Done,
}

const OAuthDestinationToURLSuffix: Record<string, OAuth2URLSuffix> = {
  slack: OAuth2URLSuffix.SLACK,
};

const DestinationsByName = getServicesByName(ExternalDestinations.services);

// -----------------------------------------------------------------------------

export const EnvironmentDestinationsOAuthPage = memo(() => {
  const navigate = useNavigate();

  const searchParams = useSearchParams()[0];
  const service = searchParams.get("service") ?? "none";

  const [oauthStore, oauthActions] = useOAuth2Store();
  const [, connectionActions] = useConnectionStore();
  const [, flairActions] = useFlairStore();
  const [organizationStore] = useOrganizationStore();

  const [phase, setPhase] = useState(Phase.OAuthProgress);
  const [connectionUuid, setConnectionUuid] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const {
    label: destinationLabel = "Unknown",
    notifications: destinationNotifications = false,
    url: destinationUrl = "",
    onConnect: destinationOnConnect,
  }: Service =
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    DestinationsByName.get(service) ?? ({} as Service);

  //
  usePageTitle(`Environment → Destinations → ${destinationLabel} → OAuth`);

  //
  useEffect(
    () =>
      asyncOrSwim(
        async () => {
          ThrowIf(oauthStore.errorDetail, new Error(oauthStore.errorDetail));
          ReturnIf(!oauthStore.uuid);

          // If we are too fast the connection might not exist yet and we flash
          // an error screen. Retry a couple of times to prevent this.
          let success = false;
          for (let i = 0; i < 2; i++) {
            const connections = await loadAllConnections();
            const connection = connections.get(oauthStore.uuid as string);

            if (!connection) {
              await new Promise((resolve) => setTimeout(resolve, 1500));
              continue;
            }
            success = !!connection;

            //
            if (success) {
              switch (connection?.clientSecret?.state) {
                case SecretState.ERROR:
                case SecretState.INCORRECT: {
                  const message = `Connected to ${destinationLabel}, but there was a problem.`;

                  captureSentryException(new Error("Sentry error"), message);
                  setErrorMessage(message);
                  setPhase(Phase.Error);

                  //
                  connectionActions.loadConnections({ reload: true });
                  break;
                }

                case SecretState.CORRECT:
                  // celebration on first connection
                  DoIf(
                    connections?.size === 1,
                    flairActions.startCelebration()
                  );

                  //
                  setConnectionUuid(connection.uuid);
                  setPhase(Phase.Done);
                  notifySuccess(`Connected to ${destinationLabel}`);

                  //
                  connectionActions.loadConnections({ reload: true });
                  break;
              }
              break;
            }
          }

          ThrowIf(!success, new Error("The connection could not be found"));
        },
        (error) => {
          const message = oauthStore.errorDetail
            ? oauthStore.errorDetail
            : error.message
              ? error.message
              : `Failed connecting to ${destinationLabel}`;

          captureSentryException(error, message);
          setPhase(Phase.Error);
          setErrorMessage(
            message.includes("already been connected")
              ? "This connection already exists, please return to the list of destinations"
              : message
          );
          notifyError(`Failed connecting to ${destinationLabel}`);
        }
      ),
    [
      destinationLabel,
      oauthStore.uuid,
      connectionActions,
      flairActions,
      oauthStore.errorDetail,
    ]
  );

  if (
    phase === Phase.OAuthProgress &&
    oauthStore.progress < OAuth2Progress.Submitting
  ) {
    const code = searchParams.get("code");
    const state = searchParams.get("state") ?? organizationStore.uuid;
    const error = searchParams.get("error");
    const params = getParams(service, searchParams);

    if (error) {
      setErrorMessage(
        error === "access_denied"
          ? `${error}: This may be due to canceling the destination or a permission problem.`
          : `${error}`
      );
      setPhase(Phase.Error);
    }

    oauthActions.submitParams(
      OAuthDestinationToURLSuffix[service],
      code,
      state,
      params
    );
  }

  //
  return (
    <>
      {phase < Phase.Error ? <LoaderModal /> : null}

      {phase === Phase.Error ? (
        <PageContent>
          <Stack spacing={2} direction="column">
            <Typography variant="h5">
              There was an error connecting to the destination
            </Typography>
            <Alert severity="error">{errorMessage}</Alert>
            <Stack spacing={2} direction="row">
              <Button component={Link} to="/environment/destinations">
                Go back
              </Button>
              {destinationOnConnect ? (
                <Button
                  onClick={() =>
                    destinationOnConnect?.(organizationStore, navigate)
                  }
                >
                  Retry
                </Button>
              ) : null}
            </Stack>
          </Stack>
        </PageContent>
      ) : null}

      {phase === Phase.Done ? (
        destinationNotifications ? (
          <Navigate
            to={`/environment/destinations/${destinationUrl}/${connectionUuid}/notifications`}
          />
        ) : (
          <Navigate to="/environment/destinations" />
        )
      ) : null}
    </>
  );
});

// -----------------------------------------------------------------------------

function getParams(
  destination: string,
  searchParams: URLSearchParams
): { subdomain: string | null } | undefined {
  switch (destination.toLowerCase()) {
    case "pagerduty":
      return {
        subdomain: searchParams.get("subdomain"),
      };
    default:
      return undefined;
  }
}
