import { memo } from "react";

import styled from "styled-components";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

// -----------------------------------------------------------------------------

export const AssetInventoryAsset = memo(({ data, triggerLoad }: Props) => {
  return (
    <Container>
      <Box>
        <strong>ID</strong>: {data.id[0] || "ID not available"}
      </Box>
      <Box>
        <strong>First seen at</strong>:{" "}
        {data.fields.created_at || "First seen at time not available"}
      </Box>
      <Box>
        <strong>Last seen at</strong>:{" "}
        {data.fields.updated_at || "Last seen at time not available"}
      </Box>
      <Box>
        <strong>Type</strong>:
        <Button onClick={() => triggerLoad(data.type)} size="small">
          {data.name?.description || data.type || "Type not available"}
        </Button>
      </Box>
    </Container>
  );
});

// -----------------------------------------------------------------------------

const Container = styled(Box)`
  padding: clamp(8px, calc(0.5rem + ((1vw - 3.75px) * 0.9697)), 16px);

  &:nth-child(even) {
    background: ${(props) => props.theme.overviewListItem.mainFaded};
  }

  & + & {
    border-top: thin ${(props) => props.theme.overviewListItem.border} solid;
  }
`;
// -----------------------------------------------------------------------------

interface Props {
  data: {
    fields: {
      created_at: string;
      updated_at: string;
    };
    id: string[];
    type: string;
    name?: {
      description?: string;
      type?: string;
    };
  };
  triggerLoad: Function;
}
