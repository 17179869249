import { memo } from "react";

import SvgIcon from "@mui/material/SvgIcon";

// -----------------------------------------------------------------------------

export const GenericSAMLSSOIcon = memo((props: object) => {
  return (
    <SvgIcon viewBox="0 0 82 24" {...props}>
      <path
        d="M6.77716 24C12.265 24 14.8767 21.2581 14.8767 16.8575C14.8767 12.7278 12.7278 11.2045 9.15743 10.1551L7.04163 9.47814C4.7936 8.83498 4.13241 8.05642 4.13241 6.22849C4.13241 4.36671 5.45479 3.3512 7.83506 3.3512C10.3476 3.3512 12.1989 3.89281 13.6204 4.50212V1.25247C12.3642 0.575459 10.7443 0 7.76894 0C2.64474 0 0.165297 2.67419 0.165297 6.60085C0.165297 10.5275 2.1158 12.2877 5.32255 13.2017L7.4714 13.811C9.95085 14.5896 10.7773 15.3343 10.7773 17.2638C10.7773 19.3625 9.48802 20.6488 6.44657 20.6488C3.96712 20.6488 1.81826 20.0395 0 19.2271V22.5106C1.55379 23.3568 3.80182 24 6.77716 24Z"
        fill="#C32527"
      />
      <path
        d="M16.7355 23.5938H20.5043L22.5539 17.7038H30.9841L33.0337 23.5938H37.2323L29.1658 0.372355H25.0003L16.7355 23.5938ZM26.6864 5.31453H26.7855L29.9592 14.5896H23.5127L26.6864 5.31453Z"
        fill="#C32527"
      />
      <path
        d="M40.485 23.5938H44.0554V5.55148H44.1546L49.8077 23.5938H53.1467L58.8329 5.55148H58.9652V23.5938H63.0314V0.372355H57.3452L51.8243 17.8731H51.7252L46.2043 0.372355H40.485V23.5938Z"
        fill="#C32527"
      />
      <path
        d="M68.3465 23.5938H82V20.3103H72.4128V0.372355H68.3465V23.5938Z"
        fill="#C32527"
      />
    </SvgIcon>
  );
});
