import { useContext } from "react";

import type { PaletteMode } from "@mui/material";

import { createTheme, type Theme } from "@mui/material/styles";

import { ThemeContext, ThemeMode } from "theme/context";

import { baseTheme } from "./data/base";
import darkJson from "./data/dark.json";
import lightJson from "./data/light.json";

// -----------------------------------------------------------------------------

export function getMuiTheme(themeMode: ThemeMode): Theme {
  return createTheme({
    ...baseTheme,

    palette: {
      mode: getThemeModeName(themeMode),
      ...(themeMode === ThemeMode.Light ? lightJson : darkJson),
    },
  });
}

export function getStyledTheme(themeMode: ThemeMode) {
  return themeMode === ThemeMode.Light ? lightJson : darkJson;
}

export function getThemeModeName(themeMode: ThemeMode): PaletteMode {
  return themeMode === ThemeMode.Light ? "light" : "dark";
}

export function useThemeModeIsLight() {
  const { finalThemeMode } = useContext(ThemeContext);

  //
  return finalThemeMode === ThemeMode.Light;
}
