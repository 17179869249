import { memo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { ReturnIf } from "babel-plugin-transform-functional-return";

import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RefreshIcon from "@mui/icons-material/Refresh";

import { type FOTS } from "data/typescript";

import { type Connection, useConnectionStore } from "stores/ConnectionStore";
import { SecretState } from "stores/lib";
import { useOrganizationStore } from "stores/OrganizationStore";

import { asyncOrSwimWithSentry } from "utility/async_or_swim_sentry";
import { type Service } from "utility/connections";
import { dayjs } from "utility/dayjs";
import { notifyError, notifySuccess } from "utility/notify";

import { ConfirmationModal } from "Component/Modal/ConfirmationModal";

import { ConnectionExtraDetails } from "Component/ConnectionExtraDetails";

// -----------------------------------------------------------------------------

export const EnvironmentConnectedService = memo(
  ({ connection, service }: Props) => {
    const navigate = useNavigate();

    const [organizationStore] = useOrganizationStore();
    const [, connectionsActions] = useConnectionStore();

    //
    const [anchorElUser, setAnchorElUser] = useState(null);

    const [showEditModal, setShowEditModal] = useState(false);
    const [editConnectionName, setEditConnectionName] = useState("");
    const [editConnectionUuid, setEditConnectionUuid] = useState("");
    const [editLoading, setEditLoading] = useState(false);

    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
      useState(false);
    const [showReconnectConfirmationModal, setShowReconnectConfirmationModal] =
      useState(false);

    //
    function openMenu(event: any) {
      setAnchorElUser(event.currentTarget);
    }

    function closeMenu() {
      setAnchorElUser(null as any);
    }

    function openEditModal() {
      setShowEditModal(true);
      setEditConnectionName(connection.name);
      setEditConnectionUuid(connection.uuid);
    }

    function onEditModalClose() {
      setShowEditModal(false);
      setEditConnectionName("");
      setEditConnectionUuid("");
    }

    function saveEdit() {
      setEditLoading(true);
      connectionsActions.editConnection({
        uuid: editConnectionUuid,
        data: {
          name: editConnectionName,
        },
        onSuccess: onEditSuccess,
        onError: onEditError,
      });
    }

    function onEditSuccess() {
      setEditLoading(false);
      setEditConnectionName("");
      setEditConnectionUuid("");
      setShowEditModal(false);
      notifySuccess(`Updated the connection name`);
    }

    function onEditError() {
      setEditLoading(false);
      setEditConnectionName("");
      setEditConnectionUuid("");
      setShowEditModal(false);
      notifyError("Something went wrong updating the connection name");
    }

    function confirmDeletion() {
      setShowDeleteConfirmationModal(true);
    }

    function onDeleteConfirmationModalClose(choice: number) {
      setShowDeleteConfirmationModal(false);
      ReturnIf(choice < 1);
      asyncOrSwimWithSentry(
        async () => {
          function onSuccess() {
            connectionsActions.loadConnections({ reload: true });
            notifySuccess(
              `Deleted ${connection.name} (${service.label}) connection`
            );
          }

          function onError() {
            connectionsActions.loadConnections({ reload: true });
            notifyError(
              `Failed to delete ${connection.name} (${service.label}) connection`
            );
          }

          await connectionsActions.deleteConnection({
            uuid: connection.uuid,
            onSuccess,
            onError,
          });
        },
        `Failed to delete ${connection.name} (${service.label}) connection`,
        (error) => {
          console.error(error);
          connectionsActions.loadConnections({ reload: true });
          notifyError(
            `Failed to delete ${connection.name} (${service.label}) connection`
          );
        }
      );
    }

    function confirmReconnect() {
      setShowReconnectConfirmationModal(true);
    }

    function onReconnectConfirmationModalClose(choice: number) {
      setShowReconnectConfirmationModal(false);
      ReturnIf(choice < 1);
      asyncOrSwimWithSentry(
        async () => {
          await connectionsActions.deleteConnection({
            uuid: connection.uuid,
            onSuccess: async () =>
              await connectionsActions.loadConnections({ reload: true }),
            onError: () =>
              notifyError(
                `Failed to reconnect ${connection.name} (${service.label}) connection`
              ),
          });
          service?.onConnect?.(organizationStore, navigate);
        },
        `Failed to reconnect ${connection.name} (${service.label}) connection`,
        (error) => {
          console.error(error);
          notifyError(
            `Failed to reconnect ${connection.name} (${service.label}) connection`
          );
        }
      );
    }

    //
    const clientSecret = connection.clientSecret;
    const [color, status, StatusIcon] = getConfig(
      clientSecret?.state ?? SecretState.RECEIVED
    );
    const extraConnectionDetails =
      service.onExtraConnectionDetails?.(connection) ?? null;
    const Logo: FOTS = service.coverImage;

    //
    return (
      <>
        <Card
          elevation={1}
          sx={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            padding: 1.5,
          }}
        >
          <Stack
            direction="column"
            alignItems="center"
            mx={{ flexBasis: 1, flexGrow: 0.1 }}
          >
            <Logo
              sx={{
                width: "80%",
                height: "80%",
                maxHeight: 90,
                align: "center",
                padding: 1,
              }}
            />
          </Stack>

          <Stack direction="column" mx={{ flexBasis: 2, flexGrow: 0.9 }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {connection.name}
              </Typography>
              <IconButton aria-label="edit" onClick={openEditModal}>
                <EditIcon />
              </IconButton>
            </Stack>
            <Typography variant="body1" fontWeight="bold">
              {service.label}
            </Typography>

            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <Typography variant="body1" sx={{ mr: 1.5 }}>
                Connected:{" "}
                {clientSecret != null
                  ? dayjs(new Date(clientSecret.created)).format("MM/DD/YYYY")
                  : "Unknown"}
              </Typography>
              <Typography
                variant="body1"
                sx={{ display: "flex", alignItems: "center", mr: 1.5 }}
              >
                Status: {status} <Box component="span" sx={{ width: 4 }} />
                <StatusIcon color={color} />
              </Typography>
            </Box>
            <ConnectionExtraDetails connection={connection} service={service} />
            {extraConnectionDetails}
            {service.notifications ? (
              <Box>
                <Link
                  to={`/environment/destinations/${service.url ?? ""}/${
                    connection.uuid
                  }/notifications`}
                  style={{ textDecoration: "none" }}
                >
                  <Button variant="text">Notification Settings</Button>
                </Link>
              </Box>
            ) : null}
          </Stack>

          <IconButton size="large" color="inherit" onClick={openMenu}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={closeMenu}
          >
            {service.configUrl ? (
              <MenuItem
                onClick={() =>
                  service.configUrl ? navigate(service.configUrl) : null
                }
              >
                Configure
              </MenuItem>
            ) : null}
            <MenuItem onClick={confirmReconnect}>
              <RefreshIcon />
              Reconnect
            </MenuItem>

            <MenuItem onClick={confirmDeletion}>
              <DeleteIcon />
              Disconnect
            </MenuItem>
          </Menu>
        </Card>

        {showDeleteConfirmationModal && (
          <ConfirmationModal
            confirmLabel="Delete"
            onClose={onDeleteConfirmationModalClose}
          >
            <>Delete this connection?</>
            <>This action cannot be undone.</>
          </ConfirmationModal>
        )}
        {showReconnectConfirmationModal && (
          <ConfirmationModal
            confirmLabel="Reconnect"
            onClose={onReconnectConfirmationModalClose}
          >
            <>Reconnect this connection?</>
            <>
              This action cannot be undone and is essentially a
              delete-then-connect.
            </>
          </ConfirmationModal>
        )}

        <Dialog onClose={onEditModalClose} open={showEditModal}>
          <DialogTitle>Edit Connection Name</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Name your connection something to help distinguish it from the
              others.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Connection name"
              type="text"
              fullWidth
              variant="standard"
              value={editConnectionName}
              onChange={(e: FOTS) => setEditConnectionName(e?.target?.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onEditModalClose}>Cancel</Button>
            <LoadingButton loading={editLoading} onClick={saveEdit}>
              Save
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </>
    );
  }
);

// -----------------------------------------------------------------------------

function getConfig(
  state: SecretState
): ["error" | "success" | "warning", string, FOTS] {
  switch (state) {
    case SecretState.ERROR:
    case SecretState.INCORRECT:
      return ["error", "Error", ErrorOutlineIcon];

    case SecretState.CORRECT:
      return ["success", "Connected", CheckCircleOutlineIcon];

    case SecretState.PENDING_EXTERNAL:
    case SecretState.PENDING_INTERNAL:
    case SecretState.RECEIVED:
      return ["warning", "Pending", AccessTimeIcon];

    default:
      return ["warning", "Unknown", HelpOutlineIcon];
  }
}

// -----------------------------------------------------------------------------

interface Props {
  connection: Connection;
  service: Service;
}
