import { memo, useEffect, useState } from "react";

import styled from "styled-components";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { useDashboardStore } from "stores/DashboardStore";
import { SubmitState } from "stores/lib";

import { dayjs } from "utility/dayjs";

import { usePageTitle } from "effect/use_page_title";

import { Page } from "Component/Page";
import { PageContent } from "Component/PageContent";
import { PageHeader } from "Component/PageHeader";
import { PageSubTitle } from "Component/PageSubTitle";
import { PageTitle } from "Component/PageTitle";

import { StaleFindingsCharts as Charts } from "./Charts";
import { getScatterData } from "./lib/get_scatter_data";
import { StaleFindingsNoData as NoData } from "./NoData";
import { StaleFindingsDataTable as Table } from "./Table";

// -----------------------------------------------------------------------------

const DayInSeconds = 86400;

// -----------------------------------------------------------------------------

export const StaleFindingsPage = memo(() => {
  usePageTitle("Reporting → Stale Findings");

  const [dashboardStore, dashboardActions] = useDashboardStore();
  const [scatterData, setScatterData] = useState(getScatterData([]));

  // Load median stale length
  useEffect(() => {
    switch (dashboardStore.loadMedianStaleLength) {
      case undefined:
        dashboardActions.loadMedianStaleLength();
        break;

      case SubmitState.SUCCESS:
        break;

      case SubmitState.ERROR:
        break;

      default:
        break;
    }
  }, [
    dashboardStore.loadMedianStaleLength,
    dashboardStore.medianStaleLength,
    dashboardActions,
  ]);

  // Load stale findings
  useEffect(() => {
    switch (dashboardStore.loadStaleFindings) {
      case undefined:
        dashboardActions.loadStaleFindings();
        break;

      case SubmitState.SUCCESS:
        setScatterData(getScatterData(dashboardStore.staleFindings ?? []));
        break;

      case SubmitState.ERROR:
        setScatterData([]);
        break;

      default:
        break;
    }
  }, [
    dashboardStore.loadStaleFindings,
    dashboardStore.staleFindings,
    dashboardActions,
  ]);

  //
  const isLoadingMedianStaleLength =
    dashboardStore.loadMedianStaleLength === SubmitState.STARTED;
  const isLoadingStaleFindings =
    dashboardStore.loadStaleFindings === SubmitState.STARTED;
  const loading = isLoadingStaleFindings || isLoadingMedianStaleLength;
  const hasStaleFindings = (dashboardStore.staleFindings ?? []).length > 0;

  //
  return (
    <Page>
      <PageHeader>
        <PageTitle>Stale Findings</PageTitle>
        <PageSubTitle>
          This dashboard shows all findings that require attention but have not
          been addressed in a timely manner.
        </PageSubTitle>
      </PageHeader>

      <PageContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            marginBottom: 2,
          }}
        >
          <Typography variant="caption" fontWeight="light">
            Showing data as of {dayjs().format("MM/DD/YYYY")}
          </Typography>
        </Box>
        <Grid container sx={{ display: "flex", flex: "1", gap: "24px" }}>
          {loading ? (
            <LoadingBox>
              <CircularProgress />
            </LoadingBox>
          ) : hasStaleFindings ? (
            <>
              <Charts
                scatterData={scatterData}
                isLoading={isLoadingStaleFindings || isLoadingMedianStaleLength}
                median={
                  dashboardStore.medianStaleLength
                    ? Math.floor(
                        dashboardStore.medianStaleLength / DayInSeconds
                      )
                    : 0
                }
              />
              <Table
                data={dashboardStore.staleFindings ?? []}
                isLoading={isLoadingStaleFindings}
              />
            </>
          ) : (
            <NoData
              cta={{
                title: "Connect more sources",
                url: "/environment/sources",
              }}
              bodyText="Your system is currently free of stale findings. Keep using ThreatKey to maintain the security and integrity of your systems."
            />
          )}
        </Grid>
      </PageContent>
    </Page>
  );
});

// -----------------------------------------------------------------------------

const LoadingBox = styled(Box)`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`;
