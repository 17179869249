import { memo } from "react";

import { type ButtonProps } from "@mui/material";

import Button from "@mui/material/Button";

// ----------------------------------------------------------------------------

export const ModalCancelButton = memo(({ onClick, disabled }: Props) => {
  return (
    <Button variant="outlined" disabled={disabled} onClick={onClick}>
      Cancel
    </Button>
  );
});

// -----------------------------------------------------------------------------

type Props = Pick<ButtonProps, "onClick" | "disabled">;
