import { memo } from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";

import { ServiceConnectionPhase } from "Component/ServiceConnectionPhase";

// -----------------------------------------------------------------------------

export const OktaConfigurePageVerifyingAPITokenPhase = memo(
  ({ disabled, organization, progressIcon, successIcon, errorIcon }: Props) => {
    return (
      <ServiceConnectionPhase disabled={disabled}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {progressIcon ? (
            <CircularProgress size="24px" sx={{ mr: 1 }} />
          ) : null}
          {successIcon ? (
            <CheckCircleOutlineRoundedIcon color="success" sx={{ mr: 1 }} />
          ) : null}
          {errorIcon ? (
            <ErrorOutlineRoundedIcon color="error" sx={{ mr: 1 }} />
          ) : null}
          <Typography variant="body1">
            Connecting{" "}
            <strong>{organization || "https://your-org-here.okta.com"}</strong>.
            Verifying API token
          </Typography>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1">
            We validate the Okta credentials to confirm they can successfully
            establish a connection.
          </Typography>
        </Box>
      </ServiceConnectionPhase>
    );
  }
);

// -----------------------------------------------------------------------------

interface Props {
  disabled: boolean;
  organization: string;
  progressIcon: boolean;
  successIcon: boolean;
  errorIcon: boolean;
}
