import { type ReactNode, memo } from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// -----------------------------------------------------------------------------

export const ProvisioningErrors = memo(({ data }: Props) => {
  let secretDetails: any = "";

  if (data.secret_details) {
    try {
      secretDetails = JSON.parse(data.secret_details);
    } catch (error: any) {
      secretDetails = data.secret_details;
    }
  }

  let message: ReactNode = (
    <Typography variant="body1">
      <strong>Error:</strong> An error occurred
    </Typography>
  );

  // Why are there so many different formats for provisioning errors
  if (secretDetails.errors && Array.isArray(secretDetails.errors)) {
    message = secretDetails.errors.map((error: string, i: number) => (
      <Typography variant="body1" key={i}>
        <strong>Error:</strong> {error}
      </Typography>
    ));
  } else if (Array.isArray(secretDetails)) {
    message = secretDetails.map((error: string, i: number) => (
      <Typography variant="body1" key={i}>
        <strong>Error:</strong> {error}
      </Typography>
    ));
  } else if (secretDetails.info && Object.keys.length > 0) {
    message = Object.entries(secretDetails.info as InfoObject).map(
      ([key, info]) => (
        <Typography variant="body1" key={key}>
          <strong>{key}:</strong> {info}
        </Typography>
      )
    );
  } else if (typeof secretDetails === "string") {
    message = (
      <Typography variant="body1">
        <strong>Error:</strong> {secretDetails}
      </Typography>
    );
  }

  //
  return (
    <Stack spacing={1} direction="column">
      {message}
    </Stack>
  );
});

// -----------------------------------------------------------------------------

interface Props {
  data: ProvisionStateResponse;
}

// -----------------------------------------------------------------------------

interface ProvisionStateResponse {
  secret_details?: string;
}

interface InfoObject {
  [key: string]: string;
}
