import { memo } from "react";

import { captureSentryException } from "utility/capture_sentry_exception";

import { MagicLinkErrorContactButton as Button } from "./ContactButton";

// -----------------------------------------------------------------------------

export const MagicLinkErrorMessage = memo(({ errorMessage }: Props) => {
  captureSentryException(
    new Error("Magic Link redirect error"),
    errorMessage,
    "info"
  );

  switch (errorMessage) {
    case "missing_code":
      return (
        <>
          Code parameter is missing. Please retry the link from your email or
          try logging in again.
          <br />
          <br />
          <Button />
        </>
      );

    case "redirect_url":
      return (
        <>
          Redirect url is either missing or invalid. Please retry the link from
          your email or try logging in again.
          <br />
          <br />
          <Button />
        </>
      );

    default:
      return (
        <>
          Something went wrong while trying to log in.
          <br />
          <br />
          <Button />
        </>
      );
  }
});

// -----------------------------------------------------------------------------

interface Props {
  errorMessage: string;
}
