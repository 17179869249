import { memo } from "react";

import SvgIcon from "@mui/material/SvgIcon";

// -----------------------------------------------------------------------------

export const NetIQSAMLSSOIcon = memo((props: object) => {
  return (
    <SvgIcon viewBox="0 0 486.94 139.47" {...props}>
      <path
        d="M574.65,345.94c2,.89,1.66,2.66,1.66,4.3,0,22.48,0,45,0,67.44a6.35,6.35,0,0,1-3,5.81c-6,4.14-12.4,6.32-19.7,3.93-3.92-1.29-8-2.3-11.71-4-16.72-7.44-22-24.68-14-40.33,1.28-2.51,2.87-4.85,4.25-7.3,3.07-5.49,6.1-11,9.14-16.5,4.63-1.68,9.31-3.21,13.86-5.06C561.68,351.61,568.15,348.73,574.65,345.94Z"
        fill="#0173be"
        transform="translate(-458.21 -315.37)"
      />
      <path
        d="M515.34,454.34a19.1,19.1,0,0,1-4.05-1.65c-16.47-11.63-32.85-23.37-49.36-34.94-1.93-1.35-3.45-2.81-3.55-5-.42-9-.43-17.85,4.21-26.09,4-7.07,9.64-11.72,17.63-13a19.83,19.83,0,0,1,7.79.81c12.77,3.2,22.2,10.49,27.29,22.89Q515.31,425.82,515.34,454.34Z"
        fill="#aacae9"
        transform="translate(-458.21 -315.37)"
      />
      <path
        d="M651.46,389.06V357.67c0-8.41,3.27-11,11.54-9.2,2.79.61,4.21,2.18,4.28,5.26.12,4.49,0,9,0,13.48,0,13.66,0,27.31,0,41,0,5.42-1.8,7-7.1,7.41-4.74.37-8.26-1.34-11.42-4.78-10.46-11.4-21.07-22.66-31.65-33.95a31.26,31.26,0,0,0-2.7-2.21c-.12,2.1-.26,3.5-.26,4.91,0,9.49,0,19,0,28.47,0,6.17-1.44,7.49-7.7,7.51-1.16,0-2.34.07-3.49,0-3-.27-4.44-1.67-4.78-4.67a12.52,12.52,0,0,1,0-1.5c0-17.81,0-35.63,0-53.44,0-5.31,1.93-7,7.16-7.51,5-.46,8.51,1.78,11.74,5.28C627.45,364.9,638,376,648.41,387.21A15.66,15.66,0,0,1,650,389.7Z"
        fill={props.theme?.netiqLogo.main}
        transform="translate(-458.21 -315.37)"
      />
      <path
        d="M459.23,346.37c-.07-2.16,1.64-2.69,3.13-3.38,18.93-8.84,37.88-17.63,56.77-26.52,3.76-1.77,7-1.44,10.12,1.29a20.43,20.43,0,0,1,6.49,9.21c1.65,5.23,3.28,10.42,3.55,15.94.67,14-7.47,23-21.22,25.53-8.4,1.54-16.47.16-24.62-1.3a40,40,0,0,0-4.71-4q-10.29-6.36-20.77-12.43C465.16,349.12,462.15,347.82,459.23,346.37Z"
        fill="#5ca5d7"
        transform="translate(-458.21 -315.37)"
      />
      <path
        d="M876.24,406.3c3.06,0,6.12-.08,9.19-.1,4.5,0,6.54,2.64,4.88,6.68a5.13,5.13,0,0,1-2.66,2.53,13.45,13.45,0,0,1-4.42.51c-8.79.22-17.58.61-26.36.51-5.79-.06-11.75-.11-17.3-1.49-12.78-3.19-20.9-13.61-22-27.67a49.47,49.47,0,0,1,.9-13.84c2.83-13.45,13.42-22.5,27.11-24.13a48.46,48.46,0,0,1,16.82.67c18.7,4.36,26.3,20.31,24.47,36.85-.87,7.92-4.61,14.34-10.54,19.55l-.67.42.56.19Zm-44.19-23.57a86.67,86.67,0,0,0,1.48,8.79c2.17,8,8.88,13.34,17.6,13.62s15.75-3.18,19-11.58a28.55,28.55,0,0,0,.29-20.43c-3.55-10.05-11.13-12.56-18.87-12.5-9.11.07-15.77,5.35-18.07,13.68A84.87,84.87,0,0,0,832.05,382.73Z"
        fill={props.theme?.netiqLogo.main}
        transform="translate(-458.21 -315.37)"
      />
      <path
        d="M710.16,391.94c-4.49,0-9-.12-13.49,0s-6.38,3.53-4.34,7.61c1.27,2.55,3.51,3.91,6.1,4.81,5.69,2,11.48,1.61,17.26.58,3.43-.61,6.8-1.61,10.24-2.16a4.5,4.5,0,0,1,3.44,1c.51.54.37,2.44-.2,3.17-1.49,1.92-3.08,4.15-5.16,5.18-12.52,6.19-25.1,5.64-37.26-1-6.67-3.63-9.5-10.07-10.22-17.35-1.83-18.39,10.23-31.62,28.79-31.58a38.52,38.52,0,0,1,10.34,1.21c10,2.81,15.69,12.27,14.59,23.8-.29,3-2.47,4.57-6.1,4.66C719.49,392,714.83,391.94,710.16,391.94Zm-4.51-19.79c-6.09-.12-10.65,2.08-13.68,7-1.35,2.19-.71,3.47,1.81,3.73a59,59,0,0,0,6,.07c5,0,9.94.13,14.89-.05,3.74-.13,4.5-1.66,2.48-4.67A13.15,13.15,0,0,0,705.65,372.15Z"
        fill={props.theme?.netiqLogo.main}
        transform="translate(-458.21 -315.37)"
      />
      <path
        d="M746.73,389.69c0-3.49.08-7,0-10.49-.12-4.29-.72-4.86-4.88-5.26a17,17,0,0,1-3-.32,5.33,5.33,0,0,1-3.81-5.73,5.27,5.27,0,0,1,4.61-4.51c.49-.07,1,0,1.5,0,5.59-.45,6.13-1,6.28-6.66.2-6.95,4.82-10.06,11.34-7.33,1.43.6,2.38,2.5,3.38,3.92.32.46.12,1.29.15,2,.34,7.88.57,8.1,8.29,8.12,1.83,0,3.67-.11,5.49,0a5.34,5.34,0,0,1,5,4.76,5.21,5.21,0,0,1-4.57,5.61c-3,.33-6,.09-9,.21-3.79.15-4.79,1-4.87,4.75q-.25,10.5,0,21c.1,4.18,1.62,5.46,5.84,6,3.32.41,6.15-.91,9-2.24,1.08-.5,3.07-1,3.52-.48a5.46,5.46,0,0,1,1.06,3.95c-.58,4.44-3.45,7.16-7.62,8.28a28.19,28.19,0,0,1-18.61-.94c-5.12-2.15-8.63-5.7-8.84-11.57-.16-4.32,0-8.65,0-13Z"
        fill={props.theme?.netiqLogo.main}
        transform="translate(-458.21 -315.37)"
      />
      <path
        d="M792.72,382.7c0-9,0-18,0-27,0-2.81.1-5.64,3.35-6.44s7.09-1.58,9.93,1.11c1.11,1.06,1.52,3.27,1.56,5,.18,6.15.05,12.31.05,18.47q0,17.23,0,34.46c0,1.16,0,2.33-.07,3.49a5.22,5.22,0,0,1-.62,2.38c-2.6,3.83-6.59,2.43-10.07,2.3-2.76-.11-4-2-4.07-5.32C792.66,401.67,792.72,392.18,792.72,382.7Z"
        fill={props.theme?.netiqLogo.main}
        transform="translate(-458.21 -315.37)"
      />
      <path
        d="M515.34,454.34q0-28.53,0-57c1,1.64,2.11,3.23,3,4.93,1.09,2,2,4.18,3,6.28a47.48,47.48,0,0,0,26.22,24.61c.92.37,1.84.75,2.75,1.15.31.13.59.31,1.1.59a12.25,12.25,0,0,1-1.83,1.57c-10,5.59-20,11.18-30,16.68A20.24,20.24,0,0,1,515.34,454.34Z"
        fill="#0274bf"
        transform="translate(-458.21 -315.37)"
      />
      <path
        d="M459.23,346.37c2.92,1.45,5.93,2.75,8.74,4.38q10.47,6.08,20.77,12.43a40,40,0,0,1,4.71,4c-4.73.59-9.53.86-14.19,1.83A31.25,31.25,0,0,0,462,379.08c-1,1.08-2.11,2-3.68,3.44,0-12,0-23.28,0-34.56C458.35,347.43,458.92,346.9,459.23,346.37Z"
        fill="#aacae9"
        transform="translate(-458.21 -315.37)"
      />
      <path
        d="M574.65,345.94c-6.5,2.79-13,5.67-19.52,8.33-4.55,1.85-9.23,3.38-13.86,5.06.66-2.7,1.45-5.39,2-8.12,1.33-7.19,1.84-14.39.22-21.62a26.68,26.68,0,0,1-.19-3.27c2.94,1.67,5.4,3,7.83,4.47,7.09,4.22,14.18,8.43,21.22,12.73A12.82,12.82,0,0,1,574.65,345.94Z"
        fill="#5da5d7"
        transform="translate(-458.21 -315.37)"
      />
    </SvgIcon>
  );
});
