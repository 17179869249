import { memo } from "react";

import Stack from "@mui/material/Stack";

// -----------------------------------------------------------------------------

export const ServiceConnectionPhases = memo(({ children }: Props) => {
  return (
    <Stack spacing="clamp(8px, 0.5rem + (1vw - 3.75px) * 1.9394, 24px)">
      {children}
    </Stack>
  );
});

// -----------------------------------------------------------------------------

interface Props {
  children: React.ReactNode;
}
