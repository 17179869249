import { memo } from "react";

import Typography from "@mui/material/Typography";

import { usePageTitle } from "effect/use_page_title";

import { Page } from "Component/Page";
import { PageContent } from "Component/PageContent";
import { PageHeader } from "Component/PageHeader";
import { PageHeaderBackButton } from "Component/PageHeaderBackButton";
import { PageTitle } from "Component/PageTitle";
import { ServiceConnectionInstructionPhase } from "Component/ServiceConnectionInstructionPhase";
import { ServiceConnectionPhases } from "Component/ServiceConnectionPhases";

import { JiraCloudConnectPageAddAppPhase as AddAppPhase } from "./Phase/AddAppPhase";

// -----------------------------------------------------------------------------

export const JiraCloudConnectPage = memo(() => {
  usePageTitle("Environment → Destinations → Jira Cloud → Connect");

  //
  return (
    <Page>
      <PageHeader>
        <PageHeaderBackButton to="/environment/destinations">
          Back to Destinations
        </PageHeaderBackButton>
        <PageTitle>Jira Cloud Issue Sync</PageTitle>
      </PageHeader>

      <PageContent>
        <ServiceConnectionPhases>
          <ServiceConnectionInstructionPhase>
            <Typography variant="body1">
              Jira Cloud Issue Sync allows the ThreatKey platform to connect to
              a Jira Cloud instance, synchronizing ThreatKey findings with
              issues in a Jira project in near real-time. When a finding is
              created or updated in ThreatKey, a corresponding issue is created
              or updated in a configured Jira project.
            </Typography>
            <Typography variant="body1">
              Sync can be configured to be bidirectional, such that status
              changes made to an associated issue in Jira are be reflected in
              the ThreatKey finding.
            </Typography>
            <Typography variant="body1">
              For more details on how this works - including information
              relevant to experienced Jira administrators and power users -
              please reference our{" "}
              <a
                href="https://docs.threatkey.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                issue sync documentation
              </a>
              .
            </Typography>
          </ServiceConnectionInstructionPhase>

          <AddAppPhase />
        </ServiceConnectionPhases>
      </PageContent>
    </Page>
  );
});
