import * as Sentry from "@sentry/react";
import { createStore } from "redux";

// -----------------------------------------------------------------------------

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

const store = createStore(sentryReduxEnhancer);

export { store };
