import { authenticatedGetFetch } from "utility/fetch/authenticated";

// -----------------------------------------------------------------------------

export async function getServerPublicKey() {
  return await authenticatedGetFetch<{
    pem: string;
    prefix: string;
    algo: string;
  }>("api/cryptography/public_key");
}
