import { memo, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { ReturnIf } from "babel-plugin-transform-functional-return";
import isEmpty from "lodash/isEmpty";
import styled from "styled-components";

import { type FOTS } from "data/typescript";

import { useUserStore } from "stores/UserStore";

import { LoaderModal } from "Component/Modal/LoaderModal";

import { RemoveLoader } from "../RemoveLoader";

import { CelebrationOverlay } from "./CelebrationOverlay";
import { FreeTrialBar } from "./FreeTrialBar";
import { Header } from "./Header";
import { Sidebar } from "./Sidebar";
import { SpecialThing } from "./SpecialThing";

// -----------------------------------------------------------------------------

export const WithNavigationLayout = memo(() => {
  const [userStore] = useUserStore();
  const navigate = useNavigate();

  const [renderContent, setRenderContent] = useState(false);

  //
  useEffect(
    () =>
      isEmpty(userStore.user) ? navigate("/login") : setRenderContent(true),
    [userStore, navigate]
  );

  useEffect(() => {
    // The default colors are correct here, so we don't have to bully ortto
    // like we did in WithoutNavigationLayout, just undo the changes (if set)

    const parent: FOTS = document.body
      .querySelector("#ap3-talk-widget-ui")
      ?.shadowRoot?.querySelector("#__root");
    ReturnIf(parent === undefined);

    // reset the CSS variable
    parent.style.setProperty(
      "--brand-action-rgb",
      parent.style.getPropertyValue("--brand-header-rgb")
    );

    // clear the color style
    parent.lastChild.querySelector("svg")?.style.removeProperty("color");
  }, []);

  //
  return renderContent ? (
    <>
      <RemoveLoader />
      <SpecialThing />
      <CelebrationOverlay />
      <Header />
      <Container>
        <Sidebar />

        <MainWrapper>
          <FreeTrialBar />
          <Content>
            <Outlet />
          </Content>
        </MainWrapper>
      </Container>
    </>
  ) : (
    <LoaderModal />
  );
});

// -----------------------------------------------------------------------------

const Container = styled.div`
  background-color: ${(props) => props.theme.appBackground.main};
  display: grid;
  grid-template-columns: auto 1fr;
  height: 100%;
  width: 100%;

  @media (max-width: 800px) {
    display: block;
  }
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  min-height: 0vw;
  padding: 0;

  padding-bottom: 0;
  width: 100%;
`;
