import { type MouseEvent, memo, useRef, useState } from "react";

import { ReturnIf } from "babel-plugin-transform-functional-return";
import styled from "styled-components";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AdjustIcon from "@mui/icons-material/Adjust";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PolicyIcon from "@mui/icons-material/Policy";
import ReportIcon from "@mui/icons-material/Report";
import UndoIcon from "@mui/icons-material/Undo";
import WarningIcon from "@mui/icons-material/Warning";

import {
  FindingSeverityColor,
  FindingSeverityLabel,
  FindingStateLabel,
  type ServerFindingWithType,
  SourceLabel,
} from "data/finding";
import { type FOTS } from "data/typescript";

import { dayjs } from "utility/dayjs";
import { classIf, classes } from "utility/style";

import { ReactMarkdown } from "Component/ReactMarkdown";

import { FindingIssue as Issue } from "./FindingIssue";
import { RawDetails } from "./RawDetails";

// -----------------------------------------------------------------------------

export const FindingsPageListFinding = memo(
  ({
    finding,
    onToggle = () => {},
    selected = false,
    selectable = true,
    startExpanded = false,
    limitedView = false,
  }: Props) => {
    const fixDescription = finding.type?.fix_description?.trim() ?? "";
    const undoFixDescription = finding.fix?.undo?.trim() ?? "";

    //
    const findingRef = useRef<HTMLDivElement>(null);

    //
    const [expanded, setExpanded] = useState(startExpanded);
    const [showRawDetails, setShowRawDetails] = useState(false);
    const [showExplanation, setShowExplanation] = useState(false);
    const [showUndo, setShowUndo] = useState(false);

    //
    function preventDefaultAnd(callback: Function) {
      return (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        e.preventDefault();

        callback?.();
      };
    }

    function toggleFinding(event: MouseEvent<HTMLDivElement>) {
      ReturnIf((event.target as FOTS).tagName === "A");

      //
      const selection = window.getSelection();
      ReturnIf(!selection?.isCollapsed);

      //
      setExpanded((value) => !value);
    }

    //
    return (
      <Container
        className={classes(
          classIf(selected, "selected"),
          classIf(expanded, "expanded"),
          classIf(limitedView, "limitedView")
        )}
        onClick={toggleFinding}
        ref={findingRef}
      >
        <Content>
          <ListCheckboxContainer>
            <Arrow />
            {!limitedView ? (
              <ListCheckbox
                checked={selected}
                disabled={selected ? false : !selectable}
                onClick={preventDefaultAnd(() => onToggle(finding))}
              />
            ) : null}
          </ListCheckboxContainer>
          <CenterContainer>
            <IssueContainer>
              <Type>
                {SourceLabel[finding.source]} ::{" "}
                {finding?.type?.friendly_name ??
                  finding.type?.name ??
                  finding.name}
              </Type>
              <Issue finding={finding} />
            </IssueContainer>
            {!limitedView || expanded ? (
              <Tags>
                <Tag
                  color={FindingSeverityColor[finding.severity] as any}
                  label={
                    <>
                      <WarningIcon fontSize="inherit" />
                      <span>{FindingSeverityLabel[finding.severity]}</span>
                    </>
                  }
                />
                <Tag
                  label={
                    <>
                      <ElectricBoltIcon fontSize="inherit" />
                      <span>{FindingStateLabel[finding.state]}</span>
                    </>
                  }
                />
                <Tag
                  label={
                    <>
                      <AdjustIcon fontSize="inherit" />
                      <span>{SourceLabel[finding.source]}</span>
                    </>
                  }
                />
                <Tag
                  label={
                    <>
                      <AccessTimeIcon fontSize="inherit" />
                      <span>{dayjs(finding.create_time).fromNow()}</span>
                    </>
                  }
                />
              </Tags>
            ) : null}

            {expanded ? (
              <Expanded>
                <When>
                  <AccessTimeIcon fontSize="inherit" />
                  <div>
                    <span>{dayjs(finding.event_time).fromNow()}</span> (detected{" "}
                    <span>{dayjs(finding.create_time).fromNow()}</span>)
                  </div>
                  <div>
                    {dayjs(finding.event_time).format("MM/DD/YY hh:mm A")}{" "}
                    (detected{" "}
                    {dayjs(finding.create_time).format("MM/DD/YY hh:mm A")})
                  </div>
                </When>

                <Section>
                  <FingerprintIcon fontSize="inherit" />
                  <Box sx={{ wordBreak: "break-word", userSelect: "all" }}>
                    {finding.uuid}
                  </Box>
                </Section>

                <Section>
                  <PolicyIcon fontSize="inherit" />
                  <Box sx={{ wordBreak: "break-word", userSelect: "all" }}>
                    {finding.name}
                  </Box>
                </Section>

                {fixDescription !== "" ? (
                  <Section>
                    <AutoFixHighIcon fontSize="inherit" />
                    <FixContainer>
                      <ReactMarkdown>{fixDescription}</ReactMarkdown>
                    </FixContainer>
                  </Section>
                ) : null}

                {showExplanation ? (
                  <Section>
                    <ReportIcon fontSize="inherit" />
                    <Box sx={{ wordBreak: "break-word" }}>
                      <ReactMarkdown>
                        {finding.type?.description?.trim() ??
                          "The description for this finding could not be loaded."}
                      </ReactMarkdown>
                    </Box>
                  </Section>
                ) : null}

                {undoFixDescription !== "" && showUndo ? (
                  <Section>
                    <UndoIcon fontSize="inherit" />

                    <Box sx={{ wordBreak: "break-word" }}>
                      <ReactMarkdown>{undoFixDescription}</ReactMarkdown>
                    </Box>
                  </Section>
                ) : null}

                {showRawDetails ? (
                  <Section>
                    <AttachFileIcon fontSize="inherit" />
                    <RawDetails finding={finding} />
                  </Section>
                ) : null}

                {!showExplanation || !showRawDetails || !showUndo ? (
                  <Buttons>
                    {!showExplanation ? (
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={preventDefaultAnd(() =>
                          setShowExplanation(true)
                        )}
                      >
                        What&apos;s the issue?
                      </Button>
                    ) : null}
                    {undoFixDescription !== "" && !showUndo ? (
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={preventDefaultAnd(() => setShowUndo(true))}
                      >
                        How to undo
                      </Button>
                    ) : null}
                    {!showRawDetails ? (
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={preventDefaultAnd(() =>
                          setShowRawDetails(true)
                        )}
                      >
                        Raw event
                      </Button>
                    ) : null}
                  </Buttons>
                ) : null}
              </Expanded>
            ) : null}
          </CenterContainer>
        </Content>
      </Container>
    );
  }
);

// -----------------------------------------------------------------------------

const IssueContainer = styled(Box)`
  flex-shrink: 0;
`;

const Arrow = styled(KeyboardArrowRightIcon)`
  margin-top: 0px;
  transition: 500ms ease;
`;

const Expanded = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 0;
  overflow: hidden;
  transition: 500ms ease;
`;

const Content = styled(Box)`
  display: grid;
  gap: 8px;
  grid-template-columns: min-content 1fr;
  max-width: 1142px;
  min-height: 0vw;
  padding: clamp(8px, calc(0.5rem + ((1vw - 3.75px) * 0.9697)), 16px)
    clamp(8px, calc(0.5rem + ((1vw - 3.75px) * 1.9394)), 24px);
  position: relative;
  z-index: 2;
`;

const Container = styled(Box)`
  cursor: pointer;
  position: relative;

  &.selected {
    &:after {
      bottom: 0;
      background-color: ${(props) => props.theme.overviewListItem.main};
      content: "";
      pointer-events: 0;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      z-index: 1;
    }
  }

  &.expanded {
    ${Arrow} {
      transform: rotateZ(90deg);
    }

    ${IssueContainer} {
      display: inline;
    }

    ${Expanded} {
      height: 100%;
    }
  }

  &.limitedView ${Content} {
    padding: clamp(8px, calc(0.5rem + ((1vw - 3.75px) * 0.9697)), 16px);
  }

  &:nth-child(even) {
    background: ${(props) => props.theme.overviewListItem.mainFaded};
  }

  & + & {
    border-top: thin ${(props) => props.theme.overviewListItem.border} solid;
  }
`;

const ListCheckboxContainer = styled(Box)``;

const ListCheckbox = styled(Checkbox)`
  padding: 0;
`;

const CenterContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const FixContainer = styled(Box)`
  overflow-wrap: anywhere;
`;

const Type = styled(Box)`
  font-weight: bold;
  overflow-wrap: anywhere;
  word-break: break-word;
  word-wrap: break-word;
`;

const Tags = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

const Tag = styled(Chip)`
  font-size: 0.75rem;
  height: 24px !important;
  .MuiChip-label {
    align-items: center;
    display: flex;
    padding: 0 8px;

    svg {
      margin-right: 4px;
    }

    span {
      max-width: 256px;
      min-height: 0vw;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const Section = styled(Box)`
  display: grid;
  gap: 8px;
  grid: 1fr / auto 1fr;

  svg {
    margin-top: 4px;
  }

  a {
    color: ${(props) => props.theme.primary.main};
  }

  @media screen and (max-width: 450px) {
    grid-column: 1 / span 3;
    grid: 1fr / 24px 1fr;

    svg {
      margin: 4px auto 0 auto;
    }
  }
`;

const When = styled(Section)`
  font-style: italic;

  span {
    text-decoration: underline dotted;
  }

  div:nth-child(3) {
    display: none;
  }

  &:active,
  &:hover {
    div:nth-child(2) {
      display: none;
    }

    div:nth-child(3) {
      display: block;
    }
  }
`;

const Buttons = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

// -----------------------------------------------------------------------------

interface Props {
  finding: ServerFindingWithType;
  onToggle?: Function;
  selected?: boolean;
  selectable?: boolean;
  startExpanded?: boolean;
  limitedView?: boolean;
}
