import { type Action, createHook, createStore } from "react-sweet-state";

import { ThrowIf } from "babel-plugin-transform-functional-return";

import { captureSentryException } from "utility/capture_sentry_exception";
import {
  authenticatedGetFetch,
  authenticatedPostFetch,
} from "utility/fetch/authenticated";

// -----------------------------------------------------------------------------

export enum SlackProgress {
  Unknown,
  Submitting,
  Error,
  Success,
}

// -----------------------------------------------------------------------------

const initialState: SlackStoreState = {
  progress: SlackProgress.Unknown,
  listProgress: SlackProgress.Unknown,
  channels: {},
};

// -----------------------------------------------------------------------------

export const useSlackStore = createHook(
  createStore({
    initialState,
    actions: {
      submitParams,
      listChannels,
    },
  })
);

// -----------------------------------------------------------------------------

function submitParams(
  oauthCode: string | null,
  oauthState: string | null
): Action<SlackStoreState> {
  return async ({ setState }) => {
    try {
      ThrowIf(!oauthCode, new Error("code is missing or invalid"));
      ThrowIf(!oauthState, new Error("state is missing or invalid"));

      //
      setState({ progress: SlackProgress.Submitting });
      await authenticatedPostFetch("api/organization/connections/slack", {
        code: oauthCode,
        state: oauthState,
      });
      setState({
        progress: SlackProgress.Success,
        errorDetail: undefined,
      });
    } catch (error) {
      setState({
        progress: SlackProgress.Error,
        errorDetail: (error as Error)?.message ?? "There was an error",
      });
    }
  };
}

function listChannels(): Action<SlackStoreState> {
  return async ({ setState }) => {
    try {
      setState({ listProgress: SlackProgress.Submitting, channels: {} });
      const responsePayload = await authenticatedGetFetch(
        "lookup/slack/channels"
      );
      setState({
        listProgress: SlackProgress.Success,
        channels: responsePayload.channels,
      });
    } catch (error) {
      captureSentryException(error, "Failed to list slack channels");
      setState({ listProgress: SlackProgress.Error, channels: {} });
    }
  };
}

// -----------------------------------------------------------------------------

interface SlackStoreState {
  progress: SlackProgress;
  errorDetail?: string;
  listProgress: SlackProgress;
  channels: SlackChannelsByConnection;
}

export type SlackChannelsByConnection = Record<string, SlackChannel[]>;
export type SlackChannel = [string, string];
