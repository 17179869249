import { memo, useState } from "react";

import { DataGrid, type GridSortModel } from "@mui/x-data-grid";

import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";

import { type FOTS, type SUTS } from "data/typescript";

import { type StaleFinding } from "stores/DashboardStore";

import {
  DataGridStyle,
  FindingsColumns,
  FindingsColumnsLoading,
  LoadingRows,
  PaperStyle,
} from "./data";

// -----------------------------------------------------------------------------

const PageSize = 20;
const PageSizeOptions = [PageSize];
const DataComponents: Record<string, () => JSX.Element> = {
  LoadingOverlay: NullComponent,
};
enum SORT_BY {
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
}

// -----------------------------------------------------------------------------

export const StaleFindingsDataTable = memo(({ data, isLoading }: Props) => {
  const [sortBy, setSortBy] = useState<SORT_BY>(SORT_BY.ASCENDING);

  //
  const handleChange = (event: SUTS<{ target: { value: SORT_BY } } | null>) => {
    setSortBy(event?.target?.value ?? SORT_BY.ASCENDING);
  };

  //
  return (
    <>
      <Grid container spacing={2} direction="row" justifyContent="end">
        <Grid item paddingTop={0}>
          <Select
            id="sort-by-select"
            value={sortBy}
            inputProps={{ "aria-label": "Sort by order" }}
            onChange={handleChange as FOTS}
          >
            <MenuItem value={SORT_BY.ASCENDING}>Sort By: Ascending</MenuItem>
            <MenuItem value={SORT_BY.DESCENDING}>Sort By: Descending</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Paper elevation={1} sx={PaperStyle}>
        <DataGrid<StaleFinding>
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          disableSelectionOnClick
          density="compact"
          getRowId={getRowId}
          rows={isLoading ? LoadingRows : data}
          rowCount={data?.length ?? 0}
          columns={isLoading ? FindingsColumnsLoading : FindingsColumns}
          components={DataComponents}
          pageSize={PageSize}
          rowsPerPageOptions={PageSizeOptions}
          checkboxSelection={false}
          sx={DataGridStyle}
          loading={isLoading}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: "create_time",
                  sort: sortBy === SORT_BY.ASCENDING ? "asc" : "desc",
                },
              ],
            },
          }}
          onSortModelChange={(model: GridSortModel) => {
            setSortBy(
              model[0].sort === "asc" ? SORT_BY.ASCENDING : SORT_BY.DESCENDING
            );
          }}
          sortingOrder={["asc", "desc"]}
        />
      </Paper>
    </>
  );
});

// -----------------------------------------------------------------------------

function getRowId(row: StaleFinding): string {
  return `${row.create_time.toString()}-${Math.random()}`;
}

function NullComponent() {
  return <></>;
}

// -----------------------------------------------------------------------------

interface Props {
  data: StaleFinding[];
  isLoading: boolean;
}
