import { type FOTS } from "data/typescript";

// -----------------------------------------------------------------------------

export function toObject<T1 = any, T2 = Record<string, T1>>(
  arr: T1[],
  key: string
): T2 {
  // this is stupid
  const initial: FOTS = {};
  return arr.reduce<T2>((obj, item) => {
    // @ts-expect-error stop
    obj[item[key]] = item;

    //
    return obj;
  }, initial);
}

export function toObjectWithValue<T1 = any, T2 = any, T3 = Record<string, T1>>(
  arr: T2[],
  key: string,
  value: T1
): T3 {
  // this is stupid
  const initial: FOTS = {};
  return arr.reduce<T3>((obj, item) => {
    // @ts-expect-error stop
    obj[item[key]] = value;

    //
    return obj;
  }, initial);
}
