import { memo, useEffect, useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { PagerDutyProgress, usePagerDutyStore } from "stores/PagerDutyStore";

import { notifySuccess, notifyError } from "utility/notify";

import { usePageTitle } from "effect/use_page_title";

import { LoaderModal } from "Component/Modal/LoaderModal";

import { PageContent } from "Component/PageContent";

// -----------------------------------------------------------------------------

export const PagerDutyConnectConfirmPage = memo(() => {
  usePageTitle("Environment → Destinations → PagerDuty → Connect Confirm");

  //
  const [pagerDutyStore, pagerDutyActions] = usePagerDutyStore();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState("");

  //
  const service = "PagerDuty";
  const searchParams = new URLSearchParams(location.search);

  //
  if (pagerDutyStore.progress < PagerDutyProgress.Submitting) {
    const config = searchParams.get("config");

    pagerDutyActions.submitParams(config);
  }

  //
  useEffect(() => {
    switch (pagerDutyStore.progress) {
      case PagerDutyProgress.Success:
        notifySuccess("Connected to PagerDuty");
        break;

      case PagerDutyProgress.Error:
      case PagerDutyProgress.ErrorPagerDutyParam:
        setErrorMessage(
          `Failed connecting to PagerDuty due to ${
            pagerDutyStore.errorDetail ?? "unknown error"
          }`
        );
        notifyError(
          `Failed connecting to PagerDuty due to ${
            pagerDutyStore.errorDetail ?? "unknown error"
          }`
        );
        break;
    }
  }, [pagerDutyStore.progress, pagerDutyStore.errorDetail, service]);

  //
  switch (pagerDutyStore.progress) {
    case PagerDutyProgress.Success:
      //
      return (
        <Navigate
          to={`/environment/destinations/pagerduty/${pagerDutyStore.connectionId}/notifications`}
        />
      );

    case PagerDutyProgress.Error:
    case PagerDutyProgress.ErrorPagerDutyParam:
      return (
        <PageContent>
          <Stack spacing={2} direction="column">
            <Typography variant="h5">
              There was an error connecting to PagerDuty
            </Typography>
            <Alert severity="error">{errorMessage}</Alert>
            <Box>
              <Button component={Link} to="/environment/destinations">
                Go back
              </Button>
            </Box>
          </Stack>
        </PageContent>
      );

    default:
      return <LoaderModal />;
  }
});
