import { ReturnIf } from "babel-plugin-transform-functional-return";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import { ServiceConnectionPhase } from "Component/ServiceConnectionPhase";

// -----------------------------------------------------------------------------

export function AzureConnectPageTestingPhase({ shown }: Props) {
  ReturnIf(!shown, null);

  //
  return (
    <ServiceConnectionPhase disabled={false}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <CircularProgress sx={{ mr: 1 }} size="16px" /> Validating connection
      </Box>
    </ServiceConnectionPhase>
  );
}

// -----------------------------------------------------------------------------

interface Props {
  shown: boolean;
}
