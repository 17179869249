import { memo, useCallback } from "react";

import styled from "styled-components";

import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// -----------------------------------------------------------------------------

export const MembersPageInviteForm = memo(
  ({
    Roles,
    index = 1,
    invite: { email, name, role } = {},
    errors: { email: emailError, name: nameError, role: roleError } = {},
    onChange,
    onDelete,
    disabled,
    hideNumberLabel,
  }: Props) => {
    const onChangeField = useCallback(
      (field: string, value: any) => {
        onChange({
          email,
          name,
          role,
          [field]: value,
        });
      },
      [email, name, role, onChange]
    );

    //
    return (
      <Paper elevation={1} sx={{ p: 2, "& + &": { mt: 2 } }}>
        <Box sx={{ mb: 1, display: "flex", alignItems: "center" }}>
          {hideNumberLabel ? null : (
            <>
              <Typography fontWeight="bold" variant="body1">
                #{index}
              </Typography>
              <Spacer>|</Spacer>
            </>
          )}
          <Button variant="text" onClick={onDelete as any} disabled={disabled}>
            REMOVE
          </Button>
        </Box>

        <TextField
          fullWidth
          variant="outlined"
          label="Email Address"
          helperText={emailError || null}
          value={orString(email)}
          onChange={(event) => onChangeField("email", event.target.value)}
          disabled={disabled}
          sx={{ mb: 2 }}
        />

        <TextField
          fullWidth
          variant="outlined"
          label="Name"
          value={orString(name)}
          helperText={nameError || null}
          onChange={(event) => onChangeField("name", event.target.value)}
          disabled={disabled}
          sx={{ mb: 2 }}
        />

        <Autocomplete
          key={2}
          options={Object.values(Roles)}
          onChange={(_event, value: any) =>
            onChangeField("role", orUndefined(value?.role))
          }
          value={Roles[role] || null}
          isOptionEqualToValue={isOptionEqualToValue}
          getOptionLabel={(role) => role.label}
          renderOption={(props, option) => {
            return (
              <ListItem
                {...props}
                alignItems="flex-start"
                key={option.definition}
              >
                <ListItemIcon>{option.icon}</ListItemIcon>
                <ListItemText
                  primary={option.label}
                  secondary={option.definition}
                />
              </ListItem>
            );
          }}
          disabled={disabled}
          renderInput={(params: any) => (
            <TextField
              {...params}
              variant="outlined"
              label="Select Role Type"
              helperText={roleError}
            />
          )}
          sx={{
            "& .MuiAutocomplete-inputRoot": {
              padding: "0 !important",
            },

            "& input": {
              padding: "16.5px 14px !important",
            },
          }}
        />
      </Paper>
    );
  }
);

// -----------------------------------------------------------------------------

function isOptionEqualToValue(option: any, value: any): boolean {
  return value ? value.label === option.label : false;
}

function orString(value: any): string {
  return value || "";
}

function orUndefined(value: any): any {
  return value || (undefined as any);
}

// -----------------------------------------------------------------------------

const Spacer = styled.span`
  margin-left: 8px;
`;

// -----------------------------------------------------------------------------

interface Props {
  Roles: any;

  index: number;
  invite: any;
  errors: any;
  onChange: Function;
  onDelete: Function;

  disabled: boolean;

  hideNumberLabel?: boolean;
}
