import { memo, useState } from "react";

import { Buffer } from "buffer";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { useOrganizationStore } from "stores/OrganizationStore";

import { usePageTitle } from "effect/use_page_title";

import { Page } from "Component/Page";
import { PageContent } from "Component/PageContent";
import { PageHeader } from "Component/PageHeader";
import { PageHeaderBackButton } from "Component/PageHeaderBackButton";
import { PageTitle } from "Component/PageTitle";
import { ServiceConnectionInstructionPhase } from "Component/ServiceConnectionInstructionPhase";
import { ServiceConnectionPhases } from "Component/ServiceConnectionPhases";

import { SalesforceConfig } from "./config";

// -----------------------------------------------------------------------------

export const SalesforceConnectPage = memo(() => {
  usePageTitle("Environment → Sources → Salesforce → Connect");

  //
  const [organizationStore] = useOrganizationStore();
  const [salesforceDomain, setSalesforceDomain] = useState("");

  //
  const state = Buffer.from(
    JSON.stringify({
      orgUuid: organizationStore.uuid,
      domain: salesforceDomain,
    })
  ).toString("base64");
  const params = new URLSearchParams({
    redirect_uri: encodeURI(
      `${window.location.origin}/environment/sources/salesforce/oauth2`
    ),
    response_type: "code",
    client_id: SalesforceConfig.clientId,
    state,
    scope: "api refresh_token",
  }).toString();
  const oauthURL = `https://${salesforceDomain}/services/oauth2/authorize?${params}`;

  //
  return (
    <Page>
      <PageHeader>
        <PageHeaderBackButton to="/environment/sources">
          Back to Sources
        </PageHeaderBackButton>
        <PageTitle>Salesforce Connection</PageTitle>
      </PageHeader>

      <PageContent>
        <ServiceConnectionPhases>
          <ServiceConnectionInstructionPhase>
            <Typography variant="body1">
              Connect your ThreatKey account with Salesforce in a just a few
              easy steps. Once connected, you can see all of your results on the
              Findings page.
            </Typography>
          </ServiceConnectionInstructionPhase>
          <Paper sx={{ p: 2, mb: 2 }}>
            <FormControl fullWidth>
              <FormLabel>Salesforce Domain</FormLabel>
              <TextField
                placeholder="company.my.salesforce.com"
                value={salesforceDomain}
                onChange={(event) => {
                  setSalesforceDomain(event.target.value);
                }}
                data-private="lipsum"
                fullWidth
              />
            </FormControl>

            <Box sx={{ mt: 2 }}>
              <Button
                variant="contained"
                component="a"
                href={oauthURL}
                rel="noopener noreferrer"
              >
                Connect
              </Button>
            </Box>
          </Paper>
        </ServiceConnectionPhases>
      </PageContent>
    </Page>
  );
});
