/** Used to make a more obvious-looking API for Jira issue metadata. */
import {
  type Action,
  createContainer,
  createHook,
  createStore,
} from "react-sweet-state";

// -----------------------------------------------------------------------------

const initialState: State = {
  initialData: {},
  data: {},
  changed: false,
};

// -----------------------------------------------------------------------------

const actions = {
  set,
  reset,
  onSave,
};

const store = createStore<State, Actions>({
  initialState,
  actions,
});

export const useJiraCIM = createHook(store);

// TODO: ditch this
export const JiraCreateIssueMetaContainer = createContainer<
  State,
  Actions,
  Props
>(store, {
  onInit:
    () =>
    ({ setState }, { initialData }) => {
      if (initialData === undefined) {
        initialData = {};
      }
      setState({
        initialData,
        data: initialData,
      });
    },
});

// -----------------------------------------------------------------------------

function set(key: string, value: any): Action<State> {
  return ({ setState, getState }) => {
    setState({
      data: {
        ...getState().data,
        [key]: value,
      },
      changed: true,
    });
  };
}

function reset(): Action<State> {
  return ({ getState, setState }) => {
    setState({
      ...getState(),
      data: getState().initialData,
      changed: false,
    });
  };
}

function onSave(): Action<State> {
  return ({ getState, setState }) => {
    setState({
      ...getState(),
      initialData: getState().data,
      changed: false,
    });
  };
}

// -----------------------------------------------------------------------------

interface Props {
  initialData: { [key: string]: any };
}

interface State {
  initialData: { [key: string]: any };
  data: { [key: string]: any };
  changed: boolean;
}

type Actions = typeof actions;
