import { memo, useEffect, useState } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";

import styled from "styled-components";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HelpOutline from "@mui/icons-material/HelpOutline";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";

import { type FOTS } from "data/typescript";

import { useUserStore } from "stores/UserStore";

import { clearAccessTokens, getRefreshToken } from "utility/credential_storage";
import { authenticatedPostFetch } from "utility/fetch/authenticated";
import { openOrtto } from "utility/ortto";

import { Sidebar } from "../Sidebar";

import { HeaderLogo } from "./Logo";
import { PaletteSearch } from "./PaletteSearch";
import { ToggleThemeButton } from "./ToggleThemeButton";

// -----------------------------------------------------------------------------

export const Header = memo(() => {
  const [, userActions] = useUserStore();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [searchModal, setSearchModal] = useState(false);

  const navigate = useNavigate();
  const SearchOnClick = () => {
    setSearchModal(true);
  };
  const openMobileMenu = () => setShowMobileMenu(true);
  const closeMobileMenu = () => setShowMobileMenu(false);
  const openAccountMenu = (event: any) => setAnchorElUser(event.currentTarget);
  const closeAccountMenu = () => setAnchorElUser(null as any);

  //
  useEffect(() => {
    const handleKeyDown = (event: FOTS) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "k") {
        setSearchModal((prevVal) => !prevVal);
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("keydown", handleKeyDown);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  //
  return (
    <>
      <Dialog
        sx={{
          marginRight: "50vw",
          marginBottom: "40vw",
        }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            position: "static",
          },
        }}
        open={searchModal}
        onClose={() => setSearchModal(false)}
      >
        <PaletteSearch onClose={() => setSearchModal(false)} />
      </Dialog>

      {showMobileMenu ? (
        <Sidebar mode="mobile" onClose={closeMobileMenu} />
      ) : null}
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          backgroundColor: "appHeader.main",
          display: "flex",
          flexDirection: "column",
          zIndex: 500,
        }}
      >
        <HeaderContainer maxWidth={false}>
          <HeaderToolbar disableGutters>
            <HeaderLogo />

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="open mobile menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={openMobileMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Box>

            <Box sx={{ flexGrow: 1 }} />

            <Box
              sx={{
                flexGrow: 0,
                display: "flex",
                justifyContent: "flex-end",
                flexWrap: "wrap",
              }}
            >
              <Tooltip title="Search">
                <IconButton
                  size="large"
                  aria-label="search"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={SearchOnClick}
                >
                  <SearchIcon />
                </IconButton>
              </Tooltip>
              <ToggleThemeButton />
              <Tooltip title="Open Help">
                <IconButton
                  size="large"
                  aria-label="open help"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={openOrtto}
                >
                  <HelpOutline />
                </IconButton>
              </Tooltip>
              <Tooltip title="Account Settings" className="hideInMobile">
                <StyledLink to="/account">
                  <IconButton
                    size="large"
                    aria-label="account settings"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                  >
                    <AccountCircleIcon />
                  </IconButton>
                </StyledLink>
              </Tooltip>
              <Tooltip title="Logout" className="hideInMobile">
                <IconButton
                  size="large"
                  aria-label="account logout"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={openAccountMenu}
                >
                  <LogoutIcon />
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={{ mt: "32px" }}
                open={Boolean(anchorElUser)}
                onClose={closeAccountMenu}
              >
                <Link
                  to="/login"
                  onClick={() => {
                    authenticatedPostFetch("api/auth/logoutUser", {
                      refresh_token: getRefreshToken(),
                    });
                    clearAccessTokens();
                    userActions.clear();
                    navigate("/login");
                  }}
                  style={{ color: "primary.text" }}
                >
                  <MenuItem
                    style={{ textDecoration: "underline" }}
                    sx={{
                      color: "primary.main",
                      textDecoration: "primary.main",
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: "none" }}>
                      <LogoutIcon fontSize="small" />
                    </ListItemIcon>{" "}
                    Logout
                  </MenuItem>
                </Link>
              </Menu>
            </Box>
          </HeaderToolbar>
        </HeaderContainer>
      </AppBar>
    </>
  );
});

// -----------------------------------------------------------------------------

const HeaderToolbar = styled(Toolbar)`
  min-height: 0vw;
  min-height: unset !important;
  padding: clamp(0.01px, calc(0.000625rem + ((1vw - 3.75px) * 0.9685)), 8px) 0;
`;

const HeaderContainer = styled(Container)`
  min-height: 0vw;
  padding: 0 clamp(0.01px, calc(0.000625rem + ((1vw - 3.75px) * 2.9079)), 24px);

  @media screen and (max-width: 415px) {
    .hideInMobile {
      display: none;
    }
  }
`;

const StyledLink = styled(NavLink)`
  color: #fff;
`;
