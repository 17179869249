import { AmazonWebServicesConnectPage } from "./Connect";
import { redirectToAmazonWebServicesConnectPage } from "./connect_handler";
import { AmazonWebServicesCoverImage } from "./CoverImage";

// -----------------------------------------------------------------------------

export { AmazonWebServicesConnectPage };

export const AmazonWebServicesAppConfig = {
  name: "aws",
  alternativeNames: ["aws_key", "aws_role", "fixit_aws_role", "fixit_aws_key"],
  label: "Amazon Web Services",
  coverImage: AmazonWebServicesCoverImage,
  onConnect: redirectToAmazonWebServicesConnectPage,
  searchTerms: ["aws", "amazon", "aws-account"],
};
