import {
  FindingSeverity,
  FindingSeverityLabel,
  type SeverityCount,
  SourceLabel,
  SourceToColor,
  type UnresolvedBySource,
} from "data/finding";

// -----------------------------------------------------------------------------

export function getSrcSevPieData(
  findings: Array<SeverityCount & UnresolvedBySource>
) {
  let foundIdx: number;

  // We only care about "severe findings", where the severity is at least medium
  const severeFindings = findings.filter(
    (f) => f.severity >= FindingSeverity.Medium
  );

  // Group all findings by source and keep track of count per source
  const pieData: PieDataProps[] = severeFindings.reduce<PieDataProps[]>(
    (acc, curr) => {
      // Check if the source is already in our output array
      foundIdx = acc.findIndex((item) => item.source === curr.source);

      // If not, add a new blank item for this source
      if (foundIdx === -1) {
        acc.push({
          id: SourceLabel[curr.source],
          label: SourceLabel[curr.source],
          value: 0,
          source: curr.source,
          severity: { Critical: 0, High: 0, Medium: 0 },
          color: SourceToColor[curr.source],
        });
      }

      // Find the right severity and increment its count
      const lookupIndex = foundIdx === -1 ? acc.length - 1 : foundIdx;
      acc[lookupIndex].severity[FindingSeverityLabel[curr.severity]] +=
        curr.count;
      acc[lookupIndex].value += curr.count;

      foundIdx = -1;

      return acc;
    },
    []
  );

  return pieData;
}

// -----------------------------------------------------------------------------

export interface PieDataProps {
  id: string;
  label: string;
  source: number;
  value: number;
  color?: string;
  severity: SevTotals;
}

interface SevTotals {
  [key: string]: number;
  Critical: number;
  High: number;
  Medium: number;
}
