import { memo } from "react";

import styled, { useTheme } from "styled-components";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { LoginErrorMessage as Message } from "./Message";

export const LoginError = memo(({ errorMessage, reset }: Props) => {
  const theme = useTheme();

  return (
    <Container>
      <ErrorOutlineIcon color="error" />
      <Typography variant="body1">
        <Message errorMessage={errorMessage} />
      </Typography>
      <ReturnContainer>
        <StyledButton
          theme={theme}
          onClick={() => {
            reset();
          }}
        >
          Return to login
        </StyledButton>
      </ReturnContainer>
    </Container>
  );
});

const Container = styled(Box)`
  align-items: center;
  display: grid;
  gap: 16px;
  grid-template-columns: auto 1fr;
`;

const ReturnContainer = styled(Box)`
  grid-column: 1 / span 2;
  text-align: center;
`;

const StyledButton = styled.button`
  background-color: ${(props) => props.theme.primary.main};
  border: none;
  border-radius: 4px;
  color: ${(props) => props.theme.primary.contrastText};
  cursor: pointer;
  font-size: 1rem;
  padding: 10px 20px;
  text-decoration: none;

  &:hover {
    background-color: ${(props) => props.theme.primary.hover};
  }
`;

interface Props {
  errorMessage: string;
  reset: Function;
}
