import { memo } from "react";

import SvgIcon from "@mui/material/SvgIcon";

// -----------------------------------------------------------------------------

export const JumpCloudSAMLSSOIcon = memo((props: object) => {
  return (
    <SvgIcon viewBox="0 200 912 172" overflow="visible" {...props}>
      <path
        fill="#B3E9E7"
        d="M449.1,137.7c7.8-3.2,16.4-2.9,24.6-2.2c12.5,1.8,22.8,10.2,29.9,20.2c7.6-0.4,15.6-1.8,22.8,1.2
	c11.8,4,21.4,14.4,23.6,26.8c0.6,1.9,0.3,6.2,3.6,5.3c5.9-0.2,12.2-1,17.8,1.5c12.4,4.5,22.1,16.5,22.8,29.8
	c0.9,14-6.7,27.1-19.2,33.3c-7.3,4.3-16.1,2.9-24.2,3.1c-1.7-4.5-3.3-9.3-6.8-12.9c-5.9-6.4-14.3-9.4-22.1-12.8
	c5.4-6.9,9.2-15.6,7.7-24.6c-1.6-14.9-16-27.1-31-25.8c-15.7,0-29.3,14.8-28.2,30.4c-0.3,7.4,3.8,13.9,7.5,20
	c-7.2,3-14.1,6.6-20.6,10.9c-3.8-1.4-7.5-3-11.1-4.8c3-5.2,6.6-10.6,6.3-16.9c1.4-13.6-10.9-26-24.2-26.4
	c-13.7-1-26.5,11.2-26.5,24.9c-0.3,6.8,3.2,12.8,6.4,18.4c-9.5,3.9-20.1,8.9-23.4,19.4c-7.5,0.1-15.5,1.1-22.5-2.2
	c-11.8-4.6-20-15.4-21.3-27.9c-2.3-17.2,10.8-34.7,27.9-37.6c6.5-0.9,13.1,0,19.4,1.3c5.6-12.7,19.7-20.1,33.3-19.7
	C424.4,155.8,434.9,143.3,449.1,137.7L449.1,137.7z"
      />
      <path
        fill="#14A19C"
        d="M470.2,211.3c-1-15.6,12.5-30.4,28.2-30.4c15-1.3,29.4,10.9,31,25.8c1.5,9-2.3,17.6-7.7,24.6
	c7.8,3.4,16.2,6.4,22.1,12.8c3.5,3.5,5.1,8.4,6.8,12.9c-55.4,0-110.8,0.3-166.1-0.1c3.4-10.5,14-15.6,23.4-19.4
	c-3.3-5.6-6.8-11.7-6.4-18.4c0-13.7,12.8-25.8,26.5-24.9c13.3,0.4,25.6,12.8,24.2,26.4c0.4,6.3-3.3,11.7-6.3,16.9
	c3.6,1.8,7.3,3.4,11.1,4.8c6.5-4.3,13.4-7.9,20.6-10.9C474,225.2,470,218.7,470.2,211.3L470.2,211.3z"
      />
      <path
        fill="#41C8C3"
        d="M116.2,301.7c6.2-2.3,13.1,0.7,15.4,6.9c0.1,0.2,0.2,0.4,0.2,0.7c2.4,6.7-2.9,14.8-10.1,15.2
	c-5.2,0.6-10.2-2.3-12.2-7.2C107,311.4,110.1,303.8,116.2,301.7 M535.2,307.6l0.7-0.6c7-0.2,14,0,21.1-0.1c0.3,18.2,0,36.3,0.1,54.5
	c-0.1,16.2,0.1,32.5-0.1,48.8c-7.3-0.1-14.6,0-21.9-0.1C535.2,375.9,535.1,341.8,535.2,307.6 M793,307c7.2-0.1,14.3,0,21.5-0.1
	c0.3,1.3,0.5,2.6,0.5,3.9c-0.1,26.9-0.1,53.8,0,80.6c-0.2,6.2,0.4,12.5-0.4,18.7c-7.2-0.2-14.5,0-21.7-0.1c0-5.7,0-11.5,0-17.2
	c0-1.1-0.3-2.3-0.8-3.3c-2.3,6.3-5,12.9-10.3,17.3c-6.5,5.7-16.3,7-24.3,3.6c-5.5-2.2-9.6-6.9-12.1-12.2c-3.8-8-4.8-17.1-4.6-26
	c-0.2-9.2,1-18.7,5-27.1c2.6-5.4,7-10,12.7-12.1c7.2-2.5,15.8-1.8,22.1,2.9c5.5,4,8.7,10.2,10.7,16.5c0,1.2,2,0.5,1.5-0.5
	C793,337,792.9,322,793,307 M776.7,351.7c-4.4,0.1-9,2-11.3,6c-3.5,6-3.4,13.2-3,19.9c0.6,5,2.2,10.6,6.8,13.3
	c3.5,2.3,7.9,2.1,11.9,1.7c6.7-1.3,11.2-7.7,11.6-14.2c0.4-6.9,0.9-14.4-3-20.4C787,353.6,781.7,351.5,776.7,351.7 M264.2,338.9
	c5.7-6.2,14.8-8.3,22.8-6.5c5.1,1,9.4,4.3,11.9,8.8c2.4,4.1,2.9,8.9,3.6,13.5l1.4-0.2c2.2-7,5.5-14.3,11.7-18.6
	c5.2-3.7,11.9-4.8,18.1-3.8c5.7,0.7,10.8,3.8,13.9,8.7c3.1,5,4.3,11,4.2,16.9c0,17.5,0,34.9,0,52.4c-7.4,0-14.7,0-22.1,0
	c0-15.5,0-31,0-46.5c0-4.1-1.7-8.5-5.5-10.6c-6.3-3.1-14.9-0.9-18.5,5.3c-1.7,3.2-2.6,6.8-2.5,10.4c0,13.8,0,27.6,0,41.4
	c-7.3,0-14.6,0-21.9,0c0-15.5,0-31,0-46.5c0-4.2-1.9-8.8-5.9-10.8c-6.2-2.7-13.4-0.8-17.6,4.5c-2,2.9-3.1,6.3-3.1,9.8
	c0,14.3,0,28.6,0,42.9c-7.3,0-14.6,0-21.9,0c0-25.4,0-50.8,0-76.2c7.1,0,14.2,0,21.4,0c0.2,6.5-0.1,13,0.1,19.5l0.9,1.4
	C257.6,349.2,259.8,343.2,264.2,338.9 M402.2,335.3c10.3-6.5,25.8-3.7,32,7.1c5,8.1,6.4,17.8,6.5,27.1c0.2,9.6-0.3,19.6-4.5,28.4
	c-2.4,5.4-6.6,10.3-12.2,12.6c-7.3,3.1-15.6,2.3-22.2-2c-5.3-3.7-8.8-9.4-10.9-15.4c-0.4-1.2-1-2.4-1.7-3.4
	c-0.3,1.3-0.5,2.6-0.5,3.9c0,13.6,0,27.2,0,40.8h-21.8c0-33.5-0.1-67,0-100.5c7.2,0,14.4,0,21.6,0c0.1,6.5-0.2,13.1,0.2,19.6
	c1-0.4,1.7-1.2,1.8-2.2c2-6.4,5.9-12.3,11.5-16 M403.3,351.7c-7,0.2-12.9,5.4-14,12.3c-1.1,5.9-1,11.9,0.3,17.8
	c1.4,5.2,5.5,9.7,10.8,10.8c4,0.4,8.4,0.7,11.9-1.7c4.7-2.9,6.2-8.9,6.8-14c0.3-6.6,0.5-13.9-3.2-19.7
	C413.1,353.1,408,351.6,403.3,351.7 M461.1,340.8c8.8-7.8,21.1-9.8,32.4-8.6c8.2,0.6,16.7,3.7,21.9,10.3c4.3,5.3,6.2,12,7.3,18.6
	c-6.8,1.5-13.6,3-20.5,3.8c-1.1-4.9-3.4-10.3-8.3-12.5c-5.3-2-11.9-1.7-16.5,1.8c-4.2,3.3-5.8,8.8-6.2,14
	c-0.3,5.9-0.2,12.1,2.7,17.4c4.1,8.4,16.8,10.3,23.6,4.1c3.1-2.9,4.2-7.1,5.4-11c6.6,1.8,13.3,3.2,19.9,4.8
	c-0.9,8.1-4.2,16.3-10.6,21.6c-7.4,6.1-17.4,7.7-26.6,7.4c-10.1-0.4-20.8-3.6-27.8-11.3c-7.6-8.5-9.7-20.5-9.1-31.5
	C449.3,359,452.8,347.9,461.1,340.8 M598.8,332.7c9.5-1.5,19.6-1.1,28.4,3c8.4,3.9,14.8,11.1,17.7,19.9c2.7,7.3,2.7,15.2,2.2,22.8
	c-0.9,9.4-4.7,18.8-11.9,25c-8.9,7.9-21.6,10-33,8.7c-9.7-1.1-19.5-5.4-25.3-13.5c-5.9-7.6-7.7-17.4-7.9-26.8
	c0.3-7.3,1.3-14.7,4.8-21.2C578.5,340.9,588.4,334.6,598.8,332.7 M605.2,350.5c-5.1,0.5-9.5,3.6-11.7,8.2c-3.1,7-3,15-1.5,22.3
	c1.2,5.7,5.4,10.9,11.3,12c5,0.7,10.7,0.9,15-2.2c4.5-3.1,6.3-8.7,7-14c0.5-6.8,0.5-14.1-3.2-20
	C618.5,351.4,611.3,349.6,605.2,350.5 M147.1,333.9c7.3,0,14.6,0,22,0c0,15.3,0,30.5,0,45.8c0,3.8,1,7.9,4,10.3
	c3.9,3.2,9.7,3,14.1,1c5.2-2.3,8.2-8,8.2-13.5c0.1-14.5,0-29.1,0-43.6c7.4,0,14.8,0,22.1,0c0,25.4,0.1,50.9,0,76.3
	c-7.1-0.1-14.3-0.1-21.4,0c-0.2-6.8,0.1-13.6-0.2-20.4c-1.1,0.3-1.8,1.2-2,2.3c-2.1,6.7-6.1,13-12.2,16.8c-7.5,4.6-17.8,4.9-25.2,0
	c-6.8-4.6-9.1-13.3-9.4-21C147.2,369.8,147.2,351.9,147.1,333.9 M707.3,333.9c7.3,0,14.7-0.1,22,0v76.1c-7.2,0-14.5,0.1-21.7,0
	c0-6.8,0.1-13.5-0.1-20.3c-1.6,0.4-1.9,2.1-2.4,3.5c-2.2,6.2-6.1,12-11.7,15.6c-7,4.3-15.7,4.7-23.2,1.2c-5-2.5-8.4-7.5-10-12.8
	c-1.6-4.9-1.4-10.2-1.5-15.3c0.1-16-0.1-32.1,0.1-48.1c7.3,0.1,14.6-0.1,22,0.1c0,15.5,0,31,0,46.5c-0.2,4.3,1.8,8.9,5.8,10.8
	c6.5,3.1,15.3,0.3,18.5-6.2c1.5-3,2.2-6.4,2.1-9.8C707.2,361.5,707.2,347.7,707.3,333.9 M109.6,404.7c0.1-23.6-0.1-47.1,0.1-70.7
	c7.3,0.1,14.6,0,22,0.1c0,24.3,0,48.5,0,72.8c-0.4,8-1.7,16.8-7.9,22.5c-3.2,3.3-7.7,5.1-12.2,5.9c-4.8,1-9.8,0.9-14.6-0.1
	c0.1-6.2,0.4-12.5,0.9-18.7c3.2,0.1,7,1.2,9.6-1.2C110.1,412.4,109.5,408.2,109.6,404.7L109.6,404.7z"
      />
    </SvgIcon>
  );
});
