import { type Action, createHook, createStore } from "react-sweet-state";

import { ReturnIf, ThrowIf } from "babel-plugin-transform-functional-return";

import {
  Kubernetes,
  type KubernetesConnectionCreateApiPayload,
  type KubernetesConnectionCreateApiPayloadAwsAkid,
  type KubernetesConnectionCreateApiPayloadAwsRole,
  type KubernetesConnectionCreateApiPayloadGcpSvAcctJson,
  type KubernetesConnectionCreateState,
  type KubernetesConnectionCreateStateAkid,
  type KubernetesConnectionCreateStateKubeconfig,
  type KubernetesConnectionCreateStateRole,
  type KubernetesConnectionCreateStateSvcAcctJson,
} from "data/kubernetes";
import { type ResponseError } from "data/response_error";

import { captureSentryException } from "utility/capture_sentry_exception";
import { encryptData } from "utility/encryption";
import {
  authenticatedDeleteFetch,
  authenticatedGetFetch,
  authenticatedPatchFetch,
  authenticatedPostFetch,
} from "utility/fetch/authenticated";

import {
  ActionState,
  type SecretState,
  type GCPSAKeyData,
  type OktaKeyData,
  type LookerKeyData,
  type SnowflakeKeyData,
} from "./lib";

// -----------------------------------------------------------------------------

const initialActionState = {
  state: ActionState.INITIAL,
};

const initialState: ConnectionStoreState = {
  connections: new Map(),
  loadConnections: initialActionState,
  createGCPConnection: initialActionState,
  createOktaConnection: initialActionState,
  createKubernetesConnection: initialActionState,
  createGKEKubernetesConnection: initialActionState,
  createLookerConnection: initialActionState,
  createSnowflakeConnection: initialActionState,
  deleteConnection: initialActionState,
  atlassianConnect: initialActionState,
  googleSheetConnect: initialActionState,
  webhookConnect: initialActionState,
  retryCreateConnection: initialActionState,
  editConnection: initialActionState,
};

// -----------------------------------------------------------------------------

const store = createStore({
  initialState,
  actions: {
    loadConnections,
    createGCPConnection,
    createLookerConnection,
    createOktaConnection,
    createSnowflakeConnection,
    createKubernetesConnection,
    createGKEKubernetesConnection,
    retryCreateConnection,
    editConnection,
    deleteConnection,

    atlassianConnect,
    googleSheetConnect,
    webhookConnect,
  },
});

export const useConnectionStore = createHook(store);

// TODO: something about this; it's not an actual useEffect
export const useConnectionByID = (uuid: string) => {
  const [store, actions] = useConnectionStore();
  if (store.loadConnections.state < ActionState.COMPLETED) {
    actions.loadConnections({ reload: false });
    return undefined;
  }

  //
  return store.connections.get(uuid) ?? undefined;
};

// -----------------------------------------------------------------------------

function loadConnections({ reload }: LoadParams): Action<ConnectionStoreState> {
  return async ({ setState, getState }) => {
    ReturnIf(getState().loadConnections.state > ActionState.INITIAL && !reload);

    //
    try {
      setState({ loadConnections: { state: ActionState.IN_PROGRESS } });

      //
      const connections = await loadAllConnections();
      setState({
        loadConnections: { state: ActionState.COMPLETED },
        connections,
      });
    } catch (error) {
      captureSentryException(error, "Failed to load connections");
      setState({
        loadConnections: {
          state: ActionState.ERROR,
          error: (error as Error).message,
        },
      });
    }
  };
}

function createOktaConnection({
  publicKey,
  prefix,
  data,
  onSuccess,
  onError,
}: EncryptedCreateParams<OktaKeyData>): Action<ConnectionStoreState> {
  return async ({ setState, getState }) => {
    ReturnIf(getState().createOktaConnection.state === ActionState.IN_PROGRESS);

    //
    try {
      setState({ createOktaConnection: { state: ActionState.IN_PROGRESS } });

      //
      const responsePayload = await authenticatedPostFetch<{ uuid: string }>(
        "api/organization/connections/okta/provision/create",
        {
          secret: await encryptData(data, publicKey),
        }
      );
      setState({
        createOktaConnection: {
          state: ActionState.COMPLETED,
        },
      });
      onSuccess?.(responsePayload);
    } catch (error) {
      captureSentryException(error, "Failed to create connection");
      setState({
        createOktaConnection: {
          state: ActionState.ERROR,
          error: (error as Error).message,
        },
      });
      onError?.(error as Error);
    }
  };
}

function createKubernetesConnection({
  publicKey,
  prefix,
  data,
  onSuccess,
  onError,
}: EncryptedCreateParams<KubernetesConnectionCreateState>): Action<ConnectionStoreState> {
  return async ({ setState, getState }) => {
    ReturnIf(
      getState().createKubernetesConnection.state === ActionState.IN_PROGRESS
    );

    //
    try {
      setState({
        createKubernetesConnection: { state: ActionState.IN_PROGRESS },
      });

      //
      const responsePayload = await authenticatedPostFetch<{ uuid: string }>(
        "api/organization/connections/eks_kubernetes/provision/create",
        {
          secret: await encryptData(
            convertToKubernetesPayload(data),
            publicKey
          ),
          k8s_cluster_resource_id: data.clusterResourceId,
        }
      );
      setState({
        createKubernetesConnection: {
          state: ActionState.COMPLETED,
        },
      });
      onSuccess?.(responsePayload);
    } catch (error) {
      captureSentryException(error, "Failed to create connection");
      setState({
        createKubernetesConnection: {
          state: ActionState.ERROR,
          error: (error as Error).message,
        },
      });
      onError?.(error as Error);
    }
  };
}
function createGKEKubernetesConnection({
  publicKey,
  prefix,
  data,
  onSuccess,
  onError,
}: EncryptedCreateParams<KubernetesConnectionCreateState>): Action<ConnectionStoreState> {
  return async ({ setState, getState }) => {
    ReturnIf(
      getState().createGKEKubernetesConnection.state === ActionState.IN_PROGRESS
    );

    //
    try {
      setState({
        createGKEKubernetesConnection: { state: ActionState.IN_PROGRESS },
      });

      //
      const responsePayload = await authenticatedPostFetch<{ uuid: string }>(
        "api/organization/connections/gke_kubernetes/provision/create",
        {
          secret: await encryptData(
            convertToKubernetesPayload(data),
            publicKey
          ),
          k8s_cluster_resource_id: data.clusterResourceId,
        }
      );
      setState({
        createGKEKubernetesConnection: {
          state: ActionState.COMPLETED,
        },
      });
      onSuccess?.(responsePayload);
    } catch (error) {
      captureSentryException(error, "Failed to create connection");
      setState({
        createGKEKubernetesConnection: {
          state: ActionState.ERROR,
          error: (error as Error).message,
        },
      });
      onError?.(error as Error);
    }
  };
}

function createGCPConnection({
  publicKey,
  prefix,
  data,
  onSuccess,
  onError,
}: EncryptedCreateParams<GCPSAKeyData>): Action<ConnectionStoreState> {
  return async ({ setState, getState }) => {
    try {
      ThrowIf(!publicKey, new Error("No public key"));
      ThrowIf(!prefix, new Error("No public key prefix"));

      //
      setState({ createGCPConnection: { state: ActionState.IN_PROGRESS } });

      //
      const responsePayload = await authenticatedPostFetch<{ uuid: string }>(
        "api/organization/connections/gcp/provision/create",
        {
          secret: await encryptData(data, publicKey),
        }
      );
      setState({
        createGCPConnection: {
          state: ActionState.COMPLETED,
        },
      });
      onSuccess?.(responsePayload);
    } catch (error) {
      captureSentryException(error, "Failed to create connection");
      setState({
        createGCPConnection: {
          state: ActionState.ERROR,
          error: (error as Error).message,
        },
      });
      onError?.(error as Error);
    }
  };
}

function createLookerConnection({
  publicKey,
  prefix,
  data,
  onSuccess,
  onError,
}: EncryptedCreateParams<LookerKeyData>): Action<ConnectionStoreState> {
  return async ({ setState, getState }) => {
    // Guard against running multiple in-progress actions
    if (getState().createLookerConnection.state === ActionState.IN_PROGRESS) {
      return;
    }

    try {
      ThrowIf(!publicKey, new Error("No public key"));
      ThrowIf(!prefix, new Error("No public key prefix"));
      // Set initial state to IN_PROGRESS
      setState({
        createLookerConnection: { state: ActionState.IN_PROGRESS },
      });

      // Call the API endpoint and handle encryption
      const responsePayload = await authenticatedPostFetch<{ uuid: string }>(
        "api/organization/connections/looker",
        {
          secret: await encryptData(data, publicKey),
        }
      );

      // On successful API response, update state to COMPLETED
      setState({
        createLookerConnection: {
          state: ActionState.COMPLETED,
        },
      });

      // Invoke onSuccess callback if provided
      onSuccess?.(responsePayload);
    } catch (error) {
      // Log the error
      captureSentryException(error, "Failed to create Looker connection");

      // Update state to ERROR and capture error message
      setState({
        createLookerConnection: {
          state: ActionState.ERROR,
          error: (error as Error).message,
        },
      });

      // Invoke onError callback if provided
      onError?.(error as Error);
    }
  };
}

function createSnowflakeConnection({
  publicKey,
  prefix,
  data,
  onSuccess,
  onError,
}: EncryptedCreateParams<SnowflakeKeyData>): Action<ConnectionStoreState> {
  return async ({ setState, getState }) => {
    ReturnIf(
      getState().createSnowflakeConnection.state === ActionState.IN_PROGRESS
    );

    //
    try {
      setState({
        createSnowflakeConnection: { state: ActionState.IN_PROGRESS },
      });

      //
      const responsePayload = await authenticatedPostFetch<{ uuid: string }>(
        "api/organization/connections/snowflake/provision/create",
        {
          secret: await encryptData(data, publicKey, prefix),
        }
      );
      setState({
        createSnowflakeConnection: {
          state: ActionState.COMPLETED,
        },
      });
      onSuccess?.(responsePayload);
    } catch (error) {
      captureSentryException(error, "Failed to create connection");
      setState({
        createSnowflakeConnection: {
          state: ActionState.ERROR,
          error: (error as Error).message,
        },
      });
      onError?.(error as Error);
    }
  };
}

function retryCreateConnection({
  uuid,
  onSuccess,
  onError,
}: RetryCreateConnectionParams): Action<ConnectionStoreState> {
  return async ({ getState, dispatch }) => {
    let connections = getState().connections;
    if (!connections.has(uuid)) {
      await dispatch(loadConnections({ reload: true }));
      connections = getState().connections;

      if (!connections.has(uuid)) {
        onError?.(new Error("Connection not found"));
      }
    }

    // idk why, but this is breaking stuff
    // dispatch(deleteConnection({ uuid, onSuccess, onError }));
  };
}

function editConnection({
  uuid,
  data,
  onSuccess,
  onError,
}: EditConnectionParams): Action<ConnectionStoreState> {
  return async ({ getState, setState }) => {
    ReturnIf(getState().editConnection.state === ActionState.IN_PROGRESS);

    //
    try {
      setState({ editConnection: { state: ActionState.IN_PROGRESS } });

      //
      const connection = await authenticatedPatchFetch(
        `api/organization/connections/${uuid}`,
        data
      );
      setState({
        editConnection: {
          state: ActionState.COMPLETED,
        },
      });

      const newConnection = {
        uuid: connection.uuid,
        clientSecret: connection.client_secret
          ? convertSecretData(connection.client_secret)
          : null,
        config: connection.config,
        kind: connection.kind,
        name: connection.name,
      };

      const connections = structuredClone(getState().connections);
      connections.set(connection.uuid, newConnection);

      setState({
        connections,
      });
      onSuccess?.();
    } catch (error) {
      captureSentryException(error, "Failed to edit connection");
      setState({
        editConnection: {
          state: ActionState.ERROR,
          error: (error as Error).message,
        },
      });
      onError?.(error as Error);
    }
  };
}

function deleteConnection({
  uuid,
  onSuccess,
  onError,
}: DeleteParams): Action<ConnectionStoreState> {
  return async ({ getState, setState }) => {
    ReturnIf(getState().deleteConnection.state === ActionState.IN_PROGRESS);

    //
    try {
      setState({ deleteConnection: { state: ActionState.IN_PROGRESS } });

      //
      await authenticatedDeleteFetch(`api/organization/connections/${uuid}`);

      //
      const connections = getState().connections;
      if (connections.has(uuid)) {
        connections.delete(uuid);
      }

      setState({
        connections,
      });

      setState({ deleteConnection: { state: ActionState.COMPLETED } });
      onSuccess?.();
    } catch (error) {
      captureSentryException(error, "Failed to delete connection");
      setState({
        deleteConnection: {
          state: ActionState.ERROR,
          error: "There was an error deleting the connection",
        },
      });
      onError?.(error as Error);
    }
  };
}

function atlassianConnect({
  nonce,
  onSuccess,
  onError,
}: AtlassianConnectParams): Action<ConnectionStoreState> {
  return async ({ getState, setState }) => {
    ReturnIf(getState().atlassianConnect.state === ActionState.IN_PROGRESS);

    //
    try {
      setState({ atlassianConnect: { state: ActionState.IN_PROGRESS } });

      const responsePayload = await authenticatedPostFetch(
        `api/organization/connections/atlassian_connect`,
        {
          nonce,
        }
      );

      setState({ atlassianConnect: { state: ActionState.COMPLETED } });
      onSuccess?.(responsePayload);
    } catch (error) {
      captureSentryException(error, "Failed to connect to Atlassian");
      setState({
        atlassianConnect: {
          state: ActionState.ERROR,
          error: (error as Error).message,
        },
      });
      onError?.(error as Error);
    }
  };
}

function googleSheetConnect({
  title,
  email,
  onSuccess,
  onError,
}: GoogleSheetConnectParams): Action<ConnectionStoreState> {
  return async ({ getState, setState }) => {
    ReturnIf(getState().googleSheetConnect.state === ActionState.IN_PROGRESS);

    //
    try {
      setState({ googleSheetConnect: { state: ActionState.IN_PROGRESS } });

      const responsePayload = await authenticatedPostFetch(
        `api/organization/connections/google_sheet`,
        { title, email }
      );

      setState({ googleSheetConnect: { state: ActionState.COMPLETED } });
      onSuccess?.(responsePayload);
    } catch (error) {
      captureSentryException(error, "Failed to connect to Google Sheet");
      setState({
        googleSheetConnect: {
          state: ActionState.ERROR,
          error: (error as Error).message,
        },
      });
      onError?.((error as ResponseError)?.payload);
    }
  };
}

function webhookConnect({
  path,
  webhookUrl,
  onSuccess,
  onError,
}: WebhookConnectParams): Action<ConnectionStoreState> {
  return async ({ getState, setState }) => {
    const state = getState() as any;
    ReturnIf(state?.webhookConnect?.state === ActionState.IN_PROGRESS);

    try {
      setState({ webhookConnect: { state: ActionState.IN_PROGRESS } });
      const responsePayload = await authenticatedPostFetch(
        `api/organization/connections/${path}`,
        {
          webhook_url: webhookUrl,
        }
      );
      setState({ webhookConnect: { state: ActionState.COMPLETED } });
      onSuccess?.(responsePayload);
    } catch (error) {
      captureSentryException(
        error,
        `Failed to create ${path} webhook connection store`
      );
      setState({
        webhookConnect: {
          state: ActionState.ERROR,
          error: (error as Error).message,
        },
      });
      onError?.(error as Error);
    }
  };
}

// -----------------------------------------------------------------------------

export async function loadAllConnections(): Promise<
  ConnectionStoreState["connections"]
> {
  let page = 1;
  const connections = new Map<string, Connection>();

  //
  while (true) {
    const responsePayload = await authenticatedGetFetch<{
      results: ConnectionData[];
      next: string | null;
    }>(`api/organization/connections?page=${page}`);

    (responsePayload.results ?? []).forEach((connection) => {
      connections.set(connection.uuid, {
        uuid: connection.uuid,
        clientSecret: connection.client_secret
          ? convertSecretData(connection.client_secret)
          : null,
        config: connection.config,
        kind: connection.kind,
        name: connection.name,
      });
    });

    //
    page += 1;
    if (responsePayload.next === null) {
      break;
    }
  }

  //
  return connections;
}

// -----------------------------------------------------------------------------

function convertToKubernetesPayload(
  data: KubernetesConnectionCreateState
): KubernetesConnectionCreateApiPayload {
  switch (data.provider) {
    case "aws":
      return kubernetesStateToAWSPayload(
        data as
          | KubernetesConnectionCreateStateAkid
          | KubernetesConnectionCreateStateRole
      );

    case "kubeconfig":
      return kubernetesStateToKubeconfigPayload(
        data as KubernetesConnectionCreateStateKubeconfig
      );

    case "gcp":
      return kubernetesStateToGCPPayload(
        data as KubernetesConnectionCreateStateSvcAcctJson
      );

    default:
      throw new Error("Unsupported Kubernetes provider");
  }
}

function kubernetesStateToAWSPayload(
  data:
    | KubernetesConnectionCreateStateAkid
    | KubernetesConnectionCreateStateRole
):
  | KubernetesConnectionCreateApiPayloadAwsAkid
  | KubernetesConnectionCreateApiPayloadAwsRole {
  switch (data.secretType) {
    case Kubernetes.SecretType.AwsAkid:
      return {
        k8s_provider_type: Kubernetes.Provider.Aws,
        k8s_secret_type: Kubernetes.SecretType.AwsAkid,
        k8s_secret: {
          akid: (data as KubernetesConnectionCreateStateAkid).awsAccessKeyId,
          aks: (data as KubernetesConnectionCreateStateAkid).awsSecretKey,
        },
        k8s_cluster_resource_id: data.clusterResourceId,
      };

    case Kubernetes.SecretType.AwsRole:
      return {
        k8s_provider_type: Kubernetes.Provider.Aws,
        k8s_secret_type: Kubernetes.SecretType.AwsRole,
        k8s_secret: {
          role_arn: (data as KubernetesConnectionCreateStateRole).awsRoleArn,
          external_id: (data as KubernetesConnectionCreateStateRole)
            .awsRoleExternalId,
        },
        k8s_cluster_resource_id: data.clusterResourceId,
      };

    default:
      throw new Error("Unsupported Kubernetes AWS secret type");
  }
}

function kubernetesStateToKubeconfigPayload(
  data: KubernetesConnectionCreateStateKubeconfig
): KubernetesConnectionCreateApiPayload {
  return {
    k8s_provider_type: Kubernetes.Provider.Kubeconfig,
    k8s_secret_type: Kubernetes.SecretType.Kubeconfig,
    k8s_secret: {
      kubeconfig: data.kubeconfig,
    },
    k8s_cluster_resource_id: data.clusterResourceId,
  };
}

function kubernetesStateToGCPPayload(
  data: Required<KubernetesConnectionCreateStateSvcAcctJson>
): KubernetesConnectionCreateApiPayloadGcpSvAcctJson {
  return {
    k8s_provider_type: Kubernetes.Provider.Gcp,
    k8s_secret_type: Kubernetes.SecretType.GcpSvcAcct,
    k8s_secret: {
      type: data.gcpSvcAcctJson.type,
      project_id: data.gcpSvcAcctJson.project_id,
      private_key_id: data.gcpSvcAcctJson.private_key_id,
      private_key: data.gcpSvcAcctJson.private_key,
      client_email: data.gcpSvcAcctJson.client_email,
      client_id: data.gcpSvcAcctJson.client_id,
      auth_uri: data.gcpSvcAcctJson.auth_uri,
      token_uri: data.gcpSvcAcctJson.token_uri,
      auth_provider_x509_cert_url:
        data.gcpSvcAcctJson.auth_provider_x509_cert_url,
      client_x509_cert_url: data.gcpSvcAcctJson.client_x509_cert_url,
      universe_domain: data.gcpSvcAcctJson.universe_domain,
    },
    k8s_cluster_resource_id: data.clusterResourceId,
  };
}

// -----------------------------------------------------------------------------

function convertSecretData(secret: SecretData): SecretInfo {
  return {
    uuid: secret.uuid,
    name: secret.name,
    state: secret.state,
    stateDetails: secret.state_details,
    created: new Date(secret.created_at),
    updated: new Date(secret.last_updated),
  };
}

// -----------------------------------------------------------------------------

interface ConnectionStoreState {
  connections: Map<string, Connection>;

  // Action States
  loadConnections: ActionInfo;
  createOktaConnection: ActionInfo;
  createKubernetesConnection: ActionInfo;
  createGKEKubernetesConnection: ActionInfo;
  createLookerConnection: ActionInfo;
  createGCPConnection: ActionInfo;
  createSnowflakeConnection: ActionInfo;
  deleteConnection: ActionInfo;
  atlassianConnect: ActionInfo;
  googleSheetConnect: ActionInfo;
  webhookConnect: ActionInfo;
  retryCreateConnection: ActionInfo;
  editConnection: ActionInfo;
}

interface ActionInfo {
  state: ActionState;
  error?: string;
}

// SecretData is the on-the-wire format of a secret, which only exposed for some useSWR usage.
export interface SecretData {
  uuid: string;
  name: string;
  state: SecretState;
  state_details: string;
  created_at: string;
  last_updated: string;
}

export interface SecretInfo {
  uuid: string;
  name: string;
  state: SecretState;
  stateDetails: string;
  created: Date;
  updated: Date;
}

// TS-friendly objects
export interface Connection {
  uuid: string;
  clientSecret: SecretInfo | null;
  config: { [name: string]: any; tld?: string };
  kind: string;
  name: string;
}

// API objects
export interface ConnectionData {
  uuid: string;
  client_secret: SecretData;
  config: { [name: string]: string };
  kind: string;
  name: string;
}

interface LoadParams {
  reload: boolean;
}

interface CreateParams<T> {
  data: T;
  onSuccess?: Function;
  onError?: (error: Error) => void;
}

interface EncryptedCreateParams<T> extends CreateParams<T> {
  publicKey: CryptoKey;
  prefix: string;
}

interface RetryCreateConnectionParams {
  uuid: string;
  onSuccess?: Function;
  onError?: (error: Error) => void;
}

interface EditConnectionParams {
  uuid: string;
  data: any;
  onSuccess?: Function;
  onError?: (error: Error) => void;
}

interface DeleteParams {
  uuid: string;
  onSuccess?: Function;
  onError?: (error: Error) => void;
}

interface AtlassianConnectParams {
  nonce: string;
  onSuccess?: Function;
  onError?: (error: Error) => void;
}

interface WebhookConnectParams {
  path: string;
  webhookUrl: string;
  onSuccess?: Function;
  onError?: (error: Error) => void;
}

interface GoogleSheetConnectParams {
  title: string;
  email: string;
  onSuccess?: (response: { connection_id: string; url: string }) => void;
  onError?: (response: Record<string, string>) => void;
}
