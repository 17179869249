import { ReturnIf } from "babel-plugin-transform-functional-return";

import { type OrgStoreState } from "stores/OrganizationStore";
import { type UserStoreState } from "stores/UserStore";

import { type Service, type ServicesContainer } from "utility/connections";

import { GoogleSheetAppConfig } from "./destination/GoogleSheet";
import { JiraCloudAppConfig } from "./destination/JiraCloud";
import { MatterMostWebhookAppConfig } from "./destination/MatterMostWebhook";
import { MicrosoftTeamsWebhookAppConfig } from "./destination/MicrosoftTeamsWebhook";
import { PagerDutyAppConfig } from "./destination/PagerDuty";
import { SlackAppConfig } from "./destination/Slack";
import { SplunkCloudWebhookAppConfig } from "./destination/SplunkCloudWebhook";
import { SplunkWebhookAppConfig } from "./destination/SplunkWebhook";
import { TinesWebhookAppConfig } from "./destination/TinesWebhook";
import { WebexWebhookAppConfig } from "./destination/WebexWebhook";
import { WebhookAppConfig } from "./destination/Webhook";
import { ZapierWebhookAppConfig } from "./destination/ZapierWebhook";

// -----------------------------------------------------------------------------

export const ExternalDestinations: ServicesContainer = {
  services: [
    // Keep in order of importance
    JiraCloudAppConfig,
    SlackAppConfig,
    PagerDutyAppConfig,
    WebhookAppConfig,
    ZapierWebhookAppConfig,
    SplunkWebhookAppConfig,
    SplunkCloudWebhookAppConfig,
    GoogleSheetAppConfig,
    // Cutoff for "available destinations" preview
    // Anything below this line will only appear in "all available"
    TinesWebhookAppConfig,
    WebexWebhookAppConfig,
    MatterMostWebhookAppConfig,
    MicrosoftTeamsWebhookAppConfig,
  ],
};

// -----------------------------------------------------------------------------

export function getAvailableDestinations(
  destinations: Service[],
  org: OrgStoreState,
  user: UserStoreState
): Service[] {
  return destinations.filter((s) => {
    ReturnIf(s.featureFlag, user.user.features & (s.featureFlag as number));
    ReturnIf(!s.internalOnly, true);

    //
    return org.organization.org_isinternal;
  });
}
