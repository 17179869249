import { memo } from "react";

import SvgIcon from "@mui/material/SvgIcon";

// -----------------------------------------------------------------------------

export const OneLoginSAMLSSOIcon = memo((props: object) => {
  return (
    <SvgIcon viewBox="0 0 2329.000000 575.000000" {...props}>
      <g
        fill={props.theme?.pingFederateLogo.main}
        stroke="none"
        transform="translate(0.000000,575.000000) scale(0.100000,-0.100000)"
      >
        <path d="M10662 5458 c-9 -9 -12 -459 -12 -1965 0 -1940 0 -1953 20 -1973 19 -19 33 -20 283 -20 221 0 266 2 285 16 l22 15 0 1958 c0 1509 -3 1960 -12 1969 -9 9 -88 12 -293 12 -205 0 -284 -3 -293 -12z" />
        <path d="M18362 5458 c-9 -9 -12 -87 -12 -288 0 -233 2 -279 16 -298 15 -22 17 -22 288 -22 252 0 274 1 289 18 15 16 17 52 17 298 0 205 -3 283 -12 292 -9 9 -88 12 -293 12 -205 0 -284 -3 -293 -12z" />
        <path d="M2160 4290 c-588 -104 -1027 -516 -1162 -1090 -28 -121 -36 -420 -14 -545 36 -212 125 -428 245 -595 420 -588 1244 -780 1911 -445 404 203 691 591 765 1033 26 155 17 449 -18 588 -69 269 -192 481 -391 671 -212 203 -471 332 -773 383 -153 27 -413 26 -563 0z m495 -570 c230 -59 430 -221 537 -435 120 -238 123 -536 7 -784 -147 -314 -452 -497 -802 -479 -343 18 -626 255 -733 615 -36 119 -44 312 -19 434 98 483 543 770 1010 649z" />
        <path d="M5515 4295 c-49 -8 -130 -26 -180 -40 -107 -31 -309 -128 -382 -184 l-53 -41 0 90 c0 127 17 120 -312 120 -264 0 -267 0 -282 -22 -14 -20 -16 -165 -16 -1350 0 -1315 0 -1328 20 -1348 19 -19 33 -20 283 -20 221 0 266 2 285 16 l22 15 0 742 c0 763 5 876 41 1007 49 181 173 335 331 410 220 106 550 107 765 4 59 -28 97 -55 154 -113 86 -86 134 -172 171 -306 22 -79 23 -86 25 -890 2 -445 5 -820 8 -832 12 -52 16 -53 304 -53 215 0 271 3 287 14 19 14 19 35 19 893 0 805 -1 885 -18 968 -86 430 -313 715 -680 853 -165 62 -238 73 -482 78 -160 2 -244 -1 -310 -11z" />
        <path d="M8579 4295 c-564 -88 -983 -428 -1153 -935 -56 -170 -70 -268 -70 -500 0 -185 3 -229 22 -317 93 -426 330 -737 707 -925 181 -91 376 -142 612 -160 484 -38 910 112 1241 437 151 148 152 136 -20 298 -185 174 -226 207 -252 207 -15 0 -61 -31 -122 -82 -250 -207 -426 -284 -669 -295 -273 -12 -500 76 -674 263 -72 78 -154 219 -167 291 l-6 33 1097 2 1097 3 24 28 c23 27 24 33 24 185 -1 304 -53 533 -177 773 -192 372 -530 617 -951 690 -136 23 -426 25 -563 4z m419 -541 c298 -61 526 -284 596 -582 l14 -62 -798 0 -798 0 15 58 c63 241 258 461 486 546 146 56 335 71 485 40z" />
        <path d="M12810 4290 c-461 -81 -844 -361 -1047 -763 -48 -96 -108 -284 -127 -396 -20 -121 -21 -370 -1 -482 36 -207 125 -423 245 -592 79 -110 250 -276 360 -349 162 -107 349 -186 521 -222 772 -159 1511 247 1744 958 49 150 67 275 67 456 -1 247 -39 415 -142 625 -198 402 -589 685 -1057 765 -153 27 -413 26 -563 0z m495 -570 c159 -41 279 -113 396 -238 110 -118 165 -220 210 -390 32 -118 30 -305 -4 -434 -106 -402 -451 -658 -860 -635 -343 18 -623 253 -734 614 -24 80 -27 104 -27 253 -1 143 2 176 22 243 46 160 108 268 220 380 199 202 494 280 777 207z" />
        <path d="M16113 4299 c-104 -16 -248 -56 -355 -99 -197 -79 -349 -182 -501 -340 -191 -198 -305 -414 -364 -690 -28 -130 -25 -469 5 -602 123 -554 530 -957 1091 -1078 412 -89 801 -23 1118 192 l73 49 0 -93 c0 -199 -28 -345 -90 -473 -91 -188 -251 -312 -475 -366 -119 -29 -330 -31 -455 -5 -224 48 -404 145 -622 338 -76 66 -95 78 -125 78 -41 0 -40 1 -220 -208 -78 -90 -123 -151 -123 -165 0 -34 36 -86 111 -158 228 -219 538 -371 884 -435 155 -29 510 -27 663 4 147 30 271 71 385 128 124 62 205 121 308 223 204 204 320 463 359 796 6 57 10 584 10 1445 0 1211 -2 1358 -16 1378 -15 22 -18 22 -275 22 -313 0 -299 6 -299 -120 0 -44 -2 -80 -5 -80 -3 0 -32 20 -64 44 -119 87 -293 163 -466 202 -83 19 -455 28 -552 13z m462 -584 c232 -67 417 -223 521 -440 107 -220 120 -452 40 -687 -91 -268 -300 -465 -576 -544 -102 -29 -330 -27 -430 4 -287 91 -488 306 -572 613 -19 71 -23 107 -22 239 1 139 3 164 28 244 52 171 118 279 241 394 73 69 198 143 289 172 145 47 330 48 481 5z" />
        <path d="M20735 4295 c-49 -8 -130 -26 -180 -40 -107 -31 -309 -128 -382 -184 l-53 -41 0 90 c0 127 17 120 -312 120 -264 0 -267 0 -282 -22 -14 -20 -16 -165 -16 -1350 0 -1315 0 -1328 20 -1348 19 -19 33 -20 283 -20 221 0 266 2 285 16 l22 15 0 742 c0 763 5 876 41 1007 49 181 173 335 331 410 220 106 550 107 765 4 59 -28 97 -55 154 -113 86 -86 134 -172 171 -306 22 -79 23 -87 25 -890 2 -445 5 -820 8 -832 12 -52 16 -53 305 -53 289 0 292 0 306 54 3 12 3 409 1 881 -3 757 -6 870 -21 945 -84 424 -314 711 -679 848 -165 62 -238 73 -482 78 -160 2 -244 -1 -310 -11z" />
        <path d="M18362 4228 c-9 -9 -12 -323 -12 -1350 0 -1325 0 -1338 20 -1358 19 -19 33 -20 283 -20 221 0 266 2 285 16 l22 15 0 1343 c0 1030 -3 1345 -12 1354 -17 17 -569 17 -586 0z" />
      </g>
    </SvgIcon>
  );
});
