import { memo, useRef, useState } from "react";

import { ReturnIf } from "babel-plugin-transform-functional-return";
import styled from "styled-components";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { hasNo } from "utility/has";

// -----------------------------------------------------------------------------

export const ActionSplitButton = memo(({ options }: Props) => {
  const anchorRef = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  //
  ReturnIf(hasNo(options), null);

  //
  const currentOption = options[selectedIndex];
  if (!currentOption) {
    setSelectedIndex(selectedIndex - 1);

    //
    return null;
  }

  //
  function handleClick() {
    options[selectedIndex][2]();
  }

  function handleMenuItemClick(
    _event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) {
    setSelectedIndex(index);
    setOpen(false);
  }

  function handleToggle() {
    setOpen((prevOpen) => !prevOpen);
  }

  function handleClose(event: Event) {
    !anchorRef.current?.contains(event.target as HTMLElement) && setOpen(false);
  }

  //
  return (
    <>
      <SplitButton variant="contained" ref={anchorRef}>
        <ActionButton onClick={handleClick} size="small" variant="outlined">
          <Label>{options[selectedIndex][1]}</Label>
        </ActionButton>
        <SelectButton size="small" variant="outlined" onClick={handleToggle}>
          <ArrowDropDownIcon />
        </SelectButton>
      </SplitButton>
      <Popper
        disablePortal
        anchorEl={anchorRef.current}
        open={open}
        role={undefined}
        sx={{
          zIndex: 2000,
        }}
      >
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <SplitMenuList autoFocusItem>
              {options.map((option, index) => (
                <SplitMenuItem
                  key={option[0]}
                  selected={index === selectedIndex}
                  onClick={(event) => handleMenuItemClick(event, index)}
                >
                  {option[1]}
                </SplitMenuItem>
              ))}
            </SplitMenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
});

// -----------------------------------------------------------------------------

const SplitButton = styled(ButtonGroup)`
  box-shadow: none;
  display: flex;
  flex-shrink: 1;
  min-width: 20px;
`;

const ActionButton = styled(Button)`
  align-items: center;
  border-color: ${(props) => props.theme.primary.main} !important;
  display: flex;
  gap: 8px;
  min-width: 20px;

  &:focus {
    border-color: ${(props) => props.theme.primary.mainFaded}!important;
  }
`;

const SelectButton = styled(Button)`
  border-left: 0 !important;
`;

const SplitMenuList = styled(MenuList)`
  overflow: hidden;
  padding: 0;
`;

const SplitMenuItem = styled(MenuItem)`
  align-items: center;
  display: flex;
  gap: 8px;
`;

const Label = styled(Box)`
  min-width: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
`;

// -----------------------------------------------------------------------------

interface Props {
  options: Array<
    [
      string, // key
      string, // label
      Function, // onClick
    ]
  >;
}
