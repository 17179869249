import { memo } from "react";
import { useNavigate } from "react-router-dom";

import { useTheme } from "styled-components";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import { useOrganizationStore } from "stores/OrganizationStore";

// -----------------------------------------------------------------------------

export const AvailableConnection = memo(
  ({
    connection: { label, coverImage: CoverImage, url, onConnect } = {} as any,
    connectionType,
  }: Props) => {
    const [organizationStore] = useOrganizationStore();
    const navigate = useNavigate();
    const theme = useTheme();

    //
    return (
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <Card elevation={1} sx={{ maxHeight: 192, position: "relative" }}>
          <Box
            sx={{
              paddingTop: `${100 * (10 / 16)}%`,
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                bottom: 0,
                left: 0,
                position: "absolute",
                top: 0,
                right: 0,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  flexGrow: 1,
                  padding: "16px 32px",
                }}
              >
                <CoverImage
                  alt={label}
                  titleAccess={label}
                  sx={{
                    flexGrow: 1,
                    width: "100%",
                  }}
                  theme={theme}
                />
              </Box>
              <Button
                variant="contained"
                fullWidth
                onClick={() =>
                  onConnect
                    ? onConnect?.(organizationStore, navigate)
                    : navigate(`/environment/${connectionType}s/${url}/connect`)
                }
                color="primary"
                sx={{
                  borderRadius: 0,
                }}
              >
                <strong>Connect</strong>
              </Button>
            </Box>
          </Box>
        </Card>
      </Grid>
    );
  }
);

// -----------------------------------------------------------------------------

interface Props {
  connection: {
    name: string;
    label: string;
    url: string;
    coverImage: Function;
    onConnect?: Function;
  };
  connectionType: "source" | "destination";
}
