import { memo, Fragment } from "react";

import styled from "styled-components";

import { type ServerFindingWithType } from "data/finding";

import { pluralizeCustom } from "utility/pluralize";

import { FakeModalDropdown } from "./FakeModalDropdown";

// -----------------------------------------------------------------------------

const KeyMap = {
  finding: "name",
  asset: "asset",
  actor: "actor",
};

// -----------------------------------------------------------------------------

export const IgnoredValues = memo(({ findings, type }: Props) => {
  const values = findings.reduce<Array<[string, number]>>((arr, finding) => {
    // @ts-expect-error
    const value = finding[KeyMap[type]];
    const existing = arr.find(([findingType]) => findingType === value);
    if (existing) {
      existing[1] += 1;
      return arr;
    }

    //
    arr.push([value, 1]);

    //
    return arr;
  }, []);
  const label = pluralizeCustom(
    values.length,
    "1 ignore rule will be created",
    `${values.length} ignore rules will be created`
  );

  //
  return (
    <FakeModalDropdown label={label}>
      {values.map((value, i) => (
        <Fragment key={i}>
          <Selectable>{value[0]}</Selectable> ({value[1]})
        </Fragment>
      ))}
    </FakeModalDropdown>
  );
});

// -----------------------------------------------------------------------------

const Selectable = styled.span`
  user-select: all;
`;

// -----------------------------------------------------------------------------

interface Props {
  findings: ServerFindingWithType[];
  type: "finding" | "asset" | "actor";
}
