import { memo, useEffect, useState } from "react";

import { ReturnIf } from "babel-plugin-transform-functional-return";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import InfoOutlined from "@mui/icons-material/InfoOutlined";
import OpenInFullRounded from "@mui/icons-material/OpenInFullRounded";

import {
  useDashboardStore,
  type CumulativeDailyFindings,
  type DashboardStoreActions,
  type DashboardStoreState,
  type RateOfFinding,
} from "stores/DashboardStore";
import { SubmitState } from "stores/lib";

import { dayjs } from "utility/dayjs";

import { usePageTitle } from "effect/use_page_title";

import { Page } from "Component/Page";
import { PageContent } from "Component/PageContent";
import { PageHeader } from "Component/PageHeader";
import { PageSubTitle } from "Component/PageSubTitle";
import { PageTitle } from "Component/PageTitle";
import { RangePicker } from "Component/RangePicker";

import { BarChart } from "./Charts/BarChart";
import { GaugePie } from "./Charts/GaugePie";
import { LineChart } from "./Charts/LineChart";
import { PieChart } from "./Charts/PieChart/PieChart";
import { SingleValuePie } from "./Charts/SingleValuePie";
import { OpenFindings } from "./ExpandedModals/OpenFindings";
import { RateOfFindings } from "./ExpandedModals/RateOfFindings";
import { ResolvedFindings } from "./ExpandedModals/ResolvedFindings";
import { getBarData } from "./lib/get_bar_data";
import { getLineData } from "./lib/get_line_data";
import { getSrcSevPieData } from "./lib/get_src_sev_pie_data";

// -----------------------------------------------------------------------------

const IconSX = { fontSize: 14, color: "icons.main" };

// -----------------------------------------------------------------------------

export const SecurityHealthScorePage = memo(() => {
  usePageTitle("Reporting → Security Health Score");

  //
  const [showOpenFindingsModal, setShowOpenFindingsModal] = useState(false);
  const [showResolvedFindingsModal, setShowResolvedFindingsModal] =
    useState(false);
  const [showRateOfFindingsModal, setShowRateOfFindingsModal] = useState(false);

  const [startDate, setStartDate] = useState<Date>(
    dayjs().subtract(30, "days").toDate()
  );
  const [endDate, setEndDate] = useState<Date>(dayjs().toDate());

  //
  const [dashboardStore, dashboardActions] = useDashboardStore();
  const barData = useRateOfFindings(
    dashboardStore,
    dashboardActions,
    startDate,
    endDate
  );
  const [openFindingsData, resolvedFindingsData] = useCumulativeDailyFindings(
    dashboardStore,
    dashboardActions,
    startDate,
    endDate
  );
  const unresolvedFindingsBySrcSev = useUnresolvedFindingsBySrcSev(
    dashboardStore,
    dashboardActions
  );
  const riskScore = useRiskScore(dashboardStore, dashboardActions);
  useMedianTimeToRespond(dashboardStore, dashboardActions);
  useMedianTimeToComplete(dashboardStore, dashboardActions);

  //
  const loadingRiskScore = dashboardStore.loadRiskScore === SubmitState.STARTED;
  const loadingRateOfFinding =
    dashboardStore.loadRateOfFindings === SubmitState.STARTED;
  const loadingCumulativeDailyFindings =
    dashboardStore.loadCumulativeDailyFindings === SubmitState.STARTED;
  const loadingMedianTimeToRespond =
    dashboardStore.loadMedianTimeToRespond === SubmitState.STARTED;
  const loadingMedianTimeToComplete =
    dashboardStore.loadMedianTimeToComplete === SubmitState.STARTED;

  //
  return (
    <Page>
      <OpenFindings
        data={openFindingsData}
        closeModal={() => setShowOpenFindingsModal(false)}
        open={showOpenFindingsModal}
      />
      <ResolvedFindings
        data={resolvedFindingsData}
        closeModal={() => setShowResolvedFindingsModal(false)}
        open={showResolvedFindingsModal}
      />
      <RateOfFindings
        data={barData}
        closeModal={() => setShowRateOfFindingsModal(false)}
        open={showRateOfFindingsModal}
      />

      <PageHeader>
        <Stack spacing={1}>
          <PageTitle>Security Health Score &amp; Factors</PageTitle>
          <PageSubTitle>
            This dashboard shows the current state of your security health score
            and factors that contribute to it.
          </PageSubTitle>
        </Stack>
      </PageHeader>

      <PageContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 2,
          }}
        >
          <RangePicker
            label="Date"
            sDate={startDate}
            eDate={endDate}
            onChange={(newStartDate, newEndDate) => {
              ReturnIf(newStartDate === null || newEndDate === null);
              setStartDate(newStartDate as Date);
              setEndDate(newEndDate as Date);
            }}
          />
          <Typography variant="caption" fontWeight="light">
            Showing data as of {dayjs().format("MM/DD/YYYY")}
          </Typography>
        </Box>
        <Grid container sx={{ display: "flex", flexWrap: "wrap", gap: "12px" }}>
          <Grid item sx={{ width: "415px" }}>
            <GaugePie
              title="Security health score"
              loading={loadingRiskScore}
              riskScore={riskScore}
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              width: "450px",
            }}
          >
            <LineChart
              title={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span>Findings over time - open</span>
                  <IconButton onClick={() => setShowOpenFindingsModal(true)}>
                    <OpenInFullRounded sx={IconSX} />
                  </IconButton>
                </Box>
              }
              data={openFindingsData}
              loading={loadingCumulativeDailyFindings}
              isOpen={true}
              isResolved={false}
            />
            <LineChart
              isResolved={true}
              title={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span>Findings over time - resolved</span>
                  <IconButton
                    onClick={() => setShowResolvedFindingsModal(true)}
                  >
                    <OpenInFullRounded sx={IconSX} />
                  </IconButton>
                </Box>
              }
              data={resolvedFindingsData}
              loading={loadingCumulativeDailyFindings}
              isOpen={false}
            />
            <BarChart
              title={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span>Rate of findings</span>
                  <IconButton onClick={() => setShowRateOfFindingsModal(true)}>
                    <OpenInFullRounded sx={IconSX} />
                  </IconButton>
                </Box>
              }
              data={barData}
              loading={loadingRateOfFinding}
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            }}
          >
            <SingleValuePie
              title={
                <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <span>Med. time to respond (days)</span>
                  <Tooltip title="The median time to respond to findings. This looks at currently unresolved findings and determines the median of the difference between the creation time and now.">
                    <InfoOutlined sx={IconSX} />
                  </Tooltip>
                </Box>
              }
              data={convertSecToDays(dashboardStore.medianTimeToRespond)}
              loading={loadingMedianTimeToRespond}
            />
            <SingleValuePie
              title={
                <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <span>Med. time to complete (days)</span>
                  <Tooltip title="The median time to complete findings. This only looks at findings with a resolved time and determines the median of the difference between the creation time and resolved time.">
                    <InfoOutlined sx={IconSX} />
                  </Tooltip>
                </Box>
              }
              data={convertSecToDays(dashboardStore.medianTimeToComplete)}
              loading={loadingMedianTimeToComplete}
            />
          </Grid>
        </Grid>

        <Typography
          variant="h6"
          fontWeight="bold"
          marginBottom="16px"
          marginTop="32px"
        >
          Risk per source
        </Typography>
        <Grid container sx={{ display: "flex", flexWrap: "wrap", gap: "12px" }}>
          <PieChart
            title={
              <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <span>Severe findings</span>
                <Tooltip
                  title="Severe findings negatively impact your Security Health Score.
                Any finding with a severity of Medium or above is considered
                severe. Hover over each segment to view severities"
                >
                  <InfoOutlined sx={IconSX} />
                </Tooltip>
              </Box>
            }
            data={unresolvedFindingsBySrcSev}
            loading={loadingRiskScore}
          />
        </Grid>
      </PageContent>
    </Page>
  );
});

// -----------------------------------------------------------------------------

function useRiskScore(
  dashboardStore: DashboardStoreState,
  dashboardActions: DashboardStoreActions
) {
  const [gaugeData, setGaugeData] = useState(-1);

  //
  useMetric(
    dashboardStore.loadRiskScore,
    dashboardActions.loadRiskScore,
    () => setGaugeData(dashboardStore.riskScore ?? -1),
    () => setGaugeData(-1)
  );

  //
  return gaugeData;
}

function useRateOfFindings(
  dashboardStore: DashboardStoreState,
  dashboardActions: DashboardStoreActions,
  startDate: Date,
  endDate: Date
) {
  const [barData, setBarData] = useState(
    getBarData(undefined, startDate, endDate)
  );

  //
  useMetric(
    dashboardStore.loadRateOfFindings,
    dashboardActions.loadRateOfFindings,
    () =>
      setBarData(
        getBarData(
          dashboardStore.rateOfFindings?.filter((rof: RateOfFinding) =>
            dayjs(rof.create_time).isBetween(startDate, endDate, "day", "[]")
          ),
          startDate,
          endDate
        )
      ),
    () => setBarData([])
  );

  //
  return barData;
}

function useCumulativeDailyFindings(
  dashboardStore: DashboardStoreState,
  dashboardActions: DashboardStoreActions,
  startDate: Date,
  endDate: Date
) {
  const [openFindingsData, setOpenFindingsData] = useState(
    getLineData(undefined, true)
  );
  const [resolvedFindingsData, setResolvedFindingsData] = useState(
    getLineData(undefined, false)
  );

  //
  useMetric(
    dashboardStore.loadCumulativeDailyFindings,
    dashboardActions.loadCumulativeDailyFindings,
    () => {
      setOpenFindingsData(
        getLineData(
          dashboardStore.cumulativeDailyFindings?.filter(
            (rof: CumulativeDailyFindings) =>
              dayjs(rof.snap_timestamp).isBetween(
                startDate,
                endDate,
                "day",
                "[]"
              )
          ),
          true /* isOpen */
        )
      );
      setResolvedFindingsData(
        getLineData(
          dashboardStore.cumulativeDailyFindings?.filter(
            (rof: CumulativeDailyFindings) =>
              dayjs(rof.snap_timestamp).isBetween(
                startDate,
                endDate,
                "day",
                "[]"
              )
          ),
          false /* isOpen */
        )
      );
    },
    () => {
      setOpenFindingsData([]);
      setResolvedFindingsData([]);
    }
  );

  //
  return [openFindingsData, resolvedFindingsData];
}

function useMedianTimeToRespond(
  dashboardStore: DashboardStoreState,
  dashboardActions: DashboardStoreActions
) {
  useMetric(
    dashboardStore.loadMedianTimeToRespond,
    dashboardActions.loadMedianTimeToRespond
  );
}

function useMedianTimeToComplete(
  dashboardStore: DashboardStoreState,
  dashboardActions: DashboardStoreActions
) {
  useMetric(
    dashboardStore.loadMedianTimeToComplete,
    dashboardActions.loadMedianTimeToComplete
  );
}

function useUnresolvedFindingsBySrcSev(
  dashboardStore: DashboardStoreState,
  dashboardActions: DashboardStoreActions
) {
  const [unresolvedFindingsBySrcSev, setUnresolvedFindingsBySrcSevData] =
    useState(getSrcSevPieData([]));

  //
  useMetric(
    dashboardStore.loadUnresolvedFindingsBySrcSev,
    dashboardActions.loadUnresolvedFindingsBySrcSev,
    () =>
      setUnresolvedFindingsBySrcSevData(
        getSrcSevPieData(dashboardStore.unresolvedFindingsBySrcSev ?? [])
      ),
    () => setUnresolvedFindingsBySrcSevData([])
  );

  //
  return unresolvedFindingsBySrcSev;
}

function useMetric(
  key: any,
  load: Function,
  success: Function = noop,
  error: Function = noop
) {
  useEffect(() => {
    switch (key) {
      case undefined:
        load();
        break;

      case SubmitState.SUCCESS:
        success();
        break;

      case SubmitState.ERROR:
        error();
        break;

      default:
        break;
    }
    // eslint-disable-next-line
  }, [key]);
}

// -----------------------------------------------------------------------------

function convertSecToDays(sec: number | undefined) {
  return sec ? sec / 84600 : undefined;
}

function noop() {}
