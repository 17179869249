import { memo } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { openOrtto } from "utility/ortto";

// -----------------------------------------------------------------------------

export const BillingPageCancelPlan = memo(() => {
  //
  return (
    <Paper sx={PaperSectionSX}>
      <Typography variant="h6" fontWeight="bold" sx={SectionHeaderSX}>
        Cancel Plan
      </Typography>
      <Box sx={CancelPlanCopySX}>
        <Typography variant="body1">
          We&apos;re sorry to see you go, but if you need to discontinue your
          plan click the button below to contact support.
        </Typography>

        <Button
          variant="text"
          color="error"
          onClick={openOrtto}
          sx={CancelPlanButtonSX}
        >
          Cancel Plan
          <ArrowForwardIcon sx={ContactSalesLinkIconSX} />
        </Button>
      </Box>
    </Paper>
  );
});

// -----------------------------------------------------------------------------

const PaperSectionSX = { p: 1.5 };

const SectionHeaderSX = { borderBottom: "1px primary.border solid" };

const ContactSalesLinkIconSX = { ml: 0.5, height: 16, width: 16 };

const CancelPlanCopySX = { p: 2 };

const CancelPlanButtonSX = { pt: 1 };
