import { memo, useState } from "react";

import { FindingState, type ServerFindingWithType } from "data/finding";

import { acknowledgeFindings } from "model/finding";

import { asyncOrSwimWithSentry } from "utility/async_or_swim_sentry";
import { notifyError, notifySuccess } from "utility/notify";
import { pluralize, pluralizeCustom } from "utility/pluralize";

import { SelectedFindingsList } from "Component/SelectedFindingsList";

import { ModalActions as Actions } from "./Component/Actions";
import { ModalCancelButton as CancelButton } from "./Component/CancelButton";
import { ModalFullHeightContent as FullHeightContent } from "./Component/FullHeightContent";
import { FullHeightModal } from "./Component/FullHeightModal";
import { ModalSubmitButton as SubmitButton } from "./Component/SubmitButton";
import { ModalTitle as Title } from "./Component/Title";

// -----------------------------------------------------------------------------

export const AcknowledgeFindingsModal = memo(({ findings, onClose }: Props) => {
  const [loading, setLoading] = useState(false);

  //
  function onAcknowledge() {
    setLoading(true);
    asyncOrSwimWithSentry(
      async () => {
        await acknowledgeFindings(findings.map((finding) => finding.uuid));

        //
        findings.forEach(
          (finding) => (finding.state = FindingState.Acknowledged)
        );

        //
        notifySuccess(
          pluralizeCustom(
            findings.length,
            `Acknowledged the finding`,
            `Acknowledged ${findings.length} findings`
          )
        );
        setLoading(false);
        onClose(true);
      },
      "Failed to acknowledge findings",
      (error) => {
        console.error(error);
        notifyError(
          `Something went wrong acknowledging the ${pluralize(
            findings.length,
            "finding"
          )}`
        );
        setLoading(false);
      }
    );
  }

  function onDone() {
    onClose(false);
  }

  //
  return (
    <FullHeightModal onClose={loading ? undefined : onDone}>
      <Title>
        {pluralizeCustom(
          findings.length,
          "Acknowledge this finding?",
          `Acknowledge ${findings.length} findings?`
        )}
      </Title>

      <FullHeightContent>
        <SelectedFindingsList findings={findings} />
      </FullHeightContent>

      <Actions>
        <CancelButton disabled={loading} onClick={onDone} />
        <SubmitButton loading={loading} onClick={onAcknowledge}>
          Acknowledge
        </SubmitButton>
      </Actions>
    </FullHeightModal>
  );
});

// -----------------------------------------------------------------------------

interface Props {
  findings: ServerFindingWithType[];
  onClose: (success: boolean) => void;
}
