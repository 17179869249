import { ReturnIf } from "babel-plugin-transform-functional-return";

import { type Finding, FindingSeverity, OpenStates } from "data/finding";

import { dayjs } from "utility/dayjs";

// -----------------------------------------------------------------------------

const ChartType: Record<FindingSeverity, SeverityKey> = {
  [FindingSeverity.Critical]: "critical",
  [FindingSeverity.High]: "high",
  [FindingSeverity.Medium]: "medium",
  [FindingSeverity.Low]: "low",
  [FindingSeverity.Info]: "other",
  [FindingSeverity.EarlyAccessInfo]: "other",
};

const ChartSeverities: SeverityKey[] = [
  "critical",
  "high",
  "medium",
  "low",
  "other",
];

// -----------------------------------------------------------------------------

// This builds a timeline chart for the severity types from the first finding
// to the (user's) current date

export function getChartData(findings: Finding[] = []) {
  ReturnIf(findings.length < 1, {
    critical: [],
    high: [],
    medium: [],
    low: [],
    other: [],
  });

  //
  const chartData: Record<string, Record<string, CumulativeEntry>> = {
    critical: {},
    high: {},
    medium: {},
    low: {},
    other: {},
  };

  const cumulativeData: CumulativeData = {
    critical: {},
    high: {},
    medium: {},
    low: {},
    other: {},
  };

  //
  const cumulative: CumulativeNumbers = {
    critical: 0,
    high: 0,
    medium: 0,
    low: 0,
    other: 0,
  };

  (findings || []).forEach((finding) => {
    const chartKey: SeverityKey = ChartType[finding.severity] || "other";

    //
    cumulative[chartKey] += 1;

    //
    const created = getUTCDate(finding.create_time);
    const createdIndex = created.getTime();

    cumulativeData[chartKey][createdIndex] =
      (cumulativeData[chartKey][createdIndex] || 0) + 1;

    //
    if (!OpenStates.includes(finding.state) && finding.resolved_time != null) {
      cumulative[chartKey] -= 1;

      //
      const resolved = getUTCDate(finding.resolved_time);
      const resolvedIndex = resolved.getTime();

      cumulativeData[chartKey][resolvedIndex] =
        (cumulativeData[chartKey][resolvedIndex] || 0) - 1;
    }
  });

  //
  const firstDate = getUTCDate(new Date(findings[0].create_time));
  const today = getUTCDate(new Date()).getTime();
  const lastValue: CumulativeNumbers = {
    critical: 0,
    high: 0,
    medium: 0,
    low: 0,
    other: 0,
  };

  let currentDate = firstDate;

  do {
    const date = new Date(currentDate);
    const index = date.getTime();

    ChartSeverities.forEach((key) => {
      ReturnIf(chartData[key][index]);

      //
      const adjustment = cumulativeData[key][index] || 0;
      lastValue[key] += adjustment;

      chartData[key][index] = { x: date, y: lastValue[key] };
    });

    currentDate = dayjs(currentDate).add(1, "d").toDate();
  } while (!dayjs(currentDate).isAfter(today));

  //
  return Object.entries(chartData).reduce(
    (obj: Record<string, CumulativeEntry[]>, [key, value]) => {
      obj[key] = Object.values(value);

      //
      return obj;
    },
    {}
  );
}

// -----------------------------------------------------------------------------

function getUTCDate(date: Date) {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    0,
    0,
    0,
    0
  );
}

// -----------------------------------------------------------------------------

type SeverityKey = "critical" | "high" | "medium" | "low" | "other";

type CumulativeData = Record<SeverityKey, Record<number, number>>;
type CumulativeNumbers = Record<SeverityKey, number>;
interface CumulativeEntry {
  x: Date;
  y: number;
}
