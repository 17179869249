window.ap3c = {
  track: (obj: unknown) => console.info("Ortto.track", obj),
};

// -----------------------------------------------------------------------------

if (!process.env.REACT_APP_NO_ORTTO && !process.env.REACT_APP_NO_EXTERNAL) {
  window.ap3c = {
    cmd: [
      () => {
        window.ap3c.talk.addEventListener("ready", () =>
          window.ap3c.talk.execute("show")
        );
        window.ap3c.talk.execute("hide");
      },
      () => {
        window.ap3c.init(
          "Y0biXYNlcco5-1vBdGhyZWF0a2V5",
          "https://t.threatkey.com/"
        );
        window.ap3c.track({ v: 0 });
      },
    ],
    act: [],
    activity: (act: any) => {
      window.ap3c.act = [act];
    },
  };

  //
  const orttoScript = document.createElement("script");
  orttoScript.type = "text/javascript";
  orttoScript.async = true;
  orttoScript.defer = true;
  orttoScript.src = "https://t.threatkey.com/app.js";

  //
  document.body.appendChild(orttoScript);
} else {
  console.info("Ortto disabled");
}

// -----------------------------------------------------------------------------

export {};
