import { memo } from "react";

import { GhostEnvironmentPossibleDestination } from "./PossibleDestination";

// -----------------------------------------------------------------------------

export const GhostEnvironmentPossibleDestinations = memo(() => {
  return (
    <>
      <GhostEnvironmentPossibleDestination />
      <GhostEnvironmentPossibleDestination />
      <GhostEnvironmentPossibleDestination />
      <GhostEnvironmentPossibleDestination />
    </>
  );
});
