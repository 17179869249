import { SourceLabel, SourceToColor } from "data/finding";

// -----------------------------------------------------------------------------

export function getPieData(
  unresolvedBySource: UnresolvedEntry[] = []
): PieDataEntry[] {
  let pieData: PieDataEntry[] = unresolvedBySource.map((item) => {
    return {
      id: SourceLabel[item.source],
      label: SourceLabel[item.source],
      source: item.source,
      value: item.count,
    };
  });

  if (pieData.length > 6) {
    const above = [];
    const below: PieDataEntry[] = [];

    for (const item of pieData) {
      if (item.value <= 10) {
        below.push(item);
        continue;
      }
      above.push(item);
    }

    if (below.length > 1) {
      let otherCount = 0;
      for (const item of below) {
        otherCount += item.value;
      }

      above.push({
        id: "Other",
        label: "Other",
        value: otherCount,
        source: -1,
      });

      pieData = above;
    }
  }

  //
  pieData.map(addColor);

  //
  return pieData;
}

// -----------------------------------------------------------------------------

function addColor(item: PieDataEntry): void {
  item.color = SourceToColor[item.source];
}

// -----------------------------------------------------------------------------

interface PieDataEntry {
  id: string;
  label: string;
  value: number;
  source: number;
  color?: string;
}

interface UnresolvedEntry {
  source: number;
  count: number;
}
