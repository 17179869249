import { memo } from "react";

import styled from "styled-components";

import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

// -----------------------------------------------------------------------------

export const FindingsListPaneSkeletonFinding = memo(() => {
  return (
    <Container>
      <Content>
        <Actions>
          <Skeleton height="24px" />
          <Skeleton height="24px" />
        </Actions>
        <Skeleton height="72.5px" />
        <Box />
        <Skeleton />
      </Content>
    </Container>
  );
});

// -----------------------------------------------------------------------------

const Container = styled(Box)`
  background: ${(props) => props.theme.overviewListItem.main};
  border-top: thin ${(props) => props.theme.overviewListItem.border} solid;
  width: 100%;

  .MuiSkeleton-root {
    transform: unset;
  }

  &:nth-child(even) {
    background: ${(props) => props.theme.overviewListItem.mainFaded};
  }
`;

const Actions = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Content = styled(Box)`
  display: grid;
  gap: 8px;
  grid-template-columns: 24px 1fr;
  max-width: 1142px;
  min-height: 0vw;
  overflow: hidden;
  padding: clamp(8px, calc(0.5rem + ((1vw - 3.75px) * 0.9697)), 16px)
    clamp(8px, calc(0.5rem + ((1vw - 3.75px) * 1.9394)), 24px);
`;
