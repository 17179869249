import { memo, type MouseEventHandler } from "react";

import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useOrganizationStore } from "stores/OrganizationStore";

import { ReactMarkdown } from "Component/ReactMarkdown";
import { ServiceConnectionPhase } from "Component/ServiceConnectionPhase";

import { AmazonWebServicesConfig as Config } from "../../config";

// -----------------------------------------------------------------------------

export const AmazonWebServicesConnectPageInstallCloudFormationTemplatePhase =
  memo(({ disabled, onNext }: Props) => {
    const [organizationStore] = useOrganizationStore();

    //
    return (
      <ServiceConnectionPhase disabled={disabled}>
        <Typography variant="body1">
          The CloudFormation template creates an IAM role with permissions to
          configure log forwarding and identify security issues. For more
          details on what it does and how we use it, you can inspect the{" "}
          <Link href="https://s3.amazonaws.com/threatkey-assets/aws/provision.yml">
            CloudFormation template file
          </Link>{" "}
          and read more in our{" "}
          <Link
            href="https://docs.threatkey.com/docs/sources/amazon-web-services"
            target="_blank"
            rel="noopener noreferrer"
          >
            technical documentation
          </Link>
          .
        </Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          Note: If{" "}
          <Link href="https://docs.threatkey.com/docs/sources/amazon-web-services">
            manually creating the role
          </Link>
          , use this <code style={{ fontWeight: "bold" }}>ExternalId</code>:{" "}
          <Typography
            sx={{ display: "inline", userSelect: "all" }}
            component="span"
          >
            <ReactMarkdown
              params={{ inline: true }}
            >{`\`${organizationStore.uuid}\``}</ReactMarkdown>
          </Typography>
        </Typography>
        <Stack sx={{ mt: 2 }} spacing={2} direction="row">
          <Button
            variant="contained"
            component="a"
            href={`https://console.aws.amazon.com/cloudformation/home#/stacks/create/review?templateURL=${
              Config.cloudFormationTemplateURL ?? ""
            }&stackName=threatkey-role-${
              organizationStore.uuid
            }&param_ThreatKeyOrgId=${organizationStore.uuid}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onNext as MouseEventHandler<HTMLAnchorElement>}
          >
            Install Template
          </Button>
          <Button
            variant="text"
            onClick={onNext as MouseEventHandler<HTMLButtonElement>}
          >
            Skip (Role already created)
          </Button>
        </Stack>
      </ServiceConnectionPhase>
    );
  });

// -----------------------------------------------------------------------------

interface Props {
  disabled: boolean;
  onNext: Function;
}
