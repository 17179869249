import { type SvgIconTypeMap } from "@mui/material";

import { type OverridableComponent } from "@mui/material/OverridableComponent";

import AdjustIcon from "@mui/icons-material/Adjust";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import WarningIcon from "@mui/icons-material/Warning";

import {
  FindingSeverity,
  FindingSeverityLabel,
  FindingState,
  FindingStateLabel,
  type ServerFindingWithType,
  Source,
  SourceLabel,
} from "data/finding";

// -----------------------------------------------------------------------------

export const AllFilter: Record<FilterOptionType, FilterOption> = {
  severity: {
    type: "severity",
    label: "All Severities",
    value: "all",
  },
  state: { type: "state", label: "All States", value: "all" },
  source: {
    type: "source",
    label: "All Sources",
    value: "all",
  },
  text: { type: "text", label: "", value: "all" },
};

export const AllOpenStateFilter: FilterOption = {
  type: "state",
  label: "All Open States",
  value: "all-open",
};

export const TypeDefaultFilters = {
  severity: [AllFilter.severity],
  state: [AllOpenStateFilter],
  source: [AllFilter.source],
  text: [],
};

const DefaultFiltersJson = JSON.stringify(TypeDefaultFilters);

export function hasDefaultFilters(filters: FilterOption[]) {
  return JSON.stringify(filters) === DefaultFiltersJson;
}

export const DefaultFilters: FilterOption[] = [
  AllFilter.severity,
  AllOpenStateFilter,
  AllFilter.source,
];

export const SeverityFilterOptions: FilterOption[] = [
  generateFilter(
    "severity",
    FindingSeverityLabel,
    FindingSeverity.EarlyAccessInfo
  ),
  generateFilter("severity", FindingSeverityLabel, FindingSeverity.Info),
  generateFilter("severity", FindingSeverityLabel, FindingSeverity.Low),
  generateFilter("severity", FindingSeverityLabel, FindingSeverity.Medium),
  generateFilter("severity", FindingSeverityLabel, FindingSeverity.High),
  generateFilter("severity", FindingSeverityLabel, FindingSeverity.Critical),
];

export const StateFilterOptions: FilterOption[] = [
  generateFilter("state", FindingStateLabel, FindingState.Acknowledged),
  generateFilter("state", FindingStateLabel, FindingState.Suppressed), // Ignored
  generateFilter("state", FindingStateLabel, FindingState.New),
  generateFilter("state", FindingStateLabel, FindingState.Notified),
  generateFilter("state", FindingStateLabel, FindingState.Resolved),
  generateFilter("state", FindingStateLabel, FindingState.Resolving),
  generateFilter("state", FindingStateLabel, FindingState.Source_Disconnected),
];

export const SourceFilterOptions: FilterOption[] = [
  generateFilter("source", SourceLabel, Source.AWSScan),
  generateFilter("source", SourceLabel, Source.AzureScan),
  generateFilter("source", SourceLabel, Source.Box),
  generateFilter("source", SourceLabel, Source.GCPScan),
  generateFilter("source", SourceLabel, Source.GithubScan),
  generateFilter("source", SourceLabel, Source.GoogleScan),
  generateFilter("source", SourceLabel, Source.M365),
  generateFilter("source", SourceLabel, Source.Okta),
  generateFilter("source", SourceLabel, Source.SalesforceScan),
  generateFilter("source", SourceLabel, Source.EKS_KubernetesScan),
  generateFilter("source", SourceLabel, Source.GKE_KubernetesScan),
  generateFilter("source", SourceLabel, Source.LookerScan),
  generateFilter("source", SourceLabel, Source.SnowflakeScan),
];

export const FilterOptions: FilterOption[] = [
  AllFilter.severity,
  ...SeverityFilterOptions,

  AllOpenStateFilter,
  AllFilter.state,
  ...StateFilterOptions,

  AllFilter.source,
  ...SourceFilterOptions,
];

export const FilterOptionsByKey = FilterOptions.reduce(
  (obj, filterOption) => {
    obj[[filterOption.type, filterOption.value].join()] = filterOption;

    //
    return obj;
  },
  // eslint-disable-next-line
  {} as Record<string, FilterOption>
);

export function getFilterOptionByKey(
  type: FilterOptionType,
  value: string
): FilterOption {
  return FilterOptionsByKey[[type, value].join()];
}

export const FilterGroupLabel: Record<FilterOptionType, string> = {
  severity: "Severity",
  state: "State",
  source: "Source",
  text: "Text Search",
};

export const FilterGroupIcon: Record<
  FilterOptionType,
  OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  }
> = {
  severity: WarningIcon,
  state: ElectricBoltIcon,
  source: AdjustIcon,
  text: FormatQuoteIcon,
};

export const FilterGroupsWithDefaults: FilterOptionType[] = [
  "severity",
  "state",
  "source",
];

export const FilterGroupOrder: Record<FilterOptionType, number> = {
  text: 0,
  severity: 1,
  state: 2,
  source: 3,
};

export const FilterGroupOptions: Record<FilterOptionType, FilterOption[]> = {
  text: [],
  severity: SeverityFilterOptions,
  state: StateFilterOptions,
  source: SourceFilterOptions,
};

// -----------------------------------------------------------------------------

function generateFilter(
  type: FilterOptionType,
  labelSource: Record<number, string>,
  value: FindingSeverity | FindingState | Source
): FilterOption {
  const label = labelSource[value];

  //
  return {
    type,
    label,
    value,
  };
}

// -----------------------------------------------------------------------------

export interface FilterOption {
  label: string;
  type: FilterOptionType;
  value: "all" | string | FindingSeverity | FindingState | Source;
}

export type FilterOptionType = "severity" | "state" | "source" | "text";
export type FilterOptionValue =
  | "all"
  | "all-open"
  | FindingSeverity
  | FindingState
  | Source;

export type FindingEntry = FindingEntryFinding | FindingEntrySkipped;

export interface FindingEntryFinding {
  type: "finding";
  finding?: ServerFindingWithType;
}
export interface FindingEntrySkipped {
  type: "filtered";
  count: number;
}
