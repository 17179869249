import { memo, useState } from "react";

import useSWR from "swr";

import Typography from "@mui/material/Typography";
import Grid2 from "@mui/material/Unstable_Grid2";

import { type JiraIssueFieldMetadata } from "data/jira_cloud";

import { type Connection } from "stores/ConnectionStore";
import { swrFetcher } from "stores/lib";

import { JiraIssueField as IssueField } from "./IssueField";

// -----------------------------------------------------------------------------

export const JiraIssueCreateMeta = memo(
  ({ connection, projectID, issueTypeID, projectTypeKey }: Props) => {
    const searchParams = new URLSearchParams({ project_id: projectID });
    const apiURL = `api/organization/connections/${
      connection.uuid
    }/jira/cim?${searchParams.toString()}`;
    const [createMetaProject, setCreateMetaProject] =
      useState<ProjectIssueCreateMetadata>();
    useSWR<CreateIssueMetaResponse>(apiURL, swrFetcher, {
      onSuccess(data, key, config) {
        setCreateMetaProject(data.create_meta);
      },
    });
    const createMeta = createMetaProject?.issuetypes?.find(
      (it) => it.id === issueTypeID
    );

    //
    if (createMeta == null) {
      return null;
    }

    //
    const requiredFields = Object.entries(createMeta.fields)
      .filter((kv) => kv[1].required)
      .map((kv) => (
        <IssueField key={kv[0]} field={kv[1]} projectTypeKey={projectTypeKey} />
      ));
    const optionalFields = Object.entries(createMeta.fields)
      .filter((kv) => !kv[1].required)
      .map((kv) => (
        <IssueField key={kv[0]} field={kv[1]} projectTypeKey={projectTypeKey} />
      ));

    //
    return (
      <>
        <Grid2 xs={12}>
          <Typography variant="h6">Required fields</Typography>
        </Grid2>
        {requiredFields}
        <Grid2 xs={12}>
          <Typography variant="h6">Optional fields</Typography>
        </Grid2>
        {optionalFields}
      </>
    );
  }
);

// -----------------------------------------------------------------------------

interface Props {
  connection: Connection;
  projectID: string;
  issueTypeID: string;
  projectTypeKey: "software" | "service_desk" | "business";
}
interface CreateIssueMetaResponse {
  create_meta: ProjectIssueCreateMetadata;
}

interface ProjectIssueCreateMetadata {
  id: string;
  key: string;
  name: string;
  issuetypes: IssueTypeIssueCreateMetadata[];
}

interface IssueTypeIssueCreateMetadata {
  id: string;
  description: string;
  name: string;
  subtask: boolean;
  fields: { [key: string]: JiraIssueFieldMetadata };
}
