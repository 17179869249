import { memo } from "react";

import { ResponsiveLine, type Serie } from "@nivo/line";
import { useTheme } from "styled-components";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import { generateTicks } from "utility/charting_utils";

// -----------------------------------------------------------------------------

export const LineChart = memo(
  ({ title, data = [], loading = false, isOpen, isResolved }: Props) => {
    const hasAtLeastOneDatapoint = data.length > 0;
    const theme = useTheme();
    //

    const responsiveLineTheme = {
      axis: {
        legend: {
          text: {
            fill: theme.scatterPlot.text,
            fontSize: 20,
          },
        },
        ticks: {
          line: {
            stroke: theme.scatterPlot.text,
          },
          text: {
            fontSize: 14,
            fill: theme.scatterPlot.text,
          },
        },
      },
    };
    //
    return (
      <Card
        sx={{
          width: "100%",
          minWidth: "400px",
          position: "relative",
          height: "125px",
          overflow: "visible",
        }}
      >
        <CardContent sx={{ padding: "8px 16px" }}>
          <Typography variant="overline">{title}</Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5" fontWeight="bold">
              {loading ? (
                <>
                  &nbsp;
                  <CircularProgress size={20} />
                </>
              ) : !hasAtLeastOneDatapoint ? (
                "None"
              ) : null}
            </Typography>
          </Box>
        </CardContent>
        {hasAtLeastOneDatapoint ? (
          <Box sx={{ height: 75 }}>
            <ResponsiveLine
              theme={responsiveLineTheme}
              data={data}
              enablePoints={false}
              areaBlendMode="multiply"
              areaOpacity={0.9}
              colors={
                !isResolved
                  ? [theme.responsiveLine.isOpen]
                  : [theme.responsiveLine.isClosed]
              }
              margin={{ top: 5, right: 40, bottom: 35, left: 60 }}
              yScale={{
                type: "linear",
                min: "auto",
                max: "auto",
              }}
              enableGridX={false}
              enableGridY={false}
              axisBottom={{
                tickValues: generateTicks(data[0].data),
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
              }}
              axisLeft={{
                tickValues: 2,
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
              }}
              pointSize={10}
              pointColor={{ theme: "background" }}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabelYOffset={-12}
              defs={[
                {
                  id: "gradientOpen",
                  type: "linearGradient",
                  colors: [
                    { offset: 0, color: theme.responsiveLine.gradientOpenRed },
                    {
                      offset: 100,
                      color: theme.responsiveLine.gradientOpenDark,
                    },
                  ],
                },
                {
                  id: "gradientResolved",
                  type: "linearGradient",
                  colors: [
                    {
                      offset: 0,
                      color: theme.responsiveLine.gradientResolvedGreen,
                    },
                    {
                      offset: 100,
                      color: theme.responsiveLine.gradientResolvedDark,
                    },
                  ],
                },
              ]}
              fill={[
                {
                  match: "*",
                  id: isOpen ? "gradientOpen" : "gradientResolved",
                },
              ]}
            />
          </Box>
        ) : null}
      </Card>
    );
  }
);

// -----------------------------------------------------------------------------

interface Props {
  title: JSX.Element | string;
  data: Serie[];
  loading?: boolean;
  isOpen: boolean;
  isResolved: boolean;
}
