import { memo } from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";

import { ServiceConnectionPhase } from "Component/ServiceConnectionPhase";

// -----------------------------------------------------------------------------

export const GoogleCloudPlatformConnectPageReceivedPhase = memo(
  ({ disabled, progressIcon, successIcon, errorIcon }: Props) => {
    return (
      <ServiceConnectionPhase disabled={disabled}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {progressIcon ? (
            <CircularProgress size="24px" sx={{ mr: 1 }} />
          ) : null}
          {successIcon ? (
            <CheckCircleOutlineRoundedIcon color="success" sx={{ mr: 1 }} />
          ) : null}
          {errorIcon ? (
            <ErrorOutlineRoundedIcon color="error" sx={{ mr: 1 }} />
          ) : null}
          <Typography variant="body1">
            Connecting to Google Cloud Platform
          </Typography>
        </Box>
        <Stack sx={{ mt: 2 }} spacing={1} direction="column">
          <Typography variant="body1">
            We&apos;re connecting your Google Cloud Platform account.
          </Typography>
          <Typography variant="body1">
            Once we&apos;ve confirmed the{" "}
            <code>threatkey@gcp-scanner-prod.iam.gserviceaccount.com</code>{" "}
            service account works in your GCP project, we&apos;ll use it to
            enable the GCP APIs we need to monitor your GCP account.
          </Typography>
        </Stack>
      </ServiceConnectionPhase>
    );
  }
);

// -----------------------------------------------------------------------------

interface Props {
  disabled: boolean;
  progressIcon: boolean;
  successIcon: boolean;
  errorIcon: boolean;
}
