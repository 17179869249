import {
  memo,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  type Dispatch,
  type MutableRefObject,
  type UIEvent,
} from "react";
import {
  useLocation,
  useNavigate,
  type NavigateFunction,
} from "react-router-dom";

import { ContinueIf, ReturnIf } from "babel-plugin-transform-functional-return";
import isEqual from "lodash/isEqual";
import styled from "styled-components";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";

import {
  FindingSeverityLabel,
  FindingState,
  FindingStateLabel,
  Source,
  type Finding,
  type FindingSeverity,
  type FindingStoreState,
  type ServerFindingWithType,
} from "data/finding";
import { type FOTS } from "data/typescript";
import { type ViewMode } from "data/view_mode";

import { useFindingStore, type FindingStoreActions } from "stores/FindingStore";
import { type DRFPageResponse } from "stores/lib";

import { loadFindingTypesAsObject } from "model/finding_types";

import { asyncOrSwimWithSentry } from "utility/async_or_swim_sentry";
import { authenticatedGetFetch } from "utility/fetch/authenticated";
import { getViewMode } from "utility/get_view_mode";
import { hasAtLeast, hasLessThan, hasNo, hasSame, hasSome } from "utility/has";
import { notifyError, notifyInfo } from "utility/notify";
import { toObject, toObjectWithValue } from "utility/object";
import { pluralize } from "utility/pluralize";
import { classes, classIf } from "utility/style";

import { usePageTitle } from "effect/use_page_title";
import { useViewMode } from "effect/use_view_mode";

import { AcknowledgeFindingsModal } from "Component/Modal/AcknowledgeFindings";
import { AlterFindingsSeverityModal } from "Component/Modal/AlterFindingsSeverity";
import { IgnoreModal } from "Component/Modal/IgnoreFindings";
import { UnignoreFindingsModal } from "Component/Modal/UnignoreFindings";

import { FindingsPageActionPane as ActionPane } from "./ActionPane";
import { FindingsPageFiltersPane as Filters } from "./FiltersPane";
import {
  FindingsContext,
  type FindingsSortBy,
  type FindingsSortByColumn,
  type IFindingContext,
} from "./lib/context";
import {
  AllFilter,
  AllOpenStateFilter,
  DefaultFilters,
  getFilterOptionByKey,
  hasDefaultFilters,
  type FilterOption,
  type FilterOptionType,
  type FindingEntry,
  type FindingEntrySkipped,
} from "./lib/data";
import { FindingsPageListPane as List } from "./ListPane";
import {
  LoadingMoreState,
  FindingsPageListPaneLoadMore as LoadMore,
} from "./LoadMore";
import { FindingsPageListPaneNoFindings as NoFindings } from "./NoFindings";
import { ShareModal } from "./ShareModal";
import { FindingsPageTitlePane as Title } from "./TitlePane";

// -----------------------------------------------------------------------------

const ShareUrlPrefix = `${window.location.origin}/findings?`;

const SortByValue: Record<string, FindingsSortBy> = {
  "-severity": ["severity", false],
  severity: ["severity", true],
  "-state": ["state", false],
  state: ["state", true],
  "-source": ["source", false],
  source: ["source", true],
};

const MinimumFilters: Array<[FilterOptionType, FilterOption]> = [
  ["severity", AllFilter.severity],
  ["state", AllOpenStateFilter],
  ["source", AllFilter.source],
];

// -----------------------------------------------------------------------------

export const FindingsPage = memo(() => {
  //
  const navigate = useNavigate();
  const location = useLocation();

  const loadMoreRef = useRef<HTMLDivElement>();
  const scrollRef = useRef<HTMLDivElement>();

  const [findingStore, findingActions] = useFindingStore();

  const [state, setState] = useFindingState();
  const context = useContext(state, setState, navigate, scrollRef);
  const shareUrl = useShareSearchUrl(state);
  const searchQuery = useSearchDependencies(state);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const newDeeplinkedFindingsId =
      [
        ...searchParams.getAll("finding"),
        ...(searchParams.get("f")?.split(",") ?? []),
      ] ?? undefined;

    ReturnIf(
      newDeeplinkedFindingsId.join() === state.deeplinkedFindingIds.join()
    );

    scrollToTop(scrollRef);
    setState({
      deeplinkedFindingIds: newDeeplinkedFindingsId,
      filters: ensureMinimumFilterQuality(state.filters),
      findings: [],
      findingsTotal: 0,
      filteredFindings: [],
      filteredFindingsTotal: 0,
      selectedFindings: [],
      page: 1,
      loading: true,
      forceRetry: new Date(),
    });
  }, [state.filters, state.deeplinkedFindingIds, setState, location.search]);

  //
  useViewMode((viewMode: ViewMode) => setState({ viewMode }));
  useLoadData({
    state,
    setState,
    searchQuery,
    findingStore,
    findingActions,
  });
  usePageTitle(
    hasNo(state.findings) && state.loading
      ? "Findings → Overview → Loading..."
      : `Findings → Overview → ${state.findingsTotal} ${pluralize(
          state.findingsTotal,
          "Finding"
        )}`
  );

  //
  function onScroll(event: UIEvent<HTMLDivElement>) {
    ReturnIf(
      state.loadingError ||
        state.loading ||
        state.findings.length >= state.findingsTotal
    );

    //
    const target = event.target as HTMLDivElement;
    const bounds = target.getBoundingClientRect();
    const maxHeight = target.scrollHeight - bounds.height;
    const triggerHeight = maxHeight - 512;

    if (target.scrollTop >= triggerHeight) {
      setState({
        loading: true,
        page: state.page + 1,
      });
    }
  }

  function handleAction(
    action: "acknowledge" | "ignore" | "unignore" | "edit-severity"
  ) {
    switch (action) {
      case "acknowledge":
        setState({ showAcknowledgeModal: true });
        break;

      case "ignore":
        setState({ showIgnoreModal: true });
        break;

      case "unignore":
        setState({ showUnignoreModal: true });
        break;

      case "edit-severity":
        setState({ showEditSeverityModal: true });
        break;
    }
  }

  //
  return (
    <FindingsContext.Provider value={context}>
      {state.showAcknowledgeModal ? (
        <AcknowledgeFindingsModal
          findings={state.selectedFindings}
          onClose={(success?: boolean) => {
            ReturnIf(!success, setState({ showAcknowledgeModal: false }));
            //
            setState({
              findings: [...state.findings],
              showAcknowledgeModal: false,
              selectedFindings: [],
            });
          }}
        />
      ) : null}
      {state.showEditSeverityModal ? (
        <AlterFindingsSeverityModal
          findings={state.selectedFindings}
          onClose={(success?: boolean, newSeverity?: FindingSeverity) => {
            ReturnIf(!success, setState({ showEditSeverityModal: false }));

            //
            setState({
              findings: [...state.findings],
              showEditSeverityModal: false,
              selectedFindings: [],
            });
          }}
        />
      ) : null}
      {state.showIgnoreModal ? (
        <IgnoreModal
          findings={state.selectedFindings}
          allFindings={[
            ...(state.findings as FOTS[]),
            ...(findingStore.findings as FOTS[]),
            ...(findingStore.findingsAll as FOTS[]),
          ]}
          onClose={(success?: boolean) => {
            ReturnIf(!success, setState({ showIgnoreModal: false }));

            //
            setState({
              findings: [...state.findings],
              showIgnoreModal: false,
              selectedFindings: [],
            });
          }}
        />
      ) : null}
      {state.showUnignoreModal ? (
        <UnignoreFindingsModal
          findings={state.selectedFindings}
          onClose={(success?: boolean) => {
            ReturnIf(!success, setState({ showUnignoreModal: false }));

            //
            setState({
              findings: [...state.findings],
              showUnignoreModal: false,
              selectedFindings: [],
            });
          }}
        />
      ) : null}
      <ShareModal
        open={state.showShareModel}
        onClose={dismissShareModal(setState)}
        value={shareUrl}
      />
      <GridContainer
        className={classes(
          classIf(hasSome(state.findings), "findings", "noFindings"),
          classIf(
            hasSome(state.selectedFindings),
            "selectedFindings",
            "noSelectedFindings"
          ),
          classIf(
            hasSome(state.deeplinkedFindingIds),
            "deeplinkedFindings",
            "noDeeplinkedFindings"
          ),
          classIf(state.loading, "loading", "notLoading")
        )}
      >
        {state.contactingServer ? (
          <Box
            sx={{
              display: "flex",
              position: "absolute",
              zIndex: 10,
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              background: "primary.border",
            }}
          >
            <CircularProgress sx={{ margin: "auto" }} />
          </Box>
        ) : null}
        <HeaderContainer>
          <Header>
            <TitleContainer>
              <Title
                initialLoading={hasNo(state.findings) && state.loading}
                totalFindings={state.findingsTotal}
              />
            </TitleContainer>
            {hasSome(state.deeplinkedFindingIds) ? null : (
              <FiltersContainer>
                <Filters showShareModal={showShareModal(setState)} />
              </FiltersContainer>
            )}
          </Header>
        </HeaderContainer>
        <ActionPaneContainer>
          <ActionPane
            selected={hasSome(state.selectedFindings)}
            maxSelected={
              hasAtLeast(100, state.selectedFindings) ||
              hasSame(state.selectedFindings, state.findings) ||
              hasSome(state.deeplinkedFindingIds)
            }
            onSelect={cycleSelectAll(state, setState, navigate, scrollRef)}
            onAction={handleAction}
          />
        </ActionPaneContainer>
        <ListScrollPaneContainer>
          <List onScroll={onScroll} scrollRef={scrollRef}>
            {hasNo(state.deeplinkedFindingIds) ? (
              <LoadMore
                containerRef={loadMoreRef}
                triggerLoad={triggerLoad(state, setState)}
                state={loadMoreState(state)}
              />
            ) : null}
            <NoFindings
              retrySearch={triggerLoad(state, setState)}
              resetSearch={
                hasDefaultFilters(state.filters)
                  ? resetSearch(setState, scrollRef)
                  : undefined
              }
            />
          </List>
        </ListScrollPaneContainer>
        <StatusBar>
          {hasNo(state.findings) && state.loading ? (
            <Skeleton width="64px" />
          ) : (
            state.filteredFindingsTotal
          )}{" "}
          loaded
          {state.selectedFindings.length ? (
            <> / {state.selectedFindings.length} selected</>
          ) : null}
        </StatusBar>
      </GridContainer>
    </FindingsContext.Provider>
  );
});

// -----------------------------------------------------------------------------

function useFindingState() {
  return useReducer(updateState, {}, getInitialState);
}

function useContext(
  state: FindingsState,
  setState: Dispatch<Partial<FindingsState>>,
  navigate: NavigateFunction,
  scrollRef: MutableRefObject<HTMLDivElement | undefined>
) {
  return useMemo(
    () => getContextValue(state, setState, navigate, scrollRef),
    [state, setState, navigate, scrollRef]
  );
}

function useSearchDependencies(state: FindingsState) {
  return useMemo(() => {
    return JSON.stringify([
      state.filters,
      state.page,
      state.sortBy,
      state.forceRetry,
      state.deeplinkedFindingIds,
    ]);
  }, [
    state.filters,
    state.page,
    state.sortBy,
    state.forceRetry,
    state.deeplinkedFindingIds,
  ]);
}

function useShareSearchUrl(state: FindingsState): string {
  return useMemo(
    () =>
      [
        `${ShareUrlPrefix}${state.filters
          .map((filter) => `${filter.type}=${filter.value}`)
          .join("&")}&ordering=${
          state.sortBy[1] ? `${state.sortBy[0]}` : `-${state.sortBy[0]}`
        }`,
        hasSome(state.selectedFindings)
          ? `&f=${state.selectedFindings
              .map((finding) => finding.uuid)
              .join(",")}`
          : "",
      ]
        .join("")
        .trim(),
    [state.filters, state.selectedFindings, state.sortBy]
  );
}

function useLoadData({
  state,
  setState,
  searchQuery,
  findingStore,
  findingActions,
}: {
  state: FindingsState;
  setState: Dispatch<Partial<FindingsState>>;
  searchQuery: string;
  findingStore: FindingStoreState;
  findingActions: FindingStoreActions;
}) {
  useEffect(() => {
    ReturnIf(state.searchQuery === searchQuery);

    //
    if (
      state.searchQuery.length > 0 &&
      onlyTextFiltersChanged(state) &&
      !state.forceRetry
    ) {
      const hasMatches = getMatchesTextFiltersFunction(state.filters);
      const allFindings = state.findings;
      const [filteredFindings, filteredFindingsCount] =
        hasMatches !== undefined
          ? getFilteredFindings(allFindings, hasMatches)
          : [
              allFindings.map((finding) => ({
                type: "finding",
                finding,
              })) as FindingEntry[],
              allFindings.length,
            ];

      //
      setState({
        filteredFindings,
        filteredFindingsTotal: filteredFindingsCount,
      });
      return;
    }

    //
    const abort = new AbortController();
    asyncOrSwimWithSentry(
      async () => {
        if (state.abort) {
          state.abort.abort();
        }

        //
        setState({
          loading: true,
          loadingError: false,
          searchQuery,
          abort,
          forceRetry: undefined as FOTS,
        });

        //
        const responsePayload = hasSome(state.deeplinkedFindingIds)
          ? await loadDeeplinkFindings(state, abort, findingStore)
          : await loadSearchFindings(state, abort);
        // these results don't actually have type yet, but :shrug:

        //
        if (abort?.signal?.aborted) {
          setState({
            loading: false,
            loadingError: false,
            abort: undefined,
            findings: [],
            findingsTotal: 0,
            deeplinkedFindingIds: [],
            selectedFindings: [],
            forceRetry: undefined as FOTS,
          });
        }

        //
        const findingsById = toObjectWithValue(state.findings, "uuid", true);
        const existingFindingTypes = findingStore.findingTypes ?? {};
        const [findings, findingTypesToLoad]: [
          ServerFindingWithType[],
          string[],
        ] = (responsePayload?.results ?? []).reduce(
          (obj: [ServerFindingWithType[], string[]], finding) => {
            ReturnIf(findingsById[finding.uuid], obj);

            //
            obj[0].push(finding);

            if (
              !existingFindingTypes[finding.name] &&
              !obj[1].includes(finding.name)
            ) {
              obj[1].push(finding.name);
            }

            //
            return obj;
          },
          [[], []]
        );

        //
        if (hasSome(findingTypesToLoad)) {
          await findingActions.addFindingTypes(
            await loadFindingTypesAsObject(findingTypesToLoad)
          );
        }

        //
        findings.forEach(
          (finding) => (finding.type = findingStore.findingTypes[finding.name])
        );

        //
        const hasMatches = getMatchesTextFiltersFunction(state.filters);
        const allFindings = [...state.findings, ...findings];
        const [filteredFindings, filteredFindingsCount] =
          hasMatches !== undefined
            ? getFilteredFindings(allFindings, hasMatches)
            : [
                allFindings.map((finding) => ({
                  type: "finding",
                  finding,
                })) as FindingEntry[],
                allFindings.length,
              ];

        //
        setState({
          loading: false,
          loadingError: false,
          abort: undefined,
          findings: allFindings,
          findingsTotal: responsePayload.count,
          filteredFindings,
          filteredFindingsTotal: filteredFindingsCount,
          selectedFindings: onlyPageHasChanged(state)
            ? state.selectedFindings
            : state.deeplinkedFindingIds.length > 0
              ? [...allFindings]
              : [],
          forceRetry: undefined as FOTS,
        });
      },
      "Failed to load findings",
      (error) => {
        console.error(error);
        notifyError("Failed to load findings");
        setState({
          loading: false,
          loadingError: true,
          abort: undefined,
          forceRetry: undefined as FOTS,
        });
      },
      () => !abort?.signal?.aborted
    );

    //
    return () => state?.abort?.abort?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);
}

// -----------------------------------------------------------------------------

async function loadSearchFindings(
  state: FindingsState,
  abort: AbortController
) {
  const uniqueFilters: string[] = [];
  const query = state.filters.reduce((query, filter: FilterOption) => {
    const filterKey = `${filter.type}=${filter.value}`;
    ReturnIf(uniqueFilters.includes(filterKey), query);

    //
    switch (true) {
      case filter.type === "state" && filter.value === "all-open":
        query.append(filter.type, String(FindingState.New));
        query.append(filter.type, String(FindingState.Notified));
        query.append(filter.type, String(FindingState.Acknowledged));
        query.append(filter.type, String(FindingState.Source_Disconnected));
        break;

      case filter.type === "source" &&
        (filter.value === Source.GoogleScan ||
          filter.value === Source.GoogleEvent):
        query.append(filter.type, String(Source.GoogleScan));
        query.append(filter.type, String(Source.GoogleEvent));
        break;

      case filter.value === "all": // skip
        break;

      default:
        query.append(filter.type, String(filter.value));
        break;
    }

    //
    uniqueFilters.push(filterKey);

    //
    return query;
  }, new URLSearchParams());
  query.append("page", String(state.page));
  query.append("page_size", String(20));
  query.append(
    "ordering",
    state.sortBy[1] ? `${state.sortBy[0]}` : `-${state.sortBy[0]}`
  );

  //
  return await authenticatedGetFetch<DRFPageResponse<ServerFindingWithType>>(
    `api/findings?${query.toString()}`,
    { abort, allowedStatuses: [200, 404] }
  );
}

function loadFindingsFromStore(
  uuids: string[],
  findingStore: FindingStoreState
): [ServerFindingWithType[], string[]] {
  const findings: ServerFindingWithType[] = [];

  const existingFindings = {
    ...findingStore.findings.reduce(
      (obj: Record<string, Finding>, finding: Finding) => {
        obj[finding.uuid] = finding;

        //
        return obj;
      },
      {}
    ),
    ...findingStore.findingsAll.reduce(
      (obj: Record<string, Finding>, finding: Finding) => {
        obj[finding.uuid] = finding;

        //
        return obj;
      },
      {}
    ),
  };

  // first look into the findingstore
  // if they are coming from the analytics board, the finding would be in the store
  const foundFindings = uuids.reduce<string[]>((arr, uuid) => {
    const finding = existingFindings[uuid];
    ReturnIf(finding === undefined, arr);

    //
    findings.push(finding as FOTS);
    arr.push(uuid);

    //
    return arr;
  }, []);

  //
  return [findings, foundFindings];
}

async function loadDeeplinkFindings(
  state: FindingsState,
  abort: AbortController,
  findingStore: FindingStoreState
): Promise<DRFPageResponse<ServerFindingWithType>> {
  const [findings, foundFindings] = loadFindingsFromStore(
    state.deeplinkedFindingIds,
    findingStore
  );

  //
  for (const uuid of state.deeplinkedFindingIds) {
    ContinueIf(foundFindings.includes(uuid));
    ReturnIf(abort.signal.aborted, {
      count: 0,
      next: null,
      previous: null,
      results: [],
    });

    //
    try {
      findings.push(
        await authenticatedGetFetch<ServerFindingWithType>(
          `api/findings/${uuid}`,
          { abort, allowedStatuses: [200] }
        )
      );
    } catch (error) {
      continue;
    }
  }

  //
  return {
    count: findings.length,
    next: null,
    previous: null,
    results: findings,
  };
}

function updateState(
  state: FindingsState,
  changes: Partial<FindingsState>
): FindingsState {
  return {
    ...state,
    ...changes,
  };
}

function getInitialState(): Omit<FindingsState, "scrollRef"> {
  const {
    deepLinkFindingIds = [],
    filters = DefaultFilters,
    sortBy = ["severity", false],
  } = getDataFromURL();

  //
  return {
    loading: false,
    loadingError: false,

    contactingServer: false,

    abort: undefined as FOTS,

    page: hasSome(deepLinkFindingIds) ? 0 : 1,

    searchQuery: "",

    findings: [],
    findingsTotal: 0,

    filteredFindings: [],
    filteredFindingsTotal: 0,

    selectedFindings: [],

    deeplinkedFindingIds: deepLinkFindingIds,

    showShareModel: false,
    showAcknowledgeModal: false,
    showEditSeverityModal: false,
    showIgnoreModal: false,
    showUnignoreModal: false,

    filters: ensureMinimumFilterQuality(filters),
    tempFilters: [],

    sortBy,

    viewMode: getViewMode(),

    forceRetry: new Date(),
  };
}

function getDataFromURL() {
  const params = new URLSearchParams(window.location.search);

  //
  return {
    deepLinkFindingIds: getDeeplinkedFindingIds(params),
    filters: reduceToUniqueFilters([
      ...getUrlTextFilters(params),
      ...getUrlFilters(params, "severity"),
      ...getUrlFilters(params, "state"),
      ...getUrlFilters(params, "source"),
    ]),
    sortBy: getSortByFromUrl(params),
  };
}

function ensureMinimumFilterQuality(filters: FilterOption[]) {
  MinimumFilters.forEach(
    ([type, defaultFilter]: [FilterOptionType, FilterOption]) => {
      ReturnIf(filters.some((filter) => filter.type === type));

      //
      filters.push(defaultFilter);
    }
  );

  //
  return filters;
}

function getContextValue(
  state: FindingsState,
  setState: Dispatch<Partial<FindingsState>>,
  navigate: NavigateFunction,
  scrollRef: MutableRefObject<HTMLDivElement | undefined>
): IFindingContext {
  return {
    loading: state.loading,
    loadingError: state.loadingError,

    mode: hasSome(state.deeplinkedFindingIds) ? "deeplink" : "normal",

    findings: state.findings,
    findingsTotal: state.findingsTotal,

    filteredFindings: state.filteredFindings,
    filteredFindingsTotal: state.filteredFindingsTotal,

    action: false,

    updateFindings: (updatedFindings: ServerFindingWithType[]) => {
      const updateFindingsByUuid = toObject(updatedFindings, "uuid");

      //
      setState({
        findings: state.findings.map((finding) => {
          const updatedFinding = updateFindingsByUuid[finding.uuid];

          //
          return updatedFinding || finding;
        }),
        selectedFindings: state.selectedFindings.map((selectedFinding) => {
          const updatedFinding = updateFindingsByUuid[selectedFinding.uuid];

          //
          return updatedFinding || selectedFinding;
        }),
      });
    },

    selectedFindings: state.selectedFindings,
    setSelectedFindings: (selectedFindings: ServerFindingWithType[]) => {
      ReturnIf(
        selectedFindings.length < 1 && state.deeplinkedFindingIds.length > 0,
        clearDeepLinkFindingIds(state, setState, navigate, scrollRef)
      );
      setState({
        selectedFindings,
      });
    },

    filters: state.filters,
    setFilters: (filters: FilterOption[]) => {
      const newFilters = hasSome(filters) ? filters : DefaultFilters;
      const filtersHaveChanged = !isEqual(newFilters, state.filters);
      const justTextFiltersChanged = onlyTextFiltersChanged(state, newFilters);
      const clearOutFindings = filtersHaveChanged && !justTextFiltersChanged;

      //
      setState({
        filters: newFilters,
        ...(clearOutFindings
          ? {
              findings: [],
              findingsTotal: 0,
              filteredFindings: [],
              filteredFindingsTotal: 0,
              selectedFindings: [],
              page: 1,
            }
          : {}),
      });
    },

    tempFilters: state.tempFilters,
    setTempFilters: (tempFilters: FilterOption[]) => setState({ tempFilters }),

    sort: state.sortBy,
    setSort: (newSortBy: FindingsSortByColumn, newSortDirection: boolean) => {
      const sortHasChanged = !isEqual(newSortBy, state.sortBy);

      setState({
        sortBy: [newSortBy, newSortDirection],
        findings: sortHasChanged ? [] : state.findings,
        ...(sortHasChanged
          ? { page: 1, previousPage: 0 }
          : { page: state.page }),
      });
    },

    viewMode: state.viewMode,
  };
}

function showShareModal(setState: Dispatch<Partial<FindingsState>>) {
  return () => setState({ showShareModel: true });
}

function dismissShareModal(setState: Dispatch<Partial<FindingsState>>) {
  return () => setState({ showShareModel: false });
}

function clearDeepLinkFindingIds(
  state: FindingsState,
  setState: Dispatch<Partial<FindingsState>>,
  navigate: NavigateFunction,
  scrollRef: MutableRefObject<HTMLDivElement | undefined>
) {
  scrollToTop(scrollRef);
  navigate("/findings");

  //
  return setState({
    deeplinkedFindingIds: [],
    filters: ensureMinimumFilterQuality(state.filters),
    findings: [],
    findingsTotal: 0,
    filteredFindings: [],
    filteredFindingsTotal: 0,
    selectedFindings: [],
    page: 1,
    loading: true,
    forceRetry: new Date(),
  });
}

function cycleSelectAll(
  state: FindingsState,
  setState: Dispatch<Partial<FindingsState>>,
  navigate: NavigateFunction,
  scrollRef: MutableRefObject<HTMLDivElement | undefined>
) {
  return () => {
    ReturnIf(
      hasSome(state.deeplinkedFindingIds),
      clearDeepLinkFindingIds(state, setState, navigate, scrollRef)
    );

    //
    switch (true) {
      case hasAtLeast(100, state.selectedFindings) ||
        hasSame(state.selectedFindings, state.findings):
        return setState({
          selectedFindings: [],
        });

      case hasLessThan(100, state.selectedFindings): {
        const count = hasAtLeast(100, state.findings)
          ? 100
          : state.findings.length;
        notifyInfo(`Top ${count} ${pluralize(count, "finding")} selected`);

        //
        return setState({
          selectedFindings: state.findings.slice(0, 100),
        });
      }

      default:
        return setState({
          selectedFindings: [],
        });
    }
  };
}

function loadMoreState(state: FindingsState) {
  switch (true) {
    case state.loading:
      return LoadingMoreState.Loading;

    case state.loadingError:
      return LoadingMoreState.Error;

    case hasAtLeast(state.findingsTotal, state.findings):
      return LoadingMoreState.AllLoaded;

    default:
      return LoadingMoreState.Ready;
  }
}

function resetSearch(
  setState: Dispatch<Partial<FindingsState>>,
  scrollRef: MutableRefObject<HTMLDivElement | undefined>
) {
  return () => {
    scrollToTop(scrollRef);
    setState({
      loading: true,
      loadingError: false,
      page: 1,
      filters: DefaultFilters,
      findings: [],
      findingsTotal: 0,
      filteredFindings: [],
      filteredFindingsTotal: 0,
      selectedFindings: [],
      deeplinkedFindingIds: [],
    });
  };
}

function triggerLoad(
  state: FindingsState,
  setState: Dispatch<Partial<FindingsState>>
) {
  return (force: boolean = false) => {
    ReturnIf(
      !force &&
        (state.loading || hasAtLeast(state.findingsTotal, state.findings))
    );

    //
    setState(force ? { forceRetry: new Date() } : { page: state.page + 1 });
  };
}

function matchesFilterValue(
  source: string | undefined,
  value: string
): boolean {
  return source?.toLowerCase()?.includes(value) ?? false;
}

function scrollToTop(
  scrollRef: MutableRefObject<HTMLDivElement | undefined>
): void {
  ReturnIf(scrollRef.current !== undefined);

  //
  (scrollRef.current as HTMLDivElement).scrollTop = 0;
}

function getFilteredFindings(
  findings: ServerFindingWithType[],
  matchesTextFilters: (finding: ServerFindingWithType) => boolean
): [FindingEntry[], number] {
  const [filteredFindings, , filteredFindingsCount] = findings.reduce(
    (
      out: [FindingEntry[], FindingEntrySkipped | undefined, number],
      finding: ServerFindingWithType
    ) => {
      if (matchesTextFilters(finding)) {
        out[0].push({ type: "finding", finding });
        out[1] = undefined;
        out[2] += 1;

        //
        return out;
      }

      //
      const lastSkip = out[1] ?? {
        type: "filtered",
        count: 0,
      };
      lastSkip.count += 1;

      if (out[1] === undefined) {
        out[0].push(lastSkip);
        out[1] = lastSkip;
      }

      //
      return out;
    },
    [[], undefined, 0]
  );

  //
  return [filteredFindings, filteredFindingsCount];
}

function getMatchesTextFiltersFunction(filters: FilterOption[]) {
  const textFilters = filters
    .filter(outNonTextFilters)
    .map((filter) => (filter.value as string).toLocaleString());

  //
  return textFilters.length > 0
    ? function matchesTextFilters(finding: ServerFindingWithType): boolean {
        return textFilters.some((filterValue) => {
          const value = filterValue.toLowerCase();

          //
          return (
            matchesFilterValue(finding.uuid, value) ||
            matchesFilterValue(finding.name, value) ||
            matchesFilterValue(finding?.type?.name, value) ||
            matchesFilterValue(finding?.type?.friendly_name, value) ||
            matchesFilterValue(finding?.type?.description, value) ||
            matchesFilterValue(finding?.type?.fix_description, value) ||
            matchesFilterValue(finding?.details?.actor?.email, value) ||
            matchesFilterValue(finding?.details?.asset?.name, value) ||
            matchesFilterValue(finding?.type?.tags?.join(" "), value) ||
            matchesFilterValue(FindingSeverityLabel[finding.severity], value) ||
            matchesFilterValue(FindingStateLabel[finding.state], value)
          );
        });
      }
    : undefined;
}

function onlyTextFiltersChanged(
  state: FindingsState,
  stateFiltersOverride?: FilterOption[]
): boolean {
  const [oldFilters, page, sort] =
    stateFiltersOverride !== undefined
      ? [state.filters, state.page, state.sortBy]
      : JSON.parse(state.searchQuery || "[[]]");
  const newFilters =
    stateFiltersOverride !== undefined ? stateFiltersOverride : state.filters;

  //
  ReturnIf(oldFilters.length < 1, false);
  ReturnIf(page !== state.page, false);
  ReturnIf(sort.join() !== state.sortBy.join(), false);

  //
  const oldFiltersCompiled = oldFilters.reduce(
    (obj: Record<string, FilterOption>, filter: FilterOption) => {
      obj[JSON.stringify(filter)] = filter;

      //
      return obj;
    },
    {}
  );

  //
  return newFilters
    .filter(
      (filter) => oldFiltersCompiled[JSON.stringify(filter)] === undefined
    )
    .every(filterIsTextFilter);
}

function onlyPageHasChanged(state: FindingsState): boolean {
  ReturnIf(state.searchQuery === "", false);

  //
  const [oldFilters, page, sort] = JSON.parse(state.searchQuery || "[[]]");

  const oldFiltersCompiled = oldFilters.reduce(
    (obj: Record<string, FilterOption>, filter: FilterOption) => {
      obj[JSON.stringify(filter)] = filter;

      //
      return obj;
    },
    {}
  );

  const sortChanged = sort.join() !== state.sortBy.join();
  const pageChanged = page !== state.page;
  const filtersChanged: boolean =
    Object.entries(
      state.filters.reduce(
        (changed: FilterOption[], filter: FilterOption): FilterOption[] => {
          ReturnIf(
            oldFiltersCompiled[JSON.stringify(filter)] !== undefined,
            changed
          );

          //
          changed.push(filter);

          //
          return changed;
        },
        []
      )
    ).length > 0;

  //
  return !filtersChanged && !sortChanged && pageChanged;
}

function outNonTextFilters(filter: FilterOption): boolean {
  return filter.type === "text";
}

const filterIsTextFilter = outNonTextFilters;

function reduceToUniqueFilters(filters: FilterOption[]): FilterOption[] {
  const uniqueFilters: string[] = [];

  //
  return filters.reduce(
    (out: FilterOption[], filter: FilterOption): FilterOption[] => {
      ReturnIf(filter === undefined, out);

      //
      const filterKey = filterAsUrlParam(filter);
      ReturnIf(uniqueFilters.includes(filterKey), out);

      //
      uniqueFilters.push(filterKey);
      out.push(filter);

      //
      return out;
    },
    []
  );
}

function filterAsUrlParam(filter: FilterOption): string {
  return `${filter.type}=${filter.value}`;
}

function getUrlFilters(
  params: URLSearchParams,
  key: "severity" | "state" | "source"
): FilterOption[] {
  return params.getAll(key).map(getFilterOptionByKey.bind(undefined, key));
}

function getUrlTextFilters(params: URLSearchParams): FilterOption[] {
  return params.getAll("text").map((param) => ({
    type: "text",
    label: param,
    value: param,
  }));
}

function getDeeplinkedFindingIds(params: URLSearchParams): string[] {
  return (
    [...params.getAll("finding"), ...(params.get("f")?.split(",") ?? [])] ??
    undefined
  );
}

function getSortByFromUrl(params: URLSearchParams): FindingsSortBy {
  return SortByValue[params.get("ordering") ?? ""];
}

// -----------------------------------------------------------------------------

const Container = styled(Box)`
  display: grid;
  grid: 1fr / 1fr;
`;

const HeaderContainer = styled(Container)`
  border-bottom: thin ${(props) => props.theme.primary.border} solid;
`;

const ActionPaneContainer = styled(Container)`
  border-bottom: 1px ${(props) => props.theme.primary.border} solid;
  overflow: hidden;
  transition: 500ms ease;
`;
const GridContainer = styled(Box)`
  background: ${(props) => props.theme.primary.background};
  display: grid;
  flex-grow: 1;
  grid: min-content 0 1fr min-content min-content / 1fr;
  position: relative;
  transition: 500ms ease;
  ::-webkit-scrollbar {
    width: 8px; /* width of the scrollbar */
    color: ${(props) => props.theme.findingsGrid.main};
  }

  ${ActionPaneContainer} {
    border-bottom-width: 0;
  }

  &.selectedFindings {
    grid: min-content 48px 1fr min-content min-content / 1fr;

    ${ActionPaneContainer} {
      border-bottom-width: 1px;
    }
  }
`;

const Header = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: clamp(8px, calc(0.5rem + ((1vw - 3.75px) * 0.9697)), 16px);
  grid-column: 1 / span 2;
  max-width: 1142px;
  min-height: 0vw;
  padding: clamp(8px, calc(0.5rem + ((1vw - 3.75px) * 1.9394)), 24px);
  padding-bottom: clamp(8px, 0.5rem + (1vw - 3.75px) * 0.9697, 16px);
`;

const TitleContainer = styled(Box)`
  margin: 0;
  padding: 0;
`;

const FiltersContainer = styled(Box)``;

const ListScrollPaneContainer = styled(Container)``;

const StatusBar = styled(Box)`
  background: ${(props) => props.theme.primary.background};
  border-top: thin ${(props) => props.theme.primary.border} solid;
  font-size: 0.9rem;
  padding: 8px clamp(8px, calc(0.5rem + ((1vw - 3.75px) * 1.9394)), 24px);
`;

// -----------------------------------------------------------------------------

interface FindingsState {
  loading: boolean;
  loadingError: boolean;

  contactingServer: boolean;

  abort: AbortController;

  page: number;

  searchQuery: string;

  findings: ServerFindingWithType[];
  findingsTotal: number;

  filteredFindings: FindingEntry[];
  filteredFindingsTotal: number;

  selectedFindings: ServerFindingWithType[];

  deeplinkedFindingIds: string[];

  showShareModel: boolean;
  showAcknowledgeModal: boolean;
  showEditSeverityModal: boolean;
  showIgnoreModal: boolean;
  showUnignoreModal: boolean;

  filters: FilterOption[];
  tempFilters: FilterOption[];

  sortBy: FindingsSortBy;

  viewMode: ViewMode;

  forceRetry: Date;
}
