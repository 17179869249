import styled from "styled-components";

import Chip from "@mui/material/Chip";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";

import {
  type FindingSeverity,
  FindingSeverityLabel,
  FindingSeverityColor,
  SourceLabel,
} from "data/finding";

import { dayjs } from "utility/dayjs";

import { getDaysFromCreation } from "../lib/get_scatter_data";

// -----------------------------------------------------------------------------

const ChipSX = {
  fontWeight: "bold",
};

export const FindingsColumns = [
  {
    field: "create_time",
    headerName: "Created time",
    flex: 2,
    renderCell: (params: any) => {
      return <span>{dayjs(params.row.create_time).toISOString()}</span>;
    },
  },
  {
    field: "source",
    headerName: "Service",
    flex: 2,
    valueGetter: (params: any) => SourceLabel[params.row.source],
    renderCell: (params: any) => (
      <Typography variant="inherit" noWrap>
        {params.value}
      </Typography>
    ),
  },
  {
    field: "asset",
    headerName: "Asset Name",
    flex: 2,
    renderCell: (params: any) =>
      params.row.asset?.name || (
        <Typography variant="inherit" noWrap>
          {params.row.asset}
        </Typography>
      ),
  },
  {
    field: "severity",
    headerName: "Severity",
    flex: 1,
    renderCell: (params: any) => {
      const severity: FindingSeverity = params.row.severity;
      const label: string = FindingSeverityLabel[severity];
      const color: any = FindingSeverityColor[severity];

      //
      return (
        <Chip
          label={label}
          color={color}
          variant="filled"
          sx={ChipSX}
          size="small"
        />
      );
    },
  },
  {
    field: "age",
    headerName: "Age",
    flex: 1,
    renderCell: (params: any) => {
      return (
        <Typography variant="inherit" noWrap>
          {getDaysFromCreation(params.row.create_time)} days
        </Typography>
      );
    },
  },
];

export const FindingsColumnsLoading = [
  {
    field: "create_time",
    headerName: "Created time",
    flex: 1,
    renderCell: RenderSkeleton,
  },
  {
    field: "source",
    headerName: "Service",
    flex: 1,
    renderCell: RenderSkeleton,
  },
  {
    field: "asset",
    headerName: "Asset",
    flex: 1,
    renderCell: RenderSkeleton,
  },
  {
    field: "severity",
    headerName: "Severity",
    flex: 1,
    renderCell: RenderSkeleton,
  },
  {
    field: "age",
    headerName: "Age",
    flex: 1,
    renderCell: RenderSkeleton,
  },
];

function RenderSkeleton() {
  return <SkeletonStyled />;
}

export const LoadingRows: any[] = [
  { uuid: 1 },
  { uuid: 2 },
  { uuid: 3 },
  { uuid: 4 },
  { uuid: 5 },
  { uuid: 6 },
  { uuid: 7 },
  { uuid: 8 },
  { uuid: 9 },
  { uuid: 10 },
];

// -----------------------------------------------------------------------------

export const PaperStyle = {
  p: 0,
  width: "100%",
  minHeight: 200,
};

export const DataGridStyle = {
  border: 0,

  "& .MuiTablePagination-displayedRows": {
    lineHeight: "1 !important",
    margin: 0,
  },

  "& .MuiDataGrid-cell": {
    wordBreak: "break-all",
    justifyContent: "space-between",

    "&:focus": {
      outline: "none",
    },
  },

  "& .MuiDataGrid-columnHeaderTitleContainer": {
    padding: 0,
  },

  "& .MuiDataGrid-columnSeparator": {
    display: "none !important",
  },

  "& .arrow-cell": {
    minWidth: "32px !important",
    justifyContent: "center",
    width: "32px !important",
  },

  "& code": {
    width: "auto",
  },
};

// -----------------------------------------------------------------------------

const SkeletonStyled = styled(Skeleton)`
  width: 100%;
`;
