import { memo, useReducer } from "react";
import { Navigate, type NavigateFunction, useNavigate } from "react-router-dom";

import { ReturnIf, ThrowIf } from "babel-plugin-transform-functional-return";
import isEmpty from "lodash/isEmpty";
import styled from "styled-components";

import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import MuiCheckbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import MuiTextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { useUserStore } from "stores/UserStore";

import { authenticateAccount, registerAccount } from "model/account";

import { asyncOrSwimWithSentry } from "utility/async_or_swim_sentry";
import { notifyError, notifySuccess } from "utility/notify";

import { usePageTitle } from "effect/use_page_title";

import { LogoIcon } from "Component/Icon/Logo";

// -----------------------------------------------------------------------------

export const RegisterPage = memo(() => {
  usePageTitle("Register");

  //
  const navigate = useNavigate();
  const [userStore] = useUserStore();

  //
  const getParam = buildParams();
  const [
    {
      fullName,
      emailAddress,
      organizationName,
      errorMessage,
      contactingServer,
    },
    setState,
  ] = useReducer((state: any, action: any) => ({ ...state, ...action }), {
    fullName: getParam("name"),
    emailAddress: getParam("email_address"),
    organizationName: getParam("organization"),
    errorMessage: "",
    contactingServer: false,
  });

  //
  function submitForm() {
    const emailAddressFinal = emailAddress.trim();
    const fullNameFinal = fullName.trim();
    const organizationNameFinal = organizationName.trim();

    try {
      ThrowIf(isInvalid(emailAddressFinal), new Error("Missing email address"));
      ThrowIf(isInvalid(fullNameFinal), new Error("Missing name"));
      ThrowIf(
        isInvalid(organizationNameFinal),
        new Error("Missing organization name")
      );
    } catch (error) {
      return setState({ errorMessage: (error as Error).message });
    }

    //
    asyncOrSwimWithSentry(
      async () => {
        setState({ contactingServer: true, errorMessage: "" });
        const responsePayload = await registerAccount({
          emailAddress: emailAddressFinal,
          fullName: fullNameFinal,
          organizationName: organizationNameFinal,
        });

        switch (true) {
          case responsePayload.message === "User created":
            attemptAutoLogin(emailAddressFinal, navigate);
            notifySuccess("Your ThreatKey account has been created!");
            break;

          case responsePayload.error:
            setState({
              contactingServer: false,
              errorMessage:
                responsePayload.message ?? "An unknown error occurred",
            });
            notifyError("There was an error creating your account");
            break;

          default:
            throw new Error("An unknown error occurred");
        }
      },
      "Failed to register account",
      (error) => {
        notifyError("There was an error creating your account");
        setState({
          contactingServer: false,
          errorMessage: error.message ?? "An unknown error occurred",
        });
      }
    );
  }

  function gotoLogin() {
    navigate("/login");
  }

  function updateState(key: string) {
    return (e: React.ChangeEvent<HTMLInputElement>) =>
      setState({ [key]: e.target.value });
  }

  //
  ReturnIf(!isEmpty(userStore.user), <Navigate to="/analytics" />);

  //
  if (getParam("autosubmit") !== "") {
    // remove autosubmit from URL
    const url = new URL(window.location.href);
    url.searchParams.delete("autosubmit");

    // change URL without refresh or trigger
    window.history.replaceState(null, "", url.toString());

    //
    submitForm();
  }

  //
  return (
    <FakeModal>
      {errorMessage ? (
        <ErrorAlert>{errorMessage || "There was an error"}</ErrorAlert>
      ) : null}

      <Logo />
      <Header>Get started for free</Header>
      <Byline>
        Fill in the below form with your work email to create your ThreatKey
        account.
      </Byline>

      <TextField
        fullWidth
        label="Full Name"
        variant="outlined"
        value={fullName}
        onChange={updateState("fullName")}
        disabled={contactingServer}
      />

      <TextField
        fullWidth
        label="Email Address"
        variant="outlined"
        value={emailAddress}
        onChange={updateState("emailAddress")}
        disabled={contactingServer}
      />

      <TextField
        fullWidth
        label="Organization Name"
        variant="outlined"
        value={organizationName}
        onChange={updateState("organizationName")}
        disabled={contactingServer}
      />

      <Checkbox
        control={<MuiCheckbox checked={true} />}
        label={
          <Typography variant="body1">
            I accept the{" "}
            <Link
              href="https://www.threatkey.com/policies/terms-of-service"
              target="_blank"
              rel="noopener noreferrer"
            >
              terms &amp; conditions
            </Link>
            .
          </Typography>
        }
      />

      <RegisterButton
        onClick={submitForm}
        disabled={contactingServer}
        loading={contactingServer}
      >
        Register
      </RegisterButton>

      <PostForm>
        Already have an account?{" "}
        <Button
          size="small"
          onClick={gotoLogin}
          sx={{ fontWeight: "bold" }}
          disabled={contactingServer}
        >
          LOGIN
        </Button>
      </PostForm>
    </FakeModal>
  );
});

// -----------------------------------------------------------------------------

function buildParams() {
  const params = new URLSearchParams(window.location.search);

  //
  return (key: string, defaultValue: string = "") =>
    params.get(key) ?? defaultValue;
}

function isInvalid(value: string) {
  return ~~value?.length < 1;
}

function attemptAutoLogin(emailAddress: string, navigate: NavigateFunction) {
  asyncOrSwimWithSentry(
    async () => {
      const responsePayload = await authenticateAccount(emailAddress);
      ThrowIf(
        responsePayload?.error,
        new Error(responsePayload?.message || "There was an error logging in")
      );

      //
      ReturnIf(!responsePayload.redirectURL, navigate("/login"));

      // nosemgrep: javascript.browser.security.open-redirect.js-open-redirect
      window.location.href = responsePayload.redirectURL;
    },
    "Failed to auto-login",
    () => navigate("/login")
  );
}

// -----------------------------------------------------------------------------

const FakeModal = styled(Paper).attrs(() => ({ elevation: 1 }))`
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 420px;
  padding: 16px;
  position: relative;
  width: 100%;
`;

const Logo = styled(LogoIcon)`
  bottom: 100%;
  fill: ${(props) => props.theme.appLogo.main};
  height: 40px;
  left: 50%;
  margin-bottom: 16px;
  position: absolute;
  transform: translateX(-50%);
  width: 164.17px;
`;

const ErrorAlert = styled(Alert).attrs(() => ({ severity: "error" }))`
  margin-bottom: 8px;
`;

const Header = styled(Typography).attrs(() => ({ variant: "h5" }))`
  font-weight: bold;
`;

const TextField = styled(MuiTextField)`
  & + & {
    margin-top: 16px;
  }
`;

const Byline = styled(Typography).attrs(() => ({ variant: "body1" }))`
  margin-bottom: 20px;
`;

const Checkbox = styled(FormControlLabel)`
  margin-top: 8px;
`;

const RegisterButton = styled(LoadingButton).attrs(() => ({
  fullWidth: true,
  variant: "contained",
}))`
  margin-top: 8px;
`;

const PostForm = styled(Typography).attrs(() => ({ variant: "body1" }))`
  margin-top: 24px;
  text-align: center;
`;
