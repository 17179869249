import { memo } from "react";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

// -----------------------------------------------------------------------------

const EKSKubernetesConnectSetupPageError = memo(
  function EKSKubernetesConnectSetupPageError({
    show,
    onTryAgain,
    children,
  }: EKSKubernetesConnectSetupPageErrorProps) {
    return show ? (
      <Alert severity="error">
        {children ?? "There was an error"}
        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="error"
            onClick={onTryAgain as React.MouseEventHandler<HTMLButtonElement>}
          >
            Try Again
          </Button>
        </Box>
      </Alert>
    ) : null;
  }
);

export default EKSKubernetesConnectSetupPageError;

// -----------------------------------------------------------------------------

interface EKSKubernetesConnectSetupPageErrorProps {
  show: boolean;
  onTryAgain: Function;
  children: React.ReactNode;
}
