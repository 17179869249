import { memo, useState, type Key } from "react";

import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";

import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";

import { type FOTS } from "data/typescript";

import { useConnectionStore } from "stores/ConnectionStore";
import { ActionState } from "stores/lib";
import { useOrganizationStore } from "stores/OrganizationStore";
import { useUserStore } from "stores/UserStore";

import { getSearchedServices } from "utility/connections";

import { usePageTitle } from "effect/use_page_title";

import {
  ExternalDestinations,
  getAvailableDestinations,
} from "external/destinations";

import { LoaderModal } from "Component/Modal/LoaderModal";

import { AdjustedText } from "Component/AdjustedText";
import { AvailableConnection } from "Component/AvailableConnection";
import { Page } from "Component/Page";
import { PageContent } from "Component/PageContent";
import { PageHeader } from "Component/PageHeader";
import { PageHeaderBackButton } from "Component/PageHeaderBackButton";
import { PageTitle } from "Component/PageTitle";

// -----------------------------------------------------------------------------

export const EnvironmentDestinationsSearchPage = memo(() => {
  usePageTitle("Environment → Destinations → Search");

  //
  const [connectionStore, connectionActions] = useConnectionStore();
  const [search, setSearch] = useState("");
  const [userStore] = useUserStore();
  const [orgStore] = useOrganizationStore();

  //
  if (connectionStore.loadConnections.state === ActionState.INITIAL) {
    connectionActions.loadConnections({ reload: true });
  }

  //
  const searchTermsRaw = search.toLocaleLowerCase().trim();
  const searchTerms = searchTermsRaw.length > 0 ? search.split(/\s+/g) : [];

  const services = getAvailableDestinations(
    ExternalDestinations.services,
    orgStore,
    userStore
  );
  const destinations =
    searchTerms.length > 0
      ? getSearchedServices(services, searchTerms)
      : services;

  //
  return connectionStore.loadConnections.state === ActionState.IN_PROGRESS ? (
    <LoaderModal />
  ) : (
    <Page>
      <PageHeader>
        <PageHeaderBackButton to="/environment/destinations">
          Back
        </PageHeaderBackButton>
        <PageTitle>All Available Destinations</PageTitle>
      </PageHeader>

      <PageContent>
        <AdjustedText
          sx={{ width: "70vw" }}
          size="small"
          variant="outlined"
          placeholder="Search destinations..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment:
              searchTerms.length > 0 ? (
                <InputAdornment position="end">
                  <CancelIcon
                    onClick={() => setSearch("")}
                    style={{ cursor: "pointer" }}
                  />
                </InputAdornment>
              ) : null,
          }}
          value={search}
          onChange={(event: FOTS) => setSearch(event.target.value)}
        />

        {destinations.length > 0 ? (
          <Grid container spacing={1} sx={{ mt: 2 }}>
            {destinations.map(
              (destination: { name: Key | null | undefined }) => (
                <AvailableConnection
                  connection={destination as any}
                  connectionType="destination"
                  key={destination.name}
                />
              )
            )}
          </Grid>
        ) : (
          <Paper
            elevation={0}
            sx={{
              mt: 2,
              width: "100%",
              p: 4,
              textAlign: "center",
            }}
          >
            No destinations found
          </Paper>
        )}
      </PageContent>
    </Page>
  );
});
