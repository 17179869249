import { type Action, createHook, createStore } from "react-sweet-state";

import { ReturnIf, ThrowIf } from "babel-plugin-transform-functional-return";

import { type ResponseError } from "data/response_error";

import { captureSentryException } from "utility/capture_sentry_exception";
import { authenticatedPostFetch } from "utility/fetch/authenticated";

// -----------------------------------------------------------------------------

const DefaultError =
  "Unknown Error. Please reach out to support for assistance";

// -----------------------------------------------------------------------------

export enum OAuth2Progress {
  Unknown,
  Submitting,
  Error,
  Success,
}

export enum OAuth2URLSuffix {
  BOX = "api/organization/connections/box",
  GITHUB = "api/organization/connections/github",
  GOOGLE = "api/organization/connections/google",
  MICROSOFT_365 = "api/organization/connections/m365",
  PAGERDUTY = "api/organization/connections/pagerduty",
  SALESFORCE = "api/organization/connections/salesforce",
  SLACK = "api/organization/connections/slack",
  SNOWFLAKE = "api/organization/connections/snowflake",
}

// -----------------------------------------------------------------------------

export const initialState: OAuth2StoreState = {
  progress: OAuth2Progress.Unknown,
};

// -----------------------------------------------------------------------------

export const useOAuth2Store = createHook(
  createStore({
    initialState,
    actions: {
      submitParams,
    },
  })
);

// -----------------------------------------------------------------------------

function submitParams(
  urlSuffix: OAuth2URLSuffix,
  oauthCode: string | null,
  oauthState: string | null,
  other?: Record<string, any>
): Action<OAuth2StoreState> {
  return async ({ getState, setState }) => {
    const { progress } = getState();
    ReturnIf(progress === OAuth2Progress.Submitting);

    //
    try {
      ThrowIf(
        other?.error === "access_denied",
        new Error(
          "You either do not have sufficient permissions in the source to connect it to ThreatKey or have cancelled the connection process."
        )
      );
      ThrowIf(!oauthCode, new Error("code is missing or invalid"));
      ThrowIf(!oauthState, new Error("state is missing or invalid"));

      //
      setState({
        progress: OAuth2Progress.Submitting,
        errorDetail: undefined,
        uuid: undefined,
      });
      const responsePayload = await authenticatedPostFetch<{
        connection_uuid: string;
      }>(urlSuffix, {
        code: oauthCode,
        state: oauthState,
        ...other,
      });
      setState({
        progress: OAuth2Progress.Success,
        errorDetail: undefined,
        uuid: responsePayload.connection_uuid,
      });
    } catch (error) {
      captureSentryException(error, "Failed to submit OAuth params");
      setState({
        progress: OAuth2Progress.Error,
        errorDetail: getErrorMessage(error as ResponseError) ?? DefaultError,
        uuid: undefined,
      });
    }
  };
}

// -----------------------------------------------------------------------------

function getErrorMessage(error: ResponseError): string {
  switch (true) {
    case typeof error !== "object":
      return error as unknown as string;

    case Array.isArray(error?.payload):
      return error.payload.join(", ");

    case error?.payload?.code !== undefined:
      return error.payload.code;

    default:
      return DefaultError;
  }
}

// -----------------------------------------------------------------------------

interface OAuth2StoreState {
  progress: OAuth2Progress;
  errorDetail?: string;
  uuid?: string;
}
