import { memo } from "react";

import TextField from "@mui/material/TextField";

import { type FOTS } from "data/typescript";

// -----------------------------------------------------------------------------

export const AdjustedText = memo(
  ({
    label,
    id,
    name,
    variant = "outlined",
    onFocus,
    InputProps,
    value,
    sx,
    size,
    placeholder,
    onChange,
  }: Props) => {
    return (
      <TextField
        fullWidth={true}
        placeholder={placeholder}
        id={id}
        name={name}
        size={size}
        label={label}
        variant={variant}
        onFocus={onFocus}
        sx={{
          width: 275,
          backgroundColor: "primary.background",
          color: "primary.text",
          ...sx,
        }}
        value={value}
        onChange={onChange}
        InputProps={InputProps}
      />
    );
  }
);

// -----------------------------------------------------------------------------

interface Props {
  label?: string;
  id?: string;
  name?: string;
  onFocus?: FOTS;
  value: FOTS;
  variant?: FOTS;
  InputProps?: FOTS;
  sx?: Object;
  size?: FOTS;
  fullwidth?: FOTS;
  placeholder: string;
  onChange?: FOTS;
}
