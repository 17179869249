import { type Action, createHook, createStore } from "react-sweet-state";

import { ReturnIf } from "babel-plugin-transform-functional-return";

import { captureSentryException } from "utility/capture_sentry_exception";
import {
  authenticatedDeleteFetch,
  authenticatedGetFetch,
  authenticatedPatchFetch,
} from "utility/fetch/authenticated";

import { SubmitState } from "./lib";

// -----------------------------------------------------------------------------

const initialState: NotificationStoreState = {};

// -----------------------------------------------------------------------------

export const useNotificationStore = createHook(
  createStore({
    initialState,
    actions: {
      fetchConfig,
      updateConfig,
    },
  })
);

// -----------------------------------------------------------------------------

function fetchConfig(): Action<NotificationStoreState> {
  return async ({ setState }) => {
    try {
      setState({ fetchingState: SubmitState.STARTED });
      const responsePayload = await authenticatedGetFetch(
        "api/organization/notifications/config"
      );
      setState({
        fetchingState: SubmitState.SUCCESS,
        config: responsePayload,
      });
    } catch (error) {
      captureSentryException(error, "Failed to load notifications config");
      setState({ fetchingState: SubmitState.ERROR });
    }
  };
}

function updateConfig(
  config: Partial<NotificationConfig> | null,
  uuid: string,
  onSuccess: Function,
  onError: Function
): Action<NotificationStoreState> {
  return async ({ setState, getState }) => {
    ReturnIf(getState().updatingState === SubmitState.STARTED);

    //
    try {
      setState({ updatingState: SubmitState.STARTED });

      // Delete the old config for this connection type first
      await authenticatedDeleteFetch("api/organization/notifications/config", {
        connection_id: uuid,
      });

      //
      ReturnIf(
        config,
        await authenticatedPatchFetch(
          "api/organization/notifications/config",
          config
        )
      );

      //
      setState({
        updatingState: SubmitState.SUCCESS,
      });
      onSuccess();
    } catch (error) {
      captureSentryException(error, "Failed to update notifications config");
      setState({ updatingState: SubmitState.ERROR });
      onError();
    }
  };
}

// -----------------------------------------------------------------------------

interface NotificationStoreState {
  fetchingState?: SubmitState;
  updatingState?: SubmitState;
  config?: NotificationConfig;
}

export interface NotificationConfig {
  finding_summary_notification: NotificationConfigSetting;
  basic_notification: NotificationConfigSetting;
  finding_notification: NotificationConfigSetting;
}

interface NotificationConfigSetting {
  filterable?: boolean;
  outputs: Record<string, { enabled: boolean; destinations: Destination[] }>;
}

export interface Destination {
  filter: {
    filter_json: string | null;
  } | null;
  connection: string | null;
  specific_location: string | null;
}
