import { memo, useState } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";

import { useIgnoreStore } from "stores/IgnoreStore";

import { dayjs } from "utility/dayjs";
import { notifyError, notifySuccess } from "utility/notify";

import { type TableRule } from "./data";

// -----------------------------------------------------------------------------

export const DeleteIgnoreRuleDialog = memo(({ rule, onClose }: Props) => {
  const [, ignoreActions] = useIgnoreStore();

  const [loading, setLoading] = useState(false);

  //
  const onSave = () => {
    setLoading(true);

    ignoreActions.deleteIgnoreRule({
      uuid: rule.uuid,
      onSuccess,
      onError,
    });
  };

  const onSuccess = () => {
    ignoreActions.listIgnoreRules();
    notifySuccess(`Rule deleted. ${rule.affected_findings} unignored`);
    setLoading(false);
    onClose();
  };

  const onError = () => {
    notifyError("Something went wrong deleting the rule");
    setLoading(false);
    onClose();
  };

  //

  return (
    <Dialog open={true} onClose={onClose} fullWidth>
      <DialogTitle>Delete Rule</DialogTitle>
      <DialogContent>
        <Typography variant="body1">1 selected rule:</Typography>
        <List
          sx={{
            listStyleType: "disc",
            pl: 3,
            pt: 0,
            pb: 2,
          }}
        >
          <ListItem
            sx={{
              display: "list-item",
              pl: 1,
              pr: 0,
              pb: 0,
            }}
            key={rule.uuid}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body1" sx={{ wordBreak: "break-all" }}>
                {rule.details} ({rule.rule_type})
              </Typography>
              <Typography
                variant="body1"
                sx={{ whiteSpace: "nowrap", color: "text.secondary", ml: 1 }}
              >
                {dayjs(rule.created_at).fromNow()}
              </Typography>
            </Box>
          </ListItem>
        </List>

        <Typography>
          Deleting this rule will unignore {rule.affected_findings}. Once
          deleted, this action cannot be undone.
        </Typography>
      </DialogContent>

      <DialogActions sx={{ pt: 0, pb: 3, px: 3 }}>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          color="error"
          onClick={onSave}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
});

// -----------------------------------------------------------------------------

interface Props {
  rule: TableRule;
  onClose: () => void;
}
