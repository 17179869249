import { memo } from "react";

import { GhostEnvironmentConnectedSource } from "./ConnectedSource";

// -----------------------------------------------------------------------------

export const GhostEnvironmentConnectedSources = memo(() => {
  return (
    <>
      <GhostEnvironmentConnectedSource />
      <GhostEnvironmentConnectedSource />
      <GhostEnvironmentConnectedSource />
      <GhostEnvironmentConnectedSource />
    </>
  );
});
