import { memo, useRef } from "react";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { copyElementContentsToClipboard } from "utility/copy_to_clipboard";

import jiraApps1 from "assets/img/screenshots/jira-apps-1.png";
import jiraApps2 from "assets/img/screenshots/jira-apps-2.png";
import jiraApps3 from "assets/img/screenshots/jira-apps-3.png";
import jiraApps4 from "assets/img/screenshots/jira-apps-4.png";
import jiraApps5 from "assets/img/screenshots/jira-apps-5.png";
import jiraApps6 from "assets/img/screenshots/jira-apps-6.png";

import { ServiceConnectionPhase } from "Component/ServiceConnectionPhase";

// -----------------------------------------------------------------------------

const AC_URL = "https://ac.threatkey.com/jira-sync/atlassian-connect.json";

// -----------------------------------------------------------------------------

export const JiraCloudConnectPageAddAppPhase = memo(() => {
  const connectURLRef = useRef<HTMLElement>(null);

  //
  return (
    <Stack spacing={2}>
      <ServiceConnectionPhase disabled={false}>
        <Alert severity="info">
          <AlertTitle>Early Access instructions will differ</AlertTitle>
          During the early access period for Jira Cloud Issue Sync, connections
          must be wired in manually through a long, delicate process on several
          Jira admin pages. This will change to an Atlassian Marketplace app
          after our app submission is approved, soon after which we will plan to
          bring this integration out of Early Access.
        </Alert>
        <Typography variant="h5" sx={{ mt: 2, mb: 1 }}>
          Temporarily enable &quot;development mode&quot;
        </Typography>
        <Typography variant="body1">
          To install the app that allows Jira Issue Sync to work, you must
          enable &quot;development mode&quot; on your Jira Cloud instance.
          First, find the <b>Manage apps</b> page on your Jira Cloud instance.
          This is commonly available at{" "}
          <code>https://[your-org-here].atlassian.net/plugins/servlet/upm</code>
          . On this page, click the <b>Settings</b> option as seen in the
          screenshot below.
        </Typography>
        <Box sx={{ textAlign: "center" }}>
          <img src={jiraApps1} alt="" />
        </Box>
        <Typography variant="body1">
          To be able to install the app, ensure that{" "}
          <b>Enable development mode</b> is checked as seen below. Make sure to
          press the Apply button if a change was made.
        </Typography>
        <Box sx={{ textAlign: "center" }}>
          <img src={jiraApps2} alt="" />
        </Box>
      </ServiceConnectionPhase>
      <ServiceConnectionPhase disabled={false}>
        <Alert severity="warning">
          <AlertTitle>
            <b>This step is error-prone</b>
          </AlertTitle>
          The Manage Apps page in &quot;development mode&quot; is extremely
          prone to hanging and silently erroring out. If the the steps below do
          not work, you may have to refresh and try again!
        </Alert>
        <Typography variant="h5" sx={{ mt: 1, mb: 1 }}>
          Install the app
        </Typography>
        <Typography variant="body1">
          Click the <b>Upload app</b> link on the right side of the page. If you
          do not see it and have enabled &quot;development mode&quot;, refresh
          the page to make it appear - if it does not appear, ensure that
          &quot;development mode&quot; is enabled as per the steps above.
        </Typography>
        <Box sx={{ textAlign: "center" }}>
          <img src={jiraApps3} alt="" />
        </Box>
        <Typography variant="body1">
          Copy and paste the URL below into the dialog input, pressing{" "}
          <b>Upload</b> to install the app.
        </Typography>
        <Paper
          sx={{
            mt: 1,
            mb: 1,
            fontFamily: "monospace",
            background: "primary.background",
            color: "primary.text",
            padding: "12px",
          }}
        >
          <Box
            ref={connectURLRef}
            sx={{ userSelect: "all", wordBreak: "break-all" }}
          >
            {AC_URL}
          </Box>
          <Button
            variant="contained"
            sx={{ mt: 1 }}
            onClick={() => {
              (async () =>
                connectURLRef.current !== null
                  ? await copyElementContentsToClipboard(connectURLRef.current)
                  : null)();
            }}
          >
            Copy to clipboard
          </Button>
        </Paper>
        <Box sx={{ textAlign: "center" }}>
          <img src={jiraApps4} alt="" />
        </Box>
        <Typography variant="body1">
          Upon success, you should see the modal shown below.
        </Typography>
        <Box sx={{ textAlign: "center" }}>
          <img src={jiraApps5} alt="" />
        </Box>
      </ServiceConnectionPhase>
      <ServiceConnectionPhase disabled={false}>
        <Typography variant="h5" sx={{ mt: 1 }}>
          Connect the installation
        </Typography>
        <Typography variant="body1">
          Although the app is now installed in your Jira tenant, theoretically
          anyone on the internet can install the app like this! In order to
          securely push your findings to your Jira tenant, we must associate
          this installation to your ThreatKey organization.
        </Typography>
        <Typography variant="body1">
          If you&apos;re still on the Manage apps page, refresh to make the
          sidebar link <b>Configure/Register</b> under{" "}
          <b>ThreatKey Issue Sync</b> appear.
        </Typography>
        <Box sx={{ textAlign: "center" }}>
          <img src={jiraApps6} alt="" />
        </Box>
        <Typography variant="body1">
          This will bring you to webpage at https://ac.threatkey.com that tells
          you to navigate to a <em>very</em> long https://app.threakey.com URL.
          This URL contains information that allows us to securely register your
          Jira installation with your ThreatKey organization.
        </Typography>
      </ServiceConnectionPhase>
      <ServiceConnectionPhase disabled={false} sx={{ mt: 2 }}>
        <Typography variant="h5" sx={{ mt: 1 }}>
          That&apos;s it!
        </Typography>
        <Typography variant="body1">
          At this stage you can disable development mode if desired, but{" "}
          <Link
            href="https://confluence.atlassian.com/jirakb/things-to-know-while-enabling-development-mode-in-the-jira-cloud-1142435906.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            we do not believe that the setting affects anything but &quot;Upload
            app&quot; link visibility
          </Link>
          .
        </Typography>
        <Typography variant="body1">
          This static page is simply a long series of instructions and this
          section marks is the end. The very long URL above will land you on a
          more normal looking webpage that allows you to configure Jira Issue
          Sync.
        </Typography>
        <Typography variant="body1">
          Thanks for trudging through the installation process!
        </Typography>
      </ServiceConnectionPhase>
    </Stack>
  );
});
