import { memo } from "react";
import { useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { usePageTitle } from "effect/use_page_title";

import Image404 from "assets/img/pages/404.png";
import Image500 from "assets/img/pages/500.png";
import ImageMaintenance from "assets/img/pages/maintenance-2.png";
import ImageNotAuthorized from "assets/img/pages/not-authorized.png";
import ImageComingSoon from "assets/img/pages/rocket.png";

// -----------------------------------------------------------------------------

const Data: DataInterface = {
  "404": {
    image: Image404,
    title: "Page not found",
    message: "It looks like you've stumbled upon a page we don't recognize.",
  },
  "500": {
    image: Image500,
    title: "Internal Server Error!",
    message:
      "It looks like something on our server encountered an error. Our robots are investigating what that may be.",
  },
  "not-authorized": {
    image: ImageNotAuthorized,
    title: "You are not authorized!",
    message: "Sorry, but you don't have access to this area.",
  },
  maintenance: {
    image: ImageMaintenance,
    title: "Under Maintenance!",
    message:
      "We're working on important stuff right now. The site will return soon.",
  },
  "coming-soon": {
    image: ImageComingSoon,
    title: "Feature launching soon",
    message: "We're almost done polishing the hull and tightening bolts.",
    returnMessage: false,
  },
};

// -----------------------------------------------------------------------------

export const ErrorPage = memo(({ override: overrideCode = "404" }: Props) => {
  usePageTitle(`Error ${overrideCode}`);

  //
  const { code = overrideCode } = useParams();
  const {
    image,
    title,
    message,
    returnMessage = true,
  } = Data[code] || Data["500"];

  //
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        maxWidth: "100%",
        margin: "auto",
      }}
    >
      {/* nosemgrep: typescript.react.security.audit.react-http-leak.react-http-leak */}
      <img src={image} alt="" style={{ maxWidth: "100%", height: "auto" }} />

      <Typography variant="h3" sx={{ mt: 3, mb: 3, textAlign: "center" }}>
        {title}
      </Typography>

      <Typography variant="body1" sx={{ textAlign: "center" }}>
        {message}
      </Typography>

      {returnMessage ? (
        <Typography variant="body1" sx={{ textAlign: "center" }}>
          Care to return to safety?
        </Typography>
      ) : null}

      <Button
        component="a"
        href="/"
        sx={{ mt: 3 }}
        variant="contained"
        color="secondary"
      >
        Back to Home
      </Button>
    </Box>
  );
});

// -----------------------------------------------------------------------------

interface Props {
  override?: string;
}

interface DataInterface {
  [propName: string]: {
    image: string;
    title: string;
    message: string;
    returnMessage?: boolean;
  };
}
