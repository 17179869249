import {
  type Action,
  type BoundActions,
  createHook,
  createStore,
} from "react-sweet-state";

import { ReturnIf } from "babel-plugin-transform-functional-return";

import {
  type Finding,
  type FindingSeverity,
  FindingSeverityLabel,
  FindingState,
  type FindingStoreState,
  type FindingType,
  type ServerFinding,
} from "data/finding";

import { captureSentryException } from "utility/capture_sentry_exception";
import {
  authenticatedGetFetch,
  authenticatedPatchFetch,
  authenticatedPostFetch,
} from "utility/fetch/authenticated";

import { type DRFPageResponse, SubmitState } from "./lib";

// -----------------------------------------------------------------------------

const initialState: FindingStoreState = {
  findings: [],
  findingTypes: {},
  findingQuery: new URLSearchParams(),
  findingsPage: 1,

  findingsAll: [],

  updateSeverityInProgress: "",
  setOrgSeverityOverrideInProgress: "",
};

const pageSize = 20;

// -----------------------------------------------------------------------------

const FindingStore = createStore({
  initialState,
  actions: {
    load,

    setAll,
    clearAll,

    updateState,
    updateSeverity,

    setOrgSeverityOverride,

    bulkUpdateState,
    bulkUpdateSeverity,

    addFindingTypes,
  },
});

export const useFindingStore = createHook(FindingStore);

export type FindingStoreActions = BoundActions<
  FindingStoreState,
  typeof FindingStore.actions
>;

// -----------------------------------------------------------------------------

function setAll(findings: Finding[]): Action<FindingStoreState> {
  return ({ setState }) => {
    setState({
      findingsAllCount: findings.length,
      findingsAll: findings.slice(),
    });
  };
}

function clearAll(): Action<FindingStoreState> {
  return ({ setState }) => {
    setState({
      findingsAllCount: 0,
      findingsAll: [],
    });
  };
}

function load(
  page: number,
  query: URLSearchParams,
  reload?: boolean
): Action<FindingStoreState> {
  return async ({ getState, setState, dispatch }) => {
    const currentState = getState();
    const queryChanged =
      getState().findingQuery.toString() !== query.toString() &&
      !(
        getState().findingQuery.toString() === "state=1&state=2" &&
        query.toString() === ""
      );
    switch (getState().loadState) {
      case SubmitState.STARTED:
        return;
      case SubmitState.SUCCESS: {
        ReturnIf(
          !reload && page === currentState.findingsPage && !queryChanged
        );
      }
    }

    setState({
      loadState:
        currentState.loadState === SubmitState.SUCCESS
          ? SubmitState.STARTED_AGAIN
          : SubmitState.STARTED,
    });

    try {
      const newState: Partial<FindingStoreState> = {
        loadState: SubmitState.SUCCESS,
        findingsPage: page,
      };

      // construct query string
      if (!query.has("state")) {
        // insert default state filter if it's not in the query
        query.append("state", FindingState.New.toString());
        query.append("state", FindingState.Notified.toString());
      }
      query.sort();
      if (queryChanged) {
        setState({
          findingQuery: query,
          findings: [],
          findingsCount: undefined,
        });
      }

      const data = await authenticatedGetFetch<DRFPageResponse<ServerFinding>>(
        `api/findings?${query.toString()}`
      );

      let findingNames = new Set<string>();
      const { findings = [], findingTypes: existingNames = {} } = getState();

      //
      const newFindingsUuid = (data.results || []).reduce(
        (obj: Record<string, boolean>, finding) => {
          obj[finding.uuid] = true;

          //
          return obj;
        },
        {}
      );

      //
      newState.findings = [
        ...findings.filter(
          // this removes deeplink findings if they were loaded earlier
          (finding: Finding) => !newFindingsUuid[finding.uuid]
        ),

        // filter out repeats
        ...data.results.map((datum: ServerFinding): Finding => {
          if (existingNames[datum.name] == null) {
            findingNames = findingNames.add(datum.name);
          }

          //
          return {
            ...datum,
            create_time: new Date(datum.create_time),
            event_time: new Date(datum.event_time),
            resolved_time: datum.resolved_time
              ? new Date(datum.resolved_time)
              : undefined,
          };
        }),
      ];
      newState.findingsCount = data.count;
      newState.findingsPageCount = Math.ceil(data.count / pageSize);
      dispatch(populateMissingFindingTypes(findingNames));
      setState(newState);
    } catch (error) {
      captureSentryException(error, "Failed to load finding page");
      setState({
        loadState: SubmitState.ERROR,
        findingsError: error instanceof Error ? error.message : undefined,
      });

      throw error;
    }
  };
}

function updateState(
  uuid: string,
  state: FindingState,
  updateType: string,
  resultFn: Function
): Action<FindingStoreState> {
  return async ({ getState, setState }) => {
    try {
      if (updateType === "ignore") {
        setState({ updateIgnoreInProgress: uuid });
      }

      if (updateType === "acknowledge") {
        setState({ updateAckInProgress: uuid });
      }

      await authenticatedPatchFetch(`api/findings/${uuid}`, {
        state,
      });

      const { findings, findingsAll } = getState();

      // eslint-disable-next-line no-inner-declarations
      function toUpdatedFinding(finding: Finding): Finding {
        return finding.uuid === uuid ? { ...finding, state } : finding;
      }

      const newFindings = findings.map(toUpdatedFinding);
      const newFindingsAll = findingsAll.map(toUpdatedFinding);

      setState({
        findings: newFindings,
        findingsAll: newFindingsAll,
        updateIgnoreInProgress: initialState.updateIgnoreInProgress,
        updateAckInProgress: initialState.updateAckInProgress,
        loadMetricsState: SubmitState.STARTED_AGAIN,
      });

      resultFn({ success: true });
    } catch (error) {
      setState({
        updateIgnoreInProgress: initialState.updateIgnoreInProgress,
        updateAckInProgress: initialState.updateAckInProgress,
      });
      captureSentryException(error, "Failed to update finding");
      resultFn({ success: false });
    }
  };
}

function updateSeverity({
  uuid,
  newSeverity,
  onSuccess,
  onError,
}: {
  uuid: string;
  newSeverity: FindingSeverity;
  onSuccess: Function;
  onError: Function;
}): Action<FindingStoreState> {
  return async ({ getState, setState }) => {
    try {
      setState({ updateSeverityInProgress: uuid });

      //
      await authenticatedPatchFetch(`api/findings/${uuid}`, {
        severity: newSeverity,
      });

      //
      const state = getState();
      const newFindings = state.findings.map((finding) => {
        if (finding.uuid === uuid) {
          finding.severity = newSeverity;
          finding.customer_overridden_severity = newSeverity;
        }

        //
        return finding;
      });
      const newFindingsAll = state.findingsAll.map((finding) => {
        if (finding.uuid === uuid) {
          finding.severity = newSeverity;
          finding.customer_overridden_severity = newSeverity;
        }

        //
        return finding;
      });
      setState({
        findings: newFindings,
        findingsAll: newFindingsAll,
        loadMetricsState: SubmitState.STARTED_AGAIN,
      });

      //
      onSuccess();
      setState({
        updateSeverityInProgress: initialState.updateSeverityInProgress,
      });
    } catch (error) {
      captureSentryException(error, "Failed to update severity");
      setState({
        updateSeverityInProgress: initialState.updateSeverityInProgress,
      });
      onError();
    }
  };
}

// This will update the severity of all findings with the given name to the given severity
function setOrgSeverityOverride({
  updatedFinding,
  newSeverity,
  onSuccess,
  onError,
}: {
  updatedFinding: Finding;
  newSeverity: FindingSeverity;
  onSuccess: Function;
  onError: Function;
}): Action<FindingStoreState> {
  return async ({ getState, setState }) => {
    try {
      setState({ setOrgSeverityOverrideInProgress: updatedFinding.name });

      //
      await authenticatedPostFetch(
        "api/organization/finding_severity_overrides",
        {
          severity_overrides: [
            {
              finding_type: updatedFinding.name,
              severity: FindingSeverityLabel[newSeverity],
            },
          ],
          finding_uuids: [updatedFinding.uuid],
        }
      );

      //
      const state = getState();
      const newFindings = state.findings.map((finding) => {
        if (
          finding.name === updatedFinding.name &&
          (finding.customer_overridden_severity === null ||
            finding.uuid === updatedFinding.uuid)
        ) {
          finding.severity = newSeverity;
        }

        //
        return finding;
      });
      const newFindingsAll = state.findingsAll.map((finding) => {
        if (
          finding.name === updatedFinding.name &&
          (finding.customer_overridden_severity === null ||
            finding.uuid === updatedFinding.uuid)
        ) {
          finding.severity = newSeverity;
        }

        //
        return finding;
      });
      setState({
        findings: newFindings,
        findingsAll: newFindingsAll,
        loadMetricsState: SubmitState.STARTED_AGAIN,
      });
      onSuccess();

      setState({
        setOrgSeverityOverrideInProgress:
          initialState.setOrgSeverityOverrideInProgress,
      });
    } catch (error) {
      captureSentryException(
        error,
        "Failed to set organization severity override"
      );
      setState({
        setOrgSeverityOverrideInProgress:
          initialState.setOrgSeverityOverrideInProgress,
      });
      onError();
    }
  };
}

function bulkUpdateState({
  uuids,
  stateType,
  onSuccess,
  onError,
}: {
  uuids: string[];
  stateType: "ignore" | "unignore" | "acknowledge";
  onSuccess: Function;
  onError: Function;
}): Action<FindingStoreState> {
  return async ({ getState, setState }) => {
    ReturnIf(
      getState().bulkActionState === SubmitState.STARTED ||
        getState().bulkActionState === SubmitState.SUCCESS
    );

    //
    try {
      setState({ bulkActionState: SubmitState.STARTED });

      const newState =
        stateType === "ignore"
          ? FindingState.Suppressed
          : stateType === "unignore"
            ? FindingState.Notified
            : FindingState.Acknowledged;

      await authenticatedPatchFetch(`api/findings/update_state`, {
        finding_uuids: uuids,
        state: newState,
      });

      //
      const state = getState();
      const newFindings = state.findings.map((finding) => {
        if (uuids.includes(finding.uuid)) {
          finding.state = newState;
        }

        //
        return finding;
      });
      const newFindingsAll = state.findingsAll.map((finding) => {
        if (uuids.includes(finding.uuid)) {
          finding.state = newState;
        }

        //
        return finding;
      });

      //
      setState({
        findings: newFindings,
        findingsAll: newFindingsAll,
        bulkActionState: undefined,
      });

      onSuccess();
    } catch (error) {
      captureSentryException(error, "Failed to bulk update finding state");
      onError();
    }
  };
}

function bulkUpdateSeverity({
  uuids,
  newSeverity,
  onSuccess,
  onError,
}: {
  uuids: string[];
  newSeverity: FindingSeverity;
  onSuccess: Function;
  onError: Function;
}): Action<FindingStoreState> {
  return async ({ getState, setState }) => {
    ReturnIf(
      getState().bulkActionState === SubmitState.STARTED ||
        getState().bulkActionState === SubmitState.SUCCESS
    );

    try {
      setState({ bulkActionState: SubmitState.STARTED });

      const payload = { severity: newSeverity };

      // so functional the old way is more "functional"
      for (const uuid of uuids) {
        await authenticatedPatchFetch(`api/findings/${uuid}`, payload);
      }

      //
      const state = getState();
      const newFindings = state.findings.map((finding) => {
        if (uuids.includes(finding.uuid)) {
          finding.severity = newSeverity;
          finding.customer_overridden_severity = newSeverity;
        }

        //
        return finding;
      });
      const newFindingsAll = state.findingsAll.map((finding) => {
        if (uuids.includes(finding.uuid)) {
          finding.severity = newSeverity;
          finding.customer_overridden_severity = newSeverity;
        }

        //
        return finding;
      });

      //
      setState({
        findings: newFindings,
        findingsAll: newFindingsAll,
        bulkActionState: undefined,
      });
      onSuccess();
    } catch (error) {
      captureSentryException(error, "Failed to bulk update finding severity");
      onError();
    }
  };
}

function addFindingTypes(
  newFindingTypes: Record<string, FindingType>
): Action<FindingStoreState, void, Promise<Record<string, FindingType>>> {
  return async ({ getState, setState }) => {
    const findingTypes = Object.assign(
      getState().findingTypes ?? {},
      newFindingTypes
    );

    //
    setState({ findingTypes });

    //
    return findingTypes;
  };
}

// -----------------------------------------------------------------------------

function populateMissingFindingTypes(
  typeNames: Set<string>
): Action<FindingStoreState> {
  return async ({ getState, setState }) => {
    const findingTypes = getState().findingTypes;

    //
    const filteredTypeNames = Array.from(typeNames).filter(
      (name) => findingTypes[name] === undefined
    );
    ReturnIf(filteredTypeNames.length < 1);

    //
    let count = 0;
    let currentPage = new URLSearchParams();
    const pages = [currentPage];
    filteredTypeNames.forEach((name) => {
      if (count >= 25) {
        count = 0;
        currentPage = new URLSearchParams();
        pages.push(currentPage);
      }

      //
      currentPage.append("name", name);
      count += 1;
    });

    try {
      const addToFindingTypes = (result: FindingType) =>
        (findingTypes[result.name] = result);

      for (const page of pages) {
        const data = await authenticatedGetFetch<DRFPageResponse<FindingType>>(
          `api/findings/types?${page.toString()}`
        );
        data.results.forEach(addToFindingTypes);
      }
    } catch (error) {
      captureSentryException(
        error,
        "Failed to populate missing findings types"
      );
    }

    //
    setState({ findingTypes });
  };
}
