import { memo, useState } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid2 from "@mui/material/Unstable_Grid2";

import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";

import { useConnectionStore } from "stores/ConnectionStore";
import { useUserStore } from "stores/UserStore";

import { isValidEmail } from "utility/is_valid_email";

import { usePageTitle } from "effect/use_page_title";

import { Page } from "Component/Page";
import { PageContent } from "Component/PageContent";
import { PageHeader } from "Component/PageHeader";
import { PageHeaderBackButton } from "Component/PageHeaderBackButton";
import { PageTitle } from "Component/PageTitle";
import { ServiceConnectionInstructionPhase } from "Component/ServiceConnectionInstructionPhase";
import { ServiceConnectionPhase } from "Component/ServiceConnectionPhase";
import { ServiceConnectionPhases } from "Component/ServiceConnectionPhases";

// -----------------------------------------------------------------------------

export const GoogleSheetConnectPage = memo(() => {
  usePageTitle("Environment → Destinations → Google Sheet → Connect");

  //
  const [userStore] = useUserStore();
  const [, actions] = useConnectionStore();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("ThreatKey Findings");
  const [email, setEmail] = useState(userStore.user.email);
  const [driveURL, setDriveURL] = useState<string>();
  const emailError = isValidEmail(email) ? "" : "Not a valid email address";
  const [responseTitleError, setResponseTitleError] = useState("");

  let titleError = "";
  if (responseTitleError) {
    titleError = responseTitleError;
  } else if (title === "") {
    titleError = "Google Drive filenames can be anything but empty";
  }

  const canSubmit = !titleError && !emailError;

  const connect = () => {
    if (!loading) {
      setLoading(true);
      actions.googleSheetConnect({
        title,
        email,
        onSuccess: ({ url }) => {
          setLoading(false);
          setDriveURL(url);
        },
        onError: (response) => {
          setLoading(false);
          if (response.title) {
            setResponseTitleError(response.title);
          }
        },
      });
    }
  };

  //
  return (
    <Page>
      <PageHeader>
        <PageHeaderBackButton to="/environment/destinations">
          Back to Destinations
        </PageHeaderBackButton>
        <PageTitle>Google Sheet Sync</PageTitle>
      </PageHeader>

      <PageContent>
        <ServiceConnectionPhases>
          <ServiceConnectionInstructionPhase>
            <Typography variant="body1">
              Google Sheet Sync allows the ThreatKey platform to
              &quot;connect&quot; to a Google Sheet, synchronizing all findings
              periodically into a spreadsheet.
            </Typography>
          </ServiceConnectionInstructionPhase>

          <ServiceConnectionPhase disabled={loading || !!driveURL}>
            <Typography variant="body1">
              Please provide a title for the spreadsheet and which email it
              should be shared with.
            </Typography>
            <Grid2 container spacing={2}>
              <Grid2 xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  label="Google Sheet title"
                  value={title}
                  onChange={(event) => {
                    setTitle(event.target.value);
                    if (responseTitleError) {
                      setResponseTitleError("");
                    }
                  }}
                  sx={{ mt: 1 }}
                  error={!!titleError}
                  helperText={titleError}
                />
              </Grid2>
              <Grid2 xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  label="Email address"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  sx={{ mt: 1 }}
                  error={!!emailError}
                  helperText={emailError}
                />
              </Grid2>
              <Grid2
                xs={12}
                display="flex"
                justifyContent="right"
                alignItems="flex-end"
              >
                <LoadingButton
                  variant="contained"
                  type="submit"
                  onClick={connect}
                  loading={loading}
                  disabled={!canSubmit}
                >
                  Create and share sheet
                </LoadingButton>
              </Grid2>
            </Grid2>
          </ServiceConnectionPhase>

          {!driveURL ? null : (
            <ServiceConnectionPhase disabled={!driveURL}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CheckCircleOutlineRoundedIcon color="success" sx={{ mr: 1 }} />
                <Typography variant="body1">Done!</Typography>
              </Box>
              <Stack sx={{ mt: 2 }} spacing={1}>
                <Typography variant="body1">
                  File <a href={driveURL}>{title}</a> created and shared with{" "}
                  {email}.
                </Typography>
              </Stack>
            </ServiceConnectionPhase>
          )}
        </ServiceConnectionPhases>
      </PageContent>
    </Page>
  );
});
