import { memo, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ReturnIf } from "babel-plugin-transform-functional-return";

import { useUserStore } from "stores/UserStore";

import { setCrossTabMessageHandler } from "utility/crosstab";

// -----------------------------------------------------------------------------

export const HandleCrossTabMessages = memo((): null => {
  const [userStore] = useUserStore();
  const navigate = useNavigate();

  //
  useEffect(() => {
    setCrossTabMessageHandler((message) => {
      switch (message) {
        case "login":
          ReturnIf(userStore.user !== null);
          window.location.reload();
          break;

        case "logout":
          ReturnIf(userStore.user === null);
          window.location.reload();
          break;
      }
    });
  }, [navigate, userStore.user]);

  //
  return null;
});
