import { memo } from "react";

import Typography from "@mui/material/Typography";

import { GoogleScopesEvaluationSummary } from "data/google";

import { type Connection } from "stores/ConnectionStore";

import { evaluateGoogleScopes } from "utility/google";

// -----------------------------------------------------------------------------

export const GoogleWorkspaceConnectedExtraDetails = memo(
  (connection: Connection) => {
    const details: StateDetails = processStateDetails(
      connection?.clientSecret?.stateDetails
    );
    const permissionsStatusMessage: React.ReactNode = getPermissionMessage(
      details.scopes
    );

    //
    return (
      <Typography variant="body1">
        Permissions: {permissionsStatusMessage}
      </Typography>
    );
  }
);

// -----------------------------------------------------------------------------

function processStateDetails(stateDetails: string | undefined): StateDetails {
  try {
    return stateDetails !== undefined
      ? (JSON.parse(stateDetails) as StateDetails)
      : {
          scopes: [],
          customer: { id: "-1", domain: "" },
          error: "No connection state details",
        };
  } catch (error: any) {
    return {
      scopes: [],
      customer: { id: "-1", domain: "" },
      error: error.message,
    };
  }
}

function getPermissionMessage(scopes: string[]): React.ReactNode {
  const permissionsEval = evaluateGoogleScopes(scopes);

  switch (permissionsEval.summary) {
    case GoogleScopesEvaluationSummary.Insufficient:
      return "Insufficient permissions";

    case GoogleScopesEvaluationSummary.ReadOnly:
      return "Read-only";

    case GoogleScopesEvaluationSummary.ReadWrite:
      return "Read-write";

    case GoogleScopesEvaluationSummary.ReadOnlyPartial:
      return "Read-only (partial)";

    case GoogleScopesEvaluationSummary.ReadWritePartial:
      return "Read-write (partial)";
  }
}

// -----------------------------------------------------------------------------

interface StateDetails {
  scopes: string[];
  customer: {
    id: string;
    domain: string;
  };

  error?: string;
}
