import { memo, useEffect } from "react";

// -----------------------------------------------------------------------------

export const RemoveLoader = memo(() => {
  useEffect(() => window?.tkRemoveLoader?.(), []);

  //
  return null;
});
