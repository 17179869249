import { ReturnIf } from "babel-plugin-transform-functional-return";

// -----------------------------------------------------------------------------

const Promisers: Record<string, Promise<any> | undefined> = {};

// -----------------------------------------------------------------------------

/**
 * Caches an async action (e.g., loading billing) such that multiple calls to
 * that action are prevented. If the user clicks on the billing page which calls
 * the billing API, then quickly moves to a different page, and then back before
 * the API finished. Only one call would be made to the API. This is preferably
 * to cancelling the HTTP fetch as the billing data is used in many places.
 *
 * @param name - The name of the promised action
 * @param action - The promised action
 * @returns - The awaited value of the promise action, clearing the promised
 * action thereafter
 */
export async function promiser(name: string, action: () => Promise<any>) {
  const promise = Promisers[name];
  ReturnIf(promise !== undefined, await promise);

  //
  try {
    Promisers[name] = action();
    const returnValue = await Promisers[name];
    Promisers[name] = undefined;

    //
    return returnValue;
  } catch (error) {
    Promisers[name] = undefined;

    throw error;
  }
}
