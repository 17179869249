import { memo } from "react";

import { type ComputedDatum } from "@nivo/bar";
import { type Serie } from "@nivo/line";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import {
  FindingSeverityLabel,
  FindingSeverity,
  SeverityToColor,
} from "data/finding";

import { generateTicks } from "utility/charting_utils";

import { ResponseBar } from "Component/ResponseBar";

// -----------------------------------------------------------------------------

export const BarChart = memo(({ title, data = [], loading = false }: Props) => {
  const hasAtLeastOneDatapoint = data.length > 0;

  //
  return (
    <Card
      sx={{
        width: "100%",
        minWidth: "400px",
        position: "relative",
        height: "124px",
      }}
    >
      {/* CardContent needs to be positioned absolutely so that the legends can overlap with the card title */}
      <CardContent
        sx={{
          position: "absolute",
          padding: "8px 16px",
          width: "100%",
          zIndex: 1, // needed so expand icon is clickable, since legends overlap with card header
        }}
      >
        <Typography variant="overline">{title}</Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5" fontWeight="bold">
            {loading ? (
              <>
                &nbsp;
                <CircularProgress size={20} />
              </>
            ) : !hasAtLeastOneDatapoint ? (
              "None"
            ) : null}
          </Typography>
        </Box>
      </CardContent>
      {hasAtLeastOneDatapoint ? (
        <Box sx={{ height: 124 }}>
          <ResponseBar
            data={data}
            isInteractive={false}
            keys={[
              FindingSeverityLabel[FindingSeverity.Critical],
              FindingSeverityLabel[FindingSeverity.High],
              FindingSeverityLabel[FindingSeverity.Medium],
            ]}
            indexBy="create_time"
            colors={Object.values(SeverityToColor).reverse()}
            margin={{ top: 50, right: 40, bottom: 30, left: 60 }}
            padding={0.5}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            borderColor={{
              from: "color",
              modifiers: [["darker", 1.6]],
            }}
            enableLabel={false}
            enableGridY={false}
            axisLeft={{
              tickValues: 3,
            }}
            axisBottom={{
              tickValues: generateTicks(data),
            }}
            legends={[
              {
                dataFrom: "keys",
                anchor: "top-right",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: -25,
                itemsSpacing: 8,
                itemWidth: 65,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 10,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            role="application"
            ariaLabel="Rate of findings bar chart"
            barAriaLabel={function (data: ComputedDatum<Serie>) {
              return `${data.id}: ${data.formattedValue} for date: ${data.indexValue}`;
            }}
          />
        </Box>
      ) : null}
    </Card>
  );
});

// -----------------------------------------------------------------------------

interface Props {
  title: JSX.Element | string;
  data: Serie[];
  loading?: boolean;
}
