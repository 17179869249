import { memo, useState } from "react";

import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { type TableRule } from "./data";
import { DeleteIgnoreRuleDialog } from "./DeleteRuleDialog";
import { EditIgnoreRuleDialog } from "./EditRuleDialog";

// -----------------------------------------------------------------------------

export const RuleTableActions = memo(({ row }: Props) => {
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [anchorElUser, setAnchorElUser] = useState(null);
  const openMenu = (event: any) => setAnchorElUser(event.currentTarget);
  const closeMenu = () => setAnchorElUser(null as any);

  return (
    <>
      <IconButton size="large" color="inherit" onClick={openMenu}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchorElUser}
        keepMounted
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={Boolean(anchorElUser)}
        onClose={closeMenu}
      >
        <MenuItem
          onClick={() => {
            setOpenEditDialog(true);
            closeMenu();
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenDeleteDialog(true);
            closeMenu();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
      {openEditDialog && (
        <EditIgnoreRuleDialog
          rule={row}
          onClose={() => setOpenEditDialog(false)}
        />
      )}
      {openDeleteDialog && (
        <DeleteIgnoreRuleDialog
          rule={row}
          onClose={() => setOpenDeleteDialog(false)}
        />
      )}
    </>
  );
});

// -----------------------------------------------------------------------------

interface Props {
  row: TableRule;
}
