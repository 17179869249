import { dayjs } from "utility/dayjs";

const AccessTokenName =
  process.env.REACT_ENV_ACCESS_TOKEN_NAME ?? "JWT_AccessToken";
const RefreshTokenName =
  process.env.REACT_ENV_REFRESH_TOKEN_NAME ?? "JWT_RefreshToken";
const CSRFTokenName = process.env.REACT_ENV_CSRF_TOKEN_NAME ?? "csrftoken";

const DefaultExpiration = "Thu, 01 Jan 1970 00:00:00 GMT";

// -----------------------------------------------------------------------------

export function getAccessToken(): string {
  return getCookie(AccessTokenName);
}

export function getRefreshToken(): string {
  return getCookie(RefreshTokenName);
}

export function getCSRFToken(): string {
  return getCookie(CSRFTokenName);
}

export function getRememberEmail(): string {
  return getCookie("remember_email");
}

export function clearAccessTokens() {
  setCookie(AccessTokenName, "", DefaultExpiration, window.location.hostname);
  setCookie(AccessTokenName, "", DefaultExpiration, ".threatkey.com");
  setCookie(RefreshTokenName, "", DefaultExpiration, window.location.hostname);
  setCookie(RefreshTokenName, "", DefaultExpiration, ".threatkey.com");
}

export function setAccessTokens(token: string, refreshToken: string): void {
  const format = "ddd, DD MMM YYYY HH:mm:ss [GMT]";

  setCookie(AccessTokenName, token, dayjs().add(1, "months").format(format));
  setCookie(
    RefreshTokenName,
    refreshToken,
    dayjs().add(1, "months").format(format)
  );
}

export function setRememberEmail(email: string): void {
  const format = "ddd, DD MMM YYYY HH:mm:ss [GMT]";

  setCookie("remember_email", email, dayjs().add(1, "months").format(format));
}

// -----------------------------------------------------------------------------

const CookieSeparator = /; */g;

function getCookie(name: string): string {
  const cookieSearch = `${name}=`;
  function outUndesiredTokens(cookie: string): boolean {
    return cookie.trim().startsWith(cookieSearch);
  }

  //
  const cookie =
    (document.cookie ?? "")
      .split(CookieSeparator)
      .filter(outUndesiredTokens)
      .pop() ?? "";

  //
  return decodeURIComponent(cookie.trim().substring(cookieSearch.length));
}

function setCookie(
  name: string,
  value: string = "",
  expires: string = "",
  domain: string = window.location.hostname,
  path: string = "/"
) {
  document.cookie = `${name}=${value}; Domain=${domain}; Expires=${expires}; Path=${path}; Secure`;
}
