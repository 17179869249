import { type MouseEventHandler, memo } from "react";

import { Box } from "@mui/system";
import styled from "styled-components";

import MuiCheckbox from "@mui/material/Checkbox";

import {
  AcknowledgableStates,
  IgnorableStates,
  UnignorableStates,
} from "data/finding";
import { ViewMode } from "data/view_mode";

import { FindingsContext, type IFindingContext } from "../lib/context";

import { ActionButton } from "./ActionButton";
import { ActionSplitButton } from "./SplitActionButton";

// -----------------------------------------------------------------------------

export const FindingsPageActionPane = memo(
  ({ onSelect, onAction, selected, maxSelected }: Props) => {
    return (
      <FindingsContext.Consumer>
        {(context) => {
          return (
            <Container>
              <Content>
                <Checkbox
                  checked={selected}
                  indeterminate={selected && !maxSelected}
                  onClick={onSelect as MouseEventHandler<HTMLButtonElement>}
                />
                <Buttons>
                  <Actions {...context} onAction={onAction} />
                </Buttons>
              </Content>
            </Container>
          );
        }}
      </FindingsContext.Consumer>
    );
  }
);

// -----------------------------------------------------------------------------

function Actions({
  viewMode,
  selectedFindings,
  onAction,
}: IFindingContext & {
  onAction: (
    action: "acknowledge" | "ignore" | "unignore" | "edit-severity"
  ) => void;
}) {
  const acknowledgeButton = selectedFindings.some(
    (selectedFindings) =>
      true || AcknowledgableStates.includes(selectedFindings.state)
  ) ? (
    <ActionButton
      label="Acknowledge"
      variant="contained"
      color="primary"
      onClick={() => onAction("acknowledge")}
    />
  ) : null;
  const ignoreOption = selectedFindings.some((selectedFindings) =>
    IgnorableStates.includes(selectedFindings.state)
  )
    ? ["ignore", "Ignore", () => onAction("ignore")]
    : null;
  const unignoreOption = selectedFindings.some((selectedFindings) =>
    UnignorableStates.includes(selectedFindings.state)
  )
    ? ["unignore", "Unignore", () => onAction("unignore")]
    : null;

  //
  switch (viewMode) {
    case ViewMode.Mobile: {
      const options = [
        ignoreOption as any,
        unignoreOption as any,
        ["edit-severity", "Edit Severity", () => onAction("edit-severity")],
      ].filter(outNull);

      //
      let splitActions = null;
      switch (true) {
        case options.length <= 0:
          splitActions = null;
          break;

        case options.length === 1:
          splitActions = (
            <ActionButton
              label={options[0][1]}
              variant="outlined"
              color="primary"
              onClick={options[0][2]}
            />
          );
          break;

        default:
          splitActions = (
            <ActionSplitButton key="all-options" options={options} />
          );
          break;
      }

      //
      return (
        <>
          {acknowledgeButton}
          {splitActions}
        </>
      );
    }

    case ViewMode.Desktop:
    case ViewMode.Tablet:
    default:
      return (
        <>
          {acknowledgeButton}
          {ignoreOption !== null ? (
            <ActionButton
              variant="outlined"
              label="Ignore"
              onClick={() => onAction("ignore")}
            />
          ) : null}
          {unignoreOption !== null ? (
            <ActionButton
              variant="outlined"
              label="Unignore"
              onClick={() => onAction("unignore")}
            />
          ) : null}
          <ActionButton
            variant="outlined"
            label="Edit Severity"
            onClick={() => onAction("edit-severity")}
          />
        </>
      );
  }
}

function outNull(o: unknown): boolean {
  return o !== null;
}

// -----------------------------------------------------------------------------

const Container = styled(Box)``;

const Content = styled(Box)`
  display: grid;
  gap: 8px;
  grid: auto / auto 1fr auto;
  max-width: 1142px;
  min-height: 0vw;
  padding: 8px calc(clamp(8px, 0.5rem + (1vw - 3.75px) * 1.9394, 24px) + 2px);
`;

const Buttons = styled(Box)`
  display: grid;
  gap: 8px;
  grid: auto / repeat(4, minmax(0px, min-content));

  @media screen and (max-width: 450px) {
    display: grid;
    grid: auto / repeat(2, minmax(0px, min-content));
  }
`;

const Checkbox = styled(MuiCheckbox)`
  margin-left: -2px;
  padding: 0;
`;

// -----------------------------------------------------------------------------

interface Props {
  selected: boolean;
  maxSelected: boolean;
  onSelect: Function;
  onAction: (
    action: "acknowledge" | "ignore" | "unignore" | "edit-severity"
  ) => void;
}
