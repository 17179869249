import {
  GoogleScope,
  GoogleReadOnlyScopes,
  GoogleReadWriteScopes,
  type GoogleScopesEvaluation,
  GoogleScopesEvaluationSummary,
} from "data/google";

import { GoogleWorkspaceConfig } from "external/source/GoogleWorkspace/config";

// -----------------------------------------------------------------------------

export function getGoogleOAuthUrl(orgUUID: string, readOnly: boolean): string {
  const redirectURI = `${window.location.origin}/environment/sources/google/oauth2`;
  const params = new URLSearchParams({
    access_type: "offline",
    approval_prompt: "force",
    client_id: GoogleWorkspaceConfig.clientID ?? "",
    redirect_uri: redirectURI,
    response_type: "code",
    scope: (readOnly ? GoogleReadOnlyScopes : GoogleReadWriteScopes).join(" "),
    state: orgUUID,
  });

  //
  return `https://accounts.google.com/o/oauth2/auth?${params.toString()}`;
}

export function evaluateGoogleScopes(scopes: string[]): GoogleScopesEvaluation {
  const scopeSet = new Set(scopes);
  const roBreakdown: { [scope: string]: boolean } = {};
  GoogleReadOnlyScopes.forEach((scope) => {
    roBreakdown[scope] = scopeSet.has(scope);
  });
  const rwBreakdown: { [scope: string]: boolean } = {};
  GoogleReadWriteScopes.forEach((scope) => {
    rwBreakdown[scope] = scopeSet.has(scope);
  });

  // check if we have every scope in a category
  let summary: GoogleScopesEvaluationSummary;
  if (GoogleReadWriteScopes.every((s) => rwBreakdown[s])) {
    summary = GoogleScopesEvaluationSummary.ReadWrite;
  } else if (GoogleReadOnlyScopes.every((s) => roBreakdown[s])) {
    summary = GoogleScopesEvaluationSummary.ReadOnly;
  } else {
    // check if there's partial membership - sans AdminDirectoryCustomerReadonly - in the RO or RW scope set
    scopeSet.delete(GoogleScope.AdminDirectoryCustomerReadonly);
    if (GoogleReadWriteScopes.some((s) => rwBreakdown[s])) {
      summary = GoogleScopesEvaluationSummary.ReadWritePartial;
    } else if (GoogleReadOnlyScopes.some((s) => roBreakdown[s])) {
      summary = GoogleScopesEvaluationSummary.ReadOnlyPartial;
    } else {
      summary = GoogleScopesEvaluationSummary.Insufficient;
    }
  }

  const breakdown = { ...roBreakdown, ...rwBreakdown };

  //
  return { summary, breakdown };
}
