import { memo, type MouseEventHandler } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";

// -----------------------------------------------------------------------------

export const SettingsGeneralDCVSectionEnterValidationCode = memo(
  ({
    isLoading,
    isConfirmed,
    domain,
    email,
    code,
    setCode,
    onConfirm,
    onResend,
    onStartOver,
  }: Props) => {
    return (
      <Stack direction="column" spacing={2}>
        <TextField
          label="Validation code from verification email"
          variant="outlined"
          InputProps={{
            startAdornment: isConfirmed ? (
              <InputAdornment position="start">
                <CheckCircleOutlineOutlinedIcon sx={ConfirmedIconSX} />
              </InputAdornment>
            ) : null,
          }}
          disabled={isLoading || isConfirmed}
          value={isConfirmed ? `Domain is validated (${domain})` : code || ""}
          onChange={(event) => setCode(event.target.value)}
          sx={TextFieldSX}
        />

        <Stack direction="row" spacing={2}>
          {isConfirmed ? (
            <Button
              variant="outlined"
              onClick={onStartOver as MouseEventHandler<HTMLButtonElement>}
            >
              Start over
            </Button>
          ) : (
            <>
              <LoadingButton
                variant="contained"
                loading={isLoading}
                disabled={isLoading || isConfirmed}
                onClick={onConfirm as MouseEventHandler<HTMLButtonElement>}
              >
                Confirm Code
              </LoadingButton>
              {email ? (
                <Button
                  variant="outlined"
                  disabled={isLoading}
                  onClick={() => onResend(email)}
                >
                  Resend Code
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  onClick={onStartOver as MouseEventHandler<HTMLButtonElement>}
                >
                  Start over
                </Button>
              )}
            </>
          )}
        </Stack>
      </Stack>
    );
  }
);

// -----------------------------------------------------------------------------

interface Props {
  isLoading: boolean;
  isConfirmed: boolean;
  domain: string;
  email: string;
  code: string;
  setCode: (code: string) => void;
  onConfirm: () => void;
  onResend: (arg0: string) => void;
  onStartOver: () => void;
}

// -----------------------------------------------------------------------------

const TextFieldSX = { maxWidth: "512px" };

const ConfirmedIconSX = { fill: "green" };
