import { memo, useEffect, useState } from "react";

import { ReturnIf } from "babel-plugin-transform-functional-return";

import Box from "@mui/material/Box";

import { useFlairStore } from "stores/FlairStore";

import { position } from "utility/style";

import { Celebration } from "../../Celebration";

// -----------------------------------------------------------------------------

export const CelebrationOverlay = memo(() => {
  const [flairStore, flairActions] = useFlairStore();

  const [active, setActive] = useState(true);

  //
  useEffect(() => {
    ReturnIf(!flairStore.celebration);

    //
    setActive(true);
    const activeTimerId = setTimeout(setActive, 8000, false);
    const celebrationTimerId = setTimeout(() => {
      (async () => {
        flairActions.stopCelebration();
      })();
    }, 8750);

    //
    return () => {
      setActive(false);
      clearTimeout(activeTimerId);
      clearTimeout(celebrationTimerId);
      flairActions.stopCelebration();
    };
  }, [flairStore.celebration, flairActions]);

  //
  return flairStore.celebration ? (
    <Box
      sx={{
        ...position("fixed", 0, 0, 0, 0),
        opacity: active ? 1 : 0,
        pointerEvents: "none",
        transition: "opacity 500ms linear",
        zIndex: 2147483647,
      }}
    >
      <Celebration />
    </Box>
  ) : null;
});
