import { memo, useState, type ReactNode } from "react";

import { ReturnIf } from "babel-plugin-transform-functional-return";
import useSWR from "swr";

import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useConnectionStore } from "stores/ConnectionStore";
import { useFlairStore } from "stores/FlairStore";
import { swrFetcher, SecretState } from "stores/lib";

import { captureSentryException } from "utility/capture_sentry_exception";
import { notifyError, notifySuccess } from "utility/notify";

import { usePageTitle } from "effect/use_page_title";

import { Page } from "Component/Page";
import { PageContent } from "Component/PageContent";
import { PageHeader } from "Component/PageHeader";
import { PageHeaderBackButton } from "Component/PageHeaderBackButton";
import { PageTitle } from "Component/PageTitle";
import { ProvisioningErrors } from "Component/ProvisioningErrors";
import { ServiceConnectionInstructionPhase } from "Component/ServiceConnectionInstructionPhase";
import { ServiceConnectionPhases } from "Component/ServiceConnectionPhases";

import { GoogleCloudPlatformConnectPageError as Error } from "./Error";
import { GoogleCloudPlatformConnectCompletedPhase as CompletedPhase } from "./Phase/Completed";
import { GoogleCloudPlatformConnectPageEnterDetailsPhase as EnterDetailsPhase } from "./Phase/EnterDetails";
import { GoogleCloudPlatformConnectPageReceivedPhase as ReceivedPhase } from "./Phase/Received";

// -----------------------------------------------------------------------------

enum Phase {
  EnterDetails,
  EnterError,
  SecretExists,
  DeleteError,
  Provisioning,
  Error,
}

enum ProvisionPhase {
  Received,
  Completed,
}

const ProvisionURL = "api/organization/connections/gcp/provision/check";

// -----------------------------------------------------------------------------

export const GoogleCloudPlatformConnectPage = memo(() => {
  usePageTitle("Environment → Sources → Google Cloud Platform → Connect");

  //
  const [connectionStore, connectionActions] = useConnectionStore();
  const [, flairActions] = useFlairStore();

  const [errorMessage, setErrorMessage] = useState<ReactNode | undefined>(
    undefined
  );
  const [phase, setPhase] = useState(Phase.EnterDetails);
  const [deleteSecretLoading, setDeleteSecretLoading] = useState(false);

  const [uuid, setUuid] = useState("");
  const [provisionPhase, setProvisionPhase] = useState(ProvisionPhase.Received);
  const [refreshInterval, setRefreshInterval] = useState(2000);
  const [retryLoading, setRetryLoading] = useState(false);

  //
  useSWR<ProvisionStateResponse>(
    ProvisionURL,
    phase === Phase.Provisioning && provisionPhase !== ProvisionPhase.Completed
      ? async (url) =>
          await swrFetcher(url, {
            method: "POST",
            body: JSON.stringify({
              connection_uuid: uuid,
            }),
          })
      : null,
    {
      refreshInterval,
      onError: (error) => {
        captureSentryException(
          error,
          "GCP connect error provisioning/polling for provision status"
        );
        setRefreshInterval(0);
        setPhase(Phase.Error);

        //
        notifyError("There was an error connecting to Google Cloud Platform");
        setErrorMessage(<ProvisioningErrors data={error} />);
      },

      onSuccess: (data) => {
        const secretState = data?.state;
        if (secretState !== undefined) {
          switch (secretState) {
            case SecretState.RECEIVED:
              setProvisionPhase(ProvisionPhase.Received);
              break;

            case SecretState.CORRECT:
              // celebration on first connection
              if (connectionStore.connections.size < 1) {
                flairActions.startCelebration();
              }

              setRefreshInterval(0);
              setProvisionPhase(ProvisionPhase.Completed);
              notifySuccess("Successfully connected to Google Cloud Platform");
              break;

            case SecretState.INCORRECT:
            case SecretState.ERROR:
              setRefreshInterval(0);
              setPhase(Phase.Error);

              notifyError(
                "There was an error connecting to Google Cloud Platform"
              );
              setErrorMessage(<ProvisioningErrors data={data} />);
              break;
          }
        }
      },
    }
  );

  //
  const onDetailSuccess = (data: any) => {
    setPhase(Phase.Provisioning);
    setUuid(data.connection_uuid);
    connectionActions.loadConnections({ reload: true });
  };

  const onDetailError = (error: any) => {
    ReturnIf(
      error &&
        error.message ===
          "secret of this kind already exists in the organization",
      setPhase(Phase.SecretExists)
    );

    setErrorMessage(
      <Stack spacing={1} direction="column">
        {error?.message || "There was an error"}
      </Stack>
    );
    setPhase(Phase.EnterError);
  };

  const deleteSecret = () => {
    setDeleteSecretLoading(true);
    (async () => {
      connectionActions.deleteConnection({
        uuid,
        onSuccess: onDeleteSuccess,
        onError: onDeleteError,
      });
    })();
  };

  const onDeleteSuccess = () => {
    setDeleteSecretLoading(false);
    notifySuccess("Successfully removed old Google Cloud Platform connection");
    setPhase(Phase.EnterDetails);
  };

  const onDeleteError = () => {
    setDeleteSecretLoading(false);
    setPhase(Phase.DeleteError);
  };

  const handleRetry = async () => {
    setRetryLoading(true);
    await connectionActions.deleteConnection({
      uuid,
      onSuccess: onRetrySuccess,
      onError: onRetryError,
    });
  };

  const onRetrySuccess = () => {
    setRetryLoading(false);
    setErrorMessage(undefined);
    setProvisionPhase(ProvisionPhase.Received);
    setPhase(Phase.EnterDetails);
  };

  const onRetryError = () => {
    setRetryLoading(false);
  };

  const handleEnterRetry = () => {
    setErrorMessage(undefined);
    setPhase(Phase.EnterDetails);
  };

  //
  return (
    <Page>
      <PageHeader>
        <PageHeaderBackButton to="/environment/sources">
          Back to Sources
        </PageHeaderBackButton>
        <PageTitle>Google Cloud Platform Connection</PageTitle>
      </PageHeader>

      <PageContent>
        <ServiceConnectionPhases>
          <ServiceConnectionInstructionPhase>
            <Typography>
              Connect your ThreatKey account with Google Cloud Platform in a
              just a few easy steps. By connecting Google Cloud Platform to
              ThreatKey, you can get a comprehensive view of your Google Cloud
              environment&apos;s security posture, identify and prioritize
              risks, and remediate them quickly and easily.
            </Typography>
            <Typography fontWeight="bold">
              In order for the ThreatKey for Google Cloud Platform connection to
              work, you will need to do the following:
            </Typography>
            <ol>
              <li>
                Grant the ThreatKey Service Account access to your project by
                navigating{" "}
                <Link
                  href="https://console.cloud.google.com/iam-admin/iam"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </Link>{" "}
                and clicking &quot;Grant Access&quot;. The principal should be:
                <ul>
                  <li>
                    <code>
                      threatkey@gcp-scanner-prod.iam.gserviceaccount.com
                    </code>
                  </li>
                </ul>
              </li>
              <li>
                Grant the following roles to the above service account
                <ul>
                  <li>
                    Service Usage Admin (
                    <code>roles/serviceusage.serviceUsageAdmin</code>)
                  </li>
                  <li>
                    Viewer (<code>roles/viewer</code>)
                  </li>
                  <li>
                    Security Reviewer (<code>roles/iam.securityReviewer</code>)
                  </li>
                  <li>
                    StackDriver Accounts Viewer (
                    <code>roles/stackdriver.accounts.viewer</code>)
                  </li>
                </ul>
              </li>
            </ol>
            <Typography variant="body1">
              Refer to{" "}
              <Link
                href="https://docs.threatkey.com/docs/sources/google-cloud-platform/"
                target="_blank"
                rel="noopener noreferrer"
              >
                our technical documentation
              </Link>{" "}
              for more details.
            </Typography>
          </ServiceConnectionInstructionPhase>

          <EnterDetailsPhase
            disabled={phase !== Phase.EnterDetails}
            onSuccess={onDetailSuccess}
            onError={onDetailError}
          />

          {phase === Phase.SecretExists ? (
            <Alert severity="error">
              A connection to Google Cloud already exists in the organization.
              You can remove the old connection and try again.
              <LoadingButton
                variant="contained"
                color="error"
                sx={{ mt: 2 }}
                onClick={deleteSecret}
                loading={deleteSecretLoading}
              >
                Remove old Google Cloud Platform connection
              </LoadingButton>
            </Alert>
          ) : null}

          {phase === Phase.DeleteError ? (
            <Alert severity="error">
              There was an error deleting your old Google Cloud Platform
              connection. Please{" "}
              <Link href="/environment/sources">return to your sources</Link>.
            </Alert>
          ) : null}

          <Error
            show={phase === Phase.EnterError}
            loading={false}
            onTryAgain={handleEnterRetry}
          >
            {errorMessage}
          </Error>

          {phase >= Phase.Provisioning ? (
            <>
              <ReceivedPhase
                disabled={provisionPhase !== ProvisionPhase.Received}
                progressIcon={
                  provisionPhase === ProvisionPhase.Received &&
                  phase !== Phase.Error
                }
                successIcon={
                  provisionPhase > ProvisionPhase.Received &&
                  phase !== Phase.Error
                }
                errorIcon={
                  provisionPhase === ProvisionPhase.Received &&
                  phase === Phase.Error
                }
              />

              <Error
                show={
                  provisionPhase === ProvisionPhase.Received &&
                  phase === Phase.Error
                }
                loading={retryLoading}
                onTryAgain={handleRetry}
              >
                {errorMessage}
              </Error>

              <CompletedPhase
                disabled={provisionPhase !== ProvisionPhase.Completed}
                successIcon={provisionPhase === ProvisionPhase.Completed}
                onClickFn={() => {
                  connectionActions.loadConnections({ reload: true });
                }}
              />
            </>
          ) : null}
        </ServiceConnectionPhases>
      </PageContent>
    </Page>
  );
});

// -----------------------------------------------------------------------------

interface ProvisionStateResponse {
  state: SecretState;
  secret_state?: SecretState;
  secret_details?: string;
  details: any;
}
