import { memo, useState, type ReactNode } from "react";
import { Link } from "react-router-dom";

import useSWR from "swr";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Breadcrumb from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { type KubernetesConnectionCreateState } from "data/kubernetes";
import { type FOTS } from "data/typescript";

import { useConnectionStore } from "stores/ConnectionStore";
import { useEncryptionStore } from "stores/EncryptionStore";
import { useFlairStore } from "stores/FlairStore";
import { ProvisionState, type SecretState } from "stores/lib";
import { useOrganizationStore } from "stores/OrganizationStore";

import { captureSentryException } from "utility/capture_sentry_exception";
import { authenticatedPostFetch } from "utility/fetch/authenticated";
import { notifyError, notifySuccess } from "utility/notify";

import { usePageTitle } from "effect/use_page_title";

import { PageContent } from "Component/PageContent";
import { PageHeader } from "Component/PageHeader";
import { PageTitle } from "Component/PageTitle";
import { ProvisioningErrors } from "Component/ProvisioningErrors";
import { ServiceConnectionInstructionPhase } from "Component/ServiceConnectionInstructionPhase";

import Error from "./Error";
import { GKEKubernetesConnectPageCompletedPhase as CompletedPhase } from "./Phase/Completed";
import { GKEKubernetesConnectPageConfiguringKubernetes as ConfiguringKubernetes } from "./Phase/ConfiguringGKE";

// -----------------------------------------------------------------------------

enum Phase {
  EnterDetails,
  Provisioning,
  Done,
  Error,
}

// -----------------------------------------------------------------------------

const ProvisionURL = "api/organization/connections/kubernetes/provision/check";

// -----------------------------------------------------------------------------

export const GKEKubernetesConnectSetupPage = memo(() => {
  usePageTitle("Environment → Sources → Kubernetes → Connect");

  //
  const [organizationStore] = useOrganizationStore();
  const [connectionStore, connectionActions] = useConnectionStore();
  const [, encryptionActions] = useEncryptionStore();
  const [, flairActions] = useFlairStore();

  const [phase, setPhase] = useState(Phase.EnterDetails);
  const [refreshInterval, setRefreshInterval] = useState(0);
  const [errorMessage, setErrorMessage] = useState<ReactNode | undefined>(
    undefined
  );

  const [kubernetesConnectionID, setKubernetesConnectionID] = useState("");

  //
  useSWR<ProvisionStateResponse>(
    ProvisionURL,
    phase === Phase.Provisioning
      ? async (url) =>
          await authenticatedPostFetch(url, {
            org_url: organizationStore.uuid,
            connection_uuid: kubernetesConnectionID,
          })
      : null,
    {
      refreshInterval,
      onError: (error) => {
        captureSentryException(error, "Failed to validate GKE provisioning");
        setRefreshInterval(0);
        setPhase(Phase.Error);
        setErrorMessage(<ProvisioningErrors data={error} />);
        notifyError("There was an error connecting to GKE");
      },

      onSuccess: (data) => {
        const provisionState = data?.state;
        switch (provisionState) {
          case ProvisionState.QUEUED:
            setErrorMessage(undefined);
            break;

          case ProvisionState.RUNNING:
            setErrorMessage(undefined);
            break;

          case ProvisionState.COMPLETED:
            // celebration on first connection
            if (connectionStore.connections.size < 1) {
              flairActions.startCelebration();
            }

            setRefreshInterval(0);
            setPhase(Phase.Done);
            setErrorMessage(undefined);
            notifySuccess("Successfully connected to GKE");
            break;

          case ProvisionState.ERROR:
          case undefined:
            setRefreshInterval(0);
            setPhase(Phase.Error);
            setErrorMessage(<ProvisioningErrors data={data} />);
            notifyError("There was an error connecting to GKE");
            break;
        }
      },
    }
  );

  async function connectToGKEKubernetes(data: KubernetesConnectionCreateState) {
    function onSuccess(data: FOTS) {
      setKubernetesConnectionID(data.connection_uuid);
      setRefreshInterval(2000);
    }

    function onError(error: Error) {
      captureSentryException(error, "Failed to create GKE connection");
      setErrorMessage(error?.message ?? "There was an error");
      setPhase(Phase.Error);
      notifyError("There was an error connecting to GKE");
    }

    //
    setPhase(Phase.Provisioning);
    try {
      await connectionActions.createGKEKubernetesConnection({
        ...(await encryptionActions.getPublicKey()),
        data,
        onSuccess,
        onError,
      });
    } catch (error) {
      onError(error as Error);
    }
  }

  function retryConnection() {
    setPhase(Phase.EnterDetails);
    setRefreshInterval(0);
    setErrorMessage(undefined);
  }

  function postDone() {
    connectionActions.loadConnections({ reload: true });
  }

  //
  return (
    <>
      <PageHeader>
        <Breadcrumbs>
          <Breadcrumb
            underline="none"
            color="inherit"
            component={Link}
            to="/environment/sources"
          >
            Environment
          </Breadcrumb>
          <Breadcrumb
            underline="none"
            color="inherit"
            component={Link}
            to="/environment/sources"
          >
            Sources
          </Breadcrumb>
          <span />
        </Breadcrumbs>
        <PageTitle>GKE Connection</PageTitle>
      </PageHeader>

      <PageContent>
        <Stack spacing={2} direction="column">
          <Error show={phase === Phase.Error} onTryAgain={retryConnection}>
            {errorMessage}
          </Error>

          <ServiceConnectionInstructionPhase>
            <Stack spacing={2}>
              <Typography variant="body1" fontWeight="bold">
                How the ThreatKey for GKE connection works:
              </Typography>
              <Typography variant="body1">
                Connect your ThreatKey account with GKE in just a few easy
                steps:
                <ol>
                  <li>
                    Create a service account in the project that contains the
                    cluster
                  </li>
                  <li>
                    Grant the service account the following permissions:
                    <ul>
                      <li>Kubernetes Engine Developer</li>
                    </ul>
                  </li>
                  <li>
                    Generate a service account JSON file for the service account
                  </li>
                </ol>
                Once connected, ThreatKey analyzes your GKE cluster for
                potential misconfigurations and insecure settings. ThreatKey
                will generate findings with recommendations to best protect your
                data.
              </Typography>
            </Stack>
          </ServiceConnectionInstructionPhase>

          {phase !== Phase.Done && (
            <ConfiguringKubernetes
              disabled={phase !== Phase.EnterDetails}
              loading={phase === Phase.Provisioning}
              onNext={connectToGKEKubernetes}
            />
          )}

          {phase === Phase.Done && (
            <CompletedPhase successIcon disabled={false} onClickFn={postDone} />
          )}
        </Stack>
      </PageContent>
    </>
  );
});

// -----------------------------------------------------------------------------

interface ProvisionStateResponse {
  state: ProvisionState;
  secret_state?: SecretState;
  secret_details?: string;
  details?: any;
}
