import { GoogleCloudPlatformConnectPage } from "./Connect";
import { redirectToGCPConnectPage } from "./connect_handler";
import { GoogleCloudPlatformCoverImage } from "./CoverImage";

// -----------------------------------------------------------------------------

export { GoogleCloudPlatformConnectPage };

export const GoogleCloudPlatformAppConfig = {
  name: "gcp",
  alternativeNames: ["gcp_resource_id"],
  label: "Google Cloud Platform",
  coverImage: GoogleCloudPlatformCoverImage,
  onConnect: redirectToGCPConnectPage,
  searchTerms: ["gcp", "google", "cloud"],
};
