import { memo, type PropsWithChildren } from "react";

import styled from "styled-components";

import DialogContent from "@mui/material/DialogContent";

// -----------------------------------------------------------------------------

export const ModalFullHeightContent = memo(
  ({ children }: PropsWithChildren) => {
    return <Container>{children}</Container>;
  }
);

// -----------------------------------------------------------------------------

const Container = styled(DialogContent)`
  border: 1px rgba(31, 107, 188, 0.08) solid;
  border-width: 1px 0;
  display: grid;
  grid-template-rows: 1fr;
  height: 100%;
  min-height: 110px;
  padding: 0;
`;
