import { ReturnIf } from "babel-plugin-transform-functional-return";

import { authenticatedFetch } from "utility/fetch/authenticated";
import { type Method, API_URL } from "utility/fetch/lib";

// -----------------------------------------------------------------------------

export const AppReleaseVersion =
  "app-" + (process.env.REACT_APP_VERSION_RELEASE ?? window.location.hostname);

export enum SubmitState {
  STARTED,
  STARTED_AGAIN,
  SUCCESS,
  ERROR,
}

export enum ProvisionState {
  ERROR = -1,
  CREATING = 0,
  QUEUED = 1,
  RUNNING = 2,
  COMPLETED = 3,
}

export enum ActionState {
  INITIAL = 0,
  IN_PROGRESS = 1,
  COMPLETED = 2,
  ERROR = 3,
}

export enum SecretState {
  ERROR = -1,
  RECEIVED = 0,
  PENDING_EXTERNAL = 1,
  PENDING_INTERNAL = 2,
  CORRECT = 3,
  INCORRECT = 4,
}

// -----------------------------------------------------------------------------

export const LoginMethod: { [prop: string]: string } = {
  ADFSSAML: "AD FS SAML",
  Auth0SAML: "Auth0 SAML",
  AzureSAML: "Azure AD SAML",
  CASSAML: "CAS SAML",
  CloudflareSAML: "Cloudflare SAML",
  CyberArkSAML: "CyberArk SAML",
  ClassLinkSAML: "ClassLink SAML",
  DuoSAML: "Duo SAML",
  GenericOIDC: "Generic OIDC",
  GenericSAML: "Generic SAML",
  GoogleOAuth: "Google OAuth",
  GoogleSAML: "Google SAML",
  JumpCloudSAML: "JumpCloud SAML",
  KeycloakSAML: "Keycloak SAML",
  LastPassSAML: "LastPass SAML",
  MicrosoftOAuth: "Microsoft OAuth",
  MiniOrangeSAML: "miniOrange SAML",
  NetIqSAML: "NetIQ SAML",
  OktaSAML: "Okta SAML",
  OneLoginSAML: "OneLogin SAML",
  OracleSAML: "Oracle SAML",
  PingFederateSAML: "PingFederate SAML",
  PingOneSAML: "PingOne SAML",
  SalesforceSAML: "Salesforce SAML",
  SimpleSamlPhpSAML: "SimpleSAMLPhp SAML",
  VMwareSAML: "VMware Workspace One SAML",
  MagicLink: "Magic Link",
  Unknown: "Unknown",
};

// -----------------------------------------------------------------------------

export const swrFetcher = async (...args: Parameters<typeof fetch>) => {
  ReturnIf(typeof args[0] === "object");

  let response;
  try {
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    response = await authedJSONFetch(args[0].toString(), args[1]);

    //
    return response;
  } catch (error) {
    const newError: any = new Error("There was an error");
    newError.info = response;

    throw error;
  }
};

async function authedJSONFetch(url: string, baseRequestInit?: RequestInit) {
  return await authenticatedFetch(
    (baseRequestInit?.method as Method) ?? "GET",
    url.replace(`${API_URL}/`, ""),
    baseRequestInit?.body && JSON.parse(baseRequestInit?.body as string),
    {
      csrf: (baseRequestInit?.headers as any)?.["X-CSRFToken"] !== undefined,
    }
  );
}

// -----------------------------------------------------------------------------

export interface AWSRoleData {
  role: string;
  external_id?: string;
}

export interface AWSKeyData {
  aws_access_key_id: string;
  aws_secret_access_key: string;
}

export interface GCPSAKeyData {
  gcp_resource_id: string;
  is_org_level: boolean;
}

export interface LookerKeyData {
  looker_client_id: string;
  looker_client_secret: string;
  looker_base_url: string;
}

export interface OktaKeyData {
  org_url: string;
  token: string;
}

export interface SnowflakeKeyData {
  snowflake_base_url: string;
  snowflake_client_id?: string;
  snowflake_client_secret?: string;
  snowflake_username?: string;
  snowflake_password?: string;
}

export type SourceData =
  | AWSRoleData
  | AWSKeyData
  | OktaKeyData
  | GCPSAKeyData
  | LookerKeyData
  | SnowflakeKeyData;

export interface DRFPageResponse<T> {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
}
