import { memo } from "react";

import SvgIcon from "@mui/material/SvgIcon";

// -----------------------------------------------------------------------------

export const LogoIcon = memo((props: object) => {
  return (
    <SvgIcon viewBox="0 0 165 40" {...props}>
      <path d="M0 4.77674L4.94708 3.946V10.5088H12.5517V14.704H4.94708V23.5514C4.94708 25.296 5.21964 26.5421 5.76477 27.2897C6.30991 28.0374 7.23663 28.4112 8.54495 28.4112C9.44442 28.4112 10.2349 28.3143 10.9163 28.1205C11.6249 27.9266 12.1837 27.7466 12.5926 27.5805L13.4103 31.568C12.8379 31.8172 12.0883 32.0665 11.1616 32.3157C10.2349 32.5926 9.14459 32.731 7.89079 32.731C6.36442 32.731 5.08336 32.5234 4.04761 32.108C3.03911 31.6926 2.23504 31.0973 1.6354 30.3219C1.03575 29.5189 0.613274 28.5635 0.367964 27.4559C0.122655 26.3205 0 25.0329 0 23.5929V4.77674Z" />
      <path d="M15.744 32.2326V0.830737L20.691 0V10.758C21.2362 10.5642 21.8631 10.3981 22.5717 10.2596C23.3077 10.1211 24.03 10.0519 24.7386 10.0519C26.4558 10.0519 27.8732 10.3011 28.9907 10.7996C30.1355 11.2703 31.0486 11.9488 31.73 12.8349C32.4114 13.6933 32.8884 14.7317 33.1609 15.9502C33.4608 17.1686 33.6107 18.5254 33.6107 20.0208V32.2326H28.6636V20.81C28.6636 19.6469 28.5818 18.6639 28.4183 17.8609C28.282 17.0301 28.0367 16.3655 27.6824 15.8671C27.3553 15.341 26.9055 14.9671 26.3332 14.7456C25.7608 14.4964 25.0521 14.3718 24.2071 14.3718C23.553 14.3718 22.8852 14.441 22.2038 14.5794C21.5224 14.7179 21.0181 14.8425 20.691 14.9533V32.2326H15.744Z" />
      <path d="M46.2159 14.4929C45.807 14.3545 45.5776 14.3325 45.5776 14.3325C45.5776 14.3325 45.4572 14.3718 44.5032 14.3718C43.9581 14.3718 43.3721 14.4271 42.7452 14.5379C42.1455 14.6487 41.7231 14.7456 41.4778 14.8287V32.2326H36.5307V11.5472C37.4847 11.1873 38.6703 10.855 40.0877 10.5504C41.5323 10.2181 43.1268 10.0519 44.8712 10.0519C45.1983 10.0519 45.5799 10.0796 46.016 10.135C46.4521 10.1627 46.8882 10.2181 47.3243 10.3011C47.7604 10.3565 48.1829 10.4396 48.5917 10.5504C49.0006 10.6334 49.3277 10.7165 49.573 10.7996C49.573 10.7996 48.2 11.7996 47.4935 12.618C46.9155 13.2875 46.2159 14.4929 46.2159 14.4929Z" />
      <path d="M47.1216 21.4746C47.1216 19.5639 47.3942 17.8885 47.9393 16.4486C48.5117 15.0087 49.2613 13.8179 50.188 12.8764C51.1147 11.9072 52.1777 11.1873 53.377 10.7165C54.5763 10.2181 55.8028 9.96885 57.0567 9.96885C60.0004 9.96885 62.2899 10.8965 63.9253 12.7518C65.588 14.6071 66.4193 17.3763 66.4193 21.0592C66.4193 21.3361 66.4057 21.6546 66.3784 22.0145C66.3784 22.3468 66.3648 22.6514 66.3375 22.9283H52.2322C52.3685 24.6729 52.9682 26.0298 54.0312 26.999C55.1214 27.9405 56.6887 28.4112 58.7329 28.4112C59.9322 28.4112 61.0225 28.3005 62.0037 28.0789C63.0122 27.8574 63.8027 27.622 64.3751 27.3728L65.0292 31.4849C64.7566 31.6234 64.3751 31.7757 63.8844 31.9419C63.4211 32.0803 62.8759 32.2049 62.249 32.3157C61.6494 32.4541 60.9952 32.5649 60.2866 32.648C59.5779 32.731 58.8556 32.7726 58.1197 32.7726C56.239 32.7726 54.6036 32.4957 53.2135 31.9419C51.8234 31.3603 50.6786 30.5711 49.7791 29.5742C48.8797 28.5497 48.2119 27.359 47.7758 26.0021C47.3397 24.6175 47.1216 23.1083 47.1216 21.4746ZM61.4722 19.2316C61.4722 18.5393 61.3768 17.8885 61.186 17.2793C60.9952 16.6424 60.709 16.1025 60.3274 15.6594C59.9731 15.1886 59.5234 14.8287 58.9782 14.5794C58.4604 14.3025 57.8335 14.1641 57.0975 14.1641C56.3344 14.1641 55.6666 14.3164 55.0942 14.621C54.5218 14.8979 54.0312 15.2717 53.6223 15.7425C53.2407 16.2132 52.9409 16.7532 52.7229 17.3624C52.5048 17.9716 52.3549 18.5947 52.2731 19.2316H61.4722Z" />
      <path d="M77.0294 28.702C78.5558 28.702 79.7142 28.6189 80.5046 28.4528V22.8868C80.2321 22.8037 79.8368 22.7207 79.319 22.6376C78.8011 22.5545 78.2287 22.513 77.6018 22.513C77.0567 22.513 76.4979 22.5545 75.9255 22.6376C75.3804 22.7207 74.8761 22.873 74.4128 23.0945C73.9767 23.316 73.6223 23.6345 73.3498 24.0498C73.0772 24.4375 72.9409 24.936 72.9409 25.5452C72.9409 26.7359 73.3089 27.5666 74.0448 28.0374C74.7807 28.4804 75.7756 28.702 77.0294 28.702ZM76.6206 9.96885C78.2559 9.96885 79.6324 10.1765 80.7499 10.5919C81.8675 11.0073 82.7533 11.5888 83.4074 12.3364C84.0889 13.0841 84.5659 13.9979 84.8384 15.0779C85.1382 16.1301 85.2882 17.2932 85.2882 18.567V31.7342C84.525 31.9003 83.3666 32.0942 81.8129 32.3157C80.2866 32.5649 78.5558 32.6895 76.6206 32.6895C75.3395 32.6895 74.1675 32.5649 73.1045 32.3157C72.0414 32.0665 71.1283 31.6649 70.3652 31.1111C69.6292 30.5573 69.0432 29.8373 68.6071 28.9512C68.1983 28.0651 67.9938 26.9713 67.9938 25.6698C67.9938 24.4237 68.2255 23.3714 68.6889 22.513C69.1795 21.6546 69.8337 20.9623 70.6514 20.4361C71.4691 19.8823 72.4094 19.4946 73.4724 19.2731C74.5627 19.0239 75.6938 18.8993 76.8659 18.8993C77.411 18.8993 77.9834 18.9408 78.583 19.0239C79.1827 19.0793 79.8232 19.19 80.5046 19.3562V18.5254C80.5046 17.9439 80.4365 17.3901 80.3002 16.864C80.1639 16.3378 79.9186 15.8809 79.5643 15.4932C79.2372 15.0779 78.7875 14.7594 78.2151 14.5379C77.6699 14.3164 76.9749 14.2056 76.1299 14.2056C74.9852 14.2056 73.9358 14.2887 72.9818 14.4548C72.0278 14.621 71.251 14.8148 70.6514 15.0363L70.0381 10.9657C70.665 10.7442 71.5781 10.5227 72.7774 10.3011C73.9767 10.0796 75.2577 9.96885 76.6206 9.96885Z" />
      <path d="M88.0916 4.77674L93.0387 3.946V10.5088H102.063L99.5076 14.704H93.0387V23.5514C93.0387 25.296 93.3112 26.5421 93.8564 27.2897C94.4015 28.0374 95.3282 28.4112 96.6365 28.4112C97.536 28.4112 98.3264 28.3143 99.0079 28.1205C99.7165 27.9266 100.275 27.7466 100.684 27.5805L101.502 31.568C100.929 31.8172 100.18 32.0665 99.2532 32.3157C98.3264 32.5926 97.2362 32.731 95.9824 32.731C94.456 32.731 93.1749 32.5234 92.1392 32.108C91.1307 31.6926 90.3266 31.0973 89.727 30.3219C89.1273 29.5189 88.7048 28.5635 88.4595 27.4559C88.2142 26.3205 88.0916 25.0329 88.0916 23.5929V4.77674Z" />
      <path d="M109.35 18.9823C109.977 18.3178 110.645 17.6116 111.354 16.864C112.062 16.0886 112.758 15.3271 113.439 14.5794C114.12 13.8041 114.761 13.0703 115.361 12.378C115.987 11.658 116.519 11.035 116.955 10.5088H122.802C121.439 12.0595 119.994 13.6656 118.468 15.3271C116.969 16.9886 115.456 18.6085 113.93 20.1869C114.747 20.8792 115.606 21.7238 116.505 22.7207C117.405 23.6899 118.277 24.7283 119.122 25.8359C119.967 26.9436 120.757 28.0512 121.493 29.1589C122.229 30.2665 122.842 31.2911 123.333 32.2326H117.609C117.119 31.3742 116.546 30.4742 115.892 29.5327C115.265 28.5912 114.584 27.6774 113.848 26.7913C113.112 25.9052 112.362 25.0744 111.599 24.2991C110.836 23.496 110.086 22.8176 109.35 22.2638V32.2326H104.403V0.830737L109.35 0V18.9823Z" />
      <path d="M123.188 21.4746C123.188 19.5639 123.461 17.8885 124.006 16.4486C124.578 15.0087 125.328 13.8179 126.255 12.8764C127.181 11.9072 128.244 11.1873 129.444 10.7165C130.643 10.2181 131.869 9.96885 133.123 9.96885C136.067 9.96885 138.356 10.8965 139.992 12.7518C141.655 14.6071 142.486 17.3763 142.486 21.0592C142.486 21.3361 142.472 21.6546 142.445 22.0145C142.445 22.3468 142.431 22.6514 142.404 22.9283H128.299C128.435 24.6729 129.035 26.0298 130.098 26.999C131.188 27.9405 132.755 28.4112 134.799 28.4112C135.999 28.4112 137.089 28.3005 138.07 28.0789C139.079 27.8574 139.869 27.622 140.442 27.3728L141.096 31.4849C140.823 31.6234 140.442 31.7757 139.951 31.9419C139.488 32.0803 138.942 32.2049 138.316 32.3157C137.716 32.4541 137.062 32.5649 136.353 32.648C135.644 32.731 134.922 32.7726 134.186 32.7726C132.305 32.7726 130.67 32.4957 129.28 31.9419C127.89 31.3603 126.745 30.5711 125.846 29.5742C124.946 28.5497 124.278 27.359 123.842 26.0021C123.406 24.6175 123.188 23.1083 123.188 21.4746ZM137.539 19.2316C137.539 18.5393 137.443 17.8885 137.253 17.2793C137.062 16.6424 136.776 16.1025 136.394 15.6594C136.04 15.1886 135.59 14.8287 135.045 14.5794C134.527 14.3025 133.9 14.1641 133.164 14.1641C132.401 14.1641 131.733 14.3164 131.161 14.621C130.588 14.8979 130.098 15.2717 129.689 15.7425C129.307 16.2132 129.007 16.7532 128.789 17.3624C128.571 17.9716 128.421 18.5947 128.34 19.2316H137.539Z" />
      <path d="M164.167 10.5088C161.959 18.7608 159.397 26.2375 156.48 32.9387C155.935 34.1848 155.376 35.251 154.804 36.1371C154.232 37.0232 153.591 37.757 152.882 38.3385C152.174 38.92 151.383 39.3354 150.511 39.5846C149.639 39.8615 148.63 40 147.486 40C146.722 40 145.959 39.9169 145.196 39.7508C144.46 39.5846 143.901 39.4046 143.52 39.2108L144.419 35.1817C145.4 35.5694 146.368 35.7632 147.322 35.7632C148.603 35.7632 149.598 35.4448 150.307 34.8079C151.043 34.1987 151.683 33.2433 152.228 31.9419C150.675 28.8958 149.176 25.5729 147.731 21.973C146.314 18.3731 145.046 14.5517 143.929 10.5088H149.203C149.475 11.6719 149.802 12.9318 150.184 14.2887C150.593 15.6456 151.029 17.0301 151.492 18.4424C151.956 19.8269 152.446 21.2115 152.964 22.5961C153.482 23.9806 154 25.2683 154.518 26.459C155.39 23.9945 156.208 21.3776 156.971 18.6085C157.734 15.8117 158.429 13.1118 159.056 10.5088H164.167Z" />
    </SvgIcon>
  );
});
