import { MattermostWebhookCoverImage } from "./CoverImage";

// ----------------------------------------------------------------------------- ∏

export const MatterMostWebhookAppConfig = {
  name: "Mattermost",
  label: "Mattermost Webhooks",
  singularLabel: "Mattermost Webhook",
  coverImage: MattermostWebhookCoverImage,
  searchTerms: ["Mattermost", "mm", "matter"],
  url: "mattermost",
  connectInfo: {
    instructions: {
      example: "Mattermost instance",
    },
    placeholder: "https://{your-mattermost-site}/hooks/xxx-generatedkey-xxx",
    // Disable this check so we match the backend regex exactly.
    // eslint-disable-next-line no-useless-escape
    regex: /^https:\/\/[a-zA-Z0-9-.]+\/hooks\/[a-zA-Z0-9-]+\/[a-zA-Z0-9-]+$/,
    path: "mattermost/webhook", // doesn't exist on BE?
  },
  notifications: true,
  notificationKey: "mattermost_webhook",
};
