import { memo } from "react";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { type SxProps, type Theme } from "@mui/material/styles";

// -----------------------------------------------------------------------------

export const ServiceConnectionPhase = memo(
  ({ className, disabled, children, sx = {} }: Props) => {
    return (
      <Paper className={className} elevation={1} sx={sx}>
        <Box
          sx={{
            p: 1.5,
            opacity: disabled ? 0.33 : 1,
            pointerEvents: disabled ? "none" : "all",
          }}
        >
          {children}
        </Box>
      </Paper>
    );
  }
);

// -----------------------------------------------------------------------------

interface Props {
  className?: string;
  children: React.ReactNode;
  disabled: boolean;
  sx?: SxProps<Theme>;
}
