import { ThrowIf } from "babel-plugin-transform-functional-return";

import {
  type ApiAccountPatchPayload,
  type ApiAccountPatchResponse,
  type ApiAuthLoginUserPostResponse,
  type ApiAuthRegisterUserPostResponse,
  type ApiMeGetResponse,
} from "data/api";

import {
  authenticatedGetFetch,
  authenticatedPatchFetch,
} from "utility/fetch/authenticated";
import { unauthenticatedPostFetch } from "utility/fetch/unauthenticated";

// -----------------------------------------------------------------------------

export async function getAccount(
  dontRetry: boolean
): Promise<ApiMeGetResponse> {
  return await authenticatedGetFetch<ApiMeGetResponse>("api/me", {
    dontRetry,
  });
}

export async function authenticateAccount(
  emailAddress: string
): Promise<ApiAuthLoginUserPostResponse> {
  const emailAddressParsed = emailAddress.trim();
  ThrowIf(
    emailAddressParsed.length < 1,
    new Error("Email address cannot be empty")
  );

  //
  return await unauthenticatedPostFetch<ApiAuthLoginUserPostResponse>(
    "api/auth/loginUser",
    {
      email: emailAddressParsed,
    },
    [200, 400]
  );
}

export async function registerAccount({
  emailAddress = "",
  fullName = "",
  organizationName = "",
}: {
  emailAddress: string;
  fullName: string;
  organizationName: string;
}): Promise<ApiAuthRegisterUserPostResponse> {
  const emailAddressParsed = emailAddress.trim();
  const fullNameParsed = fullName.trim();
  const organizationNameParsed = organizationName.trim();

  ThrowIf(
    emailAddressParsed.length < 1,
    new Error("Email address cannot be empty")
  );
  ThrowIf(fullNameParsed.length < 1, new Error("Full name cannot be empty"));
  ThrowIf(
    organizationNameParsed.length < 1,
    new Error("Organization name cannot be empty")
  );

  //
  return await unauthenticatedPostFetch<ApiAuthRegisterUserPostResponse>(
    "api/auth/registerUser",
    {
      email: emailAddressParsed,
      full_name: fullNameParsed,
      org_name: organizationNameParsed,
    },
    [201, 400]
  );
}

export async function editAccount(
  uuid: string,
  newData: ApiAccountPatchPayload
) {
  return await authenticatedPatchFetch<
    ApiAccountPatchResponse,
    ApiAccountPatchPayload
  >(`api/account/${uuid}`, newData);
}
