import { type Action, createHook, createStore } from "react-sweet-state";

import isEmpty from "lodash/isEmpty";

import { type ApiAccountRecord } from "data/api";
import { type FOTS } from "data/typescript";
import { type UserPermissionLevel, type UserRecord } from "data/user";

// -----------------------------------------------------------------------------

const initialState: UserStoreState = Object.freeze({
  user: null as FOTS,
  dismissedExpirationWarning: false,
});

// -----------------------------------------------------------------------------

export const UserStore = createStore({
  initialState,
  actions: {
    set,
    clear,

    dismissExpirationWarningBar,
  },
});

export const useUserStore = createHook(UserStore);

// -----------------------------------------------------------------------------

function set(user: ApiAccountRecord): Action<UserStoreState> {
  return ({ setState }) => setState({ user: buildUserData(user) });
}

function clear(): Action<UserStoreState> {
  return ({ setState }) => setState(initialState);
}

function dismissExpirationWarningBar(): Action<UserStoreState> {
  return async ({ setState }) => setState({ dismissedExpirationWarning: true });
}

// -----------------------------------------------------------------------------

function buildUserData(data: ApiAccountRecord): UserRecord {
  return {
    uuid: data.uuid,
    fullName: data.full_name,
    preferredName: data.preferred_name,
    gravatarEmail: data.gravatar_specific_email,
    email: data.email,
    features: data.features || 0, // '||' is sort of temporary - just so that the backend can implement flags out of band
    organization: data.organization,
    permissionLevel: data.permission_level as UserPermissionLevel,
    isNew: data.is_new,
    preferences: {
      productAndFeatures: toDate(data.product_and_features),
      companyAnnouncements: toDate(data.company_announcements),
      educationAndTraining: toDate(data.education_and_training),
      marketingInformation: toDate(data.marketing_information),
      monthlyNewsletter: toDate(data.monthly_newsletter),
      sourceConnected: toDate(data.source_connected),
      sourceDisconnected: toDate(data.source_disconnected),
      destinationConnected: toDate(data.destination_connected),
      destinationDisconnected: toDate(data.destination_disconnected),
      scanFailure: toDate(data.scan_failure),
    },
  };
}

function toDate(dateString?: string): Date | undefined {
  return isEmpty(dateString) ? undefined : new Date(dateString as string);
}

// -----------------------------------------------------------------------------

export interface UserStoreState {
  user: UserRecord;
  dismissedExpirationWarning: boolean;
}
