import { type Metrics } from "data/finding";

// -----------------------------------------------------------------------------

// this generate various numbers based on metrics

export function getWidgetData(metrics?: Metrics) {
  return {
    findings_resolved: {
      count: metrics?.resolved ?? 0,
      // how would we determine this?
      change: 0,
      changePercentage: 0,
    },
    findings_created_last_30_days: {
      count: metrics?.findings_created_last_30_days ?? 0,
      change: 0,
      changePercentage: 0,
    },
    findings: {
      count: metrics?.total ?? 0,
      change: 0,
      changePercentage: 0,
    },
  };
}
