/* eslint-disable @typescript-eslint/no-var-requires */

import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import { type FOTS } from "data/typescript";

import { AppReleaseVersion } from "stores/lib";

// -----------------------------------------------------------------------------

const ThingsToIgnore: string[] = [
  "Failed to fetch",
  "NetworkError",
  "Token is blacklisted",
  "Reauthentication required",
];

// -----------------------------------------------------------------------------

if (!process.env.REACT_APP_NO_SENTRY && !process.env.REACT_APP_NO_EXTERNAL) {
  // Don't download libraries if not enabled
  const Sentry = require("@sentry/react");

  Sentry.init({
    dsn: "https://5539f04a79764cb1b23d549c74d271e7@o531054.ingest.sentry.io/5651272",
    tunnel: "https://sp.threatkey.com/api/5651272/envelope/",
    debug: process.env.REACT_APP_ENVIRONMENT !== "prod",
    integrations: [
      Sentry.browserTracingIntegration({
        tracePropagationTargets: [
          "localhost",
          "threatkey.developer",
          /^\//,
          "api.threatkey.com",
          "api-dev.svc.threatkey.com",
        ],
        routingInstrumentation: Sentry.reactRouterV6BrowserTracingIntegration(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false,
        mask: [".lipsum", "[data-private]"],
        stickySession: true,
        networkDetailAllowUrls: [
          /^https:\/\/.*\.threatkey\.com/,
          window.location.origin,
        ],
        networkRequestHeaders: [
          // includes defaults: ["Content-Type", "Content-Length", "Accept"]
          "Referer",
          "User-Agent",
          "X-Forwarded-For",
          "Cache-Control",
        ],
        networkResponseHeaders: ["Referrer-Policy"],
      }),
    ],
    beforeSend: (event: FOTS, hint: FOTS) =>
      process.env.REACT_APP_NO_SENTRY
        ? localErrorOnly(
            event?.transaction ?? "Unknown transaction",
            hint.originalException ??
              hint.syntheticException ??
              new Error("An error occurred")
          )
        : event,
    ignoreErrors: ThingsToIgnore,
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: AppReleaseVersion,
    attachStacktrace: true,
    autoSessionTracking: true,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0, // consider reducing to 10% in production
    replaysOnErrorSampleRate: 1.0,
  });
} else {
  console.info("Sentry disabled");
}

// -----------------------------------------------------------------------------

function localErrorOnly(message: string, error: unknown): null {
  console.error(`${message}:\n`, error);

  //
  return null;
}
