import { memo } from "react";

import styled from "@emotion/styled";

import { type ServerFindingWithType } from "data/finding";

// -----------------------------------------------------------------------------

export const FindingIssue = memo(({ finding }: Props) => {
  return (
    <Container>
      <Row>
        Asset: {finding?.details?.asset?.name ?? finding?.details?.asset?.id}
      </Row>
      {finding?.details?.actor !== undefined ? (
        <Row>Actor: {finding?.details?.actor?.email}</Row>
      ) : null}
    </Container>
  );
});

// -----------------------------------------------------------------------------

const Container = styled.div``;

const Row = styled.div`
  word-break: break-word;
  word-wrap: break-word;
`;

// -----------------------------------------------------------------------------

interface Props {
  finding: ServerFindingWithType;
}
