import {
  type KeyboardEvent,
  memo,
  type MouseEvent,
  type MouseEventHandler,
  type RefObject,
  useEffect,
  useRef,
  useState,
} from "react";

import { type FilterOptionsState } from "@mui/material";
import { ReturnIf } from "babel-plugin-transform-functional-return";
import isEqual from "lodash/isEqual";
import sortBy from "lodash/sortBy";
import styled from "styled-components";

import Autocomplete, {
  type AutocompleteRenderGroupParams,
  type AutocompleteRenderInputParams,
} from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

import CheckIcon from "@mui/icons-material/Check";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ClearIcon from "@mui/icons-material/Clear";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import RefreshIcon from "@mui/icons-material/Refresh";
import ShareIcon from "@mui/icons-material/Share";

import { type FOTS } from "data/typescript";

import { hasNo } from "utility/has";
import { pluralize } from "utility/pluralize";
import { classIf } from "utility/style";

import { FindingsContext, type FindingsSortByColumn } from "./lib/context";
import {
  DefaultFilters,
  FilterGroupIcon,
  FilterGroupLabel,
  FilterGroupOptions,
  FilterGroupsWithDefaults,
  type FilterOption,
  FilterOptions,
  type FilterOptionType,
  TypeDefaultFilters,
} from "./lib/data";

// -----------------------------------------------------------------------------

enum DisplayState {
  All,
  OnlyFilters,
}

// -----------------------------------------------------------------------------

export const FindingsPageFiltersPane = memo(({ showShareModal }: Props) => {
  const searchContainerRef = useRef<HTMLDivElement>();
  const filtersInputRef = useRef<HTMLInputElement>();

  // This is the smelliest code smell that ever smelled.
  // Small price to pay.
  const onSubmit = useRef<Function>(() => {});

  //
  const [displayState, setDisplayState] = useState(DisplayState.All);

  //
  useEffect(() => {
    ReturnIf(displayState === DisplayState.All);

    //
    function checkForExternalBlur(
      event: MouseEvent<HTMLDivElement, MouseEvent>
    ) {
      event.stopPropagation();

      //
      if (displayState !== DisplayState.All) {
        const target = event.target as Element;
        const clickedOnFormItem =
          (searchContainerRef.current?.contains(target) ?? false) &&
          searchContainerRef.current !== event.target;
        if (!clickedOnFormItem) {
          resetDisplayState();
          clearAutocompleteInput();
          onSubmit.current?.();
        }
      }
    }

    //
    window.addEventListener("touchend", checkForExternalBlur as FOTS);
    window.addEventListener("mouseup", checkForExternalBlur as FOTS);

    //
    return () => {
      window.removeEventListener("touchend", checkForExternalBlur as FOTS);
      window.removeEventListener("mouseup", checkForExternalBlur as FOTS);
    };
  }, [displayState]);

  //
  function resetDisplayState() {
    setDisplayState(DisplayState.All);
  }

  function highlightFilters() {
    setDisplayState(DisplayState.OnlyFilters);
    setTimeout(() => {
      filtersInputRef.current?.focus();
      filtersInputRef.current?.select();
    });
  }

  function clearAutocompleteInput() {
    const input = filtersInputRef.current;
    if (input !== undefined) {
      input.value = "";
    }
  }

  //
  return (
    <FindingsContext.Consumer>
      {({
        sort,
        setSort,

        filters,
        setFilters,

        tempFilters,
        setTempFilters,
      }) => {
        // Can't put useEffect, etc in here, so:
        onSubmit.current = () => {
          if (tempFilters.length > 0) {
            setFilters(tempFilters);
          }
          setTempFilters([]);
        };

        //
        const localFilters = tempFilters.length > 0 ? tempFilters : filters;

        //
        const filtersInput = (
          <AutocompleteContainer>
            <Autocomplete
              autoHighlight
              disableCloseOnSelect
              disablePortal
              fullWidth
              multiple
              selectOnFocus
              className="filter"
              clearIcon={<RefreshIcon />}
              clearText="Reset search"
              filterOptions={filterOptions}
              getOptionLabel={getFilterOptionLabel}
              groupBy={getFilterOptionGroup}
              isOptionEqualToValue={isOptionEqualToValue}
              onFocus={highlightFilters}
              onChange={(_, selectedOptions: FilterOption[]) => {
                // When removing a text search from the input where there are
                // no other options in the list matching the entered search
                // value will cause an error. A bug caused by forcing in text
                // search items
                const cleanedSelectedOptions =
                  selectedOptions[selectedOptions.length - 1] === undefined
                    ? selectedOptions.slice(1, -1)
                    : selectedOptions;

                //
                const firstIndex: Record<FilterOptionType, number> = {
                  severity: 0,
                  state: 0,
                  source: 0,
                  text: 0,
                };
                const newOptions = cleanedSelectedOptions.reduce<
                  Record<FilterOptionType, FilterOption[]>
                >(
                  (obj, option, current, options) => {
                    if (current > firstIndex[option.type]) {
                      firstIndex[option.type] = current;
                    }

                    // options are sent in the order they were clicked
                    // therefore the last option is the latest choice
                    if (option.value === "all" || option.value === "all-open") {
                      if (
                        current === firstIndex[option.type] ||
                        current >= options.length - 1
                      ) {
                        obj[option.type] = [option];
                      }
                    } else {
                      const value = obj[option.type][0]?.value;
                      if (value === "all" || value === "all-open") {
                        obj[option.type] = [option];
                      } else {
                        obj[option.type].push(option);
                      }
                    }

                    // upgrade in vitro search filter
                    if (
                      option.type === "text" &&
                      option.label.startsWith("Search for:")
                    ) {
                      option.label = option.value as string;
                    }

                    //
                    return obj;
                  },
                  { text: [], severity: [], state: [], source: [] }
                );

                //
                FilterGroupsWithDefaults.forEach((group) => {
                  if (
                    hasNo(newOptions[group]) ||
                    newOptions[group].length ===
                      FilterGroupOptions[group].length
                  ) {
                    newOptions[group] = [...TypeDefaultFilters[group]];
                  }
                });

                //
                setTempFilters(
                  Object.values(newOptions).reduce((arr, group) => {
                    switch (group[0]?.type) {
                      case "severity":
                        return arr.concat(sortBy(group, "value"));

                      case "state":
                      case "source":
                        return arr.concat(sortBy(group, "label"));

                      case "text":
                        return arr.concat(group);

                      default:
                        return arr;
                    }
                  }, [])
                );
                clearAutocompleteInput();
              }}
              onClose={clearAutocompleteInput}
              onKeyDownCapture={onKeyDownCapture(filtersInputRef)}
              onKeyUp={(event) => {
                const inputLength = filtersInputRef.current?.value?.length ?? 0;

                //
                switch (event.key) {
                  case "Escape":
                    if (inputLength > 0) {
                      clearAutocompleteInput();
                    } else {
                      resetDisplayState();
                      setTempFilters([]);
                      filtersInputRef.current?.blur();
                    }

                    //
                    return;

                  case "Enter":
                    if (inputLength < 1 && event.ctrlKey) {
                      (event as FOTS).defaultMuiPrevented = true;
                      event.preventDefault();
                      event.stopPropagation();
                      resetDisplayState();
                      setTempFilters([]);
                      filtersInputRef.current?.blur();

                      //
                      return false;
                    }
                }
              }}
              open={displayState === DisplayState.OnlyFilters}
              options={[
                ...localFilters.filter((filter) => filter.type === "text"),
                ...FilterOptions,
              ]}
              popupIcon={null}
              renderGroup={renderGroup}
              renderInput={getFilterInput(
                filtersInputRef as FOTS,
                getInputPlaceholder(localFilters, displayState)
              )}
              renderOption={renderOption(localFilters)}
              renderTags={(filters) => renderFilterChips(filters)}
              size="small"
              value={localFilters}
            />
          </AutocompleteContainer>
        );

        //
        switch (displayState) {
          case DisplayState.OnlyFilters:
            return (
              <Container className="only" ref={searchContainerRef}>
                {filtersInput}
                <Box className="filter-actions">
                  <IconButton
                    onClick={() => {
                      resetDisplayState();
                      setFilters(tempFilters);
                      setTempFilters([]);
                      filtersInputRef.current?.blur();
                    }}
                  >
                    <CheckIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      resetDisplayState();
                      setTempFilters([]);
                      filtersInputRef.current?.blur();
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                </Box>
              </Container>
            );

          default:
            return (
              <Container
                className={classIf(
                  !isEqual(filters, DefaultFilters),
                  "hasUniqueFilters"
                )}
                ref={searchContainerRef}
              >
                {filtersInput}
                <FormControl size="small">
                  <InputLabel>Sort By</InputLabel>
                  <Select<FindingsSortByColumn>
                    value={sort[0]}
                    label="Sort By"
                    onChange={(event) =>
                      setSort(
                        event.target.value as FindingsSortByColumn,
                        sort[1]
                      )
                    }
                  >
                    <MenuItem value="asset">Asset</MenuItem>
                    <MenuItem value="event_time">Event Time</MenuItem>
                    <MenuItem value="name">Name</MenuItem>
                    <MenuItem value="severity">Severity</MenuItem>
                    <MenuItem value="source">Source</MenuItem>
                    <MenuItem value="state">State</MenuItem>
                  </Select>
                </FormControl>
                <IconButton
                  onClick={() => setSort(sort[0], !sort[1])}
                  color={sort[1] ? "default" : "primary"}
                >
                  <ImportExportIcon />
                </IconButton>
                <IconButton
                  className="share"
                  onClick={
                    showShareModal as MouseEventHandler<HTMLButtonElement>
                  }
                >
                  <ShareIcon />
                </IconButton>
                <Chips>{renderFilterChips(filters, highlightFilters)}</Chips>
              </Container>
            );
        }
      }}
    </FindingsContext.Consumer>
  );
});

// -----------------------------------------------------------------------------

function filterOptions(
  filters: FilterOption[],
  state: FilterOptionsState<FilterOption>
): FilterOption[] {
  // break apart search into letters
  const inputValue = state.inputValue.replace(/\s/g, "");
  const terms = inputValue.toLocaleLowerCase().split("");

  //
  const searchTermExists =
    inputValue.length > 0
      ? filters.find(
          (filter) => filter.type === "text" && filter.value === inputValue
        ) !== undefined
      : true;
  const insertSearchTerm: FilterOption = {
    type: "text",
    label: `Search for: ${state.inputValue}`,
    value: state.inputValue,
  };

  //
  return [
    ...(searchTermExists ? [] : [insertSearchTerm]),
    ...filters.filter((option) => {
      // Combine option values: severity all
      const search = [option.type, option.label].join(" ").toLocaleLowerCase();

      //
      let lastIndex = -1;
      let lastLetter = "";

      // check that each letter appears in order and after the previous letter
      return terms.every((term) => {
        const nextIndex = search.indexOf(
          term,
          // repeated letters +1 position check
          lastLetter === term ? lastIndex + 1 : lastIndex
        );

        const checkIndex = lastIndex;
        lastIndex = nextIndex;
        lastLetter = term;

        //
        return nextIndex > checkIndex;
      });
    }),
  ];
}

function getFilterOptionLabel(option: FilterOption): string {
  return option.label;
}

function getFilterOptionGroup(option: FilterOption): string {
  return option.type;
}

function getFilterInput(ref: RefObject<HTMLInputElement>, placeholder: string) {
  return (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      inputRef={ref}
      label="Search"
      placeholder={placeholder}
    />
  );
}

function renderGroup(params: AutocompleteRenderGroupParams) {
  const group = params.group as FilterOptionType;
  const label = FilterGroupLabel[group];
  const Icon = FilterGroupIcon[group];

  return (
    <li key={params.key}>
      <FilterHeader>
        <Icon fontSize="inherit" /> {label}
      </FilterHeader>
      <FilterItems>{params.children}</FilterItems>
    </li>
  );
}

function renderFilterChips(
  filters: FilterOption[],
  onClick?: MouseEventHandler<HTMLDivElement>
) {
  return filters.map((filter, i) => {
    const group = filter.type;
    const Icon = FilterGroupIcon[group];

    //
    return (
      <FilterChip
        key={i}
        className={filter.type}
        label={
          <>
            <Icon fontSize="inherit" /> <span>{filter.label}</span>
          </>
        }
        onClick={onClick}
        size="small"
      />
    );
  });
}

function renderOption(filters: FilterOption[]) {
  return (props: React.HTMLAttributes<HTMLLIElement>, option: FilterOption) => {
    const checked =
      filters.find(
        (filter) =>
          [option.type, option.value].join() ===
          [filter.type, filter.value].join()
      ) !== undefined;

    //
    return (
      // nosemgrep: typescript.react.security.audit.react-html-element-spreading.react-html-element-spreading
      <li {...props} style={{ paddingLeft: 8 }}>
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          sx={{
            marginRight: 8,
            padding: 0,
          }}
          checked={checked}
        />
        {option.label}
      </li>
    );
  };
}

function isOptionEqualToValue(filter: FilterOption, value: FilterOption) {
  return filter !== undefined
    ? getComparableFilterValue(filter) === getComparableFilterValue(value)
    : false;
}

function getComparableFilterValue(filter: FilterOption): string {
  return [filter.type, filter.label, filter.value].join();
}

function getInputPlaceholder(
  filters: FilterOption[],
  displayState: DisplayState
): string {
  switch (true) {
    case filters.length > 0 && displayState === DisplayState.All:
      return `${filters.length} ${pluralize(filters.length, "filter")}`;

    case displayState !== DisplayState.All:
    default:
      return "...";
  }
}

function onKeyDownCapture(
  filtersInputRef: RefObject<HTMLInputElement | undefined>
) {
  return (event: KeyboardEvent<HTMLInputElement>) => {
    const inputLength = filtersInputRef.current?.value?.length ?? 0;

    //
    switch (event.key) {
      case "Escape":
        event.preventDefault();
        event.stopPropagation();
        return false;

      case "Enter":
        if (inputLength < 1 && event.ctrlKey) {
          (event as FOTS).defaultMuiPrevented = true;
          event.preventDefault();
          event.stopPropagation();
          return false;
        }
    }
  };
}

// -----------------------------------------------------------------------------

const Chips = styled(Box)`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  grid-column: 1 / span 4;
`;

const AutocompleteContainer = styled(Box)`
  position: relative;

  .MuiAutocomplete-paper {
    border-radius: 4px;
  }

  .MuiAutocomplete-tag,
  .MuiChip-root {
    display: none;
  }

  .MuiAutocomplete-inputRoot {
    padding-right: 40px;
    gap: 4px;
  }

  .MuiAutocomplete-input {
    min-width: unset;
    padding: 0;
  }

  .MuiAutocomplete-endAdornment {
    right: 4px !important;
    top: unset;
  }

  .MuiAutocomplete-popper {
    left: 0;
    right: 0;
    width: unset !important;
    inset: 0px 0px auto 0px !important;
  }

  .MuiAutocomplete-listbox {
    padding: 0;
  }

  .MuiAutocomplete-option {
    min-height: unset !important;
  }

  .MuiAutocomplete-clearIndicator {
    visibility: hidden !important;
  }
`;

const Container = styled(Box)`
  align-items: center;
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr auto auto auto;

  &.only {
    grid-template-columns: 1fr auto;

    ${AutocompleteContainer} {
      .MuiChip-root {
        display: unset;
      }

      .MuiAutocomplete-input {
        width: 100%;
      }

      .MuiAutocomplete-clearIndicator {
        visibility: visible !important;
      }
    }
  }

  &.hasUniqueFilters ${AutocompleteContainer} .MuiAutocomplete-clearIndicator {
    visibility: visible !important;
  }

  @media screen and (max-width: 640px) {
    ${Chips} {
      display: none;
    }

    &.only .filter-actions {
      display: flex;
      flex-direction: column;
    }
  }
`;

const FilterHeader = styled(Box)`
  align-items: center;
  background: grey;
  color: ${(props) => props.theme.primary.contrastText};
  display: flex;
  font-size: 0.9rem;
  font-weight: bold;
  gap: 4px;
  padding: 4px 8px;
`;

const FilterItems = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
`;

const FilterChip = styled(Chip)`
  .MuiChip-label {
    align-items: center;
    display: flex;
    gap: 4px;

    span {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      color: ${(props) => props.theme.findingsFilters.text};
    }
  }

  svg {
    transform: translateY(-0.5px);
    color: ${(props) => props.theme.findingsFilters.text};
  }

  &.severity {
    background-color: ${(props) => props.theme.findingsFilters.severity};
  }

  &.state {
    background-color: ${(props) => props.theme.findingsFilters.state};
  }

  &.source {
    background-color: ${(props) => props.theme.findingsFilters.source};
  }
`;

// -----------------------------------------------------------------------------

interface Props {
  showShareModal: Function;
}
