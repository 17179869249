import { memo } from "react";

import { ResponsivePie } from "@nivo/pie";
import clamp from "lodash/clamp";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import { type FOTS } from "data/typescript";

import { SVGText } from "Component/SVGText";

// -----------------------------------------------------------------------------

const GaugeData: GaugeDataEntries[] = [
  {
    id: "high-risk",
    color: "#f00",
    label: "",
    value: 1,
  },
  {
    id: "elevated-risk",
    color: "#FF6257",
    label: "",
    value: 1,
  },
  {
    id: "notable-risk",
    color: "#FF9357",
    label: "",
    value: 1,
  },
  {
    id: "requires-improvement",
    color: "#FFBC57",
    label: "",
    value: 1,
  },
  {
    id: "good",
    color: "#3C9D40",
    label: "",
    value: 1,
  },
];

// -----------------------------------------------------------------------------

export const GaugePie = memo(
  ({ title, loading = false, riskScore = 0 }: Props) => {
    const riskScoreRatio = getRiskRatio(riskScore);
    const hasAtLeastOneDatapoint = riskScore > -1;

    //
    return (
      <Card
        sx={{
          width: "100%",
          minWidth: "400px",
          height: "400px",
          overflow: "visible",
          display: "grid",
          gridTemplateRows: "auto 1fr",
        }}
      >
        <CardContent sx={{ padding: "8px 16px" }}>
          <Typography variant="overline">{title}</Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5" fontWeight="bold">
              {loading ? (
                <>
                  &nbsp;
                  <CircularProgress size={20} />
                </>
              ) : !hasAtLeastOneDatapoint ? (
                "None"
              ) : null}
            </Typography>
          </Box>
        </CardContent>
        {!loading && hasAtLeastOneDatapoint ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflow: "hidden",
              padding: "8px 16px",
            }}
          >
            <ResponsivePie
              margin={{ left: 32, right: 32 }}
              colors={{ datum: "data.color" }}
              isInteractive={false}
              activeOuterRadiusOffset={0}
              startAngle={-90}
              endAngle={90}
              innerRadius={0.75}
              padAngle={0}
              enableArcLabels={false}
              enableArcLinkLabels={false}
              data={GaugeData}
              layers={[
                "arcs",
                renderMetric(riskScore),
                renderScore(riskScoreRatio),
              ]}
              renderWrapper={false}
            />
          </Box>
        ) : null}
      </Card>
    );
  }
);

// -----------------------------------------------------------------------------

function renderMetric(riskScore: number) {
  return ({ centerX, centerY }: any) => {
    return (
      <>
        <SVGText
          x={centerX}
          y={centerY - 50}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: "50px",
            fontWeight: 700,
          }}
        >
          {riskScore}
        </SVGText>
        <SVGText
          x={centerX}
          y={centerY - 15}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: "16px",
            fontWeight: 500,
            textTransform: "uppercase",
          }}
        >
          {getRankingFromRiskScore(riskScore)}
        </SVGText>
      </>
    );
  };
}

function renderScore(riskScoreRatio: number) {
  const degrees = 180 * riskScoreRatio;
  const radians = degrees * (Math.PI / 180);

  //
  return ({ centerX, centerY, radius, innerRadius }: FOTS) => {
    const x = centerX - radius * 0.88 * Math.cos(radians);
    const y = centerY - innerRadius * 1.165 * Math.sin(radians);

    //
    return (
      <svg x={x - 32} y={y - 32} width="64" height="64">
        <ellipse
          cx="32"
          cy="32"
          rx="6"
          ry="30"
          fill="#fff"
          stroke="#000"
          transform={`rotate(${degrees + 90})`}
          style={{ transformOrigin: "center" }}
        />
      </svg>
    );
  };
}

// -----------------------------------------------------------------------------

function getRankingFromRiskScore(riskScore: number = 0) {
  switch (true) {
    case riskScore > 799:
      return "Good";
    case riskScore > 739:
      return "Requires Improvement";
    case riskScore > 669:
      return "Notable Risk";
    case riskScore > 579:
      return "Elevated Risk";
    case riskScore > 0:
      return "High Risk";
    default:
      return "Unknown";
  }
}

const AngleValues = [
  [300, 579, 580 - 300 - 1],
  [580, 669, 670 - 580 - 1],
  [670, 739, 740 - 670 - 1],
  [740, 799, 800 - 740 - 1],
  [800, 850, 850 - 800 - 1],
];

function getRiskRatio(riskScore: number) {
  const adjustedRatio = Math.round(clamp(riskScore, 300, 850));
  const ratio = AngleValues.reduce((ratio, [min, max, difference]) => {
    switch (true) {
      case adjustedRatio > max:
        return ratio + 1;

      case adjustedRatio > min && adjustedRatio < max:
        return ratio + (adjustedRatio - min) / difference;

      default:
        return ratio;
    }
  }, 0);

  //
  return ratio / 5;
}

// -----------------------------------------------------------------------------

interface Props {
  title: JSX.Element | string;
  loading: boolean;
  riskScore: number;
}

interface GaugeDataEntries {
  id: string;
  label: string;
  value: number;
  color?: string;
}
