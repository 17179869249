import { Link } from "react-router-dom";

import styled from "styled-components";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

// -----------------------------------------------------------------------------

export const StaleFindingsNoData = ({ cta, bodyText }: Props) => {
  return (
    <Container>
      <Label gutterBottom variant="h4">
        {bodyText}
      </Label>
      <LinkUnstyled to={cta.url}>
        <Button variant="contained">{cta.title}</Button>
      </LinkUnstyled>
    </Container>
  );
};

// -----------------------------------------------------------------------------

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  text-align: center;
`;

const Label = styled(Typography)`
  font-size: 24px;
  max-width: 75%;
`;

const LinkUnstyled = styled(Link)`
  text-decoration: none;
`;

// -----------------------------------------------------------------------------

interface Props {
  cta: { url: string; title: string };
  bodyText: string;
}
