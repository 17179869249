import { memo } from "react";

import { ResponsiveLine } from "@nivo/line";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";

// -----------------------------------------------------------------------------

export const ChartDisplay = memo(
  ({
    title,
    positiveChangeIsGood = false,
    data = [],
    color = "#F00",
    loading = false,
  }: Props) => {
    const [nextToLast = { y: 0 }, last = { y: 0 }] = data.slice(-2);
    const count = last.y;
    const hasAtLeastOneDatapoint = data.some((entry) => entry.y > 0);

    const changePercentage =
      last.y > 0 ? nextToLast.y / last.y - 1 : nextToLast.y > 0 ? 1 : 0;
    const changePercentageLabel = (changePercentage * 100).toFixed(1);
    const change = last.y - nextToLast.y;

    const ChangeIcon =
      change === 0
        ? HorizontalRuleIcon
        : change < 0
          ? ArrowDownwardIcon
          : ArrowUpwardIcon;
    const changeColor =
      change === 0
        ? "primary"
        : change > 0
          ? positiveChangeIsGood
            ? "success"
            : "error"
          : positiveChangeIsGood
            ? "error"
            : "success";

    const chartData = [
      {
        id: "line",
        data,
      },
    ];

    //
    return (
      <Card>
        <CardContent>
          <Typography variant="overline">{title}</Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5" fontWeight="bold">
              {count}
              {loading ? (
                <>
                  &nbsp;
                  <CircularProgress size={20} />
                </>
              ) : null}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <ChangeIcon color={changeColor} sx={{ mr: 0.25 }} />
              <Typography variant="overline">
                {changePercentageLabel}% ({change})
              </Typography>
            </Box>
          </Box>
        </CardContent>
        {hasAtLeastOneDatapoint ? (
          <Box sx={{ height: 128 }}>
            <ResponsiveLine
              margin={{
                top: 3,
                bottom: 3,
              }}
              lineWidth={6}
              data={chartData as any}
              curve="monotoneX"
              layers={["lines"]}
              colors={[color]}
            />
          </Box>
        ) : null}
      </Card>
    );
  }
);

// -----------------------------------------------------------------------------

interface Props {
  title: string;
  positiveChangeIsGood?: boolean;
  data: Array<{ y: number }>;
  color: string;
  loading?: boolean;
}
