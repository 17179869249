import { memo, useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { ReturnIf } from "babel-plugin-transform-functional-return";
import styled from "styled-components";

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { useOrganizationStore } from "stores/OrganizationStore";

import { captureSentryException } from "utility/capture_sentry_exception";
import { notifyError, notifySuccess } from "utility/notify";

import { SettingsGeneralDCVSectionChooseEmail as ChooseEmail } from "./ChooseEmail";
import { SettingsGeneralDCVSectionEnterValidationCode as EnterValidationCode } from "./EnterValidationCode";

// -----------------------------------------------------------------------------

export const SettingsDomainControlValidation = memo(({ disabled }: Props) => {
  const [organizationStore, organizationActions] = useOrganizationStore();

  //
  const [searchParams, setSearchParams] = useSearchParams();
  const queryCode = searchParams.get("code") ?? "";

  const isConfirmed = !!organizationStore.organization.dcv_isvalid;

  //
  const [isLoading, setIsLoading] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [domain, setDomain] = useState(
    organizationStore.organization.domain ?? ""
  );
  const [code, setCode] = useState(queryCode || "");
  const [showingValidationForm, setShowingValidationForm] = useState(false);

  //
  const onEmailSelect = (email: string) => {
    setEmailAddress(email);
    setDomain(email.split("@")[1]);
    createAndSendDCV(email);
  };

  const createAndSendDCV = (email: string) => {
    (async () => {
      try {
        setIsLoading(true);
        setShowingValidationForm(false);

        //
        await organizationActions.createAndSendDCV(email);

        //
        setIsLoading(false);
        setShowingValidationForm(true);
        notifySuccess(`Sent validation email to ${email}`);
      } catch (error) {
        captureSentryException(error, "Failed to send DCV validation email");
        setIsLoading(false);
        setShowingValidationForm(false);
        notifyError(`There was an error sending the validation email`);
      }
    })();
  };

  const validateDCVConfirmationCode = useCallback(
    function validateDCVConfirmationCode() {
      (async () => {
        ReturnIf(!code);

        try {
          setIsLoading(true);
          setShowingValidationForm(true);

          //
          await organizationActions.validateDCVCode(code);

          //
          setIsLoading(false);
          setShowingValidationForm(true);
          notifySuccess("Domain Control Validation has been enabled");
        } catch (error) {
          captureSentryException(error, "Failed to validate DCV code");
          setIsLoading(false);
          setShowingValidationForm(true);
          notifyError(`There was an error verifying the DCV token`);
        }
      })();
    },
    [setIsLoading, setShowingValidationForm, organizationActions, code]
  );

  function startOver() {
    organizationActions.resetDCV();

    setIsLoading(false);
    setShowingValidationForm(false);
    setCode("");

    //
    const params = searchParams;
    params.delete("code");

    setSearchParams(params.toString());
  }

  useEffect(() => {
    setShowingValidationForm(isConfirmed);
  }, [isConfirmed, setShowingValidationForm]);

  useEffect(() => {
    ReturnIf(isConfirmed || queryCode === "");
    setCode(queryCode);
    setShowingValidationForm(true);
    setTimeout(validateDCVConfirmationCode);

    //
    const params = searchParams;
    params.delete("code");

    setSearchParams(params.toString());
  }, [
    organizationStore,
    isConfirmed,
    queryCode,
    setCode,
    setShowingValidationForm,
    validateDCVConfirmationCode,
    searchParams,
    setSearchParams,
  ]);

  //
  return (
    <Container
      elevation={1}
      sx={ContainerSX}
      className={disabled ? "disabled" : undefined}
    >
      <Typography variant="h6" sx={TitleSX} fontWeight="bold">
        Domain Control Validation (DCV)
      </Typography>

      <Typography variant="body1" sx={SubtitleSX}>
        DCV is a critical security step that helps ensure that only authorized
        users can access your ThreatKey account and data. DCV limits account
        creation to a validated domain. Select one of the email addresses below
        to receive an email with a validation link to quickly and easily verify
        the control of your domain.
      </Typography>

      {showingValidationForm ? (
        <EnterValidationCode
          isLoading={isLoading}
          isConfirmed={isConfirmed}
          domain={domain}
          email={emailAddress}
          code={code}
          setCode={setCode}
          onConfirm={validateDCVConfirmationCode}
          onResend={createAndSendDCV}
          onStartOver={startOver}
        />
      ) : (
        <ChooseEmail
          isLoading={isLoading}
          onSubmit={onEmailSelect}
          disabled={disabled}
        />
      )}
    </Container>
  );
});

// -----------------------------------------------------------------------------

const Container = styled(Paper)`
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

// -----------------------------------------------------------------------------

const ContainerSX = { p: 2, mt: 2 };

const TitleSX = { mb: 1 };

const SubtitleSX = { mb: 2 };

// -----------------------------------------------------------------------------

interface Props {
  disabled: boolean;
}
