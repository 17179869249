import { GoogleWorkspaceConnectPage } from "./Connect";
import { redirectToGoogleWorkspaceConnectPage } from "./connect_handler";
import { GoogleWorkspaceCoverImage } from "./CoverImage";
import { GoogleWorkspaceConnectedExtraDetails } from "./ExtraDetails";

// -----------------------------------------------------------------------------

export { GoogleWorkspaceConnectPage };

export const GoogleWorkspaceAppConfig = {
  name: "google",
  label: "Google Workspace",
  coverImage: GoogleWorkspaceCoverImage,
  onConnect: redirectToGoogleWorkspaceConnectPage,
  onPostOauthConnect: redirectToGoogleWorkspaceConnectPage,
  customExtraDetails: GoogleWorkspaceConnectedExtraDetails,
  searchTerms: [],
};
