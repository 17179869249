import { memo } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// -----------------------------------------------------------------------------

export const PageHeaderBackButton = memo(({ to, children }: Props) => {
  const navigate = useNavigate();

  //
  return (
    <Box>
      <Button onClick={() => navigate(to)}>
        <ArrowBackIcon /> {children}
      </Button>
    </Box>
  );
});

// -----------------------------------------------------------------------------

interface Props {
  to: string;
  children: React.ReactNode;
}
