// Create an Error object for api errors
// -----------------------------------------------------------------------------

export function getApiError(error: any): Error {
  switch (true) {
    case error instanceof Error:
      return error;

    case isSingleMessageError(error):
      return new Error(error.message);

    case isNonFieldMessagesError(error):
      return new Error(error.message.non_field_errors.join("\n"));

    case isDetailError(error):
      return new Error(error.detail);

    case isKindError(error):
      return new Error(error.kind.join("\n"));

    case isSecretError(error):
      return new Error(error.secret.join("\n"));

    case isNonceError(error):
      return new Error(error.nonce.join("\n"));

    case isWebhookUrlError(error):
      return new Error(error.webhook_url.join("\n"));

    case isNameError(error):
      return new Error(error.name.join("\n"));

    default:
      // unknown error type
      return new Error(JSON.stringify(error));
  }
}

// Functions to identify error type
// -----------------------------------------------------------------------------

function isSingleMessageError(error: any): error is SingleMessageError {
  return "message" in error && typeof error.message === "string";
}

function isNonFieldMessagesError(error: any): error is NonFieldMessagesError {
  return "message" in error && "non_field_errors" in error.message;
}

function isDetailError(error: any): error is DetailError {
  return "detail" in error && typeof error.detail === "string";
}

function isKindError(error: any): error is KindError {
  return "kind" in error && Array.isArray(error.kind);
}

function isSecretError(error: any): error is SecretError {
  return "secret" in error && Array.isArray(error.secret);
}

function isNonceError(error: any): error is NonceError {
  return "nonce" in error && Array.isArray(error.nonce);
}

function isWebhookUrlError(error: any): error is WebhookUrlError {
  return "webhook_url" in error && Array.isArray(error.webhook_url);
}

function isNameError(error: any): error is NameError {
  return "name" in error && Array.isArray(error.name);
}

// Error message formats seen from the API
// -----------------------------------------------------------------------------

interface SingleMessageError {
  message: string;
}

interface NonFieldMessagesError {
  message: { non_field_errors: string[] };
}

interface DetailError {
  detail: string;
}

interface KindError {
  kind: string[];
}

interface SecretError {
  secret: string[];
}

interface NonceError {
  nonce: string[];
}

interface WebhookUrlError {
  webhook_url: string[];
}

interface NameError {
  name: string[];
}
