import { memo } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useBillingStore } from "stores/BillingStore";

import { BillingPagePastPaymentsTable as Table } from "./Table";

// -----------------------------------------------------------------------------

export const BillingPagePastPayments = memo(() => {
  const [billingStore] = useBillingStore();

  //
  return !billingStore || billingStore?.subscription?.is_trial ? (
    (null as any)
  ) : (
    <Box sx={ContainerSX}>
      <Typography variant="h5" fontWeight="bold" sx={TitleSX}>
        Past Payments
      </Typography>
      <Table />
    </Box>
  );
});

// -----------------------------------------------------------------------------

const ContainerSX = { mt: 3 };

const TitleSX = { mb: 1 };
