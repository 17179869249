import { useState, useEffect, memo } from "react";

import { ReturnIf } from "babel-plugin-transform-functional-return";

import { useFlairStore } from "stores/FlairStore";

import { TheAudio } from "./lib/audio";

// -----------------------------------------------------------------------------

const TheCode = [
  "ArrowUp",
  "ArrowUp",
  "ArrowDown",
  "ArrowDown",
  "ArrowLeft",
  "ArrowRight",
  "ArrowLeft",
  "ArrowRight",
  "KeyB",
  "KeyA",
].join();

// -----------------------------------------------------------------------------

export const SpecialThing = memo(() => {
  const [, flairActions] = useFlairStore();

  const [playSpecialThing, setPlaySpecialThing] = useState(false);

  //
  useEffect(() => {
    ReturnIf(playSpecialThing);

    //
    const input: string[] = [];
    const onKeyUp = (e: KeyboardEvent) => {
      input.push(e.code);

      if (input.length > 10) {
        input.shift();
      }

      if (input.join() === TheCode) {
        input.length = 0;
        setPlaySpecialThing(true);
        flairActions.startCelebration();
      }
    };

    //
    window.document.addEventListener("keyup", onKeyUp);

    //
    return () => window.document.removeEventListener("keyup", onKeyUp);
  }, [playSpecialThing, flairActions]);

  //
  ReturnIf(!playSpecialThing, null);

  //
  return (
    <audio
      controls={false}
      autoPlay={true}
      onEnded={() => setPlaySpecialThing(false)}
    >
      <source src={TheAudio} />
    </audio>
  );
});
