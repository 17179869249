import { ReturnIf } from "babel-plugin-transform-functional-return";

import { ResponseError } from "data/response_error";

import {
  clearAccessTokens,
  getRefreshToken,
  getCSRFToken,
  setAccessTokens,
  getAccessToken,
} from "utility/credential_storage";
import { sendCrossTabMessage } from "utility/crosstab";

import {
  API_URL,
  DefaultAllowedStatuses,
  getRequestBodyString,
  getResponseJson,
  hasAllowedStatus,
  type Method,
} from "./lib";
import { unauthenticatedPostFetch } from "./unauthenticated";

// -----------------------------------------------------------------------------

export async function authenticatedFetch<T = any>(
  method: Method,
  path: string,
  payload?: any,
  options?: AuthenticatedFetchOptions
): Promise<T> {
  const serverResponse = await fetchWithCredentialRefresh(
    `${API_URL}/${path}`,
    {
      method,
      headers: {
        "Content-Type": "application/json",
        ...getCSRFHeader(options?.csrf as boolean),
        ...getAuthorizationHeaders(),
      },
      ...getRequestBodyString(method, payload),
      signal: options?.abort?.signal,
    },
    options?.dontRetry
  );

  //
  if (
    !hasAllowedStatus(
      options?.allowedStatuses ?? DefaultAllowedStatuses,
      serverResponse.status
    )
  ) {
    throw new ResponseError(
      serverResponse.statusText ?? "There was an error",
      serverResponse.status,
      await getResponseJson<T>(serverResponse, true)
    );
  }

  //
  return await getResponseJson<T>(serverResponse);
}

export async function authenticatedDeleteFetch<T = any>(
  path: string,
  payload?: any,
  options?: AuthenticatedFetchOptions
): Promise<T> {
  return await authenticatedFetch("DELETE", path, payload, options);
}

export async function authenticatedGetFetch<T = any>(
  path: string,
  options?: AuthenticatedFetchOptions
): Promise<T> {
  return await authenticatedFetch("GET", path, undefined, options);
}

export async function authenticatedPatchFetch<T1 = any, T2 = any>(
  path: string,
  payload?: T2,
  options?: AuthenticatedFetchOptions
): Promise<T1> {
  return await authenticatedFetch("PATCH", path, payload, options);
}

export async function authenticatedPostFetch<T = any, T2 = any>(
  path: string,
  payload?: T2,
  options?: AuthenticatedFetchOptions
): Promise<T> {
  return await authenticatedFetch("POST", path, payload, options);
}

export async function authenticatedPutFetch<T = any, T2 = any>(
  path: string,
  payload?: T2,
  options?: AuthenticatedFetchOptions
): Promise<T> {
  return await authenticatedFetch("PUT", path, payload, options);
}

// -----------------------------------------------------------------------------

interface RefreshResponseData {
  access: string;
  refresh: string;
}

let refreshingPromise: Promise<RefreshResponseData> | undefined;

async function refreshAccessToken() {
  // prevent multiple refresh calls
  ReturnIf(refreshingPromise !== undefined, await refreshingPromise);

  // don't refresh if no refresh token (somehow)
  const refreshToken = getRefreshToken();
  ReturnIf(refreshToken === "", clearTokensAndRedirectToLogin());

  //
  refreshingPromise = unauthenticatedPostFetch<RefreshResponseData>(
    "api/token/refresh/",
    {
      refresh: getRefreshToken(),
    }
  );
  const response = await refreshingPromise;
  refreshingPromise = undefined;

  //
  const { access: newCredentialsToken, refresh: newRefreshToken } = response;
  ReturnIf(!newCredentialsToken, clearTokensAndRedirectToLogin());

  //
  setAccessTokens(newCredentialsToken, newRefreshToken);
}

async function fetchWithCredentialRefresh(
  path: string,
  request: RequestInit,
  dontRetry: boolean = false
): Promise<Response> {
  // try
  const initialServerResponse = await fetch(path, request);
  ReturnIf(
    initialServerResponse.status !== 401 || dontRetry,
    initialServerResponse
  );

  // refresh
  await refreshAccessToken();

  // overwrite authorization if present
  const headers = (request.headers as Record<string, string>) ?? {};
  if (headers.Authorization !== undefined) {
    headers.Authorization = `Bearer ${getAccessToken()}`;
  }

  // retry
  return await fetch(path, request);
}

function getAuthorizationHeaders(): { Authorization: string } | {} {
  return { Authorization: `Bearer ${getAccessToken()}` };
}

function getCSRFHeader(useCSRF: boolean): { "X-CSRFToken": string } | {} {
  return useCSRF ? { "X-CSRFToken": getCSRFToken() } : {};
}

function clearTokensAndRedirectToLogin() {
  clearAccessTokens();
  sendCrossTabMessage("logout");
  window.location.href = "/login";
}

// -----------------------------------------------------------------------------

interface AuthenticatedFetchOptions {
  allowedStatuses?: number[];
  csrf?: boolean;
  dontRetry?: boolean;
  abort?: AbortController;
}
