import { ViewMode } from "data/view_mode";

// -----------------------------------------------------------------------------

export function getViewMode() {
  const browserWidth = document.body.getBoundingClientRect().width;

  switch (true) {
    case browserWidth <= 450:
      return ViewMode.Mobile;

    case browserWidth <= 800:
      return ViewMode.Tablet;

    default:
      return ViewMode.Desktop;
  }
}
