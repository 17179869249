import { type Datum, type Serie } from "@nivo/line";
import isEmpty from "lodash/isEmpty";

import { dayjs } from "utility/dayjs";

// -----------------------------------------------------------------------------

const splitToChunks = (array: any[], maxParts: number) => {
  const copyArray = [...array];
  const result = [];

  for (let i = maxParts; i > 0; i--) {
    const splicedArray = copyArray.splice(0, Math.ceil(array.length / i));

    if (splicedArray.length > 0) {
      result.push(splicedArray);
    }
  }

  //
  return result;
};

export const generateTicks = (data: Datum[]): string[] => {
  const maxDates = 5;
  const chunked = splitToChunks(data, maxDates);

  const dates = chunked
    .filter((item) => item !== undefined && (item.length > 0 || !isEmpty(item)))
    .reduce((acc: Datum[], item: Serie[], index: number) => {
      // If it's the first chunk, take the first item
      if (index === 0) {
        acc.push(item[0]);
      }
      // If it's the last chunk, take the last item
      else if (index === chunked.length - 1) {
        acc.push(item[item.length - 1]);
      }
      // Otherwise, take the middle item, so they're spaced out evenly
      else {
        acc.push(item[Math.floor(item.length / 2)]);
      }

      //
      return acc;
    }, [])
    .map((item) => dayjs(item?.timestamp || item?.create_time).format("MM/DD"));

  //
  return dates;
};
