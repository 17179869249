import { memo } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";

import { ServiceConnectionPhase } from "Component/ServiceConnectionPhase";

// -----------------------------------------------------------------------------

export const SnowflakeConfigurePageNavigateOAuthPhase = memo(
  ({ disabled, progressIcon, successIcon, errorIcon, oauthUrl }: Props) => {
    return (
      <ServiceConnectionPhase disabled={disabled}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {progressIcon ? (
            <CircularProgress size="24px" sx={{ mr: 1 }} />
          ) : null}
          {successIcon ? (
            <CheckCircleOutlineRoundedIcon color="success" sx={{ mr: 1 }} />
          ) : null}
          {errorIcon ? (
            <ErrorOutlineRoundedIcon color="error" sx={{ mr: 1 }} />
          ) : null}
          <Typography variant="body1">Connect to Snowflake</Typography>
        </Box>
        <Box>
          <Typography sx={{ mt: 2 }} variant="body1">
            The below button will take you to the Snowflake OAuth page to
            connect ThreatKey to your Snowflake account. You will not be able to
            proceed until you have completed this step.
          </Typography>

          <Button
            sx={{ mt: 2 }}
            variant="contained"
            LinkComponent="a"
            href={oauthUrl}
          >
            Continue to Snowflake OAuth
          </Button>
        </Box>
      </ServiceConnectionPhase>
    );
  }
);

// -----------------------------------------------------------------------------

interface Props {
  disabled: boolean;
  progressIcon: boolean;
  successIcon: boolean;
  errorIcon: boolean;
  oauthUrl: string;
}
