import { memo } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/system/Box";

// -----------------------------------------------------------------------------

export const LoaderModal = memo(() => {
  return (
    <Box sx={{ width: "100%", height: "100%", display: "flex" }}>
      <CircularProgress sx={{ margin: "auto" }} />
    </Box>
  );
});
