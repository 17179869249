import { memo } from "react";

import SvgIcon from "@mui/material/SvgIcon";

// -----------------------------------------------------------------------------

export const GoogleSAMLSSOIcon = memo((props: object) => {
  return (
    <SvgIcon viewBox="0 0 118 28" {...props}>
      <path
        d="M45.3647 15.5181C42.8722 14.8016 41.0558 13.9237 39.9155 12.8843C38.7853 11.8348 38.2202 10.5431 38.2202 9.00928C38.2202 7.2736 38.9115 5.84066 40.2939 4.71045C41.6865 3.57015 43.4928 3 45.7129 3C47.2266 3 48.5737 3.29264 49.7544 3.87793C50.9451 4.46322 51.8634 5.27051 52.5093 6.2998C53.1652 7.3291 53.4932 8.45426 53.4932 9.67529H50.5718C50.5718 8.34326 50.1479 7.29883 49.3003 6.54199C48.4526 5.77507 47.2568 5.3916 45.7129 5.3916C44.2799 5.3916 43.1598 5.70947 42.3525 6.34521C41.5553 6.97087 41.1567 7.84375 41.1567 8.96387C41.1567 9.86198 41.5352 10.6239 42.292 11.2495C43.0589 11.8651 44.3556 12.4302 46.1821 12.9448C48.0187 13.4595 49.4517 14.0296 50.481 14.6553C51.5203 15.2708 52.2873 15.9924 52.7817 16.8198C53.2863 17.6473 53.5386 18.6211 53.5386 19.7412C53.5386 21.5273 52.8423 22.9603 51.4497 24.04C50.0571 25.1097 48.1953 25.6445 45.8643 25.6445C44.3506 25.6445 42.9378 25.3569 41.626 24.7817C40.3141 24.1965 39.3 23.3993 38.5835 22.3901C37.8771 21.381 37.5239 20.2357 37.5239 18.9541H40.4453C40.4453 20.2861 40.9347 21.3407 41.9136 22.1177C42.9025 22.8846 44.2194 23.2681 45.8643 23.2681C47.3981 23.2681 48.5737 22.9552 49.3911 22.3296C50.2085 21.7039 50.6172 20.8512 50.6172 19.7715C50.6172 18.6917 50.2388 17.8592 49.4819 17.2739C48.7251 16.6785 47.3527 16.0933 45.3647 15.5181Z"
        fill="#7F7F7F"
      />
      <path
        d="M69.4473 19.5898H60.2139L58.1401 25.3418H55.1431L63.5591 3.30273H66.1021L74.5332 25.3418H71.5513L69.4473 19.5898ZM61.0918 17.1982H68.5845L64.8306 6.89014L61.0918 17.1982Z"
        fill="#7F7F7F"
      />
      <path
        d="M81.2539 3.30273L88.459 21.2852L95.6641 3.30273H99.4331V25.3418H96.5269V16.7593L96.7993 7.49561L89.564 25.3418H87.3389L80.1187 7.54102L80.4062 16.7593V25.3418H77.5V3.30273H81.2539Z"
        fill="#7F7F7F"
      />
      <path
        d="M107.486 22.9653H117.93V25.3418H104.564V3.30273H107.486V22.9653Z"
        fill="#7F7F7F"
      />
      <g clipPath="url(#clip0)">
        <path
          d="M28 14.3216C28 13.3699 27.9213 12.4131 27.7533 11.4768H14.2808V16.868H21.9959C21.6757 18.6067 20.647 20.1448 19.1408 21.1222V24.6203H23.7436C26.4465 22.1819 28 18.581 28 14.3216Z"
          fill="#4285F4"
        />
        <path
          d="M14.2811 27.9999C18.1334 27.9999 21.3821 26.7601 23.7491 24.6202L19.1463 21.1221C17.8657 21.976 16.2125 22.4596 14.2863 22.4596C10.56 22.4596 7.4005 19.9955 6.26685 16.6826H1.51709V20.2887C3.94183 25.0163 8.88054 27.9999 14.2811 27.9999V27.9999Z"
          fill="#34A853"
        />
        <path
          d="M6.26099 16.6827C5.66267 14.9439 5.66267 13.0612 6.26099 11.3224V7.71631H1.51647C-0.509396 11.6722 -0.509396 16.3329 1.51647 20.2888L6.26099 16.6827V16.6827Z"
          fill="#FBBC04"
        />
        <path
          d="M14.2811 5.54029C16.3174 5.50943 18.2856 6.26049 19.7604 7.63914L23.8383 3.64208C21.2562 1.26545 17.829 -0.0411869 14.2811 -3.31188e-05C8.88053 -3.31188e-05 3.94183 2.98362 1.51709 7.7163L6.2616 11.3224C7.39 8.00438 10.5548 5.54029 14.2811 5.54029V5.54029Z"
          fill="#EA4335"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect fill="white" height="28" width="28"></rect>
        </clipPath>
      </defs>
    </SvgIcon>
  );
});
