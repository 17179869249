import { memo, useState } from "react";

import styled from "styled-components";

import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { type FOTS } from "data/typescript";

// -----------------------------------------------------------------------------

export const ServiceConnectionInstructionPhase = memo(({ children }: Props) => {
  const [expand, setExpand] = useState(false);

  //
  function toggleExpand(e: React.MouseEvent<FOTS, MouseEvent>) {
    e.stopPropagation();
    e.preventDefault();

    //
    setExpand((v) => !v);
  }

  //
  return (
    <Paper
      elevation={1}
      sx={{ p: 1.5, background: "rgba(255, 255, 0, 0.2)" }}
      onClick={expand ? undefined : toggleExpand}
    >
      <Stack spacing={1}>
        <Columns>
          <Typography variant="h6" fontWeight="bold">
            Information &amp; Instructions
          </Typography>
          <Button onClick={toggleExpand}>{expand ? "hide" : "show"}</Button>
        </Columns>
        {expand && children}
      </Stack>
    </Paper>
  );
});

// -----------------------------------------------------------------------------

const Columns = styled.div`
  align-items: center;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr auto;
`;

// -----------------------------------------------------------------------------

interface Props {
  children: React.ReactNode;
}
