import { memo } from "react";

import styled from "styled-components";

import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";

import { formatNumberWithSeparator } from "utility/number";

// -----------------------------------------------------------------------------

export const FindingsPageTitlePane = memo(
  ({ initialLoading = false, totalFindings = 0 }: Props) => {
    return (
      <>
        <Title variant="h4">
          Overview of{" "}
          {initialLoading ? (
            <Skeleton width="130px" />
          ) : (
            <>{formatNumberWithSeparator(Math.ceil(totalFindings))}</>
          )}{" "}
          Findings
        </Title>
      </>
    );
  }
);

// -----------------------------------------------------------------------------

const Title = styled(Typography)`
  font-size: clamp(24px, calc(1.5rem + ((1vw - 3.75px) * 1.2121)), 34px);
  font-weight: bold;
  min-height: 0vw;

  span {
    display: inline-block;
  }
`;

// -----------------------------------------------------------------------------

interface Props {
  initialLoading: boolean;
  totalFindings: number;
}
