import { SlackConfigurePage } from "./Configure";
import { redirectToSlackOauthUrl } from "./connect_handler";
import { SlackCoverImage } from "./CoverImage";

// -----------------------------------------------------------------------------

export { SlackConfigurePage };

export const SlackAppConfig = {
  name: "slack",
  alternativeNames: ["slack_code"],
  label: "Slack",
  coverImage: SlackCoverImage,
  onConnect: redirectToSlackOauthUrl,
  searchTerms: [],
  url: "slack",
  notifications: true,
};
