import { useEffect } from "react";

import { ReturnIf } from "babel-plugin-transform-functional-return";

import { type FOTS } from "data/typescript";

// -----------------------------------------------------------------------------

export function useUpdateTalkWidgetColors() {
  useEffect(ofUpdatingTalkWidgetColors, []);
}

// -----------------------------------------------------------------------------

function ofUpdatingTalkWidgetColors() {
  function updateTalkWidgetColors() {
    const parent: FOTS = document.body
      .querySelector("#ap3-talk-widget-ui")
      ?.shadowRoot?.querySelector("#__root");
    ReturnIf(parent === undefined);

    // set talk button to white
    parent.style.setProperty("--brand-action-rgb", "255,255,255");

    // set speech bubble icons to purple
    parent.lastChild
      .querySelector("svg")
      ?.style?.setProperty("color", "#643ad1");
  }

  //
  let observer: MutationObserver = null as FOTS;

  function createTalkWidgetObserver() {
    const target: FOTS = document.body
      .querySelector("#ap3-talk-widget-ui")
      ?.shadowRoot?.querySelector("#__root");

    observer = new MutationObserver(updateTalkWidgetColors);
    observer.observe(target, {
      childList: true,
      subtree: true,
    });

    //
    return observer;
  }

  function createWaitObserver() {
    const observer = new MutationObserver(() => {
      ReturnIf(document.body.querySelector("#ap3-talk-widget-ui") === null);

      updateTalkWidgetColors();
      createTalkWidgetObserver();
    });
    observer.observe(document.body, {
      childList: true,
    });

    //
    return observer;
  }

  //
  updateTalkWidgetColors();

  //
  // Prevent the color swap flash on page load
  //
  // You don't tell me what I can't do.
  // I tell you what to do.
  //
  observer =
    document.body.querySelector("#ap3-talk-widget-ui") === null
      ? createWaitObserver()
      : createTalkWidgetObserver();

  //
  return () => {
    observer?.disconnect();
  };
}
