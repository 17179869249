import { memo, useState } from "react";

import { ReturnIf } from "babel-plugin-transform-functional-return";

import { type FindingSeverity, type ServerFinding } from "data/finding";
import { type FOTS } from "data/typescript";

import { alterFindingsSeverity } from "model/finding";

import { asyncOrSwimWithSentry } from "utility/async_or_swim_sentry";
import { notifyError, notifySuccess } from "utility/notify";
import { pluralize, pluralizeCustom } from "utility/pluralize";

import { SelectedFindingsList } from "Component/SelectedFindingsList";

import { ModalActions as Actions } from "../Component/Actions";
import { ModalCancelButton as CancelButton } from "../Component/CancelButton";
import { ModalFullHeightContent as FullHeightContent } from "../Component/FullHeightContent";
import { FullHeightModal } from "../Component/FullHeightModal";
import { ModalSubContent as SubContent } from "../Component/SubContent";
import { ModalSubmitButton as SubmitButton } from "../Component/SubmitButton";
import { ModalTitle as Title } from "../Component/Title";

import { FindingSeveritySelector } from "./FindingSeveritySelector";

// -----------------------------------------------------------------------------

export const AlterFindingsSeverityModal = memo(
  ({ findings, onClose }: Props) => {
    const [severity, setSeverity] = useState<FindingSeverity>(
      undefined as FOTS
    );
    const [loading, setLoading] = useState(false);

    //
    function onSave() {
      setLoading(true);
      asyncOrSwimWithSentry(
        async () => {
          ReturnIf(severity === undefined);

          //
          await alterFindingsSeverity(
            findings.map((finding) => finding.uuid),
            severity
          );

          //
          findings.forEach((finding) => {
            finding.severity = severity;
            finding.customer_overridden_severity = severity;
          });

          //
          notifySuccess(
            pluralizeCustom(
              findings.length,
              `Updated the severity of the finding`,
              `Updated the severity of ${findings.length} findings`
            )
          );
          setLoading(false);
          onClose(true, severity);
        },
        "Failed to update finding severity",
        (error) => {
          console.error(error);
          notifyError(
            pluralizeCustom(
              findings.length,
              "Something went wrong updating the severity of the finding",
              `Something went wrong updating the severity of ${pluralize(
                findings.length,
                "finding"
              )}`
            )
          );
          setLoading(false);
        }
      );
    }

    function onDone() {
      onClose(false);
    }

    //
    return (
      <FullHeightModal onClose={loading ? undefined : onDone}>
        <Title>
          {pluralizeCustom(
            findings.length,
            "Edit severity of this finding?",
            `Edit severity of ${findings.length} findings?`
          )}
        </Title>

        <FullHeightContent>
          <SelectedFindingsList findings={findings} />
        </FullHeightContent>

        <SubContent>
          <FindingSeveritySelector severity={severity} onChange={setSeverity} />
        </SubContent>

        <Actions>
          <CancelButton disabled={loading} onClick={onDone} />

          <SubmitButton
            disabled={severity === undefined}
            loading={loading}
            onClick={onSave}
          >
            Update Severity
          </SubmitButton>
        </Actions>
      </FullHeightModal>
    );
  }
);

// -----------------------------------------------------------------------------

interface Props {
  findings: ServerFinding[];
  onClose: (success?: boolean, severity?: FindingSeverity) => void;
}
