import { memo, useContext } from "react";

import { ReturnIf } from "babel-plugin-transform-functional-return";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import AutomaticModeIcon from "@mui/icons-material/Brightness4";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";

import { ThemeContext, type ThemeMode } from "theme/context";

import { mod } from "utility/math";

// -----------------------------------------------------------------------------

const Icons = [AutomaticModeIcon, DarkModeIcon, LightModeIcon];

// -----------------------------------------------------------------------------

export const ToggleThemeButton = memo(() => {
  const { themeMode, setThemeMode } = useContext(ThemeContext);

  //
  function toggleMode() {
    setThemeMode(toNextValue(themeMode));
  }

  //
  const Icon = Icons[themeMode] ?? AutomaticModeIcon;
  const getColor = () => {
    ReturnIf(themeMode === 0, "Automatic Mode");
    ReturnIf(themeMode === 1, "Dark Mode");
    ReturnIf(themeMode === 2, "Light Mode");
    return "";
  };
  //
  return (
    <Tooltip title={getColor()}>
      <IconButton
        size="large"
        aria-label="modeType"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        color="inherit"
        onClick={() => toggleMode()}
      >
        <Icon />
      </IconButton>
    </Tooltip>
  );
});

// -----------------------------------------------------------------------------

function toNextValue(themeMode: ThemeMode): ThemeMode {
  return mod(themeMode + 1, 3) as ThemeMode;
}
