import { memo } from "react";

import {
  ResponsiveScatterPlot,
  type ScatterPlotDatum,
  type ScatterPlotNodeData,
  type ScatterPlotRawSerie,
  type ScatterPlotValue,
} from "@nivo/scatterplot";
import { ReturnIf } from "babel-plugin-transform-functional-return";
import { useTheme } from "styled-components";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import {
  FindingSeverityLabel,
  FindingSeverityNameToIntMap,
  SeverityToColor,
} from "data/finding";
import { type ModalOnCloseCallback } from "data/modal";

import { type StaleFindingDatum } from "../../lib/get_scatter_data";

// -----------------------------------------------------------------------------

export const SCATTER_PLOT_ID = "iam-scatterplot-expanded";

// -----------------------------------------------------------------------------

export const StaleScatterPlotModal = memo(
  ({ scatterData, title, onClose }: Props) => {
    //
    const theme = useTheme();

    const scatterplotThemeing = {
      axis: {
        legend: {
          text: {
            fill: theme.scatterPlot.text,
            fontSize: 20,
          },
        },
        ticks: {
          line: {
            stroke: theme.scatterPlot.text,
          },
          text: {
            fontSize: 14,
            fill: theme.scatterPlot.text,
          },
        },
      },
      grid: {
        line: {
          stroke: "#AAAAAA",
          strokeWidth: 0.5,
        },
      },
    };

    const toolTipColor = theme.scatterPlot.toolTipColor;
    const tooltipBackground = theme.scatterPlot.toolTipBackground;
    const hasAtLeastOneDatapoint = scatterData.some(
      (category: ScatterPlotRawSerie<ScatterPlotDatum>) =>
        category.data.length > 0
    );

    ReturnIf(!hasAtLeastOneDatapoint, null);

    // dynamic size function, the size of the node is bigger if there are more hits
    const getNodeSize = (
      node: Omit<ScatterPlotNodeData<StaleFindingDatum>, "size" | "color">
    ) => {
      // Scale the node a bit so small counts are still visible on the chart
      return node.data.count * 2 + 10;
    };

    //
    return (
      <Modal
        open
        onClose={onClose as ModalOnCloseCallback}
        sx={{ display: "flex", overflowY: "auto", padding: 16 }}
      >
        <Paper
          sx={{
            padding: "16px",
            maxWidth: "90vw",
            height: "475px",
            width: "100%",
            "@media screen and (max-width:640px)": {
              borderRadius: 0,
              height: "100%",
              overflowY: "auto",
              width: "100%",

              "& .MuiBox-root": {
                flexDirection: "column",
              },
            },
          }}
        >
          <Typography variant="overline">{title}</Typography>
          <Box
            sx={{
              height: 400,
              borderRadius: "3px",
            }}
            id={SCATTER_PLOT_ID}
          >
            <ResponsiveScatterPlot
              theme={scatterplotThemeing}
              data={scatterData}
              colors={(node) =>
                SeverityToColor[FindingSeverityNameToIntMap[node.serieId]]
              }
              margin={{ top: 40, right: 40, bottom: 70, left: 110 }}
              layers={[
                "grid",
                "axes",
                "nodes",
                "markers",
                "mesh",
                "legends",
                "annotations",
              ]}
              useMesh={true}
              isInteractive={true}
              nodeSize={getNodeSize}
              yScale={{ type: "linear", min: 0, max: 5 }}
              xScale={{ type: "linear", min: 30 }}
              enableGridX={false}
              blendMode={theme.scatterPlot.blendMode}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickValues: 14,
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Age (days)",
                legendPosition: "middle",
                legendOffset: 50,
              }}
              gridYValues={[0, 1, 2, 3, 4, 5]}
              axisLeft={{
                tickValues: [0, 1, 2, 3, 4, 5],
                format: (y: ScatterPlotValue) =>
                  FindingSeverityLabel[y as number],
                tickSize: 1,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Severity",
                legendPosition: "middle",
                legendOffset: -90,
              }}
              tooltip={({ node }) => (
                <div
                  style={{
                    color: toolTipColor,
                    background: tooltipBackground,
                    padding: "12px 16px",
                  }}
                >
                  {`Age (days): ${node.formattedX}`}
                  <br />
                  {`Severity: ${
                    FindingSeverityLabel[node.formattedY as number]
                  }`}
                  <br />
                  {`Count: ${node.data.count}`}
                </div>
              )}
            />
          </Box>
        </Paper>
      </Modal>
    );
  }
);

// -----------------------------------------------------------------------------

interface Props {
  scatterData: Array<ScatterPlotRawSerie<StaleFindingDatum>>;
  title: JSX.Element;
  onClose: Function;
}
