import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { setUser as setSentryUser } from "@sentry/react";
import { ReturnIf } from "babel-plugin-transform-functional-return";

import { type ApiMeGetResponse } from "data/api";

import { useBillingStore, type BillingStoreActions } from "stores/BillingStore";
import { useOrganizationStore } from "stores/OrganizationStore";
import { useUserStore } from "stores/UserStore";

import { getAccount } from "model/account";

import { asyncOrSwim } from "utility/async_or_swim";
import { captureSentryExceptionUnless } from "utility/capture_sentry_exception_unless";
import { getAccessToken, getRefreshToken } from "utility/credential_storage";
import { sendCrossTabMessage } from "utility/crosstab";
import { authenticatedGetFetch } from "utility/fetch/authenticated";
import { userCanAccessBilling } from "utility/user";

// -----------------------------------------------------------------------------

const ErrorPageRegex = /^\/misc(\/error)?/;

// -----------------------------------------------------------------------------

export function useRequiredData() {
  const navigate = useNavigate();

  const [, userActions] = useUserStore();
  const [, organizationActions] = useOrganizationStore();
  const [, billingActions] = useBillingStore();

  const [ready, setReady] = useState(false);

  //
  useEffect(() => {
    ReturnIf(ready);

    //
    function logout() {
      userActions.clear();
      isNotAnErrorPage() && isNotMagicLinkPage() && navigate("/login");
      setReady(true);
      sendCrossTabMessage("logout");
    }

    //
    asyncOrSwim(
      async () => {
        ReturnIf(!getAccessToken(), logout());

        //
        const account = await loadAccount();
        userActions.set(account);

        const [organization] = await Promise.all([
          loadOrganization(account.organization),
          loadBilling(account, billingActions),
        ]);
        organizationActions.set(account.organization, organization);

        //
        setReady(true);
        sendCrossTabMessage("login");
      },
      (error) => {
        console.error(error);
        logout();
      }
    );
  }, [ready, userActions, organizationActions, billingActions, navigate]);

  //
  return ready;
}

// -----------------------------------------------------------------------------

async function loadAccount(): Promise<ApiMeGetResponse> {
  try {
    const account = await getAccount(getRefreshToken() === "");

    //
    setSentryUser({
      email: account.email,
      username: account.full_name,
    });

    //
    const [first, ...last] = account.full_name.split(" ");
    window.ap3c.track?.({
      v: 0,
      email: account.email,
      first: first.trim(),
      last: last.join(" ").trim(),
    });

    //
    window?.Beacon?.("identify", {
      name: account.full_name,
      email: account.email,
      company: account.organization,
    });

    //
    return account;
  } catch (error) {
    captureSentryExceptionUnless(error, "Failed to load account", [401]);

    throw error;
  }
}

// -----------------------------------------------------------------------------

async function loadOrganization(uuid: string) {
  try {
    return await authenticatedGetFetch(`api/organization/${uuid}`);
  } catch (error) {
    captureSentryExceptionUnless(error, "Failed to load organization", [403]);

    throw error;
  }
}

// -----------------------------------------------------------------------------

async function loadBilling(
  user: ApiMeGetResponse,
  billingActions: BillingStoreActions
) {
  return userCanAccessBilling(user)
    ? await billingActions.load()
    : await billingActions.loadRestricted();
}

// -----------------------------------------------------------------------------

function isNotAnErrorPage(): boolean {
  return !ErrorPageRegex.test(window.location.pathname);
}

function isNotMagicLinkPage(): boolean {
  return window.location.pathname !== "/magic_link";
}
