import { memo } from "react";

import { LinkTab } from "Component/LinkTab";
import { Page } from "Component/Page";
import { PageContent } from "Component/PageContent";
import { PageHeader } from "Component/PageHeader";
import { PageTabs } from "Component/PageTabs";
import { PageTitle } from "Component/PageTitle";

import { EnvironmentDestinations as Destinations } from "./Destinations";
import { EnvironmentSources as Sources } from "./Sources";

// -----------------------------------------------------------------------------

export const EnvironmentPage = memo(({ tab }: Props) => {
  const moduleId = getModuleId(tab);

  //
  return (
    <Page>
      <PageHeader hasTabs>
        <PageTitle>Environment</PageTitle>

        <PageTabs value={moduleId}>
          <LinkTab label="Sources" to="/environment/sources" />
          <LinkTab label="Destinations" to="/environment/destinations" />
        </PageTabs>
      </PageHeader>

      <PageContent>{renderTab(moduleId)}</PageContent>
    </Page>
  );
});

// -----------------------------------------------------------------------------

function renderTab(value: number) {
  switch (value) {
    case 1:
      return <Destinations />;

    default:
      return <Sources />;
  }
}

function getModuleId(module: string): number {
  switch (module) {
    case "destinations":
      return 1;

    default:
      return 0;
  }
}

// -----------------------------------------------------------------------------

interface Props {
  tab: "sources" | "destinations";
}
