import { AzureConnectPage } from "./Connect";
import { redirectToAzureConnectPage } from "./connect_handler";
import { AzureCoverImage } from "./CoverImage";

// -----------------------------------------------------------------------------

export { AzureConnectPage };

export const AzureAppConfig = {
  name: "azure_sp",
  label: "Microsoft Azure",
  coverImage: AzureCoverImage,
  onConnect: redirectToAzureConnectPage,
  searchTerms: ["azure", "microsoft azure"],
};
