import { memo, type MouseEventHandler, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { ServiceConnectionPhase } from "Component/ServiceConnectionPhase";

// -----------------------------------------------------------------------------

const SnowflakeBaseUrlRegex =
  /(https:\/\/[a-zA-Z0-9-.]+\.snowflakecomputing\.com\/?)/;

// -----------------------------------------------------------------------------

export const SnowflakeConnectPageUserPassPhase = memo(
  ({
    disabled,
    baseUrl,
    username,
    password,
    onChangeBaseUrl,
    onChangeUsername,
    onChangePassword,
    onNext,
  }: Props) => {
    const [errorUrl, setErrorUrl] = useState("");
    const [errorUsername, setErrorUsername] = useState("");
    const [errorPassword, setErrorPassword] = useState("");

    function checkSnowflakeUrl(newUrl: string) {
      return SnowflakeBaseUrlRegex.test(newUrl);
    }

    //
    return (
      <ServiceConnectionPhase disabled={disabled}>
        <Stack spacing={1.5} direction="column">
          <Typography variant="body1">
            Please provide the <strong>Account URL</strong>, the{" "}
            <strong>Username</strong> and the <strong>Password</strong>{" "}
            associated with the Snowflake account you&apos;d like to connect to
            ThreatKey.
          </Typography>
          <TextField
            fullWidth
            label="Snowflake Account URL"
            placeholder="https://your-unique-url.snowflakecomputing.com"
            value={baseUrl}
            onChange={(event) => {
              const newUrl = event.target.value.trim();

              onChangeBaseUrl(event.target.value);
              setErrorUrl(
                checkSnowflakeUrl(newUrl) ? "" : "Invalid Snowflake URL"
              );
            }}
            error={errorUrl !== ""}
            helperText={errorUrl}
            data-private="lipsum"
          />
          <TextField
            fullWidth
            label="Snowflake Username"
            value={username}
            onChange={(event) => {
              const newUsername = event.target.value.trim();

              onChangeUsername(event.target.value);
              setErrorUsername(newUsername ? "" : "Required");
            }}
            error={errorUsername !== ""}
            helperText={errorUsername}
            data-private="lipsum"
          />
          <TextField
            fullWidth
            label="Snowflake Password"
            placeholder="00q2vRRmTYDHid5..."
            value={password}
            onChange={(event) => {
              onChangePassword(event.target.value);
              setErrorPassword("");
            }}
            error={errorPassword !== ""}
            helperText={errorPassword}
            data-private="lipsum"
            type="password"
          />
        </Stack>

        <Box sx={{ flexGrow: 0, mt: 2.5 }}>
          <Tooltip
            arrow
            title={
              !!errorUsername || !!errorPassword || !!errorUrl
                ? "Please fill the form out correctly"
                : ""
            }
          >
            <span>
              <Button
                disabled={!!errorUsername || !!errorPassword || !!errorUrl}
                variant="contained"
                onClick={onNext as MouseEventHandler<HTMLButtonElement>}
              >
                Connect to Snowflake
              </Button>
            </span>
          </Tooltip>
        </Box>
      </ServiceConnectionPhase>
    );
  }
);

// -----------------------------------------------------------------------------

interface Props {
  disabled: boolean;
  baseUrl: string;
  username: string;
  password: string;
  onChangeBaseUrl: Function;
  onChangeUsername: Function;
  onChangePassword: Function;
  onNext: Function;
}
