import { type Action, createHook, createStore } from "react-sweet-state";

import { ReturnIf } from "babel-plugin-transform-functional-return";

import { authenticatedPostFetch } from "utility/fetch/authenticated";

// -----------------------------------------------------------------------------

export enum PagerDutyProgress {
  Unknown,
  Submitting,
  ErrorPagerDutyParam,
  Error,
  Success,
}

// -----------------------------------------------------------------------------

const initialState: PagerDutyStoreState = {
  progress: PagerDutyProgress.Unknown,
  listProgress: PagerDutyProgress.Unknown,
  services: [],
  connectionId: "",
};

// -----------------------------------------------------------------------------

export const usePagerDutyStore = createHook(
  createStore({
    initialState,
    actions: {
      submitParams,
    },
  })
);

// -----------------------------------------------------------------------------

function submitParams(config: string | null): Action<PagerDutyStoreState> {
  return async ({ setState }) => {
    ReturnIf(
      !config,
      setState({
        progress: PagerDutyProgress.ErrorPagerDutyParam,
        errorDetail: "config is missing or empty",
      })
    );

    setState({ progress: PagerDutyProgress.Submitting });

    let configDict;
    try {
      configDict = JSON.parse(config as string);
    } catch (error) {
      return setState({
        progress: PagerDutyProgress.ErrorPagerDutyParam,
        errorDetail: "config is not valid JSON",
      });
    }

    const { account } = configDict;
    ReturnIf(
      !account,
      setState({
        progress: PagerDutyProgress.ErrorPagerDutyParam,
        errorDetail: "account key is not found in config",
      })
    );

    const { integration_keys: integrationKeys } = configDict;
    ReturnIf(
      !integrationKeys,
      setState({
        progress: PagerDutyProgress.ErrorPagerDutyParam,
        errorDetail: "integration_keys key is not found in config",
      })
    );

    const { name: pdOrgName, subdomain } = account;
    ReturnIf(
      !pdOrgName,
      setState({
        progress: PagerDutyProgress.ErrorPagerDutyParam,
        errorDetail: "Name key is not found in account config",
      })
    );
    ReturnIf(
      !subdomain,
      setState({
        progress: PagerDutyProgress.ErrorPagerDutyParam,
        errorDetail: "subdomain key is not found in account config",
      })
    );

    //
    try {
      const responsePayload = await authenticatedPostFetch(
        "api/organization/connections/pagerduty",
        {
          integration_keys: integrationKeys,
          pd_org_name: pdOrgName,
          subdomain,
        }
      );

      return responsePayload.connection_uuid === undefined
        ? setState({
            progress: PagerDutyProgress.Error,
            errorDetail: JSON.stringify(responsePayload),
          })
        : setState({
            progress: PagerDutyProgress.Success,
            errorDetail: undefined,
            connectionId: responsePayload.connection_uuid,
          });
    } catch (error) {
      setState({
        progress: PagerDutyProgress.Error,
        errorDetail: (error as Error)?.message ?? "There was an error",
      });
    }
  };
}

// -----------------------------------------------------------------------------

interface PagerDutyStoreState {
  progress: PagerDutyProgress;
  errorDetail?: string;
  listProgress: PagerDutyProgress;
  services: string[];
  connectionId: string;
}
