import { memo } from "react";

import { ResponsiveLine, type Serie } from "@nivo/line";
import { useTheme } from "styled-components";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { type ModalOnCloseCallback } from "data/modal";

import { generateTicks } from "utility/charting_utils";

// -----------------------------------------------------------------------------

export const OPEN_LINE_CHART_ID = "risk-linechart-open-findings-expanded";
export const CLOSE_LINE_CHART_ID = "risk-linechart-close-findings-expanded";

// -----------------------------------------------------------------------------

export const LineChartModal = memo(
  ({ data, title, onClose, isOpen, isResolved }: Props) => {
    const hasAtLeastOneDatapoint = data.some(
      (category: Serie) => category.data.length > 0
    );
    const theme = useTheme();
    //

    const responsiveLineTheme = {
      axis: {
        legend: {
          text: {
            fill: theme.scatterPlot.text,
            fontSize: 20,
          },
        },
        ticks: {
          line: {
            stroke: theme.scatterPlot.text,
          },
          text: {
            fontSize: 14,
            fill: theme.scatterPlot.text,
          },
        },
      },
      grid: {
        line: {
          stroke: "#AAAAAA",
          strokeWidth: 0.5,
        },
      },
    };
    return hasAtLeastOneDatapoint ? (
      <Modal
        open={isOpen as any}
        onClose={onClose as ModalOnCloseCallback}
        sx={{ display: "flex", overflowY: "auto", padding: 16 }}
      >
        <Paper
          sx={{
            padding: "16px",
            maxWidth: "90vw",
            height: "475px",
            width: "100%",
            "@media screen and (max-width:640px)": {
              borderRadius: 0,
              height: "100%",
              overflowY: "auto",
              width: "100%",

              "& .MuiBox-root": {
                flexDirection: "column",
              },
            },
          }}
        >
          <Typography variant="overline">{title}</Typography>
          <Box
            sx={{ height: 400 }}
            id={!isResolved ? OPEN_LINE_CHART_ID : CLOSE_LINE_CHART_ID}
          >
            <ResponsiveLine
              theme={responsiveLineTheme}
              data={data}
              enablePoints={true}
              areaBlendMode="multiply"
              areaOpacity={0.9}
              pointSize={4}
              colors={
                !isResolved
                  ? [theme.responsiveLine.isOpen]
                  : [theme.responsiveLine.isClosed]
              }
              margin={{ top: 2, right: 40, bottom: 35, left: 60 }}
              yScale={{ type: "linear", min: "auto", max: "auto" }}
              enableGridX={false}
              axisBottom={{
                tickValues: generateTicks(data[0].data),
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
              }}
              axisLeft={{
                tickValues: 2,
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
              }}
              pointColor={{ theme: "background" }}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabelYOffset={-12}
              useMesh={true}
              defs={[
                {
                  id: "gradientOpen",
                  type: "linearGradient",
                  colors: [
                    { offset: 0, color: theme.responsiveLine.gradientOpenRed },
                    {
                      offset: 100,
                      color: theme.responsiveLine.gradientOpenDark,
                    },
                  ],
                },
                {
                  id: "gradientResolved",
                  type: "linearGradient",
                  colors: [
                    {
                      offset: 0,
                      color: theme.responsiveLine.gradientResolvedGreen,
                    },
                    {
                      offset: 100,
                      color: theme.responsiveLine.gradientResolvedDark,
                    },
                  ],
                },
              ]}
              fill={[
                {
                  match: "*",
                  id: isOpen ? "gradientOpen" : "gradientResolved",
                },
              ]}
              tooltip={({ point }) => (
                <div
                  style={{
                    color: theme.scatterPlot.toolTipColor,
                    background: theme.scatterPlot.toolTipBackground,
                    padding: "12px 16px",
                  }}
                >
                  {`Date: ${point.data.xFormatted}`}
                  <br />
                  {`Findings: ${point.data.yFormatted}`}
                </div>
              )}
            />
          </Box>
        </Paper>
      </Modal>
    ) : null;
  }
);

// -----------------------------------------------------------------------------

interface Props {
  data: Serie[];
  title: JSX.Element | string;
  onClose: Function;
  isOpen?: boolean;
  isResolved: boolean;
}
