import { memo, useEffect, useState } from "react";

import { ReturnIf } from "babel-plugin-transform-functional-return";
import styled from "styled-components";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { useConnectionStore } from "stores/ConnectionStore";
import { ActionState, SecretState } from "stores/lib";

import {
  getConnectedServices,
  type ConnectedService,
} from "utility/connections";
import { classIf, classes } from "utility/style";

import { ExternalSources } from "external/sources";

import { EnvironmentConnectedService } from "Component/ConnectedService";

import { GhostEnvironmentConnectedSources as Loading } from "./Loading";

// -----------------------------------------------------------------------------

export const EnvironmentConnectedSources = memo(() => {
  const [store] = useConnectionStore();

  const [connectedSources, setConnectedSources] = useState<ConnectedService[]>(
    []
  );
  const [failedConnections, setFailedConnections] = useState<
    ConnectedService[]
  >([]);
  const [pendingConnections, setPendingConnections] = useState<
    ConnectedService[]
  >([]);
  const [expansionTypes, setExpansionTypes] = useState({
    connectedExpand: true,
    failedExpand: true,
    pendingExpand: true,
  });

  //
  useEffect(() => {
    const [connected, failed, pending] = [[], [], []] as [
      ConnectedService[],
      ConnectedService[],
      ConnectedService[],
    ];
    getConnectedServices(store.connections, ExternalSources.services).forEach(
      (element) => {
        switch (element?.connection?.clientSecret?.state) {
          case SecretState.CORRECT:
            connected.push(element);
            break;
          case SecretState.ERROR:
          case SecretState.INCORRECT:
            failed.push(element);
            break;
          default:
            pending.push(element);
        }
      }
    );
    setConnectedSources(connected);
    setFailedConnections(failed);
    setPendingConnections(pending);
  }, [store.connections]);

  const RenderPendingSources = () => {
    ReturnIf(pendingConnections.length === 0, <></>);
    return (
      <Container
        onClick={() =>
          setExpansionTypes((prevVal) => {
            return {
              ...prevVal,
              pendingExpand: !prevVal.pendingExpand,
            };
          })
        }
        className={classes(classIf(expansionTypes.pendingExpand, "expanded"))}
      >
        <Typography gutterBottom variant="h5" fontWeight="bold">
          <Arrow /> Pending Sources ({pendingConnections.length})
        </Typography>
        <Expanded onClick={(event) => event.stopPropagation()}>
          {expansionTypes.pendingExpand && (
            <Stack
              direction="column"
              spacing={1}
              style={{ marginBottom: "7px" }}
            >
              {pendingConnections.map(({ connection, service }) => {
                return (
                  <EnvironmentConnectedService
                    connection={connection}
                    service={service}
                    key={connection.uuid}
                  />
                );
              })}
            </Stack>
          )}
        </Expanded>
      </Container>
    );
  };
  const RenderConnectedSources = () => {
    ReturnIf(connectedSources.length === 0, <></>);
    return (
      <Container
        onClick={() =>
          setExpansionTypes((prevVal) => {
            return {
              ...prevVal,
              connectedExpand: !prevVal.connectedExpand,
            };
          })
        }
        className={classes(classIf(expansionTypes.connectedExpand, "expanded"))}
      >
        <Typography gutterBottom variant="h5" fontWeight="bold">
          <Arrow /> Connected Sources ({connectedSources.length})
        </Typography>
        <Expanded onClick={(event) => event.stopPropagation()}>
          {" "}
          {expansionTypes.connectedExpand && (
            <Stack
              direction="column"
              spacing={1}
              style={{ marginBottom: "7px", cursor: "default" }}
            >
              {connectedSources.map(({ connection, service }) => {
                return (
                  <EnvironmentConnectedService
                    connection={connection}
                    service={service}
                    key={connection.uuid}
                  />
                );
              })}
            </Stack>
          )}
        </Expanded>
      </Container>
    );
  };
  const RenderFailedSources = () => {
    ReturnIf(failedConnections.length === 0, <></>);
    return (
      <Container
        onClick={() =>
          setExpansionTypes((prevVal) => {
            return {
              ...prevVal,
              failedExpand: !prevVal.failedExpand,
            };
          })
        }
        className={classes(classIf(expansionTypes.failedExpand, "expanded"))}
      >
        <Typography gutterBottom variant="h5" fontWeight="bold">
          <Arrow /> Faulty Connection Sources ({failedConnections.length})
        </Typography>
        <Expanded onClick={(event) => event.stopPropagation()}>
          {expansionTypes.failedExpand && (
            <Stack direction="column" spacing={1}>
              {failedConnections.map(({ connection, service }) => {
                return (
                  <EnvironmentConnectedService
                    connection={connection}
                    service={service}
                    key={connection.uuid}
                  />
                );
              })}
            </Stack>
          )}
        </Expanded>
      </Container>
    );
  };
  return (
    <>
      {store.loadConnections.state === ActionState.IN_PROGRESS ? (
        <Loading />
      ) : (
        <>
          <RenderConnectedSources />
          <RenderFailedSources />
          <RenderPendingSources />
        </>
      )}
    </>
  );
});
const Arrow = styled(KeyboardArrowRightIcon)`
  margin-top: 0px;
  transition: 500ms ease;
`;

const Expanded = styled(Box)`
  transition: 500ms ease;
`;
const Container = styled(Box)`
  background: ${(props) => props.theme.connectAndDestDropDownColor.main};
  border-radius: 20px;
  cursor: pointer;
  margin-bottom: 2vh;
  margin-top: 8px;
  padding: 1.5rem;
  position: relative;

  &.expanded {
    ${Arrow} {
      transform: rotateZ(90deg);
    }
  }
`;
