import { memo } from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { useConnectionStore } from "stores/ConnectionStore";
import { ActionState } from "stores/lib";
import { useOrganizationStore } from "stores/OrganizationStore";
import { useUserStore } from "stores/UserStore";

import {
  ExternalDestinations,
  getAvailableDestinations,
} from "external/destinations";

import { AvailableConnection } from "Component/AvailableConnection";

import { GhostEnvironmentPossibleDestinations as Loading } from "./Loading";

// -----------------------------------------------------------------------------

export const EnvironmentPossibleDestinations = memo(() => {
  const [store] = useConnectionStore();
  const [userStore] = useUserStore();
  const [orgStore] = useOrganizationStore();

  const possibleDestinations = getAvailableDestinations(
    ExternalDestinations.services,
    orgStore,
    userStore
  ).slice(0, 8);

  //
  switch (true) {
    case store.loadConnections.state === ActionState.IN_PROGRESS:
      return (
        <Grid container spacing={1}>
          <Loading />
        </Grid>
      );

    case possibleDestinations.length > 0:
      return (
        <Grid container spacing={1}>
          {possibleDestinations.map((integration) => {
            return (
              <AvailableConnection
                connection={integration as any}
                connectionType="destination"
                key={integration.name}
              />
            );
          })}
        </Grid>
      );

    default:
      return (
        <Typography variant="body1">
          You&apos;ve connected to all destinations.
        </Typography>
      );
  }
});
