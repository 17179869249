import { type JSXElementConstructor, type ReactElement, memo } from "react";

import styled from "styled-components";

import Button from "@mui/material/Button";
import MuiModal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// -----------------------------------------------------------------------------

export const ConfirmationModal = memo(
  ({
    headerContainer: Header = HeaderDefault,
    bodyContainer: Body = BodyDefault,
    confirmLabel = <>Yes</>,
    cancelLabel = <>Cancel</>,
    onClose,
    children,
  }: Props) => {
    function confirm() {
      onClose(1);
    }

    function cancel() {
      onClose(0);
    }

    //
    return (
      <Modal open onClose={cancel}>
        <Content>
          <Header>{children[0]}</Header>

          <Body>{children[1]}</Body>

          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <Button onClick={confirm} variant="contained">
              {confirmLabel}
            </Button>
            <Button onClick={cancel} variant="contained">
              {cancelLabel}
            </Button>
          </Stack>
        </Content>
      </Modal>
    );
  }
);

// -----------------------------------------------------------------------------

const Modal = styled(MuiModal)`
  display: flex;
`;

const Content = styled(Paper)`
  margin: auto;
  max-width: min(50vw, 512px);
  padding: 16px;

  @media screen and (max-width: 600px) {
    max-width: 75vw;
  }
`;

const HeaderDefault = styled(Typography).attrs(() => ({
  variant: "h6",
}))``;

const BodyDefault = styled(Typography).attrs(() => ({
  variant: "body1",
}))``;

// -----------------------------------------------------------------------------

interface Props {
  children: [ReactElement, ReactElement];
  headerContainer?: JSXElementConstructor<any>;
  headerLabel?: ReactElement;
  bodyContainer?: JSXElementConstructor<any>;
  confirmLabel?: ReactElement | string;
  cancelLabel?: ReactElement;
  onClose: (index: number) => void;
}
