import { memo, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";

import Alert from "@mui/material/Alert";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useConnectionStore } from "stores/ConnectionStore";
import { ActionState } from "stores/lib";

import { usePageTitle } from "effect/use_page_title";

import { PageContent } from "Component/PageContent";

// -----------------------------------------------------------------------------

export const AtlassianConnectConnectConfirmPage = memo(() => {
  usePageTitle("Environment → Destinations → Atlassian Connect → Connect");

  //
  const [store, actions] = useConnectionStore();

  const [redirectConnectionID, setRedirectConnectionID] = useState<string>("");

  const [search] = useSearchParams();
  const nonce = search.get("nonce");

  //
  function getMessage() {
    switch (true) {
      case !nonce:
        return (
          <>
            <Typography variant="h5">
              There was an error connecting to Atlassian Connect
            </Typography>
            <Alert severity="error">
              Could not find the <code>nonce</code> parameter in the URL.
            </Alert>
          </>
        );

      case store.atlassianConnect.state <= ActionState.IN_PROGRESS:
        return <Skeleton variant="rounded" height={320} />;

      case store.atlassianConnect.state === ActionState.ERROR:
        return (
          <>
            <Typography variant="h5">
              There was an error connecting to Atlassian Connect
            </Typography>
            <Alert severity="error">
              There was an error processing the Atlassian Connect nonce.
            </Alert>
          </>
        );

      default:
        return (
          <Typography>
            <p>You should be redirected to the configuration page shortly.</p>
          </Typography>
        );
    }
  }

  function onSuccess(data: any) {
    setRedirectConnectionID(data.connection);
  }

  //
  if (store.atlassianConnect.state === ActionState.INITIAL && !!nonce) {
    actions.atlassianConnect({ nonce, onSuccess });
  }

  // TODO: when we add another Atlassian Connect integration, switch out URL resolution here.

  //
  return redirectConnectionID ? (
    <Navigate
      to={`/environment/destinations/jira/${redirectConnectionID}/notifications`}
    />
  ) : (
    <PageContent>
      <Stack spacing={2} direction="column">
        {getMessage()}
      </Stack>
    </PageContent>
  );
});
