import { memo } from "react";
import { useParams } from "react-router-dom";

import { LinkTab } from "Component/LinkTab";
import { Page } from "Component/Page";
import { PageContent } from "Component/PageContent";
import { PageHeader } from "Component/PageHeader";
import { PageTabs } from "Component/PageTabs";
import { PageTitle } from "Component/PageTitle";

import { SettingsGeneral } from "./General";
import { SettingsSupportAccess } from "./SupportAccess";
import { SettingsPageTrustCenter } from "./TrustCenter";

// -----------------------------------------------------------------------------

export const SettingsPage = memo(() => {
  const { module = "general" } = useParams() as any;
  const moduleId = getModuleId(module);

  //
  return (
    <Page>
      <PageHeader hasTabs>
        <PageTitle>Settings</PageTitle>

        <PageTabs value={moduleId}>
          <LinkTab label="General" to="/settings" />
          <LinkTab label="Support Access" to="/settings/support-access" />
          <LinkTab label="Trust &amp; Compliance" to="/settings/trust-center" />
        </PageTabs>
      </PageHeader>

      <PageContent>{renderTab(moduleId)}</PageContent>
    </Page>
  );
});

// -----------------------------------------------------------------------------

function renderTab(value: number) {
  switch (value) {
    case 1:
      return <SettingsSupportAccess />;

    case 2:
      return <SettingsPageTrustCenter />;

    default:
      return <SettingsGeneral />;
  }
}

function getModuleId(module: string): number {
  switch (module) {
    case "support-access":
      return 1;
    case "trust-center":
      return 2;
    default:
      return 0;
  }
}
