import { memo } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { usePageTitle } from "effect/use_page_title";

import { Page } from "Component/Page";
import { PageContent } from "Component/PageContent";
import { PageHeader } from "Component/PageHeader";
import { PageHeaderBackButton } from "Component/PageHeaderBackButton";
import { PageTitle } from "Component/PageTitle";
import { ServiceConnectionInstructionPhase } from "Component/ServiceConnectionInstructionPhase";
import { ServiceConnectionPhase } from "Component/ServiceConnectionPhase";
import { ServiceConnectionPhases } from "Component/ServiceConnectionPhases";

import { PagerDutyConfig } from "./config";

// -----------------------------------------------------------------------------

export const PagerDutyConnectPage = memo(() => {
  usePageTitle(`Environment → Sources → PagerDuty → Connect`);

  //
  const redirectUrl: string = `https://app.pagerduty.com/install/integration?${new URLSearchParams(
    {
      app_id: PagerDutyConfig.appID,
      redirect_url: `${window.location.origin}/environment/destinations/pagerduty/connect/confirm`,
      version: 2,
    } as any
  ).toString()}`;

  //
  return (
    <Page>
      <PageHeader>
        <PageHeaderBackButton to="/environment/destinations">
          Back to Destinations
        </PageHeaderBackButton>
        <PageTitle>PagerDuty Connection</PageTitle>
      </PageHeader>

      <PageContent>
        <ServiceConnectionPhases>
          <ServiceConnectionInstructionPhase>
            <Typography variant="body1">
              Connect your ThreatKey account to PagerDuty for real-time
              notifications.
            </Typography>
          </ServiceConnectionInstructionPhase>

          <ServiceConnectionPhase disabled={false}>
            <Stack spacing={1}>
              <Typography variant="body1">
                To make a connection, you will be redirected to PagerDuty where
                you can securely approve our access using OAuth 2.0
                authorization. Afterwards, you&apos;ll be redirected back here.
              </Typography>
              <Box>
                <Button
                  variant="contained"
                  LinkComponent="a"
                  href={redirectUrl}
                >
                  Connect to PagerDuty
                </Button>
              </Box>
            </Stack>
          </ServiceConnectionPhase>
        </ServiceConnectionPhases>
      </PageContent>
    </Page>
  );
});
