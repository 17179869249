import {
  type Action,
  type BoundActions,
  createHook,
  createStore,
} from "react-sweet-state";

import { ReturnIf } from "babel-plugin-transform-functional-return";

import {
  type FindingBase,
  type FindingSeverity,
  type Metrics,
  type SeverityCount,
  type Source,
} from "data/finding";

import { captureSentryException } from "utility/capture_sentry_exception";
import { authenticatedGetFetch } from "utility/fetch/authenticated";

import { SubmitState } from "./lib";

// -----------------------------------------------------------------------------

const initialState: DashboardStoreState = {};

// -----------------------------------------------------------------------------

const DashboardStore = createStore({
  initialState,
  actions: {
    loadRiskScore: genericDashboardAction<{ risk_score: number }>(
      "loadRiskScore",
      "riskScoreError",
      "api/dashboard/risk_score",
      (response) => ({ riskScore: response.risk_score })
    ),
    loadRateOfFindings: genericDashboardAction<{ results: RateOfFinding[] }>(
      "loadRateOfFindings",
      "rateOfFindingsError",
      "api/dashboard/rate_of_findings",
      (response) => ({ rateOfFindings: response.results ?? [] })
    ),
    loadCumulativeDailyFindings: genericDashboardAction<
      CumulativeDailyFindings[]
    >(
      "loadCumulativeDailyFindings",
      "cumulativeDailyFindingsError",
      "api/dashboard/cumulative_daily_findings",
      (response) => ({ cumulativeDailyFindings: response ?? [] })
    ),
    loadMedianTimeToRespond: genericDashboardAction<{ median: number }>(
      "loadMedianTimeToRespond",
      "medianTimeToRespondError",
      "api/dashboard/median_time_to_respond",
      (response) => ({ medianTimeToRespond: response.median })
    ),
    loadMedianTimeToComplete: genericDashboardAction<{ median: number }>(
      "loadMedianTimeToComplete",
      "medianTimeToCompleteError",
      "api/dashboard/median_time_to_complete",
      (response) => ({ medianTimeToComplete: response.median })
    ),
    loadUnresolvedFindingsBySrcSev: genericDashboardAction<{
      results: Array<SeverityCount & UnresolvedBySource>;
    }>(
      "loadUnresolvedFindingsBySrcSev",
      "unresolvedFindingsBySrcSevError",
      "api/dashboard/unresolved_findings_by_src_sev",
      (response) => ({ unresolvedFindingsBySrcSev: response.results })
    ),
    loadStaleFindings: genericDashboardAction<{
      results: StaleFinding[];
    }>(
      "loadStaleFindings",
      "staleFindingsError",
      "api/dashboard/stale_findings_list",
      (response) => ({
        staleFindings: response.results,
      })
    ),
    loadMedianStaleLength: genericDashboardAction<{ median: number }>(
      "loadMedianStaleLength",
      "medianStaleLengthError",
      "api/dashboard/stale_findings_median",
      (response) => ({
        medianStaleLength: response.median,
      })
    ),
    loadIAMMetrics,
  },
});

export const useDashboardStore = createHook(DashboardStore);

export type DashboardStoreActions = BoundActions<
  DashboardStoreState,
  typeof DashboardStore.actions
>;

// -----------------------------------------------------------------------------

function genericDashboardAction<T = any>(
  loadKey: string,
  errorKey: string,
  path: string,
  setter: (response: T) => object
): () => Action<DashboardStoreState> {
  return (): Action<DashboardStoreState> =>
    async ({ getState, setState }) => {
      const { [loadKey]: load } = getState() as any;
      if (load !== SubmitState.STARTED && load !== SubmitState.SUCCESS) {
        try {
          setState({ [loadKey]: SubmitState.STARTED, [errorKey]: undefined });
          const responsePayload = await authenticatedGetFetch<T>(path);
          setState({
            [loadKey]: SubmitState.SUCCESS,
            ...setter(responsePayload),
          });
        } catch (error) {
          captureSentryException(error, `Failed to ${loadKey}`);
          setState({
            [loadKey]: SubmitState.ERROR,
            [errorKey]: (error as Error)?.message ?? undefined,
          });
        }
      }
    };
}

function loadIAMMetrics(): Action<DashboardStoreState> {
  return async ({ getState, setState }) => {
    ReturnIf(
      getState().loadMetricsState === SubmitState.STARTED ||
        getState().loadMetricsState === SubmitState.SUCCESS
    );

    try {
      setState({ loadMetricsState: SubmitState.STARTED });

      const responsePayload = await authenticatedGetFetch<{ metrics: Metrics }>(
        "api/dashboard/unresovled_by_src_sev?tag=iam"
      );

      //
      setState({
        loadMetricsState: SubmitState.SUCCESS,
        metrics: responsePayload.metrics,
      });
    } catch (error) {
      captureSentryException(error, "Failed to load IAM metrics");
      setState({
        loadMetricsState: SubmitState.ERROR,
        metricsError: error instanceof Error ? error.message : undefined,
      });
      throw error;
    }
  };
}

// -----------------------------------------------------------------------------

export interface DashboardStoreState {
  loadRiskScore?: SubmitState;
  riskScore?: number;
  riskScoreError?: string;

  loadRateOfFindings?: SubmitState;
  rateOfFindings?: RateOfFinding[];
  rateOfFindingsError?: string;

  loadCumulativeDailyFindings?: SubmitState;
  cumulativeDailyFindings?: CumulativeDailyFindings[];
  cumulativeDailyFindingsError?: string;

  loadMedianTimeToComplete?: SubmitState;
  medianTimeToComplete?: number;
  medianTimeToCompleteError?: string;

  loadMedianTimeToRespond?: SubmitState;
  medianTimeToRespond?: number;
  medianTimeToRespondError?: string;

  loadUnresolvedFindingsBySrcSev?: SubmitState;
  unresolvedFindingsBySrcSev?: Array<SeverityCount & UnresolvedBySource>;
  unresolvedFindingsBySrcSevError?: string;

  loadStaleFindings?: SubmitState;
  staleFindings?: StaleFinding[];
  staleFindingsError?: string;

  loadMedianStaleLength?: SubmitState;
  medianStaleLength?: number;
  medianStaleLengthError?: string;

  loadMetricsState?: SubmitState;
  metrics?: Metrics;
  metricsError?: string;
}

export interface RateOfFinding extends FindingBase {
  create_time: Date;
  severity: number;
  count: number;
  connection_id: string;
  organization_id: string;
}

export type ResolvedRateOfFinding = Omit<RateOfFinding, "create_time"> & {
  resolved_time: Date;
};

export interface CumulativeDailyFindings {
  open_findings: number;
  resolved_findings: number;
  snap_timestamp: Date;
}
export interface StaleFinding {
  create_time: Date;
  source: Source;
  asset: string;
  severity: FindingSeverity;
}

export interface UnresolvedBySource {
  source: Source;
  count: number;
}
