import { type ReactNode } from "react";

// -----------------------------------------------------------------------------

export function pluralize(
  value: number,
  word: string,
  plural: string = "s"
): string {
  return `${word}${value !== 1 ? plural : ""}`;
}

export function pluralizeCustom<T = ReactNode>(
  value: number,
  single: T,
  plural: T
): T {
  return value === 1 ? single : plural;
}
