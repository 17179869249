import { memo } from "react";

import SvgIcon from "@mui/material/SvgIcon";

// -----------------------------------------------------------------------------

export const GithubCoverImage = memo((props: object) => {
  return (
    <SvgIcon viewBox="0 0 496.24533 145.87466" {...props}>
      <g transform="matrix(1.3333333,0,0,-1.3333333,-135.87733,648.93732)">
        <g id="g12" transform="scale(0.1)" fill="gitHubLogo.main">
          <path d="m 1772.81,4390.13 h -302.67 c -7.81,0 -14.14,-6.34 -14.14,-14.16 V 4228 c 0,-7.81 6.33,-14.17 14.14,-14.17 h 118.07 v -183.85 c 0,0 -26.51,-9.04 -99.81,-9.04 -86.47,0 -207.27,31.6 -207.27,297.24 0,265.68 125.79,300.64 243.88,300.64 102.23,0 146.26,-17.99 174.29,-26.66 8.81,-2.7 16.95,6.06 16.95,13.88 l 33.76,142.98 c 0,3.65 -1.24,8.06 -5.4,11.05 -11.38,8.11 -80.81,46.96 -256.21,46.96 -202.05,0 -409.32,-85.96 -409.32,-499.22 0,-413.26 237.3,-474.84 437.27,-474.84 165.57,0 266.01,70.75 266.01,70.75 4.14,2.29 4.59,8.07 4.59,10.73 v 461.52 c 0,7.82 -6.33,14.16 -14.14,14.16" />
          <path d="m 3332.65,4757.52 c 0,7.87 -6.24,14.23 -14.06,14.23 h -170.42 c -7.78,0 -14.11,-6.36 -14.11,-14.23 0,-0.04 0.05,-329.35 0.05,-329.35 h -265.65 v 329.35 c 0,7.87 -6.26,14.23 -14.06,14.23 h -170.41 c -7.75,0 -14.08,-6.36 -14.08,-14.23 v -891.78 c 0,-7.87 6.33,-14.27 14.08,-14.27 h 170.41 c 7.8,0 14.06,6.4 14.06,14.27 v 381.44 h 265.65 c 0,0 -0.47,-381.41 -0.47,-381.44 0,-7.87 6.32,-14.27 14.12,-14.27 h 170.82 c 7.81,0 14.05,6.4 14.07,14.27 v 891.78" />
          <path d="m 2094.4,4640.5 c 0,61.36 -49.19,110.95 -109.89,110.95 -60.64,0 -109.88,-49.59 -109.88,-110.95 0,-61.31 49.24,-111.03 109.88,-111.03 60.7,0 109.89,49.72 109.89,111.03" />
          <path d="m 2082.22,4053.85 c 0,22.86 0,411.65 0,411.65 0,7.82 -6.31,14.21 -14.1,14.21 h -169.89 c -7.79,0 -14.76,-8.04 -14.76,-15.85 0,0 0,-495.35 0,-589.77 0,-17.33 10.8,-22.49 24.77,-22.49 0,0 72.52,0 153.06,0 16.8,0 20.92,8.25 20.92,22.77 0,31.61 0,155.45 0,179.48" />
          <path d="m 3980.32,4478.37 h -169.11 c -7.75,0 -14.07,-6.4 -14.07,-14.26 v -437.27 c 0,0 -42.97,-31.43 -103.95,-31.43 -60.97,0 -77.15,27.67 -77.15,87.38 0,59.78 0,381.32 0,381.32 0,7.86 -6.3,14.26 -14.07,14.26 h -171.64 c -7.74,0 -14.09,-6.4 -14.09,-14.26 0,0 0,-232.86 0,-410.2 0,-177.35 98.84,-220.73 234.81,-220.73 111.56,0 201.5,61.62 201.5,61.62 0,0 4.28,-32.48 6.22,-36.33 1.93,-3.84 6.98,-7.72 12.44,-7.72 l 109.19,0.49 c 7.74,0 14.09,6.4 14.09,14.23 l -0.06,598.64 c 0,7.86 -6.31,14.26 -14.11,14.26" />
          <path d="m 4375.84,3995.95 c -58.66,1.78 -98.45,28.4 -98.45,28.4 v 282.41 c 0,0 39.25,24.06 87.41,28.36 60.9,5.45 119.58,-12.94 119.58,-158.22 0,-153.19 -26.48,-183.43 -108.54,-180.95 z m 66.7,502.45 c -96.05,0 -161.38,-42.86 -161.38,-42.86 v 301.98 c 0,7.87 -6.3,14.23 -14.06,14.23 h -170.91 c -7.77,0 -14.08,-6.36 -14.08,-14.23 v -891.78 c 0,-7.87 6.31,-14.27 14.1,-14.27 0.02,0 118.54,0 118.58,0 5.34,0 9.38,2.75 12.37,7.57 2.95,4.8 7.2,41.12 7.2,41.12 0,0 69.88,-66.22 202.17,-66.22 155.32,0 244.39,78.77 244.39,353.65 0,274.89 -142.26,310.81 -238.38,310.81" />
          <path d="m 2572.02,4479.79 h -127.84 c 0,0 -0.19,168.85 -0.19,168.88 0,6.4 -3.3,9.59 -10.69,9.59 h -174.2 c -6.78,0 -10.41,-2.98 -10.41,-9.49 v -174.52 c 0,0 -87.3,-21.07 -93.21,-22.78 -5.87,-1.7 -10.2,-7.12 -10.2,-13.59 v -109.67 c 0,-7.89 6.3,-14.25 14.09,-14.25 h 89.32 c 0,0 0,-114.71 0,-263.84 0,-195.97 137.46,-215.22 230.21,-215.22 42.39,0 93.09,13.61 101.46,16.7 5.06,1.86 8,7.11 8,12.79 l 0.15,120.65 c 0,7.86 -6.65,14.23 -14.13,14.23 -7.46,0 -26.51,-3.03 -46.13,-3.03 -62.8,0 -84.07,29.19 -84.07,66.99 0,37.76 -0.01,250.73 -0.01,250.73 h 127.85 c 7.79,0 14.1,6.36 14.1,14.25 v 137.37 c 0,7.87 -6.31,14.21 -14.1,14.21" />
        </g>
      </g>
    </SvgIcon>
  );
});
