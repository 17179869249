import { memo } from "react";

import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Grid2 from "@mui/material/Unstable_Grid2";

import { type JiraIssueFieldMetadata } from "data/jira_cloud";

import { useJiraCIM } from "stores/JiraCreateIssueMetaStore";

// -----------------------------------------------------------------------------

export const JiraIssueField = memo(({ field, projectTypeKey }: Props) => {
  const [cim, actions] = useJiraCIM();
  const value = cim.data[field.key] || getDefaultValue(field);
  const labelID = `${field.key}-label`;
  let inputLabel: JSX.Element | null = (
    <InputLabel id={labelID}>{field.name}</InputLabel>
  );
  let inputElement: JSX.Element | null = null;
  let helperText: JSX.Element | string = "";
  // special case field keys
  switch (field.key) {
    case "attachment":
    case "project":
    case "issuetype":
    case "issuelinks":
    case "parent":
      return null;
    case "description":
      inputLabel = null;
      inputElement = <TextField label="Description" value="Default" disabled />;
      helperText =
        "Description customization not yet implemented! Please let us know if you'd like this.";
      break;
    case "priority":
      inputElement = (
        <Select labelId={labelID} label="Reporter" value="default" disabled>
          <MenuItem value="default">Default mappings</MenuItem>
        </Select>
      );
      helperText = (
        <>
          Please see{" "}
          <Link
            href="https://docs.threatkey.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            our documentation
          </Link>{" "}
          for default severity mappings.
        </>
      );
      break;
    case "reporter":
      inputElement = (
        <Select labelId={labelID} label="Reporter" value="threatkey" disabled>
          <MenuItem value="threatkey">ThreatKey</MenuItem>
        </Select>
      );
      helperText =
        "The issue reporter will always be the ThreatKey plugin user.";
      break;
  }
  // jira software
  if (projectTypeKey === "software") {
    switch (field.key) {
      case "customfield_10014": // "Epic Link"
        return null;
    }
  } else if (projectTypeKey === "service_desk") {
    switch (field.key) {
      case "customfield_10050": // "Affected hardware"
        inputElement = (
          <Select labelId={labelID} label={field.name} value={value} disabled>
            <MenuItem value="default">Default: asset</MenuItem>
          </Select>
        );
        helperText = "By default, this contains the asset name or asset ID.";
        break;
      case "customfield_10060": // "Root cause"
        inputElement = (
          <Select labelId={labelID} label={field.name} value={value} disabled>
            <MenuItem value="default">Default: actor attribution</MenuItem>
          </Select>
        );
        helperText =
          "By default, this contains finding attribution information.";
        break;
    }
  }
  switch (field.schema.system) {
    case "assignee":
      inputElement = (
        <Select labelId={labelID} label={field.name} value={value} disabled>
          <MenuItem value="default">Not implemented</MenuItem>
        </Select>
      );
      helperText =
        "Assignee customization not yet implemented! Please contact us if default assignees on a project don't fit your use case.";
      break;
    case "summary":
      inputElement = (
        <Select labelId={labelID} label={field.name} value={value} disabled>
          <MenuItem value="default">
            Default: <code>FINDING_NAME</code> in asset:id:here
          </MenuItem>
        </Select>
      );
      break;
  }
  if (inputElement == null) {
    switch (field.schema.type) {
      case "string":
        inputLabel = null;
        inputElement = (
          <Autocomplete
            freeSolo
            options={[]}
            value={value === "default" ? "" : value}
            onChange={(_event, value) => {
              (async () => {
                actions.set(field.key, value);
              })();
            }}
            renderInput={(params) => (
              <TextField {...params} label={field.name} />
            )}
          />
        );
        break;
      case "option":
      case "array": {
        // create a combobox instead of using a select
        const options = (field.allowedValues || []).map((v) => {
          let itemValue = v.id;
          if (field.defaultValue === v) {
            itemValue = "default";
          }
          return { id: itemValue, label: v.name ?? v.value };
        });
        inputLabel = null;
        inputElement = (
          <Autocomplete
            multiple={field.schema.type === "array"}
            options={options}
            getOptionLabel={(o) => {
              return o?.label || "";
            }}
            isOptionEqualToValue={(o, v) => o.id === v.id}
            value={value}
            onChange={(_event, value) => {
              (async () => {
                actions.set(field.key, value);
              })();
            }}
            renderInput={(params) => (
              <TextField {...params} label={field.name} />
            )}
          />
        );
        break;
      }
      default: {
        inputLabel = null;
        inputElement = <TextField label={field.name} disabled />;
        helperText = (
          <>
            Field type <code>{field.schema.type}</code> not yet supported.
          </>
        );
        break;
      }
    }
  }
  return (
    <Grid2 xs={12} md={6}>
      <FormControl fullWidth required={field.required}>
        {inputLabel}
        {inputElement}
        {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
      </FormControl>
    </Grid2>
  );
});

// -----------------------------------------------------------------------------

function getDefaultValue(field: JiraIssueFieldMetadata) {
  switch (field.schema.type) {
    case "option":
    case "array":
      return [];
    default:
      return "default";
  }
}

// -----------------------------------------------------------------------------

interface Props {
  field: JiraIssueFieldMetadata;
  projectTypeKey: "software" | "service_desk" | "business";
}
