import { memo } from "react";

import SvgIcon from "@mui/material/SvgIcon";

// -----------------------------------------------------------------------------

export const PingFederateSAMLSSOIcon = memo((props: object) => {
  return (
    <SvgIcon viewBox="45 110 800 140" {...props}>
      <g
        fill={props.theme?.oneLoginLogo.main}
        stroke="none"
        transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
      >
        <path d="M1560 3778 c-64 -24 -100 -93 -80 -153 34 -102 177 -117 226 -24 18 36 18 82 -1 119 -25 48 -96 76 -145 58z" />
        <path d="M5107 3743 c-4 -3 -7 -101 -7 -216 l0 -210 -44 41 c-52 48 -96 62 -191 62 -122 0 -207 -56 -262 -175 -26 -56 -28 -68 -28 -190 1 -112 4 -138 24 -190 51 -128 171 -205 299 -192 63 7 153 48 181 83 10 13 22 24 25 24 3 0 6 -20 6 -45 l0 -45 60 0 60 0 0 530 0 530 -58 0 c-32 0 -62 -3 -65 -7z m-126 -449 c108 -55 152 -249 89 -393 -68 -154 -254 -160 -330 -11 -63 124 -33 337 57 395 54 35 125 39 184 9z" />
        <path d="M720 3206 l0 -516 105 0 105 0 0 189 0 189 128 4 c121 3 133 6 195 36 74 37 109 73 146 152 35 76 37 201 3 275 -31 68 -83 118 -156 152 -60 27 -63 28 -293 31 l-233 4 0 -516z m409 330 c41 -22 63 -61 68 -125 4 -45 0 -65 -19 -101 -30 -59 -55 -70 -160 -70 l-88 0 0 155 0 155 85 0 c56 0 96 -5 114 -14z" />
        <path d="M3380 3180 l0 -490 70 0 70 0 0 225 0 225 175 0 175 0 0 60 0 60 -175 0 -175 0 0 145 0 145 185 0 185 0 0 60 0 60 -255 0 -255 0 0 -490z" />
        <path d="M8094 3631 c-45 -27 -64 -59 -64 -104 0 -50 25 -93 66 -112 64 -30 127 -8 164 56 20 36 21 46 11 78 -24 82 -113 123 -177 82z m120 -42 c29 -35 33 -76 11 -118 -16 -32 -33 -41 -78 -41 -79 1 -115 95 -61 159 20 25 34 31 64 31 30 0 44 -6 64 -31z" />
        <path d="M8112 3592 c-9 -6 -12 -29 -10 -73 3 -64 20 -88 26 -36 4 35 18 34 37 -3 9 -16 20 -30 25 -30 13 0 13 16 -2 39 -9 15 -8 22 5 36 20 20 22 39 4 54 -19 15 -69 23 -85 13z m68 -37 c0 -9 -9 -15 -25 -15 -16 0 -25 6 -25 15 0 9 9 15 25 15 16 0 25 -6 25 -15z" />
        <path d="M7155 3591 l-50 -18 -3 -81 -3 -82 -74 0 -75 0 0 -55 0 -55 74 0 74 0 4 -247 c3 -264 4 -270 56 -326 35 -37 119 -61 188 -53 82 9 84 11 84 73 0 48 -2 54 -17 48 -66 -26 -129 -13 -160 34 -16 23 -18 54 -21 249 l-3 222 95 0 96 0 0 55 0 55 -95 0 -95 0 0 100 c0 112 3 108 -75 81z" />
        <path d="M3184 3479 c-13 -21 -89 -49 -134 -48 -25 0 -72 6 -105 14 -78 19 -198 19 -263 0 -67 -20 -131 -70 -162 -125 -19 -36 -24 -59 -24 -115 0 -99 29 -148 117 -196 l30 -16 -34 -12 c-18 -6 -46 -23 -61 -38 -36 -34 -39 -106 -7 -146 27 -36 74 -46 244 -55 133 -7 146 -10 170 -32 33 -31 34 -82 1 -115 -61 -61 -242 -59 -275 3 -6 12 -11 37 -11 57 l0 36 -92 -3 -93 -3 -9 -37 c-21 -84 50 -189 150 -222 174 -57 396 -30 488 60 55 54 70 89 70 169 0 83 -16 118 -74 168 -46 39 -102 57 -187 58 -89 2 -166 13 -170 25 -7 22 53 47 141 61 126 18 196 63 231 145 20 50 19 116 -4 161 l-19 37 48 0 c51 0 137 38 132 59 -4 19 -74 121 -83 121 -4 0 -11 -5 -15 -11z m-320 -173 c45 -19 66 -52 66 -103 0 -62 -31 -94 -98 -101 -60 -5 -105 10 -120 41 -54 108 41 210 152 163z" />
        <path d="M1588 3452 l-88 -15 0 -373 0 -374 100 0 100 0 0 390 c0 306 -3 390 -12 389 -7 -1 -52 -8 -100 -17z" />
        <path d="M1876 3444 c-43 -13 -80 -25 -82 -27 -2 -2 1 -19 8 -38 7 -21 13 -159 15 -362 l5 -328 97 3 96 3 2 263 c1 192 5 268 14 279 21 25 86 53 123 53 64 0 66 -9 66 -322 l0 -278 100 0 100 0 0 298 c0 306 -5 357 -37 401 -30 41 -99 71 -163 71 -71 0 -114 -13 -174 -50 -27 -17 -49 -30 -50 -28 -1 2 -7 21 -12 42 -5 22 -13 40 -19 42 -5 1 -45 -9 -89 -22z" />
        <path d="M4117 3406 c-49 -18 -108 -59 -134 -94 -93 -126 -107 -318 -33 -468 36 -73 82 -115 160 -146 75 -30 239 -30 320 -1 l55 20 3 53 c5 76 0 84 -40 61 -46 -27 -134 -51 -182 -51 -55 0 -137 40 -169 82 -33 42 -57 99 -57 133 l0 25 251 0 252 0 -7 73 c-14 153 -72 258 -170 303 -41 19 -67 24 -131 23 -44 0 -97 -6 -118 -13z m196 -113 c36 -18 75 -78 83 -130 l7 -43 -183 0 -182 0 7 33 c10 45 49 111 78 130 56 37 129 41 190 10z" />
        <path d="M5499 3406 c-60 -21 -108 -55 -144 -103 -52 -68 -70 -132 -70 -248 0 -116 21 -195 70 -259 86 -113 275 -155 446 -99 l54 18 3 67 3 68 -33 -19 c-43 -25 -133 -51 -176 -51 -53 0 -107 20 -148 53 -40 33 -84 116 -84 160 l0 27 249 0 250 0 -5 58 c-21 238 -112 342 -299 341 -44 0 -96 -6 -116 -13z m196 -117 c36 -21 70 -78 80 -131 l7 -38 -181 0 c-168 0 -181 1 -181 18 0 30 53 125 81 145 54 39 136 42 194 6z" />
        <path d="M6177 3390 c-26 -16 -52 -41 -59 -55 -18 -39 -28 -30 -28 25 l0 50 -60 0 -60 0 0 -360 0 -360 65 0 65 0 0 188 c0 202 10 268 51 343 33 60 95 93 162 84 l47 -6 0 61 0 60 -68 0 c-58 0 -75 -4 -115 -30z" />
        <path d="M6535 3407 c-22 -8 -55 -21 -72 -31 -31 -16 -33 -20 -33 -72 0 -31 3 -54 8 -52 108 56 182 73 250 57 75 -16 122 -77 122 -156 0 -22 -3 -23 -109 -23 -229 0 -351 -83 -351 -237 1 -130 74 -204 218 -219 77 -8 155 14 210 61 l42 37 0 -41 0 -41 60 0 60 0 0 245 c0 267 -7 320 -50 389 -44 68 -92 89 -215 93 -64 2 -115 -2 -140 -10z m275 -429 c0 -57 -25 -122 -56 -150 -53 -46 -140 -60 -200 -32 -46 22 -64 50 -64 100 0 89 75 132 233 133 l87 1 0 -52z" />
        <path d="M7637 3406 c-108 -39 -180 -129 -207 -261 -24 -117 5 -264 70 -354 80 -109 279 -151 450 -93 l55 19 3 66 c3 70 -1 76 -33 51 -29 -22 -141 -54 -189 -54 -110 0 -223 110 -226 218 l0 22 251 0 251 0 -7 73 c-16 160 -70 257 -169 303 -41 19 -67 24 -131 23 -44 0 -97 -6 -118 -13z m195 -112 c34 -17 63 -58 77 -109 19 -69 29 -65 -170 -65 l-181 0 7 28 c12 52 47 112 77 133 55 39 128 44 190 13z" />
      </g>
    </SvgIcon>
  );
});
