import styled from "styled-components";

import Chip from "@mui/material/Chip";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";

import LaunchIcon from "@mui/icons-material/Launch";

import {
  type FindingSeverity,
  FindingSeverityLabel,
  FindingSeverityColor,
  FindingStateLabel,
  SourceLabel,
} from "data/finding";

import { dayjs } from "utility/dayjs";

// -----------------------------------------------------------------------------

export const FindingsColumns = [
  {
    field: "severity",
    headerName: "Severity",
    width: 100,
    flex: 0,
    renderCell: (params: any) => {
      const severity: FindingSeverity = params.row.severity;
      const label: string = FindingSeverityLabel[severity];
      const color: any = FindingSeverityColor[severity];

      //
      return (
        <Chip
          label={label}
          color={color}
          variant="filled"
          sx={{ fontWeight: "bold" }}
          size="small"
        />
      );
    },
  },
  {
    field: "state",
    headerName: "State",
    width: 100,
    valueGetter: (params: any) => FindingStateLabel[params.row.state],
    renderCell: (params: any) => params.value,
  },
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    renderCell: (params: any) => (
      <Typography variant="inherit" noWrap>
        {params.row.name}
      </Typography>
    ),
  },
  {
    field: "source",
    headerName: "Service",
    flex: 0.33,
    valueGetter: (params: any) => SourceLabel[params.row.source],
    renderCell: (params: any) => (
      <Typography variant="inherit" noWrap>
        {params.value}
      </Typography>
    ),
  },
  {
    field: "asset",
    headerName: "Asset",
    flex: 1,
    renderCell: (params: any) =>
      params.row.details.asset?.name || (
        <Typography variant="inherit" noWrap>
          {params.row.asset}
        </Typography>
      ),
  },
  {
    field: "event_time",
    headerName: "Event Time",
    width: 128,

    valueGetter: (params: any) => ({
      original: params.row.event_time,
      label: dayjs(params.row.event_time).fromNow(),
    }),

    renderCell: (params: any) => params.value.label,

    sortComparator: (v1: any, v2: any) => v1.original - v2.original,
  },
  {
    field: "uuid",
    headerName: "",
    headerClassName: "arrow-cell",
    cellClassName: "arrow-cell",
    renderCell: (params: any) => (
      <LaunchIcon sx={{ opacity: 0.5, width: 22 }} />
    ),
    width: 22,
    disableReorder: true,
    hideSortIcons: true,
  },
];

export const FindingsColumnsLoading = [
  {
    field: "severity",
    headerName: "Severity",
    width: 100,
    renderCell: RenderSkeleton,
  },
  {
    field: "state",
    headerName: "State",
    width: 100,
    renderCell: RenderSkeleton,
  },
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    renderCell: RenderSkeleton,
  },
  {
    field: "source",
    headerName: "Service",
    flex: 0.33,
    renderCell: RenderSkeleton,
  },
  {
    field: "asset",
    headerName: "Asset",
    flex: 1,
    renderCell: RenderSkeleton,
  },
  {
    field: "event_time",
    headerName: "Event Time",
    width: 128,
    renderCell: RenderSkeleton,
  },
  {
    field: "uuid",
    headerName: "",
    headerClassName: "arrow-cell",
    cellClassName: "arrow-cell",
    disableReorder: true,
    hideSortIcons: true,
    width: 21,
    renderCell: RenderSkeleton,
  },
];

function RenderSkeleton() {
  return <SkeletonStyled />;
}

export const LoadingRows: any[] = [
  { uuid: 1 },
  { uuid: 2 },
  { uuid: 3 },
  { uuid: 4 },
  { uuid: 5 },
  { uuid: 6 },
  { uuid: 7 },
  { uuid: 8 },
  { uuid: 9 },
  { uuid: 10 },
];

// -----------------------------------------------------------------------------

export const PaperStyle = {
  p: 0,
  width: "100%",
  height: "100%",
  minHeight: 512,
};

export const DataGridStyle = {
  border: 0,

  "& .MuiTablePagination-displayedRows": {
    lineHeight: "1 !important",
    margin: 0,
  },

  "& .MuiDataGrid-cell": {
    wordBreak: "break-all",

    "&:focus": {
      outline: "none",
    },
  },

  "& .MuiDataGrid-columnHeaderTitleContainer": {
    padding: 0,
  },

  "& .MuiDataGrid-columnSeparator": {
    display: "none !important",
  },

  "& .arrow-cell": {
    minWidth: "32px !important",
    justifyContent: "center",
    width: "32px !important",
  },

  "& code": {
    width: "auto",
  },
};

// -----------------------------------------------------------------------------

const SkeletonStyled = styled(Skeleton)`
  width: 100%;
`;
