import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";

import { type Connection } from "stores/ConnectionStore";

// -----------------------------------------------------------------------------

export function GoogleSheetConnectedExtraConnectionDetails(
  connection: Connection
) {
  return (
    <Box>
      <Link
        href={connection.config.url}
        target="_blank"
        rel="noopener noreferrer"
        underline="none"
      >
        <Button variant="text">Link to sheet</Button>
      </Link>
    </Box>
  );
}
