import { type ReactNode, memo } from "react";

import LoadingButton, { type LoadingButtonProps } from "@mui/lab/LoadingButton";

// ----------------------------------------------------------------------------

export const ModalSubmitButton = memo(
  ({
    children,
    disabled,
    loading,
    onClick,
  }: Pick<LoadingButtonProps, "disabled" | "loading" | "onClick"> & {
    children: ReactNode;
  }) => {
    return (
      <LoadingButton
        variant="contained"
        disabled={disabled}
        loading={loading}
        onClick={onClick}
      >
        {children}
      </LoadingButton>
    );
  }
);
