import { memo } from "react";

import styled from "styled-components";

import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";

// -----------------------------------------------------------------------------

export const SettingsEarlyAccess = memo(
  ({ earlyAccess, setEarlyAccess, disabled }: Props) => {
    return (
      <Container
        elevation={1}
        sx={{ p: 2, mt: 2 }}
        className={disabled ? "disabled" : undefined}
      >
        <Typography variant="h6" sx={{ mb: 1 }} fontWeight="bold">
          Early Access Program
        </Typography>
        <Typography variant="body1" sx={{ mb: 1 }}>
          If you want to get in on the ground floor of the latest and greatest
          technology, sign up for our early access program. You&apos;ll be first
          in line to receive new products, features and updates, and you&apos;ll
          help shape the future of our company.
        </Typography>
        <FormControlLabel
          control={
            <Switch
              name="product_and_features"
              checked={earlyAccess}
              onChange={(event) => setEarlyAccess(event.target.checked)}
              disabled={disabled}
            />
          }
          label="Yes! I want to enroll in the early access program."
        />
      </Container>
    );
  }
);

// -----------------------------------------------------------------------------

const Container = styled(Paper)`
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

// -----------------------------------------------------------------------------

interface Props {
  earlyAccess: boolean;
  setEarlyAccess: Function;
  disabled: boolean;
}
