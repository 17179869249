const crossTabChannel = new BroadcastChannel("threatkey");

// -----------------------------------------------------------------------------

export function setCrossTabMessageHandler(
  callback: (event: "login" | "logout") => void
) {
  crossTabChannel.onmessage = (event) => callback(event.data);
}

export function sendCrossTabMessage(action: "login" | "logout"): void {
  crossTabChannel.postMessage(action);
}
