import { type OrgStoreState } from "stores/OrganizationStore";

// -----------------------------------------------------------------------------

export function redirectToEKSKubernetesConnectPage(
  orgStore: OrgStoreState,
  navigate: Function
): void {
  navigate("/environment/sources/eks-kubernetes/connect");
}
