import { type Action, createHook, createStore } from "react-sweet-state";

import { ReturnIf } from "babel-plugin-transform-functional-return";

import {
  type IgnoreApiPayload,
  type IgnoreRuleParams,
  type IgnoreType,
  type RuleType,
} from "data/finding_ignore_rule";
import { type ResponseError } from "data/response_error";

import { captureSentryException } from "utility/capture_sentry_exception";
import {
  authenticatedDeleteFetch,
  authenticatedGetFetch,
  authenticatedPatchFetch,
  authenticatedPostFetch,
} from "utility/fetch/authenticated";

import { ActionState } from "./lib";

// -----------------------------------------------------------------------------

const initialState: IgnoreStoreState = {
  ignoreRules: [],

  ignoreState: ActionState.INITIAL,
  previewIgnoreState: ActionState.INITIAL,
  unignoreState: ActionState.INITIAL,
  bulkIgnoreState: ActionState.INITIAL,
  bulkUnignoreState: ActionState.INITIAL,
  listRulesState: ActionState.INITIAL,
  editIgnoreRuleState: ActionState.INITIAL,
  deleteIgnoreRuleState: ActionState.INITIAL,
};

// -----------------------------------------------------------------------------

export const useIgnoreStore = createHook(
  createStore({
    initialState,
    actions: {
      ignoreFinding,
      previewIgnore,
      unignoreFinding,
      bulkIgnoreFinding,
      bulkUnignoreFinding,
      listIgnoreRules,
      editIgnoreRule,
      deleteIgnoreRule,
    },
  })
);

// -----------------------------------------------------------------------------

function ignoreFinding({
  uuid,
  ignoreType,
  ruleType,
  assetRegex,
  ignoreReason,
  onSuccess,
  onError,
}: IgnoreParams): Action<IgnoreStoreState> {
  return async ({ setState, getState }) => {
    ReturnIf(getState().ignoreState === ActionState.IN_PROGRESS);

    try {
      setState({
        ignoreState: ActionState.IN_PROGRESS,
      });
      await authenticatedPostFetch(
        "api/findings/ignore/create",
        getCreateData({
          uuid,
          ignoreType,
          ruleType,
          assetRegex,
          ignoreReason,
        })
      );
      onSuccess();
    } catch (error) {
      captureSentryException(error, "Failed to ignore finding");
      onError();
    }
    setState({
      ignoreState: ActionState.INITIAL,
    });
  };
}

function previewIgnore({
  uuid,
  ignoreType,
  ruleType,
  assetRegex,
  ignoreReason,
  onSuccess,
  onError,
}: IgnoreParams): Action<IgnoreStoreState> {
  return async ({ setState, getState }) => {
    ReturnIf(getState().previewIgnoreState === ActionState.IN_PROGRESS);

    try {
      setState({
        previewIgnoreState: ActionState.IN_PROGRESS,
      });
      const responsePayload = await authenticatedPostFetch(
        "api/findings/ignore/create_preview",
        getCreateData({
          uuid,
          ignoreType,
          ruleType,
          assetRegex,
          ignoreReason,
        })
      );
      onSuccess(responsePayload.results);
    } catch (error) {
      captureSentryException(error, "Failed to create ignore finding preview");
      onError();
    }
    setState({
      previewIgnoreState: ActionState.INITIAL,
    });
  };
}

function unignoreFinding({
  uuid,
  onSuccess,
  onError,
}: UnignoreParams): Action<IgnoreStoreState> {
  return async ({ setState, getState }) => {
    ReturnIf(getState().unignoreState === ActionState.IN_PROGRESS);

    try {
      setState({
        unignoreState: ActionState.IN_PROGRESS,
      });
      await authenticatedDeleteFetch(`api/findings/single_unignore/${uuid}`);
      onSuccess();
    } catch (error) {
      captureSentryException(error, "Failed to unignore finding");
      onError();
    }
    setState({
      unignoreState: ActionState.INITIAL,
    });
  };
}

function bulkIgnoreFinding({
  uuids,
  ignoreReason,
  onSuccess,
  onError,
}: BulkIgnoreParams): Action<IgnoreStoreState> {
  return async ({ setState, getState }) => {
    ReturnIf(getState().bulkIgnoreState === ActionState.IN_PROGRESS);

    try {
      setState({
        bulkIgnoreState: ActionState.IN_PROGRESS,
      });

      await authenticatedPostFetch(`api/findings/bulk_ignore`, {
        finding_uuids: uuids,
        ignoreReason,
      });

      onSuccess();
      setState({
        bulkIgnoreState: ActionState.INITIAL,
      });
    } catch (error) {
      captureSentryException(error, "Failed to bulk ignore findings");
      onError((error as ResponseError)?.payload?.finding_uuids?.length);
      setState({
        bulkIgnoreState: ActionState.INITIAL,
      });
    }
  };
}

function bulkUnignoreFinding({
  uuids,
  onSuccess,
  onError,
}: BulkUnignoreParams): Action<IgnoreStoreState> {
  return async ({ setState, getState }) => {
    ReturnIf(getState().bulkUnignoreState === ActionState.IN_PROGRESS);

    try {
      setState({
        bulkUnignoreState: ActionState.IN_PROGRESS,
      });

      await authenticatedPostFetch(`api/findings/bulk_unignore`, {
        finding_uuids: uuids,
      });

      onSuccess();
      setState({
        bulkUnignoreState: ActionState.INITIAL,
      });
    } catch (error) {
      captureSentryException(error, "Failed to bulk unignore findings");
      onError(
        (error as ResponseError)?.payload?.finding_uuids?.length || uuids.length
      );
      setState({
        bulkUnignoreState: ActionState.INITIAL,
      });
    }
  };
}

function listIgnoreRules(): Action<IgnoreStoreState> {
  return async ({ setState, getState }) => {
    ReturnIf(getState().listRulesState === ActionState.IN_PROGRESS);

    //
    try {
      setState({
        listRulesState: ActionState.IN_PROGRESS,
      });
      const responsePayload = await authenticatedGetFetch(
        "api/findings/ignore/list"
      );
      setState({
        listRulesState: ActionState.COMPLETED,
        ignoreRules: responsePayload.results,
      });
    } catch (error) {
      setState({
        listRulesState: ActionState.ERROR,
      });
      captureSentryException(error, "Failed to get ignore rules");
    }
  };
}

function editIgnoreRule({
  uuid,
  newReason,
  onSuccess,
  onError,
}: EditIgnoreRuleParams): Action<IgnoreStoreState> {
  return async ({ setState, getState }) => {
    ReturnIf(getState().editIgnoreRuleState === ActionState.IN_PROGRESS);

    //
    try {
      setState({
        editIgnoreRuleState: ActionState.IN_PROGRESS,
      });
      await authenticatedPatchFetch(`api/findings/ignore/${uuid}`, {
        ignore_reason: newReason,
      });
      onSuccess();
    } catch (error) {
      captureSentryException(error, "Failed to edit ignore rule");
      onError();
    }

    //
    setState({
      editIgnoreRuleState: ActionState.INITIAL,
    });
  };
}

function deleteIgnoreRule({
  uuid,
  onSuccess,
  onError,
}: DeleteIgnoreRuleParams): Action<IgnoreStoreState> {
  return async ({ setState, getState }) => {
    ReturnIf(getState().deleteIgnoreRuleState === ActionState.IN_PROGRESS);

    //
    try {
      setState({
        deleteIgnoreRuleState: ActionState.IN_PROGRESS,
      });
      await authenticatedDeleteFetch(`api/findings/ignore/${uuid}`);
      onSuccess();
    } catch (error) {
      captureSentryException(error, "Failed to delete ignore rule");
      onError();
    }

    //
    setState({
      deleteIgnoreRuleState: ActionState.INITIAL,
    });
  };
}
// -----------------------------------------------------------------------------

function getCreateData({
  uuid,
  ignoreType,
  ruleType,
  assetRegex,
  ignoreReason,
}: IgnoreRuleParams) {
  let data: IgnoreApiPayload;
  if (ignoreType === "instance") {
    data = {
      finding_uuid: uuid,
      single_finding_ignored: true,
    };
  } else {
    switch (ruleType) {
      case "asset":
        data = {
          finding_uuid: uuid,
          asset_ignored: true,
        };
        break;
      case "actor":
        data = {
          finding_uuid: uuid,
          actor_ignored: true,
        };
        break;
      case "finding":
        data = {
          finding_uuid: uuid,
          finding_type_ignored: true,
        };
        break;
      default:
        data = {
          finding_uuid: uuid,
          use_asset_re: true,
          asset_re: assetRegex,
        };
        break;
    }
  }

  if (ignoreReason) {
    data.ignore_reason = ignoreReason;
  }

  //
  return data;
}

// -----------------------------------------------------------------------------

interface IgnoreStoreState {
  ignoreRules: ApiRule[];

  ignoreState: ActionState;
  previewIgnoreState: ActionState;
  unignoreState: ActionState;
  bulkIgnoreState: ActionState;
  bulkUnignoreState: ActionState;
  listRulesState: ActionState;
  editIgnoreRuleState: ActionState;
  deleteIgnoreRuleState: ActionState;
}

export interface ApiRule {
  uuid: string;
  affected_findings: string[];
  created_at: string;
  created_by: string;
  updated_at: string;
  ignore_reason: string | null;
  tk_org: string;

  // rule type
  asset_ignored: boolean;
  actor_ignored: boolean;
  finding_type_ignored: boolean;
  single_finding_ignored: boolean;
  use_asset_re: boolean;

  // details
  asset_name: string | null;
  actor_name: string | null;
  asset_re: string | null;
  finding_type_name: string | null;
  finding_uuid: string;
}

interface CreateParams {
  uuid: string;
  ignoreType: IgnoreType;
  ruleType: RuleType;
  assetRegex: string;
  ignoreReason: string;
}

interface IgnoreParams extends CreateParams {
  onSuccess: Function;
  onError: Function;
}

interface UnignoreParams {
  uuid: string;
  onSuccess: Function;
  onError: Function;
}

interface BulkIgnoreParams {
  uuids: string[];
  ignoreReason: string;
  onSuccess: Function;
  onError: Function;
}

interface BulkUnignoreParams {
  uuids: string[];
  onSuccess: Function;
  onError: Function;
}

interface EditIgnoreRuleParams {
  uuid: string;
  newReason: string;
  onSuccess: Function;
  onError: Function;
}

interface DeleteIgnoreRuleParams {
  uuid: string;
  onSuccess: Function;
  onError: Function;
}
