import { memo } from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import { PieInteraction } from "Component/PieLabel";
import { SVGText } from "Component/SVGText";

// -----------------------------------------------------------------------------

export const PieDisplay = memo(
  ({ title, data = [], loading = false }: Props) => {
    const hasAtLeastOneDatapoint = data.some((entry) => entry.value > 0);

    const CenteredMetric = ({ dataWithArc, centerX, centerY }: any) => {
      let total = 0;
      dataWithArc.forEach((datum: { value: number }) => {
        total += datum.value;
      });

      return (
        <SVGText
          x={centerX}
          y={centerY}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: "18px",
            fontWeight: 600,
          }}
        >
          {total}
        </SVGText>
      );
    };

    //
    return (
      <Card sx={{ overflow: "visible" }}>
        <CardContent>
          <Typography variant="overline">{title}</Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5" fontWeight="bold">
              {loading ? (
                <>
                  &nbsp;
                  <CircularProgress size={20} />
                </>
              ) : !hasAtLeastOneDatapoint ? (
                "None"
              ) : null}
            </Typography>
          </Box>
        </CardContent>
        {hasAtLeastOneDatapoint ? (
          <Box sx={{ height: 256 }}>
            <PieInteraction
              margin={{ top: 10, right: 10, bottom: 10, left: -200 }}
              colors={{ datum: "data.color" }}
              borderWidth={1}
              borderColor={{
                from: "color",
                modifiers: [["darker", 0.5]],
              }}
              activeOuterRadiusOffset={4}
              innerRadius={0.75}
              padAngle={1}
              cornerRadius={1}
              enableArcLabels={false}
              enableArcLinkLabels={false}
              data={data as any}
              layers={[
                "arcs",
                "arcLabels",
                "arcLinkLabels",
                "legends",
                CenteredMetric,
              ]}
              legends={[
                {
                  anchor: "right",
                  direction: "column",
                  justify: false,
                  translateX: -150,
                  translateY: 0,
                  itemsSpacing: 12,
                  itemWidth: 50,
                  itemHeight: 18,
                  itemTextColor: "#999",
                  itemDirection: "left-to-right",
                  itemOpacity: 1,
                  symbolSize: 18,
                  symbolShape: "square",
                },
              ]}
            />
          </Box>
        ) : null}
      </Card>
    );
  }
);

// -----------------------------------------------------------------------------

interface Props {
  title;
  data: Array<{ value: number }>;
  loading?: boolean;
}
