import { memo, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import { BillingModal } from "Component/Modal/BillingModal";

// -----------------------------------------------------------------------------

export const BillingPageNextTierFeatures = memo(
  ({ label, nextLabel, direction, isDowngrade, features }: Props) => {
    const [showModal, setShowModal] = useState(false);

    //
    function openModal() {
      setShowModal(true);
    }

    //
    return (
      <Paper sx={PaperSectionSX}>
        <Typography variant="h6" fontWeight="bold" sx={SectionHeaderSX}>
          {direction} to {nextLabel}
        </Typography>

        <Stack spacing={0.25} sx={NextTierFeaturesSX}>
          {isDowngrade ? (
            <Typography variant="h6">You will lose access to:</Typography>
          ) : (
            <Typography variant="h6" sx={FeatureLabelSX}>
              <CheckIcon sx={FeatureIconSX} />
              <div>
                <span>Everything in&nbsp;</span>
                <Box component="strong">{label}</Box>
              </div>
            </Typography>
          )}

          {features?.map((feature: string) => {
            const Icon = isDowngrade ? CloseIcon : CheckIcon;

            //
            return (
              <Typography variant="h6" sx={FeatureLabelSX} key={feature}>
                <Icon sx={FeatureIconSX} />
                <div>{feature}</div>
              </Typography>
            );
          }) ?? null}
        </Stack>
        <Button variant="text" sx={ContactSalesButtonSX} onClick={openModal}>
          Contact Sales
          <ArrowForwardIcon sx={ContactSalesLinkIconSX} />
        </Button>
        <BillingModal showModal={showModal} setShowModal={setShowModal} />
      </Paper>
    );
  }
);

// -----------------------------------------------------------------------------

const PaperSectionSX = { p: 1.5 };

const SectionHeaderSX = { borderBottom: "1px primary.border solid" };

const FeatureLabelSX = {
  display: "flex",
  alignItems: "flex-start",
  svg: { marginTop: "4px" },
};

const FeatureIconSX = { mr: 2 };

const NextTierFeaturesSX = { p: 2, pb: 1 };

const ContactSalesButtonSX = { ml: 2, mb: 2 };

const ContactSalesLinkIconSX = { ml: 0.5, height: 16, width: 16 };

// -----------------------------------------------------------------------------

interface Props {
  label: string;
  nextLabel: string;
  direction: string;
  isDowngrade: boolean;
  features: string[];
}
