import { memo } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useOrganizationStore } from "stores/OrganizationStore";

import { usePageTitle } from "effect/use_page_title";

import { Page } from "Component/Page";
import { PageContent } from "Component/PageContent";
import { PageHeader } from "Component/PageHeader";
import { PageHeaderBackButton } from "Component/PageHeaderBackButton";
import { PageTitle } from "Component/PageTitle";
import { ServiceConnectionInstructionPhase } from "Component/ServiceConnectionInstructionPhase";
import { ServiceConnectionPhase } from "Component/ServiceConnectionPhase";
import { ServiceConnectionPhases } from "Component/ServiceConnectionPhases";

import { Microsoft365Config } from "./config";

// -----------------------------------------------------------------------------

export const Microsoft365ConnectPage = memo(() => {
  //
  usePageTitle(`Environment → Sources → Microsoft 365 → Connect`);

  //
  const [organizationStore] = useOrganizationStore();

  //
  const redirectUrl = `https://login.windows.net/common/oauth2/authorize?${new URLSearchParams(
    {
      client_id: Microsoft365Config.clientID ?? "",
      prompt: "admin_consent",
      response_type: "code",
      resource: "https://manage.office.com",
      redirect_uri: encodeURI(
        `${window.location.origin}/environment/sources/m365/oauth2`
      ),
      state: organizationStore.uuid,
    }
  ).toString()}`;

  //
  return (
    <Page>
      <PageHeader>
        <PageHeaderBackButton to="/environment/sources">
          Back to Sources
        </PageHeaderBackButton>
        <PageTitle>Microsoft 365 Connection</PageTitle>
      </PageHeader>

      <PageContent>
        <ServiceConnectionPhases>
          <ServiceConnectionInstructionPhase>
            <Typography variant="body1">
              Connect your ThreatKey account to Microsoft 365 for comprehensive
              security monitoring.
            </Typography>
          </ServiceConnectionInstructionPhase>

          <ServiceConnectionPhase disabled={false}>
            <Stack spacing={1}>
              <Typography variant="body1">
                To make a connection, you will be redirected to Microsoft 365
                where you can securely approve our access using OAuth 2.0
                authorization. Afterwards, you&apos;ll be redirected back here.
              </Typography>
              <Box>
                <Button
                  variant="contained"
                  LinkComponent="a"
                  href={redirectUrl}
                >
                  Connect to Microsoft
                </Button>
              </Box>
            </Stack>
          </ServiceConnectionPhase>
        </ServiceConnectionPhases>
      </PageContent>
    </Page>
  );
});
