import { memo } from "react";

import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";

import { ServiceConnectionPhase } from "Component/ServiceConnectionPhase";

// -----------------------------------------------------------------------------

export const SnowflakeConnectPageTypePhase = memo(
  ({ disabled, connectionType, onConnectionTypeChange }: Props) => {
    //
    return (
      <ServiceConnectionPhase disabled={disabled}>
        <Stack spacing={1.5} direction="column">
          <FormControl>
            <FormLabel id="snowflake-connection-type-radio-label">
              Connection Type
            </FormLabel>
            <RadioGroup
              aria-labelledby="snowflake-connection-type-radio-label"
              defaultValue="oauth"
              name="connection-type-radio-group"
              onChange={(e: any) => {
                onConnectionTypeChange(e.target.value);
              }}
            >
              <FormControlLabel
                value="oauth"
                control={<Radio />}
                label="OAuth"
              />
              <FormControlLabel
                value="userpass"
                control={<Radio />}
                label="Username and Password"
              />
            </RadioGroup>
          </FormControl>
        </Stack>
      </ServiceConnectionPhase>
    );
  }
);

// -----------------------------------------------------------------------------

interface Props {
  disabled: boolean;
  connectionType: string;
  onConnectionTypeChange: Function;
}
