import html2canvas from "html2canvas";

import { dayjs } from "utility/dayjs";

// -----------------------------------------------------------------------------

export const saveAs = (uri: string, filename: string) => {
  const link = document.createElement("a");

  if (typeof link.download === "string") {
    link.href = uri;
    link.download = filename;

    // Firefox requires the link to be in the body
    document.body.appendChild(link);

    // simulate click
    link.click();

    // remove the link when done
    document.body.removeChild(link);
  } else {
    window.open(uri);
  }
};

export const exportChartAsImage = (chartId: string) => {
  const chartToCapture = document.querySelector(`#${chartId}`) as HTMLElement;
  if (chartToCapture) {
    html2canvas(chartToCapture).then((canvas) => {
      saveAs(canvas.toDataURL(), `chart-${chartId}-${dayjs().toString()}.png`);
    });
  }
};
