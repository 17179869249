import { memo, type PropsWithChildren } from "react";

import { useRequiredData } from "effect/use_required_data";

import { LoaderModal } from "Component/Modal/LoaderModal";

// -----------------------------------------------------------------------------

export const LoadRequiredData = memo(({ children }: PropsWithChildren) => {
  const ready = useRequiredData();

  //
  return ready ? (children as any) : <LoaderModal />;
});
