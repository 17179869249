import { memo, useReducer } from "react";
import { useSearchParams } from "react-router-dom";

import { ReturnIf } from "babel-plugin-transform-functional-return";
import styled from "styled-components";

import CircularProgress from "@mui/material/CircularProgress";

import { authenticateAccount } from "model/account";

import { asyncOrSwimWithSentry } from "utility/async_or_swim_sentry";

import { usePageTitle } from "effect/use_page_title";

import { LoginContainer } from "./Component/Container";
import { LoginError } from "./Component/Error";
import { LoginForm } from "./Component/Form";

// -----------------------------------------------------------------------------

export const LoginPage = memo(() => {
  usePageTitle("Login");

  //
  const [
    { errorMessage, checkEmail, contactingServer, hasSSORedirect },
    setState,
  ] = useReducer((state: any, action: any) => ({ ...state, ...action }), {
    errorMessage: useErrorMessageFromURL(),
    checkEmail: false,
    hasSSORedirect: false,
    contactingServer: false,
  });

  //
  switch (true) {
    case !!errorMessage:
      return (
        <LoginContainer>
          <LoginError errorMessage={errorMessage} reset={reset(setState)} />
        </LoginContainer>
      );

    case contactingServer:
      return (
        <LoginContainer noMaxWidth>
          <CircularProgress />
        </LoginContainer>
      );

    case hasSSORedirect:
      return (
        <LoginContainer>
          You are being redirected to your SSO provider to login. Please contact
          your admin if you experience any issues.
        </LoginContainer>
      );

    case checkEmail:
      return (
        <LoginContainer noMaxWidth>
          <Centered>
            If your email is valid, a one-time login link has been sent to you
            expiring in 15 minutes. You can close this window.
          </Centered>
        </LoginContainer>
      );

    default:
      return (
        <LoginContainer>
          <LoginForm login={login(setState)} />
        </LoginContainer>
      );
  }
});

// -----------------------------------------------------------------------------

function login(setState: Function): (emailAddress: string) => void {
  return (emailAddress: string): void => {
    const emailAddressParsed = emailAddress.trim();
    ReturnIf(emailAddressParsed.length < 1);

    //
    setState({
      contactingServer: true,
      checkEmail: false,
      hasSSORedirect: false,
      errorMessage: "",
    });
    asyncOrSwimWithSentry(
      async () => {
        const responsePayload = await authenticateAccount(emailAddressParsed);

        //
        switch (true) {
          case responsePayload.error:
            return setState({
              contactingServer: false,
              checkEmail: false,
              errorMessage: responsePayload.error,
            });

          case !!responsePayload.redirectURL:
            // nosemgrep: javascript.browser.security.open-redirect.js-open-redirect
            window.location.href = responsePayload.redirectURL;

            //
            return setState({
              contactingServer: false,
              checkEmail: false,
              hasSSORedirect: true,
            });

          case responsePayload.message === "Email sent":
            return setState({
              contactingServer: false,
              checkEmail: true,
            });

          default:
            throw new Error("Email wasn't sent");
        }
      },
      "Failed to login",
      () =>
        setState({
          contactingServer: false,
          errorMessage: "An unknown error occurred during authentication",
        })
    );
  };
}

function reset(setState: Function): () => void {
  return () =>
    setState({
      contactingServer: false,
      checkEmail: false,
      errorMessage: "",
    });
}

function useErrorMessageFromURL(): string {
  const [searchParams] = useSearchParams();

  //
  return new URLSearchParams(searchParams).get("error_msg") ?? "";
}

// -----------------------------------------------------------------------------

const Centered = styled.div`
  max-width: 25vw;
  min-width: 256px;
  text-align: center;
`;
