import { memo } from "react";

import SvgIcon from "@mui/material/SvgIcon";

// -----------------------------------------------------------------------------

export const TinesWebhookCoverImage = memo((props: object) => {
  return (
    <SvgIcon viewBox="0 0 103 32" {...props}>
      <g>
        <g>
          <g>
            <path d="M42.9689 10.5676H40V13.6565H42.9689V20.4041C42.9689 23.7629 45.1282 25.6822 48.427 25.6822H50.8561V22.3534H48.8468C47.4673 22.3534 46.6576 21.5737 46.6576 20.0442V13.6565H50.8561V10.5676H46.6576V6.00928H42.9689V10.5676Z"></path>
            <path d="M52.5751 8.19849H56.6536V4H52.5751V8.19849ZM56.4437 25.6822V10.5676H52.755V25.6822H56.4437Z"></path>
            <path d="M62.3935 25.6822V17.4352C62.3935 15.216 63.833 13.5966 65.9023 13.5966C67.7916 13.5966 69.0511 15.036 69.0511 17.1053V25.6822H72.7398V16.3256C72.7398 12.7868 70.5206 10.3277 67.1018 10.3277C64.9426 10.3277 63.2332 11.2574 62.3935 12.9068V10.5676H58.7048V25.6822H62.3935Z"></path>
            <path d="M85.399 20.644C85.0092 22.0835 83.7796 22.8632 81.8903 22.8632C79.5511 22.8632 77.9917 21.3038 77.7218 18.8747H88.8778C88.9078 18.5748 88.9377 18.1249 88.9377 17.6151C88.9377 14.0164 86.6586 10.3277 81.5904 10.3277C76.6422 10.3277 74.183 14.0764 74.183 18.065C74.183 22.0235 76.9421 25.9221 81.8903 25.9221C85.6089 25.9221 88.4279 23.8529 88.9977 20.644H85.399ZM81.5904 13.0867C83.7796 13.0867 85.0991 14.5262 85.2491 16.4455H77.8117C78.2016 14.1664 79.5211 13.0867 81.5904 13.0867Z"></path>
            <path d="M96.3428 25.9221C99.8815 25.9221 102.191 24.0628 102.191 21.2138C102.191 14.3763 93.7937 17.975 93.7937 14.7062C93.7937 13.6865 94.6634 13.0867 95.9829 13.0867C97.1525 13.0867 98.442 13.8065 98.6519 15.216H102.011C101.861 12.277 99.4316 10.3277 95.8929 10.3277C92.774 10.3277 90.4649 12.1871 90.4649 14.8261C90.4649 21.2138 98.7419 18.005 98.7419 21.3638C98.7419 22.2634 97.7822 22.9832 96.3428 22.9832C94.6933 22.9832 93.5837 22.0835 93.4338 20.644H90.075C90.2549 23.8829 92.6841 25.9221 96.3428 25.9221Z"></path>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.8018 0C8.01458 0 4.66599 2.45749 3.53258 6.06868L0.415527 16L3.53258 25.9313C4.66599 29.5425 8.01458 32 11.8018 32H20.1981C23.9853 32 27.3339 29.5425 28.4673 25.9313L31.5844 16L28.4673 6.06868C27.3339 2.45749 23.9853 0 20.1981 0H11.8018ZM20.1982 2.49634C22.8938 2.49634 25.2772 4.24548 26.0839 6.81577L26.8481 9.25062C25.3107 7.98154 23.3639 7.26723 21.3292 7.26707L10.648 7.26679C8.62691 7.26694 6.69264 7.97168 5.16015 9.22481L5.91625 6.81577C6.72297 4.24548 9.10635 2.49634 11.8019 2.49634H20.1982ZM5.73674 12.1986L3.79587 14.7519L28.1811 14.7519L26.2404 12.1989C25.0741 10.6646 23.2571 9.76356 21.329 9.76341H10.5898C8.68349 9.78153 6.89125 10.6798 5.73674 12.1986ZM28.1771 17.2482L26.2403 19.7989C25.0739 21.3349 23.2555 22.237 21.326 22.2368L10.6509 22.2366C8.72137 22.2367 6.90298 21.3346 5.73661 19.7986L3.79996 17.2482L28.1771 17.2482ZM5.9161 25.1842C6.72282 27.7545 9.1062 29.5037 11.8018 29.5037H20.1981C22.8936 29.5037 25.277 27.7545 26.0837 25.1842L26.8485 22.7476C25.3104 24.0182 23.3622 24.7333 21.3258 24.7332L10.651 24.7329C8.6283 24.7331 6.69244 24.0274 5.15921 22.7727L5.9161 25.1842Z"
            ></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
});
