import { asyncOrSwim } from "utility/async_or_swim";
import { authenticatedPostFetch } from "utility/fetch/authenticated";
import { trying } from "utility/trying";

// -----------------------------------------------------------------------------

export async function requestSalesforceQuote(
  payload: QuotePayload
): Promise<void> {
  return await trying(
    async () =>
      await authenticatedPostFetch("api/billing/request_quote", payload, {
        csrf: true,
      }),
    "requestSalesforceQuote error"
  );
}

export function requestSalesforceQuoteSynchronous(
  payload: QuotePayload,
  successHandler: () => void,
  errorHandler: (error: Error) => void
) {
  asyncOrSwim(async () => {
    await requestSalesforceQuote(payload);
    successHandler();
  }, errorHandler);
}

// -----------------------------------------------------------------------------

interface QuotePayload {
  request_full_name: string;
  request_email: string;
  request_company_name: string;
  request_domain: string;
}
