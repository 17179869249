import { type To } from "react-router-dom";

import { type Connection } from "stores/ConnectionStore";

// -----------------------------------------------------------------------------

export function getServicesByName(services: Service[]): Map<string, Service> {
  return services.reduce((servicesByName, service) => {
    const names = [service.name, ...(service.alternativeNames ?? [])];
    names.forEach((name) => servicesByName.set(name, service));

    //
    return servicesByName;
  }, new Map<string, Service>());
}

export function getConnectedServices(
  connections: Map<string, Connection>,
  services: Service[]
): ConnectedService[] {
  const servicesByName = getServicesByName(services);
  const connectedServices: ConnectedService[] = [];

  connections.forEach((connection, uuid) => {
    const connectedService = servicesByName.get(connection.kind);

    // transient Okta API key connections should not be shown
    if (
      // connection.kind !== "okta" && WHY?!
      connectedService !== undefined &&
      connection.clientSecret !== null
    ) {
      connectedServices.push({
        connection,
        service: connectedService,
      });
    }
  });

  return connectedServices;
}

export function getSearchedServices(
  services: Service[],
  searchTerms: string[]
) {
  return services.filter((service) =>
    searchTerms.some((searchTerm) => {
      return (
        service.label.toLocaleLowerCase().includes(searchTerm) ||
        service.searchTerms.some(
          (term) => term.includes(searchTerm) || searchTerm.includes(term)
        )
      );
    })
  );
}

// -----------------------------------------------------------------------------

export interface ServicesContainer {
  services: Service[];
}

export interface Service {
  name: string;
  alternativeNames?: string[];
  label: string;
  coverImage: Function;
  onConnect?: Function;
  searchTerms: string[];
  onPostOauthConnect?: Function;
  onExtraConnectionDetails?: (connect: Connection) => JSX.Element;
  customExtraDetails?: React.ElementType<{ connection: Connection }>;
  url?: string;
  notifications?: boolean;
  featureFlag?: number;
  configUrl?: To;
  internalOnly?: boolean;
  singularLabel?: string;
  connectInfo?: ConnectInfo;
  notificationKey?: string;
}

export interface ConnectedService {
  connection: Connection;
  service: Service;
}

interface ConnectInfo {
  instructions: {
    example: string;
    extraExample?: string;
    extras?: string[];
  };
  placeholder: string;
  regex: RegExp;
  path: string;
}
