import { memo, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";

import { ServiceConnectionPhase } from "Component/ServiceConnectionPhase";

// -----------------------------------------------------------------------------

export const GKEKubernetesConnectPageCompletedPhase = memo(
  ({ disabled, successIcon, onClickFn }: CompletedPhaseProps) => {
    const navigate = useNavigate();

    //
    useEffect(() => {
      const timerId = setTimeout(() => navigate("/environment/sources"), 5000);

      //
      return () => clearTimeout(timerId);
    }, [navigate]);

    //
    return (
      <ServiceConnectionPhase disabled={disabled}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {successIcon ? (
            <CheckCircleOutlineRoundedIcon color="success" sx={{ mr: 1 }} />
          ) : null}
          <Typography variant="body1">Done!</Typography>
        </Box>
        <Stack sx={{ mt: 2 }} spacing={1} direction="column">
          <Typography variant="body1">
            <Link to="/environment/sources" onClick={() => onClickFn()}>
              Click here
            </Link>{" "}
            to return to your sources. You will be redirected in a few seconds.
          </Typography>
        </Stack>
      </ServiceConnectionPhase>
    );
  }
);

// -----------------------------------------------------------------------------

interface CompletedPhaseProps {
  disabled: boolean;
  successIcon: boolean;
  onClickFn: Function;
}
