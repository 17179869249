import { EKSKubernetesConnectSetupPage } from "./Connect";
import { redirectToEKSKubernetesConnectPage } from "./connect_handler";
import { EKSKubernetesCoverImage } from "./CoverImage";

// -----------------------------------------------------------------------------

export { EKSKubernetesConnectSetupPage, redirectToEKSKubernetesConnectPage };

export const EKSKubernetesAppConfig = {
  name: "eks_kubernetes",
  label: "EKS Kubernetes",
  coverImage: EKSKubernetesCoverImage,
  onConnect: redirectToEKSKubernetesConnectPage,
  searchTerms: ["eks", "kubernetes", "k8s"],
};
