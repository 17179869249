import { type ReactFragment, memo, useMemo, useState } from "react";

import styled from "styled-components";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WarningIcon from "@mui/icons-material/Warning";

import { type FOTS } from "data/typescript";

import { useBillingStore } from "stores/BillingStore";
// import { useOrganizationStore } from "stores/OrganizationStore";
import { useUserStore } from "stores/UserStore";

import { openOrtto } from "utility/ortto";
import { userCanAccessBilling } from "utility/user";

import { BillingModal } from "Component/Modal/BillingModal";
// import { LoginPopUp } from "Component/TrialLoginModal";

// -----------------------------------------------------------------------------

export const FreeTrialBar = memo(() => {
  // const location = useLocation();
  const [userStore, actions] = useUserStore();
  const [billingStore] = useBillingStore();
  // const [organizationStore] = useOrganizationStore();

  const [modal, setModal] = useState(false);

  const changeValue = useMemo(() => {
    return (value: boolean) => {
      setModal(value);
    };
  }, []);

  // Get trial info and don't show the bar if they're not in a free trial
  // const { isTrial = false } = organizationStore.organization;
  const { subscription: { days_left: daysLeft = 0 } = {} } = billingStore;
  /* Don't show free trial bar if:
   ** 1. User is already on Billing page since there's already context and a CTA for users
   ** 2. User has dismissed the bar in this session (logging back in will resurface bar)
   ** 3. User is not in a trial
   ** 4. daysLeft is invalid
   */
  // const isBillingPage =
  //   location.pathname === "/billing";
  // const isFreeTrialBarDismissed = userStore.isFreeTrialBarDismissed;
  // const dontRender = isBillingPage || isFreeTrialBarDismissed || !isTrial;
  const days = daysLeft === 1 ? "day" : "days";
  const dontRender = true; // trial bar shouldn't render in any case for now
  //
  const canManageBilling = userCanAccessBilling(userStore.user);

  //
  function dismissTrialBar() {
    actions.dismissExpirationWarningBar();
  }

  //
  switch (true) {
    case dontRender:
      return null as FOTS;

    case daysLeft === -1:
      return (
        <TrialBar canManageBilling={canManageBilling} onClick={dismissTrialBar}>
          Your fully featured trial expired <strong>yesterday</strong>!
        </TrialBar>
      );

    case daysLeft < -7 && daysLeft > -12:
      return (
        <TrialBar canManageBilling={canManageBilling} onClick={dismissTrialBar}>
          Your fully featured trial expired <strong>last week</strong>!
        </TrialBar>
      );

    case daysLeft < 0 && daysLeft > -7:
      return (
        <TrialBar canManageBilling={canManageBilling} onClick={dismissTrialBar}>
          Your fully featured trial expired <strong>recently</strong>!
        </TrialBar>
      );

    case daysLeft < 0:
      return (
        <TrialBar canManageBilling={canManageBilling} onClick={dismissTrialBar}>
          Your fully featured trial <strong>expired</strong>{" "}
          {Math.abs(daysLeft)} {days} ago!
        </TrialBar>
      );

    case daysLeft >= 0 && daysLeft < 4:
      return (
        <TrialBar
          canManageBilling={canManageBilling}
          changeValue={changeValue}
          modal={modal}
          isCritical={true}
          days={daysLeft}
          onClick={dismissTrialBar}
        >
          Your fully featured trial will expire{" "}
          {daysLeft === 0 ? (
            <strong>today!</strong>
          ) : (
            <strong>
              in {daysLeft} {days}
            </strong>
          )}
        </TrialBar>
      );
    case daysLeft < 7 && daysLeft >= 4:
      return (
        <TrialBar
          canManageBilling={canManageBilling}
          changeValue={changeValue}
          modal={modal}
          onClick={dismissTrialBar}
          isUrgent={true}
        >
          You have{" "}
          <strong>
            {daysLeft} {days}
          </strong>{" "}
          left on your free trial
        </TrialBar>
      );

    default:
      return (
        <TrialBar canManageBilling={canManageBilling} onClick={dismissTrialBar}>
          Your fully featured trial expires in{" "}
          <strong>
            {daysLeft} {days}
          </strong>
          .
        </TrialBar>
      );
  }
});

// -----------------------------------------------------------------------------

function TrialBar({
  children = null as FOTS,
  canManageBilling = false,
  onClick = () => {},
  changeValue = () => {},
  modal = false,
  isUrgent = false,
  isCritical = false,
  days = 0,
}: {
  children: ReactFragment;
  modal?: boolean;
  isUrgent?: boolean;
  isCritical?: boolean;
  changeValue?: Function;
  canManageBilling?: boolean;
  onClick?: Function;
  days?: number;
}) {
  const colorScheme = isUrgent
    ? "trialBarUrgent"
    : isCritical
      ? "trialBarCritical"
      : "trialBarBase";

  return (
    <Box
      sx={{
        boxSizing: "border-box",
        backgroundColor: `${colorScheme}.background`,
        color: `${colorScheme}.main`,
      }}
    >
      <Content>
        {isUrgent ?? isCritical ? (
          <WarningIcon color="inherit" />
        ) : (
          <InfoOutlinedIcon color="inherit" />
        )}
        <LabelWarning>{children}</LabelWarning>
        {canManageBilling ? (
          <div>
            {/* {isCritical ? (
              <LoginPopUp days={days} setShowModal={changeValue} />
            ) : (
              <></>
            )} */}
            <BillingModal showModal={modal} setShowModal={changeValue} />
            {isUrgent ?? isCritical ? (
              <Button
                onClick={() => changeValue(true)}
                variant="contained"
                sx={{
                  whiteSpace: "no-wrap",
                }}
                color={colorScheme as FOTS}
              >
                Manage plan
              </Button>
            ) : (
              <Button
                onClick={() => openOrtto()}
                variant="contained"
                color="primary"
                sx={{ whiteSpace: "no-wrap" }}
              >
                Manage plan
              </Button>
            )}
          </div>
        ) : (
          <div />
        )}
        <IconButton
          aria-label="delete"
          onClick={onClick as FOTS}
          color="inherit"
        >
          <CloseIcon />
        </IconButton>
      </Content>
    </Box>
  );
}

// -----------------------------------------------------------------------------

const Content = styled.div`
  align-items: center;
  display: grid;
  gap: 8px;
  grid-template-columns: auto 1fr auto 40px;
  padding: 8px clamp(8px, 0.5rem + (1vw - 3.75px) * 1.9394, 24px);
`;

const LabelWarning = styled(Typography)`
  flex-grow: 1;
`;
