import { type Theme } from "@mui/material/styles";

// -----------------------------------------------------------------------------

export const baseTheme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 800,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: "100%",
        },
        body: {
          display: "flex !important",
          flexDirection: "column",
          height: "100%",
        },
        "#root": {
          display: "flex !important",
          flexDirection: "column",
          flexGrow: 1,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          border: 0,
          borderRadius: 0,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          border: `1px solid ${
            theme.palette.muiPaperOverride.border as string
          }`,
          borderRadius: 12,
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          boxShadow: " 0 2px 4px rgba(0, 0, 0, 0.1)",
          "&:hover": {
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.15)",
          },
        },
        text: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
      },
    },
  },
};
