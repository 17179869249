import { memo } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useOrganizationStore } from "stores/OrganizationStore";

import { usePageTitle } from "effect/use_page_title";

import { Page } from "Component/Page";
import { PageContent } from "Component/PageContent";
import { PageHeader } from "Component/PageHeader";
import { PageHeaderBackButton } from "Component/PageHeaderBackButton";
import { PageTitle } from "Component/PageTitle";
import { ServiceConnectionInstructionPhase } from "Component/ServiceConnectionInstructionPhase";
import { ServiceConnectionPhase } from "Component/ServiceConnectionPhase";
import { ServiceConnectionPhases } from "Component/ServiceConnectionPhases";

import { BoxConfig } from "./config";

// -----------------------------------------------------------------------------

export const BoxConnectPage = memo(() => {
  //
  usePageTitle(`Environment → Sources → Box → Connect`);

  //
  const [organizationStore] = useOrganizationStore();

  //
  const redirectUrl = `https://account.box.com/api/oauth2/authorize?${new URLSearchParams(
    {
      client_id: BoxConfig.clientID,
      redirect_uri: encodeURI(
        `${window.location.origin}/environment/sources/box/oauth2`
      ),
      state: organizationStore.uuid,
      response_type: "code",
    } as any
  ).toString()}`;

  //
  return (
    <Page>
      <PageHeader>
        <PageHeaderBackButton to="/environment/sources">
          Back to Sources
        </PageHeaderBackButton>
        <PageTitle>Box Connection</PageTitle>
      </PageHeader>

      <PageContent>
        <ServiceConnectionPhases>
          <ServiceConnectionInstructionPhase>
            <Typography variant="body1">
              Connect your ThreatKey account to Box for comprehensive security
              monitoring.
            </Typography>
          </ServiceConnectionInstructionPhase>

          <ServiceConnectionPhase disabled={false}>
            <Stack spacing={1}>
              <Typography variant="body1">
                To make a connection, you will be redirected to Box where you
                can authorize ThreatKey to access your Box account. ThreatKey
                will only be able to access the data it needs to monitor your
                Box account for security issues.
              </Typography>
              <Box>
                <Button
                  variant="contained"
                  LinkComponent="a"
                  href={redirectUrl}
                >
                  Connect to Box
                </Button>
              </Box>
            </Stack>
          </ServiceConnectionPhase>
        </ServiceConnectionPhases>
      </PageContent>
    </Page>
  );
});
