import { memo } from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";

import MenuItem from "@mui/material/MenuItem";

import { LogoIcon } from "Component/Icon/Logo";

// -----------------------------------------------------------------------------

export const HeaderLogo = memo(() => {
  return (
    <MenuItemStyled disableRipple disableTouchRipple disableGutters>
      <LinkedStyled to="/analytics">
        <LogoIconStyled />
      </LinkedStyled>
    </MenuItemStyled>
  );
});

// -----------------------------------------------------------------------------

const MenuItemStyled = styled(MenuItem)`
  padding: 0;

  &:hover {
    background: none !important;
  }
`;

const LinkedStyled = styled(Link)`
  display: flex;
  padding-left: 8px;
`;

const LogoIconStyled = styled(LogoIcon)`
  height: 40px !important;
  min-height: 0vw;
  padding: 0;
  width: clamp(100px, calc(6.25rem + ((1vw - 3.75px) * 4.8485)), 140px);

  path {
    fill: ${(props) => props.theme.appLogo.main};
  }
`;
