import { type NavigateFunction } from "react-router-dom";

import { type OrgStoreState } from "stores/OrganizationStore";

// -----------------------------------------------------------------------------

export function redirectToGCPConnectPage(
  orgStore: OrgStoreState,
  navigate: NavigateFunction
): void {
  navigate("/environment/sources/gcp/connect");
}
