import { memo } from "react";

import SvgIcon from "@mui/material/SvgIcon";

// -----------------------------------------------------------------------------

export const CasSAMLSSOIcon = memo((props: object) => {
  return (
    <SvgIcon viewBox="0 15 286.94 109.47" {...props} overflow="visible">
      <path
        d="M954.9,401.53l-10.83,23.86c-7.05-1.79-13.52-3.79-20.13-5a22.91,22.91,0,0,0-21.75,7.06c-4.79,5-4.93,10.42-.14,15.38a49.09,49.09,0,0,0,7,5.56c8.34,6,16.82,11.77,25.07,17.88,7.32,5.42,10.17,13.07,10.45,22,.46,14.49-4.12,26.66-15.68,35.83-9.36,7.42-20.29,10.4-32,10.42-7.61,0-15.22-.8-22.82-1.33-2-.14-4-.52-4.22-3.24-.65-7.72-1.39-15.44-2.1-23.17,5.43,1.36,10.6,2.88,15.85,3.92,6.94,1.38,13.82.69,20.68-1.1,5.74-1.5,9.36-4.84,10.35-10.68,1.09-6.47-.31-12.3-5.91-16.27-5.93-4.22-12.26-7.9-18.18-12.14a125,125,0,0,1-12.26-10.12c-10.45-9.75-12.66-21.84-7.27-34.5,6-14.13,16.59-24.24,31.84-28.49,7.43-2.07,15.13-1.92,22.76-1.43A124.61,124.61,0,0,1,954.9,401.53Z"
        fill={props.theme?.casSAMLLogo.main}
        transform="translate(-667.96 -395.03)"
      />
      <path
        d="M752.92,506.34c-2.53,7.26-4.84,13.8-7.06,20.36-.64,1.91-1.68,3.09-3.77,3.36-13.81,1.81-27.5,1.69-40.73-3.22-16.88-6.27-27-18.63-31-35.92-9-38.33,8.38-69.56,40.85-87.16,9.69-5.26,20.08-7.29,30.93-8.25A114.9,114.9,0,0,1,778.62,398c.77.19,1.5.56,2.81,1.05q-3.95,11.61-7.71,22.63c-6.25-1.06-12.07-2.32-18-3-22.7-2.61-41.26,7.7-50,28.35-3.51,8.27-5.83,16.79-5.74,25.94.14,14.49,7,24.5,19.93,30.45A48.24,48.24,0,0,0,752.92,506.34Z"
        fill={props.theme?.casSAMLLogo.main}
        transform="translate(-667.96 -395.03)"
      />
      <path
        d="M792.05,501.38l-13.19,29.68H747.68L813,399h36.59c.69,5,1.44,10,2.07,15q3.46,27.78,6.86,55.58c.55,4.59.89,9.21,1.33,13.82-9.12-3.27-18.4-5.76-28.15-6.1-.76-11-1.48-22.09-2.29-33.14-.51-6.91-1.16-13.81-1.78-21.2-.64,1.09-1.11,1.74-1.43,2.46q-6.4,14.24-12.76,28.48c-4.2,9.5-8.34,19-12.51,28.54-2.28,1.29-4.53,2.64-6.84,3.86-6.27,3.32-11.76,7.64-17.63,13.08,4.25.38,7.62.63,11,1C789,500.61,790.52,501.06,792.05,501.38Z"
        fill={props.theme?.casSAMLLogo.main}
        transform="translate(-667.96 -395.03)"
      />
      <path
        d="M860.9,487.58c.8,7.22,1.59,14.45,2.43,21.67S865.05,523.6,866,531H835.88c-1.11-15.22-2.2-30.35-3.3-45.49,3.37-.66,6.73-1.75,10.12-1.9C849,483.36,855,485.42,860.9,487.58Z"
        fill={props.theme?.casSAMLLogo.main}
        transform="translate(-667.96 -395.03)"
      />
      <path
        d="M860.9,487.58c-5.89-2.16-11.86-4.22-18.2-3.94-3.39.15-6.75,1.24-10.12,1.9-12,3.33-22.44,9-30.09,19.61-2-1-4.13-2.27-6.36-3.16a17.47,17.47,0,0,0-4.08-.61c-1.53-.32-3-.77-4.59-.95-3.35-.38-6.72-.63-11-1,5.87-5.44,11.36-9.76,17.63-13.08,2.31-1.22,4.56-2.57,6.84-3.86,1.24-.43,2.49-.82,3.71-1.28a59.79,59.79,0,0,1,27.06-3.86c9.75.34,19,2.83,28.15,6.1a71.48,71.48,0,0,1,21.24,12.28c2.16,1.87,4.41,3.86,4.47,8.41A79.88,79.88,0,0,0,860.9,487.58Z"
        fill="#72c063"
        transform="translate(-667.96 -395.03)"
      />
    </SvgIcon>
  );
});
