import { memo, useState } from "react";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { type FOTS } from "data/typescript";

import { ServiceConnectionPhase } from "Component/ServiceConnectionPhase";

// -----------------------------------------------------------------------------

const MatchARNUserIdRegex: RegExp = /arn:aws:iam::([0-9]{12}):role\/.+/;

// -----------------------------------------------------------------------------

export const AmazonWebServicesConnectPageDesignateAWSRolePhase = memo(
  ({ disabled, onNext, onBack }: Props) => {
    const [arn, setArn] = useState("");

    //
    const userIdRaw = arn
      ? arn.match(MatchARNUserIdRegex)?.[1] ?? undefined
      : undefined;
    const userId = userIdRaw ?? undefined;
    const userIdValid = arn ? userIdRaw !== undefined : true;
    const userIdErrorMessage = userIdValid
      ? undefined
      : "The supplied ARN is invalid";

    //
    return (
      <ServiceConnectionPhase disabled={disabled}>
        <Typography variant="body1">
          Please paste the value of <strong>Role</strong> output from the
          CloudFormation output - It should be an Amazon Web Services ARN for a
          role called <strong>ThreatKey</strong>. If you do not have this value,
          please go back to the previous step and follow the instructions.
        </Typography>
        <TextField
          fullWidth
          label="Role ARN"
          variant="outlined"
          placeholder="arn:aws:iam::"
          sx={{ mt: 1 }}
          error={!userIdValid}
          helperText={userIdErrorMessage}
          value={arn}
          onChange={(e: FOTS) => setArn(e?.target?.value.trim())}
          data-private="lipsum"
        />
        <Stack sx={{ mt: 2 }} spacing={2} direction="row">
          <Button variant="contained" onClick={() => onNext(userId)}>
            Connect to Amazon Web Services
          </Button>
          <Button
            variant="text"
            onClick={onBack as React.MouseEventHandler<HTMLButtonElement>}
          >
            Back
          </Button>
        </Stack>
      </ServiceConnectionPhase>
    );
  }
);

// -----------------------------------------------------------------------------

interface Props {
  disabled: boolean;
  onNext: Function;
  onBack: Function;
}
