import { type ReactNode, memo, useState, useRef } from "react";

import styled from "styled-components";

import Popover from "@mui/material/Popover";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { classIf } from "utility/style";

// -----------------------------------------------------------------------------

export const FakeModalDropdown = memo(({ label, children }: Props) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  //
  function onOpen() {
    setOpen(true);
  }

  function onClose() {
    setOpen(false);
  }

  //
  return (
    <>
      <Container onClick={onOpen} ref={ref} className={classIf(open, "open")}>
        <Icon />
        <Label>{label}</Label>
      </Container>

      <Popover
        open={open}
        onClose={onClose}
        anchorEl={() => ref.current as Element}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        slotProps={{
          paper: {
            sx: {
              maxHeight: "50vh",
              width:
                "calc(800px - clamp(8px, 0.5rem + (1vw - 3.75px) * 1.9394, 24px) - clamp(8px, 0.5rem + (1vw - 3.75px) * 1.9394, 24px))",
              left: "50% !important",
              transform: "translateX(-50%) !important",
              padding: 0,
              zIndex: 9999,
            },
          },
        }}
      >
        {children.map((value, i) => (
          <Item key={i}>{value}</Item>
        ))}
      </Popover>
    </>
  );
});

// -----------------------------------------------------------------------------

const Icon = styled(ArrowDropDownIcon)`
  margin-top: -1px;
  transform: rotate(-90deg);
`;

const Container = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 4px;
  min-height: 30px;
  position: relative;

  &.open {
    ${Icon} {
      transform: rotate(180deg);
    }
  }
`;

const Label = styled.div``;

const Item = styled.div`
  padding: 8px;
  word-break: break-all;

  &:nth-child(even) {
    background: #00000007;
  }
`;

// -----------------------------------------------------------------------------

interface Props {
  label: ReactNode;
  children: ReactNode[];
}
