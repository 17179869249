import { memo, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import isEmpty from "lodash/isEmpty";

import Box from "@mui/material/Box";

import { useUserStore } from "stores/UserStore";

import { useUpdateTalkWidgetColors } from "effect/use_update_talk_widget_colors";

import { LoaderModal } from "Component/Modal/LoaderModal";

import { RemoveLoader } from "../RemoveLoader";

import { CelebrationOverlay } from "./CelebrationOverlay";
import { SpecialThing } from "./SpecialThing";

// -----------------------------------------------------------------------------

export const WithoutNavigationLayout = memo(({ requireNoAuth }: Props) => {
  const [userStore] = useUserStore();
  const navigate = useNavigate();

  const [renderContent, setRenderContent] = useState(false);

  //
  useEffect(
    () =>
      requireNoAuth && !isEmpty(userStore.user)
        ? navigate("/analytics")
        : setRenderContent(true),
    [userStore, requireNoAuth, navigate]
  );

  useUpdateTalkWidgetColors();

  //
  return renderContent ? (
    <>
      <RemoveLoader />
      <SpecialThing />
      <CelebrationOverlay />
      <Box sx={{ width: "100%", height: "100%" }}>
        <Box
          sx={{
            backgroundColor: "appHeader.main",
            color: "primary.contrastText",
            display: "flex",
            height: "100%",
            padding: 2,
            width: "100%",
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </>
  ) : (
    <LoaderModal />
  );
});

// -----------------------------------------------------------------------------

interface Props {
  requireNoAuth: boolean;
}
