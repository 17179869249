import { type NavigateFunction } from "react-router-dom";

import { type OrgStoreState } from "stores/OrganizationStore";

// -----------------------------------------------------------------------------

export function redirectToBoxOauthUrl(
  _orgStore: OrgStoreState,
  navigate: NavigateFunction
): void {
  navigate("/environment/sources/box/connect");
}
