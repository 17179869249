import { memo, useState } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { type FOTS } from "data/typescript";

import { useIgnoreStore } from "stores/IgnoreStore";

import { dayjs } from "utility/dayjs";
import { notifyError, notifySuccess } from "utility/notify";

import { type TableRule } from "./data";

// -----------------------------------------------------------------------------

export const EditIgnoreRuleDialog = memo(({ rule, onClose }: Props) => {
  const [, ignoreActions] = useIgnoreStore();

  const [ignoreReason, setIgnoreReason] = useState(rule.ignore_reason);
  const [loading, setLoading] = useState(false);

  //
  const onSave = () => {
    setLoading(true);

    ignoreActions.editIgnoreRule({
      uuid: rule.uuid,
      newReason: ignoreReason ?? "",
      onSuccess,
      onError,
    });
  };

  const onSuccess = () => {
    ignoreActions.listIgnoreRules();
    notifySuccess("Saved");
    setLoading(false);
    onClose();
  };

  const onError = () => {
    notifyError("Something went wrong saving the changes");
    setLoading(false);
    onClose();
  };

  //
  const getHelperText = () => {
    switch (rule.rule_type) {
      case "Asset ID":
        return (
          <>
            You have chosen to ignore all current and future findings with the
            asset id:{" "}
            <Box
              component="span"
              sx={{ fontWeight: "bold", wordBreak: "break-word" }}
            >
              {rule.details}
            </Box>
          </>
        );
      case "Actor":
        return (
          <>
            You have chosen to ignore all current and future findings with the
            actor:{" "}
            <Box
              component="span"
              sx={{ fontWeight: "bold", wordBreak: "break-word" }}
            >
              {rule.details}
            </Box>
          </>
        );
      case "Finding Type":
        return (
          <>
            You have chosen to ignore all current and future findings of type{" "}
            <Box
              component="span"
              sx={{ fontWeight: "bold", wordBreak: "break-word" }}
            >
              {rule.details}
            </Box>
          </>
        );
      case "Regex":
        return (
          <>
            You have chosen to ignore all current and future findings matching
            the regex{" "}
            <Box
              component="span"
              sx={{ fontWeight: "bold", wordBreak: "break-word" }}
            >
              {rule.details}
            </Box>
          </>
        );
      default:
        return (
          <>
            You have chosen to ignore the finding{" "}
            <Box
              component="span"
              sx={{ fontWeight: "bold", wordBreak: "break-word" }}
            >
              {rule.details}
            </Box>
          </>
        );
    }
  };

  return (
    <Dialog open={true} onClose={onClose} fullWidth>
      <DialogTitle>Edit Rule</DialogTitle>
      <DialogContent>
        <Typography variant="body1">1 selected rule:</Typography>
        <List
          sx={{
            listStyleType: "disc",
            pl: 3,
            pt: 0,
            pb: 2,
          }}
        >
          <ListItem
            sx={{
              display: "list-item",
              pl: 1,
              pr: 0,
              pb: 0,
            }}
            key={rule.uuid}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body1" sx={{ wordBreak: "break-all" }}>
                {rule.details}
              </Typography>
              <Typography
                variant="body1"
                sx={{ whiteSpace: "nowrap", color: "text.secondary", ml: 1 }}
              >
                {dayjs(rule.created_at).fromNow()}
              </Typography>
            </Box>
          </ListItem>
        </List>

        <FormControl fullWidth sx={{ mt: 1 }} disabled={true}>
          <InputLabel id="ignore-rule-type-select-label">Rule type</InputLabel>
          <Select
            labelId="ignore-rule-type-select-label"
            id="ignore-rule-type-select"
            required
            value={rule.rule_type}
            label="Rule type"
          >
            <MenuItem value="Finding Type">Finding type</MenuItem>
            <MenuItem value="Actor">Actor</MenuItem>
            <MenuItem value="Asset ID">Asset ID</MenuItem>
            <MenuItem value="Regex">Asset Regex</MenuItem>
            <MenuItem value="Instance">Instance</MenuItem>
          </Select>
        </FormControl>
        <Typography
          variant="body1"
          sx={{ mt: 1, mb: 2, color: "text.secondary" }}
        >
          {getHelperText()}
        </Typography>

        <Typography sx={{ mb: 2 }}>Reason: (optional)</Typography>
        <TextField
          id="ignore-reason-text-field"
          placeholder="Describe why you are creating this rule"
          variant="outlined"
          multiline
          maxRows={4}
          fullWidth
          value={ignoreReason}
          onChange={(e: FOTS) => setIgnoreReason(e?.target?.value)}
        />
      </DialogContent>

      <DialogActions sx={{ pt: 0, pb: 3, px: 3 }}>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton loading={loading} variant="contained" onClick={onSave}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
});

// -----------------------------------------------------------------------------

interface Props {
  rule: TableRule;
  onClose: () => void;
}
