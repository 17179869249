import { ZapierWebhookCoverImage } from "external/destination/ZapierWebhook/CoverImage";

// -----------------------------------------------------------------------------

export const ZapierWebhookAppConfig = {
  name: "zapier_webhook",
  label: "Zapier Webhooks",
  singularLabel: "Zapier Webhook",
  coverImage: ZapierWebhookCoverImage,
  searchTerms: [],
  url: "zapier_webhook",
  connectInfo: {
    instructions: {
      example: "Zapier workflow",
      extras: [
        "ThreatKey Zapier Webhooks can be used with a variety of integrations offered on the Zapier platform. The Zap can then send the security alerts to a variety of different services, such as Google Sheets, Ops Genie, and Trello.",
      ],
    },
    placeholder: "https://hooks.zapier.com/hooks/catch/123456/abC12",
    regex:
      // Disable this check so we match the backend regex exactly.
      // eslint-disable-next-line no-useless-escape
      /^(?:https:\/\/)(?:[^@\/\n]+@)?(?:www\.)?hooks.zapier.com\/hooks\/catch\/[0-9]+\/[a-zA-Z0-9]{5}/,
    path: "zapier/webhook",
  },
  notifications: true,
  notificationKey: "zapier_webhook",
};
