import { memo, useEffect, useState } from "react";

import { DoIf, ReturnIf } from "babel-plugin-transform-functional-return";
import styled from "styled-components";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { useConnectionStore } from "stores/ConnectionStore";
import { ActionState, SecretState } from "stores/lib";

import {
  type ConnectedService,
  getConnectedServices,
} from "utility/connections";
import { classIf, classes } from "utility/style";

import { ExternalDestinations } from "external/destinations";

import { EnvironmentConnectedService } from "Component/ConnectedService";

import { GhostEnvironmentConnectedSources as Loading } from "./Loading";

// -----------------------------------------------------------------------------

export const EnvironmentConnectedDestinations = memo(() => {
  const [store] = useConnectionStore();

  const [connections, setConnections] = useState<any>([]);
  const [connectedDestinations, setConnectedDestinations] = useState<
    ConnectedService[]
  >([]);
  const [failedConnections, setFailedDestinations] = useState<
    ConnectedService[]
  >([]);
  const [pendingConnections, setPendingDestinations] = useState<
    ConnectedService[]
  >([]);
  const [expansionTypes, setExpansionTypes] = useState({
    connectedExpand: true,
    failedExpand: true,
    pendingExpand: true,
  });

  //
  useEffect(() => {
    DoIf(
      store.loadConnections.state === ActionState.COMPLETED,
      setConnections(store.connections)
    );
  }, [store.loadConnections.state, store.connections]);
  useEffect(() => {
    const [connected, failed, pending] = [[], [], []] as [
      ConnectedService[],
      ConnectedService[],
      ConnectedService[],
    ];
    getConnectedServices(connections, ExternalDestinations.services).forEach(
      (element) => {
        switch (element?.connection?.clientSecret?.state) {
          case SecretState.CORRECT:
            connected.push(element);
            break;
          case SecretState.ERROR:
          case SecretState.INCORRECT:
            failed.push(element);
            break;
          default:
            pending.push(element);
        }
      }
    );
    setConnectedDestinations(connected);
    setFailedDestinations(failed);
    setPendingDestinations(pending);
  }, [connections]);

  //
  // TODO extract as react components
  function RenderPendingDestinations() {
    ReturnIf(pendingConnections.length === 0, null);

    //
    return (
      <Container
        onClick={() =>
          setExpansionTypes((prevVal) => {
            return {
              ...prevVal,
              pendingExpand: !prevVal.pendingExpand,
            };
          })
        }
        className={classes(classIf(expansionTypes.pendingExpand, "expanded"))}
      >
        <Typography gutterBottom variant="h5" fontWeight="bold">
          <Arrow /> Pending ({pendingConnections.length})
        </Typography>
        <Expanded onClick={(event) => event.stopPropagation()}>
          {expansionTypes.pendingExpand && (
            <Stack
              direction="column"
              spacing={1}
              style={{ marginBottom: "7px" }}
            >
              {pendingConnections.map(({ connection, service }) => {
                return (
                  <EnvironmentConnectedService
                    connection={connection}
                    service={service}
                    key={connection.uuid}
                  />
                );
              })}
            </Stack>
          )}
        </Expanded>
      </Container>
    );
  }

  function RenderConnectedDestinations() {
    ReturnIf(connectedDestinations.length === 0, null);

    //
    return (
      <Container
        onClick={() =>
          setExpansionTypes((prevVal) => {
            return {
              ...prevVal,
              connectedExpand: !prevVal.connectedExpand,
            };
          })
        }
        className={classes(classIf(expansionTypes.connectedExpand, "expanded"))}
      >
        <Typography gutterBottom variant="h5" fontWeight="bold">
          <Arrow /> Connected Destinations ({connectedDestinations.length})
        </Typography>
        <Expanded onClick={(event) => event.stopPropagation()}>
          {" "}
          {expansionTypes.connectedExpand && (
            <Stack
              direction="column"
              spacing={1}
              style={{ marginBottom: "7px", cursor: "default" }}
            >
              {connectedDestinations.map(({ connection, service }) => {
                return (
                  <EnvironmentConnectedService
                    connection={connection}
                    service={service}
                    key={connection.uuid}
                  />
                );
              })}
            </Stack>
          )}
        </Expanded>
      </Container>
    );
  }

  function RenderFailedDestinations() {
    ReturnIf(failedConnections.length === 0, null);

    //
    return (
      <Container
        onClick={() =>
          setExpansionTypes((prevVal) => {
            return {
              ...prevVal,
              failedExpand: !prevVal.failedExpand,
            };
          })
        }
        className={classes(classIf(expansionTypes.failedExpand, "expanded"))}
      >
        <Typography gutterBottom variant="h5" fontWeight="bold">
          <Arrow /> Failed Connections ({failedConnections.length})
        </Typography>
        <Expanded onClick={(event) => event.stopPropagation()}>
          {expansionTypes.failedExpand && (
            <Stack direction="column" spacing={1}>
              {failedConnections.map(({ connection, service }) => {
                return (
                  <EnvironmentConnectedService
                    connection={connection}
                    service={service}
                    key={connection.uuid}
                  />
                );
              })}
            </Stack>
          )}
        </Expanded>
      </Container>
    );
  }

  //
  return (
    <Stack direction="column" spacing={1}>
      {store.loadConnections.state === ActionState.IN_PROGRESS ? (
        <Loading />
      ) : (
        <>
          <RenderConnectedDestinations />
          <RenderFailedDestinations />
          <RenderPendingDestinations />
        </>
      )}
    </Stack>
  );
});

// -----------------------------------------------------------------------------

const Arrow = styled(KeyboardArrowRightIcon)`
  margin-top: 0px;
  transition: 500ms ease;
`;

const Expanded = styled(Box)`
  transition: 500ms ease;
`;
const Container = styled(Box)`
  background: ${(props) => props.theme.connectAndDestDropDownColor.main};
  border-radius: 20px;
  cursor: pointer;
  margin-bottom: 2vh;
  margin-top: 8px;
  padding: 1.5rem;
  position: relative;

  &.expanded {
    ${Arrow} {
      transform: rotateZ(90deg);
    }
  }
`;
