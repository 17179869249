import { memo } from "react";

import SvgIcon from "@mui/material/SvgIcon";

// -----------------------------------------------------------------------------

export const ADFSSAMLSSOIcon = memo((props: object) => {
  return (
    <SvgIcon viewBox="0 0 102 30" {...props}>
      <path
        d="M41.7817 22.7277H44.109L45.4472 18.9459H52.6617L53.9999 22.7277H56.4145L50.3054 6.49501H47.8908L41.7817 22.7277ZM46.0872 17.1423L49.0545 8.79319L52.0217 17.1423H46.0872Z"
        fill="#757575"
      />
      <path
        d="M59.9192 22.7277H65.0102C70.3629 22.7277 72.8065 19.4696 72.8065 14.6114C72.8065 9.75319 70.3629 6.49501 65.0102 6.49501H59.9192V22.7277ZM62.1883 20.8077V8.41501H65.0683C69.4029 8.41501 70.4793 11.5568 70.4793 14.6114C70.4793 17.6659 69.4029 20.8077 65.0683 20.8077H62.1883Z"
        fill="#757575"
      />
      <path
        d="M79.4327 22.7277V15.3968H86.269V13.4477H79.4327V8.41501H87.3745V6.49501H77.1636V22.7277H79.4327Z"
        fill="#757575"
      />
      <path
        d="M89.6688 17.9568C89.9015 21.3605 92.4906 23.0187 96.0397 23.0187C99.0943 23.0187 101.974 21.7677 101.974 18.3932C101.974 15.9496 100.432 14.7277 98.1924 14.0877L94.6434 13.0696C93.1015 12.6332 92.3161 11.7896 92.3161 10.5096C92.3161 9.02592 93.3924 8.09501 95.487 8.09501C97.1452 8.09501 99.0361 8.67683 99.2397 11.1786H101.48C101.276 7.94956 99.0361 6.2041 95.6615 6.2041C92.6652 6.2041 90.047 7.54228 90.047 10.655C90.047 12.8659 91.3852 14.2623 93.8288 14.9605L97.3779 15.9787C98.8906 16.415 99.7052 17.2877 99.7052 18.5387C99.7052 20.0514 98.5415 21.1277 96.1561 21.1277C94.207 21.1277 92.1415 20.4296 91.967 17.9568H89.6688Z"
        fill="#757575"
      />
      <rect fill="#F35123" height="14" width="14" />
      <rect fill="#7FB902" height="14" width="14" x="15.9998" />
      <rect fill="#FFB901" height="14" width="14" x="15.9998" y="16" />
      <rect fill="#02A4EF" height="14" width="14" y="16" />
    </SvgIcon>
  );
});
