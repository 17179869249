import { memo } from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";

import { ServiceConnectionPhase } from "Component/ServiceConnectionPhase";

// -----------------------------------------------------------------------------

export const AmazonWebServicesConnectPageRunningPhase = memo(
  ({ disabled, progressIcon, successIcon, errorIcon }: Props) => {
    return (
      <ServiceConnectionPhase disabled={disabled}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {progressIcon ? (
            <CircularProgress size="24px" sx={{ mr: 1 }} />
          ) : null}
          {successIcon ? (
            <CheckCircleOutlineRoundedIcon color="success" sx={{ mr: 1 }} />
          ) : null}
          {errorIcon ? (
            <ErrorOutlineRoundedIcon color="error" sx={{ mr: 1 }} />
          ) : null}
          <Typography variant="body1">
            Provisioning your AWS connection
          </Typography>
        </Box>
        <Stack sx={{ mt: 2 }} spacing={1} direction="column">
          <Typography variant="body1">
            We&apos;re setting up a read-only IAM role called{" "}
            <strong>ThreatKeyAudit</strong>. In addition to the IAM role,
            we&apos;re also configuring{" "}
            <Link
              href="https://aws.amazon.com/cloudtrail/"
              target="_blank"
              rel="noopener noreferrer"
            >
              AWS CloudTrail audit logs.
            </Link>{" "}
            We use the read-only IAM role and the CloudTrail logs to monitor
            your AWS environment.
          </Typography>

          <Typography variant="body1">
            For further details, refer to{" "}
            <Link
              href="https://docs.threatkey.com/docs/sources/amazon-web-services"
              target="_blank"
              rel="noopener noreferrer"
            >
              our technical documentation
            </Link>
            .
          </Typography>
        </Stack>
      </ServiceConnectionPhase>
    );
  }
);

// -----------------------------------------------------------------------------

interface Props {
  disabled: boolean;
  progressIcon: boolean;
  successIcon: boolean;
  errorIcon: boolean;
}
