import { ThrowIf } from "babel-plugin-transform-functional-return";

import { notifyError, notifySuccess } from "utility/notify";
import { trying } from "utility/trying";

// -----------------------------------------------------------------------------

export async function copyElementContentsToClipboard(
  element: HTMLElement
): Promise<string> {
  return await trying(
    async () => {
      ThrowIf(!element, new Error("No element to copy"));

      //
      const contents = element.innerText.trim();

      //
      await navigator?.clipboard.writeText(contents);
      notifySuccess("Copied to clipboard");

      //
      return contents;
    },
    "Failed to copy to clipboard",
    () => {
      notifyError("Failed to copy to clipboard");

      //
      return "";
    }
  );
}

// CHANGELOG
// -----------------------------------------------------------------------------

// - 09/08/2023
// Steven - Function returns copied contents. Not used currently, but may be
// useful one day. Also, it throws an error if there is no element to copy.

// - 09/10/2023
// Steven - Function no longer swallows the error of not having the ability to
// copy to clipboard.
