import { type Finding, type ServerFinding } from "data/finding";

// -----------------------------------------------------------------------------

export function toFinding(serverFinding: ServerFinding): Finding {
  return {
    ...serverFinding,

    create_time: new Date(serverFinding.create_time),
    event_time: new Date(serverFinding.event_time),
    resolved_time: serverFinding.resolved_time
      ? new Date(serverFinding.resolved_time)
      : undefined,
  };
}
