import { memo } from "react";

import SvgIcon from "@mui/material/SvgIcon";

// -----------------------------------------------------------------------------

export const ClassLinkSAMLSSOIcon = memo((props: object) => {
  return (
    <SvgIcon viewBox="0 0 577 116" {...props}>
      <path
        d="M206.5 50.9C206.5 40.6 204.4 36.1 197.9 36.1C190.6 36.1 188.9 43.4 188.9 63.5C188.9 85 191.9 88.9 198.1 88.9C203.3 88.9 207.1 86.2 207.1 70.9H221.4C221.4 86.2 217.6 99.5 198.8 99.5C177.3 99.5 174.6 84.2 174.6 62.6C174.6 41 177.3 25.7 198.8 25.7C219.3 25.7 220.8 40.7 220.8 51.1C220.9 50.9 206.5 50.9 206.5 50.9Z"
        fill={props.theme?.classLinkSAMLLogo.main}
      />
      <path
        d="M227.7 26.9H241.4V98H227.7V26.9Z"
        fill={props.theme?.classLinkSAMLLogo.main}
      />
      <path
        d="M249 60.9V59.6C249 47.3 257.5 43.1 267.8 43.1C284.1 43.1 287.2 49.9 287.2 59.5V87.7C287.2 92.7 287.5 95 288.9 98H275.8C274.9 96.3 274.5 94.2 274.1 92.4H273.9C270.7 98 266.8 99.4 260.3 99.4C250.7 99.4 247.4 91.2 247.4 84.1C247.4 74 251.5 70 260.9 67.4L268.7 65.3C272.8 64.2 274.2 62.6 274.2 59C274.2 54.9 272.4 52.4 267.8 52.4C263.6 52.4 261.4 55.2 261.4 59.4V60.9C261.4 60.9 249 60.9 249 60.9ZM274.1 71.2C272.2 72.6 269.9 73.5 268.2 73.9C262.6 75.1 260.4 77.8 260.4 82.6C260.4 86.7 261.9 90.1 265.6 90.1C269.3 90.1 274.1 87.7 274.1 81.8V71.2Z"
        fill={props.theme?.classLinkSAMLLogo.main}
      />
      <path
        d="M305.1 80.5V82.3C305.1 87 307.6 90.1 312.3 90.1C316.5 90.1 319.3 88 319.3 83.7C319.3 80.2 317 78.6 314.3 77.6L304.6 74.1C297 71.4 293.1 66.7 293.1 59.2C293.1 50.3 298.7 43.1 312.8 43.1C325.3 43.1 331.1 48.7 331.1 58.3V60.5H318.7C318.7 55.1 317.1 52.4 312.6 52.4C309.1 52.4 306.1 54.4 306.1 58.2C306.1 60.8 307.5 63 312.1 64.5L320.4 67.3C329.1 70.2 332.3 74.6 332.3 82.6C332.3 93.8 323.9 99.4 312.4 99.4C297 99.4 292.7 92.6 292.7 82.4V80.5H305.1Z"
        fill={props.theme?.classLinkSAMLLogo.main}
      />
      <path
        d="M349.3 80.5V82.3C349.3 87 351.8 90.1 356.5 90.1C360.7 90.1 363.5 88 363.5 83.7C363.5 80.2 361.2 78.6 358.5 77.6L348.8 74.1C341.2 71.4 337.3 66.7 337.3 59.2C337.3 50.3 342.9 43.1 357 43.1C369.5 43.1 375.3 48.7 375.3 58.3V60.5H363C363 55.1 361.4 52.4 356.9 52.4C353.4 52.4 350.4 54.4 350.4 58.2C350.4 60.8 351.8 63 356.4 64.5L364.7 67.3C373.4 70.2 376.6 74.6 376.6 82.6C376.6 93.8 368.2 99.4 356.7 99.4C341.3 99.4 337 92.6 337 82.4V80.5H349.3Z"
        fill={props.theme?.classLinkSAMLLogo.main}
      />
      <path
        d="M383.8 98V26.9H398.1V86.2H423.5V98H383.8Z"
        fill={props.theme?.classLinkSAMLLogo.main}
      />
      <path
        d="M430 26.1H443.7V37.9H430V26.1ZM430 44.4H443.7V98H430V44.4Z"
        fill={props.theme?.classLinkSAMLLogo.main}
      />
      <path
        d="M451.4 44.4H464.5V50.7H464.7C467.6 45.4 472.6 43 478.3 43C486.9 43 491.9 47.7 491.9 58.2V98H478.2V61.1C478.2 55.7 476.4 53.6 472.3 53.6C467.6 53.6 465 56.5 465 62.5V98H451.3L451.4 44.4Z"
        fill={props.theme?.classLinkSAMLLogo.main}
      />
      <path
        d="M513.5 26.9V64.4H513.7L527.5 44.4H542.6L527.2 65.5L544.9 98H529.8L518.3 76L513.4 82.4V98H499.7V26.9H513.5Z"
        fill={props.theme?.classLinkSAMLLogo.main}
      />
      <path
        d="M65.9 65.1C61.3 70.3 65.9 72.8 65.9 72.8C66.1 72.9 66.2 73 66.4 73C65.7 70.2 65.6 67.6 65.9 65.1Z"
        fill={props.theme?.classLinkSAMLLogo.main}
      />
      <path
        d="M95.8 71.8C92.2 74.2 88 82.4 88 82.4H85.8C87.2 79.3 88.1 76.7 88.6 74.6C88 74.8 87.7 74.9 87.7 74.9C83.4 76.4 79 77.1 75.1 77.2C79.2 83.3 86.7 84.4 86.7 84.4C97.2 85.8 105.1 80.1 109.4 75.9C100.7 71.1 95.8 71.8 95.8 71.8Z"
        fill={props.theme?.classLinkSAMLLogo.main}
      />
      <path
        d="M129.7 49.3C129.7 49.3 136.6 32.1 120.8 20.3C120.8 20.3 95.5 3.6 81.3 31.5C81.3 31.5 71.2 21.4 58.7 29.2C48 35.9 49.8 45 50.8 49.5C50.8 49.5 30.6 55.4 31.7 76.9C32.5 92.9 47.9 97.7 53.9 99C55.2 99.3 56.6 99.4 57.9 99.4H124.1C125.4 99.4 126.7 99.3 128 99C134 97.8 149.7 93 149.4 76C149 55 129.7 49.3 129.7 49.3ZM86.3 87.8C72.9 87.6 68.1 77.6 68.1 77.6C67.8 77.1 67.6 76.5 67.4 76C63.7 74.6 61.5 72 62.1 68.2C62.6 64.3 67 60.5 67 60.5C69.6 52.6 75.3 47.2 75.3 47.2C75.3 47.2 80.1 42.1 85.4 39.4L94.5 40.8C73.8 49.2 72.8 66.6 72.8 66.6C72.7 69.2 73 71.5 73.6 73.4C75.1 73.3 76.3 73.1 76.3 73.1C81.4 72.3 85.8 70.9 89.5 69.3C89.4 65.9 87.8 65 87.8 65C87.8 65 85.9 62.9 78.2 61.6L78.9 60.6C78.9 60.6 82.7 61.4 87 60.6C91.7 59.7 96 56.5 100.8 49.9L103.1 49.8C98.4 56 98.1 60.7 98.9 63.8C103.6 60.3 105.8 57.3 105.8 57.3C114.2 45.5 102.5 47.3 102.5 47.3L106 43.1C123.9 47 107.5 63.1 107.5 63.1C105.6 65 103.5 66.7 101.4 68.2C101.7 68.5 104.8 71.7 110.7 74.6C112.3 72.9 113.1 71.7 113.1 71.7L115.4 72.1C101.4 89.1 86.3 87.8 86.3 87.8ZM92.6 54.3C92.5 56.3 90.9 57.9 88.9 57.9C86.7 57.9 85 56.1 85.1 54C85.2 52 86.8 50.4 88.8 50.4C91 50.3 92.7 52.1 92.6 54.3Z"
        fill={props.theme?.classLinkSAMLLogo.main}
      />
    </SvgIcon>
  );
});
