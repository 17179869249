import { memo, useState } from "react";

import { ReturnIf } from "babel-plugin-transform-functional-return";
import styled, { css } from "styled-components";

import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";

import { FindingState, type ServerFindingWithType } from "data/finding";
import {
  type IgnoreApiPayload,
  type IgnoreType,
  type RuleType,
} from "data/finding_ignore_rule";
import { type FOTS } from "data/typescript";

import { ignoreFindings } from "model/finding";

import { asyncOrSwimWithSentry } from "utility/async_or_swim_sentry";
import { authenticatedPostFetch } from "utility/fetch/authenticated";
import { hasAtLeast } from "utility/has";
import { notifyError, notifySuccess } from "utility/notify";
import { pluralize, pluralizeCustom } from "utility/pluralize";
import { wait } from "utility/wait";

import { ProgressBar } from "Component/ProgressBar";
import { SelectedFindingsList } from "Component/SelectedFindingsList";

import { ModalActions as Actions } from "../Component/Actions";
import { ModalCancelButton as CancelButton } from "../Component/CancelButton";
import { ModalFullHeightContent as FullHeightContent } from "../Component/FullHeightContent";
import { FullHeightModal } from "../Component/FullHeightModal";
import { ModalSubContent as SubContent } from "../Component/SubContent";
import { ModalSubmitButton as SubmitButton } from "../Component/SubmitButton";
import { ModalTitle as Title } from "../Component/Title";

import { IgnoredValues } from "./IgnoredValues";
import { IgnoreRegexInput as Regex } from "./IgnoreRegexInput";

// -----------------------------------------------------------------------------

const IgnoreRuleTypeKeyMap: Record<
  Exclude<RuleType, "regex" | "">,
  keyof ServerFindingWithType
> = {
  finding: "name",
  asset: "asset",
  actor: "actor",
};

// -----------------------------------------------------------------------------

export const IgnoreModal = memo(({ findings, allFindings, onClose }: Props) => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const [ignoreType, setIgnoreType] = useState<IgnoreType>("instance");
  const [ruleType, setRuleType] = useState<RuleType>("");
  const [ruleRegex, setRuleRegex] = useState("");
  const [ignoreReason, setIgnoreReason] = useState("");

  //
  function onIgnore() {
    setLoading(true);
    setProgress(0);

    switch (ignoreType) {
      case "instance":
        asyncOrSwimWithSentry(
          async () => {
            await ignoreFindings(
              findings.map((finding) => finding.uuid),
              ignoreReason
            );

            //
            findings.forEach(
              (finding) => (finding.state = FindingState.Suppressed)
            );

            //
            notifySuccess(
              pluralizeCustom(
                findings.length,
                "Ignored the finding",
                `Ignored ${findings.length} findings`
              )
            );
            setProgress(1);
            setLoading(false);
            onClose(true);
          },
          "Failed to ignore findings (instance)",
          (error) => {
            console.error(error);
            notifyError(
              pluralizeCustom(
                findings.length,
                "Something went wrong ignoring the finding",
                "Something went wrong ignoring the findings"
              )
            );
            setLoading(false);
          }
        );
        break;

      case "rule":
        asyncOrSwimWithSentry(
          async () => {
            const [calls, ignoredValues] = getRuleCalls({
              ruleType,
              ruleRegex,
              ignoreReason,
              findings,
            });

            let i = 0;
            for (const call of calls) {
              await authenticatedPostFetch("api/findings/ignore/create", call);
              setProgress(++i / calls.length);
            }

            // ignore the specifically selected findings
            findings.forEach(
              (finding) => (finding.state = FindingState.Suppressed)
            );

            // now do all the findings
            ignoredValues.forEach(({ key, value }) => {
              allFindings.forEach((finding) => {
                switch (key) {
                  case "regex":
                    if ((value as RegExp).test(finding.name)) {
                      finding.state = FindingState.Suppressed;
                    }
                    break;

                  default:
                    if (
                      finding[key as keyof ServerFindingWithType] ===
                      (value as string)
                    ) {
                      finding.state = FindingState.Suppressed;
                    }
                    break;
                }
              });
            });

            await wait(100);

            notifySuccess("Created ignore rule");
            setLoading(false);
            onClose(true);
          },
          `Failed to ignore findings (rule, ${ruleType})`,
          (error) => {
            console.error(error);
            notifyError("Something went wrong creating the ignore rule");
            setLoading(false);
          }
        );
        break;

      default:
        notifyError("Invalid ignore type");
        setLoading(false);
        break;
    }
  }

  function onDone() {
    onClose(false);
  }

  //
  const placeholder = `Why you are ${
    ignoreType === "instance"
      ? pluralizeCustom(
          findings.length,
          "ignoring this finding",
          "ignoring these findings"
        )
      : "creating this rule"
  }?`;

  //
  return (
    <FullHeightModal onClose={loading ? undefined : onDone}>
      <Title>
        {pluralizeCustom(
          findings.length,
          "Ignore this finding?",
          `Ignore ${findings.length} findings?`
        )}
      </Title>

      <FullHeightContent>
        <SelectedFindingsList findings={findings} />
      </FullHeightContent>

      <SubContentCustom>
        <Tabs
          value={ignoreType}
          onChange={(_e, newValue) => setIgnoreType(newValue)}
        >
          <Tab
            label={`Ignore ${pluralize(findings.length, "instance")}`}
            value="instance"
          />
          <Tab label="Create a rule" value="rule" />
        </Tabs>

        <TabContentInstance current={ignoreType === "instance"}>
          {pluralizeCustom(
            findings.length,
            "Ignore this specific finding instance. ",
            "Ignore these specific finding instances. "
          )}
          Future findings may still appear.
        </TabContentInstance>

        <TabContentRule current={ignoreType === "rule"}>
          <div>
            Create a rule that ignores all existing and future finding
            instances.
          </div>

          <div>
            <FormControl fullWidth>
              <InputLabel id="ignore-rule-type-select-label" required>
                Rule type
              </InputLabel>
              <Select
                required
                value={ruleType}
                label="Rule type"
                onChange={(e: FOTS) =>
                  setRuleType(e?.target?.value as RuleType)
                }
              >
                <MenuItem value="finding">Finding type</MenuItem>
                <MenuItem value="actor">Actor</MenuItem>
                <MenuItem value="asset">Asset ID</MenuItem>
                <MenuItem value="regex">
                  Asset Name (Regular Expression)
                </MenuItem>
              </Select>
            </FormControl>

            <Collapse
              mountOnEnter
              unmountOnExit
              in={ruleType !== "regex" && ruleType !== ""}
            >
              <IgnoredValues findings={findings} type={ruleType as FOTS} />
            </Collapse>
          </div>

          <Collapse mountOnEnter unmountOnExit in={ruleType === "regex"}>
            <Regex
              value={ruleRegex}
              onChange={setRuleRegex}
              findings={findings}
            />
          </Collapse>
        </TabContentRule>

        <TextField
          fullWidth
          multiline
          label="Reason (optional)"
          placeholder={placeholder}
          variant="outlined"
          maxRows={4}
          value={ignoreReason}
          onChange={(e: FOTS) => setIgnoreReason(e?.target?.value)}
        />
      </SubContentCustom>

      <Actions>
        {loading ? (
          <ProgressBarContainer>
            <ProgressBar max={1} value={progress} />
          </ProgressBarContainer>
        ) : null}
        <CancelButton disabled={loading} onClick={onDone} />
        <SubmitButton
          disabled={
            loading ||
            isSubmitButtonDisabled({
              ignoreType,
              ruleType,
              ruleRegex,
              findings,
            })
          }
          loading={loading}
          onClick={onIgnore}
        >
          Ignore
        </SubmitButton>
      </Actions>
    </FullHeightModal>
  );
});

// -----------------------------------------------------------------------------

function isSubmitButtonDisabled({
  ignoreType,
  ruleType,
  ruleRegex,
  findings,
}: {
  ignoreType: "instance" | "rule";
  ruleType: RuleType;
  ruleRegex: string;
  findings: ServerFindingWithType[];
}) {
  switch (true) {
    case ignoreType === "rule" && ruleType === "":
    case ignoreType === "rule" && ruleType === "regex" && ruleRegex === "":
    case ignoreType === "rule" &&
      ruleType === "regex" &&
      hasAtLeast(2, findings):
      return true;

    default:
      return false;
  }
}

function getRuleCalls({
  ruleType,
  ruleRegex,
  ignoreReason,
  findings,
}: {
  ruleType: RuleType;
  ruleRegex: string;
  ignoreReason: string;
  findings: ServerFindingWithType[];
}): [IgnoreApiPayload[], Array<{ key: string; value: string | RegExp }>] {
  const [calls, ignores] = findings.reduce<
    [
      Record<string, IgnoreApiPayload>,
      Array<{ key: string; value: string | RegExp }>,
    ]
  >(
    (
      obj: [
        Record<string, IgnoreApiPayload>,
        Array<{ key: string; value: string | RegExp }>,
      ],
      finding
    ) => {
      const key =
        IgnoreRuleTypeKeyMap[ruleType as Exclude<RuleType, "regex" | "">];
      const value: string =
        ruleType === "regex" ? ruleRegex : (finding[key] as string);
      ReturnIf(obj[0][value], obj);

      //
      obj[0][value] = getRuleCall(
        ruleType,
        finding.uuid,
        ignoreReason,
        ruleRegex
      );
      obj[1].push(
        ruleType === "regex"
          ? { key: "regex", value: new RegExp(ruleRegex, "i") }
          : { key, value }
      );

      //
      return obj;
    },
    [{}, []]
  );

  //
  return [Object.values(calls), ignores];
}

function getRuleCall(
  ruleType: RuleType,
  findingUuid: string,
  ignoreReason: string,
  ruleRegex: string
): IgnoreApiPayload {
  const base = {
    finding_uuid: findingUuid,
    ignore_reason: ignoreReason !== "" ? ignoreReason : undefined,
  };

  switch (ruleType) {
    case "finding":
      return {
        ...base,
        finding_type_ignored: true,
      };

    case "actor":
      return {
        ...base,
        actor_ignored: true,
      };

    case "asset":
      return {
        ...base,
        asset_ignored: true,
      };

    case "regex":
      return {
        ...base,
        use_asset_re: true,
        asset_re: ruleRegex,
      };

    default:
      throw new Error("Unknown rule type");
  }
}

// -----------------------------------------------------------------------------

const SubContentCustom = styled(SubContent)`
  display: flex;
  flex-direction: column;
  gap: clamp(8px, calc(0.5rem + ((1vw - 3.75px) * 1.9394)), 24px);
  padding-top: 0;
`;

const ProgressBarContainer = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  height: 100%;
`;

const TabContentInstance = styled(Box).attrs(
  ({ current }: { current: boolean }) => ({
    current,
  })
)`
  ${({ current }: { current: boolean }) =>
    !current &&
    css`
      display: none !important;
    `}
`;

const TabContentRule = styled(TabContentInstance)`
  display: flex;
  flex-direction: column;
  gap: clamp(8px, calc(0.5rem + ((1vw - 3.75px) * 1.9394)), 24px);
`;

// -----------------------------------------------------------------------------

interface Props {
  findings: ServerFindingWithType[];
  allFindings: ServerFindingWithType[];
  onClose: (success: boolean) => void;
}
