import { type MouseEventHandler, memo } from "react";

import { ReturnIf } from "babel-plugin-transform-functional-return";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { ServiceConnectionPhase } from "Component/ServiceConnectionPhase";

// -----------------------------------------------------------------------------

export const AzureConnectPageErrorPhase = memo(
  (props: DisabledProps | ActiveProps) => {
    ReturnIf(!props.shown, null);

    //
    const { onNext, message } = props as ActiveProps;

    //
    return (
      <ServiceConnectionPhase disabled={false}>
        <Box sx={{ mb: 1 }}>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            There was an error connecting to Microsoft Azure
          </Typography>
        </Box>
        <Box sx={{ mb: 1 }}>
          <Typography variant="body1">
            {message ?? "Something went wrong"}
          </Typography>
        </Box>
        <Button
          variant="contained"
          onClick={onNext as MouseEventHandler<HTMLButtonElement>}
        >
          Delete Bad Connections And Start Over
        </Button>
      </ServiceConnectionPhase>
    );
  }
);

// -----------------------------------------------------------------------------

interface DisabledProps {
  shown: false;
}

interface ActiveProps {
  shown: true;
  onNext: Function;
  message?: string;
}
