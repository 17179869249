import { memo, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";

import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";

import { type FOTS } from "data/typescript";

import { useConnectionStore } from "stores/ConnectionStore";

import { type Service } from "utility/connections";
import { notifyError, notifySuccess } from "utility/notify";

import { usePageTitle } from "effect/use_page_title";

import { WebhookAppConfig } from "external/destination/Webhook";
import { AvailableWebhookAppConfigs } from "external/destination/Webhook/data";

import { Page } from "Component/Page";
import { PageContent } from "Component/PageContent";
import { PageHeader } from "Component/PageHeader";
import { PageHeaderBackButton } from "Component/PageHeaderBackButton";
import { PageTitle } from "Component/PageTitle";
import { ServiceConnectionInstructionPhase } from "Component/ServiceConnectionInstructionPhase";
import { ServiceConnectionPhase } from "Component/ServiceConnectionPhase";
import { ServiceConnectionPhases } from "Component/ServiceConnectionPhases";

// -----------------------------------------------------------------------------

export const WebhookConnectPage = memo(() => {
  const { name = "Invalid" } = useParams();

  const [, connectionActions] = useConnectionStore();

  const [webhookUrl, setWebhookUrl] = useState("");
  const [uuid, setUuid] = useState("");
  const [inputError, setInputError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);

  const webhook = useMemo(
    () =>
      name === "webhook"
        ? WebhookAppConfig
        : (AvailableWebhookAppConfigs.find(
            (service) => service.url === name
          ) as Service),
    [name]
  );

  //
  usePageTitle(
    `Environment → Destinations → ${webhook?.label ?? name} → Connect`
  );

  //
  if (webhook === undefined || webhook.connectInfo === undefined) {
    return (
      <Page>
        <PageHeader>
          <PageHeaderBackButton to="/environment/destinations">
            Back to Destinations
          </PageHeaderBackButton>

          <PageTitle>Invalid Destination</PageTitle>
        </PageHeader>

        <PageContent>
          Return to <Link to="/environment/destinations">Destinations</Link>
        </PageContent>
      </Page>
    );
  }

  //
  function connect() {
    setLoading(true);
    connectionActions.webhookConnect({
      path: webhook.connectInfo?.path ?? "",
      webhookUrl,
      onSuccess,
      onError,
    });
  }

  function onSuccess(data: any) {
    setLoading(false);
    notifySuccess(
      `Successfully connected to ${webhook.singularLabel ?? webhook.label}`
    );
    connectionActions.loadConnections({ reload: true });
    setComplete(true);
    setUuid(data.uuid);
  }

  function onError() {
    setLoading(false);
    notifyError(
      `Something went wrong connecting to ${
        webhook.singularLabel ?? webhook.label
      }`
    );
  }

  function reset() {
    setWebhookUrl("");
    setInputError(false);
  }

  //
  return (
    <Page>
      <PageHeader>
        <PageHeaderBackButton to="/environment/destinations">
          Back to Destinations
        </PageHeaderBackButton>
        <PageTitle>
          {webhook.singularLabel ?? webhook.label} Connection
        </PageTitle>
      </PageHeader>

      <PageContent>
        <ServiceConnectionPhases>
          <ServiceConnectionInstructionPhase>
            <Typography>
              ThreatKey {webhook.label} are a way for the ThreatKey platform to
              communicate with a {webhook.connectInfo.instructions.example} in
              near real-time. When an event happens in ThreatKey, a webhook is
              sent to the {webhook.connectInfo.instructions.example} with
              information about the event. The{" "}
              {webhook.connectInfo.instructions.example} can then take action
              based on the event.
            </Typography>
            <Typography>
              You can use {webhook.label} to connect to{" "}
              {webhook.connectInfo.instructions.extraExample
                ? webhook.connectInfo.instructions.extraExample
                : webhook.connectInfo.instructions.example + "s"}
              , to receive near real-time alerts of security events.
            </Typography>
            {webhook.connectInfo.instructions.extras
              ? webhook.connectInfo.instructions.extras?.map(
                  (instruction: string, i) => {
                    return <Typography key={i}>{instruction}</Typography>;
                  }
                )
              : null}
          </ServiceConnectionInstructionPhase>

          {!complete ? (
            <ServiceConnectionPhase disabled={loading}>
              <Typography>
                Please paste the{" "}
                <strong>{webhook.singularLabel ?? webhook.label} URL</strong>{" "}
                that you would like to connect to.
              </Typography>
              <TextField
                fullWidth
                label={`${webhook.singularLabel ?? webhook.label} URL`}
                placeholder={webhook.connectInfo?.placeholder}
                variant="outlined"
                sx={{ mt: 1 }}
                value={webhookUrl}
                onChange={(e: FOTS) => {
                  const url = e?.target?.value.trim();
                  const urlValid = !(
                    url.match(webhook.connectInfo?.regex) == null
                  );

                  setInputError(!!url && !urlValid);
                  setWebhookUrl(url);
                }}
                error={inputError}
                helperText={
                  inputError
                    ? `Invalid ${
                        webhook.singularLabel ?? webhook.label
                      } URL (are you missing HTTPS?)`
                    : ""
                }
                data-private="lipsum"
              />
              <Stack sx={{ mt: 2 }} spacing={2} direction="row">
                <LoadingButton
                  variant="contained"
                  onClick={connect}
                  loading={loading}
                  disabled={!webhookUrl || inputError}
                >
                  Connect {webhook.singularLabel ?? webhook.label}
                </LoadingButton>
                <Button variant="text" onClick={reset}>
                  Clear
                </Button>
              </Stack>
            </ServiceConnectionPhase>
          ) : (
            <ServiceConnectionPhase disabled={false}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CheckCircleOutlineRoundedIcon color="success" sx={{ mr: 1 }} />
                <Typography>Done!</Typography>
              </Box>
              <Stack sx={{ mt: 2 }} spacing={1} direction="column">
                <Typography>
                  <Link
                    to={`/environment/destinations/${
                      webhook.url ?? ""
                    }/${uuid}/notifications`}
                  >
                    Click here
                  </Link>{" "}
                  to go to your {webhook.singularLabel ?? webhook.label}{" "}
                  notification settings.
                </Typography>
              </Stack>
            </ServiceConnectionPhase>
          )}
        </ServiceConnectionPhases>
      </PageContent>
    </Page>
  );
});
