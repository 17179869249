import { memo } from "react";

import SvgIcon from "@mui/material/SvgIcon";

// -----------------------------------------------------------------------------

export const BoxCoverImage = memo((props: object) => {
  return (
    <SvgIcon viewBox="0 0 49 27" {...props}>
      <path
        fill="#0061D5"
        d="M48.2 23.6 C48.8 24.4 48.7 25.5 47.9 26.1 C47 26.7 45.9 26.6 45.3 25.8 L45.3 25.8 L41.1 20.4 L37 25.8 L37 25.8 C36.4 26.6 35.2 26.7 34.4 26.1 C33.6 25.5 33.4 24.4 34 23.6 L34 23.6 L38.8 17.3 L34 11.1 L34 11.1 C33.4 10.3 33.6 9.1 34.4 8.5 C35.2 7.9 36.4 8.1 37 8.9 L37 8.9 L41.1 14.3 L45.3 8.9 L45.3 8.9 C45.9 8.1 47 7.9 47.9 8.5 C48.7 9.1 48.8 10.3 48.2 11.1 L48.2 11.1 L43.5 17.3 L48.2 23.6 L48.2 23.6 L48.2 23.6 ZM26.3 22.8 C23.2 22.8 20.7 20.4 20.7 17.3 C20.7 14.3 23.2 11.8 26.3 11.8 C29.4 11.8 31.9 14.3 31.9 17.3 C31.9 20.4 29.4 22.8 26.3 22.8 L26.3 22.8 ZM9.8 22.8 C6.7 22.8 4.2 20.4 4.2 17.3 C4.2 14.3 6.7 11.8 9.8 11.8 C12.9 11.8 15.4 14.3 15.4 17.3 C15.4 20.4 12.9 22.8 9.8 22.8 L9.8 22.8 ZM26.3 8.2 C22.8 8.2 19.7 10.1 18.1 13 C16.5 10.1 13.4 8.2 9.8 8.2 C7.7 8.2 5.8 8.9 4.2 10 L4.2 2.3 L4.2 2.3 C4.2 1.3 3.4 0.5 2.4 0.5 C1.3 0.5 0.5 1.3 0.5 2.3 L0.5 2.3 L0.5 17.5 L0.5 17.5 C0.6 22.5 4.7 26.5 9.8 26.5 C13.4 26.5 16.5 24.5 18.1 21.6 C19.7 24.5 22.8 26.5 26.3 26.5 C31.5 26.5 35.7 22.4 35.7 17.3 C35.7 12.3 31.5 8.2 26.3 8.2 L26.3 8.2 Z"
      />
    </SvgIcon>
  );
});
