import { memo, type MouseEventHandler, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { CopyToClipboard } from "Component/CopyToClipboard";
import { ServiceConnectionPhase } from "Component/ServiceConnectionPhase";

// -----------------------------------------------------------------------------

const SnowflakeBaseUrlRegex =
  /(https:\/\/[a-zA-Z0-9-.]+\.snowflakecomputing\.com\/?)/;

// -----------------------------------------------------------------------------

export const SnowflakeConnectPageOAuthPhase = memo(
  ({
    disabled,
    baseUrl,
    clientId,
    clientSecret,
    onChangeBaseUrl,
    onChangeClientId,
    onChangeClientSecret,
    onNext,
  }: Props) => {
    const [errorUrl, setErrorUrl] = useState("");
    const [errorId, setErrorId] = useState("");
    const [errorSecret, setErrorSecret] = useState("");

    //
    function checkSnowflakeUrl(newUrl: string) {
      return SnowflakeBaseUrlRegex.test(newUrl);
    }

    //
    return (
      <ServiceConnectionPhase disabled={disabled}>
        <Stack spacing={1.5} direction="column">
          <Typography variant="body1">
            To generate the necessary credentials in Snowflake, execute the
            following commands. This will set up an OAuth Security Integration
            for ThreatKey with the <code>ACCOUNTADMIN</code> role.
          </Typography>
          <CopyToClipboard>
            CREATE SECURITY INTEGRATION THREATKEY
            <br />
            TYPE = OAUTH
            <br />
            ENABLED = TRUE
            <br />
            OAUTH_CLIENT = CUSTOM
            <br />
            OAUTH_CLIENT_TYPE = &apos;CONFIDENTIAL&apos;
            <br />
            OAUTH_REDIRECT_URI = &apos;
            {`${window.location.origin}/environment/sources/snowflake/oauth2`}
            &apos;
            <br />
            OAUTH_ISSUE_REFRESH_TOKENS = TRUE
            <br />
            OAUTH_REFRESH_TOKEN_VALIDITY = 777600;
          </CopyToClipboard>

          <Typography variant="body1">
            After creating the Security Integration, use the{" "}
            <strong>describe</strong> command to retrieve important details.
            ThreatKey needs the <code>OAUTH_AUTHORIZATION_ENDPOINT</code>,{" "}
            <code>OAUTH_CLIENT_ID</code>, and <code>OAUTH_CLIENT_SECRET</code>{" "}
            for Snowflake connectivity.
          </Typography>
          <CopyToClipboard>
            DESCRIBE SECURITY INTEGRATION THREATKEY;
          </CopyToClipboard>

          <Typography variant="body1">
            Finally, reveal the OAuth client secrets with the command below and
            take note of the <code>OAUTH_CLIENT_SECRET</code>.
          </Typography>
          <CopyToClipboard>
            SELECT SYSTEM$SHOW_OAUTH_CLIENT_SECRETS(&apos;THREATKEY&apos;);
          </CopyToClipboard>

          <Typography variant="body1">
            <strong>Note</strong>: You <strong>CANNOT</strong> grant consent for
            OAuth through users that have default roles set to one of the
            following: <code>ACCOUNTADMIN</code>, <code>SECURITYADMIN</code>,{" "}
            <code>ORGADMIN</code>. If a given user that wants to connect
            Snowflake is trying to do so with an account that has this role
            grant, they will need to change their default role to something
            else. The OAuth grant login screen does not allow you to a select a
            role in advance, and an &quot;Invalid consent request.&quot; error
            will be returned when trying to login. To be clear, users that have
            those roles granted, can consent, but not if their default role is
            one of those three. For additional details, please refer to the{" "}
            <Link
              href="https://docs.snowflake.com/en/user-guide/oauth-custom#blocking-specific-roles-from-using-the-integration"
              target="_blank"
              rel="noopener noreferrer"
            >
              Snowflake Documentation
            </Link>
            .
          </Typography>
          <Typography>
            From the output of the above commands, please input the values for{" "}
            <code>OAUTH_AUTHORIZATION_ENDPOINT</code>,{" "}
            <code>OAUTH_CLIENT_ID</code>, and <code>OAUTH_CLIENT_SECRET</code>
          </Typography>

          <TextField
            fullWidth
            label="OAuth Authorization Endpoint"
            placeholder="https://your-oauth-url.snowflakecomputing.com"
            value={baseUrl}
            onChange={(event) => {
              const newUrl = event.target.value.trim();
              onChangeBaseUrl(newUrl);
              setErrorUrl(
                checkSnowflakeUrl(newUrl) ? "" : "Invalid Snowflake URL"
              );
            }}
            error={errorUrl !== ""}
            helperText={errorUrl}
            data-private="lipsum"
          />
          <TextField
            fullWidth
            label="OAuth Client ID"
            value={clientId}
            onChange={(event) => {
              const newId = event.target.value.trim();
              onChangeClientId(newId);
              setErrorId(newId ? "" : "Required");
            }}
            error={errorId !== ""}
            helperText={errorId}
            data-private="lipsum"
          />
          <TextField
            fullWidth
            label="OAuth Client Secret"
            value={clientSecret}
            onChange={(event) => {
              const newSecret = event.target.value.trim();
              onChangeClientSecret(newSecret);
              setErrorSecret(newSecret ? "" : "Required");
            }}
            error={errorSecret !== ""}
            helperText={errorSecret}
            data-private="lipsum"
            type="password"
          />
        </Stack>

        <Box sx={{ flexGrow: 0, mt: 2.5 }}>
          <Tooltip
            arrow
            title={
              !!errorUrl || !!errorId || !!errorSecret
                ? "Please fill the form out correctly"
                : ""
            }
          >
            <span>
              <Button
                disabled={!!errorUrl || !!errorId || !!errorSecret}
                variant="contained"
                onClick={onNext as MouseEventHandler<HTMLButtonElement>}
              >
                Connect to Snowflake
              </Button>
            </span>
          </Tooltip>
        </Box>
      </ServiceConnectionPhase>
    );
  }
);

// -----------------------------------------------------------------------------

interface Props {
  disabled: boolean;
  baseUrl: string;
  clientId: string;
  clientSecret: string;
  onChangeBaseUrl: Function;
  onChangeClientId: Function;
  onChangeClientSecret: Function;
  onNext: Function;
}
