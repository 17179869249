import { ReturnIf } from "babel-plugin-transform-functional-return";

import { type CumulativeDailyFindings } from "stores/DashboardStore";

import { dayjs } from "utility/dayjs";

// -----------------------------------------------------------------------------

export function getLineData(
  data: CumulativeDailyFindings[] = [],
  isOpen: boolean = false
) {
  ReturnIf(data.length === 0, []);

  //
  let ret = data.map((curr: CumulativeDailyFindings) => {
    return {
      timestamp: curr.snap_timestamp,
      x: dayjs(curr.snap_timestamp).format("MM/DD"),
      y: isOpen ? curr.open_findings : curr.resolved_findings,
    };
  });

  ret = ret.sort(
    (a, b) => dayjs(a.timestamp).valueOf() - dayjs(b.timestamp).valueOf()
  );

  //
  return [{ id: `${isOpen ? 1 : 0}-findingsOverTime`, data: ret, color: "" }];
}
