import { memo, useEffect, useState } from "react";
import { Link, Navigate, useSearchParams } from "react-router-dom";

import { Buffer } from "buffer";

import { DoIf } from "babel-plugin-transform-functional-return";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useConnectionStore } from "stores/ConnectionStore";
import { useFlairStore } from "stores/FlairStore";
import {
  OAuth2Progress,
  OAuth2URLSuffix,
  useOAuth2Store,
} from "stores/OAuth2Store";
import { useOrganizationStore } from "stores/OrganizationStore";

import { getServicesByName } from "utility/connections";
import { hasSome } from "utility/has";
import { notifyError, notifySuccess } from "utility/notify";

import { usePageTitle } from "effect/use_page_title";

import { ExternalSources } from "external/sources";

import { LoaderModal } from "Component/Modal/LoaderModal";

import { PageContent } from "Component/PageContent";

// -----------------------------------------------------------------------------

enum Phase {
  OAuthProgress,
  Error,
  Done,
}

const OAuthSourceToURLSuffix: Record<string, OAuth2URLSuffix> = {
  box: OAuth2URLSuffix.BOX,
  github: OAuth2URLSuffix.GITHUB,
  google: OAuth2URLSuffix.GOOGLE,
  m365: OAuth2URLSuffix.MICROSOFT_365,
  salesforce: OAuth2URLSuffix.SALESFORCE,
  snowflake: OAuth2URLSuffix.SNOWFLAKE,
};

const SourcesByName = getServicesByName(ExternalSources.services);

// -----------------------------------------------------------------------------

export const EnvironmentSourcesOAuthPage = memo(() => {
  const searchParams = useSearchParams()[0];
  const service = searchParams.get("service") ?? "none";

  const [oauthStore, oauthActions] = useOAuth2Store();
  const [connectionStore] = useConnectionStore();
  const [organizationStore] = useOrganizationStore();
  const [, flairActions] = useFlairStore();
  const [phase, setPhase] = useState(Phase.OAuthProgress);

  //
  const sourceLabel = SourcesByName.get(service)?.label ?? "Unknown";

  //
  usePageTitle(`Environment → Sources → ${sourceLabel} → OAuth`);

  //
  useEffect(() => {
    switch (oauthStore.progress) {
      case OAuth2Progress.Unknown: {
        const code = searchParams.get("code");
        const stateParam = searchParams.get("state") ?? organizationStore.uuid;
        const state = getState(service, stateParam);
        const params = getParams(service, searchParams, stateParam);

        oauthActions.submitParams(
          OAuthSourceToURLSuffix[service],
          code,
          state,
          params
        );
        break;
      }

      case OAuth2Progress.Success:
        // celebration on first connection
        DoIf(
          hasSome(Object.keys(connectionStore.connections)),
          flairActions.startCelebration()
        );

        setPhase(Phase.Done);
        notifySuccess(`Connected to ${sourceLabel}`);
        break;

      case OAuth2Progress.Error:
        setPhase(Phase.Error);
        notifyError(`Failed connecting to ${sourceLabel}`);
        break;
    }
  }, [
    organizationStore.uuid,
    oauthStore.progress,
    oauthActions,
    connectionStore.connections,
    service,
    searchParams,
    flairActions,
    sourceLabel,
  ]);

  //
  return (
    <>
      {phase === Phase.OAuthProgress && <LoaderModal />}
      {phase === Phase.Error && (
        <PageContent>
          <Stack spacing={2} direction="column">
            <Typography variant="h5">
              There was an error connecting to the source
            </Typography>
            <Alert severity="error">{oauthStore.errorDetail}</Alert>
            <Box>
              <Button component={Link} to="/environment/sources">
                Go back
              </Button>
            </Box>
          </Stack>
        </PageContent>
      )}
      {phase === Phase.Done && <Navigate to="/environment/sources" />}
    </>
  );
});

// -----------------------------------------------------------------------------

function getState(source: string, state: string): string {
  switch (source) {
    case "salesforce":
      return parseJson<SalesforceStateParams>(state)?.orgUuid;

    default:
      return state;
  }
}

function getParams(
  source: string,
  searchParams: URLSearchParams,
  state: string
): Record<string, any> | undefined {
  switch (source) {
    case "github": {
      return {
        installation_id: searchParams?.get("installation_id"),
      };
    }

    case "salesforce":
      return {
        salesforce_domain: parseJson<SalesforceStateParams>(state)?.domain,
      };
  }
}

function parseJson<T = Record<string, any>>(json: string): T {
  try {
    return JSON.parse(Buffer.from(json, "base64").toString("utf-8")) as T;
  } catch (error) {
    console.error("Failed to parse JSON:", error);

    //
    return undefined as T;
  }
}

// -----------------------------------------------------------------------------

interface SalesforceStateParams {
  orgUuid: string;
  domain: string;
}
