import { memo } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";

// -----------------------------------------------------------------------------

export const GhostEnvironmentPossibleDestination = memo(() => {
  return (
    <Grid item xs={12} sm={6} md={6} lg={3}>
      <Box
        sx={{
          width: "100%",
          paddingTop: `${100 * (10 / 16)}%`,
          position: "relative",
        }}
      >
        <Skeleton
          variant="rectangular"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: 1,
          }}
        />
      </Box>
    </Grid>
  );
});
